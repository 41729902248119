import React from 'react';

import { loadCaptchaEnginge, LoadCanvasTemplate } from 'react-simple-captcha';

/**
 * AutoTooltip
 */
class AutoTooltip extends React.Component {

  componentDidMount() {
    loadCaptchaEnginge(6);
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <LoadCanvasTemplate/>
    );
  }
}

export default AutoTooltip;
