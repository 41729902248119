export const ADD_BREADCRUMB = 'add_breadcrumb';
export const RESET_BREADCRUMB = 'reset_breadcrumb';

export function addBreadcrumbAction(path, title, identifier = null){
  return {
    type: ADD_BREADCRUMB,
    path: path,
    title: title,
    identifier: identifier
  }
}

export function resetBreadcrumbAction(path = null, title = null, identifier = null){
  return {
    type: RESET_BREADCRUMB,
    path: path,
    title: title,
    identifier: identifier
  }
}
