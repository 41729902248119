const ticketForm = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Le nom ne peut pas être vide."
    }
  },
  description: {
    label: "Description",
    invalid: {
      assertEmpty: "La description ne peut pas être vide."
    }
  },
  fields: {
    label: "Champs #key#",
    invalid: {
      assertQuantity: "Il faut au moins un champ."
    },
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer",
    }
  },
};

export default ticketForm;
