import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function MainRouter(props) {
  let prefix = props.conf.prefix;
  return (
    <Router>
      <Switch>
        {Object.keys(props.conf.routes).map((key) => {
          let item = props.conf.routes[key];
          if(item.type === 'component') {
            return (
              <Route key={key} exact={item.exact} path={`${prefix}${item.path}`} component={item.getComponent()}/>
            );
          }
          else if (item.type === 'render') {
            return (
              <Route key={key} exact={item.exact} path={`${prefix}${item.path}`} render={item.getRender}/>
            );
          }
          else {
            throw new Error("invalid type inside router");
          }
        })}
      </Switch>
    </Router>
  );
}

export default MainRouter;
