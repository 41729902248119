import React from 'react';

class TextResizer extends React.Component {

  static defaultProps = {
    text: "",

    className: "",

    fontUnit: "vw",
    fontStep: 0.5,
    defaultFontSize: 1,
    heightTreshold: 10,
  }

  constructor(props) {
    super(props);

    this.refContainer = React.createRef();
    this.refText = React.createRef();

    this.state = {
      fontSize: props.defaultFontSize,
      loopBack: []
    }
  }

  componentDidMount() {
    this.resizeFont();
  }

  componentDidUpdate() {
    this.resizeFont();
  }

  resizeFont () {
    if(this.refContainer.current && this.refText.current) {
      if(
        this.refText.current.clientWidth < this.refContainer.current.clientWidth
        && !this.state.loopBack.includes(this.refText.current.clientWidth)
        && this.refText.current.clientHeight < (this.refContainer.current.scrollHeight - this.props.heightTreshold)
      ) {
        let loopBack = [...this.state.loopBack]
        loopBack.splice(0, 0, this.refText.current.clientWidth);
        loopBack.splice(5, 10);
        this.setState({
          fontSize: this.state.fontSize + this.props.fontStep,
          loopBack: loopBack
        })
      }
      else if(this.refText.current.clientWidth > this.refContainer.current.clientWidth) {
        let loopBack = [...this.state.loopBack]
        loopBack.splice(0, 0, this.refText.current.clientWidth);
        loopBack.splice(5, 10);
        this.setState({
          fontSize: this.state.fontSize - this.props.fontStep,
          loopBack: loopBack
        })
      }
    }
  }

  render() {
    return (
      <div ref={this.refContainer} className={this.props.className} style={{height: "100%", width: "100%", fontSize: `${this.state.fontSize}${this.props.fontUnit}`}}>
        <div ref={this.refText} style={{height: "fit-content", width: "fit-content"}}>
        {this.props.text}
        </div>
      </div>
    );
  }
}

export default TextResizer;
