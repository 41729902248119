import React from 'react';
import BasicWidget from './BasicWidget';

import { connect } from 'react-redux';

import Chart from '../../Echart/Chart';

import { chartOption } from '../../../MetaData/Echart/stateMachine';


// import '../../../css/dashboard/widget_chart_pie.css';

/**
 * WidgetStateMachine
 */
class WidgetStateMachine extends BasicWidget {

  static defaultProps = {
    interval: 60
  }

  constructor(props) {
    super(props);

    this.chart = React.createRef();

    this.timer = null;

    this.requests = {};

    this.state = {
      viewportWidth: 1920,

      data: []
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();

    this.initiateApi(true);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.parameterBag !== this.props.parameterBag || prevProps.conf !== this.props.conf) {
      this.initiateApi(true)
    }
    else {
      this.initiateApi()
    }

    if(this.chart.current && (prevProps.column !== this.props.column || prevProps.row !== this.props.row)) {
      this.chart.current.resizeChart();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);

    if(this.timer !== null) {
      clearInterval(this.timer);
    }
  }

  onResize = () => {
    if(this.state.viewportWidth !== window.innerWidth) {
      this.setState({
        viewportWidth: window.innerWidth
      })
    }
  }

  initiateApi = (forceFetch = false) => {
    Object.keys(this.props.widgetType.elementSettings).forEach((key) => {
      if(!this.requests[key]) {
        this.requests[key] = {
          fetch: this.props.modalHandler.addVerificationWithCallback(this.props.widgetType.elementSettings[key].fetch, this.requestCallback, this.requestCallbackFailure, true)
        }
      }
    });
    if(this.timer === null) {
      this.timer = setInterval(this.timeFetch, this.props.interval * 1000);
      forceFetch = true;
    }
    if(forceFetch) {
      this.timeFetch();
    }
  }

  timeFetch = () => {
    if(!this.props.conf.dataset) {
      return;
    }
    if(!this.props.conf.data) {
      return;
    }
    if(this.requests[this.props.conf.element]) {
      this.setState({
        loading: true
      }, () => {
        let filters = [];
        if(this.props.conf.element && this.props.widgetType.elementSettings[this.props.conf.element].computeFilters) {
          filters = Object.assign({}, this.props.conf.filters);
          Object.keys(filters).forEach(key => {
            filters[key] = this.evaluateArgument('filters.'+key);
          });
          filters = this.props.widgetType.elementSettings[this.props.conf.element].computeFilters(filters);
        }
        this.requests[this.props.conf.element].fetch(this.props.conf.dataset, this.props.conf.data, filters)
      })
    }
  }

  requestCallback = (data) => {
    if(data.error) {
      console.warn(data.error);
    }
    this.setState({
      loading: false,
      data: data.result
    })
  }

  requestCallbackFailure = (msg) => {
    console.log(msg);
  }

  onClickChartItem = (data) => {
    let filters = {};
    if(this.props.conf.element && this.props.widgetType.elementSettings[this.props.conf.element].computeFilters) {
      filters = Object.assign({}, this.props.conf.filters);
      Object.keys(filters).forEach(key => {
        filters[key] = this.evaluateArgument('filters.'+key);
      });
    }

    if(this.props.conf.element && this.props.widgetType.elementSettings[this.props.conf.element].dataset) {
      let dataset = this.props.widgetType.elementSettings[this.props.conf.element].dataset;
      let availableData = Object.values(dataset[this.props.conf.dataset].available).find(available => String(available.id) === String(data.id));
      if(availableData.filters) {
        Object.assign(filters, availableData.filters());
      }
    }

    this.props.history.push(this.props.widgetType.elementSettings[this.props.conf.element].link(), { filters: filters });
  }

  getChartOption = () => {
    let fontSize = 0;

    switch (this.props.row) {
      case 1:
        fontSize = this.state.viewportWidth / 100 * 0.787
        break;
      case 2:
      default:
        fontSize = this.state.viewportWidth / 100 * 1.575
    }

    let data = [];

    if(this.state.data && Object.keys(this.state.data).length > 0) {
      data = Object.keys(this.state.data).map(key => {
        let dataset = Object.values(this.props.widgetType.elementSettings[this.props.conf.element].dataset[this.props.conf.dataset].available).find(item => String(item.id) === String(key))
        return {
          id: key,
          value: this.state.data[key],
          name: dataset.label
        }
      })
    }

    return chartOption(data, this.props.theme, fontSize);
  }

  displayChart() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Chart ref={this.chart} option={this.getChartOption()} onClickItem={this.onClickChartItem}/>
      </div>
    )
  }

  displayContent() {
    return (
      <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
        {this.displayTitle()}
        {this.displayArguments()}
        <div className="dashboard-content-container">
          {this.displayChart()}
        </div>
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="dashboard-widget-container">
        {this.displayContent()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.persisted.theme.name
})

export default connect(mapStateToProps)(WidgetStateMachine);
