const phonePrefix = {
  tab: {
    main: "Détail",
  },
  search: {
    prefix: "Préfix"
  },
  new: {
    title: "Nouveau préfixe"
  }
};

export default phonePrefix;
