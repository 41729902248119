const order = {
  tab: {
    main: "Commande",
    cart: "Produit(s)",
    technicalData: "Données techniques",
    services: "Services associés",
    bill: "Facture(s)"
  },
  search: {
    title: "Titre",
    number: "Numéro",
    state: "Etat",
    rightToCancellation: "Droit de rétractation",
    client: "Client",
    clientUser: "Référent client",
    placeDelivery: "Site de livraison",
    validationDateTimeframe: "Plage de temps / Validation",
    validationDateMin: "Validée après",
    validationDateMax: "Validée avant",
    createdAtTimeframe: "Plage de temps / Création",
    createdAtMin: "Créée après",
    createdAtMax: "Créée avant",
  },
  tabs: {
    buttons: {
      validatingOrder: "Valider la CMD",
      cancelOrder: "Annuler la CMD",
      advancePaymentPaid: "Valider la première facture",
      cancelValidatedOrder: "Annuler la CMD (avec frais)",
      cancelValidatedOrderNoBill: "Annuler la CMD (sans frais)",
      deliver: "Livrer la CMD"
    }
  },
};

export default order;
