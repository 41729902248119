const productFamilyVat = {
  clientType: {
    label: "Type de client",
  },
  accountVat: {
    label: "Compte T.V.A.",
  }
};

export default productFamilyVat;
