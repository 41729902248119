import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem,
  statistic as baseStatistic,
  stateMachineStatistic as baseStateMachineStatistic
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of orders based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('orders', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getFromId(id) {
  return baseGetFromId('orders', id);
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('orders', id, 'identification');
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getCartFromId(id) {
  return baseGetFromId('orders', id, 'cart');
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getTechnicalDataFromId(id) {
  return baseGetFromId('orders', id, 'technical_data');
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getBillFromId(id) {
  return baseGetFromId('orders', id, 'bill');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single order based on his ID
 *
 * @param id the of the order requested
 * @param data the new data of the order
 */
export function editTechnicalDataFromId(id, data) {
  return baseEditFromId('orders', id, data, 'technical_data');
}

/******************************************************************************
 * STATISTIC FUNCTION                                                         *
 ******************************************************************************/

export function statistic(stat, attribute, search) {
  return baseStatistic('orders', stat, attribute, search);
}

/******************************************************************************
 * STATE MACHINE STATISTIC FUNCTION                                           *
 ******************************************************************************/

export function stateMachineStatistic(stateStatistic, states, search) {
  return baseStateMachineStatistic('orders', stateStatistic, states, search);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new order
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return baseNewItem('orders', data);
}
