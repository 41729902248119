const order = {
  number: {
    label: "Numéro de commande",
  },
  signatureDate: {
    label: "Date de signature",
  },
  validationDate: {
    label: "Date de validation",
  },
  validationDateTimeframe: {
    label: "Validation"
  },
  validationDateMin: {
    label: "Date de validation (min)"
  },
  validationDateMax: {
    label: "Date de validation (max)"
  },
  deliveryDate: {
    label: "Date de fin de livraison",
  },
  rightToCancellation: {
    label: "Droit de rétractation",
  },
  installationTicket: {
    label: "Ticket d'installation",
  },
  subFamily: {
    label: "Sous-famille concernée"
  },
  client: {
    label: "Client",
  },
  clientAccount: {
    label: "Compte Client",
  },
  place: {
    label: "Site de livraison",
  },
  cartItems: {
    label: "Contenu de la commande",
  },
};

export default order;
