import strings from '../../../Localization/Localization';

let title = {
  fieldKey: "title",
  type: "text",
  label: strings.form.accountProduct.title.label,
  showOnDisabled: () => false,
  validators: []
}

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.accountProduct.number.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "accountProductSearch",
  fields: [
    title,
    number
  ]
}
