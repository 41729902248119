import strings from '../../Localization/Localization';

import { form as _fieldForm } from './ticketFormFields';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.ticketForm.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.ticketForm.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let description = {
  fieldKey: "description",
  type: "textarea",
  label: strings.form.ticketForm.description.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.ticketForm.description.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let fields = {
  fieldKey: "fields",
  type: "array",
  label: "",

  validators: [
    {
      id: 'assertQuantity',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.ticketForm.fields.invalid.assertQuantity,
      defaultValue: false
    },
  ],

  submitRemoval: true,
  removalCondition: (value) => !!value,
  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.ticketForm.fields.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.ticketForm.fields.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.ticketForm.fields.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: strings.form.ticketForm.fields.label,

    validators: [],

    subForm: _fieldForm,
  }
}

export const form = {
  formKey: "ticketForm",
  fields: [
    name,
    description,
    fields
  ]
}
