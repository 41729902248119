// import React from 'react';

import Error from '../../Component/Layout/Error';

export const routes = {
  prefix: '/process',
  routes: {

    error: {
      path: '/*',
      exact: false,
      createPath: () => '/',
      type: 'component',
      getComponent: () => Error,
    },
  }
}
