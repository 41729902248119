import strings from '../../../Localization/Localization';

import { store } from '../../../Store/configureStore';

import {
  fetchMainUsers
} from '../../../API/Users';

import { timeframe } from '../common';

export let bonusType = {
  fieldKey: "bonusType",
  label: strings.form.commercialBonus.bonusType.label,
  type: "dropdown",
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.commercialBonus.bonusType.options.quotePunctual
    },
    {
      value: 1,
      label: strings.form.commercialBonus.bonusType.options.quoteAnnual
    },
    {
      value: 2,
      label: strings.form.commercialBonus.bonusType.options.serviceRecurrentNoEngagement
    }
  ],
}

let flatBonusValue = {
  fieldKey: "flatBonusValue",
  type: "price",
  label: strings.form.commercialBonus.flatBonusValue.label,
  validators: []
}

let user = {
  fieldKey: "user",
  label: strings.form.commercialBonus.user.label,
  type: "dropdownEntity",
  disabled: (getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      return !credentials.roles.includes('ROLE_COMMERCIAL_BONUS_MANAGER');
    }
    return true;
  },

  validators: [],

  showEmpty: true,
  loader: {
    id: "user",
    loader: fetchMainUsers,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

export const form = {
  formKey: "commercialBonusSearch",
  fields: [
    bonusType,
    flatBonusValue,
    user,
    Object.assign({}, timeframe, {
      fieldKey: "createdAtTimeframe",
      label: `${strings.form.common.timeframe.label} ${strings.form.commercialBonus.createdAtTimeframe.label}`
    }),
    Object.assign({}, timeframe, {
      fieldKey: "receivedAtPrevisionTimeframe",
      label: `${strings.form.common.timeframe.label} ${strings.form.commercialBonus.receivedAtPrevisionTimeframe.label}`
    }),
    Object.assign({}, timeframe, {
      fieldKey: "receivedAtTimeframe",
      label: `${strings.form.common.timeframe.label} ${strings.form.commercialBonus.receivedAtTimeframe.label}`
    }),
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
