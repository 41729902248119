import {
  index as baseIndex,
  getFromId as baseGetFromId,
  request
 } from './Base';

/**
 * Get a list of billings based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('billings', page, itemPerPage, search, order, scheme);
}

/**
 * Get a single billing based on his ID
 *
 * @param id the of the billing requested
 */
export function getFromId(id) {
  return baseGetFromId('billings', id);
}

/**
 * Get a single billing based on his ID
 *
 * @param id the of the billing requested
 */
export function getBillsFromId(id) {
  return baseGetFromId('billings', id, 'bills');
}

/**
 * Create a new billing
 *
 * @param data the new data of the partner
 */
export function startBilling(data) {
  return request('/monthly_billing_process');
}


/**
 * Create a new billing
 *
 * @param data the new data of the partner
 */
export function startBillingTest(data) {
  return request('/monthly_billing_process_test');
}
