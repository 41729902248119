import BaseAssert from './BaseAssert';

class NotEmptyOrNullAssert extends BaseAssert {

  assert() {
    return (this.getValue() !== undefined)? (this.getValue() !== null && this.getValue() !== ''):null;
  }
}

export default NotEmptyOrNullAssert;
