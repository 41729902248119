import strings from '../../Localization/Localization';

import { fetchPhoneZones } from '../../API/PhoneZones';
import { fetchPhonePrices } from '../../API/PhonePrices';

import { form as _aliasForm } from './phoneZoneGroupAliases';

let label = {
  fieldKey: "label",
  type: "text",
  label: strings.form.phoneZoneGroup.label.label,
  validators: []
}

let aliases = {
  fieldKey: "aliases",
  label: strings.form.phoneZoneGroup.aliases.label,
  type: "array",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phoneZoneGroup.aliases.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phoneZoneGroup.aliases.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phoneZoneGroup.aliases.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: "",

    subForm: _aliasForm,

    validators: [],
  },

  validators: []
}

let zones = {
  fieldKey: "zones",
  label: strings.form.phoneZoneGroup.zones.label,
  type: "array",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phoneZoneGroup.zones.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phoneZoneGroup.zones.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phoneZoneGroup.zones.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.phoneZoneGroup.zone.label,

    ignoreValidation: true,
    validators: [],

    showEmpty: true,
    loader: {
      id: "prefix",
      loader: fetchPhoneZones,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["label"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["label"],
    },
  },

  validators: []
}

let priceSources = {
  fieldKey: "price_sources",
  label: strings.form.phoneZoneGroup.priceSources.label,
  type: "array",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phoneZoneGroup.priceSources.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phoneZoneGroup.priceSources.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phoneZoneGroup.priceSources.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.phoneZoneGroup.priceSource.label,

    ignoreValidation: true,
    validators: [],

    showEmpty: true,
    loader: {
      id: "prefix",
      loader: fetchPhonePrices,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  },

  validators: []
}

let priceDestinations = {
  fieldKey: "price_destinations",
  label: strings.form.phoneZoneGroup.priceDestinations.label,
  type: "array",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phoneZoneGroup.priceDestinations.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phoneZoneGroup.priceDestinations.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phoneZoneGroup.priceDestinations.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.phoneZoneGroup.priceSource.label,

    ignoreValidation: true,
    validators: [],

    showEmpty: true,
    loader: {
      id: "prefix",
      loader: fetchPhonePrices,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  },

  validators: []
}

export const zoneForm = {
  formKey: "phoneZone",
  fields: [
    zones
  ]
}

export const priceSourceForm = {
  formKey: "phoneZone",
  fields: [
    priceSources
  ]
}

export const priceDestinationForm = {
  formKey: "phoneZone",
  fields: [
    priceDestinations
  ]
}

export const form = {
  formKey: "phoneZoneGroupSearch",
  fields: [
    label,
    aliases
  ]
}
