const centreonNetwork = {
  chartType: {
    line: {
      yAxis: {
        title: "Trafic",
        unit: "Mb/s"
      }
    },
    bar: {
      yAxis: {
        _0: {
          title: "Trafic entrant",
          unit: "Mb/s"
        },
        _1: {
          title: "Trafic sortant",
          unit: "Mb/s"
        },
      },
    }
  },

  series: {
    download: {
      name: "Trafic entrant",
    },
    upload: {
      name: "Trafic sortant",
    },
  }
};

export default centreonNetwork;
