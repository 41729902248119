const amendment = {
  custom: {
    quantity: "Changement de Quantité",
    product: "Changement de Produit",
    place: "Changement de Site",
    priceContract: "Changement de Prix sur contrat",
    priceService: "Changement de Prix sur service",
    result: "Résultat",
    unavailable: " (pas d'avenant possible)"
  },
  reducer: {
    defaultTitle: "Avenant au contrat – "
  },
  breadcrumb: "Nouvel avenant"
};

export default amendment;
