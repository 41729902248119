import React from 'react';

import { Container, Col, Navbar } from 'react-bootstrap';
import '../../css/footer.css';
import strings from '../../Localization/Localization';

/**
 * Footer
 *
 * This is the footer of the application.
 */
class Footer extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    //display
    //version type
    //copyright
    //version number
    return (
      <Navbar fixed="bottom" bg="my-primary" expand="lg">
        <Container>
          <Col className="d-flex justify-content-center text-center small-text">
            {strings.common.footer.copyright}
            <br/>
            {strings.common.footer.version}{process.env.REACT_APP_VERSION}
          </Col>
        </Container>
      </Navbar>
    );
  }
}


export default Footer;
