import React from 'react';
import PropTypes from 'prop-types';

import '../../css/process.css';

import { Container, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import {
  createContainer,
  modifyFormValue,
  resetContainer
} from '../../Store/Action/process';

import MainLayout from '../Layout/MainLayout';
import MyForm from '../Form/MyForm';

class Process extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      activeStepIndex: 0
    }
  }

  componentDidMount() {
    if(this.props.processContainer[this.props.processId] === undefined) {
      this.props.dispatch(createContainer(this.props.processId, this.props.steps));
    }
    this.setState({
      isLoading: false
    })
  }

  onclickBreadcrumbStep = (stepIndex) => {
    if(this.state.activeStepIndex > stepIndex) {
      this.setState({
        activeStepIndex: stepIndex
      })
    }
  }

  displayBreadcrumb() {
    return (
      <>
        {this.props.steps
          .map((step, i) => {
            if(typeof step.disabled === 'function' && step.disabled(this.props.processContainer[this.props.processId])) {
              return null;
            }
            else if(typeof step.disabled !== 'function' && step.disabled) {
              return null;
            }
            return (
              <Button key={i} variant="breadcrumb" className={`breadcrumb-step${i === this.state.activeStepIndex ? " active":""}`} onClick={() => this.onclickBreadcrumbStep(i)}>{step.label}</Button>
            );
          }
        )}
      </>
    );
  }

  formGetValues = (step) => {
    if(step.valueOrigin === "transparent") {
      return this.props.processContainer[this.props.processId];
    }
    else {
      return this.props.processContainer[this.props.processId][step.id];
    }

  }

  formOnChangeItem = (step, key, value) => {
    if(step.valueOrigin === "transparent") {
      this.props.dispatch(modifyFormValue(this.props.processId, null, key, value));
    }
    else {
      this.props.dispatch(modifyFormValue(this.props.processId, step.id, key, value));
    }
  }

  formOnSubmit = (step, values) => {
    Object.keys(values).filter(key => !key.startsWith('_')).forEach(key => {
      this.formOnChangeItem(step, key, values[key]);
    });

    if(step.onSubmitStep !== undefined) step.onSubmitStep(values);
    this.nextStep();
  }

  onClickExtraButton = () => {
    this.props.dispatch(resetContainer(this.props.processId));
    this.setState({
      activeStepIndex: 0
    })
  }

  nextStep() {
    if(this.state.activeStepIndex === (this.props.steps.length - 1)) {
      this.props.onSubmit(this.props.processContainer[this.props.processId]);
      this.props.dispatch(resetContainer(this.props.processId));
    }
    else {
      //check if you need to skip steps
      let inc = 1;
      let done = false;
      while(!done) {
        let stepToCheck = this.props.steps[this.state.activeStepIndex+inc];
        if((typeof stepToCheck.disabled === 'function' && stepToCheck.disabled(this.props.processContainer[this.props.processId]))
          || (typeof stepToCheck.disabled !== 'function' && stepToCheck.disabled)) {
            inc++;
        }

        else {
          done = true;
        }
      }
      this.setState({
        activeStepIndex: this.state.activeStepIndex + inc
      })
    }
  }

  displayStep() {
    if(!this.state.isLoading){
      let step = this.props.steps[this.state.activeStepIndex];
      switch (step.type) {
        case "form":
          return (
            <MyForm
              formKey={step.form.formKey}
              fields={step.form.fields}
              extraValues={step.form.extraValues}
              disabled={false}
              values={this.formGetValues(step)}
              onChangeField={(key, value) => this.formOnChangeItem(step, key, value)}
              onSubmit={(values) => this.formOnSubmit(step, values)}
              submitLabel="Suivant"

              displayExtraButton={true}
              onClickExtraButton={this.onClickExtraButton}
            />
          )
        default:

      }
    }
  }

  render() {
    return (
      <MainLayout>
        <Container id={this.props.processId} fluid className="d-flex justify-content-center align-items-center breadcrumb-container">
          {this.displayBreadcrumb()}
        </Container>
        <Container className="d-flex justify-content-center align-items-center">
          {this.displayStep()}
        </Container>
      </MainLayout>
    );
  }
}

const mapStateToProps = state => ({
  processContainer: state.persisted.process
})

//use propTypes to check if the attribute are all present
Process.propTypes = {
  processId: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    function(propValue, key, componentName, location, propFullName) {
      propValue.forEach((item) => {
        if(item.id === undefined || item.type === undefined || item.label === undefined) {
          return new Error(
            'Invalid prop `' + propFullName + '` supplied to' +
            ' `' + componentName + '`. Validation failed.'
          );
        }
      });
    }
  ).isRequired,
}


export default connect(mapStateToProps)(Process);
