import strings from '../../../Localization/Localization';

import { store } from '../../../Store/configureStore';

import { fetchTicketTypes as fetchAdministrativeTicketTypes } from '../../../API/AdministrativeTicketTypes';
import { fetchTicketTypes as fetchCommercialTicketTypes } from '../../../API/CommercialTicketTypes';
import { fetchTicketTypes as fetchTechnicalTicketTypes } from '../../../API/TechnicalTicketTypes';

import { fetchAppointmentTypes as fetchAdministrativeAppointmentTypes } from '../../../API/AdministrativeAppointmentTypes';
import { fetchAppointmentTypes as fetchCommercialAppointmentTypes } from '../../../API/CommercialAppointmentTypes';
import { fetchAppointmentTypes as fetchTechnicalAppointmentTypes } from '../../../API/TechnicalAppointmentTypes';

import { fetchPartners } from '../../../API/Partners';
import { fetchPartnerAccounts } from '../../../API/PartnerAccounts';
import { fetchPlaces } from '../../../API/Places';
import {
  fetchMainUsers,
  fetchPartnerUsers
} from '../../../API/Users';

import { timeframe } from '../common';

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.ticket.number.label,
  showOnDisabled: () => false,
  validators: []
}

let ticketType = (type) => {
  let loader = null;
  switch (type) {
    case 'technical':
      loader = fetchTechnicalTicketTypes;
      break;
    case 'commercial':
      loader = fetchCommercialTicketTypes;
      break;
    case 'administrative':
      loader = fetchAdministrativeTicketTypes;
      break;
    default:
  }
  return {
    fieldKey: "requestType",
    label: strings.form.ticket.ticketType.label,
    showOnDisabled: () => false,
    type: "dropdownMultiEntity",

    validators: [],

    // showEmpty: true,
    finalValues: 'objects',
    loader: {
      id: "ticketType",
      loader: loader,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  }
}

export const state = {
  fieldKey: "state",
  type: "dropdownMulti",
  label: strings.form.ticket.state.label,
  showOnDisabled: () => false,
  validators: [],

  input: 'options',
  finalValues: 'objects',
  options: [
    {
      value: 'new',
      label: strings.form.ticket.state.options.new
    },
    {
      value: 'waiting',
      label: strings.form.ticket.state.options.waiting
    },
    {
      value: 'in_progress',
      label: strings.form.ticket.state.options.in_progress
    },
    {
      value: 'closed',
      label: strings.form.ticket.state.options.closed
    },
  ],
}

export const criticity = {
  fieldKey: "criticity",
  type: "dropdown",
  label: strings.form.ticket.criticity.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 1,
      label: strings.form.ticket.criticity.options.urgent
    },
    {
      value: 2,
      label: strings.form.ticket.criticity.options.medium
    },
    {
      value: 3,
      label: strings.form.ticket.criticity.options.noturgent
    },
  ],
}

let escalateLevel = {
  fieldKey: "escalateLevel",
  type: "dropdown",
  label: strings.form.ticket.escalateLevel.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 1,
      label: strings.form.ticket.escalateLevel.options._1
    },
    {
      value: 2,
      label: strings.form.ticket.escalateLevel.options._2
    },
    {
      value: 3,
      label: strings.form.ticket.escalateLevel.options._3
    },
  ],
}

let referee = {
  fieldKey: "referee",
  label: strings.form.ticket.referee.label,
  type: "dropdownEntity",
  disabled: (getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      return !credentials.roles.includes('ROLE_NYUKOM');
    }
    return true;
  },
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "referee",
    loader: fetchMainUsers,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let client = {
  fieldKey: "client",
  label: strings.form.ticket.client.label,
  type: "dropdownEntity",
  showOnDisabled: () => false,

  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "client",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let clientAccount = {
  fieldKey: "clientAccount",
  label: strings.form.ticket.clientAccount.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues(0).client === undefined || getValues(0).client === null || getValues(0).client.id === undefined,

  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component.reloadData()
    }
  ],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "clientAccount",
    loader: fetchPartnerAccounts,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["partner_type"] && data["partner_type"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["partner_type"]["name"],
  },
}

let place = {
  fieldKey: "place",
  label: strings.form.ticket.place.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues(0).client === undefined || getValues(0).client === null || getValues(0).client.id === undefined,
  showOnDisabled: () => false,

  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component && component.reloadData()
    }
  ],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "partnerPlaces",
    loader: fetchPlaces,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"],
        active: 1
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let refereeClient = {
  fieldKey: "refereeClient",
  label: strings.form.ticket.refereeClient.label,
  type: "dropdownEntity",
  disabled: (getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      return (!credentials.roles.includes('ROLE_NYUKOM'))
        && (getValues(0).client === undefined || getValues(0).client === null || getValues(0).client.id === undefined);
    }
    return true;
  },
  showOnDisabled: () => false,

  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component.reloadData()
    }
  ],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "refereeClient",
    loader: fetchPartnerUsers,
    loaderRequire: (value, getValues) => getValues() && getValues()["client"] && getValues()["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues()["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let createdAt = {
  fieldKey: "createdAt",
  type: "date",
  label: strings.form.ticket.createdAt.label,
  disabled: (getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      return !credentials.roles.includes('ROLE_NYUKOM');
    }
    return true;
  },
  showOnDisabled: () => false,
  validators: []
}

let appointmentType = (type) => {
  let loader = null;
  switch (type) {
    case 'technical':
      loader = fetchTechnicalAppointmentTypes;
      break;
    case 'commercial':
      loader = fetchCommercialAppointmentTypes;
      break;
    case 'administrative':
      loader = fetchAdministrativeAppointmentTypes;
      break;
    default:
  }
  return {
    fieldKey: "appointmentType",
    label: strings.form.appointment.appointmentType.label,
    type: "dropdownEntity",
    showOnDisabled: () => false,

    validators: [],

    showEmpty: true,
    assertObjectDefaultValue: true,
    loader: {
      id: "appointmentType",
      loader: loader,
      loaderRequire: () => true,
      loaderArguments: () => []
    },

    adapter: {
      requirement: (data) => data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  }
}

let appointmentNumber = {
  fieldKey: "appointmentNumber",
  type: "text",
  label: strings.form.ticket.appointmentNumber.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = (type) => {
  return {
    formKey: "ticketSearch",
    fields: [
      number,
      ticketType(type),
      state,
      criticity,
      escalateLevel,
      referee,
      client,
      clientAccount,
      place,
      refereeClient,
      Object.assign({}, timeframe, {
        fieldKey: "createdAtTimeframe",
        label: `${strings.form.common.timeframe.label} ${strings.form.ticket.createdAtTimeframe.label}`
      }),
      createdAt,
      appointmentType(type),
      appointmentNumber
    ],
    extraValues: {
      credentials: () => store.getState().persisted.credentials
    }
  }
}
