import strings from '../../Localization/Localization';

import React from 'react';
import ServiceAmendment from '../../Component/ServiceAmendment/ServiceAmendment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { processTimeframe } from './common';

import {
  index,
  getFromId,
  getDependencyFromId,
  getIdentificationFromId,
  getTechnicalDataFromId,
  getContractsFromId,
  getPhonePackageApiFromId,
  getPhonePackageOrderFromId,
  getPhonePackageConsumptionFromId,
  getSpecificPhonePriceListFromId,
  getAmendmentFromId,
  getCentreonConfigurationFromId,

  editFromId,
  editTechnicalDataFromId,
  editContractsFromId,
  editPhonePackageApiFromId,
  editSpecificPhonePriceListFromId,
  editCentreonConfigurationFromId,
  // editFromId

  disableTransition,
  terminationTransition,
  cancelTerminationTransition,
  terminatedTransition,
  terminatedNoBillTransition,

  addPhonePackage,
  activatePhonePackageOrder,
  deactivatePhonePackageOrder
} from '../../API/Services';
import {
  editTransferFromSearch,
  editWipeCalculationFromSearch,
} from '../../API/PhoneCDRs';
import {
  getMetricsFromId
} from '../../API/CentreonService'
import {
  form,
  dependencyForm,
  phonePackageApiForm,
  phonePackageOrderForm,
  phonePackageConsumptionForm,
  specificPhonePriceListForm,
  technicalDataForm,
  activeContractForm,
  archiveContractForm,
  amendmentForm,
  centreonConfigurationsForm,

  addPhonePackageForm
} from '../Form/services';
import { form as searchForm } from '../Form/Search/services';

import { services as routes } from '../Route/routes';

import { fetchPhonePackageOrders } from '../../API/PhonePackageOrders';

import { config } from '../../API/config';

import {
  FLASHBAG_TYPE_SUCCESS,
  FLASHBAG_TYPE_DANGER
} from '../../Store/Action/flashbag';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.services,

    id: "service",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.serialNumber) {
        result["serial_number"] = search.serialNumber;
      }
      if(typeof search.state === 'object' && search.state.length > 0) {
        result["state"] = search.state.map((item) => item.value).toString();
      }
      if(search.deliveryDateTimeframe) {
        let deliveryDateTimeframe = processTimeframe(search.deliveryDateTimeframe);

        result["delivery_date_min"] = deliveryDateTimeframe.min;
        result["delivery_date_max"] = deliveryDateTimeframe.max;
      }
      if(search.deliveryDateMin) {
        result["delivery_date_min"] = search.deliveryDateMin;
      }
      if(search.deliveryDateMax) {
        result["delivery_date_max"] = search.deliveryDateMax;
      }
      if(search.updateDateTimeframe) {
        let updateDateTimeframe = processTimeframe(search.updateDateTimeframe);

        result["update_date_min"] = updateDateTimeframe.min;
        result["update_date_max"] = updateDateTimeframe.max;
      }
      if(search.updateDateMin) {
        result["update_date_min"] = search.updateDateMin;
      }
      if(search.updateDateMax) {
        result["update_date_max"] = search.updateDateMax;
      }
      if(search.orderNumber) {
        result["order_number"] = search.orderNumber;
      }
      if(search.quoteNumber) {
        result["quote_number"] = search.quoteNumber;
      }
      if(search.productName) {
        result["product_name"] = search.productName;
      }
      if(search.price) {
        result["price"] = search.price;
      }
      // if(search.priceType) {
      //   result["price_type"] = search.priceType;
      // }
      if(typeof search.partner === 'object') {
        result["partner_id"] = search.partner.id
      }
      if(typeof search.client === 'object') {
        result["client_id"] = search.client.id
      }
      if(typeof search.place === 'object') {
        result["place_id"] = search.place.id
      }
      if(typeof search.productFamily === 'object') {
        result["product_family_id"] = search.productFamily.id
      }
      if(typeof search.productSubFamily === 'object') {
        result["product_sub_family_id"] = search.productSubFamily.id
      }
      if(search.emptyApiKey) {
        result["empty_api_key"] = search.emptyApiKey;
      }
      return result;
    },

    availableItemDisplay:['tab', 'tabAlt'],
    itemDisplay: {
      tab: {
        headers: [
          {
            id: "state",
            label: <>&nbsp;&nbsp;<i className="icon-flag-1"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item['state']) {
                case 'active':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.service.state.options.active}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-normal item-index-status-rounded item-index-status-info"/>
                    </OverlayTrigger>
                  );
                case 'inactive':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.service.state.options.inactive}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-normal item-index-status-rounded item-index-status-danger"/>
                    </OverlayTrigger>
                  );
                case 'termination':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.service.state.options.termination}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-normal item-index-status-rounded item-index-status-warning"/>
                    </OverlayTrigger>
                  );
                case 'terminated':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.service.state.options.terminated}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-normal item-index-status-rounded item-index-status-danger"/>
                    </OverlayTrigger>
                  );
                default:
                  return item['state'];
              }

            }
          },
          {
            id: "order",
            label: "Commande",
            value: item => item["order"]?item["order"]["number"]:""
          },
          {
            id: "partner",
            label: "Partenaire",
            value: item => item["client"]["partner"]["name"]
          },
          {
            id: "place",
            label: "Site",
            value: item => item["place"]["name"]
          },
          {
            id: "product",
            label: "Produit",
            value: item => item["cart_item"]["product"]["name"]
          },
          {
            id: "number",
            label: "Numéro",
            value: "serial_number"
          },
        ]
      },
      tabAlt: {
        headers: [
          {
            label: <>&nbsp;&nbsp;<i className="icon-flag-1"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item['state']) {
                case 'active':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.service.state.options.active}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-normal item-index-status-rounded item-index-status-info"/>
                    </OverlayTrigger>
                  );
                case 'inactive':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.service.state.options.inactive}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-normal item-index-status-rounded item-index-status-danger"/>
                    </OverlayTrigger>
                  );
                case 'termination':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.service.state.options.termination}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-normal item-index-status-rounded item-index-status-warning"/>
                    </OverlayTrigger>
                  );
                case 'terminated':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.service.state.options.terminated}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-normal item-index-status-rounded item-index-status-danger"/>
                    </OverlayTrigger>
                  );
                default:
                  return item['state'];
              }

            }
          },
          {
            label: "Commande",
            value: item => item["order"]?item["order"]["number"]:""
          },
          {
            label: "Partenaire",
            value: item => item["client"]["partner"]["name"]
          },
          {
            label: "Site",
            value: item => item["place"]["name"]
          },
          {
            label: "Produit",
            value: item => item["cart_item"]["product"]["name"]
          },
          {
            label: "Numéro",
            value: "serial_number"
          },
        ],

        indexScheme: "index_w_dependency",
        additionalSearch: {
          no_depency_slave: true
        },

        computeAdditionalRowData: (item) => {
          //TODO add recursive dependencies
          let dependencySlaves = [];
          let dependencySlavesToCheck = item["dependency_slaves"];
          let serviceSlave = null;

          if(dependencySlavesToCheck) while(dependencySlavesToCheck.length > 0) {

            serviceSlave = Object.assign({
              is_dependency: true
            },
            dependencySlavesToCheck[0]["service_slave"]);
            dependencySlaves.push(serviceSlave);

            dependencySlavesToCheck.splice(0, 1);
            dependencySlavesToCheck.splice(dependencySlavesToCheck.length, 0, ...serviceSlave["dependency_slaves"]);
          }


          return dependencySlaves
        },
        rowCollapsedElement: () => null
        // rowCollapsedElement: (item) => {
        //   if(item["dependency_slaves"].length > 0) {
        //     return (
        //       <div>
        //         {item["dependency_slaves"].map((dependencySlave, i) => {
        //           return (
        //             <div key={i}>
        //               {dependencySlave["service_slave"]["cart_item"]["product"]["name"]}
        //             </div>
        //           )
        //         })}
        //       </div>
        //     )
        //   }
        //   return (
        //     <div/>
        //   )
        //   return null;
        // }
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.services,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: (item) => {
      return `${item["cart_item"]["product"]["name"]} ${item["order"]?item["order"]["number"]:''}`
    },

    apiGet: getIdentificationFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => (item["cart_item"]["product"]["img"])?config.file_path+item["cart_item"]["product"]["img"]:null,
      placeholderTitle: (item) => item["client"]["partner"]["name"].split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item["client"]["partner"]["name"] + " > " +  item["place"]["name"],
      additionalData1: (item) => item["cart_item"]["product"]["name"],
      additionalData2: (item) => {
        let state = null;
        switch (item.state) {
          case 'active':
            state = strings.form.service.state.options.active
            break;
          case 'inactive':
            state = strings.form.service.state.options.inactive
            break;
          case 'termination':
            state = strings.form.service.state.options.termination
            break;
          case 'terminated':
            state = strings.form.service.state.options.terminated
            break;
          default:
        }
        return `${item["order"]?item["order"]["number"]:''}${state?` - ${state}`:''}`
      },
      additionalData3: (item) => `${item["quantity"]} pour un total de ${item["total"]} €/HT${item["cart_item"]["discount"] !== 0?` au lieu de ${(item["cart_item"]["unit_price"] * item["quantity"])}€/HT`:""}`,
    },

    menu: (item) => {
      let buttons = [];
      if(item.state === 'active' && !item["under_contract"]) {
        buttons.push({
          id: 'disable',
          type: 'redirection',
          auth: 'ROLE_SERVICE_DISABLE',
          path: routes.routes.transition.createPath(item.id, 'disable'),
          addVerification: true,
          label: strings.service.tabs.buttons.disable,
          variant: "my-warning"
        });
      }
      else if(item.state === 'active' && item["under_contract"]) {
        buttons.push({
          id: 'termination',
          type: 'redirection',
          auth: 'ROLE_SERVICE_TERMINATION',
          path: routes.routes.transition.createPath(item.id, 'termination'),
          addVerification: true,
          label: strings.service.tabs.buttons.termination,
          variant: "my-danger"
        });
        buttons.push({
          id: 'amendment',
          type: 'redirection',
          auth: (button, item, credentials) => ['ROLE_NYUKOM', 'ROLE_SERVICE_AMENDMENT'].every((auth) => credentials.roles.includes(auth)),
          path: routes.routes.amendment.createPath(item.id),
          label: strings.service.tabs.buttons.amendment,
          variant: "my-validated"
        });
      }
      else if(item.state === 'termination') {
        buttons.push({
          id: 'cancelTermination',
          type: 'redirection',
          auth: 'ROLE_SERVICE_CANCEL_TERMINATION',
          path: routes.routes.transition.createPath(item.id, 'cancelTermination'),
          addVerification: true,
          label: strings.service.tabs.buttons.cancelTermination,
          variant: "my-validated"
        });
        buttons.push({
          id: 'terminate',
          type: 'redirection',
          auth: 'ROLE_SERVICE_TERMINATED',
          path: routes.routes.transition.createPath(item.id, 'terminate'),
          addVerification: true,
          label: strings.service.tabs.buttons.terminate,
          variant: "my-warning"
        });
        buttons.push({
          id: 'terminateNoBill',
          type: 'redirection',
          auth: 'ROLE_SERVICE_TERMINATED_NO_BILL',
          path: routes.routes.transition.createPath(item.id, 'terminateNoBill'),
          addVerification: true,
          label: strings.service.tabs.buttons.terminateNoBill,
          variant: "my-danger"
        });
      }

      if(item["phone_package_order"] && item["phone_package_order"]["active"] === true) {
        //deactivation of phone package
        buttons.push({
          id: 'deactivatePhonePackageOrder',
          type: 'request',
          auth: 'ROLE_SERVICE_PHONE_PACKAGE_ORDER_ACTIVATION',
          request: () => deactivatePhonePackageOrder(item["id"], item["phone_package_order"]["id"]),
          postRequestCallback: [
            {
              type: "flashbag",
              message: strings.service.tabs.buttons.deactivatePhonePackageOrder.flashbagMessage,
              flashbagType: FLASHBAG_TYPE_SUCCESS,
            },
            {
              type: "updateItem",
              key: "phone_package_order",
              value: (data) => {
                return {
                  id: item["phone_package_order"]["id"],
                  active: false
                }
              }
            }
          ],
          postRequestCallbackFailure: {
            type: "flashbag",
            message: strings.service.tabs.buttons.deactivatePhonePackageOrder.flashbagMessageFailure,
            flashbagType: FLASHBAG_TYPE_DANGER,
          },
          label: strings.service.tabs.buttons.deactivatePhonePackageOrder.label,
          addVerification: true,
          variant: "my-warning"
        });
        //transfer all cdrs between two dates
        buttons.push({
          id: 'transfer',
          type: 'modal',
          label: strings.service.tabs.buttons.transfer.label,
          auth: 'ROLE_CDR_TRANSFER',
          variant: "my-warning",
          request: (form) => editTransferFromSearch({
            subscription_id: item["id"],
            begin_at_min: form.beginAtMin,
            begin_at_max: form.beginAtMax
          }, {
            phone_package_order: form.phonePackageOrder
          }),
          postRequestCallback: [
            {
              type: "flashbag",
              message: strings.service.tabs.buttons.transfer.flashbagMessage,
              flashbagType: FLASHBAG_TYPE_SUCCESS,
            }
          ],
          postRequestCallbackFailure: {
            type: "flashbag",
            message: strings.service.tabs.buttons.transfer.flashbagMessageFailure,
            flashbagType: FLASHBAG_TYPE_DANGER,
          },
          modalData: () => {
            return {
              title: strings.service.tabs.buttons.transfer.title,
              form: {
                formKey: "transfer",
                fields: [
                  {
                    fieldKey: "beginAtMin",
                    type: "date",
                    label: strings.service.tabs.buttons.transfer.form.beginAtMin.label,
                    showOnDisabled: (values) => true,
                    validators: []
                  },
                  {
                    fieldKey: "beginAtMax",
                    type: "date",
                    label: strings.service.tabs.buttons.transfer.form.beginAtMax.label,
                    showOnDisabled: (values) => true,
                    validators: []
                  },
                  {
                    fieldKey: "phonePackageOrder",
                    type: "dropdownEntity",
                    label: strings.service.tabs.buttons.transfer.form.phonePackageOrder.label,
                    showOnDisabled: (values) => true,
                    validators: [],

                    loader: {
                      id: "PhonePackageOrders",
                      loader: fetchPhonePackageOrders,
                      loaderRequire: () => true,
                      loaderArguments: () => [],
                    },

                    adapter: {
                      requirement: (data) => data["id"] && data["phone_subscription"] && data["phone_subscription"]["order"] && data["phone_subscription"]["order"]["number"] && data["phone_subscription"]["cart_item"] && data["phone_subscription"]["cart_item"]["product"] && data["phone_subscription"]["cart_item"]["product"]["name"],
                      getValue: (data) => data["id"],
                      getLabel: (data) => `${data["phone_subscription"]["order"]["number"]}${data["phone_subscription"]["serial_number"]?" "+data["phone_subscription"]["serial_number"]:""} ${data["phone_subscription"]["cart_item"]["product"]["name"]}`,
                    },
                  }
                ]
              },
              successButton: strings.service.tabs.buttons.transfer.successButton,
              successButtonOnClick: (item) => {
                return {
                  request: true,
                  arguments: [item]
                }
              },
            }
          },
        })
        //ask to recalculate all cdrs between two dates
        buttons.push({
          id: 'wipeCalculation',
          type: 'modal',
          label: strings.service.tabs.buttons.wipeCalculation.label,
          auth: 'ROLE_CDR_WIPE_CALCULATION',
          variant: "my-warning",
          request: (form) => editWipeCalculationFromSearch({
            subscription_id: item["id"],
            begin_at_min: form.beginAtMin,
            begin_at_max: form.beginAtMax
          }, {}),
          postRequestCallback: [
            {
              type: "flashbag",
              message: strings.service.tabs.buttons.wipeCalculation.flashbagMessage,
              flashbagType: FLASHBAG_TYPE_SUCCESS,
            }
          ],
          postRequestCallbackFailure: {
            type: "flashbag",
            message: strings.service.tabs.buttons.wipeCalculation.flashbagMessageFailure,
            flashbagType: FLASHBAG_TYPE_DANGER,
          },
          modalData: () => {
            return {
              title: strings.service.tabs.buttons.wipeCalculation.title,
              form: {
                formKey: "wipeCalculation",
                fields: [
                  {
                    fieldKey: "beginAtMin",
                    type: "date",
                    label: strings.service.tabs.buttons.wipeCalculation.form.beginAtMin.label,
                    showOnDisabled: (values) => true,
                    validators: []
                  },
                  {
                    fieldKey: "beginAtMax",
                    type: "date",
                    label: strings.service.tabs.buttons.wipeCalculation.form.beginAtMax.label,
                    showOnDisabled: (values) => true,
                    validators: []
                  }
                ]
              },
              successButton: strings.service.tabs.buttons.wipeCalculation.successButton,
              successButtonOnClick: (item) => {
                return {
                  request: true,
                  arguments: [item]
                }
              },
            }
          },
        })
      }
      else if(item["phone_package_order"] && item["phone_package_order"]["active"] === false) {
        //reactivation of phone package
        buttons.push({
          id: 'activatePhonePackageOrder',
          type: 'request',
          auth: 'ROLE_SERVICE_PHONE_PACKAGE_ORDER_ACTIVATION',
          request: () => activatePhonePackageOrder(item["id"], item["phone_package_order"]["id"]),
          postRequestCallback: [
            {
              type: "flashbag",
              message: strings.service.tabs.buttons.activatePhonePackageOrder.flashbagMessage,
              flashbagType: FLASHBAG_TYPE_SUCCESS,
            },
            {
              type: "updateItem",
              key: "phone_package_order",
              value: (data) => {
                return {
                  id: item["phone_package_order"]["id"],
                  active: true
                }
              }
            }
          ],
          postRequestCallbackFailure: {
            type: "flashbag",
            message: strings.service.tabs.buttons.activatePhonePackageOrder.flashbagMessageFailure,
            flashbagType: FLASHBAG_TYPE_DANGER,
          },
          label: strings.service.tabs.buttons.activatePhonePackageOrder.label,
          addVerification: true,
          variant: "my-warning"
        });
      }
      else {
        buttons.push({
          id: 'addPhonePackage',
          type: 'redirection',
          auth: 'ROLE_SERVICE_PHONE_PACKAGE_ORDER_ACTIVATION',
          path: routes.routes.transition.createPath(item.id, 'addPhonePackage'),
          label: strings.service.tabs.buttons.addPhonePackage,
          variant: "my-warning"
        });
      }
      return buttons;
    },

    tabs: {
      main: {
        title: strings.service.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {
          data["item"]["client_account"] = data["item"]["client"];
          data["item"]["client"] = data["item"]["client_account"]["partner"];
          data["item"]["quote"] = data["item"]["order"]["quote"];
        },
        onSubmit: () => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: ['ROLE_NYUKOM']
      },
      dependency: {
        title: strings.service.tab.dependency,
        type: 'form',
        form: dependencyForm,

        onUpdateItem: (data) => {},
        // onSubmit: () => {},
        // postEdit: {
        //   type: 'tab',
        //   tabId: 'main'
        // },

        apiGet: getDependencyFromId,
        // apiEdit: editFromId,

        shouldDisplay: (item) => item && item["has_dependency"] === true,
        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        // editable: ['ROLE_NYUKOM']
      },
      phonePackageApi: {
        title: strings.service.tab.phonePackageApi,
        type: 'form',
        form: phonePackageApiForm,

        apiGet: getPhonePackageApiFromId,
        apiEdit: editPhonePackageApiFromId,
        onUpdateItem: (data) => {},

        postEdit: {
          type: 'tab',
          tabId: 'phonePackageApi'
        },

        shouldDisplay: (item) => item && item["phone_package_order"] && item["phone_package_order"]["active"] === true,
        role: ['ROLE_SERVICE_PHONE_PACKAGE_API'],
        editable: true
      },
      phonePackageOrder: {
        title: strings.service.tab.phonePackageOrder,
        type: 'form',
        form: phonePackageOrderForm,

        apiGet: getPhonePackageOrderFromId,

        onUpdateItem: (data) => {},

        shouldDisplay: (item) => item && item["phone_package_order"] && item["phone_package_order"]["active"] === true,
        role: ['ROLE_SERVICE_PHONE_PACKAGE_ORDER'],
        editable: false
      },
      specificPhonePriceList: {
        title: strings.service.tab.specificPhonePriceList,
        type: 'form',
        form: specificPhonePriceListForm,

        apiGet: getSpecificPhonePriceListFromId,
        apiEdit: editSpecificPhonePriceListFromId,

        onUpdateItem: (data) => {},

        shouldDisplay: (item) => item && item["phone_package_order"] && item["phone_package_order"]["active"] === true,
        role: ['ROLE_SERVICE_PHONE_PACKAGE_ORDER_SPECIFIC_PRICE_LIST'],
        editable: ['ROLE_SERVICE_PHONE_PACKAGE_ORDER_SPECIFIC_PRICE_LIST_EDIT']
      },
      phonePackageConsumption: {
        title: strings.service.tab.phonePackageConsumption,
        type: 'form',
        form: phonePackageConsumptionForm,

        apiGet: getPhonePackageConsumptionFromId,

        onUpdateItem: (data) => {
          if(data["item"]["phone_package_order"]) {
            if(data["item"]["phone_package_order"]["current_month_consumption"]) {
              // if(data["phone_package_order"]["current_month_consumption"]["specific_phone_number_used"] !== '-') {
              //
              // }
              if(data["item"]["phone_package_order"]["current_month_consumption"]["total_data_quantity_used"] !== '-') {
                data["item"]["phone_package_order"]["current_month_consumption"]["total_data_quantity_used"] = Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(data["item"]["phone_package_order"]["current_month_consumption"]["total_data_quantity_used"] / 1024 / 1024);
                data["item"]["phone_package_order"]["current_month_consumption"]["included_total_data_quantity"] = Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(data["item"]["phone_package_order"]["current_month_consumption"]["included_total_data_quantity"] / 1024 / 1024);
              }
              if(data["item"]["phone_package_order"]["current_month_consumption"]["total_phone_time_used"] !== '-') {
                data["item"]["phone_package_order"]["current_month_consumption"]["total_phone_time_used"] = Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(data["item"]["phone_package_order"]["current_month_consumption"]["total_phone_time_used"] / 60);
                data["item"]["phone_package_order"]["current_month_consumption"]["included_total_phone_time"] = Intl.NumberFormat('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 3 }).format(data["item"]["phone_package_order"]["current_month_consumption"]["included_total_phone_time"] / 60);
              }
            }
          }
        },

        shouldDisplay: (item) => item && item["phone_package_order"] && item["phone_package_order"]["active"] === true,
        role: ['ROLE_SERVICE_PHONE_PACKAGE_ORDER_CONSUMPTION'],
        editable: false
      },
      centreonConfiguration: {
        title: strings.service.tab.centreonConfiguration,
        type: 'form',
        form: centreonConfigurationsForm,

        onUpdateItem: (data) => {
          let centreonConfigurations = []
          if(data["item"]["centreon_services"]) {
            data["item"]["centreon_services"].forEach((centreonService, i) => {
              centreonConfigurations.push({
                centreon_service: centreonService,
                centreon_host: centreonService["host"]
              })
            });
          }
          data["item"]["centreon_configurations"] = centreonConfigurations;
        },
        onSubmit: (item) => {
          let centreonServices = [];
          if(item["centreon_configurations"]) {
            item["centreon_configurations"].forEach((configuration, i) => {
              centreonServices.push(configuration["centreon_service"]);
            });
          }
          item["centreon_services"] = centreonServices
        },
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getCentreonConfigurationFromId,
        apiEdit: editCentreonConfigurationFromId,

        role: ['ROLE_CENTREON_CONFIGURATION'],
        editable: ['ROLE_CENTREON_CONFIGURATION'] //TODO
      },
      centronChart: {
        title: strings.service.tab.centreonChart,
        type: 'centreon_chart',

        apiGet: getCentreonConfigurationFromId,
        apiCentreon: getMetricsFromId,

        role: ['ROLE_CENTREON'],
      },
      activeContract: {
        title: strings.service.tab.activeContract,
        type: 'form',
        form: activeContractForm,

        onUpdateItem: (data) => {
          data["item"]["active_contract"] = data["item"]["contracts"].find((item) => item.active);
        },
        onSubmit: (item) => {
          item["active_contract"] = undefined;
        },
        postEdit: {
          type: 'tab',
          tabId: 'activeContract'
        },

        shouldDisplay: (item) => item && item["under_contract"],
        apiGet: getContractsFromId,
        apiEdit: editContractsFromId,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: ['ROLE_NYUKOM']
      },
      archiveContract: {
        title: strings.service.tab.archiveContract,
        type: 'form',
        form: archiveContractForm,

        onUpdateItem: (data) => {},

        apiGet: getContractsFromId,

        shouldDisplay: (item) => item && item["under_contract"],
        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: false
      },
      amendments: {
        title: strings.service.tab.amendment,
        type: 'form',
        form: amendmentForm,

        onUpdateItem: (data) => {},

        apiGet: getAmendmentFromId,

        role: ['ROLE_SERVICE_AMENDMENT'],
        editable: false
      },
      technicalData: {
        title: strings.service.tab.technicalData,
        type: 'form',
        form: technicalDataForm,

        onUpdateItem: (data) => {
          data["item"]["_technical_data_values"] = []; //calculate from technical_data_values
          data["item"]["technical_data_values"].forEach((technicalDataValue) => {
            if(!data["item"]["_technical_data_values"][technicalDataValue["intern_id"]]) {
              data["item"]["_technical_data_values"][technicalDataValue["intern_id"]] = [];
            }
            data["item"]["_technical_data_values"][technicalDataValue["intern_id"]].push(technicalDataValue);
          });
        },
        onSubmit: (data) => {
          data["technical_data_values"] = data["_technical_data_values"].flat(); //calculate from _technical_data_values
        },
        postEdit: {
          type: 'tab',
          tabId: 'technicalData'
        },

        apiGet: getTechnicalDataFromId,
        apiEdit: editTechnicalDataFromId,

        role: ['ROLE_SERVICE_TECHNICAL_DATA'],
        editable: ['ROLE_SERVICE_TECHNICAL_DATA_EDIT']
      },
    }
  },
  transition: {
    disable: {
      type: 'direct',
      api: disableTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => routes.routes.show.createPath(data.item.id)
      },
    },
    termination: {
      type: 'direct',
      api: terminationTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => routes.routes.show.createPath(data.item.id)
      },
    },
    cancelTermination: {
      type: 'direct',
      api: cancelTerminationTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => routes.routes.show.createPath(data.item.id)
      },
    },
    terminate: {
      type: 'direct',
      api: terminatedTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => routes.routes.show.createPath(data.item.id)
      },
    },
    terminateNoBill: {
      type: 'direct',
      api: terminatedNoBillTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => routes.routes.show.createPath(data.item.id)
      },
    },
    addPhonePackage: {
      type: 'form',
      form: addPhonePackageForm,
      api: addPhonePackage,
      postTransition: {
        type: 'redirection',
        route: (data) => routes.routes.show.createPath(data.item.id)
      },
    },
  },
  custom: {
    amendment: {
      path: '/amendment/:id',
      createPath: (id) => `/amendment/${id}`,
      type: 'render',
      getRender: (props) => <ServiceAmendment {...props}/>,
    }
  }
}
