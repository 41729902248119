const ticketFormAnswer = {
  form: {
    label: "Formulaire",
    invalid: {
      assertEmpty: "Le formulaire ne peut pas être vide."
    }
  },
};

export default ticketFormAnswer;
