import React from 'react';

import { v4 as uuidv4 } from 'uuid';

import TableCell from '../../../Table/Body/TableCell';

/**
 * DropdownCell
 */
class DropdownCell extends TableCell {

  constructor(props) {
    super(props);

    this.listId = "table-cell-dropdown-"+uuidv4();
  }

  getPlaceholder() {
    if(this.props.additionalConfiguration.placeholder) {
      return this.props.additionalConfiguration.placeholder;
    }
    return null;
  }

  getValue() {
    return this.props.value;
  }

  onChange(event) {
    this.props.additionalConfiguration.onChange(this.props._rowId, this.props._valueId, event.target.value, this.props.data);
  }

  displayDataList() {
    if(this.props.additionalConfiguration.fieldList !== undefined) {
      return (
        <datalist id={this.listId}>
          {this.props.additionalConfiguration.fieldList.map((item, i) => {
            if(item.label) {
              return (
                <option key={i} value={item.value} label={item.label}>{item.label}</option>
              )
            }
            else {
              return (
                <option key={item.value} value={item.value}>{item.value}</option>
              )
            }
          })}
        </datalist>
      )
    }
    else {
      console.error("Table cell dropdown : fieldList is not defined");
      return (
        <datalist id={this.listId}>
        </datalist>
      );
    }
  }

  displayValue() {
    if(this.props.additionalConfiguration.editable) {
      return (
        <>
          <input type="text" className="cell-form cell-form-dropdown" placeholder={this.getPlaceholder()} value={this.getValue()} list={this.listId} onChange={(e) => this.onChange(e)}/>
          {this.displayDataList()}
        </>
      )
    }
    else {
      return `${this.props.value}`;
    }
  }

  displayInnerCell() {
    return <div className="cell-container cell-container-dropdown">{this.displayValue()}</div>;
  }
}

export default DropdownCell;
