import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getIdentificationFromId,
  getMastersFromId,
  getSlavesFromId,
  editFromId,
  editMastersFromId,
  editSlavesFromId,
  newItem
} from '../../API/ProductDependencies';
import { form, mastersForm, slavesForm, newForm } from '../Form/productDependencies';
import { form as searchForm } from '../Form/Search/productDependencies';

import { productDependencies as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.productDependencies,

    id: "productDependency",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_MARKETING_PRODUCT_DEPENDENCY_CREATE'
    },

    searchForm: searchForm,
    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            label: "Nom",
            value: "name"
          },
          {
            label: "Relation",
            value: (item) => {
              switch (item["quantity"]) {
                case 0:
                  return strings.form.productDependency.quantity.options.option0
                case 1:
                  return strings.form.productDependency.quantity.options.option1
                default:

              }
            }
          },
          {
            label: "Principal",
            value: (item) => item["reverse_mandatory"]?"Obligatoire":"Non obligatoire"
          },
          {
            label: "Accessoire",
            value: (item) => item["mandatory"]?"Obligatoire":"Non obligatoire"
          },
        ]
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.productDependencies,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getIdentificationFromId,
    onUpdateItem: (data) => {},

    extendedHeader: true,
    header: {
      imgUrl: (item) => null,
      placeholderTitle: (item) => item.name.split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item.name,
      additionalData1: (item) => '',
      additionalData2: (item) => ''
    },

    tabs: {
      main: {
        title: strings.productDependency.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_NYUKOM'],
        editable: ['ROLE_MARKETING_PRODUCT_DEPENDENCY_EDIT']
      },
      masters: {
        title: strings.productDependency.tab.masters,
        type: 'form',
        form: mastersForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getMastersFromId,
        apiEdit: editMastersFromId,

        role: ['ROLE_NYUKOM'],
        editable: ['ROLE_MARKETING_PRODUCT_DEPENDENCY_EDIT']
      },
      slaves: {
        title: strings.productDependency.tab.slaves,
        type: 'form',
        form: slavesForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'configuration'
        },

        apiGet: getSlavesFromId,
        apiEdit: editSlavesFromId,

        role: ['ROLE_NYUKOM'],
        editable: ['ROLE_MARKETING_PRODUCT_DEPENDENCY_EDIT']
      },
    }
  },
  new: {
    pageTitle: strings.productDependency.new.title,

    api: newItem,
    empty: {},
    form: newForm,
    onSubmit: (item) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
