
export function processTimeframe(timeframe) {
  if(timeframe) {
    let today = new Date();

    let firstDayOfTheWeek = null;
    let firstDayOfTheMonth = null;
    let firstDayOfTheQuarter = null;
    let firstDayOfTheYear = null;

    let resultMin = null;
    let resultMax = null;

    switch (timeframe.value) {
      case "currentDay":
        resultMin = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        resultMax = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        break;
      case "yesterday":
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate()-1);

        resultMin = `${yesterday.getFullYear()}/${('0'+(yesterday.getMonth()+1)).slice(-2)}/${('0'+yesterday.getDate()).slice(-2)}`;
        resultMax = `${yesterday.getFullYear()}/${('0'+(yesterday.getMonth()+1)).slice(-2)}/${('0'+yesterday.getDate()).slice(-2)}`;
        break;
      case "currentWeek":
        firstDayOfTheWeek = new Date();
        firstDayOfTheWeek.setDate(firstDayOfTheWeek.getDate()-(firstDayOfTheWeek.getDay()-1)%7);
        firstDayOfTheWeek.setHours(0);
        firstDayOfTheWeek.setMinutes(0);
        firstDayOfTheWeek.setSeconds(0);

        resultMin = `${firstDayOfTheWeek.getFullYear()}/${('0'+(firstDayOfTheWeek.getMonth()+1)).slice(-2)}/${('0'+firstDayOfTheWeek.getDate()).slice(-2)}`;
        resultMax = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        break;
      case "pastWeek":
        firstDayOfTheWeek = new Date();
        firstDayOfTheWeek.setDate(firstDayOfTheWeek.getDate()-(firstDayOfTheWeek.getDay()-1)%7);
        firstDayOfTheWeek.setHours(0);
        firstDayOfTheWeek.setMinutes(0);
        firstDayOfTheWeek.setSeconds(0);

        let firstDayOfLastWeek = new Date(firstDayOfTheWeek);
        firstDayOfLastWeek.setDate(firstDayOfLastWeek.getDate()-7);

        let lastDayOfLastWeek = new Date(firstDayOfTheWeek);
        lastDayOfLastWeek.setDate(lastDayOfLastWeek.getDate()-1);

        resultMin = `${firstDayOfLastWeek.getFullYear()}/${('0'+(firstDayOfLastWeek.getMonth()+1)).slice(-2)}/${('0'+firstDayOfLastWeek.getDate()).slice(-2)}`;
        resultMax = `${lastDayOfLastWeek.getFullYear()}/${('0'+(lastDayOfLastWeek.getMonth()+1)).slice(-2)}/${('0'+lastDayOfLastWeek.getDate()).slice(-2)}`;
        break;
      case "floatingWeek":
        let sameDayOfLastWeek = new Date();
        sameDayOfLastWeek.setDate(sameDayOfLastWeek.getDate()-6);

        resultMin = `${sameDayOfLastWeek.getFullYear()}/${('0'+(sameDayOfLastWeek.getMonth()+1)).slice(-2)}/${('0'+sameDayOfLastWeek.getDate()).slice(-2)}`;
        resultMax = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        break;
      case "currentMonth":
        firstDayOfTheMonth = new Date();
        firstDayOfTheMonth.setDate(1);
        firstDayOfTheMonth.setHours(0);
        firstDayOfTheMonth.setMinutes(0);
        firstDayOfTheMonth.setSeconds(0);

        resultMin = `${firstDayOfTheMonth.getFullYear()}/${('0'+(firstDayOfTheMonth.getMonth()+1)).slice(-2)}/${('0'+firstDayOfTheMonth.getDate()).slice(-2)}`;
        resultMax = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        break;
      case "pastMonth":
        firstDayOfTheMonth = new Date();
        firstDayOfTheMonth.setDate(1);
        firstDayOfTheMonth.setHours(0);
        firstDayOfTheMonth.setMinutes(0);
        firstDayOfTheMonth.setSeconds(0);

        let firstDayOfTheLastMonth = new Date(firstDayOfTheMonth);
        firstDayOfTheLastMonth.setMonth(firstDayOfTheLastMonth.getMonth()-1);

        let lastDayOfTheLastMonth = new Date(firstDayOfTheMonth);
        lastDayOfTheLastMonth.setDate(lastDayOfTheLastMonth.getDate()-1);

        resultMin = `${firstDayOfTheLastMonth.getFullYear()}/${('0'+(firstDayOfTheLastMonth.getMonth()+1)).slice(-2)}/${('0'+firstDayOfTheLastMonth.getDate()).slice(-2)}`;
        resultMax = `${lastDayOfTheLastMonth.getFullYear()}/${('0'+(lastDayOfTheLastMonth.getMonth()+1)).slice(-2)}/${('0'+lastDayOfTheLastMonth.getDate()).slice(-2)}`;
        break;
      case "floatingMonth":
        let sameDayOfTheLastMonth = new Date();
        sameDayOfTheLastMonth.setMonth(sameDayOfTheLastMonth.getMonth()-1);
        sameDayOfTheLastMonth.setDate(sameDayOfTheLastMonth.getDate()+1);

        resultMin = `${sameDayOfTheLastMonth.getFullYear()}/${('0'+(sameDayOfTheLastMonth.getMonth()+1)).slice(-2)}/${('0'+sameDayOfTheLastMonth.getDate()).slice(-2)}`;
        resultMax = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        break;
      case "currentQuarter":
        firstDayOfTheMonth = new Date();
        firstDayOfTheMonth.setDate(1);
        firstDayOfTheMonth.setHours(0);
        firstDayOfTheMonth.setMinutes(0);
        firstDayOfTheMonth.setSeconds(0);

        firstDayOfTheQuarter = new Date(firstDayOfTheMonth);
        firstDayOfTheQuarter.setMonth(firstDayOfTheQuarter.getMonth()-firstDayOfTheQuarter.getMonth()%3);

        resultMin = `${firstDayOfTheQuarter.getFullYear()}/${('0'+(firstDayOfTheQuarter.getMonth()+1)).slice(-2)}/${('0'+firstDayOfTheQuarter.getDate()).slice(-2)}`;
        resultMax = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        break;
      case "pastQuarter":
        firstDayOfTheMonth = new Date();
        firstDayOfTheMonth.setDate(1);
        firstDayOfTheMonth.setHours(0);
        firstDayOfTheMonth.setMinutes(0);
        firstDayOfTheMonth.setSeconds(0);

        firstDayOfTheQuarter = new Date(firstDayOfTheMonth);
        firstDayOfTheQuarter.setMonth(firstDayOfTheQuarter.getMonth()-firstDayOfTheQuarter.getMonth()%3);

        let firstDayOfTheLastQuarter = new Date(firstDayOfTheQuarter);
        firstDayOfTheLastQuarter.setMonth(firstDayOfTheLastQuarter.getMonth()-3);

        let lastDayOfTheLastQuarter = new Date(firstDayOfTheQuarter);
        lastDayOfTheLastQuarter.setDate(lastDayOfTheLastQuarter.getDate()-1);

        resultMin = `${firstDayOfTheLastQuarter.getFullYear()}/${('0'+(firstDayOfTheLastQuarter.getMonth()+1)).slice(-2)}/${('0'+firstDayOfTheLastQuarter.getDate()).slice(-2)}`;
        resultMax = `${lastDayOfTheLastQuarter.getFullYear()}/${('0'+(lastDayOfTheLastQuarter.getMonth()+1)).slice(-2)}/${('0'+lastDayOfTheLastQuarter.getDate()).slice(-2)}`;
        break;
      case "floatingQuarter":
        let sameDayOfTheLastQuarter = new Date();
        sameDayOfTheLastQuarter.setMonth(sameDayOfTheLastQuarter.getMonth()-3);
        sameDayOfTheLastQuarter.setDate(sameDayOfTheLastQuarter.getDate()+1);

        resultMin = `${sameDayOfTheLastQuarter.getFullYear()}/${('0'+(sameDayOfTheLastQuarter.getMonth()+1)).slice(-2)}/${('0'+sameDayOfTheLastQuarter.getDate()).slice(-2)}`;
        resultMax = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        break;
      case "currentYear":
        firstDayOfTheMonth = new Date();
        firstDayOfTheMonth.setDate(1);
        firstDayOfTheMonth.setHours(0);
        firstDayOfTheMonth.setMinutes(0);
        firstDayOfTheMonth.setSeconds(0);

        firstDayOfTheYear = new Date(firstDayOfTheMonth);
        firstDayOfTheYear.setMonth(0);

        resultMin = `${firstDayOfTheYear.getFullYear()}/${('0'+(firstDayOfTheYear.getMonth()+1)).slice(-2)}/${('0'+firstDayOfTheYear.getDate()).slice(-2)}`;
        resultMax = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        break;
      case "pastYear":
        firstDayOfTheMonth = new Date();
        firstDayOfTheMonth.setDate(1);
        firstDayOfTheMonth.setHours(0);
        firstDayOfTheMonth.setMinutes(0);
        firstDayOfTheMonth.setSeconds(0);

        firstDayOfTheYear = new Date(firstDayOfTheMonth);
        firstDayOfTheYear.setMonth(0);

        let firstDayOfTheLastYear = new Date(firstDayOfTheYear);
        firstDayOfTheLastYear.setFullYear(firstDayOfTheLastYear.getFullYear()-1);

        let lastDayOfTheLastYear = new Date(firstDayOfTheYear);
        lastDayOfTheLastYear.setDate(lastDayOfTheLastYear.getDate()-1);

        resultMin = `${firstDayOfTheLastYear.getFullYear()}/${('0'+(firstDayOfTheLastYear.getMonth()+1)).slice(-2)}/${('0'+firstDayOfTheLastYear.getDate()).slice(-2)}`;
        resultMax = `${lastDayOfTheLastYear.getFullYear()}/${('0'+(lastDayOfTheLastYear.getMonth()+1)).slice(-2)}/${('0'+lastDayOfTheLastYear.getDate()).slice(-2)}`;
        break;
      case "floatingYear":
        let sameDayOfTheLastYear = new Date();
        sameDayOfTheLastYear.setFullYear(sameDayOfTheLastYear.getFullYear()-1);
        sameDayOfTheLastYear.setDate(sameDayOfTheLastYear.getDate()+1);

        resultMin = `${sameDayOfTheLastYear.getFullYear()}/${('0'+(sameDayOfTheLastYear.getMonth()+1)).slice(-2)}/${('0'+sameDayOfTheLastYear.getDate()).slice(-2)}`;
        resultMax = `${today.getFullYear()}/${('0'+(today.getMonth()+1)).slice(-2)}/${('0'+today.getDate()).slice(-2)}`;
        break;
      default:

    }

    return {
      min: resultMin,
      max: resultMax
    }
  }
  return {
    min: null,
    max: null
  }
}
