import { SAVE_PROFIL } from '../Action/profil'

const initialState = {
  user: {},
}

export default function reduce(state = initialState, action){

  let newState
  switch (action.type) {
    case SAVE_PROFIL:
      newState = Object.assign({}, {
        user: action.user,
      })
      break;
    default:
      newState = state;
  }

  return newState;
}
