const phonePrice = {
  name: {
    label: "Nom",
  },
  price: {
    label: "Prix minute (HT)",
  },
  servicePrice: {
    label: "Prix établissement (HT)",
  },
  inout: {
    label: "Sens",
    options: {
      false: "Entrant",
      true: "Sortant"
    }
  },
  roaming: {
    label: "Roaming",
    options: {
      false: "Non",
      true: "Oui"
    }
  },
  roamingLikeAtHome: {
    label: "Roaming like at home",
    options: {
      false: "Non",
      true: "Oui"
    }
  },
  applicableFrom: {
    label: "Applicable après",
  },
  applicableUntil: {
    label: "Applicable jusque",
  },
  communicationType: {
    label: "Type",
  },
  phoneZoneSources: {
    label: "Zones sources",
  },
  phoneZoneDestinations: {
    label: "Zones destinations",
  },
  phoneZoneGroupSources: {
    label: "Groupes zones sources",
  },
  phoneZoneGroupDestinations: {
    label: "Groupes zones destinations",
  },
  basePhonePrices: {
    label: "Remplace les prix suivants"
  }
};

export default phonePrice;
