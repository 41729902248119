import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, InputGroup, Col } from 'react-bootstrap';
import '../../../css/form.css';

/**
 * FormFieldTriSwitch
 *
 * This class handles HTML form field.
 *
 * The particular field is for switch with an additional null value.
 *
 * Specific configuration attribute : none
 */
class FormFieldTriSwitch extends BasicFormField {

  getValue() {
    switch (super.getValue()) {
      case false:
        return 0;
      case null:
        return 1;
      case true:
        return 2;
      default:
      return 1;
    }
  }

  onChange(value) {
    switch (value) {
      case '0':
        return super.onChange(false);
      case '1':
        return super.onChange(null);
      case '2':
        return super.onChange(true);
      default:
        return super.onChange(null);
    }
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    switch (this.getValue()) {
      case 0:
        return false;
      case 1:
        return null;
      case 2:
        return true;
      default:
      return null;
    }
  }

  getColorClassValue() {
    switch (this.getValue()) {
      case 0:
        return "form-control-range-triswitch-false";
      case 2:
        return "form-control-range-triswitch-true";
      case 1:
      default:
      return "";
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          <InputGroup.Prepend
            className={`d-flex col-auto container-form-prepend container-form-switch ${this.props.disabled?"container-form-switch-disabled":""}`}>
            <Form.Control
              className={`form-control-range-triswitch ${this.getColorClassValue(this.getValue())}`}
              placeholder={this.props.label}
              aria-label={this.props.label}
              aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
              type="range"
              min={0}
              max={2}
              disabled={this.props.disabled || this.props.submitting}
              value={this.getValue()}
              onChange={(event) => this.onChange(event.target.value)}
              isValid={(this.props.disabled)?null:this.isValid()}
              isInvalid={(this.props.disabled)?null:this.isInvalid()}
            />
          </InputGroup.Prepend>
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

export default FormFieldTriSwitch;
