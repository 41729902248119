export const order_list = {
  id: "order_list-1",
  default: true,
  roles: ["ROLE_ORDER"],

  title: "Commande",
  type: "list",
  conf: {
    element: "order",
    headers: [
      "state",
      "number",
      "signatureDate",
      "place"
    ],
    filters: {
      state:{
        _parameterType:"value",
        _title:"",
        value:[
          {
            value:"o_w_validation",
            label:"En attente de validation"
          },
          {
            value:"o_c_period",
            label:"En période de rétractation"
          },
          {
            value:"o_w_a_payment",
            label:"En attente du paiement initial"
          },
          {
            value:"o_d_i_progress",
            label:"En cours de livraison"
          }
        ]
      },
    }
  },
  column: 3,
  row: 4
}
