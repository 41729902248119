const phonePackageOrder = {
  currentMonthConsumption: {
    label: "Consommation du mois en cours"
  },
  specificPhoneNumberUsed: {
    label: "Destinations distinctes",
  },
  totalDataQuantityUsed: {
    label: "Données utilisées (en Go)",
  },
  totalPhoneTimeUsed: {
    label: "Temps d'appel utilisé (en min)",
  },
  lastUpdate: {
    label: "Dernière MàJ",
  },
  phonePackageProduct: {
    label: "Forfait téléphonique"
  }
};

export default phonePackageOrder;
