const appointmentType = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Le nom ne peut pas être vide."
    }
  },
  ticketType: {
    label: "Type de ticket",
    invalid: {
      assertEmpty: "Le type de ticket ne peut pas être vide."
    }
  },
  onPremise: {
    label: "Sur Site"
  },
  askForSignature: {
    label: "Demande de signature"
  },
  description: {
    label: "Description",
    invalid: {
      assertEmpty: "La description ne peut pas être vide."
    }
  }
};

export default appointmentType;
