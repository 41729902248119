// import { color } from './Widget/color';
// import { title } from './Widget/title';

import { parameter } from './Widget/parameter';

import { list } from './Widget/list';
import { listStat } from './Widget/listStat';

import { objective } from './Widget/objective';

import { stateMachine } from './Widget/stateMachine';

import { supervision } from './Widget/supervision';

export const main = {
  widgets: [
    // color,
    // title,

    parameter,

    list,
    listStat,
    objective,

    stateMachine,

    supervision
  ]
}
