import React from 'react';

import MainLayout from '../Layout/MainLayout';

import ModularPage from '../ModularPage/ModularPage';

import image from '../../image/nk_logo_nk_nyukom.png';

/**
 */
class ModularPageTest extends React.Component {

  getPageConfiguration() {
    return {
      containerClassName: "",
      root: {
        type: "row",
        noGutters: true,
        partChildren: [
          {
            type: "col",
            xs: { span:3, offset:2 },
            partChildren: [
              {
                type: "margin",
                marginTop: "50px",
                marginBottom: "40px",
                marginLeft: "30px",
                marginRight: "10px",
                partChild: {
                  type: "text",
                  content: "Ahahaha"
                },
              },
              {
                type: "margin",
                marginTop: "50px",
                marginBottom: "40px",
                marginLeft: "10px",
                marginRight: "0px",
                partChild: {
                  type: "image",
                  content: image,
                  alt: ""
                },
              },
            ]
          },
          {
            type: "col",
            xs: { span:3, offset:2 },
            partChildren: [
              {
                type: "text",
                content: "Ahahaha"
              },
              {
                type: "image",
                content: "login_page/NK_extranet_login_slideshow_cloud.jpg",
                alt: ""
              }
            ]
          }
        ]
      }
    }
  }

  render() {
    return (
      <MainLayout>
        <ModularPage
          conf={this.getPageConfiguration()}/>
      </MainLayout>
    );
  }
}

export default ModularPageTest;
