import React from 'react';

import strings from '../../../Localization/Localization';

import { Container } from 'react-bootstrap';
import '../../../css/tabs.css';

import { connect } from 'react-redux';

import { withModalHandler } from '../../../HOC/ModalHandler';

import Loading from '../../Layout/Loading';

import Chart from '../../Echart/Chart';

import MyForm from '../../Form/MyForm';

import { chartOption } from '../../../MetaData/Echart/centreon';

/**
 * CentreonChartTab
 *
 * This component managed a entity tab with centreon chart(s)
 */
class CentreonChartTab extends React.Component {

  static defaultProps = {
    interval: 60,

    onUpdateItem: (data) => {},
  }

  constructor(props) {
    super(props);

    this.timer = {};

    //state of the component, including :
    // - preLoading : if the data is actually preLoading
    // - item : the item
    this.state = {
      preLoading: true,
      preLoadingError: false,

      item: null,

      metrics: {},

      settings: {}
    }

    this.getFromId = this.props.modalHandler.addVerificationWithCallback(props.apiGet, this.updateItem, this.updateItemFailure);

    this.loadMetrics = this.props.modalHandler.addVerificationWithCallback(props.apiCentreon, this.updateMetrics, this.updateMetricsFailure);
  }

  /**
   * Once the component is mounted, we can query for the information of the item
   * needed to be displayed in the component
   */
  componentDidMount() {
    this.getFromId(this.props.entityId);
  }

  componentWillUnmount() {
    Object.values(this.timer).forEach((timer, i) => {
      clearInterval(timer);
    });
  }

  /**
   * callback that collect the data from the fetch call and update the item
   *
   * @param data data from the fetch call
   */
  updateItem = (data) => {
    this.props.onUpdateItem(data);
    let metrics = {}
    data.item["centreon_services"].forEach((item, i) => {
      metrics[item["id"]] = {}
    });
    this.setState({
      preLoading: false,
      item: data.item,
      metrics: metrics
    }, () => this.initiateMetrics(true))
  }

  updateItemFailure = (msg) => {
    this.setState({
      preLoading: false,
      preLoadingError: true
    })
  }

  updateMetrics = (data) => {
    let metrics = Object.assign({}, this.state.metrics);
    let metric = data.item;
    metric.metrics.forEach((item) => {
      switch (item.metric) {
        case "traffic_in":
          item.data = item.data.map((data) => {
            return data / 1000 / 1000
          });
          break;
        case "traffic_out":
          item.data = item.data.map((data) => {
            return data / 1000 / 1000
          });
          break;
        default:

      }
    });
    metric.times = metric.times.map((time) => {
      let date = new Date(time);
      return `${date.getDate()}/${date.getMonth()+1}/${(''+date.getFullYear()).slice(-2)} ${('0'+date.getHours()).slice(-2)}:${('0'+date.getMinutes()).slice(-2)}`;
    });
    metrics[data.item.id] = data.item;
    this.setState({
      metrics: metrics
    })
  }

  updateMetricsFailure = (msg) => {}

  initiateMetrics = (forceLoad = false) => {
    this.state.item["centreon_services"].forEach((centreonService, i) => {
      if(this.timer[i]) {
        clearInterval(this.timer[i]);
      }

      this.timer[i] = setInterval(() => this.timeFetch(centreonService, i), this.props.interval * 1000);

      if(forceLoad) {
        this.timeFetch(centreonService, i)
      }
    });
  }

  timeFetch = (centreonService, i) => {
    this.loadMetrics(centreonService["id"], this.state.settings.timeframe);
  }

  onChangeSettings = (key, value) => {
    let settings = Object.assign({}, this.state.settings);
    settings[key] = value;
    this.setState({
      settings: settings
    }, () => this.initiateMetrics(true))
  }

  getChartOption = (metric) => {
    return chartOption(metric, this.props.theme);
  }

  displayCharts() {
    if(!this.state.preLoading && !this.state.postLoading) {
      return (
        <>
          {Object.values(this.state.metrics).map((metric, i) => {
            let options = this.getChartOption(metric);
            if(options) {
              return (
                <div key={i} className="entity-tabs-chart-container">
                  <Chart option={options}/>
                </div>
              )
            }
            return (
              <div key={i} className="entity-tabs-chart-container">
              </div>
            );
          })}
        </>
      )
    }
  }

  displaySettingsForm() {
    let settingsForm = {
      formKey: "settings",
      fields: [
        {
          fieldKey: "timeframe",
          type: "dropdown",
          label: strings.form.centreonChart.timeframe.label,
          showOnDisabled: () => true,
          validators: [],

          input: 'options',
          options: [
            {
              value: '3Hours',
              label: strings.form.centreonChart.timeframe.options._3Hours
            },
            {
              value: '24Hours',
              label: strings.form.centreonChart.timeframe.options._24Hours
            },
            {
              value: '7Days',
              label: strings.form.centreonChart.timeframe.options._7Days
            },
          ],
        }
      ]
    }
    return (
      <MyForm
        formKey={settingsForm.formKey}
        fields={settingsForm.fields}
        extraData={settingsForm.extraData}
        disabled={false}
        values={this.state.settings}
        onChangeField={this.onChangeSettings}
        displaySubmitButton={false}
      />
    )
  }

  displayContent() {
    return (
      <Container className="d-flex flex-column align-items-center">
        {this.displaySettingsForm()}
        {this.displayCharts()}
      </Container>
    )
  }

  displayLoading() {
    if(this.state.preLoading || this.state.postLoading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayLoading()}
        {this.displayContent()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.persisted.theme.name
})

export default connect(mapStateToProps)(withModalHandler(CentreonChartTab));
