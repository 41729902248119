const user = {
  tab: {
    main: "Profil",
    password: "Mot de passe",
    roles: "Rôle(s)",
    escalateLevels: "Escalade",
    partners: "Partenaire(s)",
    notifications: "Notification"
  },
  new: {
    title: "Nouvel utilisateur",
    steps: {
      generalData: "Informations Générales",
      profilsData: "Rôles et sociétés"
      // configuration: "Configuration",
      // price: "Prix",
      // dependency: "Définition accesoires",
      // specialConditionOfSale: "Conditions spéciales de vente"
    }
  },
  tabs: {
    buttons: {
      unlock: {
        label: "Débloquer",
        flashbagMessage: "L'utilisateur a été débloqué.",
        flashbagMessageFailure: "L'utilisateur n'a pas pu été débloqué."
      },
      deactivate: {
        label: "Désactiver",
        flashbagMessage: "L'utilisateur a été désactivé.",
        flashbagMessageFailure: "L'utilisateur n'a pas pu été désactivé."
      },
      reactivate: {
        label: "Réactiver",
        flashbagMessage: "L'utilisateur a été réactivé.",
        flashbagMessageFailure: "L'utilisateur n'a pas pu été réactivé."
      }
    }
  },
  search: {
    firstname: "Prénom",
    lastname: "Nom",
    mail: "Adresse mail",
    username: "Nom d'utilisateur",
    blocked: "Blocké",
    active: "Actif",
    partner: "Partenaire",
    partner_label: "Nom de l'entreprise"
  }
};

export default user;
