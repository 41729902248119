const ticket = {
  tab: {
    main: "Ticket",
    history: "Echanges",
    assignReferee: "Assigner référent NK"
  },
  history: {
    comment: "{0} le {1} a écrit :",
    file: "{0} le {1} à déposé :",
    appointment: "{0} le {1} à réalisé l'intervention {2} :",
    formAnswerEmpty: "Le formulaire {0} est demandé :",
    formAnswerFill: "Le formulaire {0} a été rempli :"
  },
  search: {
    number: "Numéro",
    requestType: "Type de ticket",
    state: "Etat",
    criticity: "Niveau d'urgence",
    referee: "Référent NK",
    client: "Client",
    clientAccount: "Compte client",
    place: "Site",
    refereeClient: "Référent client",
    createdAt: "Date de création",
    appointmentType: "Type d'intervention",
    appointmentNumber: "Numéro d'intervention"
  },
  tabs: {
    header: {
      escalate: "Niveau d'escalade: "
    },
    buttons: {
      workInProgress: "En traitement",
      wait: "Mettre en attente",
      close: "Clore le ticket",
      escalate: {
        label: "Escalader",
        flashbagMessage: "Votre demande d'escalade a été validé.",
        flashbagMessageFailure: "Votre demande d'escalade n'a pas pu aboutir.",
        title: "Demande d'escalade",
        form: {
          comment: {
            label: "Commentaire"
          }
        },
        successButton: "Valider la demande"
      },
      unfreezeGfrtNotification: {
        label: "Dégeler les notifications GTR",
        flashbagMessage: "Votre demande a été validé.",
        flashbagMessageFailure: "Votre demande n'a pas pu aboutir.",
      },
      freezeGfrtNotification: {
        label: "Geler les notification GTR",
        flashbagMessage: "Votre demande a été validé.",
        flashbagMessageFailure: "Votre demande n'a pas pu aboutir.",
      },
    }
  },
  new: {
    title: "Nouveau ticket",
    steps: {
      partnerData: "Partenaire",
      generalData: "Informations",
    }
  }
};

export default ticket;
