import strings from '../../../Localization/Localization';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.productFamily.name.label,
  showOnDisabled: () => false,
  validators: []
}

let activeOrder = {
  fieldKey: "activeOrder",
  type: "triswitch",
  label: strings.form.productFamily.activeOrder.label,
  showOnDisabled: () => false,
  validators: []
}

let activeQuote = {
  fieldKey: "activeQuote",
  type: "triswitch",
  label: strings.form.productFamily.activeQuote.label,
  showOnDisabled: () => false,
  validators: []
}

let activeTicket = {
  fieldKey: "activeTicket",
  type: "triswitch",
  label: strings.form.productFamily.activeTicket.label,
  showOnDisabled: () => false,
  validators: []
}

let rentable = {
  fieldKey: "rentable",
  type: "triswitch",
  label: strings.form.productFamily.rentable.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "productFamilySearch",
  fields: [
    name,
    activeOrder,
    activeQuote,
    activeTicket,
    rentable
  ]
}
