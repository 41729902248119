import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getIdentificationFromId,
  editFromId
} from '../../API/CommercialBonusThreshold';
import {
  form
} from '../Form/commercialBonusThresholds';
import { form as searchForm } from '../Form/Search/commercialBonusThresholds';

import { commercialBonusThreshold as routes } from '../Route/routes';


export const conf = {
  prefix: "commercialBonusThreshold",
  index: {
    pageTitle: strings.common.homepage.commercialBonusThreshold,

    id: "commercialBonusThreshold",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            id: "bonusType",
            label: "Type",
            value: item => {
              switch (item['bonus_type']) {
                case 0:
                  return strings.form.commercialBonus.bonusType.options.quotePunctual;
                case 1:
                  return strings.form.commercialBonus.bonusType.options.quoteAnnual;
                case 2:
                  return strings.form.commercialBonus.bonusType.options.serviceRecurrentNoEngagement;
                default:
                  return item['bonus_type'];
              }
            }
          },
          {
            id: "totalTfMin",
            label: "Seuil minimal",
            value: "total_tf_min",
            type: "price"
          },
          {
            id: "totalTfMin",
            label: "Seuil maximal",
            value: "total_tf_max",
            type: "price"
          },
          {
            id: "flatBonusValue",
            label: "Montant",
            value: "flat_bonus",
            type: "price"
          },
          {
            id: "appliedFrom",
            label: "Appliqué depuis",
            value: "applied_from"
          },
          {
            id: "appliedUntil",
            label: "Appliqué jusque",
            value: "applied_until"
          }
        ]
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.commercialBonusThreshold,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "commercialBonusThreshold",

    apiGet: getIdentificationFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.commercialBonusThreshold.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_COMMERCIAL_BONUS'],
        editable: ['ROLE_COMMERCIAL_BONUS_THRESHOLD'],
      }
    }
  },
}
