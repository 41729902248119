import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, Row, Col } from 'react-bootstrap';
import '../../../css/form.css';

import NotEmptyOrNullAssert from '../../../Form/Assert/NotEmptyOrNullAssert';

/**
 * DashboardFormFieldTime
 *
 * Specific configuration attribute : none
 */
class DashboardFormFieldTime extends BasicFormField {

  /**
   * @inheritdoc
   */
  getSpecificAsserts() {
    return {
      notEmptyOrNull: NotEmptyOrNullAssert,
    }
  }

  displayInput() {
    return (
      <Form.Control
        className="d-flex dashboard-field-time"
        placeholder={this.props.label}
        aria-label={this.props.label}
        aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
        type="time"
        disabled={this.props.disabled || this.props.submitting}
        value={this.getValue()}
        onChange={(event) => this.onChange(event.target.value)}
        isValid={(this.props.disabled)?null:this.isValid()}
        isInvalid={(this.props.disabled)?null:this.isInvalid()}
      />
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        <Row className="d-flex align-items-center no-gutters">
          {this.props.label !== ""?
            <Col xs="auto" className="no-gutters dashboard-field-time-label">
              {this.props.label}&nbsp;{this.displayValidating()}&nbsp;&nbsp;&nbsp;{this.shouldDisplayMiddlePart()?<>&nbsp;&nbsp;&nbsp;{this.displayMiddlePart()}</>:''}
            </Col>
          :undefined}
          {this.props.label === "" && this.shouldDisplayMiddlePart()?
            <>
              <Col xs={12} className="no-gutters dashboard-field-time-label">
                {this.displayValidating()}{this.displayMiddlePart()}
              </Col>
            </>
          :undefined}
          <Col xs={this.props.label === ""?12:""} className="no-gutters">
            {this.displayInput()}
          </Col>
        </Row>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    )
  }
}

export default DashboardFormFieldTime;
