import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem,
  request
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of users based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('users', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single user based on his ID
 *
 * @param id the of the user requested
 */
export function getFromId(id) {
  return baseGetFromId('users', id);
}

/**
 * Get a single user based on his ID
 *
 * @param id the of the user requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('users', id, 'identification');
}

/**
 * Get a single user based on his ID
 *
 * @param id the of the user requested
 */
export function getIndexFromId(id) {
  return baseGetFromId('users', id, 'index');
}

/**
 * Get a single user based on his ID
 *
 * @param id the of the user requested
 */
export function getPasswordFromId(id) {
  return baseGetFromId('users', id, 'password');
}

/**
 * Get a single user based on his ID
 *
 * @param id the of the user requested
 */
export function getProfilsFromId(id) {
  return baseGetFromId('users', id, 'profils');
}

/**
 * Get a single user based on his ID
 *
 * @param id the of the user requested
 */
export function getPartnersFromId(id) {
  return baseGetFromId('users', id, 'partners');
}

/**
 * Get a single user based on his ID
 *
 * @param id the of the user requested
 */
export function getNotificationFromId(id) {
  return baseGetFromId('users', id, 'notification');
}

/**
 * Get a single user based on his ID
 *
 * @param id the of the user requested
 */
export function getEscalateLevelFromId(id) {
  return baseGetFromId('users', id, 'escalate_level');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single user based on his ID
 *
 * @param id the of the user requested
 * @param data the new data of the user
 */
export function editFromId(id, data) {
  return baseEditFromId('users', id, data);
}

/**
 * Edit a single user based on his ID
 *
 * @param id the of the user requested
 * @param data the new data of the user
 */
export function editPasswordFromId(id, data) {
  return baseEditFromId('users', id, data, 'password');
}

/**
 * Edit a single user based on his ID
 *
 * @param id the of the user requested
 * @param data the new data of the user
 */
export function editProfilsFromId(id, data) {
  return baseEditFromId('users', id, data, 'profils');
}

/**
 * Edit a single user based on his ID
 *
 * @param id the of the user requested
 * @param data the new data of the user
 */
export function editPartnersFromId(id, data) {
  return baseEditFromId('users', id, data, 'partners');
}

/**
 * Edit a single user based on his ID
 *
 * @param id the of the user requested
 * @param data the new data of the user
 */
export function editNotificationFromId(id, data) {
  return baseEditFromId('users', id, data, 'notification');
}

/**
 * Edit a single user based on his ID
 *
 * @param id the of the user requested
 * @param data the new data of the user
 */
export function editEscalateLevelFromId(id, data) {
  return baseEditFromId('users', id, data, 'escalate_level');
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new user
 *
 * @param data the new data of the user
 */
export function newItem(data) {
  return baseNewItem('users', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

 /**
  * Fetch all users from the main company to serve in a dropdown form choice
  */
 export function fetchUsers(search) {
   return baseIndex('users', 1, 0, search);
 }

/**
 * Fetch all users from the main company to serve in a dropdown form choice
 */
export function fetchMainUsers() {
  let search = {
    no_partner: 1
  }
  return baseIndex('users', 1, 0, search, 'ASC', 'manager_index');
}

/**
 * Fetch all users from partners company to serve in a dropdown form choice
 */
export function fetchPartnerUsers(search) {
  return baseIndex('users', 1, 0, search);
}

/******************************************************************************
 * SPECIFIC FUNCTION                                                          *
 ******************************************************************************/

/**
 * unlock a user
 *
 * @param id the user to be exclude of the list of user
 * @param username the username to check
 */
export function unlockUser(id) {
  return request('/users/'+id+'/unlock', "POST");
}

/**
 * unlock a user
 *
 * @param id the user to be exclude of the list of user
 * @param username the username to check
 */
export function deactivateUser(id) {
  return request('/users/'+id+'/deactivate', "POST");
}

/**
 * unlock a user
 *
 * @param id the user to be exclude of the list of user
 * @param username the username to check
 */
export function reactivateUser(id) {
  return request('/users/'+id+'/reactivate', "POST");
}

/**
 * Check if a username is already in use or not
 * The user defined by the id is excluded from the check
 *
 * @param id the user to be exclude of the list of user
 * @param username the username to check
 */
export function checkUsername(id = null, username = null) {
  let params = {
    id: id,
    username: username
  };
  return request('/users/check_username', "POST", params);
}

/**
 * Get a random password
 */
export function randomPassword() {
  return request('/users/password_randomizer', "GET");
}
