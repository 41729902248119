import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import {
  orders as ordersRoutes
} from '../Route/routes';


let numberWithLink = {
  fieldKey: "number",
  type: "text",
  label: strings.form.order.number.label,

  disabledLink: (value, getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    if(credentials && credentials.roles.includes("ROLE_ORDER")) {
      return getValues(0) && getValues(0)["id"]?ordersRoutes.routes.show.createPath(getValues(0)["id"]):null;
    }
    return false;
  },

  validators: []
}

let validationDate = {
  fieldKey: "validation_date",
  type: "date",
  label: strings.form.order.validationDate.label,
  validators: []
}

let deliveryDate = {
  fieldKey: "delivery_date",
  type: "date",
  label: strings.form.order.deliveryDate.label,
  validators: []
}

let subFamilyRecap = {
  fieldKey: "sub_family_recap",
  label: "",
  type: "array",

  validators: [],

  subField: {
    type: "text",
    label: strings.form.order.subFamily.label,

    validators: []
  }
}

export const identificationForm = {
  formKey: "order",
  fields: [
    numberWithLink,
    validationDate,
    deliveryDate,
    subFamilyRecap
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
