const appointmentType = {
  product: {
    label: "Produit",
    invalid: {
      assertEmpty: "Le produit ne peut pas être vide."
    }
  },
  quantity: {
    label: "Quantité",
    invalid: {
      assertEmpty: "La quantité ne peut pas être vide."
    }
  },
};

export default appointmentType;
