const user = {
  username: {
    label: "Nom d'utilisateur",
    invalid: {
      assertEmpty: "Ce nom d'utilisateur ne doit pas être vide.",
      assertUnique: "Ce nom d'utilisateur est déjà utilisé."
    },
  },
  firstname: {
    label: "Prénom",
    invalid: {
      assertEmpty: "Veuillez écrire votre prénom.",
    }
  },
  lastname: {
    label: "Nom",
    invalid: {
      assertEmpty: "Veuillez écrire votre nom."
    }
  },
  mail: {
    label: "Adresse mail",
  },
  img: {
    label: "Photo",
  },
  phoneNumber: {
    label: "Numéro fixe",
  },
  mobilePhoneNumber: {
    label: "Numéro mobile",
  },
  phoneNumbers: {
    label: "Numéro de téléphone",
    invalid: {
      assertEmpty: "Veuillez indiquer au moins un numéro de téléphone."
    },
  },
  password: {
    label: "Mot de passe",
    invalid: {
      assertEmpty: "Veuillez écrire votre mot de passe.",
      assertEqual: "Les deux mots de passe ne sont pas identique."
    }
  },
  passwordVerification: {
    label: "Vérification du Mot de passe",
  },
  roles: {
    label: "Rôle",
    labelNonEditable: "Rôle non modifiable",
    labelEditable: "Rôle modifiable",
    invalid: {
      assertEmpty: "Un utilisateur doit avoir au moins un rôle."
    },
    allowMoreField: {
      addLabel: "Ajouter un rôle",
      removeLabel: "Enlever un rôle",
      removingLabel: "Supprimer"
    },
  },
  partner: {
    label: "Partenaire",
    invalid: {
      assertEmpty: "Veuillez remplir la case partenaire."
    }
  },
  partnerAccount: {
    label: "Compte client",
    invalid: {
      assertEmpty: "Veuillez remplir la case compte client."
    }
  },
  userPartners: {
    label: "Entreprise",
    allowMoreField: {
      addLabel: "Ajouter une entreprise",
      removeLabel: "Enlever une entreprise",
      removingLabel: "Supprimer"
    },
  },
  userPartner: {
    label: "Entreprise #key#",
  },
  notifiedByMail: {
    label: "Recevoir des notifications Mail."
  },
  notifiedByWeb: {
    label: "Recevoir des notifications Web."
  },
  notifiedByFlags: {
    label: "Détail des notifications",
    header: {
      name: "Nom",
      notifiedByMail: "Par mail",
      notifiedByWeb: "Par web"
    },
    row: {
      bill: "Facture",
      commercial: "Commercial",
      technical: "Technique",
      installation: "Commande",
      administrative: "Administration",
      service_lifecycle: "Cycle de vie des services",
      ticket: "Echange (ticket)",
      admin_report: "Rapport site",
      other: "Autre"
    }
  },
  blocked: {
    label: "Utilisateur bloqué",
  },
  active: {
    label: "Utilisateur actif"
  },
  technicalEscalateLevel: {
    label: "Niveau technique"
  },
  commercialEscalateLevel: {
    label: "Niveau commercial"
  },
  administrativeEscalateLevel: {
    label: "Niveau administratif"
  },
  defaultEscalateLevel: {
    label: "Niveau par défaut",
    options: {
      _1: "Niveau 1",
      _2: "Niveau 2",
      _3: "Niveau 3"
    }
  },
  productSubFamilylevel: {
    level1: "Niveau 1",
    level2: "Niveau 2",
    level3: "Niveau 3",
  }
};

export default user;
