const bill = {
  index: {
    tab: {
      headers: {
        filename: "Rapport",
        createdAt: "Date",
      }
    }
  },
  show: {
    tab: {
      main: "Facturation",
      bills: "Factures"
    },
  },
  search: {
    createdAtMin: "Date (min)",
    createdAtMax: "Date (max)"
  }
};

export default bill;
