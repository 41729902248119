export const commercial_ticket_stat = {
  id: "commercial_ticket_stat-1",
  default: true,
  roles: [],

  title: "Stat ticket",
  type: "listStat",
  conf: {
    element: "commercialTicket",
    stats: [
      {
        title:"Qté demande de devis",
        stat:"quantity",
        attribute:"id",
        filters:{
          state:{
            _parameterType:"value",
            _title:"",
            value:[
              {
                value:"new",
                label:"Nouveau"
              },
              {
                value:"waiting",
                label:"En attente"
              },
              {
                value:"in_progress",
                label:"En cours de progrès"
              }
            ]
          },
          requestType:[
            {
              id:8,
              name:"Demande de devis"
            }
          ],
        }
      },
      {
        title:"Qté demande de RDV",
        stat:"quantity",
        attribute:"id",
        filters:{
          state:{
            _parameterType:"value",
            _title:"",
            value:[
              {
                value:"new",
                label:"Nouveau"
              },
              {
                value:"waiting",
                label:"En attente"
              },
              {
                value:"in_progress",
                label:"En cours de progrès"
              }
            ]
          },
          requestType:[
            {
              id:9,
              name:"Demande de RDV"
            }
          ],
        }
      },
      {
        title:"Qté demande d'audit",
        stat:"quantity",
        attribute:"id",
        filters:{
          state:{
            _parameterType:"value",
            _title:"",
            value:[
              {
                value:"new",
                label:"Nouveau"
              },
              {
                value:"waiting",
                label:"En attente"
              },
              {
                value:"in_progress",
                label:"En cours de progrès"
              }
            ]
          },
          requestType:[
            {
              id:10,
              name:"Demande d'audit"
            }
          ],
        }
      },
    ]
  },
  column: 2,
  row: 2
}
