export const Colors = [
  "#21BAED", //nk blue
  "#8CD9C2", //teal
  "#99D98C", //green
  "#D9ED92", //light green
  "#FFF601", //yellow
  "#E9AF23", //light orange
  "#E97723", //orange
  "#7901FF", //purple
]

export const FontFamily = "Lato-Regular";
