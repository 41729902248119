import React from 'react';

import { useRef } from 'react';

import { ItemTypes } from '../../../DnD/Constant';
import { useDrop } from 'react-dnd';

export default function DashboardGridSlot({ position, replaceBox, children }) {

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_GRID_WIDGET,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragPosition = item.position;
      const hoverPosition = position;
      // Don't replace items with themselves
      if (dragPosition === hoverPosition) {
        return;
      }
      // Time to actually perform the action
      let valid = replaceBox(item.id, dragPosition, hoverPosition);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      if(valid === true) item.position = hoverPosition;
    },
  });

  drop(ref);

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={{
        height: '100%',
        width: '100%',
      }}>
      {children}
    </div>
  )
}
