import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuid } from 'uuid';

import { addVerificationWithCallbackAction, addVerificationAction } from '../Store/Action/modalHandler';

export function withModalHandler(WrappedComponent) {
  class ModalHandlerSubscriber extends React.Component {

    uuid = uuid();

    addVerificationWithCallback = (call, callback, callbackOnFailure, singleCall = false, callId = null) => {
      if(singleCall === true && callId === null) {
        callId = uuid()
      }
      let fn = function() {
        this.props.dispatch(addVerificationWithCallbackAction(call, callback, callbackOnFailure, arguments, singleCall, this.uuid, callId));
      }
      return fn.bind(this);
    }

    addVerification = (call) => {
      let fn = function() {
        this.props.dispatch(addVerificationAction(call, arguments));
      }
      return fn.bind(this);
    }

    render() {
      const modalHandler = {
        addVerificationWithCallback: this.addVerificationWithCallback,
        addVerification: this.addVerification,
      }

      const {forwardedRef, ...rest} = this.props;

      return (
        <WrappedComponent ref={forwardedRef} {...rest} modalHandler={modalHandler}/>
      );
    }
  }

  const Connected = connect()(ModalHandlerSubscriber);

  return React.forwardRef((props, ref) => {
    return <Connected {...props} forwardedRef={ref}/>
  })
}
