import strings from '../../../Localization/Localization';

import { fetchPlaces } from '../../../API/Places';

let oldQuantity = {
  fieldKey: "oldQuantity",
  type: "text",
  label: strings.form.amendment.oldQuantity.label,
  disabled: true,
  validators: []
}

let newQuantity = {
  fieldKey: "newQuantity",
  type: "text",
  label: strings.form.amendment.newQuantity.label,
  validators: [
    {
      id: 'assertFormat',
      type: 'regex',
      conf: {
        regex: /^[0-9]{1,3}$/
      },
      invalidFeedback: strings.form.amendment.newQuantity.invalid.assertFormat,
      defaultValue: false
    },
  ]
}

let quantity = {
  fieldKey: "quantity",
  type: "text",
  label: strings.form.amendment.quantity.label,
  validators: [
    {
      id: 'assertFormat',
      type: 'regex',
      conf: {
        regex: /^[0-9]{1,3}$/
      },
      invalidFeedback: strings.form.amendment.quantity.invalid.assertFormat,
      defaultValue: false
    },
  ]
}

let oldPlace = {
  fieldKey: "old_place",
  type: "dropdownEntity",
  label: strings.form.amendment.oldPlace.label,
  disabled: true,
  validators: [],

  loader: null,

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let place = {
  fieldKey: "place",
  label: strings.form.amendment.place.label,
  type: "dropdownEntity",
  disabled: (getValues, getExtraValues) => getExtraValues().client === undefined || getExtraValues().client === null || getExtraValues().client.id === undefined,

  validators: [],

  showEmpty: true,
  loader: {
    id: "partnerPlaces",
    loader: fetchPlaces,
    loaderRequire: (value, getValues, getExtraValues) => getExtraValues() && getExtraValues()["client"] && getExtraValues()["client"]["id"],
    loaderArguments: (value, getValues, getExtraValues) => {
      return [{
        partner_id: getExtraValues()["client"]["id"],
        active: 1
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let oldPrice = {
  fieldKey: "old_price",
  type: "price",
  label: strings.form.amendment.oldPrice.label,
  disabled: true,
  validators: []
}

let price = {
  fieldKey: "price",
  type: "price",
  label: strings.form.amendment.price.label,
  disabled: () => false,

  validators: [],
}

let type = {
  fieldKey: "type",
  type: "dropdown",
  label: strings.form.amendment.type.label,
  disabled: true,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 's',
      label: strings.form.amendment.type.options.s
    },
    {
      value: 'c',
      label: strings.form.amendment.type.options.c
    }
  ],
}

let date = {
  fieldKey: "date",
  type: "date",
  label: strings.form.amendment.date.label,
  disabled: (getValues) => getValues(0).type !== 's',

  validators: [],

  min: () => {
    let date = new Date();
    let year = date.getFullYear();
    let month =  date.getMonth()+1;
    if(month < 10) month = "0" + month;
    let day = date.getDate();
    if(day < 10) day = "0" + day;
    return `${year}-${month}-${day}`;
  }
}

export const quantityForm = {
  formKey: "amendment",
  fields: [
    oldQuantity,
    newQuantity,
    quantity
  ]
}

export const placeForm = {
  formKey: "amendment",
  fields: [
    oldPlace,
    place
  ]
}

export const priceForm = {
  formKey: "amendment",
  fields: [
    oldPrice,
    price
  ]
}

export const resultForm = {
  formKey: "amendment",
  fields: [
    type,
    date
  ]
}
