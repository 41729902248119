import React from 'react';

import strings from '../../../Localization/Localization';

import MainLayout from '../../Layout/MainLayout';

import { withModalHandler } from '../../../HOC/ModalHandler';

import { connect } from 'react-redux';
import { addBreadcrumbAction } from '../../../Store/Action/breadcrumb';

import { withRouter } from 'react-router-dom';

import { getYousignFromId } from '../../../API/Quotes';

import Sign from '../Sign';

import Loading from '../../Layout/Loading';

import { quotes as routes } from '../../../MetaData/Route/routes';

/**
 * YousignQuote
 */
class YousignQuote extends React.Component {

  constructor(props) {
    super(props);

    this.getQuote = this.props.modalHandler.addVerificationWithCallback(getYousignFromId, this.postGet);

    this.state = {
      loading: true,
      quote: null
    }
  }

  componentDidMount() {
    this.getQuote(this.props.match.params.id);

    console.log(strings.yousign);

    this.props.dispatch(addBreadcrumbAction(window.location.pathname, strings.yousign.quote.breadcrumb));
  }

  postGet = (data) => {
    this.setState({
      quote: data.item,
      loading: false
    })
  }

  displayProcedure() {
    if(this.state.loading === false) {
      return (
        <Sign procedure={this.state.quote.yousign_procedure}/>
      )
    }
  }

  displayTitle() {
    if(this.state.loading === false) {
      return (
        <h3 className="page-title"><a href={routes.routes.show.createPath(this.state.quote["id"])}><i className="icon-chevron-left"/></a>  Signature devis</h3>
      )
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <MainLayout>
        {this.displayTitle()}
        {this.displayLoading()}
        {this.displayProcedure()}
      </MainLayout>
    );
  }
}

export default connect()(withRouter(withModalHandler(YousignQuote)));
