const billing = {
  filename: {
    label: "Rapport",
  },
  createdAt: {
    label: "Date"
  },
  createdAtMin: {
    label: "Date (min)"
  },
  createdAtMax: {
    label: "Date (max)"
  },
  bills: {
    label: "Factures"
  }
};

export default billing;
