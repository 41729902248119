import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

 /******************************************************************************
  * INDEX FUNCTION                                                             *
  ******************************************************************************/

 /**
  * Get a list of partner types based on a pagination system
  *
  * @param page the page requested
  * @param itemPerPage the number of item in the page requested
  */
 export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
   return baseIndex('partner_types', page, itemPerPage, search, order, scheme);
 }

 /******************************************************************************
  * GETTER FUNCTION                                                            *
  ******************************************************************************/

 /**
  * Get a single partner type based on his ID
  *
  * @param id the of the partner type requested
  */
 export function getFromId(id) {
   return baseGetFromId('partner_types', id);
 }

 /******************************************************************************
  * SETTER FUNCTION                                                            *
  ******************************************************************************/

 /**
  * Edit a single partner type based on his ID
  *
  * @param id the of the partner requested
  * @param data the new data of the partner type
  */
 export function editFromId(id, data) {
   return baseEditFromId('partner_types', id, data);
 }

 /******************************************************************************
  * NEW FUNCTION                                                               *
  ******************************************************************************/

 /**
  * Create a new partner type
  *
  * @param data the new data of the partner type
  */
 export function newItem(data) {
   return baseNewItem('partner_types', data);
 }

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of partner types based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function fetchPartnerTypes(){
  return baseIndex('partner_types', 1, 0);
}
