const accountingProduct = {
  index: {
    tab: {
      headers: {
        number: "Numéro",
        title: "Titre",
      }
    }
  },
  tab: {
    main: "Compte",
    edit: "Editer",
  },
  search: {
    number: "Numéro",
    title: "Titre",
  },
  new: {
    title: "Nouveau compte général"
  }
};

export default accountingProduct;
