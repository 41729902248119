import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of phone zone groups based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('phone_zone_groups', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single phone zone group based on his ID
 *
 * @param id the of the phone zone group requested
 */
export function getFromId(id) {
  return baseGetFromId('phone_zone_groups', id);
}

/**
 * Get a single phone zone group based on his ID
 *
 * @param id the of the phone zone group requested
 */
export function getZonesFromId(id) {
  return baseGetFromId('phone_zone_groups', id, 'zone');
}

/**
 * Get a single phone zone group based on his ID
 *
 * @param id the of the phone zone group requested
 */
export function getPriceSourcesFromId(id) {
  return baseGetFromId('phone_zone_groups', id, 'price_source');
}

/**
 * Get a single phone zone group based on his ID
 *
 * @param id the of the phone zone group requested
 */
export function getPriceDestinationsFromId(id) {
  return baseGetFromId('phone_zone_groups', id, 'price_destination');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single phone zone group based on his ID
 *
 * @param id the of the phone zone group requested
 * @param data the new data of the phone zone group
 */
export function editFromId(id, data) {
  return baseEditFromId('phone_zone_groups', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new phone zone group
 *
 * @param data the new data of the phone zone group
 */
export function newItem(data) {
  return baseNewItem('phone_zone_groups', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all phone zone groups to serve in a dropdown form choice
 */
export function fetchPhoneZoneGroups() {
  return baseIndex('phone_zone_groups', 1, 0);
}
