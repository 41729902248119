import React from 'react';

import MainLayout from '../Layout/MainLayout';
import Index from '../ItemIndex/ItemIndex';

import { withRouter } from "react-router-dom";

/**
 * ItemIndex
 *
 * This component represent a page.
 *
 * This component use a configuration file to create an index.
 *
 * For the index to work properly, the configuration file should define :
 * - api : a function that will feed the items to this component
 * - label : a function that will transform the item into a displayable label
 * - redirect : whether there should be a redirection on click
 * - path : the path to go to when the item is clicked
 */
class ItemIndex extends React.Component {

  getPreFilter() {
    if(this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.filters) {
      return this.props.history.location.state.filters;
    }
    return null;
  }

  /**
   * define how an item should react when clicked
   *
   * @param id the id of the item clicked
   */
  onClickItem = (id, item) => {
    if(this.props.conf.onClickItem.redirect) {
      this.props.history.push(this.props.conf.onClickItem.path(id, item));
    }
  }

  /**
   * define how an item should react when clicked
   *
   * @param id the id of the item clicked
   */
  entityPath = (id, item) => {
    if(typeof this.props.conf.onClickItem.path === 'function') {
      return this.props.conf.onClickItem.path(id, item);
    }
    return '#';
  }

  /**
   * Main render method for React Component
   *
   * This component represent a page of our application so we use the main
   * layout of the application
   */
  render() {
    return (
      <MainLayout>
        <Index
          pageTitle={this.props.conf.pageTitle}
          id={this.props.conf.id}
          index={this.props.conf.api}
          searchForm={this.props.conf.searchForm}
          computeSearch={this.props.conf.computeSearch}
          export={this.props.conf.export}
          newLink={this.props.conf.newLink}
          availableItemDisplay={this.props.conf.availableItemDisplay}
          itemDisplay={this.props.conf.itemDisplay}
          onClickItem={this.onClickItem}
          entityPath={this.entityPath}
          cartItem={this.props.conf.cartItem}
          buttons={this.props.conf.buttons}
          sizing={this.props.conf.sizing}

          prefilter={this.getPreFilter()}/>
      </MainLayout>
    );
  }
}

export default withRouter(ItemIndex);
