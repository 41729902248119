import React from 'react';

import BasicWidget from './BasicWidget';

import TextResizer from '../../Basic/TextResizer';
import CircleProgress from '../../Basic/CircleProgress';

import Loading from '../../Layout/Loading';

import '../../../css/dashboard/widget_objective.css';

/**
 * WidgetObjective
 */
class WidgetObjective extends BasicWidget {

  static defaultProps = {
    interval: 60,

    conf: {}
  }

  constructor(props) {
    super(props);

    this.requestValue = null;
    this.requestMax = null;

    this.timerValue = null;
    this.timerMax = null;

    this.state = {
      loadingValue: false,
      loadingMax: false,

      value: -1,
      max: -1,
    }
  }

  componentDidMount() {
    this.initiateApi()
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.parameterBag !== this.props.parameterBag || prevProps.conf !== this.props.conf) {
      this.initiateApi(true)
    }
    else {
      this.initiateApi()
    }
  }

  componentWillUnmount() {
    if(this.timerValue !== null) {
      clearInterval(this.timerValue);
    }
    if(this.timerMax !== null) {
      clearInterval(this.timerMax);
    }
  }

  postChangeArgument() {
    this.initiateApi(true);
  }

  initiateApi(forceFetch = false) {
    let forceFetchValue = false;
    this.requestValue = {
      fetch: this.props.modalHandler.addVerificationWithCallback(this.props.widgetType.elementSettings[this.props.conf.element].fetch, this.requestValueCallback, this.requestValueCallbackFailure, true)
    }
    if(this.timerValue === null && this.props.conf.valueType === "statistic") {
      this.timerValue = setInterval(this.timeFetchValue, this.props.interval * 1000);
      forceFetchValue = true;
    }
    else if(this.timerValue !== null && this.props.conf.valueType !== "statistic") {
      clearInterval(this.timerValue);
    }
    if(this.props.conf.valueType === "statistic" && (forceFetch || forceFetchValue)) {
      this.timeFetchValue();
    }

    let forceFetchMax = false;
    this.requestMax = {
      fetch: this.props.modalHandler.addVerificationWithCallback(this.props.widgetType.elementSettings[this.props.conf.element].fetch, this.requestMaxCallback, this.requestMaxCallbackFailure, true)
    }
    if(this.timerMax === null && this.props.conf.maxType === "statistic") {
      this.timerMax = setInterval(this.timeFetchMax, this.props.interval * 1000);
      forceFetchMax = true;
    }
    else if(this.timerMax !== null && this.props.conf.maxType !== "statistic") {
      clearInterval(this.timerMax);
    }
    if(this.props.conf.maxType === "statistic" && (forceFetch || forceFetchMax)) {
      this.timeFetchMax();
    }
  }

  timeFetchValue = () => {
    if(this.requestValue) {
      this.setState({
        loadingValue: true
      }, () => {
        let filters = [];
        if(this.props.conf.element && this.props.widgetType.elementSettings[this.props.conf.element].computeFilters) {
          filters = Object.assign({}, this.props.conf.valueStatistic.filters);
          Object.keys(filters).forEach(key => {
            filters[key] = this.evaluateArgument('valueStatistic.filters.'+key);
          });
          filters = this.props.widgetType.elementSettings[this.props.conf.element].computeFilters(filters);
        }
        this.requestValue.fetch(this.props.conf.valueStatistic.stat, this.props.conf.valueStatistic.attribute, filters)
      })
    }
  }

  timeFetchMax = () => {
    if(this.requestMax) {
      this.setState({
        loadingMax: true
      }, () => {
        let filters = [];
        if(this.props.conf.element && this.props.widgetType.elementSettings[this.props.conf.element].computeFilters) {
          filters = Object.assign({}, this.props.conf.maxStatistic.filters);
          Object.keys(filters).forEach(key => {
            filters[key] = this.evaluateArgument('maxStatistic.filters.'+key);
          });
          filters = this.props.widgetType.elementSettings[this.props.conf.element].computeFilters(filters);
        }
        this.requestMax.fetch(this.props.conf.maxStatistic.stat, this.props.conf.maxStatistic.attribute, filters)
      })
    }
  }

  requestValueCallback = (data) => {
    this.setState({
      loadingValue: false,
      value: data.result
    })
  }

  requestValueCallbackFailure = (msg) => {
    console.log(msg);
  }

  requestMaxCallback = (data) => {
    this.setState({
      loadingMax: false,
      max: data.result
    })
  }

  requestMaxCallbackFailure = (msg) => {
    console.log(msg);
  }

  getValue() {
    switch (this.props.conf.valueType) {
      case "static":
        return this.props.conf.valueStatic
      case "statistic":
        if(!this.props.loadingValue) {
          return this.state.value
        }
        break;
      default:
    }
    return 0;
  }

  getMaximum() {
    switch (this.props.conf.maxType) {
      case "static":
        return this.props.conf.maxStatic
      case "statistic":
        if(!this.props.loadingMax) {
          return this.state.max;
        }
        break;
      default:
    }
    return 0;
  }

  getPercentage() {
    return Math.ceil(this.getValue() / this.getMaximum() * 100);
  }

  getColor() {
    let percentage = this.getPercentage();
    if(percentage > 75) {
      return "green";
    }
    if(percentage > 50) {
      return "yellow";
    }
    if(percentage > 25) {
      return "orange";
    }
    return "red";
  }

  displayQuantity() {
    let color = this.getColor();
    return (
      <>
        <div className={`dashboard-widget-objective-quantity-progress-bar dashboard-widget-objective-quantity-progress-bar-${color}`}>
          <progress max={this.getMaximum()} value={this.getValue()}/>
        </div>
        <div className="d-flex flex-row" style={{height: "100%", width: "100%"}}>
          <div className={`d-flex col dashboard-widget-objective-main-number dashboard-widget-objective-main-number-${color}`}>
            <TextResizer className="d-flex align-items-end" text={this.getValue()}/>
          </div>
          <div className="d-flex col-auto align-items-end dashboard-widget-objective-second-number" style={{fontSize:"small"}}>
            {`/ ${this.getMaximum()}`}
          </div>
        </div>
      </>
    )
  }

  displayPercentage() {
    let color = this.getColor();
    return (
      <>
        <div className={`dashboard-widget-objective-quantity-progress-bar dashboard-widget-objective-quantity-progress-bar-${color}`}>
          <progress max={this.getMaximum()} value={this.getValue()}/>
        </div>
        <div className="d-flex flex-row" style={{height: "100%", width: "100%"}}>
          <div className={`d-flex col dashboard-widget-objective-main-number dashboard-widget-objective-main-number-${color}`}>
            <TextResizer className="d-flex align-items-end" text={this.getPercentage()}/>
          </div>
          <div className="d-flex col-auto align-items-end dashboard-widget-objective-second-number" style={{fontSize:"small"}}>
            {"%"}
          </div>
        </div>
      </>
    )
  }

  displayProgressionBar() {
    let color = this.getColor();
    let colorFill = "var(--color-information)";
    switch (color) {
      case "red":
        colorFill = "var(--color-danger)";
        break;
      case "orange":
        colorFill = "var(--color-warning)";
        break;
      case "yellow":
        colorFill = "var(--color-information)";
        break;
      case "green":
        colorFill = "var(--color-validated)";
        break;
      default:

    }
    return (
      <div className="d-flex justify-content-center align-items-center"  style={{height: "100%", width: "100%"}}>
        <CircleProgress value={this.getPercentage()} colorProgressFilled={colorFill}/>
      </div>
    )
  }

  displayObjective() {
    if(!this.state.loadingMax && !this.state.loadingValue) {
      switch (this.props.conf.display) {
        case "quantity":
          return this.displayQuantity();
        case "percentage":
          return this.displayPercentage();
        case "progressionBar":
          return this.displayProgressionBar();
        default:
          return <div/>
      }
    }
  }

  displayLoading() {
    if(this.state.loadingMax || this.state.loadingValue) {
      return (
        <Loading container="parent"/>
      )
    }
  }

  displayContent() {
    return (
      <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
        {this.displayTitle()}
        {this.displayArguments()}
        <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
          {this.displayObjective()}
          {this.displayLoading()}
        </div>
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="dashboard-widget-container">
        {this.displayContent()}
      </div>
    );
  }
}


export default WidgetObjective;
