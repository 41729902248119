import {
  index as baseIndex
 } from './Base';

/**
 * Fetch all family to serve in a dropdown form choice
 */
export function fetchRoleGroups() {
  return baseIndex('role_groups', 1, 0);
}
