import React from 'react';

import '../../css/modular_page.css';

import PartSelection from './PartSelection';

/**
 */
class ModularPage extends React.Component {

  static defaultProps = {
    conf: null
  }
  
  displayRoot() {
    return (
      <PartSelection {...this.props.conf.root} />
    )
  }

  render() {
    return (
      <div className="modular-page-container">
        {this.displayRoot()}
      </div>
    );
  }
}

export default ModularPage;
