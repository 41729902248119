import {
  index as baseIndex
 } from './Base';

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all code nafs to serve in a dropdown form choice
 */
export function fetchCodeNafs() {
  return baseIndex('code_nafs', 1, 0);
}
