const partner = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Le nom du partenaire ne doit pas être vide.",
    },
  },
  partnerNumber: {
    label: "Numéro",
  },
  legalForm: {
    label: "Forme légale",
    invalid: {
      assertEmpty: "La forme légale du partenaire ne doit pas être vide.",
    },
  },
  siret: {
    label: "Siret",
    invalid: {
      assertFormat: "La numéro de Siret n'est pas valide.",
    },
  },
  vatIntra: {
    label: "TVA Intracommunautaire",
  },
  prospect: {
    label: "Ce partenaire est-il un prospect ?",
  },
  mainPlace: {
    label: "Site principal",
  },
  billingPlace: {
    label: "Site de facturation",
    labelNew: "Site de facturation (si différent du site principal)"
  },
  nationality: {
    label: "Nationalité",
  },
  codeNaf: {
    label: "Code Naf",
  },
  places: {
    label: "Sites",
    allowMoreField: {
      addLabel: "Ajouter un site",
      removeLabel: "Enlever un site",
      removingLabel: "Supprimer"
    }
  },
  otherPlaces: {
    label: "Autres site(s)",
  },
  place: {
    label: "Site #key#"
  },
  accounts: {
    label: "Comptes",
    allowMoreField: {
      addLabel: "Ajouter un compte",
      removeLabel: "Enlever un compte",
      removingLabel: "Supprimer"
    }
  },
  account: {
    label: "Compte #key#"
  },
  partnerType: {
    label: "Type"
  },
  commercialReferrer: {
    label: "Référent Commercial",
    invalid: {
      assertEmpty: "Le référent commercial ne peut pas être vide."
    }
  },
  waitingTicketBilling: {
    label: "Facture de ticket en attente"
  },
};

export default partner;
