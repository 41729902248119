import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of parameters based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('parameters', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single parameter based on his ID
 *
 * @param id the id of the parameter requested
 */
export function getFromId(id) {
  return baseGetFromId('parameters', id, 'main');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single user based on his ID
 *
 * @param id the of the user requested
 * @param data the new data of the user
 */
export function editFromId(id, data) {
  return baseEditFromId('parameters', id, data, 'main');
}

/******************************************************************************
 * SPECIFIC FUNCTION                                                          *
 ******************************************************************************/

/**
 * Get a single parameter based on his ID
 *
 * @param id the id of the parameter requested
 */
export function getLocationPriceModifier() {
  return baseGetFromId('parameters', 3);
}
