import strings from '../../Localization/Localization';

let specialConditionOfSale = {
  fieldKey: "text",
  type: "textarea",
  label: strings.form.specialConditionOfSale.text.label,
  validators: []
}

export const form = {
  formKey: "specialConditionOfSale",
  fields: [
    specialConditionOfSale,
  ]
}
