import {
  index as baseIndex,
  getFromId as baseGetFromId,
  newItem as baseNewItem,
  statistic as baseStatistic,
  stateMachineStatistic as baseStateMachineStatistic,
  request,
 } from './Base';

 /******************************************************************************
  * INDEX FUNCTION                                                             *
  ******************************************************************************/

/**
 * Get a list of quotes based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('quotes', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single quote based on his ID
 *
 * @param id the of the quote requested
 */
export function getFromId(id) {
  return baseGetFromId('quotes', id);
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('quotes', id, 'identification');
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getCartFromId(id) {
  return baseGetFromId('quotes', id, 'cart');
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getOrderFromId(id) {
  return baseGetFromId('quotes', id, 'order');
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getDuplicateFromId(id) {
  return baseGetFromId('quotes', id, 'duplicate');
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getCopyFromId(id) {
  return baseGetFromId('quotes', id, 'copy');
}

/**
 * Get a single order based on his ID
 *
 * @param id the of the order requested
 */
export function getYousignFromId(id) {
  return baseGetFromId('quotes', id, 'yousign');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single quote based on his ID
 *
 * @param id the of the quote requested
 * @param data the new data of the quote
 */
/* export function editFromId(id, data) {
  return baseEditFromId('quotes', id, data);
} */

/******************************************************************************
 * STATISTIC FUNCTION                                                         *
 ******************************************************************************/

export function statistic(stat, attribute, search) {
  return baseStatistic('quotes', stat, attribute, search);
}

/******************************************************************************
 * STATE MACHINE STATISTIC FUNCTION                                           *
 ******************************************************************************/

export function stateMachineStatistic(stateStatistic, states, search) {
  return baseStateMachineStatistic('quotes', stateStatistic, states, search);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new quote
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return baseNewItem('quotes', data);
}

/******************************************************************************
 * NOTIFICATION FUNCTION                                                      *
 ******************************************************************************/

/**
 * Create a new quote
 *
 * @param data the new data of the partner
 */
export function sendNewNotification(id) {
  return request(`/mail/quote/${id}/quote.new.client`, "GET");
}

/******************************************************************************
 * SPECIFIC FUNCTION                                                          *
 ******************************************************************************/

/**
 * preview the quantity for bonus
 *
 * @param id the id of the partner
 * @param username the username to check
 */
export function previewCommercialBonus(data) {
  let params = {
    item: data
  };
  return request("/quotes/preview_commercial_bonus", "POST", params);
}
