import React from 'react';

import { conf } from '../Configuration/notifications';

import Index from '../../Component/Configurator/ItemIndex';

import Error from '../../Component/Layout/Error';

export const routes = {
  prefix: '/notifications',
  routes: {
    index: {
      path: '/index',
      exact: false,
      createPath: () => '/notifications/index',
      type: 'render',
      getRender: (props) => <Index {...props} conf={conf.index}/>,
    },
    error: {
      path: '/*',
      exact: false,
      createPath: () => '/',
      type: 'component',
      getComponent: () => Error,
    },
  }
}
