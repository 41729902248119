import React from 'react';

import { SubmitPositions } from '../../Form/Constant';

import { withModalHandler } from '../../HOC/ModalHandler';

import { Form, Button } from 'react-bootstrap';
import strings from '../../Localization/Localization';
import '../../css/form.css';

import Loading from '../Layout/Loading';

import FormFieldSelector from './FormFieldSelector';

import AsyncCounter from '../../Utils/Counter/AsyncCounter';

import SubFooter from '../Layout/SubFooter';

import ColumnEqualizer from '../Basic/ColumnEqualizer';

/**
 * MyForm
 *
 * This class handles HTML form.
 * To create a form, use a configuration file.
 *
 * The fields available are in ./Field directory.
 *
 * IMPORTANT, the form doesn't store the value,
 * rather it use props to get the value and send the value to parent component.
 */
class MyForm extends React.Component {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    formKey: "_default",
    disabled: false,
    ignoreValidation: false,
    fields: [],
    fieldVariant: "primary",
    values: {},
    extraValues: {},
    onChangeField: (fieldKey, value) => null,

    numberColumn: 1,
    formSizeXs: 12,
    formSizeMd: 8,
    formExtraClassName: "",

    displayExtraButton: false,
    buttonExtraClassName: "",
    extraButtonLabel: strings.form.default.button.extraButton,
    onClickExtraButton: () => true,

    onSubmit: () => null,
    onSubmitFailed: () => null,
    resetValuesOnSubmit: false,
    displaySubmitButton: true,
    submitLabel: strings.form.default.button.submit,
    submitPosition: SubmitPositions.DEFAULT
  }

  constructor(props) {
    super(props);

    this.counter = new AsyncCounter();

    this.fieldRefs = {};

    this.extraValuesLoader = {};
    if(props.extraValues) {
      Object.keys(props.extraValues).forEach((key) => {
        if(props.extraValues[key] && props.extraValues[key].type === "load") {
          this.extraValuesLoader[key] = this.props.modalHandler.addVerificationWithCallback(props.extraValues[key].loader.loader, (data) => this.extraValuesCallback(key, data), (msg) => this.extraValuesCallbackFailure(key, msg));
        }
      });
    }

    //state of the component, including :
    //submitted : a boolean variable indicating if the form is submitted
    this.state = {
      submitted: false,
      submitting: false,

      extraValues: {},
    }
  }

  componentDidMount() {
    if(this.props.extraValues) {
      Object.keys(this.props.extraValues).forEach((key) => {
        if(this.props.extraValues[key] && this.props.extraValues[key].type === "load") {
          this.extraValuesLoader[key].call();
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.extraValues) {
      Object.keys(this.props.extraValues).forEach((key) => {
        if(this.props.extraValues[key] && this.props.extraValues[key].type === "load") {
          if(this.props.extraValues[key] !== prevProps.extraValues[key]) {
            this.extraValuesLoader[key] = this.props.modalHandler.addVerificationWithCallback(this.props.extraValues[key].loader.loader, (data) => this.extraValuesCallback(key, data), (msg) => this.extraValuesCallbackFailure(key, msg));
            this.extraValuesLoader[key].call();
          }
        }
      });
    }
  }

  componentWillUnmount() {
    this.counter.unsubscribeAll();
  }

  fieldUuid = (item) => {
    return `${item.fieldKey}${item.fieldKeyUuid?'-'+item.fieldKeyUuid:''}`
  }

  fieldRefCallback = (item, ref) => {
    if(this.fieldRefs[this.fieldUuid(item)] === undefined) {
      this.fieldRefs[this.fieldUuid(item)] = React.createRef();
    }
    this.fieldRefs[this.fieldUuid(item)].current = ref;
  }

  extraValuesCallback = (key, data) => {
    let extraValues = {
      ...this.state.extraValues
    };
    extraValues[key] = data;
    this.setState({
      extraValues: extraValues
    })
  }

  extraValuesCallbackFailure = (key, msg) => {
    console.log(msg)
  }

  /**
   * Display the list of field pressent in the configuration
   */
  displayFields() {
    //check if there is fields in the configuration
    if(this.props.fields !== null && this.props.fields.length > 0){
      return (
        <ColumnEqualizer column={this.props.numberColumn}>
          {
            //loop through the fields in the configuration
            this.props.fields.map((item, key) => {
              let disabled = this.props.disabled || (item.disabled !== undefined && ((typeof item.disabled === 'function' && item.disabled(() => this.props.values, this.getExtraValues)) || item.disabled === true));
              //check if the field should be displayed or not
              if(!(disabled && typeof item.showOnDisabled === 'function' && !item.showOnDisabled(() => this.props.values, this.getExtraValues))) {
                //display the field using the field selector
                return (
                  <React.Fragment key={`${this.props.formKey}-${item.fieldKey}-${key}${item.fieldKeyUuid?"-"+item.fieldKeyUuid:""}`}>
                    <FormFieldSelector
                      key={`${this.props.formKey}-${item.fieldKey}-${key}${item.fieldKeyUuid?"-"+item.fieldKeyUuid:""}`}
                      forwardedRef={(ref) => this.fieldRefCallback(item, ref)}

                      fieldVariant={this.props.fieldVariant}

                      formKey={this.props.formKey}
                      {...item}

                      disabled={disabled}
                      ignoreValidation={this.props.ignoreValidation || item.ignoreValidation}

                      value={this.props.values[item.fieldKey]}
                      getValues={() => this.props.values}
                      getExtraValues={() => this.getExtraValues()}
                      onChange={(value) => this.onChangeField(item, value)}
                      modifyField={(fieldKey, value) => this.modifyField(fieldKey, value)}

                      validators={item.validators}
                      validationCallback={() => this.onSubmitValidationCallback()}
                      submitted={this.state.submitted}
                      submitting={this.state.submitting}
                    />
                  </React.Fragment>
                )
              }
              return null
            })
          }
        </ColumnEqualizer>
      )
    }
  }

  /**
   * Display the submit button if the form is not disabled
   */
  displayButtons() {
    if(!this.props.disabled && this.props.displaySubmitButton) {
      switch (this.props.submitPosition) {
        case SubmitPositions.SUB_FOOTER:
          return (
            <SubFooter>
              {this.props.displayExtraButton?
                <Button
                  variant="my-warning"
                  className={`button-form-row ${this.props.buttonExtraClassName}`}
                  onClick={this.props.onClickExtraButton}>
                  {this.props.extraButtonLabel}
                </Button>
                :null
              }
              <Button
                type="submit"
                variant="my-validated"
                className={`button-form-row ${this.props.buttonExtraClassName}`}>
                {this.props.submitLabel}
              </Button>
            </SubFooter>
          );
        case SubmitPositions.DEFAULT:
        default:
          return (
            <div className="d-flex justify-content-center container-form-button">
              {this.props.displayExtraButton?
                <Button
                  variant="my-warning"
                  className={`col-5 col-md-3 button-form-row ${this.props.buttonExtraClassName}`}
                  onClick={this.props.onClickExtraButton}>
                  {this.props.extraButtonLabel}
                </Button>
                :null
              }
              <Button
                type="submit"
                variant="my-validated"
                className={`col-5 col-md-3 button-form-row ${this.props.buttonExtraClassName}`}>
                {this.props.submitLabel}
              </Button>
            </div>
          )
      }
    }
  }

  getExtraValues = () => {
    return {
      ...this.props.extraValues,
      ...this.state.extraValues
    }
  }

  /**
   * callback used by the fields to change the value
   *
   * @param item the field
   * @param value the new value for the field
   */
  onChangeField = (item, value) => {
    //send the value to parent component
    this.props.onChangeField(item.fieldKey, value);

    Promise.resolve().then(() => this.triggerEvents(item.fieldKey));
  }

  /**
   * callback used by the fields to change other field the value
   *
   * @param item the field
   * @param value the new value for the field
   */
  modifyField = (fieldKey, value) => {
    //send the value to parent component
    this.props.onChangeField(fieldKey, value);

    Promise.resolve().then(() => this.triggerEvents(fieldKey));
  }

  triggerEvents = (key) => {
    this.props.fields.forEach((field) => {
      if(field.type === 'array') {
        if(this.fieldRefs[`${field.fieldKey}${field.fieldKeyUuid?'-'+field.fieldKeyUuid:''}`].current) {
          this.fieldRefs[`${field.fieldKey}${field.fieldKeyUuid?'-'+field.fieldKeyUuid:''}`].current.triggerEvents(key);
        }
      }
      else if(field.type === 'subform' && !field.preventEvent) {
        if(this.fieldRefs[`${field.fieldKey}${field.fieldKeyUuid?'-'+field.fieldKeyUuid:''}`] && this.fieldRefs[`${field.fieldKey}${field.fieldKeyUuid?'-'+field.fieldKeyUuid:''}`].current) {
          this.fieldRefs[`${field.fieldKey}${field.fieldKeyUuid?'-'+field.fieldKeyUuid:''}`].current.triggerEvents(key);
        }
      }
      else if(field.events) {
        field.events.forEach((event) => {
          if(event.event === 'onChange'
            && (event.target === key
            || event.target === "")) {
            event.function(this.fieldRefs[`${field.fieldKey}${field.fieldKeyUuid?'-'+field.fieldKeyUuid:''}`].current);
          }
        });
      }
    });
  }

  /**
   * validate all fields
   */
  validateAll = (event) => {
    this.props.fields.forEach((item) => {
      let ref = this.fieldRefs[this.fieldUuid(item)].current;
      if(ref && ref.startValidation !== undefined) ref.startValidation();
    });
  }

  /**
   * Starting point of the submission of the form,
   * this action is called when pressing the submit button
   * The submission starts by validate all field present in the field
   *
   * @param event the event click send by the event system
   */
  onSubmit = (event = null) => {
    //preventing default action from the event system
    if(event !== null) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({
      submitted: true,
      submitting: true
    }, () => {


      let validationCounter = 0;
      // let validationCounter = Object.values(this.fieldRefs).filter((item) => {
      //   console.log(item.current, item.current !== null && item.current.needValidation());
      //   return item.current !== null && item.current.needValidation()
      // }).length

      this.props.fields.forEach((item) => {
        let ref = this.fieldRefs[this.fieldUuid(item)];
        if(ref && ref.current && ref.current.needValidation()) {
          validationCounter++;
        }
      });

      if(validationCounter === 0) {
        this.submit()
      }
      else {
        this.counter.reset();
        this.counter.setValue(validationCounter);
        this.counter.subscribe(0, () => this.submit());

        this.props.fields
          .filter(item => this.fieldRefs[this.fieldUuid(item)])
          .forEach((item) => {
            let ref = this.fieldRefs[this.fieldUuid(item)].current;
            if(ref && ref.startValidation !== undefined) ref.startValidation();
          }
        );
      }
    });
  }

  onSubmitValidationCallback = () => {
    if(this.state.submitting) {
      this.counter.decrement();
    }
  }

  /**
   * Finish line of the submission of the form
   * This will send values to the parent only if all fields are valid
   */
  submit = () => {

    let valid = true;

    this.props.fields.forEach((item) => {
      let ref = this.fieldRefs[this.fieldUuid(item)];
      valid &= !ref || !ref.current || !ref.current.needValidation() || ref.current.isValid();
    });



    if(valid) {
      let values = {}
      if(!this.props.resetValuesOnSubmit) {
        Object.assign(values, this.props.values);
      }

      this.props.fields
        .filter((item) => {
          let disabled = item.disabled;
          if(typeof disabled === 'function') {
            disabled = disabled(() => this.props.values, this.getExtraValues);
          }
          return !disabled
        })
        .forEach((item) => {
          let ref = this.fieldRefs[this.fieldUuid(item)].current;
          values[item.fieldKey] = ref.getFinalValue();
        });

      //submit the value to parent
      this.props.onSubmit(values);
    }
    else {
      this.props.onSubmitFailed();
    }

    //indicate submission is now done
    this.setState({
      submitting: false
    });
  }

  displayLoading() {
    if(this.state.submitting) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Form className={`col-${this.props.formSizeXs} col-md-${this.props.formSizeMd} container-form ${this.props.formExtraClassName}`} noValidate onSubmit={this.onSubmit}>
        {this.displayLoading()}
        {this.displayFields()}
        {this.displayButtons()}
      </Form>
    );
  }
}

/**
 * HOC Modal Handler to support callback validation
 */
export default withModalHandler(MyForm);
