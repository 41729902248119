import React from 'react';

import { connect } from 'react-redux';
import { removeCredentialsAction } from '../../Store/Action/credentials'

import { Redirect } from "react-router-dom";
import { main as mainRoutes } from '../../MetaData/Route/routes'

/**
 * Logout
 *
 * This component represent a page.
 *
 * This component is used to log out user.
 *
 * This component doesn't display anything, it will disconnect the user of the
 * service, and redirect the user to another page.
 */
class Logout extends React.Component {

  /**
   * Main render method for React Component
   *
   * This function will send the logout command and then redirect itself to the
   * login route
   */
  render() {
    this.props.dispatch(removeCredentialsAction());
    return (
      <Redirect to={mainRoutes.routes.login.createPath()}/>
    );
  }
}

export default connect()(Logout);
