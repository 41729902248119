import React from 'react';

import '../../css/loading.css';

/**
 * Loading
 */
class Loading extends React.Component {

  static defaultProps = {
    containerClassName: "",
    container: "fixed", //or parent
    size: "normal" //or small
  }

  displayBackground() {
    if(this.props.container === "fixed") {
      return (
        <div className="lds-background"/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className={`lds-container-${this.props.container} ${this.props.containerClassName}`}>
        {this.displayBackground()}
        <div className={`lds-ring lds-ring-${this.props.size}`}>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
    );
  }
}

export default Loading;
