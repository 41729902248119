import React from 'react';

import strings from '../../Localization/Localization';

import {
  index,
  editFromId,
  newItem,
  deleteItem
} from '../../API/FrontendData';

import { isDeepEqual } from '../../Utils/DeepEqual';
import { v4 as uuid } from 'uuid';

import { connect } from 'react-redux';

import { withModalHandler } from '../../HOC/ModalHandler';

import {
  resetWidgets,
  saveWidgets
} from '../../Store/Action/dashboard';

import { defaultWidgets } from '../../MetaData/Dashboard/defaultWidget';

import { defaultDashboards } from '../../MetaData/Dashboard/defaultDashboard';

import { Collapse, Form, Modal, Button, Row, Col, Card } from 'react-bootstrap';

import MainLayout from '../Layout/MainLayout';
import SubFooter from '../Layout/SubFooter';

import Grid from '../Grid/Grid';

import Box from '../Grid/Box/Box';

import WidgetSelector from './WidgetSelector';

import DashboardGridWidget from '../DnD/Both/DashboardGridWidget';
import DashboardStoredWidget from '../DnD/Drag/DashboardStoredWidget';
import DashboardGridSlot from '../DnD/Drop/DashboardGridSlot';
import DashboardStorageSlot from '../DnD/Drop/DashboardStorageSlot';
import DashboardDragLayer from '../DnD/DragLayer/DashboardDragLayer';

import AsyncCounter from '../../Utils/Counter/AsyncCounter';

import MyForm from '../Form/MyForm';
import {
  editPage as editWidgetPage
} from '../../MetaData/Form/Dashboard/editWidget';

import '../../css/dashboard.css';

import { stringToBase64, base64ToString } from '../../Utils/Base64Encoder';

const DashboardTheme = React.lazy(() => import('../Theme/Themes/dashboard'));
const DashboardKeepDimensionTheme = React.lazy(() => import('../Theme/Themes/dashboard_keep_dimension'));

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.gridRef = React.createRef();
    this.visualSettingsForm = React.createRef();
    this.dataSettingsForm = React.createRef();

    this.isDragging = false;

    this.indexWidget = this.props.modalHandler.addVerificationWithCallback(index, this.postIndexWidget, this.postIndexWidgetFailure);
    this.editWidget = this.props.modalHandler.addVerificationWithCallback(editFromId, this.postEditWidget, this.postEditWidgetFailure);
    this.newWidget = this.props.modalHandler.addVerificationWithCallback(newItem, this.postNewWidget, this.postNewWidgetFailure);
    this.deleteWidget = this.props.modalHandler.addVerificationWithCallback(deleteItem, this.postDeleteWidget, this.postDeleteWidgetFailure);

    this.indexDefaultWidgetPosition = this.props.modalHandler.addVerificationWithCallback(index, this.postIndexDefaultWidgetPosition, this.postIndexDefaultWidgetPositionFailure);
    this.editDefaultWidgetPosition = this.props.modalHandler.addVerificationWithCallback(editFromId, this.postEditDefaultWidgetPosition, this.postEditDefaultWidgetPositionFailure);
    this.newDefaultWidgetPosition = this.props.modalHandler.addVerificationWithCallback(newItem, this.postNewDefaultWidgetPosition, this.postNewDefaultWidgetPositionFailure);

    this.state = {
      gridRef: 0,

      organisationMode: false,
      organisationStorageFrom: "default",
      storageFilter: {},

      parameterBag: {},

      isDragging: false,

      dashboard: 0,

      editedWidget: null,
      submittingEditWidget: false,
      showCopyWidget: false,
      showPasteWidget: false,
      pasteWidgetString: null,

      editedWidgets: null,

      widgets: [],
      defaultWidgetsPositionsInitialized: false,
      defaultWidgetsPositionsId: null,
      defaultWidgetsPositions: [],

      viewportWidth: 1920,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();

    this.indexWidget(1, 0, { identification: "dashboard_widget" });
    this.indexDefaultWidgetPosition(1, 0, { identification: "dashboard_default_widgets" });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    if(this.state.viewportWidth !== window.innerWidth) {
      this.setState({
        viewportWidth: window.innerWidth
      })
    }
  }

  getKeepDimension() {
    return true
  }

  postIndexWidget = (data) => {
    let widgets = [];
    data.items.forEach((item) => {
      try{
        let widget = JSON.parse(item.data);
        widget.serverId = item.id;
        widgets.push(widget);
      }
      catch(e) {

      }
    });

    this.setState({
      widgets: widgets
    })
  }

  postIndexWidgetFailure = (msg) => {
    console.log(msg);
  }

  postEditWidget = (data) => {}

  postEditWidgetFailure = (msg) => {
    console.log(msg);
  }

  postNewWidget = (data) => {
    let widgets = [...this.state.widgets];

    try{
      let widget = JSON.parse(data.item.data);
      widget.serverId = data.item.id;
      widgets.push(widget);
    }
    catch(e) {}

    this.setState({
      widgets: widgets
    })
  }

  postNewWidgetFailure = (msg) => {
    console.log(msg);
  }

  postDeleteWidget = (data) => {
    console.log(data);
  }

  postDeleteWidgetFailure = (msg) => {
    console.log(msg);
  }

  postIndexDefaultWidgetPosition = (data) => {
    console.log(data);
    if(data.items.length > 0) {
      this.setState({
        defaultWidgetsPositionsInitialized: true,
        defaultWidgetsPositionsId: data.items[0].id,
        defaultWidgetsPositions: JSON.parse(data.items[0].data)
      })
    }
  }

  postIndexDefaultWidgetPositionFailurepostDeleteWidgetFailure = (msg) => {
    console.log(msg);
  }

  postEditDefaultWidgetPosition = (data) => {
    this.setState({
      defaultWidgetsPositionsInitialized: true,
      defaultWidgetsPositionsId: data.item.id,
      defaultWidgetsPositions: JSON.parse(data.item.data)
    })
  }

  postEditDefaultWidgetPositionFailurepostDeleteWidgetFailure = (msg) => {
    console.log(msg);
  }

  postNewDefaultWidgetPosition = (data) => {
    this.setState({
      defaultWidgetsPositionsInitialized: true,
      defaultWidgetsPositionsId: data.item.id,
      defaultWidgetsPositions: JSON.parse(data.item.data)
    })
  }

  postNewDefaultWidgetPositionFailurepostDeleteWidgetFailure = (msg) => {
    console.log(msg);
  }




  defineGridRef = ref => {
    this.gridRef.current = ref
    this.setState({
      gridRef: this.state.gridRef+1
    })
  }

  calculateWidgetSpace = (widget, newPosition = null) => {
    let totalColumn = this.getTotalColumn();

    let spaces = [];
    let usedPosition = newPosition !== null?newPosition:widget.currentPosition;

    for (var i = 0; i < widget.column; i++) {
      for (var j = 0; j < widget.row; j++) {
        spaces.push(usedPosition + i + totalColumn*j);
      }
    }

    return spaces;
  }

  replaceBox = (id, dragPosition, hoverPosition) => {
    let widgets = [...this.state.editedWidgets];

    let valid = false;

    let totalColumn = this.getTotalColumn();
    let dashboard = this.state.dashboard;

    valid = widgets.some((widget, key) => {
      if(id === widget.id) {
      // if(Object.keys(widget.positions).includes(String(dashboard)) && Object.keys(widget.positions[dashboard]).includes(String(totalColumn)) && widget.positions[dashboard][totalColumn] === dragPosition) {
        if(widget.column > 1) {
          //check if out of bounds
          if((hoverPosition%totalColumn) > (totalColumn-widget.column)) {
            return false;
          }
        }
        //calculate boundaries
        let spaces = this.calculateWidgetSpace(widget, hoverPosition);
        //check if another widget is already in the boundaries
        if(this.getWidgets("all", this.state.dashboard, false, totalColumn).some((comparedWidget) => {
          if(widget.id !== comparedWidget.id) {
            let comparedSpaces = this.calculateWidgetSpace(comparedWidget);
            let intersection = comparedSpaces.filter(space => spaces.includes(space));
            if(intersection.length > 0) {
              return true;
            }
          }
          return false;
        })) {
          return false;
        }

        let newWidget = Object.assign({}, widget);
        newWidget.positions = Object.assign({}, newWidget.positions);
        newWidget.positions[dashboard] = Object.assign({}, newWidget.positions[dashboard]);
        newWidget.positions[dashboard][totalColumn] = hoverPosition
        widgets[key] = newWidget
        return true;
      }
      return false;
    });

    if(valid) {
      this.setState({
        editedWidgets: widgets
      })
      return true;
    }
    return false;
  }

  swapBox = (id, dragPosition, hoverPosition) => {
    let valid = false;

    // let widgets = this.getWidgets();
    //
    // let totalColumn = this.getTotalColumn();
    // let dashboard = this.state.dashboard;
    //
    // widgets.forEach((widget, key) => {
    //   if(Object.keys(widget.positions).includes(String(dashboard)) && Object.keys(widget.positions[dashboard]).includes(String(totalColumn)) && widget.positions[dashboard][totalColumn] === dragPosition) {
    //     let newWidget = Object.assign({}, widget);
    //     newWidget.positions = Object.assign({}, newWidget.positions);
    //     newWidget.positions[dashboard] = Object.assign({}, newWidget.positions[dashboard]);
    //     newWidget.positions[dashboard][totalColumn] = hoverPosition;
    //     widgets[key] = newWidget
    //     valid = true;
    //   }
    //   if(Object.keys(widget.positions).includes(String(dashboard)) && Object.keys(widget.positions[dashboard]).includes(String(totalColumn)) && widget.positions[dashboard][totalColumn] === hoverPosition) {
    //     let newWidget = Object.assign({}, widget);
    //     newWidget.positions = Object.assign({}, newWidget.positions);
    //     newWidget.positions[dashboard] = Object.assign({}, newWidget.positions[dashboard]);
    //     newWidget.positions[dashboard][totalColumn] = dragPosition
    //     widgets[key] = newWidget
    //     valid = true;
    //   }
    // });
    //
    // console.log(widgets);
    //
    // this.setState({
    //   widgets: widgets
    // })

    return valid;
  }

  storeBox = (id, dragPosition) => {
    let widgets = [...this.state.editedWidgets];

    let totalColumn = this.getTotalColumn();
    let dashboard = this.state.dashboard;

    widgets.some((widget, key) => {
      if(id === widget.id) {
      // if(Object.keys(widget.positions).includes(String(dashboard)) && Object.keys(widget.positions[dashboard]).includes(String(totalColumn)) && widget.positions[dashboard][totalColumn] === dragPosition) {
        let newWidget = Object.assign({}, widget);
        newWidget.positions = Object.assign({}, newWidget.positions);
        newWidget.positions[dashboard] = Object.assign({}, newWidget.positions[dashboard]);
        newWidget.positions[dashboard][totalColumn] = null
        widgets[key] = newWidget;
        return true;
      }
      return false;
    });

    this.setState({
      editedWidgets: widgets
    })
  }

  notifyIsDragging = (isDragging) => {
    if(this.isDragging !== isDragging) {
      this.isDragging = isDragging;
      Promise.resolve().then(() => {
        this.setState({
          isDragging: isDragging
        })
      })
    }
  }


  startEditConf = (widget) => {
    //deep copy
    let editedWidget = JSON.parse(JSON.stringify(widget));
    this.setState({
      editedWidget: editedWidget
    })
  }

  addNewWidget = () => {
    let newWidget = {}
    this.setState({
      editedWidget: newWidget
    })
  }

  startWidgetSubmit = () => {
    if(this.visualSettingsForm.current && this.dataSettingsForm.current) {
      this.setState({
        submittingEditWidget: true
      }, () => {
        this.counter = new AsyncCounter();
        this.counter.reset();
        this.counter.setValue(2);
        this.counter.subscribe(0, () => this.saveWidget());

        this.settings = {};

        this.visualSettingsForm.current.onSubmit();
        this.dataSettingsForm.current.onSubmit();
      })
    }
  }

  onSubmitSettingForm = (values) => {
    if(this.counter && this.settings) {
      Object.assign(this.settings, values);
      this.counter.decrement();
    }
    else {
      this.onSubmitFailedSettingForm()
    }
  }

  onSubmitFailedSettingForm = () => {
    this.counter = null;
    this.settings = null;
    this.setState({
      submittingEditWidget: false
    })
  }

  saveWidget = () => {
    this.counter = null;

    let widgets = null;
    let newState = {
      editedWidget: null,
      submittingEditWidget: false,
      showCopyWidget: false,
      showPasteWidget: false,
    }

    widgets = this.getWidgets();

    let index = widgets.findIndex((widget) => widget.id && widget.id === this.state.editedWidget.id);

    let newWidget = null;
    if(index !== -1 && this.state.editedWidget.default !== true) {
      newWidget = Object.assign({}, this.state.editedWidget);
      widgets[index] = newWidget;
    }
    else if(index !== -1 && this.state.editedWidget.default === true) {
      newWidget = Object.assign({}, this.state.editedWidget, {
        positions: {},
        currentPosition: null,
        default: undefined
      });
      newWidget.positions[this.state.dashboard] = {};
      newWidget.positions[this.state.dashboard][this.getTotalColumn()] = widgets[index].positions[this.state.dashboard][this.getTotalColumn()];
      widgets[index].positions[this.state.dashboard][this.getTotalColumn()] = undefined;

      widgets.push(newWidget);
    }
    else {
      newWidget = Object.assign({}, this.state.editedWidget, {
        id: undefined,
        positions: {},
        currentPosition: null,
        default: undefined
      });

      widgets.push(newWidget);
    }

    newWidget.conf = {};
    if(this.state.editedWidget.conf) {
      Object.assign(newWidget.conf, {
        element: this.state.editedWidget.conf.element
      })
    }
    Object.assign(newWidget.conf, this.settings);
    this.settings = null;

    let widgetType = this.props.conf.widgets.find((widgetType) => newWidget.type === widgetType.id);
    if(newWidget.column === undefined || newWidget.column < widgetType.column.min) {
      newWidget.column = widgetType.column.min
    }
    else if(newWidget.column > widgetType.column.max) {
      newWidget.column = widgetType.column.max
    }
    if(newWidget.row === undefined || newWidget.row < widgetType.row.min) {
      newWidget.row = widgetType.row.min
    }
    else if(newWidget.row > widgetType.row.max) {
      newWidget.row = widgetType.row.max
    }
    if(!newWidget.id) {
      newWidget.id = uuid();
    }

    this.saveWidgets(widgets);
    this.setState(newState);
  }

  deleteWidgetFromOrganisation = (widgetToDelete) => {
    let index = this.getWidgets().findIndex((widget) => widget.id === widgetToDelete.id);
    if(index !== -1) {
      let widgets = [...this.state.editedWidgets]
      widgets.splice(index, 1);
      this.setState({
        editedWidgets: widgets
      })
    }
  }

  enterOrganisationMode = () => {
    let widgets = this.getWidgets();
    this.setState({
      organisationMode: true,
      editedWidgets: widgets
    })
  }

  leaveOrganisationMode = (cancel = false) => {
    if(cancel) {
      this.setState({
        organisationMode: false,
        editedWidgets: null
      })
    }
    else {
      let widgets = [...this.state.editedWidgets]
      this.setState({
        organisationMode: false,
        editedWidgets: null
      }, () => {
        this.saveWidgets(widgets);
      })
    }
  }

  selectDefaultDashboard = (dashboard) => {
    let widgets = [...this.getDefaultWidgetsPositions()];

    dashboard.widgets.forEach((widget) => {
      let index = widgets.findIndex(value => value.id === widget.id);

      if(index === -1) {
        index = widgets.push({
          id: widget.id,
          positions: {}
        }) - 1;
      }

      if(!widgets[index].positions[this.state.dashboard]) {
        widgets[index].positions[this.state.dashboard] = {}
      }

      widgets[index].positions[this.state.dashboard][dashboard.dashboardSize] = widget.position
    });

    this.saveDefaultWidgetsPositions(widgets);
  }

  modifyParameterBag = (key, value) => {
    let parameterBag = Object.assign({}, this.state.parameterBag);
    parameterBag[key] = value;
    this.setState({
      parameterBag: parameterBag
    })
  }

  getDefaultWidgetsPositions = () => {
    return this.state.defaultWidgetsPositions;
  }

  saveDefaultWidgetsPositions = (defaultWidgetsPositions) => {
    if(this.state.defaultWidgetsPositionsInitialized) {
      this.editDefaultWidgetPosition(this.state.defaultWidgetsPositionsId, {
        data: JSON.stringify(defaultWidgetsPositions)
      })
    }
    else {
      this.newDefaultWidgetPosition({
        identification: "dashboard_default_widgets",
        data: JSON.stringify(defaultWidgetsPositions)
      })
    }
  }

  /**
   * @var from :
   *    - "all": get all widgets
   *    - "default": get only default widgets
   *    - "personal": get onyl personal widgets
   */
  getWidgets = (from = "all", dashboard = -1, computeCurrentPosition = false, totalColumn = -1) => {
    let widgets = null;
    if(this.state.organisationMode) {
      widgets = [...this.state.editedWidgets];
    }
    else {
      widgets = [...this.state.widgets];

      defaultWidgets.widgets
        .filter(defaultWidget => !defaultWidget.roles || defaultWidget.roles.every(role => this.props.credentials.roles.includes(role)))
        .forEach((defaultWidget) => {
          let positions = this.getDefaultWidgetsPositions().find(value => value.id === defaultWidget.id)
          let widget = Object.assign({}, defaultWidget);
          if(positions) {
            Object.assign(widget, positions);
          }
          widgets.push(widget);
        }
      );
    }

    if(from === "default") {
      widgets = widgets.filter((widget) => widget.default === true);
    }
    else if(from === "personal") {
      widgets = widgets.filter((widget) => widget.default !== true);
    }

    if(dashboard !== -1 && totalColumn !== -1) {
      widgets = widgets.filter((widget) => {
        if(widget.positions && Object.keys(widget.positions).includes(String(dashboard)) && Object.keys(widget.positions[dashboard]).includes(String(totalColumn)) && typeof widget.positions[dashboard][totalColumn] === 'number') {
          if(computeCurrentPosition === true) {
            widget.currentPosition = widget.positions[dashboard][totalColumn];
          }
          return true;
        }
        return false;
      })
    }
    else if(dashboard !== -1) {
      widgets = widgets.filter((widget) => {
        if(Object.keys(widget.positions).includes(String(dashboard)) && Object.keys(widget.positions[dashboard]).includes(String(8)) && typeof widget.positions[dashboard][String(8)] === 'number') {
          if(computeCurrentPosition === true) {
            widget.currentPosition = widget.positions[dashboard][8];
          }
          return true;
        }
        return false;
      })
    }
    return widgets;
  }

  saveWidgets = (widgets) => {
    if(this.state.organisationMode) {
      this.setState({
        editedWidgets: widgets
      })
    }
    else {
      let defaultWidgets = [];
      let personalWidgets = [];
      //delete widget
      widgets.forEach((widget) => {
        if(widget.default === true) {
          if(widget.positions)
          defaultWidgets.push({
            id: widget.id,
            positions: widget.positions
          });
        }
        else {
          let savedWidget = this.state.widgets.find(item => item.id === widget.id);
          let valid = true;
          if(!savedWidget || !isDeepEqual(widget, savedWidget)) {
            if(widget.serverId) {
              let string = Object.assign({}, widget, {
                serverId: undefined
              })
              this.editWidget(widget.serverId, {
                data: JSON.stringify(string)
              })
            }
            else {
              valid = false;
              this.newWidget({
                identification: "dashboard_widget",
                data: JSON.stringify(widget)
              })
            }
          }
          if(valid) {
            personalWidgets.push(widget);
          }
        }
      });

      this.state.widgets.forEach((widget) => {
        if(!personalWidgets.find(item => item.id === widget.id)) {
          if(widget.serverId) {
            this.deleteWidget(widget.serverId)
          }
        }
      });

      this.saveDefaultWidgetsPositions(defaultWidgets);
      this.setState({
        widgets: personalWidgets
      })
    }
  }

  getCopyWidgetString = (widget) => {
    if(!widget) {
      widget = this.state.editedWidget;
    }
    let copy_widget = Object.assign({}, widget, {
      id: undefined,
      serverId: undefined,
      positions: undefined,
      currentPosition: undefined,
    })
    let result = JSON.stringify(copy_widget);
    result = stringToBase64(result);
    return result;
  }

  copyWidget = (widget = null) => {
    if(navigator.clipboard) {
      navigator.clipboard.writeText(this.getCopyWidgetString(widget));
    }
    else {
      this.setState({
        showCopyWidget: !this.state.showCopyWidget
      })
    }
  }

  pasteWidget = async (widgetString = null) => {
    if(!widgetString) {
      // const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
      // const permissionStatus = await navigator.permissions.query(queryOpts);
      // Will be 'granted', 'denied' or 'prompt':
      // console.log(permissionStatus.state);

      if(navigator.clipboard && typeof navigator.clipboard.read === "function") {
        const clipboardContents = await navigator.clipboard.read();
        for (const clipboardContent of clipboardContents) {
          for (const mimeType of clipboardContent.types) {
            switch (mimeType) {
              case 'text/plain':
                const blob = await clipboardContent.getType(mimeType);
                widgetString = await blob.text();
                break;
              default:
            }
          }
        }
      }
      else {
        //fallback when clipboard not available
        this.setState({
          showPasteWidget: true
        })
        return;
      }
    }

    if(widgetString !== null) {
      try {
        let widget = JSON.parse(base64ToString(widgetString));
        widget.serverId = undefined;
        widget.id = undefined;
        this.setState({
          editedWidget: widget,
          showPasteWidget: false
        })
      } catch (e) {

      }
    }

  }

  getTotalColumn = () => {
    if(this.gridRef.current) {
      return this.gridRef.current.getTotalColumn();
    }
    return 8;
  }

  getTotalRow = (dashboard) => {
    // return 4;
    let totalColumn = this.getTotalColumn();
    return this.getWidgets("all", dashboard, true, totalColumn).reduce((acc, widget) => {
      let row = Math.floor(widget.currentPosition / totalColumn) + widget.row;
      return Math.max(acc, row)
    }, 0);
  }

  getWidgetWidth = (widget = null) => {
    let width = null;
    if(this.gridRef.current) {
      width = this.gridRef.current.getBaseWidth() * widget.column;
      return width+'px';
    }
    return '12.5%';
  }

  getHighestPosition = (dashboard) => {
    let totalColumn = this.getTotalColumn();
    return this.getWidgets("all", dashboard, true, totalColumn).reduce((acc, widget) => {
      let position = widget.currentPosition
      if(widget.column > 1) {
        position += (widget.column-1);
      }
      if(widget.row > 1) {
        position += (widget.row-1)*totalColumn + 1;
      }
      return Math.max(acc, position)
    }, 0);
  }

  getTotalHeight = (dashboard) => {
    if(this.gridRef.current) {
      return (this.getTotalRow(dashboard) * (this.gridRef.current.getBaseWidth() + 2 * this.gridRef.current.getBaseMargin()))+'px';
    }
    return (this.getTotalRow(dashboard)*12.5)+'%';
  }

  displayDragLayer() {
    return (
      <DashboardDragLayer/>
    )
  }

  displayDropGrid(inFront = false) {
    if(this.state.organisationMode) {
      let highestPosition = this.getHighestPosition(this.state.dashboard);
      let totalColumn = this.getTotalColumn();
      highestPosition = totalColumn * Math.ceil((highestPosition+1) / totalColumn + 1);
      return (
        <Grid additionalGridStyle={{zIndex: inFront?"1000":"0"}}>
          {Array(highestPosition).fill(0).map((item, key) => {
            return (
              <Box key={key} position={key} column={1} row={1}>
                <DashboardGridSlot position={key} replaceBox={this.replaceBox}>
                  <div style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px',
                    backgroundColor: 'gray',
                    opacity: 0.1
                  }}/>
                </DashboardGridSlot>
              </Box>
            )
          })}
        </Grid>
      )
    }
  }

  displayHeightControl() {
    return (
      <div className="dashboard-height-control-container">
        <div className="dashboard-height-control" style={{ height: this.getTotalHeight(this.state.dashboard) }} />
      </div>
    )
  }

  displayWidget(widget, key) {
    if(this.state.gridRef > 0) {
      let widgetType = this.props.conf.widgets.find(widgetType => widgetType.id === widget.type);
      if(this.state.organisationMode) {
        return (
          <Box key={widget.id} position={widget.currentPosition} column={widget.column} row={widget.row}>
            <DashboardGridWidget id={widget.id} position={widget.currentPosition} title={widget.title} width={this.getWidgetWidth(widget)} swapBox={this.swapBox} notifyIsDragging={this.notifyIsDragging}>
              <WidgetSelector
                type={widget.type}

                id={widget.id}
                title={widget.title}
                conf={widget.conf}
                column={widget.column}
                row={widget.row}

                startEditConf={() => this.startEditConf(widget)}

                widgetType={widgetType}

                parameterBag={this.state.parameterBag}
                modifyParameterBag={this.modifyParameterBag}

                history={this.props.history}
                credentials={this.props.credentials}
              />
            </DashboardGridWidget>
          </Box>
        )
      }
      else {
        return (
          <Box key={widget.id} position={widget.currentPosition} column={widget.column} row={widget.row}>
            <WidgetSelector
              type={widget.type}

              id={widget.id}
              title={widget.title}
              conf={widget.conf}
              column={widget.column}
              row={widget.row}

              startEditConf={() => this.startEditConf(widget)}

              widgetType={widgetType}

              parameterBag={this.state.parameterBag}
              modifyParameterBag={this.modifyParameterBag}

              history={this.props.history}
              credentials={this.props.credentials}
            />
          </Box>
        )
      }
    }
  }

  displayStoredWidget(widget, key) {
    let widgetType = this.props.conf.widgets.find(widgetType => widgetType.id === widget.type);
    let position = null;
    if(widget.positions && widget.positions[this.state.dashboard] && (widget.positions[this.state.dashboard][this.getTotalColumn()] || widget.positions[this.state.dashboard][this.getTotalColumn()] === 0)) {
      position = widget.positions[this.state.dashboard][this.getTotalColumn()];
    }
    return (
      <div key={key} className={`dashboard-storage-drawer-preview-widget-container${position !== null?" dashboard-storage-drawer-preview-widget-container-selected":""}`}>
        <DashboardStoredWidget id={widget.id} title={widget.title} width={this.getWidgetWidth(widget)} notifyIsDragging={this.notifyIsDragging}>
          <div className="d-flex flex-row justify-content-between">
            <div className="dashboard-storage-item">{widget.title}</div>
            <div>
              {!widget.default?<Button variant="my-secondary-noline" className="dashboard-storage-delete" onClick={() => this.startEditConf(widget)}><i className="icon-gear"/></Button>:null}
              {!widget.default?<Button variant="my-secondary-noline" className="dashboard-storage-delete" onClick={() => this.deleteWidgetFromOrganisation(widget)}><i className="icon-cross"/></Button>:null}
            </div>
          </div>
          <div>{widgetType.name} {widget.column}x{widget.row}{position !== null?" Position : "+position:""}</div>
        </DashboardStoredWidget>
      </div>
    )
  }

  displayWidgets(widgets) {
    if(widgets) {
      return (
        <Grid ref={this.defineGridRef} additionalGridClassName="dashboard-main-grid">
          {widgets.map((widget, key) => this.displayWidget(widget, key))}
        </Grid>
      )
    }
  }

  displayStorageDropbox() {
    return (
      <div className="dashboard-storage-dropbox-container">
        <Collapse in={this.state.isDragging}>
          <div>
            <DashboardStorageSlot storeBox={this.storeBox}>
              <div className="d-flex justify-content-center align-items-center dashboard-storage-dropbox-zone">
                <i className="icon-download"/>
              </div>
            </DashboardStorageSlot>
          </div>
        </Collapse>
      </div>
    )
  }

  displayStorage = () => {
    return (
      <>
        {this.displayStorageDropbox()}
        <div className="d-flex flex-row justify-content-around">
          <Button variant="my-primary-outline" className="dashboard-storage-button" onClick={() => this.setState({organisationStorageFrom: "default"})}>{strings.dashboard.main.storage.buttons.default}</Button>
          <Button variant="my-primary-outline" className="dashboard-storage-button" onClick={() => this.setState({organisationStorageFrom: "personal"})}>{strings.dashboard.main.storage.buttons.personal}</Button>
        </div>
        <div className="dashboard-storage-filter-container">
          <Col>
            <MyForm
              formKey="dashboard-storage-filter"
              fields={[
                {
                  fieldKey: "title",
                  type: "text",
                  label: strings.dashboard.main.storage.form.title,
                  showOnDisabled: () => false,
                  validators: []
                }
              ]}
              disabled={false}
              values={this.state.storageFilter}
              onChangeField={(key, value) => {
                let storageFilter = Object.assign({}, this.state.storageFilter)
                storageFilter[key] = value
                this.setState({storageFilter: storageFilter})
              }}
              displaySubmitButton={false}

              formSizeMd={12}
              fieldColMd={12}

              fieldVariant="dashboard"
            />
          </Col>
        </div>
        <div className="d-flex flex-column dashboard-storage-drawer-preview-widgets-container">
          {this.getWidgets(this.state.organisationStorageFrom).filter((widget) => {
            if(this.state.storageFilter.title) {
              return widget.title.includes(this.state.storageFilter.title);
            }
            return true
          }).map((widget, key) => this.displayStoredWidget(widget, key))}
        </div>
      </>
    )
  }

  displayWidgetSettings() {
    let widgetType = null;
    if(this.state.editedWidget !== null) {
      widgetType = this.props.conf.widgets.find((item) => item.id === this.state.editedWidget.type)
    }
    return (
      <Modal show={this.state.editedWidget !== null} centered={true} className="dashboard-modal" backdropClassName="dashboard-modal-backdrop" onHide={() => this.setState({editedWidget: null})}>
        <Modal.Body>
          <Row className="modal-panel-title">
            <Col xs="auto" className="modal-panel-title-label">{strings.dashboard.editWidget.main.panel.name}</Col>
            <Col>
              <Form.Control
                type="text"
                className="modal-panel-title-input"
                value={(this.state.editedWidget && this.state.editedWidget.title)?this.state.editedWidget.title:""}
                onChange={(event) => this.setState({editedWidget: Object.assign({}, this.state.editedWidget, {title: event.target.value})})}
              />
            </Col>
            <Col xs="auto" className="">
              <Button variant="my-primary-noline" onClick={() => this.copyWidget()}><i className="icon-copy"/></Button>
              {!this.state.showPasteWidget?<Button variant="my-primary-noline" onClick={() => this.pasteWidget()}><i className="icon-document-edit"/></Button>:null}
            </Col>
          </Row>
          {this.state.showCopyWidget?<Row className="modal-panel-title">
            <Col xs="auto" className="modal-panel-title-label">Copier:</Col>
            <Col>
              <Form.Control
                type="text"
                className="modal-panel-title-input"
                value={this.getCopyWidgetString()}
                onChange={(event) => null}
              />
            </Col>
            <Col xs="auto" className="">
              <Button variant="my-primary-noline" onClick={() => this.setState({ showCopyWidget: false })}><i className="icon-cross"/></Button>
            </Col>
          </Row>:undefined}
          {this.state.showPasteWidget?<Row className="modal-panel-title">
            <Col xs="auto" className="modal-panel-title-label">Coller:</Col>
            <Col>
              <Form.Control
                type="text"
                className="modal-panel-title-input"
                value={(this.state.pasteWidgetString)?this.state.pasteWidgetString:""}
                onChange={(event) => this.setState({ pasteWidgetString: event.target.value })}
              />
            </Col>
            <Col xs="auto" className="">
              <Button variant="my-primary-noline" onClick={() => this.setState({ showPasteWidget: false })}><i className="icon-cross"/></Button>
              <Button variant="my-primary-noline" onClick={() => this.pasteWidget(this.state.pasteWidgetString)}><i className="icon-checkmark"/></Button>
            </Col>
          </Row>:undefined}
          <div className="modal-panel-informations">
            <Card>
              <Card.Header>{strings.dashboard.editWidget.main.panel.general}</Card.Header>
              <Card.Body>
                <Col className="no-gutters">
                  <MyForm
                    formKey={editWidgetPage.formKey}
                    fields={editWidgetPage.fields.map(field => {
                      if(field.fieldKey === 'type' && this.state.editedWidget && this.state.editedWidget.id) {
                        return Object.assign({}, field, {
                          disabled: true
                        })
                      }
                      return field
                    })}
                    extraValues={Object.assign({}, editWidgetPage.extraValues, {dashboardConf: this.props.conf})}
                    disabled={false}
                    values={this.state.editedWidget?this.state.editedWidget:{}}
                    onChangeField={(key, value) => {
                      let editedWidget = Object.assign({}, this.state.editedWidget)
                      editedWidget[key] = value
                      this.setState({editedWidget: editedWidget})
                    }}
                    displaySubmitButton={false}

                    numberColumn={2}
                    formSizeMd={12}

                    fieldVariant="dashboard"
                  />
                </Col>
              </Card.Body>
            </Card>
          </div>
          <div className="modal-panel-informations">
            <Card>
              <Card.Header>{strings.dashboard.editWidget.main.panel.element}</Card.Header>
              <Card.Body>
                <Col className="no-gutters">
                  <MyForm
                    formKey={`${widgetType?widgetType.id:""}-element-settings`}
                    fields={widgetType && widgetType.elementSettings?[
                      {
                        fieldKey: "element",
                        type: "dropdown",
                        label: strings.dashboard.editWidget.form.element.label,
                        showOnDisabled: () => true,
                        validators: [],

                        showEmpty: true,
                        input: 'options',
                        filterOptions: (option, getValues, getExtraValues) => {
                          let credentials = getExtraValues().credentials;
                          if(credentials) {
                            if(widgetType.elementSettings[option.id].role) {
                              let roles = widgetType.elementSettings[option.id].role;
                              // if(typeof roles === 'function') {
                              //   roles = roles(option, getValues, getExtraValues);
                              // }
                              if(!Array.isArray(roles)) {
                                roles = [roles];
                              }

                              return roles.some(role => credentials.roles.includes(role));
                            }
                            return true;
                          }
                          return false;
                        },
                        options: Object.keys(widgetType.elementSettings)
                          .map(key => {
                            return {
                              id: key,
                              name: widgetType.elementSettings[key].name
                            }
                          })
                        ,

                        adapter: {
                          requirement: (data) => data["id"] && data["name"],
                          getValue: (data) => data["id"],
                          getLabel: (data) => <div>{data["icon"]?<><i className={data["icon"]}/>&nbsp;&nbsp;&nbsp;</>:""}{data["name"]}</div>,
                          isValue: true
                        },
                      }
                    ]:[]}
                    extraValues={Object.assign({}, {credentials: this.props.credentials})}
                    disabled={(this.state.editedWidget && this.state.editedWidget.id)?true:false}
                    values={this.state.editedWidget?this.state.editedWidget.conf:{}}
                    onChangeField={(key, value) => {
                      let editedWidget = Object.assign({}, this.state.editedWidget)
                      editedWidget.conf = Object.assign({}, this.state.editedWidget.conf)
                      editedWidget.conf[key] = value
                      this.setState({editedWidget: editedWidget})
                    }}
                    displaySubmitButton={false}

                    numberColumn={1}
                    formSizeMd={12}

                    fieldVariant="dashboard"
                  />
                </Col>
              </Card.Body>
            </Card>
          </div>
          <div className="modal-panel-informations">
            <Card>
              <Card.Header>{strings.dashboard.editWidget.main.panel.visualSettings}</Card.Header>
              <Card.Body>
                <Col className="no-gutters">
                  <MyForm
                    ref={this.visualSettingsForm}
                    formKey={`${widgetType?widgetType.id:""}-visual-settings`}
                    fields={widgetType?widgetType.visualSettings:[]}
                    extraValues={Object.assign({}, {
                      dashboardConf: this.props.conf,
                      widgets: this.getWidgets(),
                      credentials: () => this.props.credentials
                    })}
                    disabled={false}
                    values={this.state.editedWidget?this.state.editedWidget.conf:{}}
                    onChangeField={(key, value) => {
                      let editedWidget = Object.assign({}, this.state.editedWidget)
                      editedWidget.conf = Object.assign({}, this.state.editedWidget.conf)
                      editedWidget.conf[key] = value
                      this.setState({editedWidget: editedWidget})
                    }}
                    displaySubmitButton={false}
                    resetValuesOnSubmit={true}
                    onSubmit={this.onSubmitSettingForm}
                    onSubmitFailed={this.onSubmitFailedSettingForm}

                    numberColumn={1}
                    formSizeMd={12}

                    fieldVariant="dashboard"
                  />
                </Col>
              </Card.Body>
            </Card>
          </div>
          <div className="modal-panel-informations">
            <Card>
              <Card.Header>{strings.dashboard.editWidget.main.panel.dataSettings}</Card.Header>
              <Card.Body>
                <Col className="no-gutters">
                  <MyForm
                    ref={this.dataSettingsForm}
                    formKey={`${widgetType?widgetType.id:""}-data-settings`}
                    fields={widgetType?widgetType.dataSettings:[]}
                    extraValues={Object.assign({}, {
                      dashboardConf: this.props.conf,
                      widgets: this.getWidgets(),
                      credentials: () => this.props.credentials
                    })}
                    disabled={false}
                    values={this.state.editedWidget?this.state.editedWidget.conf:{}}
                    onChangeField={(key, value) => {
                      let editedWidget = Object.assign({}, this.state.editedWidget)
                      editedWidget.conf = Object.assign({}, this.state.editedWidget.conf)
                      editedWidget.conf[key] = value
                      this.setState({editedWidget: editedWidget})
                    }}
                    displaySubmitButton={false}
                    resetValuesOnSubmit={true}
                    onSubmit={this.onSubmitSettingForm}
                    onSubmitFailed={this.onSubmitFailedSettingForm}

                    numberColumn={2}
                    formSizeMd={12}

                    fieldVariant="dashboard"
                  />
                </Col>
              </Card.Body>
            </Card>
          </div>
          <div className="modal-panel-buttons d-flex justify-content-end">
            <Button variant="my-warning" disabled={this.state.submittingEditWidget} onClick={() => this.setState({editedWidget: null})}>
              {strings.dashboard.editWidget.button.cancel}
            </Button>
            <Button variant="my-validated" disabled={this.state.submittingEditWidget} onClick={() => this.startWidgetSubmit()}>
              {strings.dashboard.editWidget.button.save}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  customCenterHeader = () => {
    return (
      <div className="d-flex justify-content-between dashboard-header-button-container">
        <div>
          <Button variant="my-secondary-noline" className="dashboard-header-button"><i className="icon-plus" onClick={() => this.addNewWidget()}/></Button>
          <Button variant="my-secondary-noline" className="dashboard-header-button" onClick={() => this.state.organisationMode?this.leaveOrganisationMode(true):this.enterOrganisationMode()}><i className="icon-view-thumb"/></Button>
        </div>
        <div>
          <Button variant="my-secondary-noline" className={`dashboard-header-button${this.state.dashboard===0?" dashboard-header-button-selected":""}`} onClick={() => this.setState({ dashboard: 0 })}>1</Button>
          <Button variant="my-secondary-noline" className={`dashboard-header-button${this.state.dashboard===1?" dashboard-header-button-selected":""}`} onClick={() => this.setState({ dashboard: 1 })}>2</Button>
          <Button variant="my-secondary-noline" className={`dashboard-header-button${this.state.dashboard===2?" dashboard-header-button-selected":""}`} onClick={() => this.setState({ dashboard: 2 })}>3</Button>
        </div>
        <div></div>
      </div>
    )
  }

  displayOrganisationButtons() {
    if(this.state.organisationMode) {
      return (
        <SubFooter additionalClassName="dashboard-sub-footer">
          <Button
            variant="my-warning"
            className={`button-form-row ${this.props.buttonExtraClassName}`}
            onClick={() => this.leaveOrganisationMode(true)}>
            {strings.dashboard.main.organisationMode.buttons.cancel}
          </Button>
          <Button
            variant="my-validated"
            className={`button-form-row ${this.props.buttonExtraClassName}`}
            onClick={() => this.leaveOrganisationMode()}>
            {strings.dashboard.main.organisationMode.buttons.validate}
          </Button>
        </SubFooter>
      );
    }
  }

  displayDefaultDashboardSelectionOptions() {
    let dashboards = defaultDashboards
      .filter(dashboard => !dashboard.roles || dashboard.roles.every(role => this.props.credentials.roles.includes(role)))
      .filter(dashboard => dashboard.dashboardSize === this.getTotalColumn());
    return (
      <>
        {dashboards.map((dashboard, i) => {
          return (
            <Button key={i} variant="my-information" className="dashboard-default-selection-button" onClick={() => this.selectDefaultDashboard(dashboard)}>{dashboard.name}</Button>
          )
        })}
      </>
    )
  }

  displayDefaultDashboardSelection(widgets) {
    if(widgets.length === 0 && !this.state.organisationMode) {
      return (
        <div className="dashboard-default-selection">
          <Card>
            <Card.Header>Quel tableau de bord souhaitez-vous ?</Card.Header>
            <Card.Body>
              {this.displayDefaultDashboardSelectionOptions()}
            </Card.Body>
          </Card>
        </div>
      )
    }
  }

  render() {
    let totalColumn = this.getTotalColumn();
    let widgets = this.getWidgets("all", this.state.dashboard, true, totalColumn);
    return (
      <MainLayout centerHeader="custom" customCenterHeader={this.customCenterHeader()} leftMenu={this.state.organisationMode?"custom":"mainMenu"} customLeftMenu={this.state.organisationMode?this.displayStorage():<div/>}>
        <React.Suspense fallback={<></>}>
          <DashboardTheme />
        </React.Suspense>
        {this.getKeepDimension()?
          <React.Suspense fallback={<></>}>
            <DashboardKeepDimensionTheme />
          </React.Suspense>
          :undefined
        }
        {this.displayDragLayer()}
        {this.displayDropGrid(this.state.isDragging)}
        {this.displayHeightControl()}
        {this.displayWidgets(widgets)}
        {this.displayOrganisationButtons()}
        {this.displayDefaultDashboardSelection(widgets)}
        {this.displayWidgetSettings()}
      </MainLayout>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials,
  dashboard: state.persisted.dashboard
})

export default connect(mapStateToProps)(withModalHandler(Dashboard));
