import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of profils based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('profils', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single profil based on his ID
 *
 * @param id the of the profil requested
 */
export function getFromId(id) {
  return baseGetFromId('profils', id);
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single profil based on his ID
 *
 * @param id the of the profil requested
 * @param data the new data of the profil
 */
export function editFromId(id, data) {
  return baseEditFromId('profils', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new profil
 *
 * @param data the new data of the profil
 */
export function newItem(data) {
  return baseNewItem('profils', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all family to serve in a dropdown form choice
 */
export function fetchProfils() {
  return baseIndex('profils', 1, 0);
}

/**
 * Fetch all family to serve in a dropdown form choice
 */
export function fetchInnerProfils() {
  let search = {
    available_inner: 1
  }
  return baseIndex('profils', 1, 0, search);
}

/**
 * Fetch all family to serve in a dropdown form choice
 */
export function fetchPartnerProfils() {
  let search = {
    available_partner: 1
  }
  return baseIndex('profils', 1, 0, search);
}
