import React from 'react';

import PartSelection from '../PartSelection';

/**
 */
class AbstractPlacement extends React.Component {

  static defaultProps = {
    partChildren: null
  }

  displayChildren() {
    if(this.props.partChildren && Array.isArray(this.props.partChildren)) {
      return (
        <>
          {this.props.partChildren.map((child, i) => {
            return (
              <PartSelection key={i} {...child} />
            )
          })}
        </>
      )
    }
  }

  render() {
    console.warn('this component should not be used as is.')
    return (
      <div/>
    );
  }
}

export default AbstractPlacement;
