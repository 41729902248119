import React from 'react';

import { Form, InputGroup, Button, Alert } from 'react-bootstrap';
import strings from '../../Localization/Localization';

import '../../css/login.css';

import { connect } from 'react-redux';

import { login, resetPassword } from '../../API/Login';

import {
  MESSAGE_USER_NOT_FOUND,
  MESSAGE_INVALID_CREDENTIALS,
  MESSAGE_TOO_MANY_ATTEMPT,
  // MESSAGE_PERMISSION_DENIED
} from '../../API/config'

import { addCredentialsAction } from '../../Store/Action/credentials'

import Captcha from '../Basic/Captcha';
import { validateCaptcha } from 'react-simple-captcha';

/**
 * Login
 *
 * This component is used to log in user.
 *
 * This form ask for a username and a password, then will try to connect to
 * the backend of the application to validate the credentials
 */
class LoginForm extends React.Component {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the component.
   */
  static defaultProps = {
    onAuthenticationSuccess: () => null,
    onAuthenticationFailure: () => null,
    showSubmitButton: true,
    containerClassname: "login-form",
    forgottenPassword: true,
    additionalLinks: [
      // {
      //   key: 'forgottenPassword',
      //   label: 'forgottenPassword',
      //   to: '#'
      // }
    ]
  }

  constructor(props) {
    super(props);

    //state of the component, including :
    // - user : the username of the user we want to log in
    // - password : the password of the user we want to log in
    // - errorMessage : the error message if log in failed
    this.state = {
      user: '',
      password: '',
      errorMessage: '',
      forgottenPasswordMode: false,
      passwordResetMessage: false
    }
  }

  /**
   * Display the error message if there is one to display
   * This will allow the end user to see what didn't happen correctly with his
   * username and password
   */
  displayErrorMessage(){
    //check if ther is an error message
    if(this.state.errorMessage){
      return (
        <Alert variant='danger'>
          {this.state.errorMessage}
        </Alert>
      )
    }
  }

  /**
   * Display the error message if there is one to display
   * This will allow the end user to see what didn't happen correctly with his
   * username and password
   */
  displayPasswordResetMessageMessage(){
    //check if ther is an error message
    if(this.state.passwordResetMessage){
      return (
        <Alert
          variant='success'
          dismissible
          onClose={() => this.setState({passwordResetMessage: false})}>
          {strings.login.passwordResetMessage}
        </Alert>
      )
    }
  }

  /**
   * Display the button to submit the log in attempt
   * If the button is not displayed, then it's up to the parent component to
   * start the submission
   */
  displaySubmitButton(){
    //check if it should be displayed or not
    if(this.props.showSubmitButton){
      return (
        <Button type="submit" className="login-form-submit" variant="my-information" onClick={this.submit}>
          {this.state.forgottenPasswordMode?strings.form.login.submitForgottenPassword:strings.form.login.submit}
        </Button>
      )
    }
  }

  /**
   * Display forgotten password
   */
  displayForgottenPassword(){
    //check if there is additional link to display
    if(this.props.forgottenPassword){
      return (
        <div className="d-flex justify-content-center align-items-center">
          <Button variant="my-secondary-noline" key="forgottenPassword" className="login-form-additional-link" onClick={() => this.setState({forgottenPasswordMode: !this.state.forgottenPasswordMode})}>{this.state.forgottenPasswordMode?strings.login.forgottenPasswordBackButton:strings.login.forgottenPasswordButton}</Button>
        </div>
      )
    }
  }

  /**
   * Display additional link between the password field and the action button
   */
  displayAdditionalLink(){
    //check if there is additional link to display
    if(this.props.additionalLinks.length > 0){
      return (
        <div className="d-flex justify-content-center align-items-center">
          {this.props.additionalLinks.map((additionalLink) =>
            <a key={additionalLink.key} className="login-form-additional-link" href={additionalLink.to}>{strings.form.login.additionalLinks[additionalLink.label]}</a>
          )}
        </div>
      )
    }
  }

  displayAuthenticationMode() {
    if(!this.state.forgottenPasswordMode) {
      return (
        <Form className={this.props.containerClassname}>
          <Form.Group controlId="formBasicUser">
            <InputGroup>
              <Form.Label className="sr-only">{strings.form.login.user}</Form.Label>
              <Form.Control
                className="login-form-user"
                placeholder={strings.form.login.user}
                value={this.state.user}
                onChange={(event => this.setState({user: event.target.value}))} />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <InputGroup>
              <Form.Label className="sr-only">{strings.form.login.password}</Form.Label>
              <Form.Control
                className="login-form-password"
                type="password"
                placeholder={strings.form.login.password}
                value={this.state.password}
                onChange={(event => this.setState({password: event.target.value}))} />
            </InputGroup>
          </Form.Group>
          {this.displayErrorMessage()}
          {this.displayPasswordResetMessageMessage()}
          {this.displaySubmitButton()}
          {this.displayForgottenPassword()}
          {this.displayAdditionalLink()}
        </Form>
      );
    }
  }

  displayForgottenPasswordMode() {
    if(this.state.forgottenPasswordMode) {
      return (
        <Form className={this.props.containerClassname}>
          <Form.Group controlId="formBasicUser">
            <InputGroup>
              <Form.Label className="sr-only">{strings.form.login.user}</Form.Label>
              <Form.Control
                className="login-form-user"
                placeholder={strings.form.login.user}
                value={this.state.user}
                onChange={(event => this.setState({user: event.target.value}))} />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formBasicCaptcha">
            <InputGroup>
              <Form.Label className="sr-only">{strings.form.login.captcha}</Form.Label>
              <Form.Control
                className="login-form-captcha"
                placeholder={strings.form.login.captcha}
                value={this.state.captcha}
                onChange={(event => this.setState({captcha: event.target.value}))} />
            </InputGroup>
          </Form.Group>
          <Captcha/>
          {this.displayErrorMessage()}
          {this.displaySubmitButton()}
          {this.displayForgottenPassword()}
        </Form>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayAuthenticationMode()}
        {this.displayForgottenPasswordMode()}
      </>
    )
  }

  submit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if(this.state.forgottenPasswordMode) {
      if(validateCaptcha(this.state.captcha) === true) {
        resetPassword(this.state.user).then(
          (data) => {
            if(data.result === "ok") {
              this.setState({
                errorMessage: '',
                forgottenPasswordMode: false,
                passwordResetMessage: true
              });
            }
            else if(data.result === "user not found") {
              this.setState({errorMessage: strings.form.login.error.wrongCaptcha});
            }
          }
        )
      }
      else {
        this.setState({errorMessage: strings.form.login.error.wrongCaptcha})
      }
    }
    else {
      login(this.state.user, this.state.password).then(
        (data) => {
          if(data.status && data.status === 'error') {
            let messageTranslated = null;
            switch (data["body"]["status_text"]) {
              case MESSAGE_USER_NOT_FOUND:
                messageTranslated = strings.form.login.error.userNotFound;
                break;
              case MESSAGE_INVALID_CREDENTIALS:
                messageTranslated = strings.form.login.error.invalidCredentials;
                break;
              case MESSAGE_TOO_MANY_ATTEMPT:
                messageTranslated = strings.form.login.error.tooManyAttempt;
                break;
              // case MESSAGE_PERMISSION_DENIED:
              //   messageTranslated = strings.form.login.error.permissionDenied;
              //   break;
              default:
                messageTranslated = strings.form.login.error.error;
            }
            this.setState({errorMessage: messageTranslated});
            this.props.onAuthenticationFailure();
          }
          else {
            this.props.dispatch(addCredentialsAction(data["id"], this.state.user, data["auth_token"], data["refresh_token"], data["roles"], data["partners"]));
            this.props.onAuthenticationSuccess();
          }
        }
      );
    }
  }
}


export default connect()(LoginForm);
