export const ParameterTypes = {
  //base
  STRING: 'string',
  DATETIME: 'datetime',

  //entity
  PARTNER: 'partner',
  PLACE: 'place',
  USER: 'user',
}
