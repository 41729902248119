import strings from '../../Localization/Localization';

let title = {
  fieldKey: "title",
  type: "text",
  label: strings.form.preFillComment.title.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.preFillComment.title.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let body = {
  fieldKey: "body",
  type: "textarea",
  label: strings.form.preFillComment.body.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.preFillComment.body.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

export const form = {
  formKey: "preFillComment",
  fields: [
    title,
    body,
  ]
}
