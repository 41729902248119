import strings from '../../Localization/Localization';

import { index } from '../../API/Notifications';
import { form as searchForm } from '../Form/Search/notification';

import {
  bills as billsRoutes,
  technicalTicket as technicalTicketsRoutes,
  commercialTicket as commercialTicketsRoutes,
  administrativeTicket as administrativeTicketsRoutes,
  orders as ordersRoutes,
  quotes as quotesRoutes,
} from '../Route/routes';

// import { notifications as notificationsRoutes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.notifications,
    id: "notification",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id, item) => {
        if(item) {
          switch (item.template) {
            case "bill.new.client":
              return billsRoutes.routes.show.createPath(item.arguments.find((argument) => argument.label === '%bill_id%').value);

            case 'ticket.new.client':
            case 'ticket.new.nyukom':
            case 'ticket.edit.client':
            case 'ticket.comment.mixed':
            case 'ticket.appointment.client':
            case 'ticket.file.mixed':
            case 'ticket.close.client':
            case 'ticket.close.nyukom':
            case 'ticket.assign_referee.nyukom':
            case 'ticket.escalate.client':
            case 'ticket.escalate.nyukom':
            case 'ticket.gfrt.nyukom':
            case 'ticket.gfrt_timeout.nyukom':
              let groupArgument = item.arguments.find((argument) => argument.label === '%ticket_group%');
              let idArgument = item.arguments.find((argument) => argument.label === '%ticket_id%');
              if(groupArgument && idArgument) {
                let group = groupArgument.value;
                let id = idArgument.value;
                switch (group) {
                  case 1:
                    return commercialTicketsRoutes.routes.show.createPath(id);
                  case 2:
                    return administrativeTicketsRoutes.routes.show.createPath(id);
                  case 0:
                  default:
                    return technicalTicketsRoutes.routes.show.createPath(id);
                }
              }
              return '#';

            case 'order.new.client':
            case 'order.new.nyukom':
            case 'order.validate.client':
            case 'order.cancellation_period_end.client':
            case 'order.start_delivery.nyukom':
            case 'order.deliver.client':
            case 'order.cancel.client':
            case 'order.cancel.nyukom':
            case 'order.cancel_validated.client':
            case 'order.cancel_validated.nyukom':
              return ordersRoutes.routes.show.createPath(item.arguments.find((argument) => argument.label === '%order_id%').value);

            case 'quote.new.client':
            case 'quote.validated.client':
            case 'quote.signed.nyukom':
              return quotesRoutes.routes.show.createPath(item.arguments.find((argument) => argument.label === '%quote_id%').value);

            case 'user.new':
            case 'user.reset_password':
              return '#';

            case 'report.new.nyukom':
              return '#';
            case "test":
              return '#';
            default:
          }
        }
        return '#';
      }
    },
    searchForm: searchForm,
    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => {
          switch (item.template) {
            case "bill.new.client":
              return strings.formatString(strings.common.notification.bill_new_client, item.arguments.find((argument) => argument.label === '%bill_number%').value);

            case 'ticket.new.client':
              return strings.formatString(strings.common.notification.ticket_new_client, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.new.nyukom':
              return strings.formatString(strings.common.notification.ticket_new_nyukom, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.edit.client':
              return strings.formatString(strings.common.notification.ticket_edit_client, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.comment.mixed':
              return strings.formatString(strings.common.notification.ticket_comment_mixed, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.appointment.client':
              return strings.formatString(strings.common.notification.ticket_appointment_client, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.file.mixed':
              return strings.formatString(strings.common.notification.ticket_file_mixed, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.close.client':
              return strings.formatString(strings.common.notification.ticket_close_client, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.close.nyukom':
              return strings.formatString(strings.common.notification.ticket_close_nyukom, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.assign_referee.nyukom':
              return strings.formatString(strings.common.notification.ticket_assign_nyukom, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.escalate.client':
              return strings.formatString(strings.common.notification.ticket_escalate_client, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.escalate.nyukom':
              return strings.formatString(strings.common.notification.ticket_escalate_nyukom, item.arguments.find((argument) => argument.label === '%ticket_number%').value);
            case 'ticket.gfrt.nyukom':
              return strings.formatString(strings.common.notification.ticket_gfrt_nyukom, item.arguments.find((argument) => argument.label === '%ticket_number%').value, item.arguments.find((argument) => argument.label === '%gfrt_number%').value);
            case 'ticket.gfrt_timeout.nyukom':
              return strings.formatString(strings.common.notification.ticket_gfrt_timeout_nyukom, item.arguments.find((argument) => argument.label === '%ticket_number%').value);

            case 'order.new.client':
              return strings.formatString(strings.common.notification.order_new_client, item.arguments.find((argument) => argument.label === '%order_number%').value);
            case 'order.new.nyukom':
              return strings.formatString(strings.common.notification.order_new_nyukom, item.arguments.find((argument) => argument.label === '%order_number%').value);
            case 'order.validate.client':
              return strings.formatString(strings.common.notification.order_validate_client, item.arguments.find((argument) => argument.label === '%order_number%').value);
            case 'order.cancellation_period_end.client':
              return strings.formatString(strings.common.notification.order_cancellation_period_end_client, item.arguments.find((argument) => argument.label === '%order_number%').value);
            case 'order.start_delivery.nyukom':
              return strings.formatString(strings.common.notification.order_start_delivery_nyukom, item.arguments.find((argument) => argument.label === '%order_number%').value);
            case 'order.deliver.client':
              return strings.formatString(strings.common.notification.order_deliver_client, item.arguments.find((argument) => argument.label === '%order_number%').value);
            case 'order.cancel.client':
              return strings.formatString(strings.common.notification.order_cancel_client, item.arguments.find((argument) => argument.label === '%order_number%').value);
            case 'order.cancel.nyukom':
              return strings.formatString(strings.common.notification.order_cancel_nyukom, item.arguments.find((argument) => argument.label === '%order_number%').value);
            case 'order.cancel_validated.client':
              return strings.formatString(strings.common.notification.order_cancel_validated_client, item.arguments.find((argument) => argument.label === '%order_number%').value);
            case 'order.cancel_validated.nyukom':
              return strings.formatString(strings.common.notification.order_cancel_validated_nyukom, item.arguments.find((argument) => argument.label === '%order_number%').value);

            case 'quote.new.client':
              return strings.formatString(strings.common.notification.quote_new_client, item.arguments.find((argument) => argument.label === '%quote_number%').value);
            case 'quote.validated.client':
              return strings.formatString(strings.common.notification.quote_validated_client, item.arguments.find((argument) => argument.label === '%quote_number%').value);
            case 'quote.signed.nyukom':
              return strings.formatString(strings.common.notification.quote_signed_nyukom, item.arguments.find((argument) => argument.label === '%quote_number%').value);

            case 'user.new':
              return strings.formatString(strings.common.notification.user_new);
            case 'user.reset_password':
              return strings.formatString(strings.common.notification.user_reset_password);

            case 'report.new.nyukom':
              return strings.formatString(strings.common.notification.report_new_nyukom, item.arguments)
            case "test":
              return item.arguments.find((argument) => argument.label === 'message').value;
            default:
          }
        }
      },
    },
    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
}
