import strings from '../../Localization/Localization';

import { fetchProducts } from '../../API/Products';

let discount = {
  fieldKey: "discount",
  type: "text",
  label: strings.form.specificPrice.discount.label,
  validators: []
}

let discountUnit = {
  fieldKey: "discount_unit",
  type: "dropdown",
  label: strings.form.specificPrice.discountUnit.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: '€',
      label: '€',
    },
    {
      value: '%',
      label: '%',
    },
  ],
}

let product = {
  fieldKey: "product",
  type: "dropdownEntity",
  label: strings.form.specificPrice.product.label,

  validators: [],

  showEmpty: true,
  loader: {
    id: "products",
    loader: fetchProducts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

export const form = {
  formKey: "specificPrice",
  fields: [
    discount,
    discountUnit,
    product
  ]
}
