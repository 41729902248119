import strings from '../../../Localization/Localization';

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.accountAnalytic.number.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "accountAnalyticSearch",
  fields: [
    number
  ]
}
