export const RESET_WIDGETS = 'reset_widgets';
export const SAVE_WIDGETS = 'save_widgets';

export function resetWidgets(){
  return {
    type: RESET_WIDGETS
  }
}

export function saveWidgets(widgets = []){
  return {
    type: SAVE_WIDGETS,
    widgets: widgets
  }
}
