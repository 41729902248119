const place = {
  index: {
    tab: {
      headers: {
        partnerName: "Partenaire",
        name: "Nom",
        address: "Adresse",
        service: "Qté Parc"
      }
    },
    buttons: {
      service: "Parc"
    }
  },
  show: {
    header: {
      active: "Site actif",
      inactive: "Site inactif"
    }
  },
  tab: {
    main: "Site",
  },
  search: {
    partner: "Partenaire",
    active: "Actif",
    name: "Nom du site",
    number: "Numéro",
    way: "Voie",
    zipCode: "Code postal",
    city: "Ville",
  },
  tabs: {
    buttons: {
      deactivatePlace: "Désactiver",
      reactivatePlace: "Réactiver",
    }
  },
  new: {
    title: "Nouveau site"
  }
};

export default place;
