import React from 'react';

class ScrollContainer extends React.Component {

  static defaultProps = {
    height: "100px",
    className: "",
    additionalStyle: {}
  }

  render() {
    return (
      <div className={this.props.className} style={{height: this.props.height, overflowY: "scroll", ...this.props.additionalStyle}}>
        {this.props.children}
      </div>
    );
  }
}

export default ScrollContainer;
