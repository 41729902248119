import React from 'react';

import { v4 as uuid } from 'uuid';

import memoize from "memoize-one";

/**
 * CountdownTimer
 */
class CountdownTimer extends React.Component {

  static defaultProps = {
    targetDate: null,
    onFinish: () => null
  }

  constructor(props) {
    super(props);

    this.timer = null;
    this.uuid = "timer_"+uuid();
  }

  componentDidMount() {
    if(this.props.targetDate !== null) {
      this.timer = setInterval(() => {

        // Get today's date and time
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = this.targetTime(this.props.targetDate) - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="demo"
        let message = "";
        if(days > 0) {
          message += days + "jours "
        }
        message += String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0) + ":" + String(seconds).padStart(2, 0);
        document.getElementById(this.uuid).innerHTML = message;

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(this.timer);
          document.getElementById(this.uuid).innerHTML = "FINI";
          this.props.onFinish();
        }
      }, 1000)
    }
  }

  componentWillUnmount() {
    if(this.timer !== null) {
      clearInterval(this.timer);
    }
  }

  targetTime = memoize(function (targetDate) {
    return targetDate.getTime();
  })

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div id={this.uuid}>Calcul...</div>
    );
  }
}

export default CountdownTimer;
