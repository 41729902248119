const productSubFamily = {
  tab: {
    main: "Sous-famille",
    mainEdit: "Editer",
    configuration: "Configuration",
    configurationEdit: "Editer",
    accounting: "Comptabilité",
    datasheet: "Datasheet",
    technicalData: "Données techniques",
    api: "APIs",
    specialConditionOfSale: "Conditions spéciales de vente",
  },
  new: {
    title: "Nouvelle famille de produit",
    steps: {
      generalData: "Informations Générales",
      datasheet: "Définition datasheet",
      technicalData: "Définition données techniques",
      specialConditionOfSale: "Conditions spéciales de vente"
    }
  },
  search: {
    productFamily: "Famille",
    name: "Nom",
    activeOrder: "Actif en commande",
    activeQuote: "Actif en devis",
    activeTicket: "Actif en ticket",
    rentable: "Louable"
  }
};

export default productSubFamily;
