import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

 /******************************************************************************
  * INDEX FUNCTION                                                             *
  ******************************************************************************/

 /**
  * Get a list of accounting types based on a pagination system
  *
  * @param page the page requested
  * @param itemPerPage the number of item in the page requested
  */
 export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
   return baseIndex('accounting_types', page, itemPerPage, search, order, scheme);
 }

 /******************************************************************************
  * GETTER FUNCTION                                                            *
  ******************************************************************************/

 /**
  * Get a single accounting type based on his ID
  *
  * @param id the of the accounting type requested
  */
 export function getFromId(id) {
   return baseGetFromId('accounting_types', id);
 }

 /******************************************************************************
  * SETTER FUNCTION                                                            *
  ******************************************************************************/

 /**
  * Edit a single accounting type based on his ID
  *
  * @param id the of the accounting requested
  * @param data the new data of the accounting type
  */
 export function editFromId(id, data) {
   return baseEditFromId('accounting_types', id, data);
 }

 /******************************************************************************
  * NEW FUNCTION                                                               *
  ******************************************************************************/

 /**
  * Create a new accounting type
  *
  * @param data the new data of the accounting type
  */
 export function newItem(data) {
   return baseNewItem('accounting_types', data);
 }

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of accounting types based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function fetchAccountingTypes(){
  return baseIndex('accounting_types', 1, 0);
}
