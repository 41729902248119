import React from 'react';

/**
 * TableHeader
 */
class TableHeader extends React.Component {

  static defaultProps = {
    subRows: false
  }

  displayHandle() {
    if(this.props.subRows) {
      return <th/>
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <thead>
        <tr className="row-my-primary">
          {this.displayHandle()}
          {this.props.children}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
