import { MODIFY_THEME } from '../Action/theme'

const initialState = {
  name: 'dark',
}

export default function reduce(state = initialState, action){
  let newState
  switch (action.type) {
    case MODIFY_THEME:
      newState = Object.assign({}, {
        name: action.name,
      })
      break;
    default:
      newState = state;
  }

  return newState;
}
