const phonePackage = {
  tab: {
    main: "Détail",
    safeguard: "Garde-fou",
    safeguardPrice: "Prix Inclus"
  },
  search: {
    name: "Nom"
  },
  new: {
    title: "Nouveau forfait téléphonique"
  }
};

export default phonePackage;
