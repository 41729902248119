export const ADD_OPTION = 'add_dropdown_option';
export const START_LOADING = 'start_dropdown_loading';
export const ADD_ITEMS_TO_OPTION = 'add_dropdown_items_to_option';
export const RELOAD_OPTION = 'reload_dropdown_option';

export function addOption(storageId, owner){
  return {
    type: ADD_OPTION,
    storageId: storageId,
    owner: owner
  }
}

export function startLoading(storageId){
  return {
    type: START_LOADING,
    storageId: storageId
  }
}

export function addItemsToOption(storageId, items){
  return {
    type: ADD_ITEMS_TO_OPTION,
    storageId: storageId,
    options: items
  }
}

export function reloadOption(storageId){
  return {
    type: RELOAD_OPTION,
    storageId: storageId
  }
}
