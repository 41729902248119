export const technical_pie_ticket_criticity = {
  id: "technical_pie_ticket_criticity-1",
  default: true,
  roles: [],

  title: "Urgence ticket",
  type: "stateMachine",
  conf: {
    element: "technicalTicket",
    dataset: "criticity",
    data: [1, 2, 3],
    filters: {
      requestType: [
        {
          id: 3,
          name: "Dépannage",
          group: 0,
          escalatable: true,
          enable_gfrt_notification: true
        },
        {
          id: 4,
          name: "Aide à la configuration",
          group: 0,
          escalatable: true,
          enable_gfrt_notification: true
        },
        {
          id: 5,
          name: "Modification du service",
          group: 0,
          escalatable: true,
          enable_gfrt_notification: true
        }
      ],
      state: {
        _parameterType: "value",
        _title: "",
        value: [
          {
            value: "new",
            label: "Nouveau"
          },
          {
            value: "waiting",
            label: "En attente"
          },
          {
            value: "in_progress",
            label: "En cours de progrès"
          }
        ]
      }
    }
  },

  column: 1,
  row: 1
}
