const profil = {
  label: {
    label: "Label",
    invalid: {
      assertEmpty: "Le label du profil ne doit pas être vide."
    }
  },
  group: {
    label: "Groupe",
    invalid: {
      assertEmpty: "Le groupe ne peut pas être vide."
    }
  },
  hierarchy: {
    label: "Niveau hiérarchique",
  },
  active: {
    label: "Activé ?",
  },
  availableInner: {
    label: "Disponible Nyukom ?",
  },
  availablePartner: {
    label: "Disponible Partenaire ?",
  },
  role: {
    label: "Rôle",
  },
  roles: {
    label: "Rôles",
    allowMoreField: {
      addLabel: "Ajouter un rôle",
      removeLabel: "Enlever un rôle",
      removingLabel: "Supprimer"
    },
    invalid: {
      assertEmpty: "Le rôle ne peut pas être vide."
    }
  },
  profilSubs: {
    label: "Sous-profils",
    allowMoreField: {
      addLabel: "Ajouter un sous-profil",
      removeLabel: "Enlever un sous-profil",
      removingLabel: "Supprimer"
    },
    invalid: {
      assertEmpty: "Le sous-profil ne peut pas être vide."
    }
  }
};

export default profil;
