import React from 'react';

import { Button } from 'react-bootstrap';
import '../../../css/form.css';

/**
 * FormButton
 */
class FormButton extends React.Component {

  displayButtonAsA() {
    return (
      <div className={`d-flex col-md-${this.props.fieldColMd} container-form-row justify-content-center`}>
        <a
          className="col-6 col-md-3 button-form-row btn btn-my-secondary-outline"
          href={this.props.path(this.props.getValues, this.props.getExtraValues)}>
          {this.props.label}
        </a>
      </div>
    )
  }

  displayButtonAsButton() {
    return (
      <div className={`d-flex container-form-row justify-content-center`}>
        <Button
          className="col-6 col-md-3 button-form-row"
          variant="my-secondary-outline"
          onClick={() => console.log("test")}>
          {this.props.label}
        </Button>
      </div>
    )
  }

  displayButton() {
    switch (this.props.as) {
      case 'a':
        return this.displayButtonAsA();
      case 'button':
        return this.displayButtonAsButton();
      default:
        console.log("Button type is wrong : " + this.props.as);
    }
    return null;
  }

  /**
   * Main render method for React Component
   */
  render() {
    return this.displayButton();
  }
}

export default FormButton;
