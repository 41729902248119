const datasheet = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Ce nom ne doit pas être vide."
    },
  },
};

export default datasheet;
