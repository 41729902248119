import strings from '../../Localization/Localization';

let label = {
  fieldKey: "label",
  type: "text",
  label: strings.form.phoneZoneGroup.label.label,
  validators: []
}

export const form = {
  formKey: "phoneZoneGroupAlias",
  fields: [
    label
  ]
}
