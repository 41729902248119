import Counter from './Counter';

/**
 * CallbackCounter
 *
 * A counter that can register subscriber to each value it could take
 * Once the value is reach, the counter will notice the subscriber
 */
export default class CallbackCounter extends Counter {

  /**
   * reset the value of the counter
   * reset the list of callback of the counter
   */
  reset() {
    super.reset();
    this.callbacks = {};
  }

  /**
   * increment the value of the counter
   * try to fire a notification to every subscriber of the new value
   */
  increment() {
    super.increment();
    this.fire();
  }

  /**
   * decrement the value of the counter
   * try to fire a notification to every subscriber of the new value
   */
  decrement() {
    super.decrement();
    this.fire();
  }

  /**
   * allow a function to subscribe to a certain value of the counter
   * this function will be called (without any parameter) when the counter reach
   * the value subscribed to
   *
   * @param value the value of the counter to reach
   * @param callback the function called when reach the value
   */
  subscribe(value, callback) {
    if (typeof callback === 'function') {
      if(this.callbacks[value] === undefined) {
        this.callbacks[value] = [];
      }
      this.callbacks[value].push(callback);
    }
  }

  /**
   * allow a function to unsubscribe to a certain value of the counter
   *
   * @param value the value of the counter to reach
   * @param callback the function to unsubscribe
   */
  unsubscribe(value, callback) {
    if(this.callbacks[value] !== undefined && this.callbacks[value].includes(callback)) {
      let index = this.callbacks[value].findIndex((element) => element === callback);
      this.callbacks[value].splice(index, 1);
    }
  }

  /**
   * force all function to unsubscribe to the counter
   *
   */
  unsubscribeAll() {
    this.callbacks = {};
  }

  /**
   * this function will fire every function subscribed to the current value of
   * counter.
   */
  fire() {
    if(this.callbacks[this.value] !== undefined) {
      this.callbacks[this.value].forEach((item) => {
        item.call();
      });
    }
  }
}
