import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem,
  transitionFromId as baseTransitionFromId,
  statistic as baseStatistic,
  stateMachineStatistic as baseStateMachineStatistic
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of tickets based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('tickets/commercial', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single ticket based on his ID
 *
 * @param id the of the ticket requested
 */
export function getFromId(id) {
  return baseGetFromId('tickets/commercial', id);
}

/**
 * Get a single ticket based on his ID
 *
 * @param id the of the ticket requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('tickets/commercial', id, 'identification');
}

/**
 * Get a single ticket based on his ID
 *
 * @param id the of the ticket requested
 */
export function getHistoryFromId(id) {
  return baseGetFromId('tickets/commercial', id, 'history');
}

/**
 * Get a single ticket based on his ID
 *
 * @param id the of the ticket requested
 */
export function getAssignRefereeFromId(id) {
  return baseGetFromId('tickets/commercial', id, 'assign_referee');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single ticket based on his ID
 *
 * @param id the of the ticket requested
 * @param data the new data of the ticket
 */
export function editFromId(id, data) {
  return baseEditFromId('tickets/commercial', id, data);
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function editAssignRefereeFromId(id, data) {
  return baseEditFromId('tickets/commercial', id, data, 'assign_referee');
}

/**
 * Edit a single ticket based on his ID
 *
 * @param id the of the ticket requested
 * @param data the new data of the ticket
 */
export function editFormAnswerFromId(id, data) {
  return baseEditFromId('tickets/commercial', id, data, 'edit_form_answer');
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function newCommentFromId(id, data) {
  return baseEditFromId('tickets/commercial', id, data, 'new_comment');
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function newFileFromId(id, data) {
  return baseEditFromId('tickets/commercial', id, data, 'new_file');
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function newAppointmentFromId(id, data) {
  return baseEditFromId('tickets/commercial', id, data, 'new_appointment');
}

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function newFormAnswerFromId(id, data) {
  return baseEditFromId('tickets/commercial', id, data, 'new_form_answer');
}

/******************************************************************************
 * TRANSITION FUNCTION                                                        *
 ******************************************************************************/

/**
 *
 */
export function workInProgressTransition(id, data) {
  return baseTransitionFromId('tickets/commercial', id, data, 'work_in_progress');
}

/**
 *
 */
export function waitTransition(id, data) {
  return baseTransitionFromId('tickets/commercial', id, data, 'wait');
}

/**
 *
 */
export function closeTransition(id, data) {
  return baseTransitionFromId('tickets/commercial', id, data, 'close');
}

/******************************************************************************
 * STATISTIC FUNCTION                                                         *
 ******************************************************************************/

export function statistic(stat, attribute, search) {
  return baseStatistic('tickets/commercial', stat, attribute, search);
}

/******************************************************************************
 * STATE MACHINE STATISTIC FUNCTION                                           *
 ******************************************************************************/

export function stateMachineStatistic(stateStatistic, states, search) {
  return baseStateMachineStatistic('tickets/commercial', stateStatistic, states, search);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new ticket
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return baseNewItem('tickets/commercial', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all tickets to serve in a dropdown form choice
 */
export function fetchActiveTickets() {
  let search = {
    state: "new,waiting,in_progress"
  };
  return baseIndex('tickets/commercial', 1, 0, search);
}

/******************************************************************************
 * SPECIFIC FUNCTION                                                          *
 ******************************************************************************/

/**
 * escalate a ticket
 *
 * @param id the user to be exclude of the list of user
 * @param username the username to check
 */
export function escalate(id, data) {
  return baseEditFromId('tickets/commercial', id, data, 'escalate');
}
