import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem,
  deleteItem as baseDeleteItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of frontend data based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('frontend_data', page, itemPerPage, Object.assign(search, { source: process.env.REACT_APP_FRONTEND_NAME }), order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single constructor based on his ID
 *
 * @param id the of the constructor requested
 */
export function getFromId(id) {
  return baseGetFromId('frontend_data', id);
}

/**
 * Get a single frontend data based on his ID
 *
 * @param id the of the constructor requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('frontend_data', id, 'identification');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single frontend data based on his ID
 *
 * @param id the of the constructor requested
 * @param data the new data of the constructor
 */
export function editFromId(id, data) {
  return baseEditFromId('frontend_data', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new frontend data
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return baseNewItem('frontend_data', Object.assign(data, { source: process.env.REACT_APP_FRONTEND_NAME }));
}

/******************************************************************************
 * DELETE FUNCTION                                                            *
 ******************************************************************************/

/**
 * Create a new frontend data
 *
 * @param data the new data of the partner
 */
export function deleteItem(id) {
  return baseDeleteItem('frontend_data', id);
}
