import React from 'react';

import { withRouter } from "react-router-dom";

import { connect } from 'react-redux';

import { withModalHandler } from '../../HOC/ModalHandler';

import { refresh } from '../../API/Login';

/**
 * CredentialsContainer
 */
class CredentialsContainer extends React.Component {

  constructor(props) {
    super(props);

    this.refresh = this.props.modalHandler.addVerificationWithCallback(refresh, this.postRefresh, this.postRefreshFailure);

    this.state = {
      // loading: this.props.credentials.authToken?true:false
      loading: true
    }
  }

  componentDidMount() {
    this.refresh();
    // if(this.props.credentials.authToken) {
    //   this.refresh();
    // }
    // else {
    //   //authenticate
    // }
  }

  postRefresh = (data) => {
    this.setState({
      loading: false
    })
  }

  postRefreshFailure = (msg) => {
    this.setState({
      loading: false
    })
  }

  /**
   * Main render method for React Component
   */
  render() {
    if(!this.state.loading || !this.props.credentials.authToken)
      return (
        <>
          {this.props.children}
        </>
      );
    else {
      return (
        <div/>
      )
    }
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(withModalHandler(withRouter(CredentialsContainer)));
