import React from 'react';

import PartSelection from '../PartSelection';

/**
 */
class Margin extends React.Component {

  static defaultProps = {
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null
  }

  getStyle() {
    let style = {}

    if(this.props.marginTop) {
      style.marginTop = this.props.marginTop;
    }
    if(this.props.marginBottom) {
      style.marginBottom = this.props.marginBottom;
    }
    if(this.props.marginLeft) {
      style.marginLeft = this.props.marginLeft;
    }
    if(this.props.marginRight) {
      style.marginRight = this.props.marginRight;
    }

    return style
  }

  displayChild() {
    console.log(this.props.partChild);
    if(this.props.partChild) {
      return (
        <PartSelection {...this.props.partChild} />
      )
    }
  }

  render() {
    return (
      <div style={this.getStyle()}>
        {this.displayChild()}
      </div>
    );
  }
}

export default Margin;
