import React from 'react';

import strings from '../../Localization/Localization';

import { connect } from 'react-redux';
import { resetBreadcrumbAction } from '../../Store/Action/breadcrumb';

import MainLayout from '../Layout/MainLayout';

import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab/Tab';

import FlexErrorProcessing from './Tab/FlexErrorProcessing';
import AlphalinkErrorProcessing from './Tab/AlphalinkErrorProcessing';
import ZoneErrorProcessing from './Tab/ZoneErrorProcessing';
import ZoneSpecialErrorProcessing from './Tab/ZoneSpecialErrorProcessing';
import DuplicateMailErrorProcessing from './Tab/DuplicateMailErrorProcessing';
import PriceErrorProcessing from './Tab/PriceErrorProcessing';
import RoamingLikeAtHomeNotFoundErrorProcessing from './Tab/RoamingLikeAtHomeNotFoundErrorProcessing';
import ImportPriceData from './Tab/ImportPriceData';

import { withModalHandler } from '../../HOC/ModalHandler';

/**
 * ErrorProcessing
 */
class ErrorProcessing extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tabsKey: props.defaultTab || 'main',
    }
  }

  componentDidMount() {
    this.props.dispatch(resetBreadcrumbAction(window.location.pathname, strings.phoneCDR.errorProcessing.breadcrumb));
  }

  /**
   * change the tab displayed
   */
  changeTab = (key) => {
    this.setState({
      tabsKey: key,
    })
  }

  /**
   * Display the tabs
   */
  displayTabs() {
    return (
      <Tabs activeKey={this.state.tabsKey} onSelect={this.changeTab} className="entity-tabs-selection">
        <Tab eventKey="flex" title={strings.phoneCDR.errorProcessing.tabs.flex}>
          <FlexErrorProcessing/>
        </Tab>
        <Tab eventKey="alphalink" title={strings.phoneCDR.errorProcessing.tabs.alphalink}>
          <AlphalinkErrorProcessing/>
        </Tab>
        <Tab eventKey="zone" title={strings.phoneCDR.errorProcessing.tabs.zone}>
          <ZoneErrorProcessing/>
        </Tab>
        <Tab eventKey="zone_special" title={strings.phoneCDR.errorProcessing.tabs.zoneSpecial}>
          <ZoneSpecialErrorProcessing/>
        </Tab>
        <Tab eventKey="duplicate_mail" title={strings.phoneCDR.errorProcessing.tabs.duplicateMail}>
          <DuplicateMailErrorProcessing/>
        </Tab>
        <Tab eventKey="price" title={strings.phoneCDR.errorProcessing.tabs.price}>
          <PriceErrorProcessing/>
        </Tab>
        <Tab eventKey="roaming_like_at_home_not_found" title={strings.phoneCDR.errorProcessing.tabs.roamingLikeAtHomeNotFound}>
          <RoamingLikeAtHomeNotFoundErrorProcessing/>
        </Tab>
        <Tab eventKey="importPrice" title={strings.phoneCDR.errorProcessing.tabs.importPrice}>
          <ImportPriceData/>
        </Tab>
      </Tabs>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <MainLayout>
        {this.displayTabs()}
      </MainLayout>
    );
  }
}

export default connect()(withModalHandler(ErrorProcessing));
