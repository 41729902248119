import React from 'react';
import BasicFormField from './BasicFormField';

import FormFieldSelector from '../FormFieldSelector';

import LengthMinAssert from '../../../Form/Assert/LengthMinAssert';
import LengthMaxAssert from '../../../Form/Assert/LengthMaxAssert';

/**
 * FormFieldSubForm
 *
 * This class handles HTML form field.
 *
 * The particular field is for sub form. It will display a list of fields using
 * one or several of the other type of field.
 *
 * Specific configuration attribute :
 *  -
 */
class FormFieldSubForm extends BasicFormField {

  constructor(props) {
    super(props);

    this.subFieldRefs = {};
  }

  /**
   * @return the value of this field
   */
  getValue(){
    if(typeof this.props.value === 'object'
      && !Array.isArray(this.props.value)
      && this.props.value !== null) {
      return this.props.value
    }
    return {};
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    let value = this.props.value;
    if(!value) {
      value = {};
    }
    Object.values(this.props.subForm.fields)
      .filter((item) => {
        return !item.disabled
      })
      .forEach((item, i) => {
        let ref = this.subFieldRefs[`${item.fieldKey}${item.fieldKeyUuid?"-"+item.fieldKeyUuid:""}`];
        if(ref && ref.current) {
          return value[item.fieldKey] = ref.current.getFinalValue();
        }
      }
    );
    return value;
  }

  /**
   * Allow to get the values depending of the scope (depth) inside the form
   */
  getValues = (depth = -1) => {
    if(depth === -1) {
      return this.props.getValues();
    }
    else if(depth === 0) {
      //changed final value to value for a bug
      return this.getValue();
    }
    else {
      return this.props.getValues(depth-1);
    }
  }

  /**
   * @inheritdoc
   */
  needValidation() {
    return super.needValidation() || Object.values(this.subFieldRefs).filter((item) => item && item.current).some((item) => item.current.needValidation());
  }

  /**
   * @inheritdoc
   */
  isValid = () => {
    return (Object.values(this.state.valid).every((value) => value === true)?true:false)
      && (Object.values(this.subFieldRefs).filter((item) => item && item.current).every((item) => {
        return !item.current.needValidation() || item.current.isValid()
      }));
  }

  /**
   * @inheritdoc
   */
  isInvalid = () => {
    return Object.values(this.state.valid).some((value) => value === false)?true:false
     || Object.values(this.subFieldRefs).filter((item) => item && item.current).some((item) => item && item.current && item.current.isInvalid());
  }

  /**
   * Send new value to parent element
   *
   * @param key the key of the subfield
   * @param value the new value of the subfield
   */
  onChange = (key, value) => {
    let newValue = this.getValue();
    //set the value that changed
    newValue[key] = value;
    super.onChange(newValue, true);
    this.triggerEvents(key);
  }

  getSpecificAsserts() {
    return {
      lengthMin: LengthMinAssert,
      lengthMax: LengthMaxAssert,
    }
  }

  getExtraValidation() {
    return Object.values(this.subFieldRefs).filter((item) => item && item.current?item.current.needValidation():false).map((item) => item.current);
  }

  triggerEvents = (key) => {
    this.props.subForm.fields.forEach((field) => {
      if(field.events) {
        field.events.forEach((event) => {
          if(event.event === 'onChange'
            && (event.target === key
            || event.target === "")
            && this.subFieldRefs[`${field.fieldKey}${field.fieldKeyUuid?"-"+field.fieldKeyUuid:""}`]) {
            event.function(this.subFieldRefs[`${field.fieldKey}${field.fieldKeyUuid?"-"+field.fieldKeyUuid:""}`].current);
          }
        });
      }
    });
  }

  displayLabel() {
    if(this.props.label !== undefined && this.props.label !== null && this.props.label !== '') {
      return (
        <div className="container-form-field col-md-12">
          {this.props.label}
        </div>
      )
    }
    return null;
  }

  /**
   * Display all sub fields.
   */
  displaySubForm() {
    return (
      <>
        {Object.values(this.props.subForm.fields)
          .map((item) => {
            let disabled = this.props.disabled || (item.disabled !== undefined && ((typeof item.disabled === 'function' && item.disabled(this.getValues, this.props.getExtraValues)) || item.disabled === true));
            //check if the field should be displayed or not
            if(!(disabled && typeof item.showOnDisabled === 'function' && !item.showOnDisabled(this.getValues, this.props.getExtraValues))) {
              return this.displaySubField(item, disabled);
            }
            return null;
          }
        )}
      </>
    )
  }

  subFieldRefCallback = (key, ref) => {
    if(this.subFieldRefs[key] === undefined) {
      this.subFieldRefs[key] = React.createRef();
    }
    this.subFieldRefs[key].current = ref;
  }

  /**
   * display the actual subfield using a field selector
   *
   * @param key the key of the subfield
   */
  displaySubField(item, disabled) {
    //display the field selector
    return (
      <React.Fragment key={`${this.props.formKey}-${this.props.subForm.formKey}-${item.fieldKey}${item.fieldKeyUuid?"-"+item.fieldKeyUuid:""}`}>
        <FormFieldSelector
          key={`${this.props.formKey}-${this.props.subForm.formKey}-${item.fieldKey}${item.fieldKeyUuid?"-"+item.fieldKeyUuid:""}`}
          forwardedRef={(ref) => this.subFieldRefCallback(`${item.fieldKey}${item.fieldKeyUuid?"-"+item.fieldKeyUuid:""}`, ref)}

          fieldVariant={this.props.fieldVariant}

          {...item}
          formKey={`${this.props.formKey}-${this.props.subForm.formKey}`}
          fieldKey={`${this.props.formKey}-${this.props.subForm.formKey}-${item.fieldKey}`}

          disabled={disabled}

          value={this.getValue()[item.fieldKey]}
          getValues={this.getValues}
          getExtraValues={this.props.getExtraValues}
          onChange={(value) => this.onChange(item.fieldKey, value)}

          ignoreValidation={this.props.ignoreSubFormValidation}
          validationCallback={() => this.subFieldValidationCallback()}
          submitted={this.props.submitted}
          submitting={this.props.submitting}
        />
      </React.Fragment>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayLabel()}
        {this.displaySubForm()}
      </>
    );
  }
}

export default FormFieldSubForm;
