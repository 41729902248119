import {
  index as baseIndex,
  request,
 } from './Base';

const ERROR_TYPE_UNKNOWN_ZONE = "unknown_zone";
const ERROR_TYPE_UNKNOWN_ZONE_SPECIAL = "unknown_zone_special";
const ERROR_TYPE_UNKNOWN_PRICE = "unknown_price";
const ERROR_TYPE_ROAMING_LIKE_AT_HOME_NOT_FOUND = "roaming_like_at_home_not_found";
const ERROR_TYPE_PRECEDENT_ERROR = "precedent_error";
const ERROR_TYPE_DUPLICATE_MAIL_REGEX = "duplicate_mail_regex";

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of appointment types based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('call_detail_record_errors', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

 /**
  * Fetch all error lines
  */
 export function fetchZoneErrorLines(distinct) {
   let search = {
     error_type: ERROR_TYPE_UNKNOWN_ZONE
   }
   if(distinct === true) {
     search.distinct = true;
   }
   return baseIndex('call_detail_record_errors', 1, 0, search);
 }

 /**
  * Fetch all error lines
  */
 export function fetchZoneSpecialErrorLines(distinct) {
   let search = {
     error_type: ERROR_TYPE_UNKNOWN_ZONE_SPECIAL
   }
   if(distinct === true) {
     search.distinct = true;
   }
   return baseIndex('call_detail_record_errors', 1, 0, search);
 }

 /**
  * Fetch all error lines
  */
 export function fetchDuplicateMailErrorLines(distinct) {
   let search = {
     error_type: ERROR_TYPE_DUPLICATE_MAIL_REGEX
   }
   if(distinct === true) {
     search.distinct = true;
   }
   return baseIndex('call_detail_record_errors', 1, 0, search);
 }

 /**
  * Fetch all error lines
  */
 export function fetchPriceErrorLines(distinct) {
   let search = {
     error_type: ERROR_TYPE_UNKNOWN_PRICE
   }
   if(distinct === true) {
     search.distinct = true;
   }
   return baseIndex('call_detail_record_errors', 1, 0, search);
 }

 /**
  * Fetch all error lines
  */
 export function fetchRoamingLikeAtHomeErrorLines(distinct) {
   let search = {
     error_type: ERROR_TYPE_ROAMING_LIKE_AT_HOME_NOT_FOUND
   }
   if(distinct === true) {
     search.distinct = true;
   }
   return baseIndex('call_detail_record_errors', 1, 0, search);
 }

 /**
  * Fetch all error lines
  */
 export function fetchLockedErrorLines(distinct) {
   let search = {
     error_type: ERROR_TYPE_PRECEDENT_ERROR
   }
   if(distinct === true) {
     search.distinct = true;
   }
   return baseIndex('call_detail_record_errors', 1, 0, search);
 }

/******************************************************************************
 * OTHER FUNCTION                                                             *
 ******************************************************************************/

 /**
  * start to process error line
  */
 export function processErrorLines() {
   return request('/call_detail_records_process_error');
 }
