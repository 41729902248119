export const technical_ticket_list = {
  id: "technical_ticket_list-1",
  default: true,
  roles: [],

  type: "list",
  title: "Tickets techniques",
  conf: {
    element: "technicalTicket",
    headers: [
      "state",
      "criticity",
      "escalateLevel",
      "number",
      "place",
      "service"
    ],
    filters: {
      state: {
        _parameterType: "value",
        _title: "",
        value: [
          {
            value: "new",
            label: "Nouveau"
          },
          {
            value: "waiting",
            label: "En attente"
          },
          {
            value: "in_progress",
            label: "En cours de progrès"
          }
        ]
      },
      requestType: [
        {
          id: 3,
          name: "Dépannage",
          group: 0,
          escalatable: true,
          enable_gfrt_notification: true
        },
        {
          id: 4,
          name: "Aide à la configuration",
          group: 0,
          escalatable: true,
          enable_gfrt_notification: true
        },
        {
          id: 5,
          name: "Modification du service",
          group: 0,
          escalatable: true,
          enable_gfrt_notification: true
        }
      ],
    }
  },

  column: 3,
  row: 4
}
