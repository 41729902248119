import React from 'react';

import BasicWidget from './BasicWidget';

import Table from '../../Table/Table';
import TableHeader from '../../Table/TableHeader';
import TableBody from '../../Table/TableBody';
import TableTextHeader from '../../Table/Header/TableTextHeader';
import TableRow from '../../Table/Body/TableRow';

import StringCell from '../../Form/Field/TableCell/StringCell';
import IntegerCell from '../../Form/Field/TableCell/IntegerCell';
import FloatCell from '../../Form/Field/TableCell/FloatCell';
import PriceCell from '../../Form/Field/TableCell/PriceCell';

import Loading from '../../Layout/Loading';

import '../../../css/dashboard/widget_list.css';

/**
 * WidgetList
 */
class WidgetList extends BasicWidget {

  static defaultProps = {
    interval: 60,

    conf: {
      filters: {},
      headers: {}
    }
  }

  constructor(props) {
    super(props);

    this.requests = {};

    this.timer = null;

    this.state = {
      loading: true,

      items: null,
      page: null,
      total: null,
      totalPage: null
    }
  }

  componentDidMount() {
    this.initiateApi()
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.parameterBag !== this.props.parameterBag || prevProps.conf !== this.props.conf) {
      this.initiateApi(true)
    }
    else {
      this.initiateApi()
    }
  }

  componentWillUnmount() {
    if(this.timer !== null) {
      clearInterval(this.timer);
    }
  }

  postChangeArgument() {
    this.initiateApi(true)
  }

  initiateApi(forceFetch = false) {
    Object.keys(this.props.widgetType.elementSettings).forEach((key) => {
      if(!this.requests[key]) {
        this.requests[key] = {
          fetch: this.props.modalHandler.addVerificationWithCallback(this.props.widgetType.elementSettings[key].fetch, this.requestCallback, this.requestCallbackFailure, true)
        }
      }
    });
    if(this.timer === null) {
      this.timer = setInterval(this.timeFetch, this.props.interval * 1000);
      forceFetch = true;
    }
    if(forceFetch) {
      this.timeFetch();
    }
  }

  timeFetch = () => {
    if(this.requests[this.props.conf.element]) {
      this.setState({
        loading: true
      }, () => {
        let filters = [];
        if(this.props.conf.element && this.props.widgetType.elementSettings[this.props.conf.element].computeFilters) {
          filters = Object.assign({}, this.props.conf.filters);
          Object.keys(filters).forEach(key => {
            filters[key] = this.evaluateArgument('filters.'+key);
          });
          filters = this.props.widgetType.elementSettings[this.props.conf.element].computeFilters(filters);
        }
        let quantity = 5;
        if(this.props.row === 3) {
          quantity = 10;
        }
        else if(this.props.row === 4) {
          quantity = 15;
        }
        let order = 'ASC';
        if(this.props.conf.order) {
          order = this.props.conf.order;
        }
        this.requests[this.props.conf.element].fetch(1, quantity, filters, order)
      })
    }
  }

  requestCallback = (data) => {
    this.setState({
      loading: false,

      items: data.items,
      page: data.page,
      total: data.total,
      totalPage: data["total_page"]
    })
  }

  requestCallbackFailure = (msg) => {
    console.log(msg);
  }

  /**
   * define how an item should react when clicked
   *
   * @param id the id of the item clicked
   */
  onClickItem = (id, item) => {
    if(this.props.conf.onClickItem.redirect) {
      this.props.history.push(this.props.conf.onClickItem.path(id, item));
    }
  }

  /**
   * define how an item should react when clicked
   *
   * @param id the id of the item clicked
   */
  entityPath = (id, item) => {
    if(typeof this.props.widgetType.elementSettings[this.props.conf.element].entityLink === 'function') {
      return this.props.widgetType.elementSettings[this.props.conf.element].entityLink(id, item);
    }
    return '#';
  }

  activateLink = (event) => {
    event.preventDefault();
    let filters = Object.assign({}, this.props.conf.filters);
    Object.keys(filters).forEach(key => {
      filters[key] = this.evaluateArgument('filters.'+key);
    });

    this.props.history.push(this.props.widgetType.elementSettings[this.props.conf.element].link(), { filters: filters });
    // window.location.href = event.currentTarget.href;
  }

  displayList() {
    // let headers = this.props.conf.headers?this.props.conf.headers:[];
    let headers = [];
    if(Array.isArray(this.props.conf.headers)) {
      headers = this.props.widgetType.elementSettings[this.props.conf.element].headers.filter(header => this.props.conf.headers.includes(header.id))
    }

    return (
      <div className="dashboard-widget-list-container">
        <Table
          cellCollection={{
            string: StringCell,
            integer: IntegerCell,
            float: FloatCell,
            price: PriceCell
          }}>
          <TableHeader>
            {headers
              .map((header, i) => {
                return <TableTextHeader key={i} value={header.value} label={header.label} type={header.type}/>
              }
            )}
          </TableHeader>
          <TableBody>
            {!this.state.loading?this.state.items.map((item, i) => {
              return (
                <TableRow
                  key={i}
                  as="a"
                  // className="row-my-primary item-index-clickable"
                  // onClickItem={this.onClickItem}
                  entityPath={this.entityPath}
                  // itemDisplay={this.props.itemDisplay.tab}
                  data={item}>
                </TableRow>
              )
            }):[]}
          </TableBody>
        </Table>
        {(!this.state.loading && this.state.items.length === 0)?
          <div>Aucune donnée à afficher</div>
        :undefined}
        {this.state.loading?
          <Loading container="parent"/>
        :undefined}
      </div>
    )
  }

  displayLink() {
    if(this.props.widgetType.elementSettings[this.props.conf.element]) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <a href={this.props.widgetType.elementSettings[this.props.conf.element].link()} onClick={this.activateLink}><i className="icon-chevron-down"/></a>
        </div>
      )
    }
  }

  displayContent() {
    return (
      <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
        {this.displayTitle()}
        {this.displayArguments()}
        <div className="dashboard-content-container">
          {this.displayList()}
        </div>
        {this.displayLink()}
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="dashboard-widget-container">
        {this.displayContent()}
      </div>
    );
  }
}

export default WidgetList;
