const phoneZone = {
  tab: {
    main: "Détail",
    prefix: "Préfix",
    priceSource: "Prix Entrant",
    priceDestination: "Prix Sortant",
  },
  search: {
    label: "Label"
  },
  new: {
    title: "Nouvelle zone téléphonique"
  }
};

export default phoneZone;
