import {
  ADD_REQUEST,
  ADD_VERIFICATION,
  MODIFY_TYPE,
  REMOVE_ACTION,
  ON_AUTHENTICATION_SUCCESS
} from '../Action/modalHandler';

import { v4 as uuid } from 'uuid';

const initialState = []

export default function reduce(state = initialState, action){
  let newState = [];
  let index = -1;
  switch (action.type) {
    case ADD_REQUEST:
    case ADD_VERIFICATION:
      newState = [...state];
      newState.push(Object.assign(action.action, {
        uuid: uuid()
      }));
      break;
    case MODIFY_TYPE:
      newState = [...state];
      index = newState.findIndex(item => item.uuid === action.uuid)
      newState[index] = Object.assign({}, newState[index], action.action);
      break;
    case REMOVE_ACTION:
      newState = [...state];
      newState = newState.filter(item => item.uuid !== action.uuid);
      break;
    case ON_AUTHENTICATION_SUCCESS:
      // newState = [...state];
      newState = state.map(item => {
        if(item.type === 'auth') {
          item.type = 'request';
        }
        return item
      })
      break;
    default:
      newState = state;
  }
  return newState;
}
