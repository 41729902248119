import React from 'react';
import BasicWidget from './BasicWidget';

import '../../../css/dashboard/widget_title.css';

/**
 * WidgetTitle
 */
class WidgetTitle extends BasicWidget {

  static defaultProps = {}

  displayContent() {
    return (
      <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
        {this.displayTitle()}
        {this.displayArguments()}
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="dashboard-widget-container">
        {this.displayContent()}
      </div>
    );
  }
}

export default WidgetTitle;
