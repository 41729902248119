const quote = {
  number: {
    label: "Numéro de devis",
  },
  orderNumber: {
    label: "Numéro de commande"
  },
  signatureDate: {
    label: "Date de signature",
  },
  signatureDateMin: {
    label: "Date de signature (min)",
  },
  signatureDateMax: {
    label: "Date de signature (max)",
  },
  signatureDateTimeframe: {
    label: "Signature"
  },
  quoteFile: {
    label: "Devis (PDF)",
  },
  validationFile: {
    label: "Bon pour accord",
  },
  validated: {
    label: "Le devis est-il validé ?",
  },
  rightToCancellation: {
    label: "Droit de rétractation",
  },
  client: {
    label: "Client",
  },
  clientAccount: {
    label: "Compte Client",
  },
  place: {
    label: "Site de livraison",
  },
  cartItems: {
    label: "Contenu du devis",
  },
  orders: {
    label: 'Commande #key#'
  }
};

export default quote;
