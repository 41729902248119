import React from 'react';

import strings from '../../../Localization/Localization';
import { bills } from '../../../MetaData/Route/routes';

import BasicNotification from './BasicNotification';

class NewBillClient extends BasicNotification {

  displayMessage() {
    return (
      <a href={bills.routes.show.createPath(this.props.item._arguments["%bill_id%"])}>{strings.formatString(strings.common.notification.ticket_new_client, this.props.item._arguments["%bill_number%"])}</a>
    )
  }
}

export default NewBillClient;
