import React from 'react';

import '../../../css/item_index.css';

import { v4 as uuid } from 'uuid';

import strings from '../../../Localization/Localization';

import { withModalHandler } from '../../../HOC/ModalHandler';

import {
  importData,
  emptyTable,
  emptyTableDescription
} from '../../../API/PhonePrices';

import { Container, Form, InputGroup, Navbar, Col, Button } from 'react-bootstrap';

import Loading from '../../Layout/Loading';

/**
 * ImportPriceData
 */
class ImportPriceData extends React.Component {

  constructor(props) {
    super(props);

    this.inputId = `form-${this.props.formKey}-${this.props.fieldKey}-${uuid()}`;

    this.importData = this.props.modalHandler.addVerificationWithCallback(importData, this.postImport, this.postImportFailure);
    this.emptyTable = this.props.modalHandler.addVerificationWithCallback(emptyTable, this.postEmptyTable, this.postEmptyTableFailure);
    this.emptyTableDescription = this.props.modalHandler.addVerificationWithCallback(emptyTableDescription, this.postEmptyTableDescription, this.postEmptyTableDescriptionFailure);

    this.state = {
      loading: false,

      files: [],
    }
  }

  import = () => {
    if(this.state.files.length === 1) {
      this.setState({
        loading: true
      }, () => this.importData(this.state.files[0]))
    }
  }

  postImport = (data) => {
    console.log(data);
    this.setState({
      loading: false
    })
  }

  postImportFailure = (error) => {
    console.log(error);
    this.setState({
      loading: false
    })
  }

  fetchEmptyTable = () => {
    this.setState({
      loading: true
    }, () => this.emptyTable())
  }

  postEmptyTable = (data) => {
    this.setState({
      loading: false
    })
  }

  postEmptyTableFailure = (error) => {
    this.setState({
      loading: false
    })
  }

  fetchEmptyTableDescription = () => {
    this.setState({
      loading: true
    }, () => this.emptyTableDescription())
  }

  postEmptyTableDescription = (data) => {
    this.setState({
      loading: false
    })
  }

  postEmptyTableDescriptionFailure = (error) => {
    this.setState({
      loading: false
    })
  }

  displayButtons() {
    if(!this.state.loading) {
      return (
        <Navbar fixed="bottom" className="sub-footer">
          <Container>
            <Col xs={12} className="d-flex justify-content-center text-center small-text">
              <Button variant="my-validated" className="btn-sub-footer" onClick={() => this.fetchEmptyTable()}>{strings.phoneCDR.errorProcessing.importPrice.buttons.fetchEmptyTable}</Button>
              <Button variant="my-validated" className="btn-sub-footer" onClick={() => this.fetchEmptyTableDescription()}>{strings.phoneCDR.errorProcessing.importPrice.buttons.fetchEmptyTableDescription}</Button>
              <Button variant="my-warning" className="btn-sub-footer" onClick={() => this.import()}>{strings.phoneCDR.errorProcessing.importPrice.buttons.import}</Button>
            </Col>
          </Container>
        </Navbar>
      )
    }
  }

  displayForm() {
    if(!this.state.loading) {
      return (
        <Container>
          <InputGroup className={`container-form-row col-12`}>
            <InputGroup.Prepend
              className="d-flex col-md-4 container-form-prepend">
              <InputGroup.Text
                id={`form-file`}
                className="col-md-12">
                Fichier
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id={this.inputId}
              className="d-none col-border-radius col-md-border-radius"
              placeholder='Fichier'
              aria-label="Fichier"
              type="file"
              //value={this.state.files}
              onChange={(event) => this.setState({
                files: event.target.files
              })}
            />
            <InputGroup.Prepend>
              <label htmlFor={this.inputId} className="form-field-file-label">&nbsp; uploader {this.state.files[0]?"1 fichier selectioné"/*this.state.files[0].name*/:"Aucun fichier selectioné."}</label>
            </InputGroup.Prepend>
            <InputGroup.Append>
              <Button
                variant="my-secondary-noline"
                onClick={() => this.setState({
                  files: []
                })}>
                <i className={`icon-times`}></i>
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Container>
      )
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayLoading()}
        {this.displayForm()}
        {this.displayButtons()}
      </>
    );
  }
}

export default withModalHandler(ImportPriceData);
