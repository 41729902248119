import React from 'react';

import '../../css/circle_progress.css';

class CircleProgress extends React.Component {

  static defaultProps = {
    value: 0,

    colorMiddle: "var(--color-background)",
    colorProgressFilled: "var(--color-information)",
    colorProgressEmptied: "var(--color-background-darker)"
  }

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    this.updateContent();
  }

  componentDidUpdate() {
    this.updateContent();
  }

  updateContent() {
    if(this.ref.current) {
      this.ref.current.style.setProperty("--inner-text", `"${this.props.value}%"`)
    }
  }

  render() {
    let style = {
      background: "radial-gradient(closest-side, "+this.props.colorMiddle+" 79%, transparent 80% 100%), conic-gradient("+this.props.colorProgressFilled+" "+this.props.value+"%, "+this.props.colorProgressEmptied+" 0)"
    }
    return (
      <div ref={this.ref} className="circle-progress" style={style} />
    );
  }
}

export default CircleProgress;
