import React from 'react';

import { /*Tabs, Tab, */Row, Col, Button } from 'react-bootstrap';
import '../../css/entity_tabs.css';

import { connect } from 'react-redux';
import { addCartItemAction } from '../../Store/Action/cart';
import { addBreadcrumbAction } from '../../Store/Action/breadcrumb';

import { withRouter } from "react-router-dom";

import MyFormTab from './Tab/MyFormTab';
import TicketHistoryTab from './Tab/TicketHistoryTab';
import CentreonChartTab from './Tab/CentreonChartTab';

import { withModalHandler } from '../../HOC/ModalHandler';

import Loading from '../Layout/Loading';

import Tabs from '../Tabs/Tabs';
import Tab from '../Tabs/Tab/Tab';

import Image from '../Basic/MyImage';

import Menu from './Menu/Menu';

import Flashbag from '../Flashbag/Flashbag';
import {
  addLineToFlashbag,
  FLASHBAG_TYPE_SUCCESS
} from '../../Store/Action/flashbag';

/**
 * MyEntityTabs
 *
 * This component managed the tabs
 */
class MyEntityTabs extends React.Component {

  constructor(props) {
    super(props);

    //state of the component, including :
    // - tabsKey : the current tab showed
    // - loading : if the data is actually loading
    // - item : the item
    this.state = {
      tabsKey: props.defaultTab || 'main',
      loading: true,
      item: null,

      //quantity for cart item
      quantity: 0,
    }

    this.getFromId = this.props.modalHandler.addVerificationWithCallback(props.apiGet, this.updateItem, this.updateItemFailure);
  }

  /**
   * Once the component is mounted, we can query for the information of the item
   * needed to be displayed in the component
   */
  componentDidMount() {
    this.loadItem();
  }

  reloadItem() {
    this.setState({
      loading: true,
      item: null,
    }, () => {
      this.loadItem();
    })
  }

  loadItem() {
    this.getFromId(this.props.match.params.id);
  }

  /**
   * callback that collect the data from the fetch call and update the item
   *
   * @param data data from the fetch call
   */
  updateItem = (data) => {
    this.setState({
      loading: false,
      item: data.item,
    }, () => {
      let identifier = null;
      if(typeof this.props.breadcrumbIdentifier === 'function') {
        identifier = this.props.breadcrumbIdentifier(this.state.item);
      }
      else if(this.props.breadcrumbIdentifier){
        identifier = this.state.item[this.props.breadcrumbIdentifier];
      }
      this.props.dispatch(addBreadcrumbAction(window.location.pathname, this.props.pageTitle, identifier));
    })
  }

  updateItemFromMenu = (key, value) => {
    let newItem = Object.assign({}, this.state.item);
    newItem[key] = value;
    this.setState({
      item: newItem
    })
  }

  /**
   * callback that collect the data from the fetch call and update the item
   *
   * @param data data from the fetch call
   */
  updateItemFailure = (msg) => {
    this.setState({
      loading: false
    })
  }

  addToCart = () => {
    this.props.dispatch(addCartItemAction(this.state.item.id, this.state.quantity));
    this.props.dispatch(addLineToFlashbag("L'objet a été ajouté au panier.", FLASHBAG_TYPE_SUCCESS));
  }

  /**
   * change the tab displayed
   */
  changeTab = (key) => {
    this.setState({
      tabsKey: key,
      loading: true,
      item: null
    }, () => {
      this.loadItem();
    })
  }

  getImgUrl = () => {
    if(this.state.item === null) {
      return "holder.js/100x100?random=yes&text=loading";
    }
    else if(this.props.header.imgUrl(this.state.item) !== null) {
      return this.props.header.imgUrl(this.state.item);
    }
    else {
      let url = `holder.js/100x100?random=yes&text=${this.props.header.placeholderTitle(this.state.item)}`;
      return url;
    }
  }

  displayPageTitle() {
    if(this.props.pageTitle && this.props.pageTitleReturnPath) {
      return (
        <h3 className="page-title"><a href={this.props.pageTitleReturnPath()}><i className="icon-chevron-left"/></a>  {this.props.pageTitle}</h3>
      )
    }
    else if(this.props.pageTitle) {
      return (
        <h3 className="page-title">{this.props.pageTitle}</h3>
      )
    }
  }

  /**
   * Display the header for the entity
   */
  displayDataHeader() {
    if(this.props.extendedHeader === true) {
      return (
        <Row className="no-gutter entity-tabs-header-container">
          <Col xs="auto">
            <div className="entity-tabs-header-image-container">
              <Image url={this.getImgUrl()} roundedCircle/>
            </div>
          </Col>
          <Col>
            <Row>
              {this.state.item!==null && typeof this.props.header.label === 'function'?this.props.header.label(this.state.item):''}
            </Row>
            <Row>
              {this.state.item!==null && typeof this.props.header.additionalData1 === 'function'?this.props.header.additionalData1(this.state.item):''}
            </Row>
            <Row>
              {this.state.item!==null && typeof this.props.header.additionalData2 === 'function'?this.props.header.additionalData2(this.state.item):''}
            </Row>
            <Row>
              {this.state.item!==null && typeof this.props.header.additionalData3 === 'function'?this.props.header.additionalData3(this.state.item):''}
            </Row>
          </Col>
          <Col xs={12} md='auto'>
            {this.displayMenu()}
          </Col>
        </Row>
      )
    }
    else if(this.props.extendedHeader === "cartItem") {
      return (
        <Row className="no-gutter entity-tabs-header-container">
          <Col>
            <Row>
              <Col xs="auto">
                <div className="entity-tabs-header-image-container">
                  <Image url={this.getImgUrl()} roundedCircle/>
                </div>
              </Col>
              <Col>
                <Row>
                  {this.state.item!==null?this.props.header.label(this.state.item):''}
                </Row>
                <Row>
                  {this.state.item!==null?this.props.header.additionalData1(this.state.item):''}
                </Row>
                <Row>
                  {this.state.item!==null?this.props.header.additionalData2(this.state.item):''}
                </Row>
              </Col>
            </Row>
          </Col>
          {
            this.state.item !== null &&
            (
              this.props.credentials && this.props.credentials.roles
              &&
              (
                (
                  this.props.credentials.roles.includes('ROLE_QUOTE_CREATE')
                  && this.state.item.active_quote
                ) || (
                  this.props.credentials.roles.includes('ROLE_ORDER_CREATE')
                  && this.state.item.active_order
                )
              )
            )
            ?
            <Col className="d-flex justify-content-end align-items-center">
              <div className="item-index-card-footer-cart-form-container">
                <div className="item-index-input-quantity-container"><input className="item-index-input-quantity" type="number" value={this.state.quantity} onChange={(e) => this.setState({quantity: e.target.value})} /></div>
                <Button variant="my-terciary-outline" className="item-index-input-submit" onClick={this.addToCart}><i className="icon-cart-new"/></Button>
              </div>
            </Col>
            :
            <div/>
          }
          <Col xs={12} md='auto'>
            {this.displayMenu()}
          </Col>
        </Row>
      )
    }
    return (
      <div><br/></div>
    )
  }

  displayFlashbag() {
    return (
      <Flashbag />
    )
  }

  /**
   * Display the tabs
   */
  displayTabs() {
    if(this.state.loading) {
      return null;//TODO should be loading component
    } else {
      return (
        <Tabs activeKey={this.state.tabsKey} onSelect={this.changeTab} id="tabs-users" className="entity-tabs-selection">
          {Object.keys(this.props.tabs)
            .filter((key) => {
              let tab = this.props.tabs[key];
              return tab.role === undefined
                || (typeof tab.role === 'string' && this.props.credentials.roles.includes(tab.role))
                || (Array.isArray(tab.role) && tab.role.some((role) => this.props.credentials.roles.includes(role)));
            })
            .filter((key) => {
              let tab = this.props.tabs[key];
              return tab.shouldDisplay === undefined
                || (typeof tab.shouldDisplay === 'function' && tab.shouldDisplay(this.state.item))
                || (typeof tab.shouldDisplay !== 'function' && tab.shouldDisplay);
            })
            .map((key) => {
              let tab = this.props.tabs[key];
              switch (tab.type) {
                case 'form':
                  let editable = false;
                  if(typeof tab.editable === 'function') {
                    editable = tab.editable(this.state.item, this.props.credentials);
                  }
                  else if(Array.isArray(tab.editable)) {
                    editable = tab.editable.some((role) => this.props.credentials.roles.includes(role));
                  }
                  else if(typeof tab.editable === 'string') {
                    editable = this.props.credentials.roles.includes(tab.editable);
                  }
                  else if(tab.editable === true) {
                    editable = true;
                  }
                  return (
                    <Tab key={key} eventKey={key} title={tab.title} icon={editable?"icon-pen":""}>
                      <MyFormTab
                        key={key}
                        title={tab.title}
                        apiGet={tab.apiGet}
                        apiEdit={tab.apiEdit}
                        entityId={this.props.match.params.id}
                        form={tab.form}

                        editable={editable}

                        onUpdateItem={tab.onUpdateItem}
                        onClickExtraButton={() => this.reloadItem()}
                        onSubmit={tab.onSubmit}
                        postEdit={tab.postEdit}
                        changeTab={this.changeTab}
                      />
                   </Tab>
                  )
                case 'ticket_history':
                  return (
                    <Tab key={key} eventKey={key} title={tab.title} icon={tab.icon}>
                      <TicketHistoryTab
                        key={key}
                        title={tab.title}
                        icon={tab.icon}
                        entityId={this.props.match.params.id}
                        disabled={tab.disabled}
                        onUpdateItem={tab.onUpdateItem}
                        onClickExtraButton={() => this.reloadItem()}
                        onSubmit={tab.onSubmit}
                        postEdit={tab.postEdit}
                        changeTab={this.changeTab}

                        subType={tab.subType}

                        item={this.state.item}

                        commentForm={tab.commentForm}
                        fileForm={tab.fileForm}
                        appointmentForm={tab.appointmentForm}
                        formAnswerForm={tab.formAnswerForm}

                        newCommentForm={tab.newCommentForm}
                        newFileForm={tab.newFileForm}
                        newFormAnswerForm={tab.newFormAnswerForm}
                        newAppointmentForm={tab.newAppointmentForm}

                        postEditAppointment={tab.postEditAppointment}

                        apiHistory={tab.apiHistory}
                        apiNewComment={tab.apiNewComment}
                        apiNewFile={tab.apiNewFile}
                        apiNewFormAnswer={tab.apiNewFormAnswer}
                        apiEditFormAnswer={tab.apiEditFormAnswer}
                        apiNewAppointment={tab.apiNewAppointment}
                      />
                   </Tab>
                  )
                case 'centreon_chart':
                  return (
                    <Tab key={key} eventKey={key} title={tab.title} icon={tab.icon}>
                      <CentreonChartTab
                        key={key}
                        title={tab.title}
                        icon={tab.icon}

                        entityId={this.props.match.params.id}

                        apiGet={tab.apiGet}
                        apiCentreon={tab.apiCentreon}
                      />
                   </Tab>
                  )
                default:
              }
              return null;
            }
          )}
        </Tabs>
      )
    }
  }

  displayMenu() {
    if(this.props.menu) {
      return (
        <Menu
          conf={this.props.menu}
          item={this.state.item}
          updateItem={this.updateItemFromMenu}
        />
      )
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayLoading()}
        {this.displayPageTitle()}
        {this.displayDataHeader()}
        {this.displayFlashbag()}
        {this.displayTabs()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default withModalHandler(connect(mapStateToProps)(withRouter(MyEntityTabs)));
