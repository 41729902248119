import React from 'react';
import BasicWidget from './BasicWidget';

import Table from '../../Table/Table';
import TableHeader from '../../Table/TableHeader';
import TableBody from '../../Table/TableBody';
import TableTextHeader from '../../Table/Header/TableTextHeader';
import TableRow from '../../Table/Body/TableRow';

import StringCell from '../../Form/Field/TableCell/StringCell';
import LoadingCell from '../../Table/Body/Cell/LoadingCell';

// import '../../../css/dashboard/widget_list_stat.css';

/**
 * WidgetList
 */
class WidgetListStat extends BasicWidget {

  static defaultProps = {
    interval: 60,

    conf: {}
  }

  constructor(props) {
    super(props);

    this.requests = [];

    this.timer = [];

    this.loading = [];

    Object.assign(this.state, {
      loading: [],

      values: [],
    })
  }

  componentDidMount() {
    this.initiateApi()
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.parameterBag !== this.props.parameterBag || prevProps.conf !== this.props.conf) {
      this.initiateApi(true)
    }
    else {
      this.initiateApi()
    }
  }

  componentWillUnmount() {
    this.timer.forEach((timer, i) => {
      clearInterval(timer);
    });
  }

  postChangeArgument() {
    this.initiateApi(true);
  }

  initiateApi(forceFetch = false) {
    this.props.conf.stats
      .filter(item => {
        return item.stat && item.attribute
      })
      .forEach((stat, i) => {
        if(!this.requests[i]) {
          this.requests[i] = {
            fetch: this.props.modalHandler.addVerificationWithCallback(this.props.widgetType.elementSettings[this.props.conf.element].fetch, (data) => this.requestCallback(stat, i, data), this.requestCallbackFailure, true)
          }
        }
        let tempForceFetch = forceFetch;
        if(!this.timer[i]) {
          this.timer[i] = setInterval(() => this.timeFetch(stat, i), this.props.interval * 1000);
          tempForceFetch = true;
        }
        if(tempForceFetch) {
          this.timeFetch(stat, i);
        }
      }
    );
  }

  timeFetch = (stat, i) => {
    if(this.requests[i]) {
      this.loading[i] = true;
      this.setState({
        loading: this.loading
      }, () => {
        let filters = [];
        if(this.props.conf.element && this.props.widgetType.elementSettings[this.props.conf.element].computeFilters) {
          filters = Object.assign({}, stat.filters);
          Object.keys(filters).forEach(key => {
            filters[key] = this.evaluateArgument(`stats.${i}.filters.${key}`);
          });
          filters = this.props.widgetType.elementSettings[this.props.conf.element].computeFilters(filters);
        }
        this.requests[i].fetch(stat.stat, stat.attribute, filters)
      })
    }
  }

  requestCallback = (stat, i, data) => {
    let values = [...this.state.values];
    values[i] = data.result
    this.loading[i] = false;
    this.setState({
      loading: this.loading,

      values: values
    })
  }

  requestCallbackFailure = (msg) => {
    console.log(msg);
  }

  onClickItem = (i, id, data) => {

    let item = this.props.conf.stats.filter(item => item.stat && item.attribute)[i];

    let filters = Object.assign({}, item.filters);
    Object.keys(filters).forEach(key => {
      filters[key] = this.evaluateArgument(`stats.${i}.filters.${key}`);
    });

    this.props.history.push(this.props.widgetType.elementSettings[this.props.conf.element].link(), { filters: filters });
  }

  displayList() {
    // let headers = this.props.conf.headers?this.props.conf.headers:[];
    // let headers = [];
    // if(Array.isArray(this.props.conf.headers)) {
    //   headers = this.props.widgetType.elementSettings[this.props.conf.element].extraValues.headers.filter(header => this.props.conf.headers.includes(header.id))
    // }
    return (
      <div className="dashboard-widget-list-container">
        <Table
          cellCollection={{
            string: StringCell,
            loading: LoadingCell
          }}>
          <TableHeader>
            <TableTextHeader value="title" label="Titre" type="string" />
            <TableTextHeader value="value" label="Valeur" type={(data, i) => this.state.loading[i]?"loading":"string"} />
            <TableTextHeader value="unit" label="Unité" type="string" />
          </TableHeader>
          <TableBody>
            {this.props.conf.stats?this.props.conf.stats
              .filter(item => {
                return item.stat && item.attribute
              })
              .map((item, i) => {
                let as = null;
                let onClickItem = null;
                if(item.stat === "quantity") {
                  as = "button"
                  onClickItem = (id, data) => this.onClickItem(i, id, data)
                }
                let value = "";
                if(!this.state.loading[i]) {
                  value = this.state.values[i]
                }

                if(this.props.widgetType.elementSettings[this.props.conf.element].stat[item.stat].attribute[item.attribute].adapter) {
                  value = this.props.widgetType.elementSettings[this.props.conf.element].stat[item.stat].attribute[item.attribute].adapter(value);
                }
                return (
                  <TableRow
                    key={i}
                    data={{
                      title: item.title,
                      value: value,
                      unit: this.props.widgetType.elementSettings[this.props.conf.element].stat[item.stat].attribute[item.attribute].unit
                    }}
                    as={as}
                    onClickItem={onClickItem}
                    >
                  </TableRow>
                )
              }
            ):undefined}
          </TableBody>
        </Table>
      </div>
    )
  }

  displayContent() {
    return (
      <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
        {this.displayTitle()}
        {this.displayArguments()}
        <div className="dashboard-content-container">
          {this.displayList()}
        </div>
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="dashboard-widget-container">
        {this.displayContent()}
      </div>
    );
  }
}

export default WidgetListStat;
