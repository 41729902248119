import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem,
  request
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of products based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('products', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getFromId(id) {
  return baseGetFromId('products', id);
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('products', id, 'identification');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getConfigurationFromId(id) {
  return baseGetFromId('products', id, 'configuration');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getConfigurationEditFromId(id) {
  return baseGetFromId('products', id, 'configuration_edit');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getContractFromId(id) {
  return baseGetFromId('products', id, 'contract');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getPriceFromId(id) {
  return baseGetFromId('products', id, 'price');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getDependencyFromId(id) {
  return baseGetFromId('products', id, 'dependency');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getDatasheetFromId(id) {
  return baseGetFromId('products', id, 'datasheet');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getSpecialConditionOfSaleFromId(id) {
  return baseGetFromId('products', id, 'special_condition_of_sale');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getPhonePackageFromId(id) {
  return baseGetFromId('products', id, 'phone_package');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getCartFromId(id) {
  return baseGetFromId('products', id, 'cart');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getCartWithDiscountFromId(id) {
  return baseGetFromId('products', id, 'cart_with_discount');
}


/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editFromId(id, data) {
  return baseEditFromId('products', id, data);
}

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editConfigurationFromId(id, data) {
  return baseEditFromId('products', id, data, 'configuration_edit');
}

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editContractFromId(id, data) {
  return baseEditFromId('products', id, data, 'contract');
}

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editPriceFromId(id, data) {
  return baseEditFromId('products', id, data, 'price');
}

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editDependencyFromId(id, data) {
  return baseEditFromId('products', id, data, 'dependency');
}

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editDatasheetFromId(id, data) {
  return baseEditFromId('products', id, data, 'datasheet');
}

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editSpecialConditionOfSaleFromId(id, data) {
  return baseEditFromId('products', id, data, 'special_condition_of_sale');
}

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editPhonePackageFromId(id, data) {
  return baseEditFromId('products', id, data, 'phone_package');
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new product
 *
 * @param data the new data of the product
 */
export function newItem(data) {
  return baseNewItem('products', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all products to serve in a dropdown form choice
 */
export function fetchProducts() {
  return baseIndex('products', 1, 0);
}

export function fetchActiveTicketProduct() {
  let search = {
    active_ticket: 1
  }
  return baseIndex('products', 1, 0, search);
}

/******************************************************************************
 * SPECIFIC FUNCTION                                                          *
 ******************************************************************************/

 /**
  * Check if a code is already in use or not
  * The product defined by the id is excluded from the check
  *
  * @param id the user to be exclude of the list of user
  * @param username the username to check
  */
 export function checkCode(id = null, code = null) {
   let params = {
     id: id,
     code: code
   };
   return request('/products/check_code', "POST", params);
 }
