export const quote_list = {
  id: "quote_list-1",
  default: true,
  roles: ["ROLE_QUOTE"],

  title: "Devis",
  type: "list",
  conf: {
    element: "quote",
    headers: [
      "state",
      "number",
      "title",
      "place"
    ],
    filters: {
      state:{
        _parameterType:"value",
        _title:"",
        value:[
          {
            value:"q_w_agreement",
            label:"En attente de BpA"
          },
          {
            value:"q_w_validation",
            label:"En attente de validation"
          }
        ]
      },
    }
  },
  column: 3,
  row: 2
}
