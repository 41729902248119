import strings from '../../Localization/Localization';

import { form as _dropdownElementForm } from './ticketFormFieldDropdownElements';

let label = {
  fieldKey: "label",
  type: "text",
  label: strings.form.ticketFormField.label.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.ticketFormField.label.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let type = {
  fieldKey: "field_type",
  type: "dropdown",
  label: strings.form.ticketFormField.type.label,

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.ticketFormField.type.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 'text',
      label: strings.form.ticketFormField.type.options.text
    },
    {
      value: 'textarea',
      label: strings.form.ticketFormField.type.options.textarea
    },
    {
      value: 'dropdown',
      label: strings.form.ticketFormField.type.options.dropdown
    },
  ],
}

let order = {
  fieldKey: "field_order",
  type: "text",
  label: strings.form.ticketFormField.order.label,

  validators: [
    {
      id: 'assertEmpty',
      type: 'regex',
      conf: {
        regex: /\d+/
      },
      invalidFeedback: strings.form.ticketFormField.order.invalid.assertEmpty,
      defaultValue: false
    },
  ],
}

let dropdownElements = {
  fieldKey: "dropdown_elements",
  type: "array",
  label: strings.form.ticketFormField.dropdownElements.label,
  disabled: (getValues) => !(getValues(0) && getValues(0)["field_type"] && getValues(0)["field_type"] === 'dropdown'),
  showOnDisabled: (values) => false,

  events: [
    {
      id: "reset",
      event: "onChange",
      target: "field_type",
      function: (component) => component?component.resetArray():null
    }
  ],

  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.ticketFormField.dropdownElements.invalid.assertQuantity,
      defaultValue: false
    },
  ],

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.ticketForm.fields.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.ticketForm.fields.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.ticketForm.fields.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: "",

    validators: [],

    subForm: _dropdownElementForm
  }
}

export const form = {
  formKey: "ticketFormField",
  fields: [
    label,
    type,
    order,
    dropdownElements
  ]
}
