import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of phone price lists based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('phone_price_lists', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single phone price list based on his ID
 *
 * @param id the of the phone price list requested
 */
export function getFromId(id) {
  return baseGetFromId('phone_price_lists', id);
}

/**
 * Get a single phone price list based on his ID
 *
 * @param id the of the phone price list requested
 */
export function getPriceFromId(id) {
  return baseGetFromId('phone_price_lists', id, 'price');
}

/**
 * Get a single phone price list based on his ID
 *
 * @param id the of the phone price list requested
 */
export function getCompletePriceFromId(id) {
  return baseGetFromId('phone_price_lists', id, 'complete_price');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single phone price list based on his ID
 *
 * @param id the of the phone price list requested
 * @param data the new data of the phone price list
 */
export function editFromId(id, data) {
  return baseEditFromId('phone_price_lists', id, data);
}

/**
 * Edit a single phone price list based on his ID
 *
 * @param id the of the phone price list requested
 * @param data the new data of the phone price list
 */
export function editCompletePriceFromId(id, data) {
  return baseEditFromId('phone_price_lists', id, data, 'complete_price');
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new phone price list
 *
 * @param data the new data of the phone price list
 */
export function newItem(data) {
  return baseNewItem('phone_price_lists', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all phone price lists to serve in a dropdown form choice
 */
export function fetchPhonePriceLists() {
  return baseIndex('phone_price_lists', 1, 0);
}
