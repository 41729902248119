import strings from '../../../Localization/Localization';

let prefix = {
  fieldKey: "prefix",
  type: "text",
  label: strings.form.phonePrefix.prefix.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "phonePrefixSearch",
  fields: [
    prefix
  ]
}
