import strings from '../../../Localization/Localization';

import { fetchRoleGroups } from '../../../API/RoleGroups';
import { fetchRoles } from '../../../API/Roles';

let label = {
  fieldKey: "label",
  type: "text",
  label: strings.form.profil.label.label,

  validators: []
}

let active = {
  fieldKey: "active",
  type: "triswitch",
  label: strings.form.profil.active.label,

  validators: []
}

let group = {
  fieldKey: "group",
  type: "dropdownEntity",
  label: strings.form.profil.group.label,

  validators: [],

  loader: {
    id: "profils",
    loader: fetchRoleGroups,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["label"],
    getValue: (data) => data["id"],
    getLabel: (data) => strings.resolve('common.'+data["label"]),
  },
}

let role = {
  fieldKey: "role",
  type: "dropdownEntity",
  label: strings.form.profil.role.label,

  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "group",
      function: (component) => component.reloadData()
    }
  ],

  loader: {
    id: "roles",
    loader: fetchRoles,
    loaderRequire: () => true,
    loaderArguments: (value, getValues) => {
      if(getValues(1)["group"]) {
        return [{
          group_id: getValues(1)["group"]["id"]
        }]
      }
      else {
        return [];
      }
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["label"],
    getValue: (data) => data["id"],
    getLabel: (data) => strings.resolve('common.'+data["label"]),
  },
}

export const form = {
  formKey: "profilSearch",
  fields: [
    label,
    active,
    group,
    role,
  ]
}
