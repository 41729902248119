import strings from '../../Localization/Localization';

import { index, getFromId, editFromId, newItem } from '../../API/AdministrativeTicketForm';
import { form } from '../Form/ticketForms';
import { form as searchForm } from '../Form/Search/ticketForms';

import { administrativeTicketForm as routes } from '../Route/routes';

export const conf = {
  prefix: "ticketForms/administrative",
  index: {
    pageTitle: strings.common.homepage.administrativeTicketForm,

    id: "ticketForm",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_ADMINISTRATIVE_TICKET_FORM_CREATE'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => item["name"],
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.administrativeTicketForm,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.ticketForm.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_ADMINISTRATIVE_TICKET_FORM_EDIT'],
        editable: ['ROLE_ADMINISTRATIVE_TICKET_FORM_EDIT']
      }
    }
  },
  new: {
    pageTitle: strings.ticketForm.new.title,

    api: newItem,
    empty: {},
    form: form,
    onSubmit: (item) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
