const phonePackage = {
  canalQuantity: {
    label: "Nombre de canaux",
  },
  phonePackage: {
    label: "Forfait téléphonique"
  }
};

export default phonePackage;
