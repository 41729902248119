export const commercial_ticket_list = {
  id: "commercial_ticket_list-1",
  default: true,
  roles: [],

  title: "Tickets commerciaux",
  type: "list",
  conf: {
    element: "commercialTicket",
    headers: [
      "state",
      "criticity",
      "escalateLevel",
      "number",
      "place",
    ],
    filters: {
      state:{
        _parameterType:"value",
        _title:"",
        value:[
          {
            value:"new",
            label:"Nouveau"
          },
          {
            value:"waiting",
            label:"En attente"
          },
          {
            value:"in_progress",
            label:"En cours de progrès"
          }
        ]
      },
    }
  },
  column: 3,
  row: 2
}
