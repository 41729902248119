export const CREATE_CONTAINER = 'create_container';
export const MODIFY_FORM_VALUE = 'modify_form_value';
export const RESET_CONTAINER = 'reset_container';

export function createContainer(processId, steps) {
  return {
    type: CREATE_CONTAINER,
    processId: processId,
    steps: steps
  }
}

export function modifyFormValue(processId, stepId, key, value) {
  return {
    type: MODIFY_FORM_VALUE,
    processId: processId,
    stepId: stepId,
    key: key,
    value: value
  }
}

export function resetContainer(processId) {
  return {
    type: RESET_CONTAINER,
    processId: processId
  }
}
