import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of pre fill comments based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('pre_fill_comment/administrative', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single pre fill comment based on his ID
 *
 * @param id the of the pre fill comment requested
 */
export function getFromId(id) {
  return baseGetFromId('pre_fill_comment/administrative', id);
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getIdentificationDataFromId(id) {
  return baseGetFromId('pre_fill_comment/administrative', id, 'id');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single pre fill comment based on his ID
 *
 * @param id the of the pre fill comment requested
 * @param data the new data of the pre fill comment
 */
export function editFromId(id, data) {
  return baseEditFromId('pre_fill_comment/administrative', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new pre fill comment
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return baseNewItem('pre_fill_comment/administrative', data);
}


/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get the complete list of pre fill comments based on a pagination system
 */
export function fetchTicketTypes(){
  return baseIndex('pre_fill_comment/administrative', 1, 0);
}
