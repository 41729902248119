export const MODIFY_SEARCH = 'modify_search';
export const REMOVE_SEARCH = 'remove_search';
export const RESET_SEARCH = 'reset_search';
export const MODIFY_ITEM_PER_PAGE_SEARCH = 'modify_item_per_page_search';
export const MODIFY_ORDER_SEARCH = 'modify_order_search';
export const MODIFY_DISPLAY_TYPE_SEARCH = 'modify_display_type_search';

export function modifySearchAction(index, fields){
  return {
    type: MODIFY_SEARCH,
    index: index,
    fields: fields
  }
}

export function removeSearchAction(index, fieldKey){
  return {
    type: REMOVE_SEARCH,
    index: index,
    fieldKey: fieldKey
  }
}

export function resetSearchAction(index){
  return {
    type: RESET_SEARCH,
    index: index
  }
}

export function modifyItemPerPageAction(index, itemPerPage){
  return {
    type: MODIFY_ITEM_PER_PAGE_SEARCH,
    index: index,
    itemPerPage: itemPerPage
  }
}

export function modifyOrderAction(index, order){
  return {
    type: MODIFY_ORDER_SEARCH,
    index: index,
    order: order
  }
}

export function modifyDisplayTypeAction(index, displayType){
  return {
    type: MODIFY_DISPLAY_TYPE_SEARCH,
    index: index,
    displayType: displayType
  }
}
