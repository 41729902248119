import {
  getFromId as baseGetFromId,
  index as baseIndex
 } from './Base';


/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single centreon service based on his ID
 *
 * @param id
 */
export function getMetricsFromId(id, timeframe = "3Hours") {
 return baseGetFromId('centreon_services', id, 'metrics', { timeframe: timeframe });
}


/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all code nafs to serve in a dropdown form choice
 */
export function fetchCentreonServices(search, scheme = null) {
  return baseIndex('centreon_services', 1, 0, search, 'ASC', scheme);
}
