import strings from '../../Localization/Localization';

import { processTimeframe } from './common';

import {
  index,
  getFromId,
  getIdentificationFromId,
  // editFromId
} from '../../API/CommercialBonus';
import {
  form
} from '../Form/commercialBonuses';
import { form as searchForm } from '../Form/Search/commercialBonuses';

import { commercialBonus as routes } from '../Route/routes';


export const conf = {
  prefix: "commercialBonus",
  index: {
    pageTitle: strings.common.homepage.commercialBonus,

    id: "commercialBonus",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.bonusType || search.bonusType === 0) {
        result["bonus_type"] = search.bonusType;
      }
      if(search.flatBonusValue) {
        result["flat_bonus_value"] = search.flatBonusValue;
      }
      if(search.user) {
        result["user_id"] = search.user.id;
      }
      if(search.createdAtTimeframe) {
        let createdAtTimeframe = processTimeframe(search.createdAtTimeframe);

        result["created_at_min"] = createdAtTimeframe.min;
        result["created_at_max"] = createdAtTimeframe.max;
      }
      if(search.receivedAtPrevisionTimeframe) {
        let receivedAtPrevisionTimeframe = processTimeframe(search.receivedAtPrevisionTimeframe);

        result["received_at_prevision_min"] = receivedAtPrevisionTimeframe.min;
        result["received_at_prevision_max"] = receivedAtPrevisionTimeframe.max;
      }
      if(search.receivedAtTimeframe) {
        let receivedAtTimeframe = processTimeframe(search.receivedAtTimeframe);

        result["received_at_min"] = receivedAtTimeframe.min;
        result["received_at_max"] = receivedAtTimeframe.max;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            id: "bonusType",
            label: "Type",
            value: item => {
              switch (item['bonus_type']) {
                case 0:
                  return strings.form.commercialBonus.bonusType.options.quotePunctual;
                case 1:
                  return strings.form.commercialBonus.bonusType.options.quoteAnnual;
                case 2:
                  return strings.form.commercialBonus.bonusType.options.serviceRecurrentNoEngagement;
                default:
                  return item['bonus_type'];
              }
            }
          },
          {
            id: "commercial",
            label: "Commercial",
            value: item => item["user"]["displayname"],
          },
          {
            id: "totalTfBase",
            label: "Base de calcul",
            value: "total_tf_base",
            type: "price"
          },
          {
            id: "flatBonusValue",
            label: "Montant",
            value: "flat_bonus_value",
            type: "price"
          },
        ]
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.commercialBonus,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "commercialBonus",

    apiGet: getIdentificationFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.ticket.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        apiGet: getFromId,

        role: ['ROLE_COMMERCIAL_BONUS'],
      }
    }
  },
}
