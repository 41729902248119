import React from 'react';

/**
 * TableCell
 */
class TableCell extends React.Component {

  displayInnerCell() {
    if(this.props.href) {
      return (
        <a className="table-cell-link" href={this.props.href}>
          <div className="table-cell-link">
            {this.props.value}
          </div>
        </a>
      )
    }
    else {
      return this.props.value;
    }
  }

  tdAdditionClassName() {
    return "";
  }

  /**
   * Main render method for React Component
   */
  render() {
    if(this.props.colSpan === 0) {
      return null;
    }
    return (
      <td colSpan={this.props.colSpan} className={`${this.tdAdditionClassName()}`}>
        {this.displayInnerCell()}
      </td>
    );
  }
}

export default TableCell;
