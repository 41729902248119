import strings from '../../Localization/Localization';

let beginAt = {
  fieldKey: "begin_at",
  type: "date",
  label: strings.form.contract.beginAt.label,
  validators: []
}

let engagement = {
  fieldKey: "engagement",
  type: "dropdown",
  label: strings.form.contract.engagement.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 1,
      label: strings.form.contract.engagement.options.none
    },
    {
      value: 12,
      label: strings.form.contract.engagement.options.year1
    },
    {
      value: 24,
      label: strings.form.contract.engagement.options.year2
    },
    {
      value: 36,
      label: strings.form.contract.engagement.options.year3
    },
    {
      value: 48,
      label: strings.form.contract.engagement.options.year4
    },
    {
      value: 60,
      label: strings.form.contract.engagement.options.year5
    }
  ],
}

let billPeriod = {
  fieldKey: "bill_period",
  type: "dropdown",
  label: strings.form.contract.billPeriod.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: -1,
      label: strings.form.contract.billPeriod.options.oneshot
    },
    {
      value: 1,
      label: strings.form.contract.billPeriod.options.monthly
    },
    {
      value: 2,
      label: strings.form.contract.billPeriod.options.fortnightly
    },
    {
      value: 3,
      label: strings.form.contract.billPeriod.options.quarterly
    },
    {
      value: 6,
      label: strings.form.contract.billPeriod.options.halfYearly
    },
    {
      value: 12,
      label: strings.form.contract.billPeriod.options.yearly
    }
  ],
}

let priorNotice = {
  fieldKey: "prior_notice",
  type: "dropdown",
  label: strings.form.contract.priorNotice.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.contract.priorNotice.options.none
    },
    {
      value: 1,
      label: strings.form.contract.priorNotice.options.month1
    },
    {
      value: 3,
      label: strings.form.contract.priorNotice.options.month3
    }
  ],
}

let tacitAgreement = {
  fieldKey: "tacit_agreement",
  type: "dropdown",
  label: strings.form.contract.tacitAgreement.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.contract.tacitAgreement.options.none
    },
    {
      value: 1,
      label: strings.form.contract.tacitAgreement.options.month1
    },
    {
      value: 12,
      label: strings.form.contract.tacitAgreement.options.year1
    }
  ],
}

let expectedTacitAgreementAt = {
  fieldKey: "expected_tacit_agreement_at",
  type: "date",
  label: strings.form.contract.expectedTacitAgreementAt.label,
  validators: []
}

let terminationPrice = {
  fieldKey: "termination_price",
  type: "price",
  label: strings.form.contract.terminationPrice.label,
  validators: [],
  defaultValue: 0,
}

let additionalTerminationPrice = {
  fieldKey: "additional_termination_price",
  type: "price",
  label: strings.form.contract.additionalTerminationPrice.label,
  validators: [],
  defaultValue: 0,
}

let guaranteedFaultRepairTime = {
  fieldKey: "guaranteed_fault_repair_time",
  type: "dropdown",
  label: strings.form.contract.guaranteedFaultRepairTime.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.contract.guaranteedFaultRepairTime.options.best_effort
    },
    {
      value: 1,
      label: strings.form.contract.guaranteedFaultRepairTime.options._24_8_5_7
    },
    {
      value: 2,
      label: strings.form.contract.guaranteedFaultRepairTime.options._8_8_5_7
    },
    {
      value: 3,
      label: strings.form.contract.guaranteedFaultRepairTime.options._4_8_5_7
    },
    {
      value: 4,
      label: strings.form.contract.guaranteedFaultRepairTime.options._1_8_5_7
    },
    {
      value: 5,
      label: strings.form.contract.guaranteedFaultRepairTime.options._24_24_5_7
    },
    {
      value: 6,
      label: strings.form.contract.guaranteedFaultRepairTime.options._8_24_5_7
    },
    {
      value: 7,
      label: strings.form.contract.guaranteedFaultRepairTime.options._4_24_5_7
    },
    {
      value: 8,
      label: strings.form.contract.guaranteedFaultRepairTime.options._1_24_5_7
    },
    {
      value: 9,
      label: strings.form.contract.guaranteedFaultRepairTime.options._24_24_7_7
    },
    {
      value: 10,
      label: strings.form.contract.guaranteedFaultRepairTime.options._8_24_7_7
    },
    {
      value: 11,
      label: strings.form.contract.guaranteedFaultRepairTime.options._4_24_7_7
    },
    {
      value: 12,
      label: strings.form.contract.guaranteedFaultRepairTime.options._1_24_7_7
    },
    {
      value: 13,
      label: strings.form.contract.guaranteedFaultRepairTime.options.parent_value
    },
  ],
}

let guaranteedResponseTime = {
  fieldKey: "guaranteed_response_time",
  type: "dropdown",
  label: strings.form.contract.guaranteedResponseTime.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.contract.guaranteedResponseTime.options.best_effort
    },
    {
      value: 1,
      label: strings.form.contract.guaranteedResponseTime.options._24_8_5_7
    },
    {
      value: 2,
      label: strings.form.contract.guaranteedResponseTime.options._8_8_5_7
    },
    {
      value: 3,
      label: strings.form.contract.guaranteedResponseTime.options._4_8_5_7
    },
    {
      value: 4,
      label: strings.form.contract.guaranteedResponseTime.options._1_8_5_7
    },
    {
      value: 5,
      label: strings.form.contract.guaranteedResponseTime.options._24_24_5_7
    },
    {
      value: 6,
      label: strings.form.contract.guaranteedResponseTime.options._8_24_5_7
    },
    {
      value: 7,
      label: strings.form.contract.guaranteedResponseTime.options._4_24_5_7
    },
    {
      value: 8,
      label: strings.form.contract.guaranteedResponseTime.options._1_24_5_7
    },
    {
      value: 9,
      label: strings.form.contract.guaranteedResponseTime.options._24_24_7_7
    },
    {
      value: 10,
      label: strings.form.contract.guaranteedResponseTime.options._8_24_7_7
    },
    {
      value: 11,
      label: strings.form.contract.guaranteedResponseTime.options._4_24_7_7
    },
    {
      value: 12,
      label: strings.form.contract.guaranteedResponseTime.options._1_24_7_7
    },
    {
      value: 13,
      label: strings.form.contract.guaranteedResponseTime.options.parent_value
    },
  ],
}

let serviceLevelAgreement = {
  fieldKey: "service_level_agreement",
  type: "dropdown",
  label: strings.form.contract.serviceLevelAgreement.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.contract.serviceLevelAgreement.options._99_00
    },
    {
      value: 1,
      label: strings.form.contract.serviceLevelAgreement.options._99_50
    },
    {
      value: 2,
      label: strings.form.contract.serviceLevelAgreement.options._99_99
    },
    {
      value: 3,
      label: strings.form.contract.serviceLevelAgreement.options.parent_value
    },
  ],
}

let externalNumber = {
  fieldKey: "external_number",
  type: "text",
  label: strings.form.contract.externalNumber.label,
  validators: [
    // {
    //   id: 'assertLengthMax',
    //   type: 'lengthMax',
    //   conf: {
    //     max: 30
    //   },
    //   invalidFeedback: strings.form.contract.externalNumber.invalid.assertLengthMax,
    //   defaultValue: false
    // },
  ]
}

let externalNumberDuration = {
  fieldKey: "external_number_duration",
  type: "text", //TODO integer
  label: strings.form.contract.externalNumberDuration.label,
  defaultValue: 0,
  validators: []
}

export const newDefaultContractForm = {
  formKey: "contract",
  fields: [
    engagement,
    billPeriod,
    priorNotice,
    tacitAgreement,
    terminationPrice,
    additionalTerminationPrice,
    guaranteedFaultRepairTime,
    guaranteedResponseTime,
    serviceLevelAgreement
  ]
}

export const activeForm = {
  formKey: "contract",
  fields: [
    Object.assign({}, beginAt, {
      disabled: true
    }),
    Object.assign({}, engagement, {
      disabled: true
    }),
    Object.assign({}, billPeriod, {
      disabled: true
    }),
    Object.assign({}, priorNotice, {
      disabled: true
    }),
    Object.assign({}, tacitAgreement, {
      disabled: true
    }),
    Object.assign({}, expectedTacitAgreementAt, {
      disabled: true
    }),
    Object.assign({}, terminationPrice, {
      disabled: true
    }),
    Object.assign({}, additionalTerminationPrice, {
      disabled: true
    }),
    Object.assign({}, guaranteedFaultRepairTime, {
      disabled: true
    }),
    Object.assign({}, guaranteedResponseTime, {
      disabled: true
    }),
    Object.assign({}, serviceLevelAgreement, {
      disabled: true
    }),
    externalNumber,
    externalNumberDuration,
  ]
}

export const form = {
  formKey: "contract",
  fields: [
    beginAt,
    engagement,
    billPeriod,
    priorNotice,
    tacitAgreement,
    terminationPrice,
    additionalTerminationPrice,
    guaranteedFaultRepairTime,
    guaranteedResponseTime,
    serviceLevelAgreement,
    externalNumber,
    externalNumberDuration,
  ]
}
