import React from 'react';

import strings from '../../../Localization/Localization';

let type = {
  fieldKey: "type",
  type: "dropdown",
  label: strings.dashboard.editWidget.form.type.label,
  showOnDisabled: () => true,
  validators: [],

  showEmpty: true,
  input: 'formValues',
  compute: (getValues, extraValues) => {
    if(extraValues.dashboardConf && extraValues.dashboardConf.widgets) {
      return extraValues.dashboardConf.widgets.map((widget) => {
        return {
          id: widget.id,
          name: widget.name,
          icon: widget.icon
        }
      });
    }
    return [];
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => <div>{data["icon"]?<><i className={data["icon"]}/>&nbsp;&nbsp;&nbsp;</>:""}{data["name"]}</div>,
    isValue: true
  },
}

let column = {
  fieldKey: "column",
  type: "dropdown",
  label: strings.dashboard.editWidget.form.column.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  input: 'formValues',
  compute: (getValues, extraValues) => {
    let values = getValues();
    if(extraValues.dashboardConf && extraValues.dashboardConf.widgets && values.type) {
      let widget = extraValues.dashboardConf.widgets.find((widget) => widget.id === values.type);
      if(widget) {
        return Array.from(Array(widget.column.max - widget.column.min + 1), (_, i) => i+widget.column.min)
      }
    }
    return [];
  },

  displayAsRows: false
}

let row = {
  fieldKey: "row",
  type: "dropdown",
  label: strings.dashboard.editWidget.form.row.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  input: 'formValues',
  compute: (getValues, extraValues) => {
    let values = getValues();
    if(extraValues.dashboardConf && extraValues.dashboardConf.widgets && values.type) {
      let widget = extraValues.dashboardConf.widgets.find((widget) => widget.id === values.type);
      if(widget) {
        return Array.from(Array(widget.row.max - widget.row.min + 1), (_, i) => i+widget.row.min)
      }
    }
    return [];
  },

  displayAsRows: false
}

export const editPage = {
  formKey: "dashboardEditWidgetPage",
  fields: [
    type,
    column,
    row
  ],
  extraValues: {
    dashboardConf: null //this must be set by the component displaying the form
  }
}
