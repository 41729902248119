import React from 'react';

import '../../css/badge.css';

/**
 * Badge
 */
class Badge extends React.Component {

  static defaultProps = {
    value: null,
    disabled: false,
    rounded: false,
    position: 'top-left-corner',
    size: 'normal',
    variant: 'info'
  }

  displayBadge() {
    let roundedCss = "badge-squared";
    if(this.props.rounded) {
      roundedCss = "badge-rounded";
    }

    let positionCss = null;
    switch (this.props.position) {
      case 'bottom-right-circle':
        positionCss = "badge-bottom-right-circle";
        break;
      case 'bottom-left-circle':
        positionCss = "badge-bottom-left-circle";
        break;
      case 'top-right-circle':
        positionCss = "badge-top-right-circle";
        break;
      case 'top-left-circle':
        positionCss = "badge-top-left-circle";
        break;
      case 'bottom-right-corner':
        positionCss = "badge-bottom-right-corner";
        break;
      case 'bottom-left-corner':
        positionCss = "badge-bottom-left-corner";
        break;
      case 'top-right-corner':
        positionCss = "badge-top-right-corner";
        break;
      case 'top-left-corner':
      default:
        positionCss = "badge-top-left-corner";
    }

    let sizeCss = null;
    switch (this.props.size) {
      case 'small':
        sizeCss = "badge-small";
        break;
      case 'big':
        sizeCss = "badge-big";
        break;
      case 'normal':
      default:
        sizeCss = "badge-normal";
    }

    if(this.props.value === null) {
      sizeCss = "badge-no-text";
    }

    let styleCss = null;
    switch (this.props.style) {
      case 'warning':
        styleCss = "badge-warning";
        break;
      case 'danger':
        styleCss = "badge-danger";
        break;
      case 'info':
      default:
        styleCss = "badge-info";
    }

    return (
      <div className={`badge-badge ${roundedCss} ${positionCss} ${sizeCss} ${styleCss}`}>
        {this.props.value}
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    if(Array.isArray(this.props.children)) {
      console.warn('./src/Component/Badge.js', 'Badge should have only one child.');
      return (
        <div>
          {this.props.children}
        </div>
      )
    }

    return (
      <div className="badge-container">
        <div className="badge-child-container">
          {this.props.children}
          {this.props.disabled?<div/>:this.displayBadge()}
        </div>
      </div>
    );
  }
}

export default Badge;
