import strings from '../../../Localization/Localization';

let createdAtMin = {
  fieldKey: "createdAtMin",
  type: "date",
  label: strings.form.billing.createdAtMin.label,
  showOnDisabled: () => false,

  validators: []
}

let createdAtMax = {
  fieldKey: "createdAtMax",
  type: "date",
  label: strings.form.billing.createdAtMax.label,
  showOnDisabled: () => false,

  validators: []
}

export const form = {
  formKey: "billingSearch",
  fields: [
    createdAtMin,
    createdAtMax
  ],
}
