import strings from '../../../Localization/Localization';

let content = {
  fieldKey: "content",
  type: "text",
  label: strings.form.log.content.label,
  showOnDisabled: () => false,
  validators: []
}

let logType = {
  fieldKey: "log_type",
  type: "text",
  label: strings.form.log.logType.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "logSearch",
  fields: [
    content,
    logType
  ]
}
