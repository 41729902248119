import strings from '../../../../Localization/Localization';

let file = {
  fieldKey: "file",
  type: "file",
  label: strings.form.cart.transition.addingAgreement.file.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.cart.transition.addingAgreement.file.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let signatureDate = {
  fieldKey: "signature_date",
  type: "date",
  label: strings.form.cart.transition.addingAgreement.signatureDate.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.cart.transition.addingAgreement.signatureDate.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let externalNumber = {
  fieldKey: "external_number",
  type: "text",
  label: strings.form.cart.transition.addingAgreement.externalNumber.label,
  validators: []
}

let externalNumberFurniture = {
  fieldKey: "external_number_furniture",
  type: "text",
  label: strings.form.cart.transition.addingAgreement.externalNumberFurniture.label,
  validators: []
}

let rightToCancellation = {
  fieldKey: "right_to_cancellation",
  type: "switch",
  label: strings.form.cart.transition.addingAgreement.rightToCancellation.label,
  validators: []
}

export const form = {
  formKey: "addingAgreement",
  fields: [
    file,
    signatureDate,
    externalNumber,
    externalNumberFurniture,
    rightToCancellation
  ]
}
