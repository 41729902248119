import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of product sub families based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('product_sub_families', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single product sub family based on his ID
 *
 * @param id the of the product sub family requested
 */
export function getFromId(id) {
  return baseGetFromId('product_sub_families', id);
}

/**
 * Get a single product sub family based on his ID
 *
 * @param id the of the product requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('product_sub_families', id, 'identification');
}

/**
 * Get a single product sub family based on his ID
 *
 * @param id the of the product requested
 */
export function getConfigurationFromId(id) {
  return baseGetFromId('product_sub_families', id, 'configuration');
}

/**
 * Get a single product sub family based on his ID
 *
 * @param id the of the product requested
 */
export function getConfigurationEditFromId(id) {
  return baseGetFromId('product_sub_families', id, 'configuration_edit');
}

/**
 * Get a single product sub family based on his ID
 *
 * @param id the of the product requested
 */
export function getAccountingFromId(id) {
  return baseGetFromId('product_sub_families', id, 'accounting');
}

/**
 * Get a single product sub family based on his ID
 *
 * @param id the of the product requested
 */
export function getDatasheetFromId(id) {
  return baseGetFromId('product_sub_families', id, 'datasheet');
}

/**
 * Get a single product sub family based on his ID
 *
 * @param id the of the product requested
 */
export function getTechnicalDataFromId(id) {
  return baseGetFromId('product_sub_families', id, 'technical_data');
}

/**
 * Get a single product sub family based on his ID
 *
 * @param id the of the product requested
 */
export function getAPIFromId(id) {
  return baseGetFromId('product_sub_families', id, 'api');
}

/**
 * Get a single product sub family based on his ID
 *
 * @param id the of the product requested
 */
export function getSpecialConditionOfSaleFromId(id) {
  return baseGetFromId('product_sub_families', id, 'special_condition_of_sale');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single product sub family based on his ID
 *
 * @param id the of the product sub family requested
 * @param data the new data of the product sub family
 */
export function editFromId(id, data) {
  return baseEditFromId('product_sub_families', id, data);
}

/**
 * Edit a single product sub family based on his ID
 *
 * @param id the of the product sub family requested
 * @param data the new data of the product sub family
 */
export function editConfigurationFromId(id, data) {
  return baseEditFromId('product_sub_families', id, data, 'configuration_edit');
}

/**
 * Edit a single product sub family based on his ID
 *
 * @param id the of the product sub family requested
 * @param data the new data of the product sub family
 */
export function editAccountingFromId(id, data) {
  return baseEditFromId('product_sub_families', id, data, 'accounting');
}

/**
 * Edit a single product sub family based on his ID
 *
 * @param id the of the product sub family requested
 * @param data the new data of the product sub family
 */
export function editDatasheetFromId(id, data) {
  return baseEditFromId('product_sub_families', id, data, 'datasheet');
}

/**
 * Edit a single product sub family based on his ID
 *
 * @param id the of the product sub family requested
 * @param data the new data of the product sub family
 */
export function editTechnicalDataFromId(id, data) {
  return baseEditFromId('product_sub_families', id, data, 'technical_data');
}

/**
 * Edit a single product sub family based on his ID
 *
 * @param id the of the product sub family requested
 * @param data the new data of the product sub family
 */
export function editAPIFromId(id, data) {
  return baseEditFromId('product_sub_families', id, data, 'api');
}

/**
 * Edit a single product sub family based on his ID
 *
 * @param id the of the product sub family requested
 * @param data the new data of the product sub family
 */
export function editSpecialConditionOfSaleFromId(id, data) {
  return baseEditFromId('product_sub_families', id, data, 'special_condition_of_sale');
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new product
 *
 * @param data the new data of the product sub family
 */
export function newItem(data) {
  return baseNewItem('product_sub_families', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all family to serve in a dropdown form choice
 */
export function fetchSubFamilies(search) {
  return baseIndex('product_sub_families', 1, 0, search);
}
