import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getIdentificationFromId,
  getConfigurationFromId,
  getConfigurationEditFromId,
  getContractFromId,
  getPriceFromId,
  // getDependencyFromId,
  getDatasheetFromId,
  getSpecialConditionOfSaleFromId,
  getPhonePackageFromId,
  editFromId,
  editConfigurationFromId,
  editContractFromId,
  editPriceFromId,
  // editDependencyFromId,
  editDatasheetFromId,
  editSpecialConditionOfSaleFromId,
  editPhonePackageFromId,
  newItem
} from '../../API/Products';
import {
  newForm,
  priceForm,
  // dependencyForm,
  configurationForm,
  configurationEditForm,
  contractForm,
  specialConditionOfSaleForm,
  datasheetForm,
  phonePackageForm,
  form
} from '../Form/products';
import { form as searchForm } from '../Form/Search/products';

import { products as routes } from '../Route/routes';

import { config } from '../../API/config';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.products,

    id: "product",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_MARKETING_PRODUCT_CREATE'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(typeof search.productFamily === 'object') {
        result["product_family_id"] = search.productFamily.id
      }
      if(typeof search.productSubFamily === 'object') {
        result["product_sub_family_id"] = search.productSubFamily.id
      }
      if(search.name) {
        result["name"] = search.name;
      }
      if(search.code) {
        result["code"] = search.code;
      }
      if(search.description) {
        result["description"] = search.description;
      }
      if(search.price) {
        result["price"] = search.price;
      }
      if(search.priceType) {
        result["price_type"] = search.priceType;
      }
      if(search.buyingPrice) {
        result["buying_price"] = search.buyingPrice;
      }
      if(search.buyingCompany) {
        result["buying_company"] = search.buyingCompany;
      }
      if(search.buyingReference) {
        result["buying_reference"] = search.buyingReference;
      }
      if(search.createdAtMin) {
        result["created_at_min"] = search.createdAtMin;
      }
      if(search.createdAtMax) {
        result["created_at_max"] = search.createdAtMax;
      }
      if(search.updatedAtMin) {
        result["updated_at_min"] = search.updatedAtMin;
      }
      if(search.updatedAtMax) {
        result["updated_at_max"] = search.updatedAtMax;
      }

      // if(search.activeOrder === true) {
      //   result["active_order"] = 1
      // }
      // else if(search.activeOrder === false) {
      //   result["active_order"] = 0
      // }
      // if(search.activeQuote === true) {
      //   result["active_quote"] = 1
      // }
      // else if(search.activeQuote === false) {
      //   result["active_quote"] = 0
      // }
      // if(search.activeTicket === true) {
      //   result["active_ticket"] = 1
      // }
      // else if(search.activeTicket === false) {
      //   result["active_ticket"] = 0
      // }
      // if(search.rentable === true) {
      //   result["rentable"] = 1
      // }
      // else if(search.rentable === false) {
      //   result["rentable"] = 0
      // }

      if(typeof search.productConstructor === 'object') {
        result["constructor_id"] = search.productConstructor.id
      }
      if(search.displayHidden !== true) {
        result["display_main_index"] = 1
      }
      return result;
    },

    availableItemDisplay:[/*'line', */'card'/*, 'bar'/*, 'tab'*/],
    itemDisplay: {
      line: {
        label: (item) => `${item.code} > ${item["name"]}`
      },
      card: {
        firstLine: (item) => item["code"],
        secondLine: (item) => item["name"],
        thirdLine: (item) => '',
        fourthLine: (item) => '',
        img: (item) => (item.img)?config.file_path+item.img:null,
      },
      bar: {
        firstLine: (item) => item["code"],
        secondLine: (item) => item["name"],
        thirdLine: (item) => 'a',
        fourthLine: (item) => 'a',
        dateLine: (item) => 'a'
      },
      tab: {
        headers: [
          {
            label: "Code",
            value: "code"
          },
          {
            label: "Nom",
            value: "name"
          }
        ]
      }
    },
    cartItem: {
      priceLine: (item) => {
        let periodicity = "";
        if(item["price_type"] === 1) {
          switch (item["contract"]["bill_period"]) {
            case 1:
              periodicity = ' / ' + strings.product.cartItem.periodicity.monthly;
              break;
            case 2:
              periodicity = ' / ' + strings.product.cartItem.periodicity.fortnightly;
              break;
            case 3:
              periodicity = ' / ' + strings.product.cartItem.periodicity.quarterly;
              break;
            case 6:
              periodicity = ' / ' + strings.product.cartItem.periodicity.halfYearly;
              break;
            case 12:
              periodicity = ' / ' + strings.product.cartItem.periodicity.yearly;
              break;
            default:

          }
        }
        return `${Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(item["price"])}HT${periodicity}`
      }
    },
    // buttons: (item) => {
      // return [
      //   {
      //     label: "test1",
      //     icon: "fa fa-user-ninja"
      //   },
      //   {
      //     label: "test2"
      //   },
      //   {
      //     icon: "fa fa-user-ninja"
      //   },
      // ]
    // },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.products,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getIdentificationFromId,

    extendedHeader: "cartItem",
    header: {
      imgUrl: (item) => (item.img)?config.file_path+item.img:null,
      placeholderTitle: (item) => item.name[0],
      label: (item) => item.name,
      additionalData1: (item) => item.code,
      additionalData2: (item) => {
        let periodicity = "";
        if(item["price_type"] === 1) {
          switch (item["contract"]["bill_period"]) {
            case 1:
              periodicity = ' / ' + strings.product.cartItem.periodicity.monthly;
              break;
            case 2:
              periodicity = ' / ' + strings.product.cartItem.periodicity.fortnightly;
              break;
            case 3:
              periodicity = ' / ' + strings.product.cartItem.periodicity.quarterly;
              break;
            case 6:
              periodicity = ' / ' + strings.product.cartItem.periodicity.halfYearly;
              break;
            case 12:
              periodicity = ' / ' + strings.product.cartItem.periodicity.yearly;
              break;
            default:

          }
        }
        return `${Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(item["price"])}HT${periodicity}`
      }
    },

    tabs: {
      main: {
        title: strings.product.tab.main,
        type: 'form',
        form: form,

        apiGet: getFromId,
        apiEdit: editFromId,
        onUpdateItem: (data) => {
          data["item"]["product_family"] = data["item"]["product_sub_family"]["product_family"]
        },
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        role: ['ROLE_USER', 'ROLE_NYUKOM'],//['ROLE_MARKETING_PRODUCT_EDIT'],
        editable: ['ROLE_MARKETING_PRODUCT_EDIT']
      },
      productPrice: {
        title: strings.product.tab.productPrice,
        type: 'form',
        form: priceForm,

        apiGet: getPriceFromId,
        apiEdit: editPriceFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        role: ['ROLE_MARKETING_PRODUCT_PRICE_EDIT'],
        editable: ['ROLE_MARKETING_PRODUCT_PRICE_EDIT']
      },
      configuration: {
        title: strings.product.tab.configuration,
        type: 'form',
        form: configurationForm,

        apiGet: getConfigurationFromId,
        onUpdateItem: (data) => {},

        role: ['ROLE_MARKETING_PRODUCT_CONFIGURATION']
      },
      configurationEdit: {
        title: strings.product.tab.configuration,
        type: 'form',
        form: configurationEditForm,

        apiGet: getConfigurationEditFromId,
        apiEdit: editConfigurationFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'configuration'
        },

        role: ['ROLE_MARKETING_PRODUCT_CONFIGURATION_EDIT'],
        editable: ['ROLE_MARKETING_PRODUCT_CONFIGURATION_EDIT']
      },
      contract: {
        title: strings.product.tab.contract,
        type: 'form',
        form: contractForm,

        apiGet: getContractFromId,
        apiEdit: editContractFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'contract'
        },

        shouldDisplay: (item) => item && item["price_type"] === 1,
        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: ['ROLE_MARKETING_PRODUCT_EDIT']
      },
      datasheet: {
        title: strings.product.tab.datasheet,
        type: 'form',
        form: datasheetForm,

        apiGet: getDatasheetFromId,
        apiEdit: editDatasheetFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'datasheet'
        },

        role: ['ROLE_NYUKOM'],
        editable: ['ROLE_NYUKOM']
      },
      phonePackage: {
        title: strings.product.tab.phonePackage,
        type: 'form',
        form: phonePackageForm,

        apiGet: getPhonePackageFromId,
        apiEdit: editPhonePackageFromId,
        onUpdateItem: (data) => {},

        postEdit: {
          type: 'tab',
          tabId: 'phonePackage'
        },

        shouldDisplay: (item) => item && item["have_phone_package"] === true,
        role: ['ROLE_CDR_PHONE_PACKAGE'],
        editable: ['ROLE_CDR_PHONE_PACKAGE']
      },
      specialConditionOfSale: {
        title: strings.product.tab.specialConditionOfSale,
        icon: 'pen',
        type: 'form',
        form: specialConditionOfSaleForm,

        apiGet: getSpecialConditionOfSaleFromId,
        apiEdit: editSpecialConditionOfSaleFromId,
        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},

        postEdit: {
          type: 'tab',
          tabId: 'specialConditionOfSale'
        },

        role: ['ROLE_NYUKOM'],
        editable: ['ROLE_NYUKOM']
      },
    }
  },
  new: {
    pageTitle: strings.product.new.title,

    api: newItem,

    type: 'process',
    processId: 'newProduct',

    steps: [
      {
        id: "_general_data",
        label: strings.product.new.steps.generalData,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['name', 'code', 'description', 'product_family', 'product_sub_family', 'constructor'].includes(item.fieldKey))
        },
        onSubmitStep: () => {}
      },
      {
        id: "_configuration",
        label: strings.product.new.steps.configuration,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['active_order', 'active_quote', 'active_ticket', 'order_breaking', 'service_tracking', 'service_breaking', 'rentable'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
      {
        id: "_price",
        label: strings.product.new.steps.price,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['price', 'price_type', 'min_price', 'buying_prices'].includes(item.fieldKey)),
        },
        onSubmitStep: (values) => {}
      },
      {
        id: "_contract",
        label: strings.product.new.steps.contract,
        type: "form",
        valueOrigin: "transparent",
        disabled: (values) => !(values && values["price_type"] === "1"),
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['contract'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
      // {
      //   id: "_dependency",
      //   label: strings.product.new.steps.dependency,
      //   type: "form",
      //   valueOrigin: "transparent",
      //   form: {
      //     ...newForm,
      //     fields: newForm.fields.filter((item) => ['product_masters', 'product_slaves'].includes(item.fieldKey)),
      //   },
      //   onSubmitStep: () => {}
      // },
      {
        id: "_special_condition_of_sale",
        label: strings.product.new.steps.specialConditionOfSale,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['special_condition_of_sale', 'special_condition_of_sale_argument_collection'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      }
    ],

    onSubmit: (item) => {
      //set contract to null if not priceType === 1
      if(item && item["price_type"] !== "1") {
        item.contract = undefined;
      }
    },
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
