const phoneCDR = {
  service: {
    label: "Service",
  },
  communicationType: {
    label: "Type",
    options: {
      voice: "Voix",
      data: "Data",
      sms: "SMS",
      mms: "MMS",
      other: "Autre"
    }
  },
  roaming: {
    label: "Roaming"
  },
  roamingLikeAtHome: {
    label: "Roaming Like At Home"
  },
  redirection: {
    label: "Redirection"
  },
  inout: {
    label: "Communication",
    options: {
      in: "Entrante",
      out: "Sortante"
    }
  },
  sourceNumber: {
    label: "Numéro appelant"
  },
  destinationNumber: {
    label: "Numéro appelé"
  },
  beginAtMin: {
    label: "Date (min)"
  },
  beginAtMax: {
    label: "Date (max)"
  },
  quantityMin: {
    label: "Quantité (min)"
  },
  quantityMax: {
    label: "Quantité (max)"
  },
  priceFinalMin: {
    label: "Prix (min)"
  },
  priceFinalMax: {
    label: "Prix (max)"
  },
};

export default phoneCDR;
