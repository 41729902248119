import React from 'react';

import { Form, InputGroup } from 'react-bootstrap';
import '../../../css/form.css';

import Loading from '../../Layout/Loading';

import AsyncCounter from '../../../Utils/Counter/AsyncCounter';

import FunctionAssert from '../../../Form/Assert/FunctionAssert';
import RegexAssert from '../../../Form/Assert/RegexAssert';

/**
 * BasicFormField
 *
 * This class handles HTML form field.
 *
 * The form field is a base for other form field, do not use it directly.
 */
class BasicFormField extends React.Component {

  /**
   * initialize the component variable :
   *  - valid state
   *  - validator counter
   *  - validator call
   *
   * @props react argument for component
   */
  constructor(props) {
    super(props);

    //initialize valid temp variable
    this.valid = {};
    //initialize the validator counter
    this.validatorCounter = new AsyncCounter();

    //initialize valid state variable with null value
    let valid = {};
    //initialize validator call callbacks
    this.validatorCall = {};
    this.getValidators().forEach((item) => {
      valid[item.id] = null
      if(item.type === 'call') {
        this.validatorCall[item.id] = this.props.modalHandler.addVerificationWithCallback(item.call, (data) => this.validatorCallback(item, data), (msg) => this.validatorCallbackFailure(item, msg));
      }
    });

    this.state = {
      valid: valid,
      validating: false,
    }
  }

  /**
   * @inheritdoc
   */
  componentDidMount() {
    //make a first validation if the field is not disabled
    if(this.props.preValidate === true) this.startValidation();
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    //unsubscribe to the counter to avoid callbacks on an empty component
    this.validatorCounter.unsubscribeAll();
  }

  /**
   * @return the value of this field
   */
  getValue() {
    if(this.props.value === undefined || this.props.value === null) {
      return '';
    }
    return this.props.value;
  }

  /**
   * @return the final value of the field (used in submission)
   */
  getFinalValue() {
    return this.props.value;
  }

  /**
   * @return the list of validators configuration called automatically by the
   *  field
   */
  getSpecificValidators() {
    return [];
  }

  /**
   * @return the complete list of validators, both from the field configuration
   *  and the inner field configuration
   */
  getValidators(target = null) {
    let validators = null;
    if(this.props.ignoreValidation) {
      validators = [];
      // validators = [...this.getSpecificValidators()];
    }
    else {
      validators = [...this.props.validators, ...this.getSpecificValidators()];
    }
    if(target !== null) {
      return validators.filter((item) => item.target === target);
    }
    else {
      return validators;
    }
  }

  /**
   * @return true if the field need to be validatet (there is at least one
   *  validators)
   */
  needValidation() {
    return this.getValidators().length > 0;
  }

  /**
   * @return an array of validated validators
   */
  getValidValidators = (target = null) => {
    return this.getValidators(target).filter(item => this.state.valid[item.id] === true && item.validFeedback);
  }

  /**
   * @return the list of assertion that has been validated ready to be displayed
   */
  displayValidValidators = (target = null) => {
    return (
      <>
        {this.getValidValidators(target).map((item, i) => {
          return (
            <Form.Control.Feedback key={`${item.id}-valid`} type="valid">
              {item.validFeedback}
            </Form.Control.Feedback>
          )
        })}
      </>
    )
  }

  /**
   * @return a boolean indicating if the field is validated or not
   */
  isValid = (target = null) => {
    if(this.getValidators(target).length === 0) return false;
    return this.getValidators(target).map((item) => this.state.valid[item.id]).every((value) => value === true)?true:false;
  }

  /**
   * @return an of invalidated assertion
   */
  getInvalidValidators = (target = null) => {
    return this.getValidators(target).filter(item => this.state.valid[item.id] === false && item.invalidFeedback);
  }

  /**
   * @return the list of assertion that has been invalidated ready to be
   *  displayed
   */
  displayInvalidValidators = (target = null) => {
    return (
      <>
        {this.getInvalidValidators(target).map((item, i) => {
          return (
            <Form.Control.Feedback key={`${item.id}-invalid`} type="invalid">
              {item.invalidFeedback}
            </Form.Control.Feedback>
          )
        })}
      </>
    )
  }

  /**
   * @return a boolean indicating if the field is invalidated or not
   */
  isInvalid = (target = null) => {
    if(this.getValidators(target).length === 0) return false;
    return this.getValidators(target).map((item) => this.state.valid[item.id]).some((value) => value === false)?true:false;
  }

  /**
   * Send new value to parent element
   * realize a validation of the field
   */
  onChange(value, ignoreValidation = false) {
    this.props.onChange(value);
    if(!ignoreValidation) {
      this.startValidation(value);
    }
  }

  resetValue() {
    this.onChange(undefined, true);
  }

  /**
   * Get the list of possible assertion fot this type of field (that can be used
   * in the configuration file)
   *
   * @return an object with all the assertion
   */
  getSpecificAsserts() {
    return {};
  }

  /**
   * Get the list of assertion possible for the field (that can be used in the
   * configuration file)
   *
   * @return an object with all the assertion
   */
  getAsserts() {
    return Object.assign(this.getSpecificAsserts(), {
      function: FunctionAssert,
      regex: RegexAssert,
    });
  }

  /**
   * Get the extraData to send to assert for the field (for example when the
   * field has multiple value)
   *
   * @return the list of extraData
   */
  getAssertExtraData() {
    return {};
  }

  /**
   * start the validation
   * launch all assert used in the configuration given to validate the value of
   * the field
   *
   * @param value the value to test OR
   *              the current value of the field if null is used
   */
  startValidation = (value = null) => {
    //if the value if null, we take the current value of the field
    if(value === null) value = this.getFinalValue();

    //reset the value of valid
    this.valid = {};

    this.setState({
      valid: {},
      validating: (this.getValidators().length + this.getExtraValidation().length) !== 0
    }, () => {
      //initialize the counter to decount the assertion
      this.validatorCounter.reset();
      this.validatorCounter.setValue(this.getValidators().length + this.getExtraValidation().length);
      //in case of submission, we need to send to use the parent callback to
      //report that this field has finished is validation
      if(this.props.submitting) this.validatorCounter.subscribe(0, () => this.validationCallback());
      this.validatorCounter.subscribe(0, () => this.validationCallbackAnimation());

      this.startExtraValidation();

      //loop through the validator assertion to check the value
      this.getValidators().forEach((item) => {
        //initialize the new valid state
        let valid = this.valid;
        //initialize a boolean to check if we need to decrement the counter
        let decrement = true;

        //check if the assertion type in configurated in the field
        if(Object.keys(this.getAsserts()).includes(item.type)){
          //create the assertion
          let assert = new (this.getAsserts()[item.type])();

          //configure the assertion
          assert.configure(item.conf);
          assert.setValue(value);
          assert.setValues(this.props.getValues);
          assert.setExtraValues(this.props.getExtraValues);
          assert.setExtraData(this.getAssertExtraData());
          assert.setSubmitted(this.props.submitted);
          //make the assertion
          valid[item.id] = assert.assert();
          //if the field has been submited, null cannot be accepted as a value
          //for the assertion, in this case we use the defaultValue
          if(this.props.submitted && valid[item.id] === null) valid[item.id] = item.defaultValue;
        }

        else if(item.type === 'call') {
          // assert.call();
          this.validatorCall[item.id](value, this.props.getValues);
          decrement = false;
        }

        //Set the new valid state
        this.setState({
          valid: Object.assign({}, valid)
        }, () => {
          //decrease the validator counter by one
          if(decrement) this.validatorCounter.decrement();
        })
      });
    })
  }

  /**
   * A callback for a validator call to report the result of the assertion and
   * decrease the counter
   */
  validatorCallback = (item, data) => {
    let valid = Object.assign({}, this.state.valid);
    valid[item.id] = data.valid;
    this.setState({
      valid: valid
    }, () => {
      this.validatorCounter.decrement();
    })
  }

  validatorCallbackFailure = (item, msg) => {
    let valid = Object.assign({}, this.state.valid);
    valid[item.id] = false;
    this.setState({
      valid: valid
    }, () => {
      this.validatorCounter.decrement();
    })
  }

  /**
   * This function allows to subscribe the sub field (if there is) to the
   * validation process of the field
   *
   * @return a list of extra validation to be done alongside the validators
   */
  getExtraValidation() {
    return [];
  }

  /**
   * starts the extra validation of any field referenced in getExtraValidation
   * during the validation process
   */
  startExtraValidation() {
    this.getExtraValidation().forEach((item) => {
      //check if the startValidation function do exist in the sub field
      if(item.startValidation === undefined) {
        console.warn(`Extra validation error in field ${this.props.fieldKey}`);
        this.validatorCounter.decrement();
      }
      else {
        //start the sub field validation
        item.startValidation();
      }
    });
  }

  /**
   * This function should be used by the sub field to report that their
   *  validation is done
   */
  subFieldValidationCallback = () => {
    this.validatorCounter.decrement();
  }

  /**
   * Once every validator and extra validation is finished, stop the loading
   * animation on the field
   */
  validationCallbackAnimation() {
    this.setState({
      validating: false
    })
  }

  /**
   * Once every validators and extra validation is finished, report to the
   * parent element, then the parent can use the "isValid" function to get the
   * the state of validation of the field
   */
  validationCallback() {
    this.props.validationCallback();
  }

  displayValidating() {
    if(this.state.validating) {
      return (
        <InputGroup.Append>
          <Loading container="parent" containerClassName="loading-form-row" size="small"/>
        </InputGroup.Append>
      )
    }
  }

  getMiddlePartAdditionalClassname() {
    return "middle-part-form-row";
  }

  shouldDisplayMiddlePart() {
    if(this.props.middlePart) {
      return true;
    }
    return false;
  }

  displayMiddlePart() {
    if(this.props.middlePart) {
      switch (this.props.middlePart.type) {
        case "form":
          return (
            <Form.Control className={this.getMiddlePartAdditionalClassname()} type="text" value={this.props.middlePart.value} onChange={(event) => this.props.middlePart.onChange(event.target.value)} placeholder={this.props.middlePart.placeholder}/>
          )
        case "text":
          return this.props.middlePart.text
        default:
          console.warn("middle part type unknown : "+this.props.middlePart.type);
      }
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    console.warn("You have used a component (BasicFormField) that shouldn't be used as is.");
    return null;
  }
}

export default BasicFormField;
