import strings from '../../Localization/Localization';

let iban = {
  fieldKey: "iban",
  type: "text",
  label: strings.form.bankInformation.iban.label,
  validators: []
}

let bic = {
  fieldKey: "bic",
  type: "text",
  label: strings.form.bankInformation.bic.label,
  validators: []
}

let useForSubscription = {
  fieldKey: "use_for_subscription",
  type: "switch",
  label: strings.form.bankInformation.useForSubscription.label,
  validators: []
}

let useForSell = {
  fieldKey: "use_for_sell",
  type: "switch",
  label: strings.form.bankInformation.useForSell.label,
  validators: []
}

export const form = {
  formKey: "bankInformation",
  fields: [
    iban,
    bic,
    useForSubscription,
    useForSell
  ]
}
