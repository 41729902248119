import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem,
  request
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
* Get a list of places based on a pagination system
*
* @param page the page requested
* @param itemPerPage the number of item in the page requested
*/
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('places', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
* Get a single place based on his ID
*
* @param id the of the place requested
*/
export function getFromId(id) {
  return baseGetFromId('places', id);
}

/**
* Get a single place based on his ID
*
* @param id the of the place requested
*/
export function getIdentificationFromId(id) {
  return baseGetFromId('places', id, 'identification');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
* Edit a single place based on his ID
*
* @param id the of the place requested
* @param data the new data of the place
*/
export function editFromId(id, data) {
  return baseEditFromId('places', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
* Create a new place
*
* @param data the new data of the place
*/
export function newItem(data) {
  return baseNewItem('places', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all places to serve in a dropdown form choice
 */
export function fetchPlaces(search) {
  return baseIndex('places', 1, 0, search, 'ASC', 'form_selection');
}

/******************************************************************************
 * OTHER FUNCTION                                                             *
 ******************************************************************************/

/**
* Mark a notification as seen
*
* @param id the of the notification
*/
export function deactivatePlace(id) {
  return request('/places/deactivate/'+id, "POST");
}

/**
* Mark a notification as seen
*
* @param id the of the notification
*/
export function reactivatePlace(id) {
  return request('/places/reactivate/'+id, "POST");
}
