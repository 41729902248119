import React from 'react';

import strings from '../../../Localization/Localization';

import BasicNotification from './BasicNotification';

class UserNew extends BasicNotification {

  displayMessage() {
    return (
      <>{strings.formatString(strings.common.notification.user_new)}</>
    )
  }
}

export default UserNew;
