export const MODIFY_CART = 'modify_cart';
export const RESET_CART = 'reset_cart';
export const ADD_CART_SECTION = 'add_cart_section';
export const REMOVE_CART_SECTION = 'remove_cart_section';
export const MODIFY_CART_SECTION_ORDER = 'modify_cart_section_order';
export const MODIFY_CART_SECTION_TITLE = 'modify_cart_section_title';
export const ADD_CART_ITEM = 'add_cart_item';
export const ADD_CART_ITEM_SLAVE = 'add_cart_item_slave';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const MODIFY_CART_ITEMS_ORDER = 'modify_cart_items_order';
export const MODIFY_CART_ITEM_RENTED = 'modify_cart_item_rented';
export const MODIFY_CART_ITEM_QUANTITY = 'modify_cart_item_quantity';
export const MODIFY_CART_ITEM_DISCOUNT = 'modify_cart_item_discount';
export const MODIFY_CART_ITEM_DISCOUNT_UNIT = 'modify_cart_item_discount_unit';

export const ADD_AMENDMENT = 'add_amendment';
export const REMOVE_AMENDMENT = 'remove_amendment';

export const COPY_QUOTE_INFORMATION = 'copy_quote_client';
export const COPY_QUOTE_ITEMS = 'copy_quote_items';

export function modifyCartAction(key, value){
  return {
    type: MODIFY_CART,
    key: key,
    value: value
  }
}

export function resetCartAction(){
  return {
    type: RESET_CART
  }
}

export function addCartSectionAction(){
  return {
    type: ADD_CART_SECTION
  }
}

export function removeCartSectionAction(sectionId) {
  return {
    type: REMOVE_CART_SECTION,
    sectionId: sectionId
  }
}

export function modifyCartSectionOrderAction(dragOrder, hoverOrder){
  return {
    type: MODIFY_CART_SECTION_ORDER,
    dragOrder: dragOrder,
    hoverOrder: hoverOrder
  }
}

export function modifySectionTitle(sectionId, title){
  return {
    type: MODIFY_CART_SECTION_TITLE,
    sectionId: sectionId,
    title: title
  }
}

export function addCartItemAction(productId, quantity) {
  return {
    type: ADD_CART_ITEM,
    productId: productId,
    quantity: quantity
  }
}

export function addCartItemSlaveAction(masterId, relationId, productId) {
  return {
    type: ADD_CART_ITEM_SLAVE,
    masterId: masterId,
    relationId: relationId,
    productId: productId
  }
}

export function removeCartItemAction(itemId) {
  return {
    type: REMOVE_CART_ITEM,
    itemId: itemId
  }
}

export function modifyCartItemsOrderAction(dragOrder, dragSectionId, hoverOrder, hoverSectionId){
  return {
    type: MODIFY_CART_ITEMS_ORDER,
    dragOrder: dragOrder,
    dragSectionId: dragSectionId,
    hoverOrder: hoverOrder,
    hoverSectionId: hoverSectionId
  }
}

export function modifyCartItemRentedAction(itemId, rented) {
  return {
    type: MODIFY_CART_ITEM_RENTED,
    itemId: itemId,
    rented: rented
  }
}

export function modifyCartItemQuantityAction(itemId, quantity) {
  return {
    type: MODIFY_CART_ITEM_QUANTITY,
    itemId: itemId,
    quantity: quantity
  }
}

export function modifyCartItemDiscountAction(itemId, discount) {
  return {
    type: MODIFY_CART_ITEM_DISCOUNT,
    itemId: itemId,
    discount: discount
  }
}

export function modifyCartItemDiscountUnitAction(itemId, discountUnit) {
  return {
    type: MODIFY_CART_ITEM_DISCOUNT_UNIT,
    itemId: itemId,
    discountUnit: discountUnit
  }
}

export function addAmendmentAction(productId, quantity, amendment) {
  return {
    type: ADD_AMENDMENT,
    productId: productId,
    quantity: quantity,
    amendment: amendment
  }
}

export function removeAmendmentAction(itemId) {
  return {
    type: REMOVE_AMENDMENT,
    itemId: itemId
  }
}

export function copyQuoteInformationAction(partner, partner_account, place, client_referrer, title, description) {
  return {
    type: COPY_QUOTE_INFORMATION,
    client: partner,
    clientAccount: partner_account,
    placeDelivery: place,
    clientUser: client_referrer,
    title: title,
    description: description
  }
}

export function copyQuoteItemsAction(sections, cartItems) {
  return {
    type: COPY_QUOTE_ITEMS,
    sections: sections,
    cartItems: cartItems
  }
}
