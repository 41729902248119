import { dashboard as technical_8_1 } from './DefaultDashboard/technical_8_1';
// import { dashboard as technical_4_1 } from './DefaultDashboard/technical_4_1';
// import { dashboard as technical_2_1 } from './DefaultDashboard/technical_2_1';

import { dashboard as commercial_8_1 } from './DefaultDashboard/commercial_8_1';
// import { dashboard as commercial_4_1 } from './DefaultDashboard/commercial_4_1';
// import { dashboard as commercial_2_1 } from './DefaultDashboard/commercial_2_1';

import { dashboard as administrative_8_1 } from './DefaultDashboard/administrative_8_1';
// import { dashboard as administrative_4_1 } from './DefaultDashboard/administrative_4_1';
// import { dashboard as administrative_2_1 } from './DefaultDashboard/administrative_2_1';

export const defaultDashboards = [
  technical_8_1,
  // technical_4_1,
  // technical_2_1,

  commercial_8_1,
  // commercial_4_1,
  // commercial_2_1,

  administrative_8_1,
  // administrative_4_1,
  // administrative_2_1,
]
