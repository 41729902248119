import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import {
  users as usersRoutes,
  commercialBonusThreshold as thresholdsRoutes
} from '../Route/routes';

export let bonusType = {
  fieldKey: "bonus_type",
  label: strings.form.commercialBonus.bonusType.label,
  type: "dropdown",
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.commercialBonus.bonusType.options.quotePunctual
    },
    {
      value: 1,
      label: strings.form.commercialBonus.bonusType.options.quoteAnnual
    },
    {
      value: 2,
      label: strings.form.commercialBonus.bonusType.options.serviceRecurrentNoEngagement
    }
  ],
}

let totalTfBase = {
  fieldKey: "total_tf_base",
  type: "price",
  label: strings.form.commercialBonus.totalTfBase.label,
  validators: []
}

let flatBonusValue = {
  fieldKey: "flat_bonus_value",
  type: "price",
  label: strings.form.commercialBonus.flatBonusValue.label,
  validators: []
}

let user = {
  fieldKey: "user",
  label: strings.form.commercialBonus.user.label,
  type: "dropdownEntity",
  disabled: () => true,

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && !Array.isArray(value) && credentials.roles.includes('ROLE_NYUKOM') && (credentials.roles.includes('ROLE_USER_ADMIN') || credentials.roles.includes('ROLE_USER_INDEX'))) {
        return usersRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let threshold = {
  fieldKey: "threshold",
  label: strings.form.commercialBonus.threshold.label,
  type: "dropdownEntity",
  disabled: () => true,

  disabledLink: (value, getValues, getExtraValues) => {
    if(value && !Array.isArray(value)) {
      return thresholdsRoutes.routes.show.createPath(value["id"]);
    }
    return false;
  },

  validators: [],

  showEmpty: true,
  loader: null,

  adapter: {
    requirement: (data) => data["id"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["id"],
  },
}

export const form = {
  formKey: "commercialBonus",
  fields: [
    bonusType,
    totalTfBase,
    flatBonusValue,
    user,
    threshold
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
