import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

 /******************************************************************************
  * INDEX FUNCTION                                                             *
  ******************************************************************************/

/**
 * Get a list of dependencies based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('dependencies', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single dependency based on his ID
 *
 * @param id the of the dependency requested
 */
export function getFromId(id) {
  return baseGetFromId('dependencies', id);
}

/**
 * Get a single dependency based on his ID
 *
 * @param id the of the dependency requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('dependencies', id, 'identification');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single dependency based on his ID
 *
 * @param id the of the dependency requested
 * @param data the new data of the dependency
 */
export function editFromId(id, data) {
  return baseEditFromId('dependencies', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new dependency
 *
 * @param data the new data of the dependency
 */
export function newItem(data) {
  return baseNewItem('dependencies', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all dependency to serve in a dropdown form choice
 */
export function fetchDependencies() {
  return baseIndex('dependencies', 1, 0);
}
