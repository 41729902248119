const service = {
  serialNumber: {
    label: "Numéro de série",
  },
  orderNumber: {
    label: "Numéro de CMD"
  },
  terminationTicket: {
    label: "Ticket de résiliation"
  },
  quoteNumber: {
    label: "Numéro de devis"
  },
  productName: {
    label: "Nom du produit"
  },
  price: {
    label: "Prix"
  },
  state: {
    label: "Etat",
    options: {
      active: "Actif",
      inactive: "Inactif",
      termination: "En cours de résiliation",
      terminated: "Résilié",
    }
  },
  partner: {
    label: "Client"
  },
  client: {
    label: "Compte"
  },
  place: {
    label: "Site"
  },
  productFamily: {
    label: "Famille de produit"
  },
  productSubFamily: {
    label: "Sous-famille de produit"
  },
  deliveryDateTimeframe: {
    label: "Livraison"
  },
  deliveryDateMin: {
    label: "Date de livraison (min)"
  },
  deliveryDateMax: {
    label: "Date de livraison (max)"
  },
  updateDateTimeframe: {
    label: "MàJ"
  },
  updateDateMin: {
    label: "Date de MàJ (min)"
  },
  updateDateMax: {
    label: "Date de MàJ (max)"
  },
  contracts: {
    label: "Contract"
  },
  amendments: {
    label: "Avenants"
  },
  emptyApiKey: {
    label: "Clé manquante"
  },
  specificPhonePriceList: {
    label: ""
  },
  serviceSlave: {
    label: "Option"
  },
  centreonHost: {
    label: "Host Centreon"
  },
  centreonService: {
    label: "Service Centreon"
  },
  centreonConfigurations: {
    label: "Service Centreon #key#",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  }
};

export default service;
