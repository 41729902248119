import strings from '../../../Localization/Localization';

import { store } from '../../../Store/configureStore';

import { fetchPartners } from '../../../API/Partners';

let username = {
  fieldKey: "username",
  type: "text",
  label: strings.form.user.username.label,
  showOnDisabled: () => false,
  validators: []
}

let firstname = {
  fieldKey: "firstname",
  type: "text",
  label: strings.form.user.firstname.label,
  showOnDisabled: () => false,
  validators: []
}

let lastname = {
  fieldKey: "lastname",
  type: "textUppercase",
  label: strings.form.user.lastname.label,
  showOnDisabled: () => false,
  validators: []
}

// let mail = {
//   fieldKey: "mail",
//   type: "mail",
//   label: strings.form.user.mail.label,
//   showOnDisabled: () => false,
//   validators: []
// }

// let phoneNumber = {
//   fieldKey: "phoneNumber",
//   type: "text",
//   label: strings.form.user.phoneNumbers.label,
//
//   validators: [],
// }

let partner = {
  fieldKey: "partner",
  type: "dropdownEntity",
  label: strings.form.user.partner.label,
  disabled: () => false,
  showOnDisabled: () => false,

  validators: [],

  loader: {
    id: "partner",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["legal_form"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["legal_form"] + " " + data["name"],
  },
}

let blocked = {
  fieldKey: "blocked",
  type: "switch",
  label: strings.form.user.blocked.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  showOnDisabled: () => false,
  validators: []
}

let active = {
  fieldKey: "active",
  type: "triswitch",
  label: strings.form.user.active.label,

  validators: []
}

export const form = {
  formKey: "userSearch",
  fields: [
    firstname,
    lastname,
    // mail,
    // phoneNumber,
    username,
    partner,
    blocked,
    active
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
