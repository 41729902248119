import React from 'react';

import { connect } from 'react-redux';

import { withRouter } from "react-router-dom";

import { withModalHandler } from '../../../HOC/ModalHandler';

import { Modal, Button, Alert, OverlayTrigger, Popover, Col } from 'react-bootstrap';

import Loading from '../../Layout/Loading';

import { Redirect } from "react-router-dom";

import Form from '../../Form/MyForm';

import HoverText from '../../Basic/HoverText';

import {
  addLineToFlashbag,
  FLASHBAG_TYPE_WARNING
} from '../../../Store/Action/flashbag';

/**
 * Menu
 */
class Menu extends React.Component {

  constructor(props) {
    super(props);

    this.requests = null;

    this.state = {
      loading: false,

      modal: false,
      modalData: null,
      modalError: null,
      item: {},

      redirection: null,

      mobileMenuOpen: false
    }
  }

  componentDidUpdate() {
    if(this.props.item) {
      let buttons = this.computeButtons();
      this.requests = {};
      this.actions = {};
      buttons.forEach((button, i) => {
        let buttonId = button.id;
        if(!buttonId) {
          buttonId = i;
        }
        switch (button.type) {
          case "function":
            if(!this.actions[buttonId]) {
              this.actions[buttonId] = button.onClick;
              if(button.addVerification) {
                this.actions[buttonId] = this.props.modalHandler.addVerification(this.actions[buttonId]);
              }
            }
            break;
          case "request":
            if(!this.requests[buttonId]) {
              this.requests[buttonId] = this.props.modalHandler.addVerificationWithCallback(button.request, (data) => this.requestCallback(button, data), (msg) => this.requestCallbackFailure(button, msg));
              if(button.addVerification) {
                let temp = this.requests[buttonId];
                let callback = (...args) => {
                  this.setState({
                    loading: true
                  }, () => {
                    temp.apply(null, args);
                  })
                }
                this.requests[buttonId] = this.props.modalHandler.addVerification(callback);
              }
            }
            break;
          case "modal":
            if(button.request) {
              if(!this.requests[buttonId]) {
                this.requests[buttonId] = this.props.modalHandler.addVerificationWithCallback(button.request, (data) => this.requestCallback(button, data), (msg) => this.requestCallbackFailure(button, msg));
                if(button.addVerification) {
                  this.requests[buttonId] = this.props.modalHandler.addVerification(this.requests[buttonId]);
                }
              }
            }
            break;
          case "redirection":
            if(!this.actions[buttonId]) {
              this.actions[buttonId] = () => this.setState({ redirection: button.path });
              if(button.addVerification) {
                this.actions[buttonId] = this.props.modalHandler.addVerification(this.actions[buttonId]);
              }
            }
            break;
          default:
        }
      });
    }
  }

  computeButtons() {
    let buttons = [];
    if(Array.isArray(this.props.conf)) {
      buttons = this.props.conf;
    }
    else if(typeof this.props.conf === 'function') {
      buttons = this.props.conf(this.props.item);
    }
    buttons = buttons.filter((button, i) => {
      return button.auth === false
      || (typeof button.auth === 'string' && this.props.credentials.roles.includes(button.auth))
      || (Array.isArray(button.auth) && button.auth.some((auth) => this.props.credentials.roles.includes(auth)))
      || (typeof button.auth === 'function' && button.auth(button, this.props.item, this.props.credentials));
    });
    return buttons;
  }

  onClickButton = (button, i) => {
    let buttonId = button.id;
    if(!buttonId) {
      buttonId = i;
    }
    switch (button.type) {
      case "function":
        this.actions[buttonId](this.props.item);
        break;
      case "redirection":
        this.actions[buttonId](this.props.item);
        break;
      case "request":
        if(button.addVerification) {
          this.requests[buttonId](this.props.item.id, this.props.item);
        }
        else {
          this.setState({
            loading: true
          }, () => {
            this.requests[buttonId](this.props.item.id, this.props.item);
          })
        }
        break;
      case "modal":
        let context = {
          reload: () => this.onClickButton(button, i),
          credentials: this.props.credentials
        }
        this.setState({
          modal: true,
          modalButtonId: button.id,
          modalData: button.modalData(context),
          modalError: "",
          item: {
            id: this.props.item.id
          }
        })
        break;
      default:

    }
  }

  requestCallback = (button, data) => {
    if(button.postRequestCallback) {
      let payload = { data: data };
      if(Array.isArray(button.postRequestCallback)) {
        button.postRequestCallback.forEach((postRequestCallback) => this.processRequestCallback(postRequestCallback, payload));
      }
      else {
        this.processRequestCallback(button.postRequestCallback, payload)
      }
    }
    this.setState({
      loading: false
    })
  }

  requestCallbackFailure = (button, msg) => {
    if(button.postRequestCallbackFailure) {
      let payload = { msg: msg };
      if(Array.isArray(button.postRequestCallbackFailure)) {
        button.postRequestCallbackFailure.forEach((postRequestCallback) => this.processRequestCallback(postRequestCallback, payload));
      }
      else {
        this.processRequestCallback(button.postRequestCallbackFailure, payload)
      }
    }
    this.setState({
      loading: false
    })
  }

  processRequestCallback = (postRequestCallback, payload) => {
    switch (postRequestCallback.type) {
      case "flashbag":
        this.props.dispatch(addLineToFlashbag(postRequestCallback.message, postRequestCallback.flashbagType));
        break;
      case "updateItem":
        this.updateItem(postRequestCallback.key, postRequestCallback.value(payload.data));
        break;
      case "callback":
        postRequestCallback.callback(payload);
        break;
      default:

    }
  }

  updateItem = (fieldKey, value) => {
    this.props.updateItem(fieldKey, value);
  }

  onChangeItem = (fieldKey, value) => {
    let item = Object.assign({}, this.state.item);
    item[fieldKey] = value;
    this.setState({
      item: item,
    });
  }

  closeModal = () => {
    this.setState({
      modal: false,
      modalButtonId: null,
      modalData: null,
      modalError: "",
      item: {}
    })
  }

  closeModalWithAction = () => {
    let result = this.state.modalData.successButtonOnClick(this.state.item);
    if(result.redirection) {
      this.props.history.push(result.redirection);
    }
    if(result.request) {
      this.setState({
        loading: true
      }, () => {
        this.closeModal();
        this.requests[this.state.modalButtonId](...result.arguments);
      })
    }
    if(result.error) {
      this.setState({
        modalError: result.error,
      })
    }
  }

  displayModal() {
    let title = '';
    let body = '';
    let footer = null;
    if(this.state.modal) {
      title = this.state.modalData.title
      body = (
        <>
          {this.state.modalData.text?this.state.modalData.text():null}
          {this.state.modalData.body?
            this.state.modalData.body
            :null
          }
          {this.state.modalData.form?
            <div className="d-flex flex-column align-items-center">
              <Form
                formKey={this.state.modalData.form.formKey}
                fields={this.state.modalData.form.fields}
                extraValues={this.state.modalData.form.extraValues}
                disabled={false}
                values={this.state.item}
                onChangeField={this.onChangeItem}
                displaySubmitButton={false}

                formSizeMd={12}/>
            </div>
            :null
          }
          {this.state.modalError?
            <Alert variant={FLASHBAG_TYPE_WARNING}>
              {this.state.modalError}
            </Alert>
            :null
          }
        </>
      )
      if(this.state.modalData.form) {
        footer =
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="my-validated" className="modal-action-button" onClick={this.closeModalWithAction}>
              {this.state.modalData.successButton}
            </Button>
          </Modal.Footer>;
      }
    }
    return (
      <Modal show={this.state.modal} centered={true} onHide={this.closeModal}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        {footer}
      </Modal>
    )
  }

  displayRedirection() {
    if(this.state.redirection) {
      return (
        <Redirect to={this.state.redirection}/>
      )
    }
  }

  displayButtonsDefault(buttons) {
    if(buttons.length === 1 || buttons.length === 2) {
      return (
        <div style={{height:"100%"}} className="d-none d-md-flex flex-column justify-content-center">
          {buttons
          .map((button, i) => {
            switch (button.type) {
              case "function":
              case "request":
              case "modal":
                return (
                  <Button key={i} variant={button.variant?button.variant:"my-information"} className="entity-tabs-menu-button-action" onClick={() => this.onClickButton(button, i)}>{button.hoverLabel?<HoverText text={button.label} hoverText={button.hoverLabel}/>:button.label}</Button>
                )
              case "redirection":
                return (
                  <Button key={i} variant={button.variant?button.variant:"my-information"} className="entity-tabs-menu-button-action" onClick={() => this.onClickButton(button, i)}>{button.hoverLabel?<HoverText text={button.label} hoverText={button.hoverLabel}/>:button.label}</Button>
                )
              default:

            }
            return null;
          })}
        </div>
      );
    }
    else if(buttons.length > 2) {
      return (
        <div style={{height:"100%"}} className="d-none d-md-flex flex-column justify-content-center">
          {buttons
          .filter((button) => {
            return button.alwaysDisplay === true
          })
          .map((button, i) => {
            switch (button.type) {
              case "function":
              case "request":
              case "modal":
                return (
                  <Button key={i} variant={button.variant?button.variant:"my-information"} className="entity-tabs-menu-button-action" onClick={() => this.onClickButton(button, i)}>{button.hoverLabel?<HoverText text={button.label} hoverText={button.hoverLabel}/>:button.label}</Button>
                )
              case "redirection":
                return (
                  <Button key={i} variant={button.variant?button.variant:"my-information"} className="entity-tabs-menu-button-action" onClick={() => this.onClickButton(button, i)}>{button.hoverLabel?<HoverText text={button.label} hoverText={button.hoverLabel}/>:button.label}</Button>
                )
              default:

            }
            return null;
          })}
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={
              <Popover id={`popover`}>
                <Popover.Content className="entity-tabs-menu-popover">
                  <Col className="d-flex flex-column justify-content-center" xs='auto'>
                    {buttons
                    .filter((button) => {
                      return button.alwaysDisplay !== true
                    })
                    .map((button, i) => {
                      switch (button.type) {
                        case "function":
                        case "request":
                        case "modal":
                          return (
                            <Button key={i} variant={button.variant?button.variant:"my-information"} className="entity-tabs-menu-button-action" onClick={() => this.onClickButton(button, i)}>{button.hoverLabel?<HoverText text={button.label} hoverText={button.hoverLabel}/>:button.label}</Button>
                          )
                        case "redirection":
                          return (
                            <Button key={i} variant={button.variant?button.variant:"my-information"} className="entity-tabs-menu-button-action" onClick={() => this.onClickButton(button, i)}>{button.hoverLabel?<HoverText text={button.label} hoverText={button.hoverLabel}/>:button.label}</Button>
                          )
                        default:

                      }
                      return null;
                    })}
                  </Col>
                </Popover.Content>
              </Popover>
            }
          >
            <Button variant="my-information" className="entity-tabs-menu-button-action">Action(s)</Button>
          </OverlayTrigger>
        </div>
      )
    }
    return null;
  }

  displayButtonsMobile(buttons) {
    if(buttons.length > 0) {
      return (
        <div style={{height:"100%", paddingTop:"20px"}} className="d-flex d-md-none flex-column justify-content-center">
          {buttons
          .map((button, i) => {
            switch (button.type) {
              case "function":
              case "request":
              case "modal":
                return (
                  <Button key={i} variant={button.variant?button.variant:"my-information"} className="entity-tabs-menu-button-action" onClick={() => this.onClickButton(button, i)}>{button.label}</Button>
                )
              case "redirection":
                return (
                  <Button key={i} variant={button.variant?button.variant:"my-information"} className="entity-tabs-menu-button-action" onClick={() => this.onClickButton(button, i)}>{button.label}</Button>
                )
              default:

            }
            return null;
          })}
        </div>
      );
    }
    return null;
  }

  displayButtons() {
    let buttons = [];
    if(this.props.item) {
      buttons = this.computeButtons();
    }
    return (
      <>
        {this.displayButtonsDefault(buttons)}
        {this.displayButtonsMobile(buttons)}
      </>
    )
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayLoading()}
        {this.displayModal()}
        {this.displayRedirection()}
        {this.displayButtons()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(withModalHandler(withRouter(Menu)));
