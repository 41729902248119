const bankInformation = {
  iban: {
    label: "IBAN",
  },
  bic: {
    label: "Code BIC",
  },
  useForSubscription: {
    label: "Utlisé pour les abonnements",
  },
  useForSell: {
    label: "Utlisé pour les ventes",
  },
};

export default bankInformation;
