import React from 'react';

/**
 */
class Text extends React.Component {

  static defaultProps = {
    content: null
  }

  displayContent() {
    return this.props.content
  }

  render() {
    return (
      <div>
        {this.displayContent()}
      </div>
    );
  }
}

export default Text;
