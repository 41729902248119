import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getPriceFromId,
  // getCompletePriceFromId,
  editFromId,
  editCompletePriceFromId,
  newItem
} from '../../API/PhonePriceLists';
import {
  form,
  priceForm,
  // completePriceForm,
  newPricesForm
} from '../Form/phonePriceLists';
import { form as searchForm } from '../Form/Search/phonePriceLists';

import { phonePriceList as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.phonePriceList,

    id: "phonePriceList",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: ['ROLE_CDR_PRICE_LIST']
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => {
          return `${item["name"]}`
        }
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: (item) => []
  },
  show: {
    pageTitle: strings.common.homepage.phonePriceList,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.phonePriceList.tab.main,
        type: 'form',
        form: form,
        disabled: false,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_CDR_PRICE_LIST'],
        editable: ['ROLE_CDR_PRICE_LIST_EDIT']
      },
      price: {
        title: strings.phonePriceList.tab.price,
        type: 'form',
        form: priceForm,

        apiGet: getPriceFromId,

        role: ['ROLE_CDR_PRICE_LIST'],
        editable: false
      },
      // completePrice: {
      //   title: strings.phonePriceList.tab.completePrice,
      //   type: 'form',
      //   form: completePriceForm,
      //
      //   apiGet: getCompletePriceFromId,
      //   apiEdit: editCompletePriceFromId,
      //
      //   role: ['ROLE_CDR_PRICE_LIST'],
      //   editable: ['ROLE_CDR_PRICE_LIST']
      // },
      newPrice: {
        title: strings.phonePriceList.tab.newPrice,
        type: 'form',
        form: newPricesForm,

        apiGet: getFromId,
        apiEdit: editCompletePriceFromId,

        onUpdateItem: (data) => {},
        onSubmit: (user) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        role: ['ROLE_CDR_PRICE_LIST_PRICE_EDIT'],
        editable: ['ROLE_CDR_PRICE_LIST_PRICE_EDIT']
      },
    }
  },
  new: {
    pageTitle: strings.phonePriceList.new.title,

    api: newItem,
    empty: {},
    form: form,
    onSubmit: (phonePriceList) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
