import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  editFromId
} from '../../API/Parameters';
import { form } from '../Form/parameters';
import { form as searchForm } from '../Form/Search/parameters';

import { parameters as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.parameters,

    id: "parameter",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => item["name"]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.parameters,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.parameter.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (data) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        auth: ['ROLE_ADMIN'],
        editable: ['ROLE_AMDIN']
      },
    }
  }
}
