import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of time zones based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('ticket_time_zones', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single time zone based on his ID
 *
 * @param id the of the time zone requested
 */
export function getFromId(id) {
  return baseGetFromId('ticket_time_zones', id);
}

/**
 * Get a single time zone based on his ID
 *
 * @param id the of the time zone requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('ticket_time_zones', id, 'identification');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single time zone based on his ID
 *
 * @param id the of the time zone requested
 * @param data the new data of the time zone
 */
export function editFromId(id, data) {
  return baseEditFromId('ticket_time_zones', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new time zone
 *
 * @param data the new data of the time zone
 */
export function newItem(data) {
  return baseNewItem('ticket_time_zones', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all family to serve in a dropdown form choice
 */
export function fetchTimeZones() {
  return baseIndex('ticket_time_zones', 1, 0);
}
