const centreonPing = {
  yAxis: {
    _0: {
      title: "Temps",
      unit: "ms"
    },
    _1: {
      title: "%",
      unit: "%"
    },
  },
  series: {
    roundTripAverage: {
      name: "Moyenne",
    },
    roundTripMax: {
      name: "Maximum",
    },
    roundTripMin: {
      name: "Minimum",
    },
    packetLoss: {
      name: "Perte de paquet",
    },
  }
};

export default centreonPing;
