import strings from '../../Localization/Localization';

import {
  getProfil,
  getProfilIndex,
  getProfilPassword,
  getProfilProfils,
  getProfilPartners,
  getProfilNotification,
  editProfil,
  editProfilPassword,
  editProfilNotification
} from '../../API/Profil';
import {
  form,
  roleForm,
  passwordForm,
  partnerForm,
  notificationForm
} from '../Form/users';

import { config } from '../../API/config';

export const conf = {
  profil: {
    pageTitle: strings.common.homepage.profil,

    apiGet: getProfilIndex,
    // apiEdit: editProfil,

    extendedHeader: true,
    header: {
      imgUrl: (item) => (item.img)?config.file_path+item.img:null,
      placeholderTitle: (item) => item.displayname.split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item.displayname,
      additionalData1: (item) => item.mail,
      additionalData2: (item) => (item && item.phone_numbers && item.phone_numbers[0])?'+'+item.phone_numbers[0].prefix+item.phone_numbers[0].number:''
    },

    tabs: {
      main: {
        title: strings.user.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getProfil,
        apiEdit: editProfil,

        editable: true
      },
      password: {
        title: strings.user.tab.password,
        type: 'form',
        form: passwordForm,

        onUpdateItem: (data) => {},

        apiGet: getProfilPassword,
        apiEdit: editProfilPassword,

        editable: true
      },
      roles: {
        title: strings.user.tab.roles,
        type: 'form',
        form: roleForm,

        onUpdateItem: (data) => {},

        apiGet: getProfilProfils,

        role: 'ROLE_NYUKOM',
        editable: false,
      },
      partners: {
        title: strings.user.tab.partners,
        type: 'form',
        form: partnerForm,
        disabled: true,

        onUpdateItem: (data) => {},

        apiGet: getProfilPartners,

        role: 'ROLE_USER',
        editable: false,
      },
      notifications: {
        title: strings.user.tab.notifications,
        type: 'form',
        form: notificationForm,

        onUpdateItem: (data) => {
          data.item.notifiedByFlags = [];

          if(data.item.roles.includes("ROLE_ACCOUNTING_BILL")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.bill,
              notifiedByMail: data.item["notified_by_mail_flag_bill"],
              notifiedByWeb: data.item["notified_by_web_flag_bill"]
            });
          }

          if(data.item.roles.includes("ROLE_ORDER") || data.item.roles.includes("ROLE_QUOTE")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.commercial,
              notifiedByMail: data.item["notified_by_mail_flag_commercial"],
              notifiedByWeb: data.item["notified_by_web_flag_commercial"]
            });
          }

          if(data.item.roles.includes("ROLE_TECHNICAL_TICKET_NOTIFICATION_GFRT")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.technical,
              notifiedByMail: data.item["notified_by_mail_flag_technical"],
              notifiedByWeb: data.item["notified_by_web_flag_technical"]
            });
          }

          if(data.item.roles.includes("ROLE_ORDER")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.installation,
              notifiedByMail: data.item["notified_by_mail_flag_installation"],
              notifiedByWeb: data.item["notified_by_web_flag_installation"]
            });
          }

          data.item.notifiedByFlags.push({
            name: strings.form.user.notifiedByFlags.row.administrative,
            notifiedByMail: data.item["notified_by_mail_flag_administrative"],
            notifiedByWeb: data.item["notified_by_web_flag_administrative"]
          });

          data.item.notifiedByFlags.push({
            name: strings.form.user.notifiedByFlags.row.service_lifecycle,
            notifiedByMail: data.item["notified_by_mail_flag_service_lifecycle"],
            notifiedByWeb: data.item["notified_by_web_flag_service_lifecycle"]
          });

          if(data.item.roles.includes("ROLE_TECHNICAL_TICKET_NOTIFICATION") || data.item.roles.includes("ROLE_COMMERCIAL_TICKET_NOTIFICATION") || data.item.roles.includes("ROLE_ADMINISTRATIVE_TICKET_NOTIFICATION")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.ticket,
              notifiedByMail: data.item["notified_by_mail_flag_ticket"],
              notifiedByWeb: data.item["notified_by_web_flag_ticket"]
            });
          }

          if(data.item.roles.includes("ROLE_CDR_TECH_ERROR_REPORT")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.admin_report,
              notifiedByMail: data.item["notified_by_mail_flag_admin_report"],
              notifiedByWeb: data.item["notified_by_web_flag_admin_report"]
            });
          }

          data.item.notifiedByFlags.push({
            name: strings.form.user.notifiedByFlags.row.other,
            notifiedByMail: data.item["notified_by_mail"],
            notifiedByWeb: data.item["notified_by_web"]
          });
        },
        onSubmit: (user) => {
          user.notifiedByFlags.forEach((notifiedByFlag) => {
            switch (notifiedByFlag.name) {
              case strings.form.user.notifiedByFlags.row.bill:
                user["notified_by_mail_flag_bill"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_bill"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.commercial:
                user["notified_by_mail_flag_commercial"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_commercial"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.technical:
                user["notified_by_mail_flag_technical"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_technical"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.installation:
                user["notified_by_mail_flag_installation"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_installation"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.administrative:
                user["notified_by_mail_flag_administrative"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_administrative"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.service_lifecycle:
                user["notified_by_mail_flag_service_lifecycle"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_service_lifecycle"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.ticket:
                user["notified_by_mail_flag_ticket"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_ticket"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.admin_report:
                user["notified_by_mail_flag_admin_report"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_admin_report"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.other:
                user["notified_by_mail"] = notifiedByFlag.notifiedByMail
                user["notified_by_web"] = notifiedByFlag.notifiedByWeb
                break;
              default:

            }
          });
          user.notifiedByFlags = undefined;
        },
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getProfilNotification,
        apiEdit: editProfilNotification,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: true,
      },
    }
  },
}
