const phoneZoneGroup = {
  tab: {
    main: "Détail",
    zone: "Zone",
    priceSource: "Prix Entrant",
    priceDestination: "Prix Sortant",
  },
  search: {
    label: "Label"
  },
  new: {
    title: "Nouveau groupe de zone téléphonique"
  }
};

export default phoneZoneGroup;
