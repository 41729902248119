import React from 'react';

import { Container, Alert } from 'react-bootstrap';
import '../../css/entity_tabs.css';

import { connect } from 'react-redux';
import {
  // addLineToFlashbag,
  removeLineFromFlashbag,

  // FLASHBAG_TYPE_PRIMARY,
  // FLASHBAG_TYPE_SECONDARY,
  // FLASHBAG_TYPE_SUCCESS,
  // FLASHBAG_TYPE_DANGER,
  // FLASHBAG_TYPE_WARNING,
  // FLASHBAG_TYPE_INFO,
  // FLASHBAG_TYPE_LIGHT,
  // FLASHBAG_TYPE_DARK
} from '../../Store/Action/flashbag';

import {
  DELAY_SECONDS
} from '../../Flashbag/Constant';

/**
 * MyEntityTabs
 *
 * This component managed the tabs
 */
class Flashbag extends React.Component {

  constructor(props) {
    super(props);


    this.waiting_remove = []
  }

  /**
   * Once the component is mounted, we can query for the information of the item
   * needed to be displayed in the component
   */
  componentDidMount() {
    this.removePastLine();
  }

  componentDidUpdate() {
    this.removePastLine();
  }

  getDelay() {
    return DELAY_SECONDS;
  }

  removePastLine() {
    this.props.flashbag.lines.forEach((line, i) => {
      if(!this.waiting_remove.includes(line.id)) {
        this.waiting_remove.push(line.id);
        setTimeout(() => {
          this.props.dispatch(removeLineFromFlashbag(line.id));
          this.waiting_remove.filter((wr) => wr !== line.id);
        },this.getDelay()*1000);
      }
    });
  }

  displayFlashBagData() {
    return (
      <>
        {this.props.flashbag?this.props.flashbag.lines.map((line) => {
          return (
            <Alert
              key={line.id}
              variant={line.type}
              dismissible
              onClose={() => this.props.dispatch(removeLineFromFlashbag(line.id))}>
              {line.text}
            </Alert>
          )
        }):null}
      </>
    );
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Container>
        {this.displayFlashBagData()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  flashbag: state.persisted.flashbag
})

export default connect(mapStateToProps)(Flashbag);
