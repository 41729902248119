import BaseAssert from './BaseAssert';

class FunctionAssert extends BaseAssert {

  defaultConf = { function: () => false };

  assert() {
    return (this.getValue() !== null && this.getValue() !== undefined)? this.conf.function(this.getValue(), this.getValues, this.getSubmitted()):null;
  }
}

export default FunctionAssert;
