import React from 'react';

import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import Menu from './Menu';

import '../../../css/main_menu.css';

/**
 * HeaderButton
 */
class HeaderButton extends React.Component {

  displayPopover() {
		return (
      <Popover className="main-menu-header-popover-container">
        <div className="d-xs-flex d-md-none">
  		    <Popover.Content className="no-gutter main-menu-header-popover-content-container">
            <Menu/>
  		    </Popover.Content>
        </div>
		  </Popover>
		)
	}

  displayIcon() {
    return (
      <i className='icon-bell'></i>
    )
	}

  /**
   * LeftMenu render method for React Component
   */
  render() {
    return (
      <div className="d-xs-flex d-md-none">
        <OverlayTrigger
  				placement="bottom"
  				trigger="click"
  				overlay={this.displayPopover()}
          rootClose={true}>
          <div>
            <Button className="m-2" variant="my-secondary-noline">
              <i className="icon-menu-1"/>
            </Button>
          </div>
  			</OverlayTrigger>
      </div>
    );
  }
}

export default HeaderButton;
