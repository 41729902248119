const accountAnalytic = {
  title: {
    label: "Titre",
  },
  number: {
    label: "Numéro",
  },
  vat: {
    label: "Montant T.V.A.",
  }
};

export default accountAnalytic;
