const preFillComment = {
  tab: {
    main: "Commentaire",
  },
  search: {
    name: "Nom"
  },
  new: {
    title: "Nouveau commentaire pré-rempli"
  }
};

export default preFillComment;
