import {
  index as baseIndex,
  getFromId as baseGetFromId,
  request
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of notification based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('notifications', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single notification based on his ID
 *
 * @param id the id of the notification requested
 */
export function getFromId(id) {
  return baseGetFromId('notifications', id);
}

/******************************************************************************
 * OTHER FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of the last notification
 */
export function indexLast() {
  return request('/notifications/last', "GET");
}

/**
 * Mark a notification as seen
 *
 * @param id the of the notification
 */
export function markAsSeen(id) {
  return request('/notifications/markAsSeen/'+id, "POST");
}

/**
 * Mark a notification as seen
 *
 * @param id the of the notification
 */
export function markAllAsSeen(id) {
  return request('/notifications/markAllAsSeen/', "POST");
}

/**
 * Mark a notification as unseen
 *
 * @param id the of the notification
 */
export function markAsUnseen(id) {
  return request('/notifications/markAsUnseen/'+id, "POST");
}
