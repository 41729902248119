import strings from '../../../Localization/Localization';

import { store } from '../../../Store/configureStore';

import { fetchPartners } from '../../../API/Partners';

import { timeframe } from '../common';

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.bill.number.label,
  showOnDisabled: () => false,
  validators: []
}

let partner = {
  fieldKey: "partner",
  type: "dropdownEntity",
  label: strings.form.bill.partner.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "client",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let amountMin = {
  fieldKey: "amountMin",
  type: "price",
  label: strings.form.bill.amountMin.label,
  showOnDisabled: () => false,
  validators: []
}

let amountMax = {
  fieldKey: "amountMax",
  type: "price",
  label: strings.form.bill.amountMax.label,
  showOnDisabled: () => false,
  validators: []
}

let createdAtMin = {
  fieldKey: "createdAtMin",
  type: "date",
  label: strings.form.bill.createdAtMin.label,
  showOnDisabled: () => false,
  validators: []
}

let createdAtMax = {
  fieldKey: "createdAtMax",
  type: "date",
  label: strings.form.bill.createdAtMax.label,
  showOnDisabled: () => false,
  validators: []
}

let partialBill = {
  fieldKey: "partialBill",
  type: "switch",
  label: strings.form.bill.partialBill.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "billSearch",
  fields: [
    number,
    partner,
    amountMin,
    amountMax,
    Object.assign({}, timeframe, {
      fieldKey: "createdAtTimeframe",
      label: `${strings.form.common.timeframe.label} ${strings.form.bill.createdAtTimeframe.label}`
    }),
    createdAtMin,
    createdAtMax,
    partialBill
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
