import strings from '../../Localization/Localization';

let title = {
  fieldKey: "title",
  type: "text",
  label: strings.form.accountingType.title.label,
  validators: []
}

let collectiveClientNumber = {
  fieldKey: "collective_client_number",
  type: "text",
  label: strings.form.accountingType.collectiveClientNumber.label,
  validators: []
}

export const form = {
  formKey: "accountVat",
  fields: [
    title,
    collectiveClientNumber
  ]
}
