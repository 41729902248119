import strings from '../../Localization/Localization';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.ticketTimeZone.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.ticketTimeZone.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

export const form = {
  formKey: "ticketTimeZone",
  fields: [
    name,
  ]
}

export const newForm = {
  formKey: "ticketTimeZone",
  fields: [
    name,
  ]
}
