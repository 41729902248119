const contract = {
  beginAt: {
    label: "Date de début",
  },
  engagement: {
    label: "Durée de l'engagement",
    options: {
      none: "Aucun engagement",
      year1: "12 mois",
      year2: "24 mois",
      year3: "36 mois",
      year4: "48 mois",
      year5: "60 mois"
    },
  },
  billPeriod: {
    label: "Rythme de facturation",
    options: {
      monthly: "Mensuel",
      fortnightly: "Bimestriel",
      quarterly: "Trimestriel",
      halfYearly: "Semestriel",
      yearly: "Annuel",
      oneshot: "Paiement en une fois"
    },
  },
  priorNotice: {
    label: "Durée du préavis",
    options: {
      none: "Aucun préavis",
      month1: "1 mois",
      month3: "3 mois"
    },
  },
  tacitAgreement: {
    label: "Reconduction tacite",
    options: {
      none: "Aucune reconduction",
      month1: "reconduction de 1 mois",
      year1: "reconduction de 12 mois"
    },
  },
  expectedTacitAgreementAt: {
    label: "Date prévisionnelle de reconduction"
  },
  terminationPrice: {
    label: "Tarif de résiliation",
  },
  additionalTerminationPrice: {
    label: "Si non restitution de matériel",
  },
  guaranteedFaultRepairTime: {
    label: "GTR",
    options: {
      best_effort: "Best Effort, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _24_8_5_7: "24h, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _8_8_5_7: "8h, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _4_8_5_7: "4h, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _1_8_5_7: "1h, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _24_24_5_7: "24h, du Lundi au Vendredi de 0h00 à 23h59 (24/5)",
      _8_24_5_7: "8h, du Lundi au Vendredi de 0h00 à 23h59 (24/5)",
      _4_24_5_7: "4h, du Lundi au Vendredi de 0h00 à 23h59 (24/5)",
      _1_24_5_7: "1h, du Lundi au Vendredi de 0h00 à 23h59 (24/5)",
      _24_24_7_7: "24h, du Lundi au Dimanche de 0h00 à 23h59 (24/7)",
      _8_24_7_7: "8h, du Lundi au Dimanche de 0h00 à 23h59 (24/7)",
      _4_24_7_7: "4h, du Lundi au Dimanche de 0h00 à 23h59 (24/7)",
      _1_24_7_7: "1h, du Lundi au Dimanche de 0h00 à 23h59 (24/7)",
      parent_value: "Voir valeur parent"
    },
  },
  guaranteedResponseTime: {
    label: "GTI",
    options: {
      best_effort: "Best Effort, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _24_8_5_7: "24h, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _8_8_5_7: "8h, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _4_8_5_7: "4h, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _1_8_5_7: "1h, du Lundi au Vendredi de 8h30 à 12h30 et de 14h00 à 18h00 (8/5)",
      _24_24_5_7: "24h, du Lundi au Vendredi de 0h00 à 23h59 (24/5)",
      _8_24_5_7: "8h, du Lundi au Vendredi de 0h00 à 23h59 (24/5)",
      _4_24_5_7: "4h, du Lundi au Vendredi de 0h00 à 23h59 (24/5)",
      _1_24_5_7: "1h, du Lundi au Vendredi de 0h00 à 23h59 (24/5)",
      _24_24_7_7: "24h, du Lundi au Dimanche de 0h00 à 23h59 (24/7)",
      _8_24_7_7: "8h, du Lundi au Dimanche de 0h00 à 23h59 (24/7)",
      _4_24_7_7: "4h, du Lundi au Dimanche de 0h00 à 23h59 (24/7)",
      _1_24_7_7: "1h, du Lundi au Dimanche de 0h00 à 23h59 (24/7)",
      parent_value: "Voir valeur parent"
    },
  },
  serviceLevelAgreement: {
    label: "SLA",
    options: {
      _99_00: "99,00%",
      _99_50: "99,50%",
      _99_99: "99,99%",
      parent_value: "Voir valeur parent"
    },
  },
  externalNumber: {
    label: "Numéro externe",
    invalid: {
      assertLengthMax: "Ce numéro ne peut pas dépasser 30 caractères."
    },
  },
  externalNumberDuration: {
    label: "Durée du numéro externe",
  }
};

export default contract;
