const preFillComment = {
  title: {
    label: "Titre",
    invalid: {
      assertEmpty: "Le titre ne peut pas être vide."
    }
  },
  body: {
    label: "Commentaire",
    invalid: {
      assertEmpty: "Le commentaire ne peut pas être vide."
    }
  }
};

export default preFillComment;
