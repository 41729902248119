import strings from '../../Localization/Localization';

let filename = {
  fieldKey: "filename",
  type: "file",
  label: strings.form.billing.filename.label,
  validators: []
}

let createdAt = {
  fieldKey: "created_at",
  type: "date",
  label: strings.form.billing.createdAt.label,
  validators: []
}

let bills = {
  fieldKey: "bills",
  type: "table",
  label: strings.form.billing.bills.label,
  validators: [],

  table: {
    valuesToRows: (values) => {
      let result = [];
      values.forEach((value, i) => {
        result.push(Object.assign({}, value, {
          client_name: value.client.partner.name
        }))
      });
      return result;
    },
    headers: [
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: "client_name",
        label: "Client",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "price",
        value: "total_ti",
        label: "Total",
        editable: false
      },
    ]
  }
}

export const billsForm = {
  formKey: "billing",
  fields: [
    bills
  ]
}

export const form = {
  formKey: "billing",
  fields: [
    filename,
    createdAt
  ]
}
