const technicalTicket = {
  search: {
    number: "Numéro",
    requestType: "Type de ticket",
    state: "Etat",
    criticity: "Niveau d'urgence",
    escalateLevel: "Niveau d'escalade",
    referee: "Référent NK",
    client: "Client",
    clientAccount: "Compte client",
    place: "Site",
    refereeClient: "Référent client",
    createdAtTimeframe: "Plage de temps / Création",
    createdAt: "Date de création",
    appointmentType: "Type d'intervention",
    appointmentNumber: "Numéro d'intervention"
  }
};

export default technicalTicket;
