import strings from '../../../Localization/Localization';

import { store } from '../../../Store/configureStore';

import { fetchPartnerTypes } from '../../../API/PartnerTypes';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.partner.name.label,
  showOnDisabled: () => false,
  validators: []
}

let partnerNumber = {
  fieldKey: "partnerNumber",
  type: "text",
  label: strings.form.partner.partnerNumber.label,
  showOnDisabled: () => false,
  validators: []
}

let siret = {
  fieldKey: "siret",
  type: "text",
  label: strings.form.partner.siret.label,
  showOnDisabled: () => false,
  validators: []
}

let legalForm = {
  fieldKey: "legalForm",
  type: "text",
  label: strings.form.partner.legalForm.label,
  showOnDisabled: () => false,
  validators: []
}

let prospect = {
  fieldKey: "prospect",
  type: "switch",
  label: strings.form.partner.prospect.label,
  showOnDisabled: () => false,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  validators: []
}

let partnerType = {
  fieldKey: "partnerType",
  type: "dropdownEntity",
  label: strings.form.partner.partnerType.label,
  showOnDisabled: () => false,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  validators: [],

  showEmpty: true,
  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "partnerTypes",
    loader: fetchPartnerTypes,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let waitingTicketBilling = {
  fieldKey: "waitingTicketBilling",
  type: "switch",
  label: strings.form.partner.waitingTicketBilling.label,
  showOnDisabled: () => false,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FROM_TICKET');
  },
  validators: []
}


export const form = {
  formKey: "partnerSearch",
  fields: [
    name,
    partnerNumber,
    siret,
    legalForm,
    prospect,
    partnerType,
    waitingTicketBilling
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
