import { store } from '../Store/configureStore';

import { config } from './config';

import { v4 as uuidv4 } from 'uuid';

/**
 * Get a list of items based on a pagination system
 *
 * @param url_id the id of the resource requested
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 * @param search list of search filter
 */
export function index(url_id, page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  //create the path
  let path = '/'+url_id;
  if(scheme !== null) {
    path += '/'+scheme
  }

  //create the params for the request from the parameters of the functions
  let params = {
    page: page,
    item_per_page: itemPerPage,
    order: order
  }

  Object.assign(params, search);

  //execute the request
  return request(path, "GET", params);
}

/**
 * TODO change to use request()
 * Get a single item based on his ID
 *
 * @param url_id the id of the ressource
 * @param id the of the user requested
 * @param scheme the name of the scheme data
 */
export function getFromId(url_id, id, scheme = undefined, additionalGetParams) {
  //url of the resource
  let path = '/'+url_id+'/'+id+((scheme !== undefined)?'/'+scheme:'');

  //execute the request
  return request(path, "GET", additionalGetParams);
}

/**
 * Edit a single item based on his ID
 *
 * @param url_id the id of the ressource
 * @param id the item id in the request POST
 * @param data the data of the item requested
 * @param scheme the data scheme
 */
export function editFromId(url_id, id, data, scheme = undefined) {
  //create the path
  let path = '/'+url_id+'/'+id+((scheme !== undefined)?'/'+scheme:'');

  let params = {
    item: data
  };

  return request(path, "POST", params);
}

/**
 * Edit a single item based on his ID
 *
 * @param url_id the id of the ressource
 * @param search the search arguments used to filter what we edit
 * @param data the data of the item requested
 * @param scheme the data scheme
 */
export function editFromSearch(url_id, search, data, scheme = undefined) {
  //create the path
  let path = '/'+url_id;
  if(scheme !== null) {
    path += '/'+scheme
  }

  let params = {
    body: {
      item: data
    },
    url: search
  };

  return request(path, "PATCH", params);
}

/**
 * Transition a single item based on his ID
 *
 * @param url_id the id of the ressource
 * @param id the item id in the request POST
 * @param data the data of the item requested
 * @param transition the transition to execute
 */
export function transitionFromId(url_id, id, data, transition = undefined) {
  //create the path
  let path = '/'+url_id+'/transition/'+id+((transition !== undefined)?'/'+transition:'');

  let params = {
    ...data
  };

  return request(path, "POST", params);
}

/**
 * Get a stat
 */
export function statistic(url_id, stat, attibute = null, search = {}){
  //create the path
  let path = '/'+url_id+'/statistic/'+stat;
  if(attibute !== null) {
    path += '/'+attibute
  }

  //create the params for the request from the parameters of the functions
  let params = {}

  Object.assign(params, search);

  //execute the request
  return request(path, "GET", params);
}

/**
 * Get a state machine's statistic
 */
export function stateMachineStatistic(url_id, stateStatistic, states = [], search = {}){
  //create the path
  let path = '/'+url_id+'/state_machine_statistic/'+stateStatistic;

  //create the params for the request from the parameters of the functions
  let params = {
    _states: states
  }

  Object.assign(params, search);

  //execute the request
  return request(path, "GET", params);
}

/**
 * Create a new item
 *
 * @param url_id the id of the ressource
 * @param data the data of the new user
 */
export function newItem(url_id, data) {
  //url of the resource
  let path = '/'+url_id;

  let params = {
    item: data
  };

  return request(path, "POST", params);
}

/**
 * Create a delete item
 *
 * @param url_id the id of the ressource
 * @param id the item id in the request
 */
export function deleteItem(url_id, id) {
  //url of the resource
  let path = '/'+url_id+'/'+id;

  return request(path, "DELETE");
}

/**
 * import a file
 *
 * @param url_id the id of the ressource
 * @param data the new data of the user
 */
export function importData(url_id, file, scheme = undefined) {
  //url of the resource
  let path = '/'+url_id+'/import'+((scheme !== undefined)?'/'+scheme:'');

  let params = {
    file: file
  };

  return request(path, "POST", params);
}



/**
 * make a simple request
 */
export function request(path, type = "GET", params = null) {
  const credentials = store.getState().persisted.credentials;

  //url of the resource
  let url = config.api_path+path;

  let postData = null;
  if(type === "POST" && params !== null) {
    postData = processPostData(params);
  }
  else if(type === "GET" && params !== null) {
    //map the params to the request as GET parameters
    let urlParams = '?'+Object.keys(params).map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    }).join('&');

    url += urlParams;
  }
  else if(type === "PATCH" && params !== null) {
    if(params.body) {
      postData = processPostData(params.body);
    }
    if(params.url) {
      let urlParams = '?'+Object.keys(params.url).map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params.url[key])}`;
      }).join('&');

      url += urlParams;
    }
  }

  //headers used by the request
  let headers = new Headers({
    //"Content-Type": (type === "GET" || !postData.form_data)?"application/json":null,
    "API-AUTH-TOKEN": credentials.authToken,
  });

  if(type === "GET" || (postData !== null && !postData.form_data)) {
    headers.append("Content-Type", "application/json");
  }

  let init = {
    method: type,
    headers: headers,
    mode: 'cors',
    cache: 'default',
  };

  if(postData !== null) {
    init.body = postData.body;
  }

  //execute the request
  return fetch(url, init)
    .then((response) => {
      switch (response.status) {
        case 200:
          switch (response.headers.get('Content-Type')) {
            case 'text/csv; charset=UTF-8':
            case 'text/plain; charset=UTF-8':
              return response.blob();
            case 'application/json':
            default:
              return response.json();
          }
        case 301:
        case 401:
        case 403:
          //TODO send message to store for LoginForm to display Error 403
          return {
            status: 'error',
            message: "Une erreur s'est produite au niveau du serveur",
            serverMessage: response.statusText,
            needAuthentication: true
          }
        case 404:
        case 500:
        default:
          return {
            status: 'error',
            message: "Une erreur s'est produite au niveau du serveur",
            serverMessage: response.statusText
          }
      }
    })
    .then((response) => {
      if(response instanceof Blob) {
        let fileURL = window.URL.createObjectURL(response);
        let fileLink = document.createElement('a');
        let extension = '';
        switch (response.type) {
          case 'text/csv; charset=UTF-8':
          case 'text/csv; charset=utf-8':
            extension = '.csv';
            break;
          case 'text/plain; charset=UTF-8':
          case 'text/plain; charset=utf-8':
            extension = '.txt';
            break;
          default:
        }
        fileLink.href = fileURL;
        fileLink.download = 'file'+extension;
        fileLink.click();
      }
      return response;
    })
    .catch((error) => console.error(error));
}

function processPostData(data) {

  if(searchForFile(data)) {
    let body = new FormData();

    let files = replaceFile(data);

    body.append("json", JSON.stringify(data));
    for (let property in files) {
      body.append(property, files[property]);
    }

    return {
      form_data: true,
      body: body
    }
  }
  else {
    return {
      form_data: false,
      body: JSON.stringify(data)
    }
  }
}

function searchForFile(object) {
  if(object instanceof Array) {
    return object.some((item) => item instanceof File || searchForFile(item));
  }
  else {
    if(typeof object === "object") for(let property in object) {
      if(object[property] instanceof File || searchForFile(object[property]) === true) {
        return true;
      }
    }
  }
  return false;
}

function replaceFile(object) {
  let files = {};
  if(object instanceof Array) {
    object.forEach((item, i) => {
      if(item instanceof File) {
        let uuid = uuidv4();
        files[uuid] = item;
        object[i] = `${"files["+uuid+"]"}`;
      }
      else {
        Object.assign(files, replaceFile(item));
      }
    });
  }
  else {
    if(typeof object === "object") for(let property in object) {
      if(object[property] instanceof File) {
        let uuid = uuidv4();
        files[uuid] = object[property];
        object[property] = `${"files["+uuid+"]"}`;
      }
      else {
        Object.assign(files, replaceFile(object[property]));
      }
    }
  }
  return files;
}
