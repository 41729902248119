import React from 'react';

import { Row, OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * OneRowText
 */
class OneRowText extends React.Component {

  static defaultProps = {
    tooltip: true,
    text: '',
    noGutters: false
  }

  constructor(props) {
    super(props);
    this.ref = React.createRef();

    this.state = {
      loaded: false,
    }
  }

  componentDidMount() {
    this.setState({
      loaded: true
    })
  }

  displayTooltip = () => {
    if(this.props.tooltip && this.ref.current && this.ref.current.clientWidth < this.ref.current.scrollWidth) {
      return (
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.props.text}</Tooltip>}>
          {this.displayText()}
        </OverlayTrigger>
      );
    }
    else {
      return (
        <>
          {this.displayText()}
        </>
      )
    }
  }

  displayText = () => {
    return (
      <span ref={this.ref} className="d-inline-block one-line-text">
        {this.props.text}
      </span>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Row className={this.props.noGutters?"no-gutters":""}>
        {this.displayTooltip()}
      </Row>
    );
  }
}

export default OneRowText;
