import React from 'react';

import strings from '../../../Localization/Localization';

import BasicNotification from './BasicNotification';

class ReportNewNyukom extends BasicNotification {

  displayMessage() {
    return (
      <>{strings.formatString(strings.common.notification.report_new_nyukom, this.props.item._arguments)}</>
    )
  }
}

export default ReportNewNyukom;
