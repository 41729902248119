import strings from '../../../Localization/Localization';

import { conf as quoteConf } from '../../Configuration/quotes';
import { statistic as quoteStatistic } from '../../../API/Quotes';

import { conf as orderConf } from '../../Configuration/orders';
import { statistic as orderStatistic } from '../../../API/Orders';

import { conf as commercialTicketConf } from '../../Configuration/commercialTicket';
import { statistic as commercialTicketStatistic } from '../../../API/CommercialTicket';

import { conf as serviceConf } from '../../Configuration/services';
import { statistic as serviceStatistic } from '../../../API/Services';

import { conf as technicalTicketConf } from '../../Configuration/technicalTicket';
import { statistic as technicalTicketStatistic } from '../../../API/TechnicalTicket';

import { conf as administrativeTicketConf } from '../../Configuration/administrativeTicket';
import { statistic as administrativeTicketStatistic } from '../../../API/AdministrativeTicket';

import { conf as billConf } from '../../Configuration/bills';
import { statistic as billStatistic } from '../../../API/Bills';

import { ParameterTypes } from '../../../Dashboard/Constant';

import { transformFieldToParamaterChoice } from './common';

export const objective = {
  id: "objective",
  name: strings.dashboard.widget.objective.name,
  icon: "icon-view-list",
  column: {
    min: 1,
    max: 1
  },
  row: {
    min: 1,
    max: 1
  },
  additionalData: {

  },
  elementSettings: {
    quote: {
      name: "Devis",
      fetch: quoteStatistic,
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.quote,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            signature_days: {
              label: strings.dashboard.widget.listStat.attribute.quote.signatureDays,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            signature_days: {
              label: strings.dashboard.widget.listStat.attribute.quote.signatureDays,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            signature_days: {
              label: strings.dashboard.widget.listStat.attribute.quote.signatureDays,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },
      filters: {
        formKey: quoteConf.index.searchForm.formKey,
        fields: quoteConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: quoteConf.index.computeSearch,
    },
    order: {
      name: "Commande",
      fetch: orderStatistic,
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.order,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            delivery_days: {
              label: strings.dashboard.widget.listStat.attribute.order.deliveryDays,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            delivery_days: {
              label: strings.dashboard.widget.listStat.attribute.order.deliveryDays,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            delivery_days: {
              label: strings.dashboard.widget.listStat.attribute.order.deliveryDays,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },
      filters: {
        formKey: orderConf.index.searchForm.formKey,
        fields: orderConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: orderConf.index.computeSearch,
    },
    commercialTicket: {
      name: "Ticket commercial",
      fetch: commercialTicketStatistic,
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.ticket,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },
      filters: {
        formKey: commercialTicketConf.index.searchForm.formKey,
        fields: commercialTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: commercialTicketConf.index.computeSearch,
    },
    service: {
      name: "Parc",
      fetch: serviceStatistic,
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.service,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
      },
      filters: {
        formKey: serviceConf.index.searchForm.formKey,
        fields: serviceConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "serialNumber":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "state":
              return transformFieldToParamaterChoice(field);
            case "partner":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: serviceConf.index.computeSearch,
    },
    technicalTicket: {
      name: "Ticket technique",
      fetch: technicalTicketStatistic,
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.ticket,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },
      filters: {
        formKey: technicalTicketConf.index.searchForm.formKey,
        fields: technicalTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: technicalTicketConf.index.computeSearch,
    },
    administrativeTicket: {
      name: "Ticket administratif",
      fetch: administrativeTicketStatistic,
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.ticket,
              role: ["ROLE_USER", "ROLE_NYUKOM"]
            }
          },
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            criticity: {
              label: strings.dashboard.widget.listStat.attribute.ticket.criticity,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            escalate_level: {
              label: strings.dashboard.widget.listStat.attribute.ticket.escalateLevel,
              unit: strings.common.units.none,
              role: ["ROLE_NYUKOM"]
            },
            days_opened: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysOpened,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
            days_last_changed: {
              label: strings.dashboard.widget.listStat.attribute.ticket.daysLastChanged,
              unit: strings.common.units.days,
              role: ["ROLE_NYUKOM"]
            },
          },
        },
      },
      filters: {
        formKey: administrativeTicketConf.index.searchForm.formKey,
        fields: administrativeTicketConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "criticity":
            case "state":
            case "escalate":
              return transformFieldToParamaterChoice(field);
            case "client":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            case "referee":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.USER, true);
            default:
          }
          return field
        })
      },
      computeFilters: administrativeTicketConf.index.computeSearch
    },
    bill: {
      name: "Facture",
      fetch: billStatistic,
      stat: {
        quantity: {
          label: strings.dashboard.widget.listStat.stat.quantity,
          attribute: {
            id: {
              label: strings.dashboard.widget.listStat.attribute.common.id,
              unit: strings.common.units.bill,
              role: ["ROLE_NYUKOM"]
            }
          }
        },
        average: {
          label: strings.dashboard.widget.listStat.stat.average,
          attribute: {
            total_ti: {
              label: strings.dashboard.widget.listStat.attribute.bill.totalTi,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            total_tf: {
              label: strings.dashboard.widget.listStat.attribute.bill.total_tf,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            }
          }
        },
        minimum: {
          label: strings.dashboard.widget.listStat.stat.minimum,
          attribute: {
            total_ti: {
              label: strings.dashboard.widget.listStat.attribute.bill.totalTi,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            total_tf: {
              label: strings.dashboard.widget.listStat.attribute.bill.total_tf,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            }
          }
        },
        maximum: {
          label: strings.dashboard.widget.listStat.stat.maximum,
          attribute: {
            total_ti: {
              label: strings.dashboard.widget.listStat.attribute.bill.totalTi,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            total_tf: {
              label: strings.dashboard.widget.listStat.attribute.bill.total_tf,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            }
          }
        },
        sum: {
          label: strings.dashboard.widget.listStat.stat.sum,
          attribute: {
            total_ti: {
              label: strings.dashboard.widget.listStat.attribute.bill.totalTi,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            },
            total_tf: {
              label: strings.dashboard.widget.listStat.attribute.bill.total_tf,
              unit: strings.common.units.euro,
              adapter: (value) => Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(value),
              role: ["ROLE_NYUKOM"]
            }
          }
        },
      },
      filters: {
        formKey: billConf.index.searchForm.formKey,
        fields: billConf.index.searchForm.fields.map(field => {
          switch (field.fieldKey) {
            case "number":
              return transformFieldToParamaterChoice(field, true, false, ParameterTypes.STRING);
            case "partner":
              return transformFieldToParamaterChoice(field, true, true, ParameterTypes.PARTNER);
            default:
          }
          return field
        })
      },
      computeFilters: billConf.index.computeSearch
    },
  },
  visualSettings: [
    {
      fieldKey: "display",
      label: "Affichage",
      type: "dropdown",

      validators: [
        {
          id: 'assertEmpty',
          type: 'notEmptyOrNull',
          invalidFeedback: strings.form.ticket.criticity.invalid.assertEmpty,
          defaultValue: false
        },
      ],

      showEmpty: true,
      input: 'options',
      options: [
        {
          value: "quantity",
          label: "Quantité"
        },
        {
          value: "percentage",
          label: "Pourcentage"
        },
        {
          value: "progressionBar",
          label: "Barre de progression"
        },
      ],
    }
  ],
  dataSettings: [
    {
      fieldKey: "valueType",
      label: "Type de la valeur",
      type: "dropdown",

      validators: [
        {
          id: 'assertEmpty',
          type: 'notEmptyOrNull',
          invalidFeedback: strings.form.ticket.criticity.invalid.assertEmpty,
          defaultValue: false
        },
      ],

      showEmpty: true,
      input: 'options',
      options: [
        {
          value: "static",
          label: "Valeur fixe"
        },
        {
          value: "statistic",
          label: "Statistique"
        },
      ],
    },
    {
      fieldKey: "valueStatic",
      type: "text",
      label: "Valeur",
      disabled: (getValues) => !(getValues(0) && getValues(0)["valueType"] === 'static'),
      showOnDisabled: (getValues) => getValues(0) && getValues(0)["valueType"] === 'static',
      validators: []
    },
    {
      fieldKey: "valueStatistic",
      type: "subform",
      label: "Statistic",
      disabled: (getValues) => !(getValues(0) && getValues(0)["valueType"] === 'statistic'),
      showOnDisabled: (getValues) => getValues(0) && getValues(0)["valueType"] === 'statistic',
      validators: [],

      subForm: {
        formKey: "valueStatistic",
        fields: [
          {
            fieldKey: "stat",
            type: "dropdown",
            label: "Statistique",
            showOnDisabled: () => false,
            validators: [],

            input: 'formValues',
            finalValues: 'values',
            compute: (getValues, extraValues) => {
              let element = getValues().element;
              if(element && objective.elementSettings[element]) {
                return Object.keys(objective.elementSettings[element].stat).map(stat => {
                  return {
                    id: stat,
                    label: objective.elementSettings[element].stat[stat].label
                  }
                })
              }
              return []
            },
            adapter: {
              requirement: (data) => data && data["id"] && data["label"],
              getValue: (data) => data["id"],
              getLabel: (data) => data["label"],
              isFinalValue: true
            },
          },
          {
            fieldKey: "attribute",
            type: "dropdown",
            label: "Attribut",
            showOnDisabled: () => false,
            validators: [],

            input: 'formValues',
            finalValues: 'values',
            compute: (getValues, extraValues) => {
              let element = getValues().element;
              let stat = getValues(0).stat;
              if(element && objective.elementSettings[element] && stat) {
                return Object.keys(objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute)
                  .filter(id => {
                    // console.log(objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id]);
                    let credentials = extraValues.credentials();
                    if(credentials) {
                      if(objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id].role) {
                        let roles = objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id].role;
                        // if(typeof roles === 'function') {
                        //   roles = roles(getValues, extraValues);
                        // }
                        if(!Array.isArray(roles)) {
                          roles = [roles];
                        }

                        return roles.some(role => credentials.roles.includes(role));
                      }
                      return true;
                    }
                    return false;
                  })
                  .map(id => {
                    return {
                      id: id,
                      label: objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id].label
                    }
                  })
              }
              return []
            },
            adapter: {
              requirement: (data) => data && data["id"] && data["label"],
              getValue: (data) => data["id"],
              getLabel: (data) => data["label"],
              isFinalValue: true
            },
          },
          {
            fieldKey: "filters",
            type: "configurableField",
            label: "",
            validators: [],

            subField: (value, getValues, getExtraValues) => {
              let element = getValues().element;
              if(element && objective.elementSettings[element]) {
                return {
                  subForm: objective.elementSettings[element].filters
                }
              }
              return {
                subForm: {
                  fields: {}
                }
              }
            },
            subFieldConf: {
              label: () => "",
              type: () => "subform",
              disabled: () => false,
              validators: () => [],
            }
          }
        ]
      }
    },
    {
      fieldKey: "maxType",
      label: "Type du maximum",
      type: "dropdown",

      validators: [
        {
          id: 'assertEmpty',
          type: 'notEmptyOrNull',
          invalidFeedback: strings.form.ticket.criticity.invalid.assertEmpty,
          defaultValue: false
        },
      ],

      showEmpty: true,
      input: 'options',
      options: [
        {
          value: "static",
          label: "Valeur fixe"
        },
        {
          value: "statistic",
          label: "Statistique"
        },
      ],
    },
    {
      fieldKey: "maxStatic",
      type: "text",
      label: "Maximum",
      disabled: (getValues) => !(getValues(0) && getValues(0)["maxType"] === 'static'),
      showOnDisabled: (getValues) => getValues(0) && getValues(0)["maxType"] === 'static',
      validators: []
    },
    {
      fieldKey: "maxStatistic",
      type: "subform",
      label: "Statistic",
      disabled: (getValues) => !(getValues(0) && getValues(0)["maxType"] === 'statistic'),
      showOnDisabled: (getValues) => getValues(0) && getValues(0)["maxType"] === 'statistic',
      validators: [],

      subForm: {
        formKey: "valueStatistic",
        fields: [
          {
            fieldKey: "stat",
            type: "dropdown",
            label: "Statistique",
            showOnDisabled: () => false,
            validators: [],

            input: 'formValues',
            finalValues: 'values',
            compute: (getValues, extraValues) => {
              let element = getValues().element;
              if(element && objective.elementSettings[element]) {
                return Object.keys(objective.elementSettings[element].stat).map(stat => {
                  return {
                    id: stat,
                    label: objective.elementSettings[element].stat[stat].label
                  }
                })
              }
              return []
            },
            adapter: {
              requirement: (data) => data && data["id"] && data["label"],
              getValue: (data) => data["id"],
              getLabel: (data) => data["label"],
              isFinalValue: true
            },
          },
          {
            fieldKey: "attribute",
            type: "dropdown",
            label: "Attribut",
            showOnDisabled: () => false,
            validators: [],

            input: 'formValues',
            finalValues: 'values',
            compute: (getValues, extraValues) => {
              let element = getValues().element;
              let stat = getValues(0).stat;
              if(element && objective.elementSettings[element] && stat) {
                return Object.keys(objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute)
                  .filter(id => {
                    // console.log(objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id]);
                    let credentials = extraValues.credentials();
                    if(credentials) {
                      if(objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id].role) {
                        let roles = objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id].role;
                        // if(typeof roles === 'function') {
                        //   roles = roles(getValues, extraValues);
                        // }
                        if(!Array.isArray(roles)) {
                          roles = [roles];
                        }

                        return roles.some(role => credentials.roles.includes(role));
                      }
                      return true;
                    }
                    return false;
                  })
                  .map(id => {
                    return {
                      id: id,
                      label: objective.elementSettings[element].stat[stat.id?stat.id:stat].attribute[id].label
                    }
                  })
              }
              return []
            },
            adapter: {
              requirement: (data) => data && data["id"] && data["label"],
              getValue: (data) => data["id"],
              getLabel: (data) => data["label"],
              isFinalValue: true
            },
          },
          {
            fieldKey: "filters",
            type: "configurableField",
            label: "",
            validators: [],

            subField: (value, getValues, getExtraValues) => {
              let element = getValues().element;
              if(element && objective.elementSettings[element]) {
                return {
                  subForm: objective.elementSettings[element].filters
                }
              }
              return {
                subForm: {
                  fields: {}
                }
              }
            },
            subFieldConf: {
              label: () => "",
              type: () => "subform",
              disabled: () => false,
              validators: () => [],
            }
          }
        ]
      }
    },
  ]
}
