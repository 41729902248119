const centreonChart = {
  timeframe: {
    label: "Période",
    invalid: {},
    options: {
      _3Hours: "Les 3 dernières heures",
      _24Hours: "Les 24 dernières heures",
      _7Days: "Les 7 derniers jours"
    }
  }
};

export default centreonChart;
