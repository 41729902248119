import strings from '../../Localization/Localization';

import { index, getFromId, editFromId, newItem } from '../../API/Profils';
import { form } from '../Form/profils';
import { form as searchForm } from '../Form/Search/profils';

import { profils as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.profils,

    id: "profil",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: ['ROLE_ADMIN']
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.label) {
        result["label"] = search.label;
      }
      if(search.active === true) {
        result["active"] = 1
      }
      else if(search.active === false) {
        result["active"] = 0
      }
      if(typeof search.group === 'object') {
        result["group_id"] = search.group.id
      }
      else if(search.group && search.group !== ''){
        result["group_label"] = search.group
      }
      if(typeof search.role === 'object') {
        result["role_id"] = search.role.id
      }
      else if(search.role && search.role !== ''){
        result["role_name"] = search.role
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => strings.resolve(item.label),
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.profils,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "label",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.profil.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_NYUKOM'],
        editable: ['ROLE_ADMIN']
      }
    }
  },
  new: {
    pageTitle: strings.profil.new.title,

    api: newItem,
    empty: {},
    form: form,
    onSubmit: (profil) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
