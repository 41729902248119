const dashboard = {
  main: {
    storage: {
      buttons: {
        default: "Défaut",
        personal: "Personnalisé"
      },
      form: {
        title: "Titre"
      }
    },
    organisationMode: {
      buttons: {
        cancel: "Annuler",
        validate: "Confirmer"
      }
    }
  },
  widget: {
    color: {
      name: "Couleur"
    },
    title: {
      name: "Titre"
    },
    parameter: {
      name: "Paramètres"
    },
    list: {
      name: "Liste d'entités"
    },
    listStat: {
      name: "Liste de statistique",
      stat: {
        quantity: "Quantité",
        average: "Moyenne",
        minimum: "Minimum",
        maximum: "Maximum",
        sum: "Somme"
      },
      attribute: {
        common: {
          id: "Total"
        },
        quote: {
          signatureDays: "Jour avant signature"
        },
        order: {
          deliveryDays: "Jour avant livraison"
        },
        ticket: {
          criticity: "Urgence",
          escalateLevel: "Escalade",
          daysOpened: "Nb de jour ouvert",
          daysLastChanged: "Nb de jour depuis la dernière action",
        },
        bill: {
          totalTi: "Total T.T.C.",
          total_tf: "Total H.T."
        },
        commercialBonus: {
          flatBonusValue: "Total commission"
        }
      }
    },
    objective: {
      name: "Objectif"
    },
    stateMachine: {
      name: "Camembert"
    },
    chartBar: {
      name: "Graphique baton"
    },
    chartLine: {
      name: "Graphique Ligne"
    },
    supervision: {
      name: "Supervision"
    },
  },
  editWidget: {
    main: {
      panel: {
        name: "Nom du module :",
        general: "Mise en page",
        element: "Element",
        visualSettings: "Paramètre visuel",
        dataSettings: "Paramètre données",
      }
    },
    button: {
      cancel: "Annuler",
      save: "Sauvegarder"
    },
    form: {
      type: {
        label: "Type de module"
      },
      column: {
        label: "Nbre de colonnes"
      },
      row: {
        label: "Nbre de lignes"
      },
      element: {
        label: "Choix de l'élément"
      }
    }
  },

  entity: {
    quote: "Devis",
    order: "Commande",
    commercialTicket: "Ticket commercial",
    service: "Parc",
    technicalTicket: "Ticket technique",
    administrativeTicket: "Ticket administratif",
    bill: "Facture",
    commercialBonus: "Commission"
  }
};

export default dashboard;
