import strings from '../../../Localization/Localization';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.constructor.name.label,
  showOnDisabled: (values) => false,
  validators: []
}

export const form = {
  formKey: "constructorSearch",
  fields: [
    name
  ]
}
