const bill = {
  partner: {
    label: "Partenaire",
    invalid: {}
  },
  amountMin: {
    label: "Montant minimum",
    invalid: {}
  },
  amountMax: {
    label: "montant maximum",
    invalid: {}
  },
  number: {
    label: "Numéro",
    invalid: {},
  },
  totalTf: {
    label: "Total HT",
    invalid: {},
  },
  totalTi: {
    label: "Total TTC",
    invalid: {},
  },
  filename: {
    label: "Fichier PDF",
    invalid: {},
  },
  createdAt: {
    label: "Date de création",
    invalid: {},
  },
  deadline: {
    label: "Date d'échéance",
    invalid: {},
  },
  accountLines: {
    label: "Détails de la facture",
    invalid: {},
    table: {
      headers: {
        designation: "Désignation",
        amount: "Montant"
      }
    }
  },
  payments: {
    label: "Paiements reçu",
    invalid: {},
    table: {
      headers: {
        type: "Type de paiement",
        amount: "Montant",
        validated: "Validé"
      }
    }
  },
  createdAtMin: {
    label: "Date (min)"
  },
  createdAtMax: {
    label: "Date (max)"
  },
  createdAtTimeframe: {
    label: "Date"
  },
  partialBill: {
    label: "Facture partielle"
  },
};

export default bill;
