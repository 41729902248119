import React from 'react';

import strings from '../../Localization/Localization';

import { Container, Col, Row, Form, Button, Navbar } from 'react-bootstrap';

import MainLayout from '../Layout/MainLayout';

import { connect } from 'react-redux';
import { addBreadcrumbAction } from '../../Store/Action/breadcrumb';

import { withModalHandler } from '../../HOC/ModalHandler';

import AsyncCounter from '../../Utils/Counter/AsyncCounter';

import { index as orderIndex } from '../../API/Orders';
import { index as ticketIndex } from '../../API/TechnicalTicket';
import { index as productSubFamilyIndex } from '../../API/ProductSubFamilies';
import {
  newItem as newBill
} from '../../API/Bills';

import Loading from '../Layout/Loading';

import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableBody from '../Table/TableBody';
import TableTextHeader from '../Table/Header/TableTextHeader';
import TableRow from '../Table/Body/TableRow';

import StringCell from '../Form/Field/TableCell/StringCell';
import IntegerCell from '../Form/Field/TableCell/IntegerCell';
import FloatCell from '../Form/Field/TableCell/FloatCell';
import PriceCell from '../Form/Field/TableCell/PriceCell';
import DropdownCell from '../Form/Field/TableCell/DropdownCell';
import TimeCell from '../Form/Field/TableCell/TimeCell';
import SwitchCell from '../Form/Field/TableCell/SwitchCell';

import {
  bills as route,
  technicalTicket as ticketsRoute
} from '../../MetaData/Route/routes';

// import BaseEntityTabs from '../EntityTabs/MyEntityTabs';

/**
 * BillCreator
 *
 * This component configure the entity tab component
 */
class BillCreator extends React.Component {

  constructor(props) {
    super(props);

    this.orderIndex = this.props.modalHandler.addVerificationWithCallback(orderIndex, this.postOrderWaitingBilling, this.postOrderWaitingBillingFailure);
    this.ticketIndex = this.props.modalHandler.addVerificationWithCallback(ticketIndex, this.postTicketWaitingBilling, this.postTicketWaitingBillingFailure);
    this.productSubFamilyIndex = this.props.modalHandler.addVerificationWithCallback(productSubFamilyIndex, this.postProductSubFamilyIndex, this.postProductSubFamilyIndexFailure);
    this.newBill = this.props.modalHandler.addVerificationWithCallback(newBill, this.postNewBill, this.postNewBillFailure);

    this.state = {
      loading: true,
      loadingGenerate: false,
      loadingGenerateError: false,

      orders: null,
      ordersError: false,
      tickets: null,
      ticketsError: false,
      productSubFamilies: null,
      productSubFamiliesError: false,
      freeLines: [],
    }
  }

  componentDidMount() {
    this.props.dispatch(addBreadcrumbAction(window.location.pathname, strings.bill.billCreator.breadcrumb));

    this.counter = new AsyncCounter();

    let orderBill = this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FROM_ORDER');
    let ticketBill = this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FROM_TICKET');
    let productSubFamilyBill = this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FREE_LINE');

    this.counter.reset();
    this.counter.setValue(((orderBill)?1:0) + ((ticketBill)?1:0) + ((productSubFamilyBill)?1:0));
    this.counter.subscribe(0, () => this.setState({ loading:false }));
    if(orderBill) {
      this.orderIndex(1, 0, {
        partner_id: this.props.match.params.clientId,
        state: 'o_d_i_progress',
        last_bill: 0
      }, 'ASC', 'waiting_billing');
    }
    if(ticketBill) {
      this.ticketIndex(1, 0, {
        client_account_id: this.props.match.params.clientId,
        state: 'closed',
        close_billing: 0
      }, 'ASC', 'waiting_billing');
    }
    if(productSubFamilyBill) {
      this.productSubFamilyIndex(1, 0);
    }
    if(!orderBill && !ticketBill && !productSubFamilyBill) {
      this.setState({ loading:false });
    }
  }

  postOrderWaitingBilling = (data) => {
    this.setState({
      orders: data.items,
    }, () => this.counter.decrement());
  }

  postOrderWaitingBillingFailure = (msg) => {
    this.setState({
      ordersError: true,
    }, () => this.counter.decrement());
  }

  postTicketWaitingBilling = (data) => {
    this.setState({
      tickets: data.items,
    }, () => this.counter.decrement())
  }

  postTicketWaitingBillingFailure = (msg) => {
    this.setState({
      ticketsError: true,
    }, () => this.counter.decrement());
  }

  postProductSubFamilyIndex = (data) => {
    this.setState({
      productSubFamilies: data.items,
    }, () => this.counter.decrement())
  }

  postProductSubFamilyIndexFailure = (msg) => {
    this.setState({
      productSubFamiliesError: true,
    }, () => this.counter.decrement());
  }

  postNewBill = (data) => {
    //TODO dispatch flashbag

    //TODO redirect to
    this.props.history.push(route.routes.index.createPath());
  }

  postNewBillFailure = (error) => {
    this.setState({
      loadingGenerate: false,
      loadingGenerateError: true
    })
  }

  //Order
  onSelectBillTypeOrder = (order, billType) => {
    let newOrder = Object.assign({}, order, {bill_type: billType});

    this.setState({
      orders: this.state.orders.map((order) => (order.number === newOrder.number)?newOrder:order)
    });
  }

  onChangeBillPercentageOrder = (order, billPercentage) => {

    let lastBill = order.lastBill;

    let maxPercentage = 100;
    maxPercentage -= order.cart["cart_items"][0]["bill_lines"].reduce((prev, curr) => prev + curr.quantity, 0);

    if(billPercentage < 0) billPercentage = 0;
    if(billPercentage >= maxPercentage) {
      billPercentage = null;
      lastBill = true;
    }

    let newOrder = Object.assign({}, order, {bill_percentage: billPercentage, last_bill: lastBill});

    this.setState({
      orders: this.state.orders.map((order) => (order.number === newOrder.number)?newOrder:order)
    });
  }

  //TODO change action when all lines are full
  onChangeBillQuantityOrder = (order, cartItemId, quantity) => {
    let cartItem = order.cart.cart_items[cartItemId];

    let maxQuantity = cartItem.quantity;
    maxQuantity -= cartItem["bill_lines"].reduce((prev, curr) => prev + curr.quantity, 0);

    if(quantity > maxQuantity) quantity = maxQuantity;

    let newCartItem = Object.assign({}, order.cart["cart_items"][cartItemId], {new_bill_line: (quantity > 0)?{ quantity: quantity }:null});

    let newOrder = Object.assign({}, order);

    newOrder.cart["cart_items"] = [...newOrder.cart.cart_items];
    newOrder.cart["cart_items"][cartItemId] = newCartItem;

    this.setState({
      orders: this.state.orders.map((order) => (order.number === newOrder.number)?newOrder:order)
    });
  }

  onChangeLastBillOrder = (order, lastBill) => {
    let newOrder = Object.assign({}, order, {last_bill: lastBill});

    this.setState({
      orders: this.state.orders.map((order) => (order.number === newOrder.number)?newOrder:order)
    });
  }

  //Ticket
  onChangeCloseBillingTicket = (ticket, closeBilling) => {
    let newTicket = Object.assign({}, ticket, {close_billing: closeBilling});

    this.setState({
      tickets: this.state.tickets.map((ticket) => (ticket.number === newTicket.number)?newTicket:ticket)
    });
  }

  onChangeTimeSlotBillTicket = (ticket, appointment, quantity, timeSlot) => {
    if(quantity < 0) {
      quantity = 0;
    }
    if(quantity > timeSlot.quantity) {
      quantity = timeSlot.quantity;
    }

    let newTimeSlots = [...appointment["time_slots"]];
    newTimeSlots[timeSlot.timeSlotId]["new_bill_line_quantity"] = quantity;

    let newAppointment = Object.assign({}, appointment, {time_slots: newTimeSlots});

    let newTicket = Object.assign({}, ticket, {appointments: ticket.appointments.map((appointment) => (appointment.number === newAppointment.number)?newAppointment:appointment)});

    this.setState({
      tickets: this.state.tickets.map((ticket) => (ticket.number === newTicket.number)?newTicket:ticket)
    });
  }

  onChangeZoneBillTicket = (ticket, appointment, zoneBill) => {

    let newAppointment = Object.assign({}, appointment, {new_bill_line_zone: zoneBill});

    let newTicket = Object.assign({}, ticket, {appointments: ticket.appointments.map((appointment) => (appointment.number === newAppointment.number)?newAppointment:appointment)});

    this.setState({
      tickets: this.state.tickets.map((ticket) => (ticket.number === newTicket.number)?newTicket:ticket)
    });
  }

  onChangeAppointmentProductQuantityTicket = (ticket, appointment, quantity, item) => {

    let product = appointment["products"][item.productId];

    let maxQuantity = product.quantity;

    if(quantity > maxQuantity) quantity = maxQuantity;

    let newProduct = Object.assign({}, appointment["products"][item.productId], { new_bill_line: (quantity > 0)?{ quantity: quantity }:null })

    let newAppointment = Object.assign({}, appointment);

    newAppointment["products"] = [...newAppointment["products"]];
    newAppointment["products"][item.productId] = newProduct;

    let newTicket = Object.assign({}, ticket, {appointments: ticket.appointments.map((appointment) => (appointment.number === newAppointment.number)?newAppointment:appointment)});

    this.setState({
      tickets: this.state.tickets.map((ticket) => (ticket.number === newTicket.number)?newTicket:ticket)
    });
  }

  //Free Line
  onChangeTitleFreeLine = (lineId, title) => {

    let newLine = Object.assign({}, this.state.freeLines[lineId], { title: title });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangePriceFreeLine = (lineId, price) => {

    let newLine = Object.assign({}, this.state.freeLines[lineId], { price: price });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangeQuantityFreeLine = (lineId, quantity) => {

    if(quantity < 0) {
      return;
    }

    let newLine = Object.assign({}, this.state.freeLines[lineId], { quantity: quantity });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangeDiscountFreeLine = (lineId, discount) => {

    let newLine = Object.assign({}, this.state.freeLines[lineId], { discount: discount });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangeDiscountUnitFreeLine = (lineId, discountUnit) => {

    let newLine = Object.assign({}, this.state.freeLines[lineId], { discount_unit: discountUnit });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangeProductSubFamilyFreeLine = (lineId, productSubFamilyId) => {

    let newLine;
    let productSubFamily = this.state.productSubFamilies.find(productSubFamily => productSubFamily["id"].toString() === productSubFamilyId);

    if(productSubFamily) {
      newLine = Object.assign({}, this.state.freeLines[lineId], { product_sub_family: productSubFamily, product_sub_family_name: undefined });
    }
    else {
      newLine = Object.assign({}, this.state.freeLines[lineId], { product_sub_family: undefined, product_sub_family_name: productSubFamilyId });
    }

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  addFreeLine = () => {

    this.setState({
      freeLines: [...this.state.freeLines, {
        discount_unit: '€'
      }]
    });
  }

  removeFreeLine = (lineId) => {
    this.setState({
      freeLines: [...this.state.freeLines.slice(0, lineId), ...this.state.freeLines.slice(lineId+1)]
    });
  }

  //bill generation
  generateBill = () => {

    this.setState({
      loadingGenerate: true
    }, () => {
      let new_bill_lines = [];
      let orders = [];
      let tickets = [];

      if(this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FROM_ORDER')) {
        this.state.orders.forEach((order, i) => {
          if(order["bill_type"] === '%') {
            // order["bill_percentage"]
            order["cart"]["cart_items"].forEach((cartItem, j) => {
              let amount = null;
              if(cartItem["discount_unit"] === '%') {
                amount = Math.round((cartItem["price"] * cartItem["quantity"] * (1 - cartItem["discount"] / 100))*100)/100;
              }
              else if(cartItem["discount_unit"] === '€') {
                amount = Math.round(((cartItem["price"] - cartItem["discount"]) * cartItem["quantity"])*100)/100;
              }
              new_bill_lines.push({
                title: cartItem["product"]["name"],
                amount: amount,
                quantity: order["bill_percentage"],
                discount: 0,
                discount_unit: cartItem["discount_unit"],
                origin: {
                  type: 'order_sell_p',
                  cart_item: {
                    id: cartItem["id"]
                  }
                }
              });
            });
          }
          else if (order["bill_type"] === 'Q') {
            order["cart"]["cart_items"].forEach((cartItem, j) => {
              if(cartItem["new_bill_line"]) {
                // cartItem["new_bill_line"]["quantity"]
                new_bill_lines.push({
                  title: cartItem["product"]["name"],
                  amount: cartItem["price"],
                  quantity: cartItem["new_bill_line"]["quantity"],
                  discount: cartItem["discount"],
                  discount_unit: cartItem["discount_unit"],
                  origin: {
                    type: 'order_sell_q',
                    cart_item: {
                      id: cartItem["id"]
                    }
                  }
                });
              }
            });
          }

          if(order["bill_type"] || order["last_bill"]) {
            orders.push({
              id: order["id"],
              bill_type: order["bill_type"],
              last_bill: order["last_bill"]
            });
          }
        });
      }

      if(this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FROM_TICKET')) {
        this.state.tickets.forEach((ticket, i) => {
          ticket["appointments"].forEach((appointment, j) => {
            appointment["time_slots"].forEach((timeSlot, k) => {
              if(timeSlot["new_bill_line_quantity"] && timeSlot["new_bill_line_quantity"] !== 0) {
                new_bill_lines.push({
                  title: `Main d'oeuvre${appointment["appointment_type"]["on_premise"]?"":" Demi"} ${timeSlot["timezone"]["name"]}`,
                  amount: timeSlot["timezone"]["price_per_hour"] * (appointment["appointment_type"]["on_premise"]?1:0.5),
                  quantity: timeSlot["new_bill_line_quantity"],
                  discount: 0,
                  discount_unit: '€',
                  origin: {
                    type: 'appointment_work',
                    appointment: {
                      id: appointment["id"]
                    }
                  }
                });
              }
            });
            if(appointment.new_bill_line_zone) {
              new_bill_lines.push({
                title: `Déplacement ${appointment["trip"]["zone"]["name"]}`,
                amount: appointment["trip"]["zone"]["price"],
                quantity: appointment["trip"]["quantity"], //TODO change 1 to multiply_quantity?quantity:1
                discount: 0,
                discount_unit: '€',
                origin: {
                  type: 'appointment_trip',
                  appointment: {
                    id: appointment["id"]
                  }
                }
              });
            }
            appointment["products"].forEach((product, j) => {
              if(product["new_bill_line"] && product["new_bill_line"].quantity) {
                new_bill_lines.push({
                  title: product.product.name,
                  amount: product.price,
                  quantity: product["new_bill_line"].quantity,
                  discount: 0,
                  discount_unit: '€',
                  origin: {
                    type: 'appointment_product',
                    appointment_product: {
                      id: product["id"]
                    }
                  }
                });
              }
            });
          });

          if(ticket["close_billing"]) {
            tickets.push({
              id: ticket["id"],
              close_billing: ticket["close_billing"]
            });
          }
        });
      }

      if(this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FREE_LINE')) {
        this.state.freeLines.forEach((freeLine, i) => {
          if(freeLine["product_sub_family"]
            && freeLine["title"]
            && !Number.isNaN(freeLine["price"])
            && (!Number.isNaN(freeLine["discount"]) || freeLine["discount"] === undefined)
            && !Number.isNaN(freeLine["quantity"])
          ) {
            new_bill_lines.push({
              title: freeLine["title"],
              amount: PriceCell.parseFinalValue(freeLine["price"]),
              quantity: freeLine["quantity"]?freeLine["quantity"]:0,
              discount: freeLine["discount"]?freeLine["discount"]:0,
              discount_unit: freeLine["discount_unit"],
              origin: {
                type: 'product_sub_family',
                product_sub_family: {
                  id: freeLine["product_sub_family"]["id"]
                }
              }
            });
          }
        });
      }

      let data = {
        item: {
          bill_lines: new_bill_lines,
          client: {
            id: this.props.match.params.clientId
          }
        },
        orders: orders,
        tickets: tickets
      }

      if(data.item.bill_lines.length > 0 || data.orders.length > 0 || data.tickets.length > 0) {
        this.newBill(data);
      }
      else {
        this.setState({
          loadingGenerate: false
        })
      }
    })
  }

  displayOrders() {
    if(!this.state.loading && !this.state.ordersError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FROM_ORDER')) {
      return (
        <Container>
          {this.state.orders
            .filter((order) => order["cart"]["cart_items"].some((cartItem) => cartItem["product"]["price_type"] === 0 && cartItem["rented"] === false))
            .map((order, i) => {
              return (
                <React.Fragment key={i}>
                  <Row><Col><h4>{order.number}</h4></Col></Row>
                  <Row>
                    <Col>
                      <Form.Check
                        id={`input-${order.number}`}
                        type="switch"
                        label="Facture complète"
                        disabled={false}
                        checked={order["last_bill"]?order["last_bill"]:false}
                        onChange={(event) => this.onChangeLastBillOrder(order, event.target.checked)}
                      />
                    </Col>
                  </Row>
                  {order["last_bill"]?null:<Row>
                    <Col xs="auto" className="d-flex align-items-center">Type de paiement : </Col>
                    <Col xs="auto">
                      <Form.Control
                        as="select"
                        type="text"
                        disabled={order.cart["cart_items"].reduce((prev, curr) => prev || curr["bill_lines"].length > 0, false)}
                        value={order["bill_type"]?order["bill_type"]:""}
                        onChange={(event) => this.onSelectBillTypeOrder(order, event.target.value)}
                        style={{width: "auto"}}>
                        <option value=""></option>
                        <option value="%" label="%"></option>
                        <option value="Q" label="Q"></option>
                      </Form.Control>
                    </Col>
                    {(order["bill_type"] === '%')?
                      <Col xs="auto">
                        <Form.Control
                          type="number"
                          value={order["bill_percentage"]?order["bill_percentage"]:0}
                          onChange={(event) => this.onChangeBillPercentageOrder(order, event.target.value)}
                        />
                      </Col>:
                      <div/>
                    }
                  </Row>}
                  {(!order["last_bill"] && order["bill_type"] === 'Q')?<Table
                    cellCollection={{
                      string: StringCell,
                      integer: IntegerCell,
                      float: FloatCell,
                      price: PriceCell,
                      dropdown: DropdownCell,
                    }}>
                    <TableHeader>
                      <TableTextHeader
                        value={(item) => item.product.name}
                        label="Produit"/>
                      <TableTextHeader
                        value={(item) => item.quantity}
                        label="Qté tot"/>
                      <TableTextHeader
                        value={(item) => item["bill_lines"].reduce((prev, curr) => prev + curr["bill_line"]["quantity"], 0)}
                        label="Qté facturée"/>
                      <TableTextHeader
                        value={(item) => item.quantity - item["bill_lines"].reduce((prev, curr) => prev + curr["bill_line"]["quantity"], 0)}
                        label="Qté restante"/>
                      <TableTextHeader
                        label="Qté à facturer"
                        type="integer"
                        value={(item) => item["new_bill_line"] && item["new_bill_line"].quantity?item["new_bill_line"].quantity:0}
                        additionalConfiguration={{
                          onChange: (rowId, valueId, quantity) => this.onChangeBillQuantityOrder(order, rowId, quantity),
                          editable: true
                        }}
                        />
                      <TableTextHeader
                        value={(item) => Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
                          item["new_bill_line"] && item["new_bill_line"].quantity
                            ?item["new_bill_line"].quantity *
                              (item["discount_unit"] === '€'
                                ?item["price"] - item["discount"]
                                :item["price"] * (1- item["discount"]/100)
                              )
                            :0
                        )}
                        label="S.T. (€ H.T.)"/>
                    </TableHeader>
                    <TableBody>
                      {order.cart["cart_items"].map((cartItem, j) => {
                        return (
                          <TableRow
                            key={j}
                            data={cartItem}
                          />
                        )
                      })}
                    </TableBody>
                  </Table>:null}
                </React.Fragment>
              )
            }
          )}
        </Container>
      );
    }
    else if(!this.state.loading && this.state.ordersError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FROM_ORDER')) {
      return (
        <Container>
          <div>{strings.bill.billCreator.ordersError}</div>
        </Container>
      )
    }
  }

  displayTickets() {
    if(!this.state.loading && !this.state.ticketsError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FROM_TICKET')) {
      return (
        <Container>
          {this.state.tickets.map((ticket, i) => {
            return (
              <React.Fragment key={i}>
                <Row><Col><h4><a href={ticketsRoute.routes.show.createPath(ticket.id)}>{ticket.number}</a></h4></Col></Row>
                <Row>
                  <Col>
                    <Form.Check
                      id={`close-billing-${ticket.number}`}
                      type="switch"
                      label="Cloturer la facturation"
                      disabled={false}
                      checked={ticket["close_billing"]?ticket["close_billing"]:false}
                      onChange={(event) => this.onChangeCloseBillingTicket(ticket, event.target.checked)}
                    />
                  </Col>
                </Row>
                {ticket.appointments?ticket.appointments.map((appointment, j) => {
                  return (
                    <React.Fragment key={j}>
                      <Row className="d-flex justify-content-between">
                        <Col><h4>{appointment.number} {appointment.bill_lines.length > 0?"(déjà facturé)":null}</h4></Col>
                        <Col xs="auto">{appointment.bill_lines.length === 0?`Estimé : ${appointment["time_slot_estimate"]}h, en date du : ${appointment["date_start"]}`:""}</Col>
                      </Row>
                      {appointment.bill_lines.length > 0?null:<Table
                        cellCollection={{
                          string: StringCell,
                          integer: IntegerCell,
                          float: FloatCell,
                          price: PriceCell,
                          dropdown: DropdownCell,
                          time: TimeCell,
                          switch: SwitchCell
                        }}>
                        <colgroup>
                          <col style={{width: '70%'}}/>{/*name*/}
                          <col style={{width: '10%'}}/>{/*price*/}
                          <col style={{width: '10%'}}/>{/*quantity*/}
                          <col style={{width: '10%'}}/>{/*to_bill*/}
                        </colgroup>
                        <TableHeader>
                          <TableTextHeader
                            value={(item) => {
                              switch (item.type) {
                                case 'time_slot':
                                  return item.name;
                                case 'zone':
                                  return item.name;
                                default:
                              }
                              return item.product.name;
                            }}
                            label="Nom du produit"/>
                          <TableTextHeader
                            value={(item) => Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(item.price)}
                            label="P.U. (€ H.T.)"/>
                          <TableTextHeader
                            value={(item) => item.quantity}
                            label="Quantité"/>
                          <TableTextHeader
                            label="A facturer"
                            value={(item) => {
                              switch (item.type) {
                                case 'time_slot':
                                  return item["newBillLineQuantity"]?item["newBillLineQuantity"]:0;
                                case 'zone':
                                  return appointment["new_bill_line_zone"]?appointment["new_bill_line_zone"]:"";
                                default:
                              }
                              return item["new_bill_line"] && item["new_bill_line"].quantity?item["new_bill_line"].quantity:0
                            }}
                            type={(item) => {
                              switch (item.type) {
                                case 'zone':
                                  return "switch";
                                default:
                              }
                              return "integer"
                            }}
                            additionalConfiguration={{
                              onChange: (rowId, valueId, value, item) => {
                                switch (item.type) {
                                  case 'time_slot':
                                    this.onChangeTimeSlotBillTicket(ticket, appointment, value, item);
                                    return;
                                  case 'zone':
                                    this.onChangeZoneBillTicket(ticket, appointment, value);
                                    return;
                                  default:
                                }
                                this.onChangeAppointmentProductQuantityTicket(ticket, appointment, value, item)
                              },
                              editable: true
                            }}
                            />
                        </TableHeader>
                        <TableBody>
                          {appointment["time_slots"].map((timeSlot, k) => {
                            return (
                              <TableRow
                                key={`time-slot-${k}`}
                                data={{
                                  type: 'time_slot',
                                  timeSlotId: k,
                                  name: `Main d'oeuvre ${timeSlot["timezone"]["name"]}${timeSlot["quantity_unit"] === "00:30"?" (demi-heure)":" (heure)"}`,
                                  price: timeSlot["timezone"]["price_per_hour"] * (appointment["appointment_type"]["on_premise"]?1:0.5),
                                  quantity: timeSlot["quantity"],
                                  newBillLineQuantity: timeSlot["new_bill_line_quantity"]
                                }}
                              />
                            )
                          }).concat(
                            [
                              (appointment["appointment_type"]["on_premise"]?
                                <TableRow
                                  key='zone'
                                  data={{
                                    type: 'zone',
                                    name: `Déplacement ${appointment["trip"]["zone"]["name"]}`,
                                    price: appointment["trip"]["zone"]["price"],
                                    quantity: `${appointment["trip"]["quantity"]}`
                                  }}
                                />
                              :undefined)
                            ],
                          appointment["products"].map((product, k) => {
                            return (
                              <TableRow
                                key={`product-${k}`}
                                data={Object.assign({ productId: k }, product)}
                              />
                            )
                          })).filter(line => line)}
                        </TableBody>
                      </Table>}
                    </React.Fragment>
                  )
                }):null}
              </React.Fragment>
            )
          })}
        </Container>
      );
    }
    else if(!this.state.loading && this.state.ticketsError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FROM_TICKET')) {
      return (
        <Container>
          <div>{strings.bill.billCreator.ticketsError}</div>
        </Container>
      )
    }
  }

  displayFreeLines() {
    if(!this.state.loading && !this.state.productSubFamiliesError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FREE_LINE')) {
      return (
        <Container>
          <Row>
            <Col xs="auto"><h2>Ligne(s) Libre(s)</h2></Col>
            <Col xs="auto" className="d-flex align-items-center"><Button variant="my-validated" className="button-form-row" onClick={() => this.addFreeLine()}>Ajout</Button></Col>
          </Row>
          {this.state.freeLines.length > 0?<Table
            cellCollection={{
              string: StringCell,
              integer: IntegerCell,
              float: FloatCell,
              price: PriceCell,
              dropdown: DropdownCell,
            }}>
            <colgroup>
              <col style={{width: '39%'}}/>{/*title*/}
              <col style={{width: '8%'}}/>{/*price*/}
              <col style={{width: '8%'}}/>{/*quantity*/}
              <col style={{width: '8%'}}/>{/*discount*/}
              <col style={{width: '6%'}}/>{/*discount_unit*/}
              <col style={{width: '16%'}}/>{/*productSubFamily*/}
              <col style={{width: '5%'}}/>{/*delete*/}
            </colgroup>
            <TableHeader>
              <TableTextHeader
                label="Titre"
                type="string"
                value={(item) => item["title"]?item["title"]:""}
                additionalConfiguration={{
                  onChange: (rowId, valueId, title) => this.onChangeTitleFreeLine(rowId, title),
                  placeholder: "Titre",
                  editable: true
                }}/>
              <TableTextHeader
                label="P.U. (€ H.T.)"
                type="price"
                value={(item) => item["price"]?item["price"]:0}
                additionalConfiguration={{
                  onChange: (rowId, valueId, title) => this.onChangePriceFreeLine(rowId, title),
                  editable: true
                }}/>
              <TableTextHeader
                type="integer"
                label="Quantité"
                value={(item) => item["quantity"]?item["quantity"]:0}
                additionalConfiguration={{
                  onChange: (rowId, valueId, quantity) => this.onChangeQuantityFreeLine(rowId, quantity),
                  editable: true
                }}/>
              <TableTextHeader
                type="float"
                label="Promotion"
                value={(item) => item["discount"]?item["discount"]:0}
                colSpan={2}
                additionalConfiguration={{
                  onChange: (rowId, valueId, quantity) => this.onChangeDiscountFreeLine(rowId, quantity),
                  editable: true
                }}/>
              <TableTextHeader
                value={(item, i) => {
                  return (
                    <Button variant="my-secondary-noline" onClick={(e) => this.onChangeDiscountUnitFreeLine(i, (item["discount_unit"] === '€')?'%':'€')}>{item["discount_unit"]}</Button>
                  )
                }}
                colSpan={0}/>
              <TableTextHeader
                value={(item) => item["product_sub_family"]?item["product_sub_family"]["name"]:(item["product_sub_family_name"]?item["product_sub_family_name"]:"")}
                type="dropdown"
                label="SF de produit"
                additionalConfiguration={{
                  onChange: (rowId, valueId, productSubFamilyId) => this.onChangeProductSubFamilyFreeLine(rowId, productSubFamilyId),
                  editable: true,
                  placeholder: "SF de produit",
                  fieldList: this.state.productSubFamilies.map((productSubFamily) => {
                    return {
                      label: `${productSubFamily["product_family"]["name"]} > ${productSubFamily["name"]}`,
                      value: productSubFamily.id
                    }
                  })
                }}/>
                <TableTextHeader
                  value={(item, i) => {
                    return (
                      <Button variant="my-secondary-noline" onClick={() => this.removeFreeLine(i)}><i className="icon-cross"/></Button>
                    )
                  }}
                  label=""/>

            </TableHeader>
            <TableBody>
              {this.state.freeLines.map((freeLine, i) => {
                return (
                  <TableRow
                    key={i}
                    data={freeLine}
                  />
                )
              })}
            </TableBody>
          </Table>:null}
        </Container>
      )
    }
    else if(!this.state.loading && this.state.productSubFamiliesError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FREE_LINE')) {
      return (
        <Container>
          <div>{strings.bill.billCreator.productSubFamiliesError}</div>
        </Container>
      )
    }
  }

  displayButton() {
    return (
      <Navbar fixed="bottom" className="sub-footer">
        <Container>
          <Col xs={12} className="d-flex justify-content-center text-center small-text">
            <Button variant="my-validated" className="btn-sub-footer" onClick={this.generateBill}><i className="icon-document-edit"/> Générer la facture</Button>
          </Col>
        </Container>
      </Navbar>
    )
  }

  displayError() {
    if(this.state.loadingGenerateError) {
      return (
        <Container>
          <div>{strings.bill.billCreator.billGenerateError}</div>
        </Container>
      )
    }
  }

  displayLoading() {
    if(this.state.loading || this.state.loadingGenerate) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <MainLayout>
        {this.displayLoading()}
        {this.displayError()}
        {this.displayOrders()}
        {this.displayTickets()}
        {this.displayFreeLines()}
        {this.displayButton()}
      </MainLayout>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(withModalHandler(BillCreator));
