import React from 'react';

import SubRouter from '../../Routing/SubRouter';

import Index from '../../Component/Configurator/ItemIndex';
import MyEntityTabs from '../../Component/Configurator/MyEntityTabs';
import NewItemForm from '../../Component/Configurator/NewItem/NewItemForm';
import NewItemProcess from '../../Component/Configurator/NewItem/NewItemProcess';
import Transition from '../../Component/Configurator/Transition';

import Error from '../../Component/Layout/Error';

export function createBaseRoutes(prefix, conf) {
  let routes = {
    prefix: `/${prefix}`,
    routes: {}
  };

  if(conf.index !== undefined) {
    routes.routes.index = {
      path: '/index',
      exact: false,
      createPath: () => `/${prefix}/index`,
      type: 'render',
      getRender: (props) => <Index {...props} conf={conf.index}/>,
    }
  }

  if(conf.show !== undefined) {
    routes.routes.show = {
      path: '/show/:id',
      exact: false,
      createPath: (id) => `/${prefix}/show/${id}`,
      type: 'render',
      getRender: (props) => <MyEntityTabs {...props} conf={conf.show}/>,
    }
  }

  if(conf.new !== undefined) {
    if(conf.new.type === undefined || conf.new.type === 'form') {
      routes.routes.new = {
        path: '/new',
        exact: false,
        createPath: () => `/${prefix}/new`,
        type: 'render',
        getRender: (props) => <NewItemForm {...props} conf={conf.new}/>,
      }
    }
    else if(conf.new.type === 'process') {
      routes.routes.new = {
        path: '/new',
        exact: false,
        createPath: () => `/${prefix}/new`,
        type: 'render',
        getRender: (props) => <NewItemProcess {...props} conf={conf.new}/>,
      }
    }
  }

  if(conf.transition !== undefined) {
    routes.routes.transition = {
      path: '/transition/:id/:transition',
      exact: false,
      createPath: (id, transition) => `/${prefix}/transition/${id}/${transition}`,
      type: 'render',
      getRender: (props) => <Transition {...props} conf={conf.transition}/>,
    }
  }

  if(conf.custom !== undefined) {
    Object.keys(conf.custom).forEach((key) => {
      routes.routes[key] = {
        path: conf.custom[key].path,
        exact: false,
        createPath: (id) => `/${prefix}${conf.custom[key].createPath(id)}`,
        type: conf.custom[key].type,
        getRender: (props) => conf.custom[key].getRender(props),
      }
    });
  }

  routes.routes.error = {
    path: '/*',
    exact: false,
    createPath: () => '/',
    type: 'component',
    getComponent: () => Error,
  }

  return routes;
}

export function createBaseRoute(prefix, routes) {
  return {
    path: `/${prefix}`,
    exact: false,
    createPath: () => `/${prefix}`,
    type: 'render',
    getRender: (props) => <SubRouter {...props} conf={routes}/>,
    routes: routes.routes
  }
}

export function batchRouting(routing) {
  let routes = {};

  Object.keys(routing).forEach((key) => {
    let route = {};
    let prefix = key;
    if(routing[key].prefix) {
      prefix = routing[key].prefix;
    }
    route = createBaseRoute(prefix, createBaseRoutes(prefix, routing[key]));
    routes[key] = route;
  });

  return routes;
}
