
import { pingChartOption } from './centreon/ping';
import { networkChartOption } from './centreon/network';

export const chartOption = (metric, theme = 'dark', fontSize = 15, additionalOption = {}) => {
  if(metric.template) {
    switch (metric.template.name) {
      case 'stpl_os_linux_snmp_network':
        return networkChartOption(metric, theme, fontSize, additionalOption)
      case 'Ping-Lan-service':
        return pingChartOption(metric, theme, fontSize, additionalOption)
      default:
    }
  }
  return;
}
