const ticketTimeZone = {
  tab: {
    main: "Zone",
    time: "Horaire"
  },
  search: {
    name: "Nom",
  },
  new: {
    title: "Nouvelle zone temporelle"
  }
};

export default ticketTimeZone;
