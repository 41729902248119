const parameter = {
  name: {
    label: "Nom",
    invalid: {}
  },
  value: {
    label: "Valeur",
    invalid: {}
  },
};

export default parameter;
