import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import { fetchPartners } from '../../API/Partners';
import { fetchPartnerAccounts } from '../../API/PartnerAccounts';
import { fetchPlaces } from '../../API/Places';

import {
  form as _cartForm,
  clientRefererForm as _cartClientRefererForm,
} from './carts';

import { identificationForm as _orderForm } from './quoteOrders';

import {
  quotes as quotesRoutes,
  partners as partnersRoutes,
  places as placesRoutes
} from '../Route/routes';


let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.quote.number.label,
  validators: []
}

let numberWithLink = {
  fieldKey: "number",
  type: "text",
  label: strings.form.quote.number.label,

  disabledLink: (value, getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    if(credentials && credentials.roles.includes("ROLE_QUOTE")) {
      return getValues(0) && getValues(0)["id"]?quotesRoutes.routes.show.createPath(getValues(0)["id"]):null;
    }
    return false;
  },

  validators: []
}

let signatureDate = {
  fieldKey: "signature_date",
  type: "date",
  label: strings.form.quote.signatureDate.label,
  validators: []
}

let quoteFile = {
  fieldKey: "quote_file",
  type: "file",
  label: strings.form.quote.quoteFile.label,
  validators: []
}

let validationFile = {
  fieldKey: "validation_file",
  type: "file",
  label: strings.form.quote.validationFile.label,
  validators: []
}

let validated = {
  fieldKey: "validated",
  type: "switch",
  label: strings.form.quote.validated.label,
  validators: []
}

let rightToCancellation = {
  fieldKey: "right_to_cancellation",
  type: "switch",
  label: strings.form.quote.rightToCancellation.label,
  showOnDisabled: (getValues) => getValues()["cart"]["state"] === "q_validated" || getValues()["cart"]["state"] === "q_w_validation",
  validators: []
}

let client = {
  fieldKey: "client",
  type: "dropdownEntity",
  label: strings.form.quote.client.label,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_USER') || credentials.roles.includes('ROLE_NYUKOM'))) {
        return partnersRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "clients",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let clientAccount = {
  fieldKey: "client_account",
  type: "dropdownEntity",
  label: strings.form.quote.clientAccount.label,
  validators: [],

  loader: {
    id: "clientAccounts",
    loader: fetchPartnerAccounts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["partner_type"] && data["partner_type"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["partner_type"]["name"],
  },
}

let place = {
  fieldKey: "place",
  type: "dropdownEntity",
  label: strings.form.quote.place.label,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_USER') || credentials.roles.includes('ROLE_NYUKOM'))) {
        return placesRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "places",
    loader: fetchPlaces,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let cartClientReferer = {
  fieldKey: "cart",
  type: "subform",
  label: "",

  subForm: _cartClientRefererForm,

  validators: []
}

let cart = {
  fieldKey: "cart",
  type: "subform",
  label: strings.form.order.cartItems.label,

  subForm: _cartForm,

  validators: []
}

let orders = {
  fieldKey: "orders",
  type: "array",
  label: "",

  validators: [],

  subField: {
    type: "subform",
    label: strings.form.quote.orders.label,

    validators: [],

    subForm: _orderForm,
  }
}

export const cartForm = {
  formKey: "quote",
  fields: [
    cart,
  ]
}

export const numberForm = {
  formKey: "quote",
  fields: [
    numberWithLink
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const orderForm = {
  formKey: "quote",
  fields: [
    orders,
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const form = {
  formKey: "quote",
  fields: [
    number,
    signatureDate,
    quoteFile,
    validationFile,
    validated,
    rightToCancellation,
    client,
    clientAccount,
    place,
    cartClientReferer
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
