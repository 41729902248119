import {
  // index as baseIndex,
  getFromId as baseGetFromId,
  // editFromId as baseEditFromId,
  // newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single ticket based on his ID
 *
 * @param id the of the ticket requested
 */
export function getYousignFromId(id) {
  return baseGetFromId('appointments', id, 'yousign');
}
