import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getIdentificationFromId,
  editFromId,
  newItem
} from '../../API/TicketTimeZone';
import { form, newForm } from '../Form/ticketTimeZones';
import { form as searchForm } from '../Form/Search/ticketTimeZones';

import { ticketTimeZone as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.ticketTimeZone,

    id: "ticketTimeZone",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_ADMIN'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => item["name"]
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.ticketTimeZone,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getIdentificationFromId,

    extendedHeader: false,

    onUpdateItem: (data) => {},

    tabs: {
      main: {
        title: strings.ticketTimeZone.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_TICKET_TIME_ZONE'],
        editable: ['ROLE_TICKET_TIME_ZONE']
      },
    }
  },
  new: {
    pageTitle: strings.ticketTimeZone.new.title,

    api: newItem,
    empty: {},
    form: newForm,
    onSubmit: (item) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
