import strings from '../../Localization/Localization';

import { fetchPhonePackages } from '../../API/PhonePackages';
import { phonePackage as phonePackagesRoutes } from '../Route/routes';

let canalQuantity = {
  fieldKey: "canal_quantity",
  type: "text",
  label: strings.form.phonePackageProduct.canalQuantity.label,
  validators: []
}

let phonePackage = {
  fieldKey: "phone_package",
  type: "dropdownEntity",
  label: strings.form.phonePackageProduct.phonePackage.label,

  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && credentials.roles.includes('ROLE_CDR')) {
        return phonePackagesRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  showEmpty: true,
  loader: {
    id: "phone_packages",
    loader: fetchPhonePackages,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}


export const form = {
  formKey: "phonePackageProduct",
  fields: [
    canalQuantity,
    phonePackage
  ]
}
