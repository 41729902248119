export const bill_list = {
  id: "bill_list-1",
  default: true,
  roles: ["ROLE_ACCOUNTING_BILL"],

  title: "Factures",
  type: "list",
  conf: {
    element: "bill",
    headers: [
      "number",
      "date",
      "price"
    ],
    filters: {},
    order: "DESC"
  },
  column: 3,
  row: 4
}
