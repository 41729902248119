import strings from '../../Localization/Localization';

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.accountAnalytic.number.label,
  validators: []
}

export const form = {
  formKey: "accountAnalytic",
  fields: [
    number
  ]
}
