export const technical_centreon = {
  id: "technical_centreon-1",
  default: true,
  roles: ["ROLE_CENTREON"],

  type: "supervision",
  conf:{
    element: "service",
    chartType: "bar",
  },
  column: 3,
  row: 2,
  title: "Débit internet"
}
