import React from 'react';

import strings from '../../../Localization/Localization';
import { technicalTicket, commercialTicket, administrativeTicket } from '../../../MetaData/Route/routes';

import BasicNotification from './BasicNotification';

class TicketAssignNyukom extends BasicNotification {

  displayMessage() {
    let path = '#';
    switch (this.props.item._arguments["%ticket_group%"]) {
      case 1:
        path = commercialTicket.routes.show.createPath(this.props.item._arguments["%ticket_id%"]);
        break;
      case 2:
        path = administrativeTicket.routes.show.createPath(this.props.item._arguments["%ticket_id%"]);
        break;
      case 0:
      default:
        path = technicalTicket.routes.show.createPath(this.props.item._arguments["%ticket_id%"]);
    }
    return (
      <a href={path}>{strings.formatString(strings.common.notification.ticket_assign_nyukom, this.props.item._arguments["%ticket_number%"])}</a>
    )
  }
}

export default TicketAssignNyukom;
