import BaseAssert from './BaseAssert';

class PasswordEqualAssert extends BaseAssert {

  assert() {
    return (this.getValue() !== undefined && this.getExtraData().repeatField !== null)?this.getValue() === this.getExtraData().repeatField:null;
  }
}

export default PasswordEqualAssert;
