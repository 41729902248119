import {
  request
 } from './Base';

/*
import { store } from '../Store/configureStore';

import { config } from './config';
*/

/**
 * Get the user authentified
 */
export function getProfil() {
  return request('/users/profil', "GET");
}

/**
 * Get the user authentified
 */
export function getProfilIndex() {
  return request('/users/profil/index', "GET");
}

/**
 * Get the user authentified
 */
export function getProfilPassword() {
  return request('/users/profil/password', "GET");
}

/**
 * Get the user authentified
 */
export function getProfilProfils() {
  return request('/users/profil/profils', "GET");
}

/**
 * Get a single user based on his ID
 *
 * @param id the of the user requested
 */
export function getProfilPartners(id) {
  return request('/users/profil/partners', "GET");
}

/**
 * Get the user authentified
 */
export function getProfilUserProfilModification() {
  return request('/users/profil/user_profil_modification', "GET");
}

/**
 * Get the user authentified
 */
export function getProfilNotification() {
  return request('/users/profil/notification', "GET");
}

/**
 * Edit the user authentified
 *
 * @param id the id of the user authentified
 * @param data the new data of the user
 */
export function editProfil(id, data) {
  return request('/users/profil', "POST", {item: data});
}

/**
 * Edit the user authentified
 *
 * @param id the id of the user authentified
 * @param data the new data of the user
 */
export function editProfilPassword(id, data) {
  return request('/users/profil/password', "POST", {item: data});
}

/**
 * Edit the user authentified
 *
 * @param id the id of the user authentified
 * @param data the new data of the user
 */
export function editProfilNotification(id, data) {
  return request('/users/profil/notification', "POST", {item: data});
}
