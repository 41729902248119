const amendment = {
  oldQuantity: {
    label: "Quantité actuelle"
  },
  newQuantity: {
    label: "Nouvelle quantité",
    invalid: {
      assertFormat: "Quantité incorrect."
    }
  },
  quantity: {
    label: "Quantité à commander",
    invalid: {
      assertFormat: "Quantité incorrect."
    }
  },
  oldPlace: {
    label: "Site actuel"
  },
  place: {
    label: "Site"
  },
  oldPrice: {
    label: "Tarif actuel"
  },
  price: {
    label: "Nouveau tarif"
  },
  type: {
    label: "Type",
    options: {
      s: "Avenant au service",
      sAlt: "Service",
      c: "Avenant au contrat",
      cAlt: "Contrat"
    }
  },
  date: {
    label: "Date"
  }
};

export default amendment;
