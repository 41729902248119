const cartItem = {
  apiCdrFlex: {
    label: "Flex",
  },
  apiKeyFlex: {
    label: "Clé API",
  },
  apiCdrAlphalink: {
    label: "Alphalink",
  },
  apiKeyAlphalink: {
    label: "Clé API",
  },
  apiOriginCdrAlphalink: {
    label: "Origin CDR",
    options: {
      alphalink: "Alphalink",
      orange: "Orange",
    }
  },
  apiCdrFile: {
    label: "Autre",
  },
  apiKeyFile: {
    label: "Clé API",
  },
};

export default cartItem;
