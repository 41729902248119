import React from 'react';

//import 'holderjs/holder.js';

import './css/app.css';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './Store/configureStore';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Theme from './Component/Theme/Theme';

import ModalDisplayer from './Component/Layout/ModalDisplayer';

import { main } from './MetaData/Route/routes';
import Router from './Routing/MainRouter';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <DndProvider backend={HTML5Backend}>
          <Theme>
            <ModalDisplayer>
              <Router conf={main}/>
            </ModalDisplayer>
          </Theme>
        </DndProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
