import React from 'react';

/**
 * Tabs
 */
class Tab extends React.Component {

  componentDidMount() {
    //console.log(this.props.eventKey+" mounted !");
  }

  componentWillUnmount() {
    //console.log(this.props.eventKey+" unmounted !");
  }

  /**
   * Main render method for React Component
   */
  render() {
    if(this.props.active && this.props.children !== undefined) {
      return this.props.children
    }
    return null;
  }
}

export default Tab;
