import {
  MODIFY_SEARCH,
  REMOVE_SEARCH,
  RESET_SEARCH,
  MODIFY_ITEM_PER_PAGE_SEARCH,
  MODIFY_ORDER_SEARCH,
  MODIFY_DISPLAY_TYPE_SEARCH
} from '../Action/search'

const initialState = {}

export default function reduce(state = initialState, action){
  let newState;
  switch (action.type) {
    case MODIFY_SEARCH:
      newState = Object.assign({}, state);
      if(newState[action.index]) {
        newState[action.index] = Object.assign({}, {
          search: Object.assign({}, action.fields),
          itemPerPage: newState[action.index].itemPerPage,
          order: newState[action.index].order,
          displayType: newState[action.index].displayType
        });
      }
      else {
        newState[action.index] = Object.assign({}, {
          search: Object.assign({}, action.fields),
        });
      }
      break;
    case REMOVE_SEARCH:
      newState = Object.assign({}, state);
      if(newState[action.index]) {
        newState[action.index] = Object.assign({}, {
          search: {...newState[action.index].search},
          itemPerPage: newState[action.index].itemPerPage,
          order: newState[action.index].order,
          displayType: newState[action.index].displayType
        });
        delete newState[action.index].search[action.fieldKey];
      }
      break;
    case RESET_SEARCH:
      newState = Object.assign({}, state);
      if(newState[action.index]) {
        newState[action.index] = Object.assign({}, {
          search: [],
          itemPerPage: newState[action.index].itemPerPage,
          order: newState[action.index].order,
          displayType: newState[action.index].displayType
        });
      }
      break;
    case MODIFY_ITEM_PER_PAGE_SEARCH:
      newState = Object.assign({}, state);
      if(newState[action.index]) {
        newState[action.index] = Object.assign({}, {
          search: newState[action.index].search,
          itemPerPage: action.itemPerPage,
          order: newState[action.index].order,
          displayType: newState[action.index].displayType
        });
      }
      else {
        newState[action.index] = Object.assign({}, {
          itemPerPage: action.itemPerPage
        });
      }
      break;
    case MODIFY_ORDER_SEARCH:
      newState = Object.assign({}, state);
      if(newState[action.index]) {
        newState[action.index] = Object.assign({}, {
          search: newState[action.index].search,
          itemPerPage: newState[action.index].itemPerPage,
          order: action.order,
          displayType: newState[action.index].displayType
        });
      }
      else {
        newState[action.index] = Object.assign({}, {
          order: action.order,
        });
      }
      break;
    case MODIFY_DISPLAY_TYPE_SEARCH:
      newState = Object.assign({}, state);
      if(newState[action.index]) {
        newState[action.index] = Object.assign({}, {
          search: newState[action.index].search,
          itemPerPage: newState[action.index].itemPerPage,
          order: newState[action.index].order,
          displayType: action.displayType
        });
      }
      else {
        newState[action.index] = Object.assign({}, {
          displayType: action.displayType
        });
      }
      break;
    default:
      newState = state;
  }

  return newState;
}
