import React from 'react';

/**
 * TableRow
 */
class TableRow extends React.Component {

  static defaultProps = {
    className: "row-my-primary",

    subRows: false
  }

  displayHandle() {
    if(this.props.subRows) {
      return <td/>
    }
  }

  displayMain() {
    switch (this.props.as) {
      case 'button':
        return this.displayAsButton();
      case 'a':
        return this.displayAsA();
      default:
        return this.displayDefault();
    }
  }

  displayAsButton() {
    if(this.props.as === 'button') {
      return (
        <tr
          className={this.props.className}
          onClick={() => this.props.onClickItem(this.props.data.id, this.props.data)}>
          {this.displayHandle()}
          {this.props.children}
        </tr>
      )
    }
  }

  displayAsA() {
    if(this.props.as === 'a') {
      return (
        <tr
          className={this.props.className}>
          {this.displayHandle()}
          {this.props.children
            .map((child) => {
              return React.cloneElement(child, {
                href: this.props.entityPath(this.props.data.id, this.props.data)
              })
            })
          }
        </tr>
      )
    }
  }

  displayDefault() {
    return (
      <tr className={this.props.className}>
        {this.displayHandle()}
        {this.props.children}
      </tr>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayMain()}
      </>
    );
  }
}

export default TableRow;
