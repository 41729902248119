import React from 'react';

import { Row, Col, Button } from 'react-bootstrap';

import OneRowText from '../../Basic/OneRowText';

import '../../../css/dashboard/basic_widget.css';

/**
 * WidgetColor
 */
class BasicWidget extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      arguments: {},
    }
  }

  getArgumentFromName = (name) => {
    let result = this.props.conf;
    name.split('.').every((key) => {
      if(typeof result === 'object' && result[key]) {
        result = result[key];
      }
      else {
        result = "";
        return false;
      }
      return true;
    });
    return result;
  }

  postChangeArgument() {}

  onChangeArgument = (key, value) => {
    let newArguments = Object.assign({}, this.state.arguments);
    newArguments[key] = value;
    this.setState({
      arguments: newArguments
    }, () => {
      this.postChangeArgument()
    })
  }

  evaluateArgument = (name) => {
    let argument = this.getArgumentFromName(name);
    if(typeof argument === 'object' && argument._parameterType) {
      switch (argument._parameterType) {
        case "value":
          return argument.value;
        case "parameterList":
          if(this.state.arguments) {
            return this.state.arguments[name]?this.state.arguments[name]:argument.value[0];
          }
          return "";
        case "parameterBag":
          if(argument && this.props.parameterBag && this.props.parameterBag[argument.value]) {
            return this.props.parameterBag[argument.value];
          }
          return "";
        case "userSelf":
          if(this.props.credentials) {
            return this.props.credentials;
          }
          return "";
        default:

      }
    }
    return argument;
  }

  displayArguments() {
    if(this.props.conf) {
      let result = [];
      let iterator = Object.keys(this.props.conf);
      while(iterator[0]) {
        let argument = this.getArgumentFromName(iterator[0]);
        if(typeof argument === 'object' && argument._parameterType === "parameterList") {
          result.push(iterator[0]);
        }
        else if(typeof argument === 'object') {
          Object.keys(argument).forEach((key) => {
            iterator.push(iterator[0]+'.'+key);
          });
        }
        iterator.splice(0, 1);
      }
      return (
        <div>
          {result
            .map((key, i) => {
              let argument = this.getArgumentFromName(key);
              if(!argument || !argument.value) {
                return <div key={i}/>
              }
              return (
                <div key={i} className="d-flex flex-row justify-content-center dashboard-widget-argument">
                  {argument.value
                    .filter(value => !!value)
                    .map((choice, j) => {
                      let label = choice;
                      if(typeof choice === 'object') {
                        label = "Pas de label";
                      }
                      if(argument._title) {
                        if(Array.isArray(argument._title)) {
                          label = argument._title[j];
                        }
                        else {
                          label = argument._title;
                        }
                      }
                      return (
                        <Button key={j} variant="my-secondary-outline" className={`dashboard-widget-argument-button${(this.state.arguments && choice === this.state.arguments[key])?" dashboard-widget-argument-button-selected":""}`} onClick={() => this.onChangeArgument(key, choice)}>{label}</Button>
                      )
                    }
                  )}
                </div>
              )
            })
          }
        </div>
      )
    }
  }

  displayAdditionalTitleButtons() {}

  displayTitle() {
    return (
      <Row className="d-flex justify-content-between align-items-center dashboard-widget-header">
        <Col style={{maxWidth:"75%"}}>
          <OneRowText noGutters text={this.props.title}/>
        </Col>
        <Col xs="auto" className="no-gutters">
          {this.displayAdditionalTitleButtons()}
          <Button variant="my-secondary-noline" onClick={this.props.startEditConf}><i className="icon-gear"/></Button>
        </Col>
      </Row>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    console.warn("You have used a component (BaseWidget) that shouldn't be used as is.");
    return null;
  }
}

export default BasicWidget;
