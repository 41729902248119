import strings from '../../Localization/Localization';

import { fetchCommunicationTypes } from '../../API/PhoneCommunicationTypes';
import { fetchPhoneZones } from '../../API/PhoneZones';
import { fetchPhoneZoneGroups } from '../../API/PhoneZoneGroups';
import { fetchPhonePrices } from '../../API/PhonePrices';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.phonePrice.name.label,
  validators: []
}

let price = {
  fieldKey: "price",
  type: "price",
  label: strings.form.phonePrice.price.label,
  validators: []
}

let servicePrice = {
  fieldKey: "service_price",
  type: "price",
  label: strings.form.phonePrice.servicePrice.label,
  validators: []
}

let inout = {
  fieldKey: "inout",
  type: "dropdown",
  label: strings.form.phonePrice.inout.label,
  validators: [],

  input: 'options',
  showEmpty: true,
  options: [
    {
      value: 0,
      label: strings.form.phonePrice.inout.options.false
    },
    {
      value: 1,
      label: strings.form.phonePrice.inout.options.true
    }
  ]
}

let roaming = {
  fieldKey: "roaming",
  type: "dropdown",
  label: strings.form.phonePrice.roaming.label,
  validators: [],

  input: 'options',
  showEmpty: true,
  options: [
    {
      value: 0,
      label: strings.form.phonePrice.roaming.options.false
    },
    {
      value: 1,
      label: strings.form.phonePrice.roaming.options.true
    }
  ]
}

let roamingLikeAtHome = {
  fieldKey: "roaming_like_at_home",
  type: "dropdown",
  label: strings.form.phonePrice.roamingLikeAtHome.label,
  validators: [],

  input: 'options',
  showEmpty: true,
  options: [
    {
      value: 0,
      label: strings.form.phonePrice.roamingLikeAtHome.options.false
    },
    {
      value: 1,
      label: strings.form.phonePrice.roamingLikeAtHome.options.true
    }
  ]
}

let applicableFrom = {
  fieldKey: "applicable_from",
  type: "date",
  label: strings.form.phonePrice.applicableFrom.label,
  validators: []
}

let applicableUntil = {
  fieldKey: "applicable_until",
  type: "date",
  label: strings.form.phonePrice.applicableUntil.label,
  validators: []
}

let communicationType = {
  fieldKey: "communication_type",
  type: "dropdownEntity",
  label: strings.form.phonePrice.communicationType.label,
  validators: [],

  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "communicationType",
    loader: fetchCommunicationTypes,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let phoneZoneSources = {
  fieldKey: "phone_zone_sources",
  type: "dropdownMultiEntityAlt",
  label: strings.form.phonePrice.phoneZoneSources.label,
  validators: [],

  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "phoneZone",
    loader: fetchPhoneZones,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["label"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["label"],
  },
}

let phoneZoneDestinations = {
  fieldKey: "phone_zone_destinations",
  type: "dropdownMultiEntityAlt",
  label: strings.form.phonePrice.phoneZoneDestinations.label,
  validators: [],

  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "phoneZone",
    loader: fetchPhoneZones,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["label"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["label"],
  },
}

let phoneZoneGroupSources = {
  fieldKey: "phone_zone_group_sources",
  type: "dropdownMultiEntityAlt",
  label: strings.form.phonePrice.phoneZoneGroupSources.label,
  validators: [],

  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "phoneZoneGroup",
    loader: fetchPhoneZoneGroups,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["label"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["label"],
  },
}

let phoneZoneGroupDestinations = {
  fieldKey: "phone_zone_group_destinations",
  type: "dropdownMultiEntityAlt",
  label: strings.form.phonePrice.phoneZoneGroupDestinations.label,
  validators: [],

  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "phoneZoneGroup",
    loader: fetchPhoneZoneGroups,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["label"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["label"],
  },
}

let basePhonePrices = {
  fieldKey: "base_phone_prices",
  type: "dropdownMultiEntityAlt",
  label: strings.form.phonePrice.basePhonePrices.label,
  validators: [],

  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "phonePrice",
    loader: fetchPhonePrices,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },
  filterOptions: (value, getValues, getExtraValues) => {
    let values = getValues(0);
    if(values["communication_type"] && value["communication_type"]["id"] === values["communication_type"]["id"]
      && values["inout"] && Number(value["inout"]) === Number(values["inout"])
      && values["roaming"] && Number(value["roaming"]) === Number(values["roaming"])
      && values["roaming_like_at_home"] && Number(value["roaming_like_at_home"]) === Number(values["roaming_like_at_home"])
    ) {
      return true;
    }
    return false;
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

export const form = {
  formKey: "phonePrice",
  fields: [
    name,
    price,
    servicePrice,
    inout,
    roaming,
    roamingLikeAtHome,
    applicableFrom,
    applicableUntil,
    communicationType,
    phoneZoneSources,
    phoneZoneDestinations,
    phoneZoneGroupSources,
    phoneZoneGroupDestinations,
    basePhonePrices
  ]
}
