export const technical_service_list = {
  id: "technical_service_list-1",
  default: true,
  roles: [],

  title: "Services",
  type: "list",
  conf: {
    element: "service",
    headers: [
      "state",
      "order",
      "place",
      "product",
      "number"
    ],
    filters: {
      orderNumber: null,
      quoteNumber: null,
      productName: null,
      state: {
        _parameterType: "value",
        _title: "",
        value: [
          {
            value: "active",
            label: "Actif"
          },
          {
            value: "termination",
            label: "En cours de résiliation"
          }
        ]
      }
    }
  },
  column: 3,
  row: 2
}
