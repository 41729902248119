import React from 'react';

import TableCell from '../TableCell';

import Loading from '../../../Layout/Loading';

/**
 * LoadingCell
 */
class LoadingCell extends TableCell {

  displayInnerCell() {
    return <Loading container="parent" size="small"/>;
  }
}

export default LoadingCell;
