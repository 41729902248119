import CallbackCounter from './CallbackCounter';

/**
 * AsyncCounter
 *
 * An asynchrone counter
 *
 * Use callbacks to fire notification when the wanted value is reached
 */
export default class AsyncCounter extends CallbackCounter {

  /**
   * reset the value of the counter
   * reset the list of callback of the counter
   * reset the promise
   */
  reset() {
    super.reset();
    this.counter = new Promise((resolve, reject) => resolve());
  }

  /**
   * use an asynchrone function to set the value
   */
  setValue(value) {
    this.counter.then(() => {
      super.setValue(value);
    })
  }

  /**
   * @warn do not use this function, the value return by this function may not
   * be the real value
   */
  getValue() {
    console.warn("You are trying to access the value of an AsyncCounter, this value may be false, you should try to subscribe to a value instead.");
    return super.getValue();
  }

  /**
   * use an asynchrone function to increment the value
   */
  increment() {
    this.counter.then(() => {
      super.increment();
    })
  }

  /**
   * use an asynchrone function to decrement the value
   */
  decrement() {
    this.counter.then(() => {
      super.decrement();
    })
  }
}
