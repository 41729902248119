import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of vat accounts based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('account_vats', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single vat account based on his ID
 *
 * @param id the of the vat account requested
 */
export function getFromId(id) {
  return baseGetFromId('account_vats', id);
}

/**
 * Get a single vat account based on his ID
 *
 * @param id the of the vat account requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('account_vats', id, 'identification');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single vat account based on his ID
 *
 * @param id the of the vat account requested
 * @param data the new data of the vat account
 */
export function editFromId(id, data) {
  return baseEditFromId('account_vats', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new vat account
 *
 * @param data the new data of the vat account
 */
export function newItem(data) {
  return baseNewItem('account_vats', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all vat accounts to serve in a dropdown form choice
 */
export function fetchAccountVats() {
  return baseIndex('account_vats', 1, 0);
}
