import {
  index as baseIndex
 } from './Base';

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all communication types to serve in a dropdown form choice
 */
export function fetchCommunicationTypes() {
  return baseIndex('phone_communication_types', 1, 0);
}
