import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, InputGroup, Col } from 'react-bootstrap';
import '../../../css/form.css';

/**
 * FormFieldPhoneNumber
 *
 * This class handles HTML form field.
 *
 * The particular field is for phone number, when the field is disabled, it
 * shows a link to directly make a call.
 *
 * Specific configuration attribute : none
 *
 * List of subField :
 *  - prefix
 *  - number
 */
class FormFieldPhoneNumber extends BasicFormField {

  static subField = {
    prefix: 'prefix',
    number: 'number'
  }

  /**
   * @inheritdoc
   */
  getValue() {
    let value = super.getValue();
    if(!value) {
      value = {};
      value[FormFieldPhoneNumber.subField.prefix] = "";
      value[FormFieldPhoneNumber.subField.number] = "";
    }
    if(!value[FormFieldPhoneNumber.subField.prefix]) {
      value[FormFieldPhoneNumber.subField.prefix] = "";
    }
    if(!value[FormFieldPhoneNumber.subField.number]) {
      value[FormFieldPhoneNumber.subField.number] = "";
    }
    return value;
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    let value = super.getFinalValue();
    if(value && !value[FormFieldPhoneNumber.subField.prefix]) {
      value[FormFieldPhoneNumber.subField.prefix] = "";
    }
    if(value && !value[FormFieldPhoneNumber.subField.number]) {
      value[FormFieldPhoneNumber.subField.number] = "";
    }
    return value;
  }

  /**
   * @inheritdoc
   */
  getSpecificValidators() {
    return [];
    // return [
    //   {
    //     id: 'assertFormat',
    //     type: 'regex',
    //     conf: {
    //       regex: /^[0-9.\s-]*$/,
    //       subField: FormFieldPhoneNumber.subField.number
    //     },
    //     invalidFeedback: strings.form.default.field.phoneNumber.invalid.assertFormat,
    //     defaultValue: true,
    //     target: FormFieldPhoneNumber.subField.number
    //   },
    // ];
  }

  /**
   * Send new value to parent element
   */
  onChange = (value, type) => {
    //remove all none number characters
    value = value.replace(/[^\d]+/gm, '');
    let fieldVal;
    fieldVal = Object.assign({}, this.props.value);
    fieldVal[type] = value;
    super.onChange(fieldVal);
  }

  isEmpty() {
    return !(this.getValue()[FormFieldPhoneNumber.subField.prefix] && this.getValue()[FormFieldPhoneNumber.subField.number]);
  }

  getFinalPhoneNumber() {
    return `+${this.getValue()[FormFieldPhoneNumber.subField.prefix]+this.getValue()[FormFieldPhoneNumber.subField.number]}`;
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          {!this.props.disabled?
            <>
              <Form.Control
                as="select"
                className="d-flex col-4 col-md-2 col-border-radius col-md-border-radius"
                disabled={this.props.disabled || this.props.submitting}
                value={this.getValue()[FormFieldPhoneNumber.subField.prefix]}
                onChange={(event) => this.onChange(event.target.value, FormFieldPhoneNumber.subField.prefix)}>
                <option value=""></option>
                <option value="33">+33</option>
                <option value="32">+32</option>
              </Form.Control>
              <Form.Control
                className="d-flex col-8 col-md-6"
                placeholder={this.props.label}
                aria-label={this.props.label}
                aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
                type="text"
                disabled={this.props.disabled || this.props.submitting}
                value={this.getValue()[FormFieldPhoneNumber.subField.number]}
                onChange={(event) => this.onChange(event.target.value, FormFieldPhoneNumber.subField.number)}
                isValid={(this.props.disabled)?null:this.isValid()}
                isInvalid={(this.props.disabled)?null:this.isInvalid()}
              />
            </>
            :
            <a className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius form-control align-items-center form-disabled" href={`${this.isEmpty()?'#':`tel:${this.getFinalPhoneNumber()}`}`}>{this.isEmpty()?'':this.getFinalPhoneNumber()}</a>
          }
          {this.displayValidating()}
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

export default FormFieldPhoneNumber;
