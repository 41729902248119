import React from 'react';

import AbstractPlacement from './AbstractPlacement';

import { Col as BaseCol } from 'react-bootstrap';

/**
 */
class Col extends AbstractPlacement {

  static defaultProps = {
    partChildren: null,
    
    xs: null,
    sm: null,
    md: null,
    lg: null,
    xl: null,

    noGutters: null
  }

  render() {
    return (
      <BaseCol
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.md}
        lg={this.props.lg}
        xl={this.props.xl}
        className={this.props.noGutters?"no-gutters":""}>
        {this.displayChildren()}
      </BaseCol>
    );
  }
}

export default Col;
