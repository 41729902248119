import React from 'react';
import ReactDOM from 'react-dom';


import 'bootstrap/dist/css/bootstrap.min.css';
import './css/bootstrap/base.css';
import './css/bootstrap/pagination.css';
import './css/bootstrap/my-warning.css';
import './css/bootstrap/my-validated.css';
import './css/bootstrap/my-information.css';
import './css/bootstrap/my-danger.css';
import './css/bootstrap/my-primary.css';
import './css/bootstrap/my-primary-outline.css';
import './css/bootstrap/my-primary-noline.css';
import './css/bootstrap/my-secondary.css';
import './css/bootstrap/my-secondary-outline.css';
import './css/bootstrap/my-secondary-noline.css';
// import './css/bootstrap/my-terciary.css';
import './css/bootstrap/my-terciary-outline.css';
// import './css/bootstrap/my-terciary-noline.css';
import './css/bootstrap/breadcrumb.css';

import './css/bootstrap/form.css';
import './css/bootstrap/dropdown.css';

import './css/index.css';

import './plugin/fontawesome-5.12.0/css/all.css';
import './plugin/fontnyukom/css/font-nyukom.css';
import './plugin/fontnyukomicon/styles.css';
import './css/fontnyukomicon/modifiers.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
