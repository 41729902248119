import React from 'react';
import BasicFormField from './BasicFormField';

import { Row, Col, Button } from 'react-bootstrap';
import '../../../css/form.css';

import NotEmptyOrNullAssert from '../../../Form/Assert/NotEmptyOrNullAssert';

/**
 * DashboardFormFieldDropdownMulti
 *
 * Specific configuration attribute : none
 */
class DashboardFormFieldDropdownMulti extends BasicFormField {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    autoComplete: false,
    alwaysOpen: false,
    input: 'options',
    finalValues: 'values', // or labels or objects
    options: []
  }

  constructor(props) {
    super(props);

    //the state of the component
    //options are the options coming from the call
    this.state = Object.assign(this.state, {
      options: [],

      selected: {},

      collapse: true,
    })
  }

  static getDerivedStateFromProps(props, state) {
    if(props.value && Array.isArray(props.value)) {
      let selected = {};
      props.value.forEach((item, i) => {
        let {value} = DashboardFormFieldDropdownMulti.applyAdapter(props, item);
        if(value) {
          selected[value] = true
        }
        else {
          selected[item] = true
        }
      });
      return{
        selected: selected
      };
    }
    else if(props.value && typeof props.value === "object") {
      return{
        selected: props.value
      };
    }
    return null;
  }

  /**
   * @inheritdoc
   */
  getSpecificAsserts() {
    return {
      notEmptyOrNull: NotEmptyOrNullAssert,
    }
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    let results = this.getFilteredOptions().filter((item) => {
      let {value} = this.applyAdapter(item);
      return Object.keys(this.state.selected).includes(String(value)) && this.state.selected[value] === true;
    });
    switch (this.props.finalValues) {
      case 'values':
        return results.map((item) => {
          let {value} = this.applyAdapter(item);
          return value;
        });
      case 'labels':
        return results.map((item) => {
          let {label} = this.applyAdapter(item);
          return label;
        });
      case 'objects':
      default:
        return results;
    }
  }

  /**
   * get the value of the options
   */
  getOptions() {
    //check for input type
    if(this.props.input === 'options' && this.props.options) {
      return this.props.options;
    }
    else if(this.props.input === 'formValues') {
      let options = this.props.compute(this.props.getValues, this.props.getExtraValues());
      if(options) {
        return options;
      }
    }
    return [];
  }

  /**
   * get the value of the options with the filter
   */
  getFilteredOptions() {
    if(this.props.filterOptions) {
      return this.getOptions().filter((item, i) => {
        return this.props.filterOptions(item, this.props.getValues, this.props.getExtraValues);
      })
    }
    return this.getOptions();
  }

  static applyAdapter(props, option) {
    if(props.adapter && props.autoComplete) {
      return {
        value: 'value('+props.adapter.getValue(option)+')',
        label: props.adapter.getLabel(option)
      }
    }
    else if(props.adapter && !props.autoComplete) {
      return {
        value: props.adapter.getValue(option),
        label: props.adapter.getLabel(option)
      }
    }
    else if(option.value !== undefined && option.value !== null && option.label !== undefined  && option.label !== null) {
      return option;
    }
    return {
      value: option,
      label: option
    }
  }

  /**
   * apply the adapter for options
   */
  applyAdapter(option) {
    return DashboardFormFieldDropdownMulti.applyAdapter(this.props, option);
  }

  /**
   * Send new value to parent element
   */
  onChange = (value, checked, ignoreValidation = false) => {
    let selected = Object.assign({}, this.state.selected);
    selected[value] = checked;
    super.onChange(selected, ignoreValidation)
  }

  displayOption(option) {
    let {value, label} = this.applyAdapter(option);
    let checked = this.state.selected[value]?this.state.selected[value]:false;
    return (
      <Button variant="my-seconday-noline" key={value} disabled={this.props.disabled} className={`dashboard-form-dropdown-option dashboard-form-dropdown-option-color${checked?" dashboard-form-dropdown-option-selected":""}`} onClick={() => this.onChange(value, !checked)}>
        <Row className="no-gutters dashboard-form-dropdown-option-text">
          <Col xs="auto">&nbsp;&nbsp;&nbsp;<i className={`${checked?"dashboard-form-dropdown-option-icon icon-checkmark":"icon-media-stop"}`}/>&nbsp;&nbsp;&nbsp;</Col><Col className="no-gutters dashboard-form-dropdown-option-text">{label}</Col>
        </Row>
      </Button>
    )
  }

  displayOptions() {
    return (
      <>
        {this.getFilteredOptions().map((option, key) => {
          return <Row className="no-gutters" key={key}>{this.displayOption(option)}</Row>
        })}
      </>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        {this.props.label !== ""?<Row className="no-gutters">{this.props.label}{this.displayMiddlePart()}{this.displayValidating()}</Row>:undefined}
        {(this.props.label === "" && this.props.middlePart)?<Row className="no-gutters">{this.displayMiddlePart()}</Row>:undefined}
        {this.displayOptions()}
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    )
  }
}

export default DashboardFormFieldDropdownMulti;
