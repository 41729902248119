import React from 'react';
import BasicWidget from './BasicWidget';

import '../../../css/dashboard/widget_color.css';

/**
 * WidgetColor
 */
class WidgetColor extends BasicWidget {

  static defaultProps = {
    conf: {
      color: '88FFFF'
    }
  }

  displayContent() {
    return (
      <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
        {this.displayTitle()}
        {this.displayArguments()}
        <div className="d-flex justify-content-center align-items-center" style={{height: "100%", width: "100%"}}>
          {this.evaluateArgument('data')}
        </div>
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="dashboard-widget-container"
        style={{background: this.evaluateArgument('color')}}>
        {this.displayContent()}
      </div>
    );
  }
}

export default WidgetColor;
