import React from 'react';

import TableCell from '../../../Table/Body/TableCell';

/**
 * PriceCell
 */
class PriceCell extends TableCell {

  getPlaceholder() {
    if(this.props.additionalConfiguration.placeholder) {
      return this.props.additionalConfiguration.placeholder;
    }
    return null;
  }

  getValue() {
    if(this.props.value === undefined || this.props.value === null) {
      return '';
    }
    let value = this.props.value;
    // let lastChar = null;
    // if(typeof value === 'string') {
    //   value = value.replace(',', '.');
    //   lastChar = value.slice(-1);
    //   value = value.replaceAll(/\s/g, '');
    // }
    // value = new Intl.NumberFormat('fr-FR').format(value);
    // if(lastChar && (lastChar === ".")){
    //   value += ",";
    // }
    value = value.toString();
    value = value.replace('.', ',');
    return value;
  }

  static parseFinalValue(value) {
    if(typeof value === 'string') {
      value = value.replace(',', '.');
      value = value.replaceAll(/\s/g, '');
    }
    return parseFloat(value);
  }

  onChange(event) {
    let value = event.target.value;
    if(value !== undefined && value !== null) {
      value = value.toString();
      let regex = /^-?(\d{0,2})(\d{3})*([.,]\d{0,5})?$/g;
      if(value.match(regex)) {
        this.props.additionalConfiguration.onChange(this.props._rowId, this.props._valueId, value, this.props.data);
      }
      // let lastChar = value.slice(-1);
      // if(!isNaN(lastChar) || ((lastChar === "," || lastChar === ".") && (value.match(/,|\./g) || []).length) === 1){
      //   // super.onChange(value);
      //   this.props.additionalConfiguration.onChange(this.props._rowId, this.props._valueId, value, this.props.data);
      // }
    }
  }

  displayValue() {
    if(this.props.additionalConfiguration && this.props.additionalConfiguration.editable) {
      return <input type="text" className="cell-form cell-form-price" placeholder={this.getPlaceholder()} value={this.getValue()} onChange={(e) => this.onChange(e)}/>
    }
    else if(this.props.value !== undefined && this.props.value !== null) {
      return `${Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(this.props.value)}`;
    }
    else {
      return "";
    }
  }

  displayInnerCell() {
    return <div className="cell-container cell-container-price">{this.displayValue()}</div>;
  }
}

export default PriceCell;
