export const bill_stat = {
  id: "bill_stat-1",
  default: true,
  roles: ["ROLE_ACCOUNTING_BILL"],

  title: "Stat facture",
  type: "listStat",
  conf: {
    element: "bill",
    stats: [
      {
        stat:"quantity",
        attribute:"id",
        title:"Qté mois en cours",
        filters:{
          createdAtTimeframe:{
            value:"currentMonth",
            label:"Mois en cours (1/9/2024 - 11/9/2024)"
          }
        }
      },
      {
        stat:"sum",
        attribute:"total_ti",
        title:"Somme TTC mois en cours",
        filters:{
          createdAtTimeframe:{
            value:"currentMonth",
            label:"Mois en cours (1/9/2024 - 11/9/2024)"
          },
        }
      }
    ]
  },
  column: 2,
  row: 2
}
