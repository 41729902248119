import React from 'react';

import '../../../css/item_index.css';

import strings from '../../../Localization/Localization';

import { withModalHandler } from '../../../HOC/ModalHandler';

import {
  fetchErrorLines,
  processErrorLines
} from '../../../API/FlexCdrFileErrorLines';

import { Container, Form, InputGroup, Navbar, Col, Button } from 'react-bootstrap';

import Loading from '../../Layout/Loading';

import Table from '../../Table/Table';
import TableHeader from '../../Table/TableHeader';
import TableBody from '../../Table/TableBody';
import TableTextHeader from '../../Table/Header/TableTextHeader';
import TableRow from '../../Table/Body/TableRow'

const displayTableHeaders = [
  {
    label: strings.phoneCDR.errorProcessing.flex.header.type,
    value: "error_type"
  },
  {
    label: strings.phoneCDR.errorProcessing.flex.header.value,
    value: "error_value"
  },
  {
    label: strings.phoneCDR.errorProcessing.flex.header.version,
    value: "header_version"
  }
]

/**
 * FlexErrorProcessing
 */
class FlexErrorProcessing extends React.Component {

    constructor(props) {
      super(props);

      this.fetchErrorLines = this.props.modalHandler.addVerificationWithCallback(fetchErrorLines, this.updateErrorLines, this.updateErrorLinesFailure);
      this.processErrorLines = this.props.modalHandler.addVerificationWithCallback(processErrorLines, this.postProcessErrorLines, this.postProcessErrorLinesFailure);

      this.state = {
        loading: true,
        error: false,

        items: null,
        page: null,
        total: null,
        totalPage: null,

        distinct: true,
      }
    }

    componentDidMount() {
      this.fetchErrorLines(this.state.distinct);
    }

    fetch() {
      this.setState({
        loading: true
      }, () => {
        this.fetchErrorLines(this.state.distinct);
      })
    }

    process() {
      this.setState({
        loading: true
      }, () => {
        this.processErrorLines();
      })
    }

    updateErrorLines = (data) => {
      console.log(data);
      this.setState({
        loading: false,
        items: data.items,
        page: data.page,
        total: data.total,
        totalPage: data.total_page
      })
    }

    updateErrorLinesFailure = (error) => {
      this.setState({
        loading: false,
        error: true
      })
    }

    postProcessErrorLines = (data) => {
      this.setState({
        loading: false
      }, () => this.fetch())
    }

    postProcessErrorLinesFailure = (error) => {
      this.setState({
        loading: false
      })
    }

    displayDistinct() {
      if(!this.state.loading) {
        return (
          <Container className="d-flex flex-column align-items-center">
            <InputGroup className={`container-form-row col-12`}>
              <InputGroup.Prepend
                className="d-flex col container-form-prepend">
                <InputGroup.Text
                  id={`form-distinct`}
                  className="col-md-12">
                  Afficher que les valeurs distincts
                </InputGroup.Text>
              </InputGroup.Prepend>
              <InputGroup.Prepend
                className="d-flex col-auto container-form-prepend container-form-switch">
                <Form.Check
                  type="switch"
                  id={`form-field-distinct`}
                  label=""
                  bsCustomPrefix="nk-switch"
                  checked={this.state.distinct}
                  onChange={(event) => this.setState({
                    distinct: event.target.checked
                  }, () => {
                    this.fetch()
                  })}
                />
              </InputGroup.Prepend>
            </InputGroup>
          </Container>
        )
      }
    }

    displayQuantity() {
      if(!this.state.loading) {
        return (
          <Container>
            <div style={{color:'#B2B2B2'}}>{strings.formatString(strings.common.itemIndex.totalDisplay, this.state.total)}</div>
          </Container>
        )
      }
    }

    displayList() {
      if(!this.state.loading) {
        return (
          <Container className="d-flex flex-column align-items-center">
            <Table>
              <TableHeader>
                {displayTableHeaders.map((header, i) => {
                  return <TableTextHeader key={i} value={header.value} label={header.label}/>
                })}
              </TableHeader>
              <TableBody>
                {this.state.items.map((item, i) => {
                  return (
                    <TableRow
                      key={i}
                      data={item}/>
                  )
                })}
              </TableBody>
            </Table>
          </Container>
        )
      }
    }

    displayButtons() {
      if(!this.state.loading) {
        return (
          <Navbar fixed="bottom" className="sub-footer">
            <Container>
              <Col xs={12} className="d-flex justify-content-center text-center small-text">
                <Button variant="my-validated" className="btn-sub-footer" onClick={() => this.process()}>{strings.phoneCDR.errorProcessing.flex.buttons.startProcess}</Button>
              </Col>
            </Container>
          </Navbar>
        )
      }
    }

    displayLoading() {
      if(this.state.loading) {
        return (
          <Loading/>
        )
      }
    }


    /**
     * Main render method for React Component
     */
    render() {
      return (
        <>
          {this.displayLoading()}
          {this.displayDistinct()}
          {this.displayQuantity()}
          {this.displayList()}
          {this.displayButtons()}
        </>
      );
    }
}

export default withModalHandler(FlexErrorProcessing);
