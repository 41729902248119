import strings from '../../Localization/Localization';

import {
  index,
  exportData
} from '../../API/PhoneCDRs';
// import {
//   form,
// } from '../Form/PhoneCDRs';
import { form as searchForm } from '../Form/Search/phoneCDRs';

// import { phoneCDR as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.phoneCDR,

    id: "phoneCDR",
    api: index,
    onClickItem: {
      redirect: false,
    },

    export: {
      link: (search) => exportData(search),
      auth: ['ROLE_CDR_EXTRACT']
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(typeof search.service === 'object') {
        result["subscription_id"] = search.service.id;
      }
      if(search.communicationType) {
        result["communication_type_id"] = search.communicationType;
      }
      if(search.roaming === false) {
        result["roaming"] = 0;
      }
      else if(search.roaming === true) {
        result["roaming"] = 1;
      }
      if(search.roamingLikeAtHome === false) {
        result["roaming_like_at_home"] = 0;
      }
      else if(search.roamingLikeAtHome === true) {
        result["roaming_like_at_home"] = 1;
      }
      if(search.redirection === false) {
        result["redirection"] = 0;
      }
      else if(search.redirection === true) {
        result["redirection"] = 1;
      }
      if(search.inout === '0' || search.inout === '1') {
        result["inout"] = search.inout;
      }
      if(search.sourceNumber) {
        result["source_number"] = search.sourceNumber;
      }
      if(search.destinationNumber) {
        result["destination_number"] = search.destinationNumber;
      }
      if(search.beginAtMin) {
        result["begin_at_min"] = search.beginAtMin;
      }
      if(search.beginAtMax) {
        result["begin_at_max"] = search.beginAtMax;
      }
      if(search.quantityMin) {
        result["quantity_min"] = search.quantityMin;
      }
      if(search.quantityMax) {
        result["quantity_max"] = search.quantityMax;
      }
      if(search.priceFinalMin) {
        result["price_final_min"] = search.priceFinalMin;
      }
      if(search.priceFinalMax) {
        result["price_final_max"] = search.priceFinalMax;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            label: strings.phoneCDR.index.headers.sourceNumber,
            value: "source_number"
          },
          {
            label: strings.phoneCDR.index.headers.destinationNumber,
            value: "destination_number"
          },
          {
            label: strings.phoneCDR.index.headers.redirection,
            value: (item) => item["redirection"]?"oui":"non",
          },
          {
            label: strings.phoneCDR.index.headers.beginAt,
            value: "begin_at"
          },
          {
            label: strings.phoneCDR.index.headers.type,
            value: (item) => item && item["communication_type"] && item["communication_type"]["name"],
          },
          {
            label: strings.phoneCDR.index.headers.quantity,
            value: (item) => {
              let unit = "";
              switch (item["communication_type"]["id"]) {
                case 1:
                  unit = "s";
                  break;
                case 2:
                  unit = "ko";
                  break;
                case 3:
                  unit = "";
                  break;
                case 4:
                  if(item["quantity"] !== 1 && item["quantity"] !== '1') {
                    unit = "ko";
                  }
                  break;
                default:

              }
              return `${item["quantity"]} ${unit}`;
            }
          },
          {
            label: strings.phoneCDR.index.headers.priceFinal,
            type: "price",
            value: "price_final"
          },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: (item) => []
  },
}
