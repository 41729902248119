import strings from '../../Localization/Localization';

import { form as _priceForm } from './phonePrices'

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.phonePriceList.name.label,
  validators: []
}

let prices = {
  fieldKey: "prices",
  label: strings.form.phonePriceList.prices.label,
  type: "table",

  validators: [],

  table: {
    headers: [
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: "name",
        label: strings.form.phonePriceList.prices.table.name,
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "price",
        value: "price",
        label: strings.form.phonePriceList.prices.table.price,
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "price",
        value: "service_price",
        label: strings.form.phonePriceList.prices.table.servicePrice,
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: (line) => line["communication_type"]["name"],
        label: strings.form.phonePriceList.prices.table.communicationType,
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: (line) => line["inout"]?strings.form.phonePriceList.prices.table.inout.optionTrue:strings.form.phonePriceList.prices.table.inout.optionFalse,
        label: strings.form.phonePriceList.prices.table.inout.label,
        editable: false
      }
    ]
  },
}

let newPrices = {
  fieldKey: "prices",
  label: strings.form.phonePriceList.completePrices.label,
  type: "array",

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phonePriceList.completePrices.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phonePriceList.completePrices.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phonePriceList.completePrices.allowMoreField.removingLabel,

  validators: [],

  subField: {
    type: "subform",
    label: strings.form.phonePriceList.prices.label,

    validators: [],

    subForm: _priceForm
  }
}

let completePrices = {
  fieldKey: "prices",
  label: strings.form.phonePriceList.completePrices.label,
  type: "array",

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phonePriceList.completePrices.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phonePriceList.completePrices.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phonePriceList.completePrices.allowMoreField.removingLabel,

  validators: [],

  subField: {
    type: "subform",
    label: strings.form.phonePriceList.prices.label,

    validators: [],

    subForm: _priceForm
  }
}

export const priceForm = {
  formKey: "phonePriceList",
  fields: [
    prices
  ]
}

export const newPricesForm = {
  formKey: "phonePriceList",
  fields: [
    newPrices
  ]
}

export const completePriceForm = {
  formKey: "phonePriceList",
  fields: [
    completePrices
  ]
}

export const form = {
  formKey: "phonePriceList",
  fields: [
    name
  ]
}
