import strings from '../../Localization/Localization';

import { index, getFromId, editFromId, newItem } from '../../API/AdministrativePreFillComment';
import { form } from '../Form/preFillComment';
import { form as searchForm } from '../Form/Search/preFillComment';

import { administrativePreFillComment as routes } from '../Route/routes';

export const conf = {
  prefix: "preFillComment/administrative",
  index: {
    pageTitle: strings.common.homepage.administrativePreFillComment,

    id: "preFillComment",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_ADMINISTRATIVE_TICKET_PRE_FILL_COMMENT'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => item["title"],
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.administrativePreFillComment,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "title",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.preFillComment.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_ADMINISTRATIVE_TICKET_PRE_FILL_COMMENT'],
        editable: ['ROLE_ADMINISTRATIVE_TICKET_PRE_FILL_COMMENT']
      }
    }
  },
  new: {
    pageTitle: strings.preFillComment.new.title,

    api: newItem,
    empty: {},
    form: form,
    onSubmit: (item) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
