import strings from '../../../Localization/Localization';

import { store } from '../../../Store/configureStore';

import { fetchPartners } from '../../../API/Partners';
import { fetchPlaces } from '../../../API/Places';
import {
  fetchUsers,
  fetchPartnerUsers
} from '../../../API/Users';

let title = {
  fieldKey: "title",
  label: strings.form.cart.title.label,
  type: "text",
  showOnDisabled: () => false,
  validators: [],
}

let owner = {
  fieldKey: "owner",
  type: "dropdownEntity",
  label: strings.form.cart.owner.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  loader: {
    id: "mainUser",
    loader: fetchUsers,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let client = {
  fieldKey: "client",
  label: strings.form.cart.client.label,
  type: "dropdownEntity",
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  loader: {
    id: "client",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let placeDelivery = {
  fieldKey: "placeDelivery",
  label: strings.form.cart.placeDelivery.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues(0).client === undefined || getValues(0).client === null || getValues(0).client.id === undefined,
  showOnDisabled: () => false,
  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "partnerPlaces",
    loader: fetchPlaces,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let clientUser = {
  fieldKey: "clientUser",
  type: "dropdownEntity",
  label: strings.form.cart.clientUser.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  showOnDisabled: () => false,
  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "clientUser",
    loader: fetchPartnerUsers,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let createdAtMin = {
  fieldKey: "createdAtMin",
  type: "date",
  label: strings.form.cart.createdAtMin.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  showOnDisabled: () => false,
  validators: []
}

let createdAtMax = {
  fieldKey: "createdAtMax",
  type: "date",
  label: strings.form.cart.createdAtMax.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "cartSearch",
  fields: [
    title,
    owner,
    client,
    placeDelivery,
    clientUser,
    createdAtMin,
    createdAtMax
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
