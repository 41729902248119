const common = {
  timeframe: {
    label: "Plage de temps",
    options: {
      currentDay: "Aujourd'hui",
      yesterday: "Hier",
      currentWeek: "Semaine en cours",
      pastWeek: "Semaine dernière",
      floatingWeek: "Semaine glissante",
      currentMonth: "Mois en cours",
      pastMonth: "Mois dernier",
      floatingMonth: "Mois glissant",
      currentQuarter: "Trimestre en cours",
      pastQuarter: "Trimestre dernier",
      floatingQuarter: "Trois mois glissant",
      currentYear: "Année en cours",
      pastYear: "Année dernière",
      floatingYear: "Année glissante",
    }
  }
};

export default common;
