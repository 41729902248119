import strings from '../../Localization/Localization';

export const timeframe = {
  fieldKey: "timeframe",
  label: strings.form.common.timeframe.label,
  type: "dropdown",
  validators: [],

  showEmpty: true,
  input: 'formValues',
  finalValues: 'objects',
  compute: () => {
    let today = new Date();

    let firstDayOfTheWeek = new Date();
    firstDayOfTheWeek.setDate(firstDayOfTheWeek.getDate()-(firstDayOfTheWeek.getDay()-1)%7);
    firstDayOfTheWeek.setHours(0);
    firstDayOfTheWeek.setMinutes(0);
    firstDayOfTheWeek.setSeconds(0);

    let firstDayOfLastWeek = new Date(firstDayOfTheWeek);
    firstDayOfLastWeek.setDate(firstDayOfLastWeek.getDate()-7);

    let lastDayOfLastWeek = new Date(firstDayOfTheWeek);
    lastDayOfLastWeek.setDate(lastDayOfLastWeek.getDate()-1);

    let sameDayOfLastWeek = new Date();
    sameDayOfLastWeek.setDate(sameDayOfLastWeek.getDate()-6);


    let firstDayOfTheMonth = new Date();
    firstDayOfTheMonth.setDate(1);
    firstDayOfTheMonth.setHours(0);
    firstDayOfTheMonth.setMinutes(0);
    firstDayOfTheMonth.setSeconds(0);

    let firstDayOfTheLastMonth = new Date(firstDayOfTheMonth);
    firstDayOfTheLastMonth.setMonth(firstDayOfTheLastMonth.getMonth()-1);

    let lastDayOfTheLastMonth = new Date(firstDayOfTheMonth);
    lastDayOfTheLastMonth.setDate(lastDayOfTheLastMonth.getDate()-1);

    let sameDayOfTheLastMonth = new Date();
    sameDayOfTheLastMonth.setMonth(sameDayOfTheLastMonth.getMonth()-1);
    sameDayOfTheLastMonth.setDate(sameDayOfTheLastMonth.getDate()+1);


    let firstDayOfTheQuarter = new Date(firstDayOfTheMonth);
    firstDayOfTheQuarter.setMonth(firstDayOfTheQuarter.getMonth()-firstDayOfTheQuarter.getMonth()%3);

    let firstDayOfTheLastQuarter = new Date(firstDayOfTheQuarter);
    firstDayOfTheLastQuarter.setMonth(firstDayOfTheLastQuarter.getMonth()-3);

    let lastDayOfTheLastQuarter = new Date(firstDayOfTheQuarter);
    lastDayOfTheLastQuarter.setDate(lastDayOfTheLastQuarter.getDate()-1);

    let sameDayOfTheLastQuarter = new Date();
    sameDayOfTheLastQuarter.setMonth(sameDayOfTheLastQuarter.getMonth()-3);
    sameDayOfTheLastQuarter.setDate(sameDayOfTheLastQuarter.getDate()+1);


    let firstDayOfTheYear = new Date(firstDayOfTheMonth);
    firstDayOfTheYear.setMonth(0);

    let firstDayOfTheLastYear = new Date(firstDayOfTheYear);
    firstDayOfTheLastYear.setFullYear(firstDayOfTheLastYear.getFullYear()-1);

    let lastDayOfTheLastYear = new Date(firstDayOfTheYear);
    lastDayOfTheLastYear.setDate(lastDayOfTheLastYear.getDate()-1);

    let sameDayOfTheLastYear = new Date();
    sameDayOfTheLastYear.setFullYear(sameDayOfTheLastYear.getFullYear()-1);
    sameDayOfTheLastYear.setDate(sameDayOfTheLastYear.getDate()+1);

    return [
      {
        value: 'currentDay',
        label: strings.form.common.timeframe.options.currentDay
      },
      {
        value: 'yesterday',
        label: strings.form.common.timeframe.options.yesterday
      },
      {
        value: 'currentWeek',
        label: `${strings.form.common.timeframe.options.currentWeek} (${firstDayOfTheWeek.getDate()}/${firstDayOfTheWeek.getMonth()+1}/${firstDayOfTheWeek.getFullYear()} - ${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()})`
      },
      {
        value: 'pastWeek',
        label: `${strings.form.common.timeframe.options.pastWeek} (${firstDayOfLastWeek.getDate()}/${firstDayOfLastWeek.getMonth()+1}/${firstDayOfLastWeek.getFullYear()} - ${lastDayOfLastWeek.getDate()}/${lastDayOfLastWeek.getMonth()+1}/${lastDayOfLastWeek.getFullYear()})`
      },
      {
        value: 'floatingWeek',
        label: `${strings.form.common.timeframe.options.floatingWeek} (${sameDayOfLastWeek.getDate()}/${sameDayOfLastWeek.getMonth()+1}/${sameDayOfLastWeek.getFullYear()} - ${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()})`
      },
      {
        value: 'currentMonth',
        label: `${strings.form.common.timeframe.options.currentMonth} (${firstDayOfTheMonth.getDate()}/${firstDayOfTheMonth.getMonth()+1}/${firstDayOfTheMonth.getFullYear()} - ${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()})`
      },
      {
        value: 'pastMonth',
        label: `${strings.form.common.timeframe.options.pastMonth} (${firstDayOfTheLastMonth.getDate()}/${firstDayOfTheLastMonth.getMonth()+1}/${firstDayOfTheLastMonth.getFullYear()} - ${lastDayOfTheLastMonth.getDate()}/${lastDayOfTheLastMonth.getMonth()+1}/${lastDayOfTheLastMonth.getFullYear()})`
      },
      {
        value: 'floatingMonth',
        label: `${strings.form.common.timeframe.options.floatingMonth} (${sameDayOfTheLastMonth.getDate()}/${sameDayOfTheLastMonth.getMonth()+1}/${sameDayOfTheLastMonth.getFullYear()} - ${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()})`
      },
      {
        value: 'currentQuarter',
        label: `${strings.form.common.timeframe.options.currentQuarter} (${firstDayOfTheQuarter.getDate()}/${firstDayOfTheQuarter.getMonth()+1}/${firstDayOfTheQuarter.getFullYear()} - ${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()})`
      },
      {
        value: 'pastQuarter',
        label: `${strings.form.common.timeframe.options.pastQuarter} (${firstDayOfTheLastQuarter.getDate()}/${firstDayOfTheLastQuarter.getMonth()+1}/${firstDayOfTheLastQuarter.getFullYear()} - ${lastDayOfTheLastQuarter.getDate()}/${lastDayOfTheLastQuarter.getMonth()+1}/${lastDayOfTheLastQuarter.getFullYear()})`
      },
      {
        value: 'floatingQuarter',
        label: `${strings.form.common.timeframe.options.floatingQuarter} (${sameDayOfTheLastQuarter.getDate()}/${sameDayOfTheLastQuarter.getMonth()+1}/${sameDayOfTheLastQuarter.getFullYear()} - ${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()})`
      },
      {
        value: 'currentYear',
        label: `${strings.form.common.timeframe.options.currentYear} (${firstDayOfTheYear.getDate()}/${firstDayOfTheYear.getMonth()+1}/${firstDayOfTheYear.getFullYear()} - ${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()})`
      },
      {
        value: 'pastYear',
        label: `${strings.form.common.timeframe.options.pastYear} (${firstDayOfTheLastYear.getDate()}/${firstDayOfTheLastYear.getMonth()+1}/${firstDayOfTheLastYear.getFullYear()} - ${lastDayOfTheLastYear.getDate()}/${lastDayOfTheLastYear.getMonth()+1}/${lastDayOfTheLastYear.getFullYear()})`
      },
      {
        value: 'floatingYear',
        label: `${strings.form.common.timeframe.options.floatingYear} (${sameDayOfTheLastYear.getDate()}/${sameDayOfTheLastYear.getMonth()+1}/${sameDayOfTheLastYear.getFullYear()} - ${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()})`
      },
    ]
  },

  adapter: {
    requirement: (data) => data["value"] && data["label"],
    getValue: (data) => data["value"],
    getLabel: (data) => data["label"],
  },
}
