import { technical_ticket_list } from './DefaultWidget/technical_ticket_list';
import { technical_service_list } from './DefaultWidget/technical_service_list';
import { technical_pie_ticket_state } from './DefaultWidget/technical_pie_ticket_state';
import { technical_pie_ticket_criticity } from './DefaultWidget/technical_pie_ticket_criticity';
import { technical_centreon } from './DefaultWidget/technical_centreon';
import { technical_ticket_stat } from './DefaultWidget/technical_ticket_stat';

import { bill_stat } from './DefaultWidget/bill_stat';
import { administrative_ticket_stat } from './DefaultWidget/administrative_ticket_stat';
import { bill_list } from './DefaultWidget/bill_list';
import { administrative_ticket_list } from './DefaultWidget/administrative_ticket_list';

import { commercial_ticket_list } from './DefaultWidget/commercial_ticket_list';
import { commercial_ticket_stat } from './DefaultWidget/commercial_ticket_stat';
import { order_stat } from './DefaultWidget/order_stat';
import { order_list } from './DefaultWidget/order_list';
import { quote_list } from './DefaultWidget/quote_list';

export const defaultWidgets = {
  widgets: [
    technical_ticket_list,
    technical_service_list,
    technical_pie_ticket_state,
    technical_pie_ticket_criticity,
    technical_centreon,
    technical_ticket_stat,

    bill_stat,
    administrative_ticket_stat,
    bill_list,
    administrative_ticket_list,

    commercial_ticket_list,
    commercial_ticket_stat,
    order_stat,
    order_list,
    quote_list
  ]
}
