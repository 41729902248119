import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of ticket forms based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('ticket_forms/commercial', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 */
export function getFromId(id) {
  return baseGetFromId('ticket_forms/commercial', id);
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getIdentificationDataFromId(id) {
  return baseGetFromId('ticket_forms/commercial', id, 'id');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single ticket form based on his ID
 *
 * @param id the of the ticket form requested
 * @param data the new data of the ticket form
 */
export function editFromId(id, data) {
  return baseEditFromId('ticket_forms/commercial', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new ticket form
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return baseNewItem('ticket_forms/commercial', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get the complete list of ticket forms
 */
export function fetchTicketForms(){
  return baseIndex('ticket_forms/commercial', 1, 0);
}
