import strings from '../../Localization/Localization';

import { fetchPartners } from '../../API/Partners';

// import { checkZone } from '../../API/TicketZone';

import {
  partners as partnersRoutes,
  // users as usersRoutes
  places as placesRoutes
} from '../Route/routes';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.place.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min:1
      },
      invalidFeedback: strings.form.place.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let img = {
  fieldKey: "img",
  type: "file",
  label: strings.form.place.img.label,
  showOnDisabled: () => false,

  validators: [],
}

let partner = {
  fieldKey: "partner",
  label: strings.form.place.partner.label,
  type: "dropdownEntity",

  disabledLink: (value) => value && partnersRoutes.routes.show.createPath(value["id"]),

  validators: [],

  showEmpty: true,
  loader: {
    id: "client",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.place.number.label,
  validators: []
}

let way = {
  fieldKey: "way",
  type: "text",
  label: strings.form.place.way.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min:1
      },
      invalidFeedback: strings.form.place.way.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let zipCode = {
  fieldKey: "zip_code",
  type: "text",
  label: strings.form.place.zipCode.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min:1
      },
      invalidFeedback: strings.form.place.zipCode.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let city = {
  fieldKey: "city",
  type: "text",
  label: strings.form.place.city.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min:1
      },
      invalidFeedback: strings.form.place.city.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let zone = {
  fieldKey: "zone",
  type: "text",
  label: strings.form.place.zone.label,
  disabled: true,
  validators: [
    // {
    //   id: 'assertValidity',
    //   type: 'call',
    //   call: (value) => checkZone(value),
    //   invalidFeedback: strings.form.place.zone.invalid.assertValidity,
    //   defaultValue: false,
    // }
  ]
}

let latitude = {
  fieldKey: "latitude",
  type: "text",
  label: strings.form.place.latitude.label,
  disabled: true,
  validators: []
}

let longitude = {
  fieldKey: "longitude",
  type: "text",
  label: strings.form.place.longitude.label,
  disabled: true,
  validators: []
}

let country = {
  fieldKey: "country",
  type: "text",
  label: strings.form.place.country.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min:1
      },
      invalidFeedback: strings.form.place.country.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let active = {
  fieldKey: "active",
  type: "switch",
  label: strings.form.place.active.label,
  disabled: true,
  validators: []
}

export const addressForm = {
  formKey: "place",
  fields: [
    Object.assign({}, name, {
      disabledLink: (value, getValues) => getValues(0) && getValues(0)["id"]?placesRoutes.routes.show.createPath(getValues(0)["id"]):null,
    }),
    number,
    way,
    zipCode,
    city,
    country,
    active
  ]
}

export const form = {
  formKey: "place",
  fields: [
    name,
    partner,
    number,
    way,
    zipCode,
    city,
    zone,
    latitude,
    longitude,
    country,
    active
  ]
}

export const editForm = {
  formKey: "place",
  fields: [
    name,
    partner,
    img,
    number,
    way,
    zipCode,
    city,
    country
  ]
}

export const newForm = {
  formKey: "place",
  fields: [
    name,
    partner,
    img,
    number,
    way,
    zipCode,
    city,
    country
  ]
}
