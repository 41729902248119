import React from 'react';

import MainLayout from '../Layout/MainLayout';

import BaseTransition from '../Transition/Transition';

// import BaseEntityTabs from '../EntityTabs/MyEntityTabs';

/**
 * Transition
 *
 * This component display a transition for an entity
 */
class Transition extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    let transition = this.props.conf[this.props.match.params.transition];
    return (
      <MainLayout>
        <BaseTransition
          id={this.props.match.params.id}
          type={transition.type}
          api={transition.api}
          form={transition.form}
          postTransition={transition.postTransition}/>
      </MainLayout>
    );
  }
}

export default Transition;
