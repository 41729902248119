import {
  index as baseIndex
 } from './Base';

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all countries to serve in a dropdown form choice
 */
export function fetchCountries() {
  return baseIndex('countries', 1, 0);
}
