// import _default from './echart/default';
// import _common from './echart/common';

import centreonPing from './echart/centreonPing';
import centreonNetwork from './echart/centreonNetwork';

const echart = {
  // default: _default,
  // common: _common,

  centreonPing: centreonPing,
  centreonNetwork: centreonNetwork
};

export default echart;
