import {
  index as baseIndex
 } from './Base';


/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all phone packages to serve in a dropdown form choice
 */
export function fetchPhonePackageOrders() {
  return baseIndex('phone_package_orders', 1, 0);
}
