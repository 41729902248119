import { config } from './config';

import { request } from './Base';

/**
 * Login function to the main external API
 *
 * @param username the username of the user to login
 * @param password the password of the user to login
 */
export function login(username, password){
  //url of the resource
  let url = config.api_path+'/login';
  //headers used by the request
  let headers = new Headers({
    "Content-Type": "application/json",
    "API-AUTH-USERNAME": username,
    "API-AUTH-PASSWORD": password,
  })


  let init = {
    method: 'GET',
    headers: headers,
    mode: 'cors',
    cache: 'default',
  }

  //execute the request
  return fetch(url, init)
    .then(async (response) => {
      switch (response.status) {
        case 200:
          return response.json();
        case 401:
        case 403:
          return {
            status: 'error',
            message: "Une erreur s'est produite au niveau du serveur",
            serverMessage: response.statusText,
            body: await response.json()
          }
        case 404:
        case 500:
        default:
          return {
            status: 'error',
            message: "Une erreur s'est produite au niveau du serveur",
            serverMessage: response.statusText
          }
      }
    })
    .catch((error) => console.error(error))
}

/**
 * reset password
 */
export function resetPassword(username) {
  return request('/reset_password/'+username, "GET");
}

/**
 * Refresh credentials to the main external API
 */
export function refresh(){
  return request('/refresh_credentials', "GET");
}
