const service = {
  tab: {
    main: "Service",
    dependency: "Option",
    technicalData: "Données techniques",
    activeContract: "Contract actif",
    archiveContract: "Archive contrat",
    phonePackageApi: "API Téléphonie",
    phonePackageOrder: "Forfait téléphonique",
    phonePackageConsumption: "Consommations",
    specificPhonePriceList: "Grille tarifaire téléphonique spéciale",
    amendment: "Avenants",
    centreonConfiguration: "Conf Centreon",
    centreonChart: "Supervision"
  },
  search: {
    serialNumber: "Numéro de série",
    orderNumber: "Numéro de CMD",
    quoteNumber: "Numéro de devis",
    productName: "Nom du produit",
    price: "Prix",
    state: "Etat",
    partner: "Client",
    client: "Compte client",
    place: "Site",
    productFamily: "Famille de produit",
    productSubFamily: "Sous-famille de produit",
    deliveryDateTimeframe: "Plage de temps / Livraison",
    deliveryDateMin: "Livré après",
    deliveryDateMax: "Livré avant",
    updateDateTimeframe: "Plage de temps / MàJ",
    updateDateMin: "Mise à jour après",
    updateDateMax: "Mise à jour avant",
    emptyApiKey: "Clé d'API vide",
  },
  tabs: {
    buttons: {
      disable: "Désactiver",
      termination: "Résilier",
      cancelTermination: "Annuler la résiliation",
      terminate: "Valider la résiliation",
      terminateNoBill: "Valider la résiliation (sans frais)",
      amendment: "Nouvel avenant",
      addPhonePackage: "Ajout Téléphonie",
      activatePhonePackageOrder: {
        label: "Réactiver Téléphonie",
        flashbagMessage: "La téléphonie a été réactivée.",
        flashbagMessageFailure: "La téléphonie n'a pas pu être réactivée."
      },
      deactivatePhonePackageOrder: {
        label: "Désactiver Téléphonie",
        flashbagMessage: "La téléphonie a été désactivée.",
        flashbagMessageFailure: "La téléphonie n'a pas pu être désactivée."
      },
      wipeCalculation: {
        label: "Recalcule CDR",
        title: "Demande de recalcul des CDR",
        successButton: "Recalculer",
        flashbagMessage: "Demande de recalcul réussi.",
        flashbagMessageFailure: "Demande de recalcul échoué.",
        form: {
          beginAtMin: {
            label: "Date de début"
          },
          beginAtMax: {
            label: "Date de fin"
          }
        }
      },
      transfer: {
        label: "Transfert CDR",
        title: "Demande de transfert des CDR",
        successButton: "Transfèrer",
        flashbagMessage: "Demande de transfert réussi.",
        flashbagMessageFailure: "Demande de transfert échoué.",
        form: {
          beginAtMin: {
            label: "Date de début"
          },
          beginAtMax: {
            label: "Date de fin"
          },
          phonePackageOrder: {
            label: "Cible"
          }
        }
      }
    }
  },
};

export default service;
