import React from 'react';

import { connect } from 'react-redux';

//STEP 1:
//create components using React.lazy
const LightTheme = React.lazy(() => import('./Themes/light'));
const DarkTheme = React.lazy(() => import('./Themes/dark'));
const DarkColorblindTheme = React.lazy(() => import('./Themes/dark_colorblind'));
const Custom1Theme = React.lazy(() => import('./Themes/custom_1'));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
class Theme extends React.Component {

  render() {
    return (
      <>
        <React.Suspense fallback={<></>}>
          {(this.props.theme === 'light') && <LightTheme />}
          {(this.props.theme === 'dark') && <DarkTheme />}
          {(this.props.theme === 'dark_colorblind') && <DarkColorblindTheme />}
          {(this.props.theme === 'custom_1') && <Custom1Theme />}
        </React.Suspense>
        {this.props.children}
      </>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.persisted.theme.name
})

export default connect(mapStateToProps)(Theme);
