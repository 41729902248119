import strings from '../../../Localization/Localization';

let title = {
  fieldKey: "title",
  type: "text",
  label: strings.form.accountVat.title.label,
  showOnDisabled: () => false,
  validators: []
}

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.accountVat.number.label,
  showOnDisabled: () => false,
  validators: []
}

let vat = {
  fieldKey: "vat",
  type: "text",
  label: strings.form.accountVat.vat.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "accountVatSearch",
  fields: [
    title,
    number,
    vat
  ]
}
