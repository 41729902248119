import React from 'react';

/**
 */
class Image extends React.Component {

  static defaultProps = {
    content: null
  }

  displayContent() {
    if(this.props.content) {
      return <img style={{ maxWidth: "100%" }} src={this.props.content} alt={this.props.alt}/>
    }
  }

  render() {
    return (
      <div>
        {this.displayContent()}
      </div>
    );
  }
}

export default Image;
