import React from 'react';
import { FormFieldDropdownMultiEntity } from './FormFieldDropdownMultiEntity';
import strings from '../../../Localization/Localization';

import ScrollContainer from '../../Basic/ScrollContainer';
import ExtendableZone from '../../Basic/ExtendableZone';

import { InputGroup, Button, Col, Row, Form } from 'react-bootstrap';
import '../../../css/form.css';

import { connect } from 'react-redux';

/**
 * FormFieldDropdownMultiEntityAlt
 *
 * This class handles HTML form field.
 *
 * The particular field is a Dropdown, it allow to choose multiple element from
 * a list.
 *
 * Specific configuration attribute :
 * - adapter : an adapter in case the options are objects
 * - filterOptions : inherit from FormFieldDropdown
 *
 * - loader :
 *    - id : the id for the the storage of the values
 *    - loader : the function that will load the options
 *    - loaderRequire : a function that will test if the arguments are ready
 *                    for the function
 *    - loaderArguments : the arguments of the loader function
 *    - dataTransformation : the transformation required if the options are
 *                         nested in the loader reply
 *
 * - storage : either 'state' (default) or 'redux'
 * - shareStorage : true, the storage will be shared with other dropdown with
 *                the same loader ID (only work with redux storage)
 *                  false, the storage won't be shared
 *
 * - finalValues : determine how the final values are sent
 *              if 'values', return an array with the values
 *              if 'labels', return an array with the labels
 *              if 'objects', return an array with the objects, only useful when
 *                          if there is an array adapter
 */
class FormFieldDropdownMultiEntityAlt extends FormFieldDropdownMultiEntity {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    autoComplete: false,
    input: 'load',
    storage: 'state',
    shareStorage: true,
    finalValues: 'objects', // or labels or values
    options: []
  }

  constructor(props) {
    super(props);

    this.state = Object.assign(this.state, {
      height: 300,

      innerUnselectedSelection: {},
      innerSelectedSelection: {},

      unselectedTemporarySearch: "",
      selectedTemporarySearch: ""
    })
  }

  componentDidMount() {
    this.checkOptions();
  }

  componentDidUpdate() {
    this.checkOptions();
  }

  onDoubleClickOption(key, value) {
    if(!this.props.disabled) {
      this.onChange(key, value)

      let innerSelectedSelection = Object.assign({}, this.state.innerSelectedSelection)
      innerSelectedSelection[value] = false;

      let innerUnselectedSelection = Object.assign({}, this.state.innerUnselectedSelection)
      innerUnselectedSelection[value] = false;

      this.setState({
        innerSelectedSelection: innerSelectedSelection,
        innerUnselectedSelection: innerUnselectedSelection
      })
    }
  }

  onClickOption(selected, value) {
    if(!this.props.disabled) {
      let selection;
      if(selected) {
        selection = Object.assign({}, this.state.innerSelectedSelection);
      }
      else {
        selection = Object.assign({}, this.state.innerUnselectedSelection);
      }

      selection[value] = !selection[value];

      if(selected) {
        this.setState({
          innerSelectedSelection: selection
        })
      }
      else {
        this.setState({
          innerUnselectedSelection: selection
        })
      }
    }
  }

  /**
   * Display all options of the field
   */
  displayOptions(selected = false) {
    let strip = true;
    return (
      <>
        {this.getFilteredOptions()
          .filter((option) => {
            let {value} = this.applyAdapter(option);
            return (selected && this.state.selected[value] === selected) || (!selected && (!this.state.selected[value] || this.state.selected[value] === selected));
          })
          .filter((option) => {
            let {label} = this.applyAdapter(option);
            let search = "";
            if(selected) {
              search = this.state.selectedTemporarySearch;
            }
            else {
              search = this.state.unselectedTemporarySearch;
            }

            if(search) {
              const regex = new RegExp(
                search.replace(".", "\\.").replace("%", ".*"),
                "gmi"
              );
              return label.match(regex);
            }
            return true;
          })
          .map((option, i) => {
            strip = !strip;
            let {value, label} = this.applyAdapter(option);
            let inSelection = selected?(this.state.innerSelectedSelection[value] === true):(this.state.innerUnselectedSelection[value] === true)
            return (
              <div
                key={`${this.props.formKey}-${this.props.fieldKey}-${value}`}
                disabled={this.props.disabled || this.props.submitting}
                onClick={() => this.onClickOption(selected, value)}
                onDoubleClick={() => this.onDoubleClickOption(value, !selected)}>
                <div className={`${inSelection?"form-field-dropdown-multi-alt-option-in-selection":"form-field-dropdown-multi-alt-option-out-selection"}`}>
                  <div className={`${strip?"form-field-dropdown-multi-alt-option-odd":"form-field-dropdown-multi-alt-option-even"}`}>
                    {label}
                  </div>
                </div>
              </div>
            )
          }
        )}
      </>
    )
  }

  onClickButtonRight() {
    let selection = Object.assign({}, this.state.innerUnselectedSelection);
    let selected = Object.assign({}, this.state.selected);
    Object.keys(this.state.innerUnselectedSelection).forEach((option) => {
      if(this.state.innerUnselectedSelection[option] === true) {
        selected[option] = true;
        selection[option] = false;
      }
    });
    this.setState({
      innerUnselectedSelection: selection,
    })
    this.props.onChange(selected);
    this.startValidation(selected);
  }

  onClickButtonLeft() {
    let selection = Object.assign({}, this.state.innerSelectedSelection);
    let selected = Object.assign({}, this.state.selected);
    Object.keys(this.state.innerSelectedSelection).forEach((option) => {
      if(this.state.innerSelectedSelection[option] === true) {
        selected[option] = false;
        selection[option] = false;
      }
    });
    this.setState({
      innerSelectedSelection: selection,
    })
    this.props.onChange(selected);
    this.startValidation(selected);
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field">
        <Row className="no-gutters">
          <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
            <InputGroup.Prepend
              className="d-flex col-12 container-form-prepend">
              <InputGroup.Text
                id={`form-${this.props.formKey}-${this.props.fieldKey}`}
                className="col-12">
                {this.props.label}
              </InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </Row>
        <ExtendableZone
          extendHeigth={true}
          height={this.state.height}
          onChangeHeight={(height) => this.setState({height:height})}
          minHeight={250}
          maxHeight={1000}
          width="auto">
          <Col className="no-gutters">
            <Form.Control
              className="d-flex form-field-dropdown-multi-alt-search"
              placeholder={strings.form.default.field.dropdownEntityMultiAlt.search}
              type="text"
              value={this.state.unselectedTemporarySearch}
              onChange={(event) => this.setState({unselectedTemporarySearch: event.target.value})}
            />
            <ScrollContainer
              className="form-field-dropdown-multi-alt-scroll-container"
              height={`${this.state.height-100}px`}>
              {this.displayOptions(false)}
            </ScrollContainer>
          </Col>
          <Col xs="auto" className="d-flex flex-column justify-content-center">
            <Button variant="my-primary-noline" onClick={() => this.onClickButtonRight()}><i className="icon-droite"/></Button>
            <Button variant="my-primary-noline" onClick={() => this.onClickButtonLeft()}><i className="icon-gauche"/></Button>
          </Col>
          <Col className="no-gutters">
            <Form.Control
              className="d-flex form-field-dropdown-multi-alt-search"
              placeholder={strings.form.default.field.dropdownEntityMultiAlt.search}
              type="text"
              value={this.state.selectedTemporarySearch}
              onChange={(event) => this.setState({selectedTemporarySearch: event.target.value})}
            />
            <ScrollContainer
              className="form-field-dropdown-multi-alt-scroll-container"
              height={`${this.state.height-100}px`}>
              {this.displayOptions(true)}
            </ScrollContainer>
          </Col>
        </ExtendableZone>
        <Row>
          {this.displayValidValidators()}
          {this.displayInvalidValidators()}
          {this.displayLoadingError()}
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  optionsContainer: state.optionsContainer,
})

/**
 * HOC Modal Handler to support call options
 */
export default connect(mapStateToProps, null, null, { forwardRef: true })(FormFieldDropdownMultiEntityAlt);
