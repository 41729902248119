const accountAnalytic = {
  index: {
    tab: {
      headers: {
        number: "Numéro",
      }
    }
  },
  tab: {
    main: "Compte",
    edit: "Editer",
  },
  new: {
    title: "Nouveau compte analytique"
  },
  search: {
    number: "Numéro",
  }
};

export default accountAnalytic;
