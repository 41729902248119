const login = {
  user: "Nom d'utilisateur",
  password: "Mot de passe",
  captcha: "Captcha",
  submit: "Se connecter",
  submitForgottenPassword: "Réinitialiser le mot de passe",
  additionalLinks: {},
  error: {
    userNotFound: "Utilisateur incorrect.",
    invalidCredentials: "Mot de passe incorrect.",
    tooManyAttempt: "Votre compte est bloqué, veuillez nous contacter pour le débloquer.",
    wrongCaptcha: "Le captcha est incorrect.",
    permissionDenied: "Vous n'avez pas les droit pour effectuer cette action.",
    error: "Problème lors de la connexion, veuillez réessayer dans quelques minutes."
  }
};

export default login;
