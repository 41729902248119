import React from 'react';

import { Button } from 'react-bootstrap';

/**
 * HoldButton
 */
class HoldButton extends React.Component {

  static defaultProps = {
    variant: null,
    className: "",
    disabled: false,
    onClick: () => null,
    onHold: () => null,
    slope: [
      {
        limit: 10,
        interval: 500
      },
      {
        limit: 40,
        interval: 100
      },
      {
        limit: null,
        interval: 50
      }
    ]
  }

  constructor(props) {
    super(props);

    this.counter = null;
    this.count = 0;
  }

  checkSlope() {
    if(this.props.slope.length <= 0 || !this.props.slope[0].interval) {
      return false;
    }
    return true;
  }

  onMouseDown = () => {
    this.props.onHold();
    if(this.checkSlope()) this.setInterval(0);
  }

  onMouseUp = () => {
    clearInterval(this.counter);
  }

  setInterval = (slopeId) => {
    let slope = this.props.slope[slopeId];
    this.count = 0;
    clearInterval(this.counter);
    this.counter = setInterval(() => {
      this.props.onHold();
      this.count++;
      if(this.props.slope[slopeId+1] !== undefined && slope.limit !== null && slope.limit === this.count) {
        this.setInterval(slopeId+1);
      }
    }, slope.interval);
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Button
        variant={this.props.variant}
        className={this.props.className}
        disabled={this.props.disabled}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onClick={this.props.onClick}>
        {this.props.children}
      </Button>
    );
  }
}

export default HoldButton;
