export const ADD_LINE_TO_FLASHBAG = 'add_line_to_flashbag';
export const REMOVE_LINE_FROM_FLASHBAG = 'remove_line_from_flashbag';

export const FLASHBAG_TYPE_PRIMARY = 'primary';
export const FLASHBAG_TYPE_SECONDARY = 'secondary';
export const FLASHBAG_TYPE_SUCCESS = 'success';
export const FLASHBAG_TYPE_DANGER = 'danger';
export const FLASHBAG_TYPE_WARNING = 'warning';
export const FLASHBAG_TYPE_INFO = 'info';
export const FLASHBAG_TYPE_LIGHT = 'light';
export const FLASHBAG_TYPE_DARK = 'dark';

export function addLineToFlashbag(text, type = 'info'){
  return {
    type: ADD_LINE_TO_FLASHBAG,
    text: text,
    line_type: type
  }
}

export function removeLineFromFlashbag(id){
  return {
    type: REMOVE_LINE_FROM_FLASHBAG,
    id: id
  }
}
