import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of partners based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('partners', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single partner based on his ID
 *
 * @param id the of the partner requested
 */
export function getFromId(id) {
  return baseGetFromId('partners', id);
}

/**
 * Get a single partner based on his ID
 *
 * @param id the of the partner requested
 */
export function getPlacesFromId(id) {
  return baseGetFromId('partners', id, 'places');
}

/**
 * Get a single partner based on his ID
 *
 * @param id the of the partner requested
 */
export function getAccountsFromId(id) {
  return baseGetFromId('partners', id, 'accounts');
}

/**
 * Get a single partner based on his ID
 *
 * @param id the of the partner requested
 */
export function getAccountingFromId(id) {
  return baseGetFromId('partners', id, 'accounting');
}

/**
 * Get a single partner based on his ID
 *
 * @param id the of the partner requested
 */
export function getBankInformationFromId(id) {
  return baseGetFromId('partners', id, 'bank_information');
}

/**
 * Get a single partner based on his ID
 *
 * @param id the of the partner requested
 */
export function getSpecificPricesFromId(id) {
  return baseGetFromId('partners', id, 'specific_prices');
}

/**
 * Get a single partner based on his ID
 *
 * @param id the of the partner requested
 */
export function getCartFromId(id) {
  return baseGetFromId('partners', id, 'cart');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single partner based on his ID
 *
 * @param id the of the partner requested
 * @param data the new data of the partner
 */
export function editFromId(id, data) {
  return baseEditFromId('partners', id, data);
}

/**
 * Edit a single partner based on his ID
 *
 * @param id the of the partner requested
 * @param data the new data of the partner
 */
export function editPlacesFromId(id, data) {
  return baseEditFromId('partners', id, data, 'places');
}

/**
 * Edit a single partner based on his ID
 *
 * @param id the of the partner requested
 * @param data the new data of the partner
 */
export function editAccountsFromId(id, data) {
  return baseEditFromId('partners', id, data, 'accounts');
}

/**
 * Edit a single partner based on his ID
 *
 * @param id the of the partner requested
 * @param data the new data of the partner
 */
export function editAccountingFromId(id, data) {
  return baseEditFromId('partners', id, data, 'accounting');
}

/**
 * Edit a single partner based on his ID
 *
 * @param id the of the partner requested
 * @param data the new data of the partner
 */
export function editBankInformationFromId(id, data) {
  return baseEditFromId('partners', id, data, 'bank_information');
}

/**
 * Edit a single partner based on his ID
 *
 * @param id the of the partner requested
 * @param data the new data of the partner
 */
export function editSpecificPricesFromId(id, data) {
  return baseEditFromId('partners', id, data, 'specific_prices');
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new partner
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return baseNewItem('partners', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all partners to serve in a dropdown form choice
 */
export function fetchPartners() {
  return baseIndex('partners', 1, 0);
}

/******************************************************************************
 * SPECIFIC FUNCTION                                                          *
 ******************************************************************************/

/**
 * change the payment failure status
 *
 * @param id the id of the partner
 * @param username the username to check
 */
export function changePaymentFailure(id, paymentFailure) {
  let data = {
    payment_failure: paymentFailure
  }
  return baseEditFromId('partners', id, data, 'payment_failure');
}
