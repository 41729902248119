const phoneZoneGroup = {
  label: {
    label: "Label",
  },
  aliases: {
    label: "Alias",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  zones: {
    label: "Zones",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  zone: {
    label: "Zone"
  },
  priceSources: {
    label: "",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  priceSource: {
    label: "Prix"
  },
  priceDestinations: {
    label: "",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  priceDestination: {
    label: "Prix"
  },
};

export default phoneZoneGroup;
