import React from 'react';

import Holder from 'holderjs';

import { Image as BaseImage } from 'react-bootstrap';

/**
 * TODO
 */
class Image extends React.Component {

  static defaultProps = {
    height: 100,
    width: 100,
    url: `holder.js/100x100?random=yes&text=loading`,
  }

  constructor(props) {
    super(props);

    this.imageId = Math.random().toString(36).substring(7).replace(/[0-9]/gi,'a');
  }

  render() {
    if(this.props.url.startsWith("holder.js")) {
      setTimeout(() => {
        Holder.run({
          images: `#${this.imageId}`
        })
      }, 10);
    }

    return (
      <BaseImage
        style={{maxWidth:"100%",maxHeight:"100%"}}
        id={this.imageId}
        src={this.props.url}
        roundedCircle={this.props.roundedCircle}
        rounded={this.props.rounded}
        thumbnail={this.props.thumbnail}
        height={this.props.height}
        width={this.props.width}
        fluid/>
    );
  }
}

export default Image;
