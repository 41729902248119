import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import { checkUsername } from '../../API/Users';
import { fetchInnerProfils, fetchPartnerProfils } from '../../API/Profils';
import { getProfilUserProfilModification } from '../../API/Profil';
import { fetchPartners } from '../../API/Partners';
import { fetchPartnerAccounts } from '../../API/PartnerAccounts';
import { fetchSubFamilies } from '../../API/ProductSubFamilies';

let username = {
  fieldKey: "username",
  type: "text",
  label: strings.form.user.username.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1,
      },
      invalidFeedback: strings.form.user.username.invalid.assertEmpty,
      defaultValue: false,
    },
    {
      id: 'assertUnique',
      type: 'call',
      call: (value, getValues) => checkUsername(getValues()["id"], value),
      invalidFeedback: strings.form.user.username.invalid.assertUnique,
      defaultValue: true,
    }
  ]
}

let firstname = {
  fieldKey: "firstname",
  type: "text",
  label: strings.form.user.firstname.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1,
      },
      invalidFeedback: strings.form.user.firstname.invalid.assertEmpty,
      defaultValue: false,
    },
  ]
}

let lastname = {
  fieldKey: "lastname",
  type: "textUppercase",
  label: strings.form.user.lastname.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1,
      },
      invalidFeedback: strings.form.user.lastname.invalid.assertEmpty,
      defaultValue: false,
    },
  ]
}

let mail = {
  fieldKey: "mail",
  type: "mail",
  label: strings.form.user.mail.label,
  validators: []
}

let phoneNumber = {
  fieldKey: "phone_number",
  type: "phoneNumber",
  label: strings.form.user.phoneNumber.label,

  validators: [],
}

let mobilePhoneNumber = {
  fieldKey: "mobile_phone_number",
  type: "phoneNumber",
  label: strings.form.user.mobilePhoneNumber.label,

  validators: [],
}

let img = {
  fieldKey: "img",
  type: "file",
  label: strings.form.user.img.label,
  showOnDisabled: () => false,

  validators: [],
}

let password = {
  fieldKey: "password",
  type: "password",
  label: strings.form.user.password.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 5,
      },
      invalidFeedback: strings.form.user.password.invalid.assertEmpty,
      defaultValue: true,
      target: "main"
    },
  ],
  showVerification: true,
  verification: {
    label: strings.form.user.passwordVerification.label,
  }
}

let notifiedByMail = {
  fieldKey: "notified_by_mail",
  type: "switch",
  label: strings.form.user.notifiedByMail.label,
  defaultValue: true,
  validators: []
}

let notifiedByWeb = {
  fieldKey: "notified_by_web",
  type: "switch",
  label: strings.form.user.notifiedByWeb.label,
  defaultValue: false,
  validators: []
}

let notifiedByFlags = {
  fieldKey: "notifiedByFlags",
  type: "table",
  label: strings.form.user.notifiedByFlags.label,
  validators: [],

  table: {
    headers: [
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: "name",
        label: strings.form.user.notifiedByFlags.header.name,
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "switch",
        value: "notifiedByMail",
        label: strings.form.user.notifiedByFlags.header.notifiedByMail,
        editable: true
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "switch",
        value: "notifiedByWeb",
        label: strings.form.user.notifiedByFlags.header.notifiedByWeb,
        editable: true
      }
    ]
  }
}

let profils = {
  fieldKey: "profils",
  type: "array",
  label: strings.form.user.roles.label,

  submitRemoval: true,
  removalCondition: (value) => value === undefined || value === '',
  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.user.roles.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.user.roles.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.user.roles.allowMoreField.removingLabel,

  validators: [],

  subField: {
    type: "dropdownEntity",
    label: strings.form.user.roles.label,

    showEmpty: true,
    storage: 'redux',
    // loader: {
    //   id: "profils",
    //   loader: fetchProfils,
    //   loaderRequire: () => true,
    //   loaderArguments: () => [],
    // },

    adapter: {
      requirement: (data) => data && typeof data === 'object' && data["id"] && data["label"],
      getValue: (data) => data["id"],
      getLabel: (data) => strings.resolve(data["label"]),
    },

    validators: [],
  }
}

let partner = {
  fieldKey: "partner",
  type: "dropdownEntity",
  label: strings.form.user.partner.label,

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.user.partner.invalid.assertEmpty,
      defaultValue: false,
    },
    {
      id: 'assertObject',
      type: 'function',
      conf: {
        function: (item) => typeof item === 'object'
      },
      invalidFeedback: strings.form.user.partner.invalid.assertEmpty,
      defaultValue: false,
    },
  ],

  showEmpty: true,
  storage: 'redux',
  loader: {
    id: "partner",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["legal_form"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["legal_form"] + " " + data["name"],
  },
}

let partnerAccount = {
  fieldKey: "partner_account",
  type: "dropdownEntity",
  label: strings.form.user.partnerAccount.label,

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.user.partnerAccount.invalid.assertEmpty,
      defaultValue: false,
    },
  ],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "partner",
      function: (component) => component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "partnerAccount",
    loader: fetchPartnerAccounts,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["partner"] && getValues(0)["partner"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["partner"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["partner_type"] && data["partner_type"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["partner_type"]["name"],
  },
}

export const partnerRoleForm = {
  formKey: "user",
  fields: [
    partner,
    partnerAccount,
    Object.assign({}, profils, {
      label: strings.form.user.roles.labelEditable,
      fieldKeyUuid: 'editable',
      filterValues: (key, value, getValues, getExtraValues) => {
        let ownProfils = getExtraValues().ownProfils;
        if(ownProfils && ownProfils.item) {
          //empty field
          //half filled field
          //admin user role
          //filter out fields that the user can't modify
          return value === undefined
            || typeof value === 'string'
            || ownProfils.item.roles.includes('ROLE_USER_ADMIN')
            || ownProfils.item.roles.includes('ROLE_USER_CREATE_PARTNER')
            || Object.values(ownProfils.item["user_partners"]).some((userPartner) => (userPartner["partner_account"]["id"] === getValues(1)["partner_account"]["id"]) && userPartner["profils"].some((item) => value.group.id === item.group.id && ((item.group.id !== 1 && value.hierarchy < item.hierarchy) || (item.group.id === 1 && value.hierarchy <= item.hierarchy))))//.some((item) => value.group.id === item.group.id && value.hierarchy < item.hierarchy);
        }
        return false;
      },
      subField: {
        ...profils.subField,
        filterOptions: (value, getValues, getExtraValues) => {
          let ownProfils = getExtraValues().ownProfils;
          if(ownProfils && ownProfils.item) {
            let partner_account = getValues(1).partner_account;
            let account = null;
            let profils = null;
            if(partner_account && ownProfils.item.user_partners) account = Object.values(ownProfils.item.user_partners).find((user_partner) => partner_account.id === user_partner.partner_account.id);
            if(account) profils = account.profils;
            //check if profils is set
            //filter out options already selected
            //admin user role
            //filter out options that the user can't add/remove/modify
            return (!getValues(1).profils
              || !getValues(1).profils.some((item) => item && value.id === item.id))
              && (ownProfils.item.roles.includes('ROLE_USER_ADMIN')
              || ownProfils.item.roles.includes('ROLE_USER_CREATE_PARTNER')
              || (profils && Object.values(profils).some((item) => value.group.id === item.group.id && ((item.group.id !== 1 && value.hierarchy < item.hierarchy) || (item.group.id === 1 && value.hierarchy <= item.hierarchy))))
            );
          }
          return false;
        },
        loader: {
          id: "partnerProfils",
          loader: fetchPartnerProfils,
          loaderRequire: () => true,
          loaderArguments: () => [],
        }
      }
    }),
    Object.assign({}, profils, {
      disabled: true,
      label: strings.form.user.roles.labelNonEditable,
      filterValues: (key, value, getValues, getExtraValues) => {
        let ownProfils = getExtraValues().ownProfils;
        if(ownProfils && ownProfils.item) {
          //empty field
          //half filled field
          //admin user role
          //filter out fields that the user can't modify
          return value !== undefined
            && typeof value !== 'string'
            && !ownProfils.item.roles.includes('ROLE_USER_ADMIN')
            && !ownProfils.item.roles.includes('ROLE_USER_CREATE_PARTNER')
            && Object.values(ownProfils.item["user_partners"]).every((userPartner) => (userPartner["partner_account"]["id"] !== getValues(1)["partner_account"]["id"]) || userPartner["profils"].every((item) => value.group.id !== item.group.id || ((item.group.id !== 1 && value.hierarchy >= item.hierarchy) && (item.group.id === 1 && value.hierarchy > item.hierarchy))))
        }
        return false;
      },
      subField: {
        ...profils.subField,
        loader: {
          id: "innerProfils",
          loader: fetchInnerProfils,
          loaderRequire: () => true,
          loaderArguments: () => [],
        }
      }
    })
  ],
  extraValues: {
    ownProfils: {
      type: "load",
      loader: {
        loader: getProfilUserProfilModification,
        loaderRequire: () => true,
        loaderArguments: () => [],
      }
    }
  }
}

export const partnerRoleNewForm = {
  formKey: "user",
  fields: [
    partner,
    partnerAccount,
    Object.assign({}, profils, {
      subField: {
        ...profils.subField,
        filterOptions: (value, getValues, getExtraValues) => {
          let ownProfils = getExtraValues().ownProfils;
          if(ownProfils && ownProfils.item) {
            //getValues(1).partner.id ownProfils.item.partners.id
            //getValues(1).partner_account partner.accounts[].id
            //value account.profils
            let partner_account = getValues(1).partner_account;
            let account = null;
            let profils = null;
            if(partner_account && ownProfils.item.user_partners) account = Object.values(ownProfils.item.user_partners).find((user_partner) => partner_account.id === user_partner.partner_account.id);
            if(account) profils = account.profils;
            console.log(ownProfils.item, partner_account, account, profils);
            //check if profils is set
            //filter out options already selected
            //admin user role
            //filter out options that the user can't add/remove/modify
            return (!getValues(0).profils
              || !getValues(0).profils.some((item) => item && value.id === item.id))
              && (ownProfils.item.roles.includes('ROLE_USER_ADMIN')
              || ownProfils.item.roles.includes('ROLE_USER_CREATE_PARTNER')
              || (profils && Object.values(profils).some((item) => value.group.id === item.group.id && ((item.group.id !== 1 && value.hierarchy < item.hierarchy) || (item.group.id === 1 && value.hierarchy <= item.hierarchy))))
            );
          }
          return false;
        },
        loader: {
          id: "partnerProfils",
          loader: fetchPartnerProfils,
          loaderRequire: () => true,
          loaderArguments: () => [],
        }
      }
    })
  ]
}

let userPartners = {
  fieldKey: "user_partners",
  type: "array",
  label: strings.form.user.userPartners.label,

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.user.userPartners.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.user.userPartners.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.user.userPartners.allowMoreField.removingLabel,

  validators: [],

  subField: {
    type: "subform",
    label: strings.form.user.userPartner.label,

    subForm: partnerRoleForm,

    validators: [],
  }
}

let userPartnersNew = {
  fieldKey: "user_partners",
  type: "array",
  label: strings.form.user.userPartners.label,

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.user.userPartners.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.user.userPartners.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.user.userPartners.allowMoreField.removingLabel,

  validators: [],

  subField: {
    type: "subform",
    label: strings.form.user.userPartner.label,

    subForm: partnerRoleNewForm,

    validators: [],
  }
}

export const form = {
  formKey: "user",
  fields: [
    username,
    firstname,
    lastname,
    mail,
    phoneNumber,
    mobilePhoneNumber,
    img
  ]
}

export const roleForm = {
  formKey: "user",
  fields: [
    Object.assign({}, profils, {
      subField: {
        ...profils.subField,
        loader: {
          id: "innerProfils",
          loader: fetchInnerProfils,
          loaderRequire: () => true,
          loaderArguments: () => [],
        }
      }
    })
  ],
}

export const roleEditForm = {
  formKey: "user",
  fields: [
    Object.assign({}, profils, {
      label: strings.form.user.roles.labelEditable,
      fieldKeyUuid: 'editable',
      filterValues: (key, value, getValues, getExtraValues) => {
        let ownProfils = getExtraValues().ownProfils;
        if(ownProfils && ownProfils.item) {
          //empty field
          //half filled field
          //admin user role
          //filter out fields that the user can't modify
          return value === undefined
            || typeof value === 'string'
            || ownProfils.item.roles.includes('ROLE_USER_ADMIN')
            || Object.values(ownProfils.item.profils).some((item) => value.group.id === item.group.id && value.hierarchy < item.hierarchy);
        }
        return false;
      },
      subField: {
        ...profils.subField,
        filterOptions: (value, getValues, getExtraValues) => {
          let ownProfils = getExtraValues().ownProfils;
          if(ownProfils && ownProfils.item) {
            //check if profils is set
            //filter out options already selected
            //admin user role
            //filter out options that the user can't add/remove/modify
            return (!getValues().profils
              || !getValues().profils.some((item) => item && value.id === item.id))
              && (ownProfils.item.roles.includes('ROLE_USER_ADMIN')
              || Object.values(ownProfils.item.profils).some((item) => value.group.id === item.group.id && value.hierarchy < item.hierarchy));
          }
          return false;
        },
        loader: {
          id: "innerProfils",
          loader: fetchInnerProfils,
          loaderRequire: () => true,
          loaderArguments: () => [],
        }
      }
    }),
    Object.assign({}, profils, {
      disabled: true,
      label: strings.form.user.roles.labelNonEditable,
      filterValues: (key, value, getValues, getExtraData) => {
        let ownProfils = getExtraData().ownProfils;
        if(ownProfils && ownProfils.item) {
          //filter out empty field
          //filter out half filled field
          //admin user role
          //filter out fields that the user can modify
          return value !== undefined
            && typeof value !== 'string'
            && !ownProfils.item.roles.includes('ROLE_USER_ADMIN')
            && Object.values(ownProfils.item.profils).every((item) => value.group.id !== item.group.id || (value.group.id === item.group.id && value.hierarchy >= item.hierarchy));
        }
        return false;
      },
      subField: {
        ...profils.subField,
        loader: {
          id: "innerProfils",
          loader: fetchInnerProfils,
          loaderRequire: () => true,
          loaderArguments: () => [],
        }
      }
    })
  ],
  extraValues: {
    ownProfils: {
      type: "load",
      loader: {
        loader: getProfilUserProfilModification,
        loaderRequire: () => true,
        loaderArguments: () => [],
      }
    }
  }
}

export const partnerForm = {
  formKey: "user",
  fields: [
    userPartners,
  ],
  extraValues: {
    ownProfils: {
      type: "load",
      loader: {
        loader: getProfilUserProfilModification,
        loaderRequire: () => true,
        loaderArguments: () => [],
      }
    }
  }
}

export const passwordForm = {
  formKey: "user",
  fields: [
    password
  ]
}

export const notificationForm = {
  formKey: "user",
  fields: [
    // notifiedByMail,
    // notifiedByWeb,
    notifiedByFlags
  ]
}



let defaultEscalateLevel = {
  fieldKey: "defaultEscalateLevel",
  type: "dropdown",
  label: strings.form.user.defaultEscalateLevel.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 1,
      label: strings.form.user.defaultEscalateLevel.options._1
    },
    {
      value: 2,
      label: strings.form.user.defaultEscalateLevel.options._2
    },
    {
      value: 3,
      label: strings.form.user.defaultEscalateLevel.options._3
    }
  ],
}

let productSubFamilylevel = (fieldKey) => {
  return {
    fieldKey: fieldKey,
    label: "",
    type: "dropdownMultiEntityAlt",

    validators: [],

    showEmpty: true,
    storage: "redux",
    shareStorage: true,
    filterOptions: (item, getValues, getExtraValues) => {
      let values = getValues(0);
      // console.log(item, values);
      if(fieldKey !== "level1" && values.level1 && values.level1[item.id] === true) {
        return false;
      }
      if(fieldKey !== "level2" && values.level2 && values.level2[item.id] === true) {
        return false;
      }
      if(fieldKey !== "level3" && values.level3 && values.level3[item.id] === true) {
        return false;
      }
      return true;
    },
    loader: {
      id: "productSubFamilies",
      loader: fetchSubFamilies,
      loaderRequire: () => true,
      loaderArguments: () => [{
        available_as_ticket_origin: 1
      }],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => `${data["product_family"]?data["product_family"]["name"]+" > ":""}${data["name"]}`,
    },
  }
}

export const _escalateLevelForm = {
  formKey: "escalateLevel",
  fields: [
    defaultEscalateLevel,
    Object.assign({}, productSubFamilylevel("level1"), {
      label: strings.form.user.productSubFamilylevel.level1
    }),
    Object.assign({}, productSubFamilylevel("level2"), {
      label: strings.form.user.productSubFamilylevel.level2
    }),
    Object.assign({}, productSubFamilylevel("level3"), {
      label: strings.form.user.productSubFamilylevel.level3
    })
  ]
}

let escalateLevel = {
  fieldKey: "",
  type: "subform",
  label: "",

  subForm: _escalateLevelForm,

  validators: [],
}

export const escalateLevelForm = {
  formKey: "user",
  fields: [
    Object.assign({}, defaultEscalateLevel, {
      showOnDisabled: (getValues, getExtraValues) => {
        let credentials = getExtraValues().credentials();
        return credentials.roles.includes('ROLE_USER_ESCALATE_LEVEL') || credentials.roles.includes('ROLE_USER_ADMIN');
      },
      disabled: (getValues, getExtraValues) => {
        let credentials = getExtraValues().credentials();
        return !credentials.roles.includes('ROLE_USER_ESCALATE_LEVEL') && !credentials.roles.includes('ROLE_USER_ADMIN');
      },
    }),
    Object.assign({}, escalateLevel, {
      fieldKey: "technicalEscalateLevel",
      label: strings.form.user.technicalEscalateLevel.label,
      showOnDisabled: (getValues, getExtraValues) => {
        let credentials = getExtraValues().credentials();
        return credentials.roles.includes('ROLE_USER_ESCALATE_LEVEL_TECHNICAL') || credentials.roles.includes('ROLE_USER_ADMIN');
      },
      disabled: (getValues, getExtraValues) => {
        let credentials = getExtraValues().credentials();
        return !credentials.roles.includes('ROLE_USER_ESCALATE_LEVEL_TECHNICAL') && !credentials.roles.includes('ROLE_USER_ADMIN');
      },
    }),
    Object.assign({}, escalateLevel, {
      fieldKey: "commercialEscalateLevel",
      label: strings.form.user.commercialEscalateLevel.label,
      showOnDisabled: (getValues, getExtraValues) => {
        let credentials = getExtraValues().credentials();
        return credentials.roles.includes('ROLE_USER_ESCALATE_LEVEL_COMMERCIAL') || credentials.roles.includes('ROLE_USER_ADMIN');
      },
      disabled: (getValues, getExtraValues) => {
        let credentials = getExtraValues().credentials();
        return !credentials.roles.includes('ROLE_USER_ESCALATE_LEVEL_COMMERCIAL') && !credentials.roles.includes('ROLE_USER_ADMIN');
      },
    }),
    Object.assign({}, escalateLevel, {
      fieldKey: "administrativeEscalateLevel",
      label: strings.form.user.administrativeEscalateLevel.label,
      showOnDisabled: (getValues, getExtraValues) => {
        let credentials = getExtraValues().credentials();
        return credentials.roles.includes('ROLE_USER_ESCALATE_LEVEL_ADMINISTRATIVE') || credentials.roles.includes('ROLE_USER_ADMIN');
      },
      disabled: (getValues, getExtraValues) => {
        let credentials = getExtraValues().credentials();
        return !credentials.roles.includes('ROLE_USER_ESCALATE_LEVEL_ADMINISTRATIVE') && !credentials.roles.includes('ROLE_USER_ADMIN');
      },
    })
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const newForm = {
  formKey: "user",
  fields: [
    username,
    firstname,
    lastname,
    mail,
    phoneNumber,
    mobilePhoneNumber,
    notifiedByMail,
    notifiedByWeb,
    Object.assign({}, profils, {
      disabled: (getValues, getExtraValues) => {
        let ownProfils = getExtraValues().ownProfils;
        return !(ownProfils && ownProfils.item.roles.includes('ROLE_USER_CREATE_INNER'));
      },
      subField: {
        ...profils.subField,
        filterOptions: (value, getValues, getExtraValues) => {
          let ownProfils = getExtraValues().ownProfils;
          if(ownProfils && ownProfils.item) {
            //check if profils is set
            //filter out options already selected
            //admin user role
            //filter out fields that the user can't add
            return (!getValues().profils
              || !getValues().profils.some((item) => item && value.id === item.id))
              && (ownProfils.item.roles.includes('ROLE_USER_ADMIN')
              || Object.values(ownProfils.item.profils).some((item) => value.group.id === item.group.id && value.hierarchy < item.hierarchy));
          }
          return false;
        },
        loader: {
          id: "innerProfils",
          loader: fetchInnerProfils,
          loaderRequire: () => true,
          loaderArguments: () => [],
        }
      }
    }),
    Object.assign({}, userPartnersNew, {
      defaultNumberOfField: 0,
    }),
  ],
  extraValues: {
    ownProfils: {
      type: "load",
      loader: {
        loader: getProfilUserProfilModification,
        loaderRequire: () => true,
        loaderArguments: () => [],
      }
    }
  }
}
