import React from 'react';

import { Redirect } from "react-router-dom";

import { main as mainRoutes } from '../../MetaData/Route/routes';

/**
 * Error
 *
 * This is the default error page of the application
 *
 * TODO make a error 404 page
 */
class Error extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    // return (
    //   <>
    //     <MainLayout>
    //       <Redirect
    //       <div>La page que vous recherchez n'existe pas</div>
    //     </MainLayout>
    //   </>
    // );
    return (
      <Redirect to={mainRoutes.routes.login.createPath()}/>
    );
  }
}

export default Error;
