const productDependency = {
  tab: {
    main: "Dépendance",
    masters: "produits principaux",
    slaves: "Accessoires",
  },
  search: {
    name: "Nom"
  },
  new: {
    title: "Nouvelle dépendance de produit"
  }
};

export default productDependency;
