import strings from '../../../Localization/Localization';

let title = {
  fieldKey: "title",
  type: "text",
  label: strings.form.accountingType.title.label,
  showOnDisabled: () => false,
  validators: []
}

let collectiveClientNumber = {
  fieldKey: "collectiveClientNumber",
  type: "text",
  label: strings.form.accountingType.collectiveClientNumber.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "accountingTypeSearch",
  fields: [
    title,
    collectiveClientNumber
  ]
}
