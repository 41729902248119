import strings from '../../../Localization/Localization';

import { store } from '../../../Store/configureStore';

import { fetchFamilies } from '../../../API/ProductFamilies';
import { fetchSubFamilies } from '../../../API/ProductSubFamilies';

import { fetchConstructors } from '../../../API/Constructors';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.product.name.label,
  showOnDisabled: () => false,
  validators: []
}

let code = {
  fieldKey: "code",
  type: "text",
  label: strings.form.product.code.label,
  showOnDisabled: () => false,
  validators: []
}

let description = {
  fieldKey: "description",
  type: "text",
  label: strings.form.product.description.label,
  showOnDisabled: () => false,
  validators: []
}

let price = {
  fieldKey: "price",
  type: "price",
  label: strings.form.product.price.label,
  showOnDisabled: () => false,
  validators: []
}

let priceType = {
  fieldKey: "priceType",
  type: "dropdown",
  label: strings.form.product.priceType.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.product.priceType.options.option0
    },
    {
      value: 1,
      label: strings.form.product.priceType.options.option1
    },
    {
      value: 2,
      label: strings.form.product.priceType.options.option2
    }
  ],
}

// let buyingPrice = {
//   fieldKey: "buyingPrice",
//   type: "price",
//   label: strings.form.product.buyingPrice.label,
//   showOnDisabled: () => false,
//   disabled: (getValues, getExtraValues) => {
//     let credentials = getExtraValues().credentials();
//     return !credentials.roles.includes('ROLE_NYUKOM');
//   },
//   validators: []
// }

// let buyingCompany = {
//   fieldKey: "buyingCompany",
//   type: "text",
//   label: strings.form.buyingPrice.company.label,
//   showOnDisabled: () => false,
//   disabled: (getValues, getExtraValues) => {
//     let credentials = getExtraValues().credentials();
//     return !credentials.roles.includes('ROLE_NYUKOM');
//   },
//   validators: []
// }

let buyingReference = {
  fieldKey: "buyingReference",
  type: "text",
  label: strings.form.buyingPrice.reference.label,
  showOnDisabled: () => false,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  validators: []
}

// let createdAtMin = {
//   fieldKey: "createdAtMin",
//   type: "date",
//   label: strings.form.product.createdAtMin.label,
//   showOnDisabled: () => false,
//   disabled: (getValues, getExtraValues) => {
//     let credentials = getExtraValues().credentials();
//     return !credentials.roles.includes('ROLE_NYUKOM');
//   },
//   validators: []
// }

// let createdAtMax = {
//   fieldKey: "createdAtMax",
//   type: "date",
//   label: strings.form.product.createdAtMax.label,
//   showOnDisabled: () => false,
//   disabled: (getValues, getExtraValues) => {
//     let credentials = getExtraValues().credentials();
//     return !credentials.roles.includes('ROLE_NYUKOM');
//   },
//   validators: []
// }

// let updatedAtMin = {
//   fieldKey: "updatedAtMin",
//   type: "date",
//   label: strings.form.product.updatedAtMin.label,
//   showOnDisabled: () => false,
//   disabled: (getValues, getExtraValues) => {
//     let credentials = getExtraValues().credentials();
//     return !credentials.roles.includes('ROLE_NYUKOM');
//   },
//   validators: []
// }

// let updatedAtMax = {
//   fieldKey: "updatedAtMax",
//   type: "date",
//   label: strings.form.product.updatedAtMax.label,
//   showOnDisabled: () => false,
//   disabled: (getValues, getExtraValues) => {
//     let credentials = getExtraValues().credentials();
//     return !credentials.roles.includes('ROLE_NYUKOM');
//   },
//   validators: []
// }

// let activeOrder = {
//   fieldKey: "activeOrder",
//   type: "triswitch",
//   label: strings.form.product.activeOrder.label,
//   showOnDisabled: () => false,
//   validators: []
// }

// let activeQuote = {
//   fieldKey: "activeQuote",
//   type: "triswitch",
//   label: strings.form.product.activeQuote.label,
//   showOnDisabled: () => false,
//   validators: []
// }

// let activeTicket = {
//   fieldKey: "activeTicket",
//   type: "triswitch",
//   label: strings.form.product.activeTicket.label,
//   showOnDisabled: () => false,
//   validators: []
// }

// let rentable = {
//   fieldKey: "rentable",
//   type: "triswitch",
//   label: strings.form.product.rentable.label,
//   showOnDisabled: () => false,
//   validators: []
// }

let productFamily = {
  fieldKey: "productFamily",
  label: strings.form.product.productFamily.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "productFamilies",
    loader: fetchFamilies,
    loaderRequire: () => true,
    loaderArguments: () => [{
      display_main_index: 1
    }],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let productSubFamily = {
  fieldKey: "productSubFamily",
  label: strings.form.product.productSubFamily.label,
  type: "dropdownEntity",

  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "productFamily",
      function: (component) => component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "productSubFamilies",
    loader: fetchSubFamilies,
    loaderRequire: (value, getValues) => getValues(0)["productFamily"] && getValues(0)["productFamily"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        display_main_index: 1,
        product_family_id: getValues(0)["productFamily"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let constructor = {
  fieldKey: "productConstructor",
  type: "dropdownEntity",
  label: strings.form.product.constructor.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  loader: {
    id: "constructors",
    loader: fetchConstructors,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let displayHidden = {
  fieldKey: "displayHidden",
  type: "switch",
  label: strings.form.product.displayHidden.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "productSubFamilySearch",
  fields: [
    name,
    code,
    productFamily,
    productSubFamily,
    description,
    priceType,
    price,
    // buyingPrice,
    // buyingCompany,
    buyingReference,
    // createdAtMin,
    // createdAtMax,
    // updatedAtMin,
    // updatedAtMax
    // activeOrder,
    // activeQuote,
    // activeTicket,
    // rentable
    constructor,
    displayHidden
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
