import strings from '../../Localization/Localization';

import { fetchRoleGroups } from '../../API/RoleGroups';
import { fetchRoles } from '../../API/Roles';
import { fetchProfils } from '../../API/Profils';

let label = {
  fieldKey: "label",
  type: "text",
  label: strings.form.profil.label.label,

  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      invalidFeedback: strings.form.profil.label.invalid.assertEmpty,
      conf: {
        min: 1
      },
      defaultValue: false
    },
  ]
}

let group = {
  fieldKey: "group",
  type: "dropdownEntity",
  label: strings.form.profil.group.label,

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.profil.group.invalid.assertEmpty,
      defaultValue: false,
    }
  ],

  showEmpty: true,
  storage: 'redux',
  loader: {
    id: "profils",
    loader: fetchRoleGroups,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["label"],
    getValue: (data) => data["id"],
    getLabel: (data) => strings.resolve('common.'+data["label"]),
  },
}

let hierarchy = {
  fieldKey: "hierarchy",
  type: "text",
  label: strings.form.profil.hierarchy.label,

  validators: []
}

let active = {
  fieldKey: "active",
  type: "switch",
  label: strings.form.profil.active.label,

  validators: []
}

let availableInner = {
  fieldKey: "available_inner",
  type: "switch",
  label: strings.form.profil.availableInner.label,

  validators: []
}

let availablePartner = {
  fieldKey: "available_partner",
  type: "switch",
  label: strings.form.profil.availablePartner.label,

  validators: []
}

let roles = {
  fieldKey: "roles",
  type: "array",
  label: strings.form.profil.roles.label,

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.profil.roles.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.profil.roles.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.profil.roles.allowMoreField.removingLabel,

  validators: [],

  subField: {
    type: "dropdownEntity",
    label: strings.form.profil.roles.label,

    validators: [],

    events: [
      {
        id: "reload",
        event: "onChange",
        target: "group",
        function: (component) => component.reloadData()
      },
      {
        id: "reload",
        event: "onChange",
        target: "available_inner",
        function: (component) => component.reloadData()
      },
      {
        id: "reload",
        event: "onChange",
        target: "available_partner",
        function: (component) => component.reloadData()
      }
    ],

    showEmpty: true,
    storage: "redux",
    shareStorage: true,
    filterOptions: (item, getValues) =>
      !getValues(1)['roles'].some((value) => value && value.id === item.id)
      && ((getValues()["available_inner"] === true && item["include_inner"] === true)
      || (getValues()["available_partner"] === true && item["include_partner"] === true))
      ,
    loader: {
      id: "roles",
      loader: fetchRoles,
      loaderRequire: (value, getValues) => getValues(1)["group"] && getValues(1)["group"]["id"],
      loaderArguments: (value, getValues) => {
        let search = {
          group_id: getValues(1)["group"]["id"]
        }
        if(getValues()["available_inner"]) {
          search["include_inner"] = 1;
        }
        if(getValues()["available_partner"]) {
          search["include_partner"] = 1;
        }
        return [search]
      },
      dataTransformation: (data) => data.items
    },

    adapter: {
      requirement: (data) => data["id"] && data["label"],
      getValue: (data) => data["id"],
      getLabel: (data) => strings.resolve('common.'+data["label"]),
    },
  }
}

let profilSubs = {
  fieldKey: "profil_subs",
  type: "array",
  label: strings.form.profil.profilSubs.label,

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.profil.profilSubs.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.profil.profilSubs.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.profil.profilSubs.allowMoreField.removingLabel,

  validators: [],

  subField: {
    type: "dropdownEntity",
    label: strings.form.profil.profilSubs.label,

    validators: [],

    events: [
      {
        id: "reload",
        event: "onChange",
        target: "group",
        function: (component) => component.reloadData()
      }
    ],

    showEmpty: true,
    shareStorage: true,
    filterOptions: (item, getValues) => !(getValues()["id"] === item["id"])
        && !getValues(1)["profil_subs"].some((value) => value && value.id === item.id)
        && getValues()["group"] && getValues()["group"]["id"] === item["group"]["id"]
        && ((getValues()["available_inner"] === true && item["available_inner"] === true)
        || (getValues()["available_partner"] === true && item["available_partner"] === true)),
    loader: {
      id: "profils",
      loader: fetchProfils,
      loaderRequire: (value, getValues) => getValues(1)["group"] && getValues(1)["group"]["id"],
      loaderArguments: (value, getValues) => {
        return [{
          group_id: getValues(1)["group"]["id"]
        }]
      },
      dataTransformation: (data) => data.items
    },

    adapter: {
      requirement: (data) => data["id"] && data["label"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["label"],
    },
  }
}

export const form = {
  formKey: "profil",
  fields: [
    label,
    group,
    active,
    availableInner,
    availablePartner,
    hierarchy,
    roles,
    profilSubs
  ]
}
