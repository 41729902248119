import React from 'react';

import { Container, Button, Row, Accordion, Card, Col, Modal } from 'react-bootstrap';

import { withModalHandler } from '../../HOC/ModalHandler';

import strings from '../../Localization/Localization';

import '../../css/cart_summary.css';
import '../../css/table.css';

import { connect } from 'react-redux';
import {
  modifyCartAction,
  resetCartAction,
  addCartSectionAction,
  removeCartSectionAction,
  modifyCartSectionOrderAction,
  modifySectionTitle,
  // addCartItemAction,
  addCartItemSlaveAction,
  removeCartItemAction,
  modifyCartItemsOrderAction,
  modifyCartItemRentedAction,
  modifyCartItemQuantityAction,
  modifyCartItemDiscountAction,
  modifyCartItemDiscountUnitAction,
  removeAmendmentAction
} from '../../Store/Action/cart';

import {
  addProductAction
} from '../../Store/Action/cartProduct';

import { resetBreadcrumbAction } from '../../Store/Action/breadcrumb';

import {
  carts as cartRoutes,
  orders as orderRoutes,
  quotes as quoteRoutes,
  services as serviceRoutes
} from '../../MetaData/Route/routes';

import Loading from '../Layout/Loading';

import MainLayout from '../Layout/MainLayout';
import SubFooter from '../Layout/SubFooter';
import MyForm from '../Form/MyForm';
import ItemIndex from '../ItemIndex/ItemIndex';

import HoldButton from '../Basic/HoldButton';
import AutoTooltip from '../Basic/AutoTooltip';


import DroppableCartItemSlot from '../DnD/Drop/CartItemSlot';
import DnDCartSection from '../DnD/Both/CartSection';
import DnDCartItem from '../DnD/Both/CartItem';

import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableBody from '../Table/TableBody';
import TableTextHeader from '../Table/Header/TableTextHeader';
import TableRow from '../Table/Body/TableRow';

import AsyncCounter from '../../Utils/Counter/AsyncCounter';
import FormulaParser from '../../Utils/FormulaParser/FormulaParser';

import { conf as productsConf } from '../../MetaData/Configuration/products';
import {
  form as cartForm,
  rightToCancellationForm
} from '../../MetaData/Form/Cart/cart';

import {
  getCartFromId as loadProduct,
  getCartWithDiscountFromId as loadProductWithDiscount
} from '../../API/Products';
import { getCartFromId as loadPartnerCart } from '../../API/Partners';
import { newItem as newCart } from '../../API/Carts';
import { newItem as newOrder } from '../../API/Orders';
import {
  newItem as newQuote,
  previewCommercialBonus,
} from '../../API/Quotes';
import { getLocationPriceModifier } from '../../API/Parameters';

/**
* Summary
*
* TODO AFFICHER LES VALEURS DE REMISEs SPECIFIQUES EN PLACEHOLDER
*/
class Summary extends React.Component {

  constructor(props) {
    super(props);

    if(this.props.credentials.roles.includes('ROLE_QUOTE_CREATE_WITH_DISCOUNT')) {
      this.loadProduct = this.props.modalHandler.addVerificationWithCallback(loadProductWithDiscount, this.postLoadProduct, this.postLoadProductFailure);
    }
    else {
      this.loadProduct = this.props.modalHandler.addVerificationWithCallback(loadProduct, this.postLoadProduct, this.postLoadProductFailure);
    }
    this.loadPartnerCart = this.props.modalHandler.addVerificationWithCallback(loadPartnerCart, this.postLoadPartnerCart, this.postLoadPartnerCartFailure);
    this.getLocationPriceModifier = this.props.modalHandler.addVerificationWithCallback(getLocationPriceModifier, this.postLocationPriceModifier, this.postLocationPriceModifierFailure);
    this.newCart = this.props.modalHandler.addVerificationWithCallback(newCart, this.postSaveCart, this.postSaveFailure);
    this.newOrder = this.props.modalHandler.addVerificationWithCallback(newOrder, this.postSaveOrder, this.postSaveFailure);
    this.newQuote = this.props.modalHandler.addVerificationWithCallback(newQuote, this.postSaveQuote, this.postSaveFailure);
    this.previewCommercialBonus = this.props.modalHandler.addVerificationWithCallback(previewCommercialBonus, this.postPreviewCommercialBonus, this.postPreviewCommercialBonusFailure);

    this.removeCartSectionWithVerification = this.props.modalHandler.addVerification(this.removeCartSection);

    this.counter = new AsyncCounter();

    this.locationFormula = new FormulaParser();

    this.state = {
      productLoad: (this.props.cart.cartItems.length === 0)?true:false,

      locationFormulaLoad: false,
      locationFormulaError: false,

      displayAccessory: true,

      partnerCartAskLoading: false,
      partnerCart: {},
      partnerCartLoading: false,
      partnerCartError: false,

      postLoading: false,
      postError: '',

      subItemChoice: false,
      relationName: '',
      relation: -1,
      relationMaster: -1,
      relationChoices: [],

      commercialBonusTotal: 0,
      commercialBonusValue: 0
    }
  }

  componentDidMount() {
    this.props.dispatch(resetBreadcrumbAction(window.location.pathname, strings.cart.custom.summary.breadcrumb));

    //reload products
    this.loadProducts();

    //reload loadPartnerCart
    if(this.props.cart.client && typeof this.props.cart.client === 'object') {
      this.setState({
        partnerCartLoading: true
      }, () => {
        this.loadPartnerCart(this.props.cart.client.id)
      })
    };

    //reload locationPriceModifier to calculate location price from product price
    this.getLocationPriceModifier();

    this.checkRefs();
    this.checkInitialCart();
  }

  componentDidUpdate() {
    //auto select dependency when it is mandatory and there is only one possibiiity
    if(this.props.cart) {
      this.props.cart.cartItems.forEach((cartItem) => {
        if(this.props.cartProduct.products[cartItem.product.id]) {
          let product = this.props.cartProduct.products[cartItem.product.id];
          product["product_slaves"]
            .filter((productSlave, i) => (cartItem.amendment === undefined || cartItem.amendment === null || productSlave["amendment_mandatory"] === true))
            .forEach((productSlave) => {
              if(productSlave.mandatory === true && productSlave["product_slaves"].length === 1 && !this.props.cart.cartItems.some(subCartItem => subCartItem.dependencyMaster && subCartItem.dependencyMaster.masterId === cartItem["_sid"] && subCartItem.dependencyMaster.relationId === productSlave["id"])) {
                this.props.dispatch(addCartItemSlaveAction(cartItem["_sid"], productSlave["id"], productSlave["product_slaves"][0]["id"]));
              }
            }
          );
        }
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if(props.cart.client && typeof props.cart.client === 'object'
      && (!state.partnerCart || props.cart.client.id !== state.partnerCart.id)
      && state.partnerCartLoading === false
    ) {
      return{
        partnerCartAskLoading: true
      };
    }
    return null;
  }

  loadProducts() {
    //reload products
    let productIds = [];
    this.props.cart.cartItems.forEach((cartItem, i) => {
      if(!productIds.includes(cartItem.product.id)) {
        productIds.push(cartItem.product.id);
      }
    });
    this.counter.reset();
    this.counter.setValue(productIds.length);
    this.counter.subscribe(0, () => this.setState({productLoad: true}));
    productIds.forEach((productId, i) => {
      this.loadProduct(productId);
    });
  }

  checkRefs = () => {
    this.discountRefs = {};
    Object.values(this.props.cart.cartItems).forEach((cartItem) => {
      if(!this.discountRefs[cartItem._sid]) this.discountRefs[cartItem._sid] = React.createRef();
    });
  }

  checkForPartnerCart = () => {
    if(this.state.partnerCartAskLoading) {
      Promise.resolve().then(() => {
        this.setState({
          partnerCartAskLoading: false,
          partnerCartLoading: true
        }, () => {
          this.loadPartnerCart(this.props.cart.client.id)
        })
      })
    }
  }

  checkInitialCart = () => {
    if(!this.state.productLoad) {
      Promise.resolve().then(() => {
        this.props.cart.cartItems.forEach((item) => {
          if(item.dependencyMaster) {
            let remove = false;
            let cartItemMaster = this.props.cart.cartItems.find(master => master._sid === item.dependencyMaster.masterId);
            if(!cartItemMaster) {
              remove = true
            }
            else {
              let productMaster = this.props.cartProduct.products[cartItemMaster.product.id];
              if(productMaster) {
                let relation = productMaster["product_slaves"].find(relation => relation.id === item.dependencyMaster.relationId)
                if(!relation) {
                  remove = true
                }
              }
            }
            if(remove) {
              this.removeCartItem(item._sid);
            }
          }
        });
      })
    }
  }



  findLastMaster(item) {
    if(item && item.dependencyMaster) {
      return this.findLastMaster(this.props.cart.cartItems.find((cartItem) => cartItem._sid === item.dependencyMaster.masterId));
    }
    else {
      return item;
    }
  }

  computeCartItemsData = () => {
    let data = [];
    let additionalData = {
      colorCounter: 1
    }
    this.props.cart.sections
      .sort((a, b) => a.order - b.order)
      .forEach((section) => this.computeCartItemsDataSection(section, data, additionalData));
    let length = 0;
    this.props.cart.cartItems
      .filter(item => item.section === undefined || item.section._sid === undefined)
      .filter(item => !item.dependencyMaster)
      .sort((a, b) => a.order - b.order)
      .forEach((cartItem, i) => {
        this.computeCartItemsDataCartItem(cartItem, null, data);
        length++;
      });

    let dropCartItemSlot = {
      _sid: 10000,
      type: "dropCartItemSlot",
      order: length+1,
      sectionId: null
    };
    data.push(dropCartItemSlot);

    return data;
  }

  computeCartItemsDataSection = (section, data, additionalData) => {
    let subTotalPrice = this.props.cart.cartItems
      .filter(item => {
        let itemToCheck = this.findLastMaster(item);
        return itemToCheck.section && itemToCheck.section._sid === section._sid
      })
      .reduce((prev, curr) => {
        let discount = this.calculateDiscount(curr);
        return prev + this.calculateSubTotal(this.calculateQuantity(curr), this.calculatePrice(curr), discount.value, discount.unit)
      }, 0);
    let sectionData = {
      _sid: section._sid,
      type: "section",
      colorCounter: additionalData.colorCounter,
      order: section.order,
      title: section.title,
      subTotalPrice: subTotalPrice
    }
    data.push(sectionData);
    let length = 0;
    this.props.cart.cartItems
      .filter(item => item.section !== undefined && item.section._sid === section._sid)
      .filter(item => !item.dependencyMaster)
      .sort((a, b) => a.order - b.order)
      .forEach((cartItem) => {
        this.computeCartItemsDataCartItem(cartItem, section._sid, data, additionalData);
        length++;
      });
      let dropCartItemSlot = {
        _sid: 1000+section._sid,
        type: "dropCartItemSlot",
        order: length+1,
        sectionId: section._sid
      };
      data.push(dropCartItemSlot);
      additionalData.colorCounter++;
  }

  computeCartItemsDataCartItem = (cartItem, sectionSid, data, additionalData, lockQuantity = false) => {
    let price = this.calculatePrice(cartItem);
    let quantity = this.calculateQuantity(cartItem);
    let minPrice = this.calculateMinPrice(cartItem);
    let discount = this.calculateDiscount(cartItem);
    let product = this.productFromId(cartItem.product.id);

    let type = "cartItem";
    if(cartItem.dependencyMaster) {
      type = "subCartItem";
    }
    else if(cartItem.amendment) {
      type = "amendment";
    }

    let cartItemData = {
      _sid: cartItem._sid,
      type: type,
      colorCounter: (additionalData && additionalData.colorCounter)?additionalData.colorCounter:null,
      order: cartItem.order,
      sectionId: sectionSid,
      name: product["name"],
      priceType: product["price_type"],
      billPeriod: product["contract"]?product["contract"]["bill_period"]:0,
      rentable: product["rentable"],
      rented: cartItem.rented,
      quantity: quantity,
      lockQuantity: lockQuantity,
      minPrice: minPrice,
      price: price,
      discount: discount.value,
      discountUnit: discount.unit,
      subTotalPrice: this.calculateSubTotal(quantity, price, discount.value, discount.unit),

      productId: cartItem.product.id,
      amendment: cartItem.amendment
    };
    data.push(cartItemData);

    if(product["product_slaves"]) {
      product["product_slaves"]
        .filter((productSlave, i) => (cartItem.amendment === undefined || cartItem.amendment === null || productSlave["amendment_mandatory"] === true)) //TODO check
        .forEach((productSlave, i) => {
          let empty = true;
          this.props.cart.cartItems
            .filter((cartItemSlave) => {
              return cartItemSlave.dependencyMaster
                && cartItemSlave.dependencyMaster.masterId === cartItem._sid
                && cartItemSlave.dependencyMaster.relationId === productSlave.id
            })
            .forEach((cartItemSlave) => {
              this.computeCartItemsDataCartItem(cartItemSlave, sectionSid, data, additionalData, productSlave.quantity === 1);
              empty = false;
            });

          if(empty && (productSlave.mandatory || this.state.displayAccessory)) {
            let cartItemSlave = {
              _sid: 10000+cartItem._sid*100+i,
              type: "emptySubCartItem",
              colorCounter: (additionalData && additionalData.colorCounter)?additionalData.colorCounter:null,
              master: cartItem._sid,
              name: productSlave.displayname?productSlave.displayname:productSlave.name,
              relation: productSlave.id,
              choices: productSlave["product_slaves"],
              mandatory: productSlave.mandatory
            };
            data.push(cartItemSlave);
          }
        }
      );
    }
  }

  computeRecapData = () => {
    let result = {};
    result.tvas = [];
    result.data = [];

    let vatApplicable = true;
    let account = null;
    if(this.state.partnerCart && this.state.partnerCart.accounts && this.props.cart.clientAccount) {
      account = this.state.partnerCart.accounts.find((account) => account.id === this.props.cart.clientAccount.id);
    }
    if(account) {
      vatApplicable = account["partner_type"]["vat_applicable"];
    }
    this.props.cart.cartItems
      .forEach((cartItem, i) => {
        let product = this.productFromId(cartItem.product["id"])
        let priceType = product["price_type"];
        if(priceType === 0 && cartItem.rented === true) {
          priceType = -1;
        }
        let linePriceType = this.priceTypeTrans(priceType);
        if(priceType === 1) {
          linePriceType += ' ' + this.billPeriodTrans(product["contract"]["bill_period"]);
        }

        let price = this.calculatePrice(cartItem);
        let discount = this.calculateDiscount(cartItem);
        let quantity = this.calculateQuantity(cartItem);
        let subTotalPrice = this.calculateSubTotal(quantity, price, discount.value, discount.unit);
        let vatPercentage = (vatApplicable)?product["vat_percentage"]:0;
        let vatPrice = Math.floor(100*(subTotalPrice*vatPercentage/100))/100;

        if(!result.tvas.includes(vatPercentage)) {
          result.tvas.push(vatPercentage);
        }

        let done = false;

        result.data.forEach((line) => {
          if(line.priceType === linePriceType) {
            line.subTotalPrice += subTotalPrice;
            line.totalPrice += subTotalPrice + vatPrice;

            if(!line[vatPercentage]) {
              line[vatPercentage] = 0;
            }
            line[vatPercentage] += vatPrice;

            done = true;
          }
        });

        if(!done) {
          let line = {
            priceType: linePriceType,
            subTotalPrice: subTotalPrice,
            totalPrice: subTotalPrice + vatPrice
          }
          line[vatPercentage] = vatPrice;
          result.data.push(line);
        }
      }
    );

    result.data.forEach((line) => {
      line.subTotalPrice = this.priceFormat(line.subTotalPrice);
      line.totalPrice = this.priceFormat(line.totalPrice);
      result.tvas.forEach((tva) => {
        if(line[tva] || line[tva] === 0) {
          if(tva === 0) {
            line[tva] = '-';
          }
          else {
            line[tva] = this.priceFormat(line[tva]);
          }
        }
      });
    });

    return result;
  }

  computeFinalCart = () => {
    let cartItems = [];
    this.props.cart.cartItems.forEach((cartItem) => {
      let price = this.calculatePrice(cartItem, false);
      let quantity = this.calculateQuantity(cartItem);
      let discount = this.calculateDiscount(cartItem);

      let amendment = null;
      if(cartItem.amendment) {
        amendment = {
          previous_cart_item: cartItem.amendment.previousCartItem,
          service: cartItem.amendment.service,
          quantity_additional: cartItem.amendment.types.includes('quantity')?amendment.quantityAdditional:false
        }
      }

      let newCartItem = {
        _sid: cartItem._sid,
        quantity: quantity,
        price: price,
        discount: discount.value,
        discount_unit: discount.unit,
        rented: cartItem.rented,
        order: cartItem.order,
        section: cartItem.section,
        product: cartItem.product,
        dependency_master: cartItem.dependencyMaster?{ cart_item_master: { _sid: cartItem.dependencyMaster.masterId }, product_dependency: {id: cartItem.dependencyMaster.relationId} }:null,
        amendment: amendment
      }
      cartItems.push(newCartItem);
    });
    let sections = [];
    this.props.cart.sections.forEach((section) => {
      let newSection = {
        _sid: section._sid,
        title: section.title,
        order: section.order
      }
      sections.push(newSection);
    });
    let cart = {
      title: this.props.cart.title,
      description: this.props.cart.description,
      place_delivery: (this.props.cart.placeDelivery && this.props.cart.placeDelivery.id)?{ id: this.props.cart.placeDelivery.id }:null,
      client: (this.props.cart.clientAccount && this.props.cart.clientAccount.id)?{ id: this.props.cart.clientAccount.id }:null,
      commercial_referrer: (this.props.cart.mainUser && this.props.cart.mainUser.id)?{ id: this.props.cart.mainUser.id }:null,
      client_referrer: (this.props.cart.clientUser && this.props.cart.clientUser.id)?{ id: this.props.cart.clientUser.id }:null,
      cart_items: cartItems,
      sections: sections
    }
    return cart;
  }

  computeFinalQuote = () => {
    let quote = {
      cart: this.computeFinalCart()
    }
    return quote;
  }

  computeFinalOrder = () => {
    let order = {
      cart: this.computeFinalCart(),
      right_to_cancellation: !this.props.cart.relinquishRightToCancellation,
    }
    return order;
  }

  checkCart = (cart, ignoreUser = false) => {
    let result = true;
    if(cart["place_delivery"] === null
      || cart["client"] === null
      || (!ignoreUser && cart["commercial_referrer"] === null)
      || (!ignoreUser && cart["client_referrer"] === null)
      || cart["cart_items"].length === 0){
      result = false;
    }
    result = result && cart["cart_items"].every((cartItem) => {
      let product = this.productFromId(cartItem.product.id);
      return product["product_slaves"].every((productSlave) => {
        if((!cartItem.amendment && productSlave['mandatory']) || (!!cartItem.amendment && productSlave['amendment_mandatory'])) {
          return cart["cart_items"].some((cartItemSlave) => {
            return cartItemSlave["dependency_master"]
              && cartItem._sid === cartItemSlave["dependency_master"]["cart_item_master"]._sid
              && productSlave["product_slaves"].some((slaves) => slaves.id === cartItemSlave.product.id);
          });
        }
        return true;
      });
    });
    result = result && cart["sections"].every((section) => {
      return cart["cart_items"].some((cart_item) => {
        if(cart_item.section && cart_item.section._sid === section._sid) {
          return true;
        }
        return false;
      })
    });
    if(result === false) {
      this.setState({
        postError: "Tous les champs nécessaires ne sont pas remplis."
      })
    }
    return result;
  }



  postLoadProduct = (data) => {
    this.props.dispatch(addProductAction(data.item));
    this.counter.decrement();
  }

  postLoadProductFailure = (msg) => {
    this.counter.decrement();
  }

  postLoadPartnerCart = (data) => {
    this.setState({
      partnerCartLoading: false,
      partnerCart: data.item
    });
  }

  postLoadPartnerCartFailure = (msg) => {
    this.setState({
      partnerCartLoading: false,
      partnerCartError: true
    });
  }

  postLocationPriceModifier = (data) => {
    this.locationFormula.setFormula(data.item.value);
    this.setState({
      locationFormulaLoad: true
    })
  }

  postLocationPriceModifierFailure = (msg) => {
    this.setState({
      locationFormulaLoad: true,
      locationFormulaError: true,
    })
  }


  addCartSection() {
    this.props.dispatch(addCartSectionAction());
  }

  removeCartSection = (sectionId) => {
    this.props.dispatch(removeCartSectionAction(sectionId));
  }

  modifySectionTitle(sectionId, value) {
    this.props.dispatch(modifySectionTitle(sectionId, value));
  }

  modifyAmendment(cartItem) {
    let parameters = {};
    if(cartItem.amendment.types.includes('quantity')) {
      parameters.quantity = cartItem.quantity;
      parameters.quantityAdditional = cartItem.amendment.quantityAdditional;
    }
    if(cartItem.amendment.types.includes('product')) {
      parameters.product = this.productFromId(cartItem.productId)
    }
    if(cartItem.amendment.types.includes('place')) {
      parameters.place = cartItem.amendment.place
    }
    if(cartItem.amendment.types.includes('price')) {
      parameters.unitPrice = cartItem.amendment.unitPrice
    }
    parameters.types = cartItem.amendment.types;

    this.props.history.push(serviceRoutes.routes.amendment.createPath(cartItem.amendment.service.id), parameters);
  }

  removeCartItem(itemId) {
    this.props.dispatch(removeCartItemAction(itemId));
  }

  removeAmendment(itemId) {
    this.props.dispatch(removeAmendmentAction(itemId));
  }

  modifyCartItemOrder = (dragOrder, dragSectionId, hoverOrder, hoverSectionId) => {
    this.props.dispatch(modifyCartItemsOrderAction(dragOrder, dragSectionId, hoverOrder, hoverSectionId));
  }

  modifyCartSectionOrder = (dragOrder, hoverOrder) => {
    this.props.dispatch(modifyCartSectionOrderAction(dragOrder, hoverOrder));
  }

  modifyRented(itemId, rented) {
    this.props.dispatch(modifyCartItemRentedAction(itemId, rented === 'true'));
  }

  modifyQuantity(itemId, quantity) {
    quantity = Math.round(quantity);
    if(quantity < 0) {
      quantity = 0;
    }
    this.props.dispatch(modifyCartItemQuantityAction(itemId, quantity));
  }

  modifyDiscount(itemId, discount, discountUnit, price, minPrice) {
    let lastCharacter = "";
    if(typeof discount === 'string' && discount.endsWith('.')) lastCharacter = '.';
    if(typeof discount === 'string' && discount.endsWith(',')) {
      lastCharacter = '.'
      discount = discount.replace(',', '.');
    };
    discount = `${Math.round(discount*100)/100}`;

    if(this.calculateUnitPrice(price, discount, discountUnit) < minPrice) {
      if(this.discountRefs[itemId].current) this.discountRefs[itemId].current.toggle();
      if(discountUnit === '€') {
        discount = price - minPrice;
      }
      else if(discountUnit === '%') {
        discount = (price - minPrice)/price * 100;
      }
    }
    else if(discount < 0) {
      discount = 0;
    }

    discount = `${discount}${lastCharacter}`;

    this.props.dispatch(modifyCartItemDiscountAction(itemId, discount));
  }

  modifyDiscountUnit(itemId, discountUnit, discount, price) {
    if(discountUnit === '€') {
      discount = price * discount / 100;
    }
    else if(discountUnit === '%') {
      discount = (1-(price - discount)/price) * 100;
    }
    discount = `${Math.round(discount*100)/100}`;
    this.props.dispatch(modifyCartItemDiscountAction(itemId, discount));
    this.props.dispatch(modifyCartItemDiscountUnitAction(itemId, discountUnit));
  }

  onChangeItem = (key, value) => {
    if(key === "client" && value && typeof value === 'object') {
      this.setState({
        partnerCartLoading: true
      }, () => {
        this.loadPartnerCart(value.id);
      })
    }
    this.props.dispatch(modifyCartAction(key, value));
  }

  onClickAccessory = (e) => {
    //e.preventDefault();
    e.stopPropagation();
  }

  onChangeAccessory = (e) => {
    this.setState({displayAccessory: e.target.checked});
  }



  priceTypeTrans(priceType) {
    switch (priceType) {
      case 0:
        return strings.form.product.priceType.options.option0;
      case 1:
        return strings.form.product.priceType.options.option1;
      case 2:
        return strings.form.product.priceType.options.option2;
      case -1:
        return strings.form.product.priceType.options.option_1;
      default:

    }
  }

  billPeriodTrans(billPeriod) {
    switch (billPeriod) {
      case 1:
        return strings.form.contract.billPeriod.options.monthly;
      case 2:
        return strings.form.contract.billPeriod.options.fortnightly;
      case 3:
        return strings.form.contract.billPeriod.options.quarterly;
      case 6:
        return strings.form.contract.billPeriod.options.halfYearly;
      case 12:
        return strings.form.contract.billPeriod.options.yearly;
      default:
        return '';
    }
  }

  priceFormat(price) {
    return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
  }

  productFromId(productId) {
    if(this.props.cartProduct.products[productId]) {
      return this.props.cartProduct.products[productId];
    }
    else {
      Promise.resolve().then(() => this.setState({
        productLoad: false
      }, () => {
        this.counter.increment();
        this.loadProduct(productId);
      }));
      return {
        name: '',
        price: 0,
        priceType: 0,
        rentable: false,
        buyingPrices: [],
        product_slaves: [],
        vatPercentage: 0
      }
    }
  }

  calculatePrice(cartItem, location_modifier = true) {
    let price = this.productFromId(cartItem.product.id).price;
    if(cartItem.amendment && cartItem.amendment.unitPrice) {
      price = cartItem.amendment.unitPrice
    }
    if(cartItem.unitPrice) {
      price = cartItem.unitPrice;
    }
    if(location_modifier && this.state.locationFormulaLoad === true && cartItem.rented === true) {
      price = this.locationFormula.parse({price:price});
    }
    else if(this.state.locationFormulaLoad === false && cartItem.rented === true) {
      price = 0;
    }
    return price;
  }

  calculateQuantity(cartItem) {
    let result = cartItem.quantity;
    if(cartItem.dependencyMaster) {
      this.props.cart.cartItems.some((dependencyMaster) => {
        if(dependencyMaster._sid === cartItem.dependencyMaster.masterId) {
          let product = this.productFromId(dependencyMaster.product.id);
          product["product_slaves"].some((productSlave) => {
            if(cartItem.dependencyMaster.relationId === productSlave.id) {
              if(productSlave.quantity === 1) {
                result = this.calculateQuantity(dependencyMaster);
              }
              return true;
            }
            return false;
          });
        }
        return false;
      });
    }
    return result;
  }

  calculateMinPrice(cartItem) {
    let product = this.productFromId(cartItem.product.id);
    return product["min_price"];
  }

  calculateDiscount(cartItem) {
    let discount = {
      value: cartItem.discount,
      unit: cartItem.discountUnit
    };
    if(cartItem.rented) {
      discount.value = 0;
    }
    else if(+discount.value <= 0) {
      if(this.state.partnerCart && this.state.partnerCart.accounts && this.props.cart.clientAccount) {
        this.state.partnerCart.accounts.some((account) => {
          if(account.id === this.props.cart.clientAccount.id) {
            account["specific_prices"].some((specificPrice) => {
              if(specificPrice.product.id === cartItem.product.id) {
                discount.value = specificPrice["discount"];
                discount.unit = specificPrice["discount_unit"];
                return true;
              }
              return false;
            });
            return true;
          }
          return false;
        })
      }
    }
    return discount;
  }

  calculateUnitPrice(price, discount, discountUnit) {
    if(discountUnit === '€') {
      price -= discount;
    }
    else if(discountUnit === '%') {
      price *= (1 - discount/100);
    }
    return price;
  }

  calculateSubTotal(quantity, price, discount, discountUnit) {
    price = this.calculateUnitPrice(price, discount, discountUnit);
    return price * quantity;
  }



  startSubItemChoice = (item) => {
    let choices = [];
    item.choices.forEach((choice) => {
      if((choice.active_quote && this.props.credentials.roles.includes('ROLE_QUOTE_CREATE'))
        || (choice.active_order && this.props.credentials.roles.includes('ROLE_ORDER_CREATE')))
      return choices.push(choice);
    });
    this.setState({
      subItemChoice: true,
      relationName: item.name,
      relation: item.relation,
      relationMaster: item.master,
      relationChoices: choices,
    })
  }

  saveSubItemChoice = (itemId) => {
    this.props.dispatch(addCartItemSlaveAction(this.state.relationMaster, this.state.relation, itemId));

    this.setState({
      subItemChoice: false,
      relationName: '',
      relation: -1,
      relationMaster: -1,
      relationChoices: [],
    })
  }


  resetCart = () => {
    this.props.dispatch(resetCartAction());
  }

  saveCart = () => {
    this.setState({
      postLoading: true
    }, () => {
      let cart = this.computeFinalCart();
      this.newCart(cart);
    })
  }

  makeQuote = () => {
    this.setState({
      postLoading: true
    }, () => {
      let quote = this.computeFinalQuote();
      if(this.checkCart(quote.cart)) {
        this.newQuote(quote);
      }
      else {
        this.setState({
          postLoading: false
        })
      }
    })
  }

  previewCommercialBonusQuote = () => {
    this.setState({
      postLoading: true
    }, () => {
      let quote = this.computeFinalQuote();
      if(this.checkCart(quote.cart)) {
        this.previewCommercialBonus(quote);
      }
      else {
        this.setState({
          postLoading: false
        })
      }
    })
  }

  makeOrder = () => {
    this.setState({
      postLoading: true
    }, () => {
      let order = this.computeFinalOrder();
      if(this.checkCart(order.cart, true)) {
        this.newOrder(order);
      }
      else {
        this.setState({
          postLoading: false
        })
      }
    })
  }

  postSaveCart = (data) => {
    this.props.dispatch(resetCartAction());
    this.props.history.push(cartRoutes.routes.show.createPath(data.item.id));
  }

  postSaveOrder = (data) => {
    this.props.dispatch(resetCartAction());
    this.props.history.push(orderRoutes.routes.index.createPath());
  }

  postSaveQuote = (data) => {
    this.props.dispatch(resetCartAction());
    this.props.history.push(quoteRoutes.routes.show.createPath(data.item.id));
  }

  postSaveFailure = (msg) => {
    this.setState({
      postLoading: false,
    })
  }

  postPreviewCommercialBonus = (data) => {
    console.log(data);
    this.setState({
      postLoading: false,
      commercialBonusTotal: data["total_tf"],
      commercialBonusValue: data["bonus"]
    })
  }

  postPreviewCommercialBonusFailure = (msg) => {
    console.log(msg);
    this.setState({
      postLoading: false,
    })
  }


  shoudDisplayDiscount() {
    return this.props.credentials && this.props.credentials.roles && this.props.credentials.roles.includes('ROLE_QUOTE_CREATE_WITH_DISCOUNT');
  }

  shouldDisplayAmendmentButton() {
    return this.props.cart.cartItems && this.props.cart.cartItems.some(cartItem => !!cartItem.amendment);
  }


  displayPostError() {
    if(this.state.postError) {
      return (
        <Row style={{display: "flex"}} className="invalid-feedback">
          {this.state.postError}
        </Row>
      )
    }
    return null;
  }

  displayGeneralData() {
    return (
      <Row className="d-flex flex-column align-items-center">
        <MyForm
          formKey={cartForm.formKey}
          fields={cartForm.fields}
          extraData={cartForm.extraData}
          disabled={false}
          values={this.props.cart}
          extraValues={{ credentials: this.props.credentials }}
          onChangeField={this.onChangeItem}
          displaySubmitButton={false}
          onSubmit={this.onSubmit}
        />
      </Row>
    );
  }

  displayCartItemColgroup() {
    let nameSize = 52;
    let discountSize = 0;
    let discountUnitSize = 0;
    let buttonSize = 5;
    if(this.shoudDisplayDiscount()) {
      nameSize -= 14;
      discountSize += 11;
      discountUnitSize += 3;
    }
    if(this.shouldDisplayAmendmentButton()) {
      nameSize -= 5;
      buttonSize += 5;
    }

    return (
      <colgroup>
        <col style={{width: '5%'}}/>{/*line order*/}
        <col style={{width: nameSize+'%'}}/>{/*name*/}
        <col style={{width: '10%'}}/>{/*price_type*/}
        <col style={{width: '10%'}}/>{/*quantity*/}
        <col style={{width: '8%'}}/>{/*unit_price*/}
        <col style={{width: discountSize+'%'}}/>{/*discount*/}
        <col style={{width: discountUnitSize+'%'}}/>{/*discount_unit*/}
        <col style={{width: '10%'}}/>{/*sub_total*/}
        <col style={{width: buttonSize+'%'}}/>{/*delete button*/}
      </colgroup>
    )
  }

  displayCartItems() {
    if(this.state.productLoad && this.state.locationFormulaLoad) {
      let data = this.computeCartItemsData();
      return (
        <Row className="d-flex flex-column align-items-center">
          <datalist id="discountUnit">
            <option value="€"></option>
            <option value="%"></option>
          </datalist>
          <table className="table-my-primary cart-table">
            {this.displayCartItemColgroup()}
            <thead>
              <tr className="row-my-primary">
                <th></th>
                <th>Produit</th>
                <th>Type</th>
                <th>Qté</th>
                <th>PU (HT)</th>
                {this.shoudDisplayDiscount()?<th colSpan={2}>Remise</th>:<th colSpan={2}/>}
                <th>ST (HT)</th>
                <th></th>
              </tr>
            </thead>
            {data
              .map((item, i) => {
                let handleClassName = `cart-drag-handle-color-${item.colorCounter}`;
                switch (item.type) {
                  case "section":
                    return (
                      <DnDCartSection
                        key={item._sid}
                        id={item._sid}
                        order={item.order}
                        modifyCartSectionOrder={this.modifyCartSectionOrder}
                        trClassName="row-my-primary"
                        handleClassName={`${handleClassName}`}>
                        <td colSpan={this.shoudDisplayDiscount()?6:4}><div>{`Section : `}<input style={{width: "auto"}} className="cell-form p-0" value={item.title} onChange={(e) => this.modifySectionTitle(item._sid, e.target.value)} placeholder="Titre de ma section"/></div></td>
                        <td>
                          <div className="cell-container cell-container-price">
                            {this.priceFormat(item.subTotalPrice)}
                          </div>
                        </td>
                        <td><Button variant="my-secondary-noline" onClick={() => this.removeCartSectionWithVerification(item._sid)}><i className="icon-cross"/></Button></td>
                      </DnDCartSection>
                    );
                  case "cartItem":
                  case "subCartItem":
                    return (
                      <DnDCartItem
                        key={item._sid}
                        id={item._sid}
                        order={item.order}
                        sectionId={item.sectionId}
                        modifyCartItemOrder={this.modifyCartItemOrder}
                        trClassName="row-my-primary"
                        handleClassName={handleClassName}
                        subItem={(item.type === "subCartItem")?true:false}>
                          <td>{item.name}</td>
                          <td>
                            {item.rentable ?
                              <div className="cell-container cell-container-dropdown">
                                <select className="cell-form" value={item.rented} onChange={(e) => this.modifyRented(item._sid, e.target.value)}>
                                  <option value={false}>{this.priceTypeTrans(0)}</option>
                                  <option value={true}>{this.priceTypeTrans(-1)}</option>
                                </select>
                              </div>
                              :
                              `${this.priceTypeTrans(item.priceType)}${(item.priceType === 1)?' '+this.billPeriodTrans(item.billPeriod):''}`
                            }
                          </td>
                          <td>
                            {item.lockQuantity?
                              <div>{item.quantity}</div>:
                              <div className="d-flex cart-btn-pill-cell">
                                <HoldButton variant="my-terciary-outline" className="d-flex cart-btn-pill-half-left" onHold={() => this.modifyQuantity(item._sid, (item.quantity-1))}><i className="icon-minus"/></HoldButton>
                                <div className="cell-container cell-container-integer cart-btn-pill-middle">
                                  <input type="text" /*step={1}*/ className="cell-form cell-form-integer" style={{textAlign: 'center'}} value={item.quantity} onChange={(e) => this.modifyQuantity(item._sid, e.target.value)}/>
                                </div>
                                <HoldButton variant="my-terciary-outline" className="d-flex cart-btn-pill-half-right" onHold={() => this.modifyQuantity(item._sid, (+item.quantity+1))}><i className="icon-plus"/></HoldButton>
                              </div>
                            }
                          </td>
                          <td>
                            <div className="cell-container cell-container-price">
                              {this.priceFormat(item.price)}
                            </div>
                          </td>
                          {this.shoudDisplayDiscount()?
                            <td>
                              <div>
                                {item.rented ?
                                  <div className="d-flex justify-content-center">
                                    {item.discount}
                                  </div>
                                  :
                                  <div className="d-flex cart-btn-pill-cell">
                                    <HoldButton variant="my-terciary-outline" className="d-flex cart-btn-pill-half-left" onHold={() => this.modifyDiscount(item._sid, (item.discount-0.01), item.discountUnit, item.price, item.minPrice)}><i className="icon-minus"/></HoldButton>
                                      <AutoTooltip ref={this.discountRefs[item._sid]} text="Maximum de remise atteint">
                                        <div className="cell-container cell-container-integer cart-btn-pill-middle">
                                            <input type="text" /*step={0.01}*/ className="cell-form cell-form-integer" style={{textAlign: 'center'}} value={item.discount} onChange={(e) => this.modifyDiscount(item._sid, e.target.value, item.discountUnit, item.price, item.minPrice)}/>
                                        </div>
                                      </AutoTooltip>
                                    <HoldButton variant="my-terciary-outline" className="d-flex cart-btn-pill-half-right" onHold={() => this.modifyDiscount(item._sid, (+item.discount+0.01), item.discountUnit, item.price, item.minPrice)}><i className="icon-plus"/></HoldButton>
                                  </div>
                                }
                              </div>
                            </td>
                          :<td/>}
                          {this.shoudDisplayDiscount()?
                            <td>
                              <div>
                                <Button variant="my-secondary-noline" onClick={(e) => this.modifyDiscountUnit(item._sid, (item.discountUnit === '€')?'%':'€', item.discount, item.price)}>{item.discountUnit}</Button>
                              </div>
                            </td>
                          :<td/>}
                          <td>
                            <div className="cell-container cell-container-price">
                              {this.priceFormat(this.calculateSubTotal(item.quantity, item.price, item.discount, item.discountUnit))}
                            </div>
                          </td>
                          <td><Button variant="my-secondary-noline" onClick={() => this.removeCartItem(item._sid)}><i className="icon-cross"/></Button></td>
                      </DnDCartItem>
                    );
                  case "amendment":
                    return (
                      <DnDCartItem
                        key={item._sid}
                        id={item._sid}
                        order={item.order}
                        sectionId={item.sectionId}
                        modifyCartItemOrder={this.modifyAmendmentOrder}
                        trClassName="row-my-primary"
                        handleClassName={handleClassName}
                        subItem={(item.type === "subCartItem")?true:false}>
                          <td>{item.name}</td>
                          <td>
                              {this.priceTypeTrans(item.priceType)+((item.priceType === 1)?' '+this.billPeriodTrans(item.billPeriod):'')}
                          </td>
                          <td>
                              <div>{item.quantity}</div>
                          </td>
                          <td>
                            <div className="cell-container cell-container-price">
                              {this.priceFormat(item.price)}
                            </div>
                          </td>
                          <td/>
                          <td/>
                          <td>
                            <div className="cell-container cell-container-price">
                              {this.priceFormat(this.calculateSubTotal(item.quantity, item.price, item.discount, item.discountUnit))}
                            </div>
                          </td>
                          <td>
                            <Button variant="my-secondary-noline" onClick={() => this.modifyAmendment(item)}><i className="icon-pen"/></Button>
                            <Button variant="my-secondary-noline" onClick={() => this.removeAmendment(item._sid)}><i className="icon-cross"/></Button>
                          </td>
                      </DnDCartItem>
                    );
                  case "emptySubCartItem":
                    return (
                      <DnDCartItem
                        key={item._sid}
                        id={item._sid}
                        sectionId={item.sectionId}
                        trClassName={`row-my-primary ${item.mandatory?"row-danger":"row-information"}`}
                        handleClassName={handleClassName}
                        subItem={true}>
                        <td colSpan={8} className={`${item.mandatory?"":""}`} onClick={() => this.startSubItemChoice(item)}>{`Relation ${item.mandatory?"obligatoire":"non-obligatoire"} : ${item.name}`}</td>
                      </DnDCartItem>
                    );
                  case "dropCartItemSlot":
                    return (
                      <DroppableCartItemSlot
                        key={item._sid}
                        id={item._sid}
                        order={item.order}
                        sectionId={item.sectionId}
                        modifyCartItemOrder={this.modifyCartItemOrder}
                      />
                    );
                  default:
                    return null;
                }
              }
            )}
          </table>
        </Row>
      )
    }
    return <div/>;
  }

  displaySubCartItemChoice() {
    return (
      <Modal centered={true} dialogClassName="cart-sub-item-choice-modal" show={this.state.subItemChoice} onHide={() => this.setState({subItemChoice: false})}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{this.state.relationName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemIndex
            type="choice"
            choices={this.state.relationChoices}
            availableItemDisplay={['card']}
            itemDisplay={productsConf.index.itemDisplay}
            onClickItem={this.saveSubItemChoice}
            displaySearch={false}
            displayToolbar={false}
            displayPagination={false}/>
        </Modal.Body>
        {/*<Modal.Footer>test</Modal.Footer>*/}
      </Modal>
    )
  }

  displayNyukomTab() {
    if(this.props.credentials.roles.includes('ROLE_COMMERCIAL_BONUS')) {
      return (
        <>
          <Accordion.Toggle className="mt-25" as={Card.Header} eventKey="2">
            Autre
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <div>
              <Button variant="my-validated" className="btn-sub-footer" onClick={this.previewCommercialBonusQuote}>Calculer les commissions</Button>
              <div>{`Total calculé : ${this.state.commercialBonusTotal} €`}</div>
              <div>{`Commission calculé : ${this.state.commercialBonusValue} €`}</div>
            </div>
          </Accordion.Collapse>
        </>
      )
    }
  }

  displayRightToCancellation() {
    if(this.props.credentials.roles.includes('ROLE_ORDER_CREATE')) {
      return (
        <Row className="d-flex flex-column align-items-center">
          <MyForm
            formKey={rightToCancellationForm.formKey}
            fields={rightToCancellationForm.fields}
            extraData={rightToCancellationForm.extraData}
            disabled={false}
            values={this.props.cart}
            onChangeField={this.onChangeItem}
            displaySubmitButton={false}
            onSubmit={this.onSubmit}
            />
        </Row>
      )
    }
  }

  displayRecap() {
    if(this.state.productLoad && this.state.locationFormulaLoad && this.props.cart.cartItems && this.props.cart.cartItems.length > 0) {
      let result = this.computeRecapData();
      let headers = [
        {
          value: "priceType",
          label: ""
        },
        {
          value: "subTotalPrice",
          label: "HT"
        }
      ]
      result.tvas.forEach((tva) => {
        headers.push(
          {
            value: tva,
            label: `TVA ${tva}%`
          }
        )
      })
      headers.push(
        {
          value: "totalPrice",
          label: "TTC"
        }
      )
      return (
        <Table>
          <TableHeader>
            {headers.map((header, i) => {
              return <TableTextHeader key={i} value={header.value} label={header.label}/>
            })}
          </TableHeader>
          <TableBody>
            {result.data.map((line, i) => {
              return <TableRow key={i} data={line}/>
            })}
          </TableBody>
        </Table>
      )
    }
  }

  displayLoading() {
    if(!this.state.productLoad || !this.state.locationFormulaLoad || this.state.postLoading || this.state.partnerCartLoading) {
      return (
        <Loading/>
      )
    }
  }

  displayButtons() {
    return (
      <SubFooter>
        <Button variant="my-validated" className="btn-sub-footer" onClick={this.resetCart}><i className="icon-checkmark"/> REINITIALISER</Button>
        {/*<Button variant="my-warning" className="btn-sub-footer" onClick={this.saveCart}><i className="icon-document-edit"/> SAUVEGARDER LE PANIER</Button>*/}
        {this.props.credentials.roles.includes('ROLE_QUOTE_CREATE')?<Button variant="my-validated" className="btn-sub-footer" onClick={this.makeQuote}><i className="icon-checkmark"/> CRÉER UN DEVIS</Button>:null}
        {this.props.credentials.roles.includes('ROLE_ORDER_CREATE')?<Button variant="my-validated" className="btn-sub-footer" onClick={this.makeOrder}><i className="icon-checkmark"/> VALIDER LA COMMANDE</Button>:null}
      </SubFooter>
    )
  }

  render() {
    this.checkRefs();
    this.checkForPartnerCart();
    this.checkInitialCart();
    return (
      <MainLayout>
        {this.displayLoading()}
        <Container className="mt-25">
          {this.displayPostError()}
          <Accordion defaultActiveKey="0">
            <Accordion.Toggle className="mt-25" as={Card.Header} eventKey="0">
              Informations générales
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              {this.displayGeneralData()}
            </Accordion.Collapse>
            <Accordion.Toggle className="mt-25" as={Card.Header} eventKey="1">
              <div className="d-flex justify-content-between">
                <div>Produits</div>
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <div><input type="checkbox" checked={this.state.displayAccessory} onClick={this.onClickAccessory} onChange={this.onChangeAccessory}/> Accessoire</div>
                  <div style={{width: '20px'}}/>
                  <div><Button style={{padding: 0}}variant="my-secondary-noline" onClick={(e) => {e.preventDefault(); e.stopPropagation(); this.addCartSection()}}><i className="icon-plus"/>  section</Button></div>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              {this.displayCartItems()}
            </Accordion.Collapse>
            {this.displayNyukomTab()}
          </Accordion>

          <Row className="mt-15">
            <Col xs={12}>
              {this.displayRightToCancellation()}
            </Col>
          </Row>

          <Row className="mt-25">
            <Col xs={12} md={{ span: 6, offset: 6 }}>
              {this.displayRecap()}
            </Col>
          </Row>

          {this.displaySubCartItemChoice()}

        </Container>
        {this.displayButtons()}
      </MainLayout>
    );
  }
}

const mapStateToProps = state => ({
  cart: state.persisted.cart,
  cartProduct: state.cartProduct,
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(withModalHandler(Summary));
