import {
  request,
  index as baseIndex,
  importData as baseImportData
} from './Base';

/******************************************************************************
 * IMPORT FUNCTION                                                            *
 ******************************************************************************/

 /**
  * fetch the empty table description
  */
 export function importData(file) {
   return baseImportData('phone_prices', file);
 }

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all phone prices to serve in a dropdown form choice
 */
export function fetchPhonePrices() {
  return baseIndex('phone_prices', 1, 0);
}


/******************************************************************************
 * OTHER FUNTION                                                              *
 ******************************************************************************/

/**
 * fetch the empty table as model for futur import
 */
export function emptyTable() {
  return request('/phone_prices/empty');
}

/**
 * fetch the empty table description
 */
export function emptyTableDescription() {
  return request('/phone_prices/empty/description');
}
