import React from 'react';

import { Row, Col } from 'react-bootstrap';

class ExtendableZone extends React.Component {

  static defaultProps = {
    height: null,
    onChangeHeight: () => null,
    extendHeigth: false,
    minHeight: 100,
    maxHeight: 200,

    width: null,
    onChangeWidth: () => null,
    extendWidth: false,
    minWidth: 100,
    maxWidth: 200,
  }

  constructor(props) {
    super(props);

    this.state = {
      dragHeight: false,
      height: props.extendHeigth?props.minHeight:"auto",
      dragWidth: false,
      width: props.extendWidth?props.minWidth:"auto",
    }
  }

  getHeight() {
    if(this.props.height) {
      if(Number.isInteger(this.props.height)) {
        return this.props.height+"px";
      }
      return this.props.height;
    }
    else {
      if(Number.isInteger(this.state.height)) {
        return this.state.height+"px";
      }
      return this.state.height;
    }
  }

  getWidth() {
    if(this.props.width) {
      if(Number.isInteger(this.props.width)) {
        return this.props.width+"px";
      }
      return this.props.width;
    }
    else {
      if(Number.isInteger(this.state.width)) {
        return this.state.width+"px";
      }
      return this.state.width;
    }
  }

  dragHeight(clientY) {
    this.clientY = clientY;
    this.setState({
      dragHeight: true
    })
  }

  dragWidth(clientX) {
    this.clientX = clientX;
    this.setState({
      dragWidth: true
    })
  }

  onChange(clientX, clientY) {
    if(this.state.dragWidth) {
      let width = this.props.width?this.props.width:this.state.width;
      if(this.clientX) {
        width += clientX - this.clientX;
      }
      let diff = 0;
      if(width > this.props.maxWidth) {
        diff = width - this.props.maxWidth;
        width = this.props.maxWidth;
      }
      if(width < this.props.minWidth) {
        diff = width - this.props.minWidtht;
        width = this.props.minWidth;
      }
      this.clientX = clientX - diff;
      this.props.width?this.props.onChangeWidth(width):this.setState({width:width})
    }

    if(this.state.dragHeight) {
      let height = this.props.height?this.props.height:this.state.height;
      if(this.clientY) {
        height += clientY - this.clientY;
      }
      let diff = 0;
      if(height > this.props.maxHeight) {
        diff = height - this.props.maxHeight;
        height = this.props.maxHeight;
      }
      if(height < this.props.minHeight) {
        diff = height - this.props.minHeight;
        height = this.props.minHeight;
      }
      this.clientY = clientY - diff;
      this.props.height?this.props.onChangeHeight(height):this.setState({height:height})
    }
  }

  stopDragging() {
    this.clientX = null;
    this.clientY = null;
    this.setState({
      dragHeight: false,
      dragWidth: false,
    })
  }

  displayHeightExtendHandle() {
    if(this.props.extendHeigth) {
      return (
        <Row className="d-flex justify-content-center">
          <div onMouseDown={(e) => this.dragHeight(e.clientY)}>
            <i className="icon-media-pause icon-rotate-90"/>
          </div>
        </Row>
      )
    }
  }

  displayWidthExtendHandle() {
    if(this.props.extendWidth) {
      return (
        <Col xs="auto" className="d-flex justify-content-center align-items-center">
          <div onMouseDown={(e) => this.dragWidth(e.clientX)}>
            <i className="icon-media-pause"/>
          </div>
        </Col>
      )
    }
  }

  displayDraggingArea() {
    if(this.state.dragHeight || this.state.dragWidth) {
      return (
        <div style={
          {
            position: "fixed",
            inset: 0,
            zIndex: 10000
          }}
          onMouseMove={(e) => this.onChange(e.clientX, e.clientY)}
          onMouseUp={() => this.stopDragging()}/>
      )
    }
  }

  render() {
    return (
      <div style={{height: this.getHeight(), width: this.getWidth()}}>
        <Row className="no-gutters">
          <Col>
            <Row className="no-gutters">
              {this.props.children}
            </Row>
          {this.displayHeightExtendHandle()}
          </Col>
          {this.displayWidthExtendHandle()}
        </Row>
        {this.displayDraggingArea()}
      </div>
    );
  }
}

export default ExtendableZone;
