import strings from '../../Localization/Localization';

let price = {
  fieldKey: "price",
  type: "price",
  label: strings.form.buyingPrice.price.label,
  validators: []
}

let company = {
  fieldKey: "company",
  type: "text",
  label: strings.form.buyingPrice.company.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.buyingPrice.company.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let reference = {
  fieldKey: "reference",
  type: "text",
  label: strings.form.buyingPrice.reference.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.buyingPrice.reference.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

export const form = {
  formKey: "buyingPrice",
  fields: [
    price,
    company,
    reference
  ]
}
