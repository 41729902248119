const place = {
  partner: {
    label: "Partenaire",
  },
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Le nom du site ne doit pas être vide.",
    },
  },
  img: {
    label: "Image",
  },
  number: {
    label: "Numéro",
  },
  way: {
    label: "Voie",
    invalid: {
      assertEmpty: "La voie du site ne doit pas être vide.",
    },
  },
  zipCode: {
    label: "Code postal",
    invalid: {
      assertEmpty: "Le code postal ne doit pas être vide.",
    },
  },
  city: {
    label: "Ville",
    invalid: {
      assertEmpty: "La ville ne doit pas être vide.",
    },
  },
  zone: {
    label: "Zone (km)",
    invalid: {
      assertValidity: "La zone n'est pas valide."
    }
  },
  latitude: {
    label: "Latitude",
  },
  longitude: {
    label: "Longitude",
  },
  country: {
    label: "Pays",
    invalid: {
      assertEmpty: "Le pays ne doit pas être vide.",
    },
  },
  active: {
    label: "Site actif",
  },
};

export default place;
