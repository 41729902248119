import React from 'react';
import FormFieldDropdownMulti from './FormFieldDropdownMulti';
import strings from '../../../Localization/Localization';

import { InputGroup, Button, Col, Collapse } from 'react-bootstrap';
import '../../../css/form.css';

import Loading from '../../Layout/Loading';

import { v4 as uuid } from 'uuid';

import { connect } from 'react-redux';
import {
  addOption,
  startLoading,
  addItemsToOption,
  reloadOption
} from '../../../Store/Action/optionsContainer';

// import NotEmptyOrNullAssert from '../../../Form/Assert/NotEmptyOrNullAssert';

/**
 * FormFieldDropdownMultiEntity
 *
 * This class handles HTML form field.
 *
 * The particular field is a Dropdown, it allow to choose multiple element from
 * a list.
 *
 * Specific configuration attribute :
 * - adapter : an adapter in case the options are objects
 * - filterOptions : inherit from FormFieldDropdown
 *
 * - loader :
 *    - id : the id for the the storage of the values
 *    - loader : the function that will load the options
 *    - loaderRequire : a function that will test if the arguments are ready
 *                    for the function
 *    - loaderArguments : the arguments of the loader function
 *    - dataTransformation : the transformation required if the options are
 *                         nested in the loader reply
 *
 * - storage : either 'state' (default) or 'redux'
 * - shareStorage : true, the storage will be shared with other dropdown with
 *                the same loader ID (only work with redux storage)
 *                  false, the storage won't be shared
 *
 * - finalValues : determine how the final values are sent
 *              if 'values', return an array with the values
 *              if 'labels', return an array with the labels
 *              if 'objects', return an array with the objects, only useful when
 *                          if there is an array adapter
 */
export class FormFieldDropdownMultiEntity extends FormFieldDropdownMulti {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    autoComplete: false,
    input: 'load',
    storage: 'state',
    shareStorage: true,
    finalValues: 'objects', // or labels or values
    options: []
  }

  constructor(props) {
    super(props);


    this.uuid = uuid();
    this.loading = false;
    if(props.loader && props.loader.loader) {
      this.loader = this.props.modalHandler.addVerificationWithCallback(props.loader.loader, this.postLoad, this.postLoadFailure, true);
      this.storageId = `${props.loader.id}${!props.shareStorage?'-'+this.uuid:''}`;
    }
    else {
      this.loader = () => console.log("No loader was configured for the field : "+props.fieldKey);
      this.storageId = "";
    }

    //the state of the component
    //options are the options coming from the call
    this.state = Object.assign(this.state, {
      loading: false,
      loaded: false,
      loadingFailure: false,
      options: [],
    })
  }

  componentDidMount() {
    if(!this.props.disabled) {
      this.checkOptions();
    }
  }

  componentDidUpdate() {
    if(!this.props.disabled) {
      this.checkOptions();
    }
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    let results = this.getFilteredOptions().filter((item) => {
      let {value} = this.applyAdapter(item);
      return Object.keys(this.state.selected).includes(value.toString()) && this.state.selected[value] === true;
    });
    switch (this.props.finalValues) {
      case 'values':
        return results.map((item) => {
          let {value} = this.applyAdapter(item);
          return value;
        });
      case 'labels':
        return results.map((item) => {
          let {label} = this.applyAdapter(item);
          return label;
        });
      case 'objects':
      default:
        return results;
    }
  }

  reloadData() {
    Promise.resolve().then(() => {
      this.onChange('', true);
    }).then(() => {
      switch (this.props.storage) {
        case 'state':
          this.setState({
            loading: false,
            loaded: false,
            options: [],
          })
          break;
        case 'redux':
          this.props.dispatch(reloadOption(this.storageId));
          break;
        default:
      }
    })
  }

  loadData() {
    if(this.props.loader.loaderRequire(this.props.value, this.props.getValues, this.props.getExtraValues)) {
      let myArguments = this.props.loader.loaderArguments(this.props.value, this.props.getValues, this.props.getExtraValues);
      switch (this.props.storage) {
        case 'state':
          Promise.resolve().then(() => {
            if(!this.state.loading) {
              this.setState({
                loading: true,
              }, () => {
                this.loader(...myArguments);
              });
            }
          })
          break;
        case 'redux':
          Promise.resolve().then(() => {
            this.props.dispatch(startLoading(this.storageId));
          }).then(() => {
            this.loader(...myArguments);
          })
          break;
        default:
          console.log('wrong storage used in FormFieldDropdownEntity');
      }

    }
  }

  /**
   * callback collecting the result of the call
   */
  postLoad = (data) => {
    this.loading = false;
    let items = null;
    if(this.props.loader.dataTransformation) {
      items = this.props.loader.dataTransformation(data);
    }
    else {
      items = data.items;
    }
    this.setOptions(items);
  }

  /**
   * callback collecting the result of the call
   */
  postLoadFailure = (msg) => {
    this.setState({
      loaded: true,
      loading: false,
      loadingFailure: true,
    })
  }

  /**
   * @inheritdoc
   */
  resetOptions() {
    switch (this.props.storage) {
      case 'state':
        this.setState({
          loaded: true,
          loading: false,
          options: []
        })
        break;
      case 'redux':
        this.props.dispatch(addItemsToOption(this.storageId, []));
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  /**
   * @inheritdoc
   */
  getOptions() {
    switch (this.props.storage) {
      case 'state':
        if(this.state.loaded && this.state.options) {
          return this.state.options;
        }
        break;
      case 'redux':
        if(this.props.optionsContainer[this.storageId]
          && this.props.optionsContainer[this.storageId].loaded && this.props.optionsContainer[this.storageId].options) {
          return this.props.optionsContainer[this.storageId].options;
        }
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
    return [];
  }

  /**
   * @inheritdoc
   */
  setOptions(options = []) {
    switch (this.props.storage) {
      case 'state':
        this.setState({
          loaded: true,
          loading: false,
          loadingFailure: false,
          options: options
        })
        break;
      case 'redux':
        this.props.dispatch(addItemsToOption(this.storageId, options));
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  checkOptions() {
    switch (this.props.storage) {
      case 'state':
        if(!this.state.loaded && !this.state.loading) {
          this.loadData();
        }
        break;
      case 'redux':
        if(!this.props.optionsContainer[this.storageId]) {
          this.props.dispatch(addOption(this.storageId, this.uuid));
        }
        else if(this.props.optionsContainer[this.storageId]
          && this.props.optionsContainer[this.storageId].owner === this.uuid
          && !this.props.optionsContainer[this.storageId].loading
          && !this.props.optionsContainer[this.storageId].loaded
          && !this.loading) {
          this.loading = true;
          this.loadData();
        }
        break;
      default:
        console.log('wrong storage used in FormFieldDropdownEntity');
    }
  }

  displayLoadingError() {
    if(this.state.loadingFailure) {
      return (
        <div className="invalid-feedback" style={{display:"block"}}>
          {strings.form.default.field.dropdownEntity.error.loadingFailure}
        </div>
      )
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <InputGroup.Append>
          <Loading container="parent" containerClassName="loading-form-row" size="small"/>
        </InputGroup.Append>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    let length = Object.keys(this.state.selected).filter(key => this.state.selected[key] === true).length;
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          <div className="d-flex flex-column col-12 col-md-8 col-border-radius col-md-border-radius form-control" style={{height: "inherit"}}>
            <div className="align-items-center form-disabled">
              {length===0?"Aucune sélection":`${length} option(s) selectionée(s)`}
            </div>
            <Collapse in={!this.state.collapse}>
              <div>
                {this.displayOptions()}
              </div>
            </Collapse>
          </div>
          {this.displayValidating()}
          {this.displayLoading()}
          <InputGroup.Append>
            <Button
              variant="my-primary-noline"
              onClick={this.collapse}>
              <i className={`icon-${this.state.collapse?"chevron-down":"chevron-up"}`}></i>
            </Button>
          </InputGroup.Append>
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
        {this.displayLoadingError()}
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  optionsContainer: state.optionsContainer,
})

/**
 * HOC Modal Handler to support call options
 */
export default connect(mapStateToProps, null, null, { forwardRef: true })(FormFieldDropdownMultiEntity);
