import React from 'react';
import PropTypes from 'prop-types';

import {
  Text,
  Image,

  Margin,

  Col,
  Row,
} from './PartReference';

/**
 * FormFieldSelector
 *
 * This class is a selector for HTML form field. It select the right form field
 * using the type in the props of the component. It also check that every main
 * attribute are present for the field.
 *
 * main configuration attribute :
 * - type : the type of the field
 * - formKey : the key of the form (should be unique for each form)
 * - fieldKey : the key of the field (should be unique for each field inside one
 *            form)
 * - label : the string used to present the field to the end user,
 * - value : the value of the field,
 * - disabled : whether the field is disabled or not,
 * - onChange : the callback used to change the value in the parent component,
 * - modifyField : the callback used to change the value of other field in the parent component,
 * - isValid : the list of validation callback to ensure the value of the field
 *           is correct
 */
class PartSelector extends React.Component {

  static defaultProps = {
  }

  /**
   * Main render method for React Component
   */
  render() {
    switch (this.props.type) {
      case "text":
        return (
          <Text
            ref={this.props.forwardedRef}

            content={this.props.content}
          />
        );
      case "image":
        return (
          <Image
            ref={this.props.forwardedRef}

            content={this.props.content}
          />
        );

      case "margin":
        return (
          <Margin
            ref={this.props.forwardedRef}

            partChild={this.props.partChild}

            marginTop={this.props.marginTop}
            marginBottom={this.props.marginBottom}
            marginLeft={this.props.marginLeft}
            marginRight={this.props.marginRight}
          />
        );

      case "row":
        return (
          <Row
            ref={this.props.forwardedRef}

            partChildren={this.props.partChildren}

            noGutters={this.props.noGutters}
          />
        );
      case "col":
        return (
          <Col
            ref={this.props.forwardedRef}

            partChildren={this.props.partChildren}

            xs={this.props.xs}
            sm={this.props.sm}
            md={this.props.md}
            lg={this.props.lg}
            xl={this.props.xl}

            noGutters={this.props.noGutters}
          />
        );
      default:
    }
    return <div/>;
  }
}

//use propTypes to check if the attribute are all present
PartSelector.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'image',

    'margin',

    'row',
    'col',
  ])
}

export default PartSelector;
