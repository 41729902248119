import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  editFromId,
  newItem
} from '../../API/PartnerTypes';
import { form } from '../Form/partnerTypes';
import { form as searchForm } from '../Form/Search/partnerTypes';

import { partnerTypes as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.partnerTypes,

    id: "partnerType",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_ADMIN'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};

      if(search.name) {
        result["name"] = search.name
      }

      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => item["name"]
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: (item) => []
  },
  show: {
    pageTitle: strings.common.homepage.partnerTypes,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getFromId,

    extendedHeader: false,

    menu: false,

    tabs: {
      main: {
        title: strings.partnerType.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        main: ['ROLE_ADMIN'],
        editable: ['ROLE_ADMIN']
      },
    }
  },
  new: {
    pageTitle: strings.partnerType.new.title,

    api: newItem,

    type: 'form',
    // empty: {},
    form: form,

    onSubmit: (partner_type) => {},

    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
