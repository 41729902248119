import React from 'react';

import VerticalCollapsed from './VerticalCollapsed';

/**
 * VerticalCollapse
 */
class VerticalCollapse extends React.Component {

  static defaultProps = {
    collapsed: null
  }

  constructor(props) {
    super(props);

    this.divEl = React.createRef();

    this.state = {
      hovering: false,
      height: -1,
      firstTransition: true,
    }
  }

  componentDidMount() {
    this.setState({
      height: this.divEl.clientHeight
    })
  }

  defaultRender(collapsedContainer, style, hoverStyle) {
    if(this.props.collapsed === null) {
      return (
        <>
          <div
            onMouseEnter={(event) => this.setState({ hovering: true, firstTransition: false })}
            onMouseLeave={(event) => this.setState({ hovering: false })}>
            {(Array.isArray(this.props.children))?this.props.children.filter((child) => child.type !== VerticalCollapsed):this.props.children}
          </div>
          <div
            onMouseEnter={(event) => this.setState({ hovering: true })}
            onMouseLeave={(event) => this.setState({ hovering: false })}>
            <div style={collapsedContainer}>
              <div
                ref={el => this.divEl = el}
                style={(this.props.collapsed !== null?this.props.collapsed:this.state.hovering)?hoverStyle:style}>
                {(Array.isArray(this.props.children))?this.props.children.find((child) => child.type === VerticalCollapsed).props.children:null}
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  remoteControlRender(collapsedContainer, style, hoverStyle) {
    if(this.props.collapsed !== null) {
      return (
        <>
          <div
            onMouseEnter={(event) => this.setState({ hovering: true, firstTransition: false })}
            onMouseLeave={(event) => this.setState({ hovering: false })}>
            {(Array.isArray(this.props.children))?this.props.children.filter((child) => child.type !== VerticalCollapsed):this.props.children}
          </div>
          <div
            onMouseEnter={(event) => this.setState({ hovering: true })}
            onMouseLeave={(event) => this.setState({ hovering: false })}>
            <div style={collapsedContainer}>
              <div
                ref={el => this.divEl = el}
                style={(this.props.collapsed !== null?this.props.collapsed:this.state.hovering)?hoverStyle:style}>
                {(Array.isArray(this.props.children))?this.props.children.find((child) => child.type === VerticalCollapsed).props.children:null}
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    let collapsedContainer = {
      overflow: "hidden",
    }
    let style = {};
    let hoverStyle = {};
    if(this.state.firstTransition) {
      style = {
        marginTop: "-"+(this.state.height+1)+"px",
        width: "100%",
        opacity: 0
      };
    }
    else {
      style = {
        marginTop: "-"+(this.state.height+1)+"px",
        width: "100%",
        transition: "margin 0.3s",
      };
      hoverStyle = {
        marginTop: "0px",
        width: "100%",
        transition: "margin 0.3s",
      };
    }
    return (
      <>
        {this.defaultRender(collapsedContainer, style, hoverStyle)}
        {this.remoteControlRender(collapsedContainer, style, hoverStyle)}
      </>
    )
  }
}

export default VerticalCollapse;
