import strings from '../../Localization/Localization';

import { processTimeframe } from './common';

import React from 'react';
import YousignAppointment from '../../Component/Yousign/Holder/YousignAppointment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  index,
  getFromId,
  getIdentificationFromId,
  getHistoryFromId,
  getAssignRefereeFromId,
  editFromId,
  editFormAnswerFromId,
  editAssignRefereeFromId,
  newCommentFromId,
  newFileFromId,
  newAppointmentFromId,
  newFormAnswerFromId,
  newItem,
  workInProgressTransition,
  waitTransition,
  closeTransition,
  escalate
} from '../../API/CommercialTicket';
import {
  form,
  newForm,
  newAppointmentForm,
  assignRefereeForm
} from '../Form/tickets';
import {
  form as commentForm,
  newForm as newCommentForm
} from '../Form/comments';
import {
  form as fileForm,
  newForm as newFileForm
} from '../Form/ticketFiles';
import {
  form as appointmentForm
} from '../Form/appointments';
import {
  form as formAnswerForm,
  newForm as newFormAnswerForm
} from '../Form/ticketFormAnswers';
import { form as searchForm } from '../Form/Search/tickets';

import { commercialTicket as routes } from '../Route/routes';

import {
  FLASHBAG_TYPE_SUCCESS,
  FLASHBAG_TYPE_DANGER
} from '../../Store/Action/flashbag';

const _form = form('commercial');
const _newForm = newForm('commercial');
const _newAppointmentForm = newAppointmentForm('commercial');
const _appointmentForm = appointmentForm('commercial');
const _assignRefereeForm = assignRefereeForm('commercial');
const _newFormAnswerForm = newFormAnswerForm('commercial');
const _searchForm = searchForm('commercial');

export const conf = {
  prefix: "tickets/commercial",
  index: {
    pageTitle: strings.common.homepage.commercialTicket,

    id: "commercialTicket",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_COMMERCIAL_TICKET_CREATE'
    },

    searchForm: _searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.number) {
        result["number"] = search.number;
      }
      if(typeof search.requestType === 'object' && search.requestType.length > 0) {
        result["request_type_id"] = search.requestType.map((item) => item.id).toString();
      }
      if(typeof search.state === 'object' && search.state.length > 0) {
        result["state"] = search.state.map((item) => item.value).toString();
      }
      if(search.criticity) {
        result["criticity"] = search.criticity;
      }
      if(search.escalateLevel) {
        result["escalate_level"] = search.escalateLevel;
      }
      if(typeof search.referee === 'object') {
        result["referee_id"] = search.referee.id;
      }
      if(typeof search.client === 'object') {
        result["client_id"] = search.client.id;
      }
      if(typeof search.clientAccount === 'object') {
        result["client_account_id"] = search.clientAccount.id;
      }
      if(typeof search.place === 'object') {
        result["place_id"] = search.place.id;
      }
      if(typeof search.refereeClient === 'object') {
        result["referee_client_id"] = search.refereeClient.id;
      }
      if(search.createdAtTimeframe) {
        let createdAtTimeframe = processTimeframe(search.createdAtTimeframe);

        result["created_at_min"] = createdAtTimeframe.min;
        result["created_at_max"] = createdAtTimeframe.max;
      }
      if(search.createdAt) {
        result["created_at"] = search.createdAt;
      }
      if(typeof search.appointmentType === 'object') {
        result["appointment_type_id"] = search.appointmentType.id;
      }
      if(search.appointmentNumber) {
        result["appointment_number"] = search.appointmentNumber;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            id: "state",
            label: <>&nbsp;&nbsp;<i className="icon-flag-1"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item["state"]) {
                case 'new':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.state.options.new}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-ticket-new"/></div>
                    </OverlayTrigger>
                  );
                case 'waiting':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.state.options.waiting}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-ticket"/></div>
                    </OverlayTrigger>
                  );
                case 'in_progress':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.state.options.in_progress}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-ticket"/></div>
                    </OverlayTrigger>
                  );
                case 'closed':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.state.options.closed}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-validated-icon"><i className="icon-checkmark"/></div>
                    </OverlayTrigger>
                  );
                default:

              }
            }
          },
          {
            id: "criticity",
            label: <>&nbsp;&nbsp;<i className="icon-clock"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item["criticity"]) {
                case 1:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.criticity.options.urgent}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-danger-icon"><i className="icon-clock"/></div>
                    </OverlayTrigger>
                  );
                case 2:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.criticity.options.medium}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-clock"/></div>
                    </OverlayTrigger>
                  );
                case 3:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.criticity.options.noturgent}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-clock"/></div>
                    </OverlayTrigger>
                  );
                default:

              }
            }
          },
          {
            id: "escalateLevel",
            label: <>&nbsp;&nbsp;<i className="icon-broadcast"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item["escalate_level"]) {
                case 1:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.escalateLevel.options._1}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-broadcast"/></div>
                    </OverlayTrigger>
                  );
                case 2:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.escalateLevel.options._2}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-broadcast"/></div>
                    </OverlayTrigger>
                  );
                case 3:
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.ticket.escalateLevel.options._3}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-danger-icon"><i className="icon-broadcast"/></div>
                    </OverlayTrigger>
                  );
                default:

              }
            }
          },
          {
            id: "number",
            label: "Numéro",
            value: "number"
          },
          {
            id: "partner",
            label: "Partenaire",
            value: item => item["client"]["partner"]["name"]
          },
          {
            id: "place",
            label: "Site",
            value: item => item["place"]["name"]
          },
          {
            id: 'createdAt',
            label: "Ouverture",
            value: item => item["created_at"]
          },
          // {
          //   label: "Montant TTC",
          //   value: item => item["cart"][""]
          // },
        ]
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.commercialTicket,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "number",

    apiGet: getIdentificationFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => null,
      placeholderTitle: (item) => "NK",
      label: (item) => item.number,
      additionalData1: (item) => `${item["client"]["partner"]["name"]} > ${item["place"]["name"]}`,
      additionalData2: (item) => `${item["created_at"]}${item["closed_at"]?` > ${item["closed_at"]}`:""}`,
      additionalData3: (item) => `${item["request_type"]["name"]}`
    },

    menu: (item) => {
      let buttons = [];
      if(item.state !== 'closed') {
        if(item.state !== 'in_progress') {
          buttons.push({
            id: 'workInProgress',
            type: 'redirection',
            auth: 'ROLE_COMMERCIAL_TICKET_EDIT',
            path: routes.routes.transition.createPath(item.id, 'workInProgress'),
            label: strings.ticket.tabs.buttons.workInProgress,
            variant: "my-validated"
          });
        }
        if(item.state !== 'waiting') {
          buttons.push({
            id: 'wait',
            type: 'redirection',
            auth: 'ROLE_COMMERCIAL_TICKET_EDIT',
            path: routes.routes.transition.createPath(item.id, 'wait'),
            label: strings.ticket.tabs.buttons.wait,
            variant: "my-information"
          });
        }
        buttons.push({
          id: 'close',
          type: 'redirection',
          auth: 'ROLE_COMMERCIAL_TICKET_EDIT',
          path: routes.routes.transition.createPath(item.id, 'close'),
          addVerification: true,
          label: strings.ticket.tabs.buttons.close,
          variant: "my-warning",
          alwaysDisplay: true
        });

        if(item["request_type"]["escalatable"] && item["escalate_level"] < 3) {
          buttons.push({
            id: 'escalate',
            type: 'modal',
            label: strings.ticket.tabs.buttons.escalate.label,
            auth: 'ROLE_COMMERCIAL_TICKET_ESCALATE',
            variant: "my-warning",
            request: (form) => escalate(item["id"], {
              id: item["id"],
              escalate_comment: form.comment
            }),
            postRequestCallback: [
              {
                type: "flashbag",
                message: strings.ticket.tabs.buttons.escalate.flashbagMessage,
                flashbagType: FLASHBAG_TYPE_SUCCESS,
              },
              {
                type: "updateItem",
                key: "escalate_level",
                value: (data) => item["escalate_level"]+1
              }
            ],
            postRequestCallbackFailure: {
              type: "flashbag",
              message: strings.ticket.tabs.buttons.escalate.flashbagMessageFailure,
              flashbagType: FLASHBAG_TYPE_DANGER,
            },
            modalData: () => {
              return {
                title: strings.ticket.tabs.buttons.escalate.title,
                form: {
                  formKey: "escalate",
                  fields: [
                    {
                      fieldKey: "comment",
                      type: "textarea",
                      label: strings.ticket.tabs.buttons.escalate.form.comment.label,
                      showOnDisabled: (values) => true,
                      validators: []
                    },
                  ]
                },
                successButton: strings.ticket.tabs.buttons.escalate.successButton,
                successButtonOnClick: (item) => {
                  return {
                    request: true,
                    arguments: [item]
                  }
                },
              }
            },
          })
        }
      }
      return buttons;
    },

    tabs: {
      main: {
        title: strings.ticket.tab.main,
        type: 'form',
        form: _form,

        onUpdateItem: (data) => {
          data.item['client_account'] = data.item['client'];
          data.item['client'] = data.item['client_account']['partner'];
        },
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: (item, credentials) => item && item.state !== 'closed' && credentials.roles.includes('ROLE_COMMERCIAL_TICKET_EDIT')
      },
      history: {
        title: strings.ticket.tab.history,
        type: 'ticket_history',
        subType: 'commercial',

        commentForm: commentForm,
        fileForm: fileForm,
        appointmentForm: _appointmentForm,
        formAnswerForm: formAnswerForm,

        newCommentForm: newCommentForm,
        newFileForm: newFileForm,
        newFormAnswerForm: _newFormAnswerForm,
        newAppointmentForm: _newAppointmentForm,

        postEdit: {
          type: 'tab',
          tabId: 'history'
        },
        postEditAppointment: (data) => {
          if(data && data["item"] && data["item"]["appointment"] && data["item"]["appointment"]["appointment_type"]["ask_for_signature"]) {
            return {
              type: 'redirection',
              path: routes.routes.yousign.createPath(data["item"]["appointment"]["id"])
            }
          }
          else {
            return {
              type: 'tab',
              tabId: 'history'
            }
          }
        },

        apiHistory: getHistoryFromId,
        apiNewComment: newCommentFromId,
        // apiPublishComment: editFromId,
        // apiUnpublishComment: editFromId,
        apiNewFile: newFileFromId,
        apiNewFormAnswer: newFormAnswerFromId,
        apiEditFormAnswer: editFormAnswerFromId,
        apiNewAppointment: newAppointmentFromId,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
      },
      /*
      newAppointment: {
        title: strings.ticket.tab.newAppointment,
        type: 'form',
        form: _newAppointmentForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: (data) => {
          if(data && data["item"] && data["item"]["appointment"] && data["item"]["appointment"]["appointment_type"]["ask_for_signature"]) {
            return {
              type: 'redirection',
              path: routes.routes.yousign.createPath(data["item"]["appointment"]["id"])
            }
          }
          else {
            return {
              type: 'tab',
              tabId: 'history'
            }
          }
        },

        apiGet: getFromId,
        apiEdit: newAppointmentFromId,

        shouldDisplay: (item) => item && item.state !== 'closed',
        role: ['ROLE_COMMERCIAL_TICKET_APPOINTMENT_CREATE'],
        editable: true,
      },
      */
      assignReferee: {
        title: strings.ticket.tab.assignReferee,
        type: 'form',
        form: _assignRefereeForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getAssignRefereeFromId,
        apiEdit: editAssignRefereeFromId,

        shouldDisplay: (item) => item && item.state !== 'closed',
        role: ['ROLE_COMMERCIAL_TICKET_MANAGER'],
        editable: true,
      }
    }
  },
  new: {
    pageTitle: strings.ticket.new.title,

    api: newItem,
    empty: {},

    // form: _newForm,
    type: 'process',
    processId: 'newCommercialTicket',

    steps: [
      {
        id: "_general_data",
        label: strings.ticket.new.steps.partnerData,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ..._newForm,
          fields: _newForm.fields.filter((item) => ['client', 'client_account', 'place', 'referee_clients'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
      {
        id: "_general_data",
        label: strings.ticket.new.steps.generalData,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ..._newForm,
          fields: _newForm.fields.filter((item) => ['description', 'request_type', 'criticity'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
    ],

    onSubmit: (item) => {
      item["client"] = item["client_account"];
      item["client_account"] = undefined;
    },
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  },
  transition: {
    workInProgress: {
      type: 'direct',
      api: workInProgressTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => routes.routes.show.createPath(data.item.id)
      },
    },
    wait: {
      type: 'direct',
      api: waitTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => routes.routes.show.createPath(data.item.id)
      },
    },
    close: {
      type: 'direct',
      api: closeTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => routes.routes.index.createPath(data.item.id)
      },
    },
  },
  custom: {
    yousign: {
      path: '/yousign/:id',
      createPath: (id) => `/yousign/${id}`,
      type: 'render',
      getRender: (props) => <YousignAppointment {...props} ticketType="commercial"/>,
    }
  }
}
