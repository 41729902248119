import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of phone prefixes based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('phone_prefixes', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single phone prefix based on his ID
 *
 * @param id the of the phone prefix requested
 */
export function getFromId(id) {
  return baseGetFromId('phone_prefixes', id);
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single phone prefix based on his ID
 *
 * @param id the of the phone prefix requested
 * @param data the new data of the phone prefix
 */
export function editFromId(id, data) {
  return baseEditFromId('phone_prefixes', id, data);
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new phone prefix
 *
 * @param data the new data of the phone prefix
 */
export function newItem(data) {
  return baseNewItem('phone_prefixes', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all phone prefixes to serve in a dropdown form choice
 */
export function fetchPhonePrefixes() {
  return baseIndex('phone_prefixes', 1, 0);
}
