import React from 'react';

import { Container, Col, Navbar } from 'react-bootstrap';
import '../../css/footer.css';

/**
 * SubFooter
 *
 * This is the footer of the application.
 */
class SubFooter extends React.Component {

  constructor(props) {
    super(props);

    this.navbarDefault = React.createRef();
    this.navbarMobile = React.createRef();
  }

  componentDidMount() {
    if(this.navbarDefault.current && this.navbarMobile.current) {
      let computedStyleDefault = window.getComputedStyle(this.navbarDefault.current);
      let computedStyleMobile = window.getComputedStyle(this.navbarMobile.current);

      let pixelAmount = 0;

      let heightDefault = computedStyleDefault.getPropertyValue('height');
      if(heightDefault.endsWith('px')) {
        pixelAmount += Number(heightDefault.substring(0,heightDefault.length-2));

        let bottomDefault = computedStyleDefault.getPropertyValue('bottom');
        if(bottomDefault.endsWith('px')) {
          pixelAmount += Number(bottomDefault.substring(0,bottomDefault.length-2));
        }
      }

      let heightMobile = computedStyleMobile.getPropertyValue('height');
      if(heightMobile.endsWith('px')) {
        pixelAmount += Number(heightMobile.substring(0,heightMobile.length-2));

        let bottomMobile = computedStyleDefault.getPropertyValue('bottom');
        if(bottomMobile.endsWith('px')) {
          pixelAmount += Number(bottomMobile.substring(0,bottomMobile.length-2));
        }
      }

      // pixelAmount += 50;

      document.getElementById('root').style.setProperty('--footer-size', pixelAmount+"px");
    }
  }

  displayNavbar() {
    return (
      <Navbar ref={this.navbarDefault} fixed="bottom" className={`d-none d-md-flex sub-footer${this.props.additionalClassName?" "+this.props.additionalClassName:""}`}>
        <div className="left-menu-position"/>
        <Container>
          <Col xs={12} className="d-flex justify-content-center text-center small-text">
            {this.props.children}
          </Col>
        </Container>
      </Navbar>
    );
  }

  displayButtonsMobile() {
    return (
      <Navbar ref={this.navbarMobile} fixed="bottom" className="d-flex d-md-none sub-footer">
        <Container ref={this.container}>
          <Col className="d-flex flex-column justify-content-center">
            {this.props.children}
          </Col>
        </Container>
      </Navbar>
    )
  }

  /**
   * Main render method for React Component
   */
   render() {
     if(
       this.props.children
       && ((Array.isArray(this.props.children) && this.props.children.some((child) => !!child))
       || !Array.isArray(this.props.children))
     ){
       return (
         <>
           {this.displayNavbar()}
           {this.displayButtonsMobile()}
         </>
       );
     }
     return <div/>;
   }
}


export default SubFooter;
