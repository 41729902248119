import React from 'react';

import { connect } from 'react-redux';

import '../../css/modal.css';

import { modifyTypeAction, removeActionAction, onAuthenticationSuccessAction } from '../../Store/Action/modalHandler';

import { Modal, Button } from 'react-bootstrap';
import strings from '../../Localization/Localization';

import LoginForm from '../Login/LoginForm';

import Loading from '../Layout/Loading';

/**
 * ModalDisplayer
 *
 * The modal displayer will collect request from other components to either :
 * - make a security check to be sure the end user wants to execute the action
 * - make an authentication check which will display a login form if the user
 *   has benn time out or is not logged.
 * - if an error happened in the backup, this will propose to retry the action
 */
class ModalDisplayer extends React.Component {

  //the last action that is being executed
  lastAction = [];

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  /**
   * check if the data from an action is valid or not
   */
  checkData = (action, data) => {
    if(data && data.status === 'error'){
      this.lastAction = this.lastAction.filter(item => item.uuid !== action.uuid);
      this.props.dispatch(modifyTypeAction(action.uuid, data.needAuthentication?'auth':'error'));
      throw new Error();
    }
    return data
  }

  /**
   * try an action
   */
  tryAction = (action) => {
    //check if last action is not the current action
    if(this.lastAction.every(item => item.uuid !== action.uuid)) {
      this.lastAction.forEach(item => {
        if(action.singleCall === true && item.ownerUuid === action.ownerUuid && item.callUuid === action.callUuid) {
          item.canceled = true
        }
      });
      action.canceled = false;
      this.lastAction.push(action);
      Promise.resolve(action.call.apply(null, action.myArguments))
        .then((data) => {
          return this.checkData(action, data)
        })
        .then((data) => {
          if(action.canceled === false && action.callback !== undefined) action.callback(data);
          this.clean(action);
        })
        .catch((msg) => {
          if(action.callbackOnFailure !== undefined) action.callbackOnFailure(msg);
          console.error(msg)
        })
    }
  }

  hide = (action) => {
    this.clean(action);
  }

  /**
   * callback when closing the modal
   */
  close = (action) => {
    this.clean(action);
  }

  /**
   * callback when closing the modal AND trying the action
   */
  closeWithAction = (action) => {
    this.setState({
      loading: true
    }, () => {
      this.props.dispatch(modifyTypeAction(action.uuid, 'request'));
    })
  }

  /**
   * callback when authentification is successful
   */
  onAuthenticationSuccess = (action) => {
    this.setState({
      loading: true
    }, () => {
      this.props.dispatch(onAuthenticationSuccessAction());
    })
  }

  /**
   * clean all data from the action
   */
  clean = (action) => {
    this.lastAction = this.lastAction.filter(item => item.uuid !== action.uuid);
    this.props.dispatch(removeActionAction(action.uuid));
    this.setState({
      loading: false
    });
  }

  /**
   * Display the modal
   */
  displayModal() {
    //check if there is any action left
    if(this.props.actions.length > 0) {
      //the basic information for any modal
      let modalInitialized = false;
      let closeButton = true;
      let className = '';
      let title = '';
      let body = '';
      let footer = null;
      let modalAction = null;

      this.props.actions.forEach((action) => {
        switch (action.type) {
          //if the type is request, the action should be tried
          case 'request':
            this.tryAction(action);
            //in this case, we don't display anything as a modal
            break;
          //if the type is verification, this is a security check to be sure the
          //user wants to execute the action
          //there can only be one verification at one
          case 'verification':
            if(modalInitialized === false) {
              modalInitialized = true;
              title = strings.common.errorHandler.check.title;
              body = strings.common.errorHandler.check.body;
              footer = (
                <Modal.Footer className="d-flex justify-content-center">
                  <Button variant="my-warning" className="modal-action-button" onClick={() => this.close(action)}>
                    {strings.common.errorHandler.check.buttons.close}
                  </Button>
                  <Button variant="my-validated" className="modal-action-button" onClick={() => this.closeWithAction(action)}>
                    {strings.common.errorHandler.check.buttons.validate}
                  </Button>
                </Modal.Footer>
              )
              modalAction = action;
            }
            break;
          //if the type is auth, there is a problem with the authentication, we
          //need the user to retry log in
          case 'auth':
            if(modalInitialized === false) {
              modalInitialized = true;
              closeButton = false;
              className = "modal-authentification";
              title = strings.common.errorHandler.auth.title;
              body = (
                <div className="modal-login-container">
                  {strings.common.errorHandler.auth.body}
                  <LoginForm
                    onAuthenticationSuccess={() => this.onAuthenticationSuccess()}/>
                </div>
              );
              footer = null;
              modalAction = action;
            }
            break;
          default:
            if(modalInitialized === false) {
              modalInitialized = true;
              title = strings.common.errorHandler.else.title;
              body = strings.common.errorHandler.else.body;
              footer = (
                <Modal.Footer className="d-flex justify-content-center">
                  <Button variant="my-warning" className="modal-action-button" onClick={() => this.close(action)}>
                    {strings.common.errorHandler.else.buttons.close}
                  </Button>
                  <Button variant="my-validated" className="modal-action-button" onClick={() => this.closeWithAction(action)}>
                    {strings.common.errorHandler.else.buttons.retry}
                  </Button>
                </Modal.Footer>
              )
              modalAction = action;
            }
        }
      });

      if(modalInitialized === true) {
        //display the modal
        return (
          <Modal show={true} centered={true} className={className} onHide={() => this.hide(modalAction)}>
            <Modal.Header closeButton={closeButton}>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            {footer}
          </Modal>
        );
      }
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayLoading()}
        {this.displayModal()}
        {/*display the rest of the page*/}
        {this.props.children}
      </>
    );
  }
}

const mapStateToProps = state => ({
  actions: state.modalHandler
})

export default connect(mapStateToProps)(ModalDisplayer);
