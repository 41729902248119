const phonePackage = {
  name: {
    label: "Nom",
  },
  phonePriceList: {
    label: "Grille tarifaire",
  },
  includedTotalPhoneTime: {
    label: "Temps total d'appel inclus (sec/mois)",
  },
  includedSpecificPhoneNumber: {
    label: "Nombre de numéro inclus (/canal)",
  },
  includedSpecificPhoneTime: {
    label: "Temps d'appel inclus (sec/mois)",
  },
  includedTotalDataQuantity: {
    label: "Quantité de Data incluse (ko)",
  },
  includedRedirection: {
    label: "Redirection d'appel inclus ?",
  },
  includedMms: {
    label: "MMS France/France inclus ?",
  },
  includedSms: {
    label: "SMS France/France inclus ?",
  },
  slowDataAtHome: {
    label: "Débit réduit après consommations en France ?",
  },
  includedPrices: {
    label: "Liste des prix inclus",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  includedPrice: {
    label: "Prix",
  },
};

export default phonePackage;
