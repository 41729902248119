const technicalData = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Ce nom ne doit pas être vide."
    },
  },
  ciphered: {
    label: "Faut-il chiffrer cette donnée ?",
  },
  active: {
    label: "Cette donnée est-elle active ?",
  },
  availableOrder: {
    label: "Demandé lors d'une commande ?",
  },
  availableService: {
    label: "Demandé dans un service ?",
  },
  displayBillOrder: {
    label: "Affichage dans facture",
    invalid: {
      assertNumber: "Ce champ doit être un entier natural."
    },
  },
};

export default technicalData;
