import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

 /******************************************************************************
  * INDEX FUNCTION                                                             *
  ******************************************************************************/

/**
 * Get a list of product families based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('product_families', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single product family based on his ID
 *
 * @param id the of the product family requested
 */
export function getFromId(id) {
  return baseGetFromId('product_families', id);
}

/**
 * Get a single product family based on his ID
 *
 * @param id the of the product family requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('product_families', id, 'identification');
}

/**
 * Get a single product family based on his ID
 *
 * @param id the of the product family requested
 */
export function getConfigurationFromId(id) {
  return baseGetFromId('product_families', id, 'configuration');
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getConfigurationEditFromId(id) {
  return baseGetFromId('product_families', id, 'configuration_edit');
}

/**
 * Get a single product family based on his ID
 *
 * @param id the of the product family requested
 */
export function getAccountingFromId(id) {
  return baseGetFromId('product_families', id, 'accounting');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single product family based on his ID
 *
 * @param id the of the product family requested
 * @param data the new data of the product family
 */
export function editFromId(id, data) {
  return baseEditFromId('product_families', id, data);
}

/**
 * Edit a single product family based on his ID
 *
 * @param id the of the product family requested
 * @param data the new data of the product family
 */
export function editConfigurationFromId(id, data) {
  return baseEditFromId('product_families', id, data, 'configuration_edit');
}

/**
 * Edit a single product family based on his ID
 *
 * @param id the of the product family requested
 * @param data the new data of the product family
 */
export function editAccountingFromId(id, data) {
  return baseEditFromId('product_families', id, data, 'accounting');
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new product family
 *
 * @param data the new data of the product family
 */
export function newItem(data) {
  return baseNewItem('product_families', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all family to serve in a dropdown form choice
 */
export function fetchFamilies(search) {
  return baseIndex('product_families', 1, 0, search);
}
