const ticketFormField = {
  label: {
    label: "Label",
    invalid: {
      assertEmpty: "Le label ne peut pas être vide."
    }
  },
  type: {
    label: "Type de champ",
    invalid: {
      assertEmpty: "La type ne peut pas être vide."
    },
    options: {
      text: 'Texte',
      textarea: 'Zone de texte',
      dropdown: 'Liste déroulante',
    }
  },
  order: {
    label: "Ordre d'apparition",
    invalid: {
      assertEmpty: "L'order ne peut pas être vide."
    }
  },
  dropdownElements: {
    label: "Elément de la liste",
    invalid: {
      assertQuantity: "Il faut au moins un élément dans la liste."
    }
  }
};

export default ticketFormField;
