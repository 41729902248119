import strings from '../../Localization/Localization';

import { index, getFromId, editFromId, newItem } from '../../API/CommercialAppointmentTypes';
import { form } from '../Form/appointmentTypes';
import { form as searchForm } from '../Form/Search/appointmentTypes';

import { commercialAppointmentTypes as routes } from '../Route/routes';

const _form = form('commercial');

export const conf = {
  prefix: "appointmentTypes/commercial",
  index: {
    pageTitle: strings.common.homepage.commercialAppointmentType,

    id: "appointmentType",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_COMMERCIAL_TICKET_APPOINTMENT_TYPE'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => item["name"],
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.commercialAppointmentType,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.appointmentType.tab.main,
        type: 'form',
        form: _form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_COMMERCIAL_TICKET_APPOINTMENT_TYPE'],
        editable: ['ROLE_COMMERCIAL_TICKET_APPOINTMENT_TYPE']
      }
    }
  },
  new: {
    pageTitle: strings.appointmentType.new.title,

    api: newItem,
    empty: {},
    form: _form,
    onSubmit: (item) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
