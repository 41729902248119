const ticketType = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Le nom ne peut pas être vide."
    }
  },
  billable: {
    label: "facturable"
  },
  escalatable: {
    label: "Escaladable"
  },
  enableGfrtNotification: {
    label: "Notification de GTR"
  },
  description: {
    label: "Description",
    invalid: {
      assertEmpty: "La description ne peut pas être vide."
    }
  }
};

export default ticketType;
