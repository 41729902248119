export const technical_pie_ticket_state = {
  id: "technical_pie_ticket_state-1",
  default: true,
  roles: [],

  title: "Etat ticket",
  type: "stateMachine",
  conf: {
    element: "technicalTicket",
    dataset: "state",
    data: [
      "new",
      "waiting",
      "in_progress"
    ],
    filters: {
      requestType: [
        {
          id: 3,
          name: "Dépannage",
          group: 0,
          escalatable: true,
          enable_gfrt_notification: true
        },
        {
          id: 4,
          name: "Aide à la configuration",
          group: 0,
          escalatable: true,
          enable_gfrt_notification: true
        },
        {
          id: 5,
          name: "Modification du service",
          group: 0,
          escalatable: true,
          enable_gfrt_notification: true
        }
      ],
      appointmentNumber: null
    }
  },
  column: 1,
  row: 1
}
