//main configuration for external API
export const config = {
  //path to api
  api_path: process.env.REACT_APP_API_PATH,
  file_path: process.env.REACT_APP_FILE_PATH,
}

//list of message user by the external API for authentication
export const MESSAGE_USER_NOT_FOUND = 'Username could not be found.';
export const MESSAGE_INVALID_CREDENTIALS = 'Invalid credentials.';
export const MESSAGE_TOO_MANY_ATTEMPT = 'Account locked for too many attempt.';
export const MESSAGE_PERMISSION_DENIED = 'Forbidden';
