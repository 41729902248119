import React from 'react';

/**
 * VerticalCollapsed
 */
class VerticalCollapsed extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.props.children}
      </>
    );
  }
}

export default VerticalCollapsed;
