import React from 'react';

import '../../css/column_equalizer.css';

/**
 */
class ColumnEqualizer extends React.Component {

  static defaultProps = {
    column: 2,
    columnAdditionalClassname: ""
  }

  render() {
    return (
      <div className="column-equalizer" style={{columns:this.props.column}}>
        {this.props.children}
      </div>
    );
  }
}

export default ColumnEqualizer;
