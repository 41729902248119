import React from 'react';

import '../../css/item_index.css';

import { Container, ListGroup, Pagination, Col, ButtonToolbar, ButtonGroup, Button, Row } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';

import strings from '../../Localization/Localization';

import { withModalHandler } from '../../HOC/ModalHandler';

import { connect } from 'react-redux';
import {
  modifySearchAction,
  removeSearchAction,
  resetSearchAction,
  modifyItemPerPageAction,
  modifyOrderAction,
  modifyDisplayTypeAction
} from '../../Store/Action/search';
import { resetBreadcrumbAction } from '../../Store/Action/breadcrumb';

import Loading from '../Layout/Loading';

import MyForm from '../Form/MyForm';

import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableBody from '../Table/TableBody';
import TableTextHeader from '../Table/Header/TableTextHeader';
import TableRowWithCollapse from '../Table/Body/TableRowWithCollapse';
import TableRowCollapsedElement from '../Table/Body/TableRowCollapsedElement';
import TableRowCollapsedRow from '../Table/Body/TableRowCollapsedRow';

import StringCell from '../Form/Field/TableCell/StringCell';
import IntegerCell from '../Form/Field/TableCell/IntegerCell';
import FloatCell from '../Form/Field/TableCell/FloatCell';
import PriceCell from '../Form/Field/TableCell/PriceCell';
// import DropdownCell from '../Form/Field/TableCell/DropdownCell';
// import TimeCell from '../Form/Field/TableCell/TimeCell';
// import SwitchCell from '../Form/Field/TableCell/SwitchCell';

import ItemIndexLine from './Item/ItemIndexLine';
import ItemIndexBar from './Item/ItemIndexBar';
import ItemIndexCard from './Item/ItemIndexCard';

import Flashbag from '../Flashbag/Flashbag';


/**
 * Index
 *
 * This component represent an index, a list of items displayed on several page.
 *
 * For the index to work properly, we need to set 3 props :
 * - index : a function that will feed the items to this component
 // * - displayItem : how the item should be displayed
 * - onClickItem : a function called when the end user click one item
 */
class Index extends React.Component {

  static itemDisplayIcons = {
    card: "view-thumb",
    bar: "view-list-large",
    line: "view-list",
    tab: "view-list",
    tabAlt: "view-list"
  }

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    pageTitle: "",
    type: 'index',
    index: () => null,
    choices: [],
    //TODO remove ?
    // displayItem: (item) => item.id,
    onClickItem: (id) => null,

    searchFields: [],
    computeSearch: (search) => search,

    availableItemDisplay: ['line'],
    itemDisplay: {
      line: {
        label: (item) => item.id,
      }
    },

    sizing: {
      numberColumn: 1,
      formSizeMd: 12
    },

    displaySearch: true,
    displayToolbar: true,
    displayPagination: true,

    newLink: false,

    cartItem: false,

    emptyText: () => "This list is empty.",

    buttons: () => []
  };

  constructor(props) {
    super(props);
    //state of the component, including :
    // - items : the items currently displayed
    // - page : the page currently displayed
    // - total : the total number of items
    // - totalPage : the total number of page
    this.state = {
      collapseSearch: false,
      search: {},

      collapseHeader: false,
      activeHeaders: (props.itemDisplay.tab && props.itemDisplay.tab.defaultHeader)?props.itemDisplay.tab.defaultHeader:[],

      loading: false,
      exportLoading: false,

      items: null,
      page: null,
      total: null,
      totalPage: null
    }

    if(this.props.type === 'index') {
      this.index = this.props.modalHandler.addVerificationWithCallback(this.props.index, this.updateIndex, this.updateIndexFailure);
    }
    if(this.props.export) {
      this.export = this.props.modalHandler.addVerificationWithCallback(this.props.export.link, this.postExport, this.postExportFailure);
    }

    if(this.props.prefilter) {
      props.dispatch(modifySearchAction(this.props.id, this.props.prefilter));
    }
  }

  /**
   * Once the component is mounted, we can query for the first page of data
   */
  componentDidMount() {
    if(this.props.type === 'index') {
      this.loadNewPage(1);

      this.props.dispatch(resetBreadcrumbAction(window.location.pathname, this.props.pageTitle))
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.type === 'index' && this.props.search[this.props.id] !== prevProps.search[this.props.id]) {
      this.loadNewPage(1);
    }
  }

  getPersistedSearch() {
    return this.props.search[this.props.id];
  }

  getSearch() {
    if(this.getPersistedSearch() && this.getPersistedSearch().search) {
      return this.getPersistedSearch().search
    }
    return {};
  }

  getItemPerPage() {
    if(this.getPersistedSearch() && this.getPersistedSearch().itemPerPage) {
      return this.getPersistedSearch().itemPerPage
    }
    return 10;
  }

  getOrder() {
    if(this.getPersistedSearch() && this.getPersistedSearch().order) {
      return this.getPersistedSearch().order
    }
    return 'ASC';
  }

  getDisplayType() {
    if(
      this.getPersistedSearch() && this.getPersistedSearch().displayType
      && this.props.availableItemDisplay.includes(this.getPersistedSearch().displayType)
    ) {
      return this.getPersistedSearch().displayType
    }
    return this.props.availableItemDisplay[0];
  }

  computeDataList = () => {
    if(this.props.type === 'index') {
      return this.state.items;
    }
    else if(this.props.type === 'choice') {
      //TODO ADD search, order, pagination
      return this.props.choices;
    }
  }

  getSearchValues() {
    if(this.getPersistedSearch()) {
      return this.computedSearch(this.getSearch());
    }
    return {};
  }

  computeSearchForDisplay() {
    let search = this.getSearch();
    let result = {};
    if(search) {
      Object.keys(search).forEach((key) => {
        if(search[key]) {
          if(typeof search[key] === 'object') {
            if(Array.isArray(search[key]) && search[key].length > 0) {
              result[key] = search[key]
                .filter(item => !!item)
                .map((item) => {
                  if(item["label"]) {
                    return item["label"];
                  }
                  else if(item["name"]){
                    return item["name"];
                  }
                  else if(item["displayname"]){
                    return item["displayname"];
                  }
                  else if(item["title"]){
                    return item["title"];
                  }
                  else if(item["id"]){
                    return item["id"];
                  }
                  return item
                })
                .toString();
            }
            else {
              if(search[key]["label"]) {
                result[key] = search[key]["label"];
              }
              else if(search[key]["name"]){
                result[key] = search[key]["name"];
              }
              else if(search[key]["displayname"]){
                result[key] = search[key]["displayname"];
              }
              else if(search[key]["title"]){
                result[key] = search[key]["title"];
              }
              else if(search[key]["id"]){
                result[key] = search[key]["id"];
              }
            }
          }
          else if(search[key]) {
            result[key] = search[key];
          }
        }
      });
    }
    return result;
  }

  computedSearch(search) {
    let finalSearch = this.props.computeSearch(search);

    let additionalSearch = this.props.itemDisplay[this.getDisplayType()].additionalSearch
    if(additionalSearch) {
      Object.keys(additionalSearch).forEach((key) => {
        finalSearch[key] = additionalSearch[key];
      });
    }

    return finalSearch;
  }

  /**
   * start the loading of a new page
   *
   * @param page the page to load
   */
  loadNewPage = (page) => {
    if(this.props.type === 'index') {
      let indexScheme = null;
      if(this.props.itemDisplay[this.getDisplayType()] && this.props.itemDisplay[this.getDisplayType()].indexScheme) {
        indexScheme = this.props.itemDisplay[this.getDisplayType()].indexScheme;
      }
      this.setState({
        loading: true
      }, () => this.index(page, this.getItemPerPage(), this.getSearchValues(), this.getOrder(), indexScheme));
    }
  }

  /**
   * collect the result of the query and update the state of the component to
   * display the new set of data
   *
   * @param data the data of the query
   */
  updateIndex = (data) => {
    this.setState({
      loading: false,
      items: data.items,
      page: data.page,
      total: data.total,
      totalPage: data["total_page"]
    })
  }

  updateIndexFailure = (msg) => {
    this.setState({
      loading: false
    })
  }

  postExport = (data) => {
    this.setState({
      exportLoading: false
    })
  }

  postExportFailure = (msg) => {
    this.setState({
      exportLoading: false
    })
  }

  onChangeSearch = (fieldKey, value) => {
    let search = {
      ...this.state.search,
    }
    search[fieldKey] = value;
    this.setState({
      search: search
    })
  }

  computeHeadersFormValues = () => {
    return {
      headers: this.state.activeHeaders
    }
  }

  onChangeHeaders = (fieldKey, value) => {
    let activeHeaders = Object.keys(value).filter((key) => value[key] === true).map(Number);
    this.setState({
      activeHeaders: activeHeaders
    })
  }

  onClickSearchButton = (fieldKey) => {
    this.props.dispatch(removeSearchAction(this.props.id, fieldKey));
  }

  onClickExtraSearchButton = () => {
    this.props.dispatch(resetSearchAction(this.props.id));
    this.setState({
      collapseSearch: false
    })
  }

  onSubmitSearch = (values) => {
    this.props.dispatch(modifySearchAction(this.props.id, values));
    this.setState({
      collapseSearch: false
    })
  }

  calculteAs = () => {
    switch (this.props.type) {
      case 'choice':
        return 'button';
      case 'index':
      default:
        return 'a';
    }
  }

  displayPageTitle() {
    if(this.props.pageTitle !== null && this.props.pageTitle !== '') {
      return (
        <h3 className="page-title">{this.props.pageTitle}</h3>
      )
    }
  }

  displaySearch(){
    if(this.props.displaySearch) {
      return (
        <Collapse in={this.state.collapseSearch}>
          {this.props.searchForm && this.props.searchForm.fields && this.props.searchForm.fields.length > 0 ?
            <div className="item-index-collapse-container">
              <div className="d-flex justify-content-center">
                <MyForm
                  formKey={this.props.searchForm.formKey}
                  fields={this.props.searchForm.fields}
                  extraData={this.props.searchForm.extraData}
                  extraValues={this.props.searchForm.extraValues}

                  disabled={false}
                  ignoreValidation={true}

                  values={this.state.search}
                  onChangeField={this.onChangeSearch}

                  onSubmit={this.onSubmitSearch}
                  submitLabel="Filtrer"

                  displayExtraButton={true}
                  buttonExtraClassName="item-index-search-buttons"
                  onClickExtraButton={() => this.onClickExtraSearchButton()}

                  formExtraClassName="item-index-search-form"

                  numberColumn={this.props.sizing.numberColumn}
                  formSizeMd={this.props.sizing.formSizeMd}>
                </MyForm>
              </div>
            </div>
            :
            <div><div className="p-4">Aucun champs de recherche disponible.</div></div>
          }
        </Collapse>
      )
    }
  }

  displayHeaders() {
    if(this.props.itemDisplay.tab && this.props.itemDisplay.tab.enableHeaderEdit === true) {
      return (
        <Collapse in={this.state.collapseHeader}>
          <div className="item-index-collapse-container">
            <div className="d-flex justify-content-center">
              <MyForm
                formKey="headers-selection"
                fields={[
                  {
                    fieldKey: "headers",
                    label: strings.form.default.itemIndex.headers.label,
                    type: "dropdownMulti",
                    alwaysOpen: true,

                    input: 'options',
                    options: this.props.itemDisplay.tab.headers
                      .filter((header) => {
                        if(typeof header.hide === 'function') {
                          return !header.hide(this.props.credentials);
                        }
                        else if (header.hide) {
                          return !header.hide;
                        }
                        return true;
                      })
                      .map((header, i) => {
                        return {
                          value: i,
                          label: header.label
                        }
                      }),
                  }
                ]}

                ignoreValidation={true}

                values={this.computeHeadersFormValues()}
                onChangeField={this.onChangeHeaders}

                displaySubmitButton={false}

                formExtraClassName="item-index-search-form">
              </MyForm>
            </div>
          </div>
        </Collapse>
      )
    }
  }

  displayCurrentSearch() {
    let searches = this.computeSearchForDisplay();
    if(searches) {
      return (
        <Row className="container-current-search">
          {Object.keys(searches).map((fieldKey) => {
            if(searches[fieldKey] !== undefined) {
              return <Button key={fieldKey} variant="my-secondary-outline" onClick={() => this.onClickSearchButton(fieldKey)}><i className="icon-cross"/>  {strings.resolve(`${this.props.id}.search.${fieldKey}`)} : {searches[fieldKey]}</Button>;
            }
            return null;
          })}
        </Row>
      )
    }
  }

  displayToolbar(){
    if(this.props.displayToolbar) {
      return (
        <div className="container-toolbar">
          <Col>
            <Row>
              <Col xs={12} md={4}>
                {this.displayPageTitle()}
              </Col>
              <Col xs={12} md={8} className="d-flex justify-content-end">
                <ButtonToolbar className="item-index-toolbar">
                  {(this.props.export && ((Array.isArray(this.props.export.auth))?this.props.export.auth.some((role) => this.props.credentials.roles.includes(role)):this.props.credentials.roles.includes(this.props.export.auth)))?(
                      this.state.exportLoading?
                        <div><Loading container="parent" containerClassName="loading-form-row" size="small"/></div>
                      :
                        <ButtonGroup>
                          <Button
                            onClick={() => {
                              this.setState({
                                exportLoading: true
                              }, () => this.export(this.getSearchValues()))
                              // this.props.export.link(this.getSearchValues())
                            }}
                            variant={`my-secondary-toolbar-noline${this.state.collapseSearch?"-selected":""}`}>
                            <i className="icon-download"></i>
                          </Button>
                        </ButtonGroup>
                      )
                    :
                      <></>
                  }
                  {(this.props.newLink && ((Array.isArray(this.props.newLink.auth))?this.props.newLink.auth.some((role) => this.props.credentials.roles.includes(role)):this.props.credentials.roles.includes(this.props.newLink.auth)))?
                    <ButtonGroup>
                        <Button
                          as='a'
                          href={this.props.newLink.link()}
                          variant={`my-secondary-toolbar-noline${this.state.collapseSearch?"-selected":""}`}>
                          <div style={{height: "100%"}} className="d-flex align-items-center">
                            <i className="icon-plus"></i>
                          </div>
                        </Button>
                      </ButtonGroup>
                    :
                      <></>
                  }
                  <ButtonGroup>
                    <Button
                      onClick={() => this.setState({collapseSearch: !this.state.collapseSearch})}
                      variant={`my-secondary-toolbar-noline${this.state.collapseSearch?"-selected":""}`}>
                      <i className="icon-search"></i>
                    </Button>
                    {(this.props.itemDisplay.tab && this.props.itemDisplay.tab.enableHeaderEdit)?
                      <Button
                        onClick={() => this.setState({collapseHeader: !this.state.collapseHeader})}
                        variant={`my-secondary-toolbar-noline${this.state.collapseHeader?"-selected":""}`}>
                        <i className="icon-toggles"></i>
                      </Button>
                    :<></>}
                  </ButtonGroup>
                  <ButtonGroup>
                    <Button
                      onClick={() => this.props.dispatch(modifyItemPerPageAction(this.props.id, 10))}
                      variant={`my-secondary-toolbar-noline${this.getItemPerPage()===10?"-selected":""}`}>
                      <div>10</div>
                    </Button>
                    <Button
                      onClick={() => this.props.dispatch(modifyItemPerPageAction(this.props.id, 25))}
                      variant={`my-secondary-toolbar-noline${this.getItemPerPage()===25?"-selected":""}`}>
                      <div>25</div>
                    </Button>
                    <Button
                      onClick={() => this.props.dispatch(modifyItemPerPageAction(this.props.id, 50))}
                      variant={`my-secondary-toolbar-noline${this.getItemPerPage()===50?"-selected":""}`}>
                      <div>50</div>
                    </Button>
                    {/*
                    <Button
                      onClick={() => this.props.dispatch(modifyItemPerPageAction(this.props.id, 100))}
                      variant={`my-secondary-toolbar-noline${this.getItemPerPage()===100?"-selected":""}`}>
                      <div>100</div>
                    </Button>
                    */}
                  </ButtonGroup>
                  <ButtonGroup>
                    <Button
                      onClick={() => this.props.dispatch(modifyOrderAction(this.props.id, 'ASC'))}
                      variant={`my-secondary-toolbar-noline${this.getOrder()==='ASC'?"-selected":""}`}>
                      <i className="icon-trieaz"></i>
                    </Button>
                    <Button
                      onClick={() => this.props.dispatch(modifyOrderAction(this.props.id, 'DESC'))}
                      variant={`my-secondary-toolbar-noline${this.getOrder()==='DESC'?"-selected":""}`}>
                      <i className="icon-trieza"></i>
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup>
                    {this.props.availableItemDisplay.map((item, index) => {
                      return (
                        <Button
                          key={index}
                          onClick={() => this.props.dispatch(modifyDisplayTypeAction(this.props.id, item))}
                          variant={`my-secondary-toolbar-noline${this.getDisplayType()===item?"-selected":""}`}>
                          <i className={`icon-${Index.itemDisplayIcons[item]}`}></i>
                        </Button>
                      )
                    })}
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </Row>
            <Row>
              <Container className="d-flex flex-column align-items-center">
                {this.displaySearch()}
                {this.displayHeaders()}
              </Container>
            </Row>
          </Col>
        </div>
      )
    }
  }

  displayFlashbag() {
    return (
      <Flashbag />
    )
  }

  displayResultTotal() {
    if(this.state.total) {
      return (
        <div style={{color:'#B2B2B2'}}>{strings.formatString(strings.common.itemIndex.totalDisplay, this.state.total)}</div>
      )
    }
  }

  displayListTyped(){
    let headers = null;
    switch (this.getDisplayType()) {
      case "bar":
        return (
          <ListGroup className="item-index-list">
            {this.computeDataList().map((item, i) =>
              <ItemIndexBar
                key={i}
                as={this.calculteAs()}
                onClickItem={this.props.onClickItem}
                entityPath={this.props.entityPath}
                itemDisplay={this.props.itemDisplay.bar}
                item={item}
                cartItem={this.props.cartItem}
                buttons={this.props.buttons}/>
            )}
          </ListGroup>
        )
      case "card":
        return (
          <Row className="item-index-list item-index-list-card">
            {this.computeDataList().map((item, i) => {
              return (
                <Col key={i} xs={12} md={6} lg={4}>
                  <ItemIndexCard
                    as={this.calculteAs()}
                    onClickItem={this.props.onClickItem}
                    entityPath={this.props.entityPath}
                    itemDisplay={this.props.itemDisplay.card}
                    item={item}
                    cartItem={this.props.cartItem}
                    buttons={this.props.buttons}/>
                </Col>
              )
            })}
          </Row>
        )
      case "tab":
        headers = this.props.itemDisplay.tab.headers;
        headers = headers.filter((header) => {
          if(typeof header.hide === 'function') {
            return !header.hide(this.props.credentials);
          }
          else if (header.hide) {
            return !header.hide;
          }
          return true;
        })

        if(this.props.itemDisplay.tab.enableHeaderEdit === true) {
          headers = headers.filter((header, i) => {
            return this.state.activeHeaders.includes(i);
          });
        }

        return (
          <Table
            cellCollection={{
              string: StringCell,
              integer: IntegerCell,
              float: FloatCell,
              price: PriceCell
            }}>
            <TableHeader>
              {headers
                .map((header, i) => {
                  return <TableTextHeader key={i} value={header.value} label={header.label} type={header.type}/>
                }
              )}
            </TableHeader>
            <TableBody>
              {this.computeDataList().map((item, i) => {
                return (
                  <TableRowWithCollapse
                    key={i}
                    as={this.calculteAs()}
                    className="row-my-primary item-index-clickable"
                    onClickItem={this.props.onClickItem}
                    entityPath={this.props.entityPath}
                    itemDisplay={this.props.itemDisplay.tab}
                    data={item}>
                    <TableRowCollapsedElement>
                      <Row
                        className="justify-content-end"
                        noGutters={true} >
                        {this.props.buttons(item).map((button, i) => {
                          return <Button key={i} className="item-index-button" variant="my-secondary-outline" onClick={button.onClick}>{button.icon?<i className={button.icon}/>:null}{button.label?button.label:null}</Button>;
                        })}
                      </Row>
                    </TableRowCollapsedElement>
                  </TableRowWithCollapse>
                )
              })}
            </TableBody>
          </Table>
        )
      case "tabAlt":
        headers = this.props.itemDisplay.tabAlt.headers;
        headers = headers.filter((header) => {
          if(typeof header.hide === 'function') {
            return !header.hide(this.props.credentials);
          }
          else if (header.hide) {
            return !header.hide;
          }
          return true;
        })

        if(this.props.itemDisplay.tabAlt.enableHeaderEdit === true) {
          headers = headers.filter((header, i) => {
            return this.state.activeHeaders.includes(i);
          });
        }

        return (
          <Table
            cellCollection={{
              string: StringCell,
              integer: IntegerCell,
              float: FloatCell,
              price: PriceCell
            }}>
            <TableHeader subRows={true}>
              {headers
                .map((header, i) => {
                  return <TableTextHeader key={i} value={header.value} label={header.label} type={header.type}/>
                }
              )}
            </TableHeader>
            <TableBody>
              {this.computeDataList().map((item, i) => {
                let displayAdditionalRows = false;
                let additionalRows = (
                  <>
                    {this.props.itemDisplay.tabAlt.computeAdditionalRowData(item, i).map((rowData, j) => {
                      displayAdditionalRows = true;
                      return (
                        <TableRowCollapsedRow key={j} data={rowData}/>
                      )
                    })}
                  </>
                )
                return (
                  <TableRowWithCollapse
                    key={i}
                    subRows={true}
                    as={this.calculteAs()}
                    className="row-my-primary item-index-clickable"
                    onClickItem={this.props.onClickItem}
                    entityPath={this.props.entityPath}
                    itemDisplay={this.props.itemDisplay.tabAlt}
                    data={item}>
                    {displayAdditionalRows?additionalRows:undefined}
                    <TableRowCollapsedElement>
                      <Row>
                        <Col>
                          <Row
                            className="justify-content-end"
                            noGutters={true}>
                          {this.props.buttons(item).map((button, i) => {
                            return <Button key={i} className="item-index-button" variant="my-secondary-outline" onClick={button.onClick}>{button.icon?<i className={button.icon}/>:null}{button.label?button.label:null}</Button>;
                          })}
                          </Row>
                          <Row noGutters={true}>
                            {this.props.itemDisplay.tabAlt.rowCollapsedElement(item)}
                          </Row>
                        </Col>
                      </Row>
                    </TableRowCollapsedElement>
                  </TableRowWithCollapse>
                )
              })}
            </TableBody>
          </Table>
        )
      case "line":
      default:
        return (
          <div className="item-index-list">
            {this.computeDataList().map((item, i) =>
              <ItemIndexLine
                key={i}
                as={this.calculteAs()}
                onClickItem={this.props.onClickItem}
                entityPath={this.props.entityPath}
                itemDisplay={this.props.itemDisplay.line}
                item={item}
                cartItem={this.props.cartItem}
                buttons={this.props.buttons}/>
            )}
          </div>
        )
    }
  }

  /**
   * Display the list of items
   */
  displayList(){
    if(this.computeDataList() != null){
      return (
        <Col xs={12} className="list" >
          {this.displayResultTotal()}
          {this.displayListTyped()}
        </Col>
      )
    }
  }

  /**
   * Display the pagination
   */
  displayPagination(){
    if(this.props.displayPagination && this.state.page && this.state.totalPage){
      return (
        <Pagination className="pagination">
          {(this.state.page !== 1)? <Pagination.First onClick={() => this.loadNewPage(1)} /> : null}
          {(this.state.page !== 1)? <Pagination.Prev onClick={() => this.loadNewPage(this.state.page - 1)}/> : null}
          {(this.state.page - 3 >= 1)? <Pagination.Ellipsis /> : null}

          {(this.state.page - 2 >= 1)? <Pagination.Item onClick={() => this.loadNewPage(this.state.page - 2)}>{this.state.page - 2}</Pagination.Item> : null}
          {(this.state.page - 1 >= 1)? <Pagination.Item onClick={() => this.loadNewPage(this.state.page - 1)}>{this.state.page - 1}</Pagination.Item> : null}
          <Pagination.Item active onClick={(event) => console.log(event.target)}>{this.state.page}</Pagination.Item>
          {(this.state.page + 1 <= this.state.totalPage)? <Pagination.Item onClick={() => this.loadNewPage(this.state.page + 1)}>{this.state.page + 1}</Pagination.Item> : null}
          {(this.state.page + 2 <= this.state.totalPage)? <Pagination.Item onClick={() => this.loadNewPage(this.state.page + 2)}>{this.state.page + 2}</Pagination.Item> : null}

          {(this.state.page + 3 <= this.state.totalPage)? <Pagination.Ellipsis /> : null}
          {(this.state.page !== this.state.totalPage)? <Pagination.Next onClick={() => this.loadNewPage(this.state.page + 1)}/> : null}
          {(this.state.page !== this.state.totalPage)? <Pagination.Last onClick={() => this.loadNewPage(this.state.totalPage)}/> : null}
        </Pagination>
      )
    }
  }

  /**
   * Display when the list is empty
   */
  displayEmpty(){
    if(!this.state.loading) {
      if(this.props.type === 'index') {
        if(this.state.total === undefined || this.state.total === null || this.state.total === 0) {
          return (
            <div>
              {this.props.emptyText()}
            </div>
          )
        }
      }
      else if(this.props.type === 'choice') {
        if(this.props.choices.length === 0) {
          return (
            <div>
              {this.props.emptyText()}
            </div>
          )
        }
      }
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayLoading()}

        {this.displayToolbar()}
        {this.displayFlashbag()}
        <Container className="d-flex flex-column align-items-center">
          {this.displayCurrentSearch()}
        </Container>
        <Container className="d-flex flex-column align-items-center">
          {this.displayPagination()}
          {this.displayList()}
          {this.displayPagination()}
          {this.displayEmpty()}
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  search: state.persisted.search,
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(withModalHandler(Index));
