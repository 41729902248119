import React from 'react';
import BasicFormField from './BasicFormField';

import strings from '../../../Localization/Localization';

import { Form, InputGroup, Col } from 'react-bootstrap';
import '../../../css/form.css';

/**
 * FormFieldMail
 *
 * This class handles HTML form field.
 *
 * The particular field is for Mail, when the field is disabled, it shows a link
 * directly send a mail.
 *
 * Specific configuration attribute : none
 */
class FormFieldMail extends BasicFormField {

  getSpecificValidators() {
    return [
      {
        id: 'assertFormat',
        type: 'regex',
        conf: {
          regex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        },
        invalidFeedback: strings.form.default.field.mail.invalid.assertFormat,
        defaultValue: false,
      },
    ]
  }

  /**
   * Trigerred by a click on the mail when the field is disabled
   * Open a new tab to send a mail to the address shown by the field
   */
  onClickMail = (event) => {
    event.preventDefault();
    event.stopPropagation();

    window.open(`mailto:${this.getValue()}`, "_blank");
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          {
            //If the field is not disabled show the field as normal
            !this.props.disabled?
            <Form.Control
              className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius"
              placeholder={this.props.label}
              aria-label={this.props.label}
              aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
              type={this.props.type}
              disabled={this.props.disabled || this.props.submitting}
              value={this.getValue()}
              onChange={(event) => this.onChange(event.target.value)}
              isValid={(this.props.disabled)?null:this.isValid()}
              isInvalid={(this.props.disabled)?null:this.isInvalid()}
            />
            :
            //If the field is disabled show a link to send a mail
            <a className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius form-control align-items-center form-disabled" href={`mailto:${this.getValue()}`} onClick={this.onClickMail}>{this.getValue()}</a>
          }
          {this.displayValidating()}
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

export default FormFieldMail;
