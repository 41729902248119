import BaseAssert from './BaseAssert';

class ForbiddenCharactersAssert extends BaseAssert {

  defaultConf = { characters:'' };

  assert() {
    let regex = new RegExp('['+this.conf.characters.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')+']');
    return (this.getValue() !== undefined)? this.getValue().match(regex) === null:null;
  }
}

export default ForbiddenCharactersAssert;
