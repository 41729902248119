export const dashboard = {
  name: "Administratif",
  version: 1,

  dashboardSize: 8,
  roles: ['ROLE_ACCOUNTING_BILL'],

  widgets: [
    {
      id: 'bill_stat-1',
      position: 0
    },
    {
      id: 'administratif_ticket_stat-1',
      position: 16
    },
    {
      id: 'bill_list-1',
      position: 2
    },
    {
      id: 'administrative_ticket_list-1',
      position: 5
    },
  ]
}
