const yousign = {
  appointment: {
    breadcrumb: "Signature",
  },
  quote: {
    breadcrumb: "Signature",
  },
};

export default yousign;
