export const technical_ticket_stat = {
  id: "technical_ticket_stat-1",
  default: true,
  roles: [],

  title: "Stat ticket",
  type: "listStat",
  conf: {
    element: "technicalTicket",
    stats: [
      {
        title:"Qté panne total",
        stat:"quantity",
        attribute:"id",
        filters:{
          state:{
            _parameterType:"value",
            _title:"",
            value:[
              {
                value:"new",
                label:"Nouveau"
              },
              {
                value:"waiting",
                label:"En attente"
              },
              {
                value:"in_progress",
                label:"En cours de progrès"
              }
            ]
          },
          requestType:[
            {
              id:3,
              name:"Panne totale"
            }
          ],
        }
      },
      {
        title:"Qté dépannage",
        stat:"quantity",
        attribute:"id",
        filters:{
          state:{
            _parameterType:"value",
            _title:"",
            value:[
              {
                value:"new",
                label:"Nouveau"
              },
              {
                value:"waiting",
                label:"En attente"
              },
              {
                value:"in_progress",
                label:"En cours de progrès"
              }
            ]
          },
          requestType:[
            {
              id:15,
              name:"Dépannage"
            }
          ],
        }
      },
      {
        title:"Qté aide à la configuration",
        stat:"quantity",
        attribute:"id",
        filters:{
          state:{
            _parameterType:"value",
            _title:"",
            value:[
              {
                value:"new",
                label:"Nouveau"
              },
              {
                value:"waiting",
                label:"En attente"
              },
              {
                value:"in_progress",
                label:"En cours de progrès"
              }
            ]
          },
          requestType:[
            {
              id:4,
              name:"Aide à la configuration"
            }
          ],
        }
      },
      {
        title:"Qté modification de service",
        stat:"quantity",
        attribute:"id",
        filters:{
          state:{
            _parameterType:"value",
            _title:"",
            value:[
              {
                value:"new",
                label:"Nouveau"
              },
              {
                value:"waiting",
                label:"En attente"
              },
              {
                value:"in_progress",
                label:"En cours de progrès"
              }
            ]
          },
          requestType:[
            {
              id:5,
              name:"Modification du service"
            }
          ],
        }
      },
    ]
  },
  column: 2,
  row: 2
}
