import React from 'react';

import strings from '../../Localization/Localization';

import '../../css/notification.css';

import { Button, OverlayTrigger, Popover, Tooltip, Row, Col } from 'react-bootstrap';

import Loading from '../Layout/Loading';

import Badge from '../Basic/Badge';

import { withModalHandler } from '../../HOC/ModalHandler';
import { indexLast, markAllAsSeen } from '../../API/Notifications';

import TemplateSelector from './TemplateSelector';

class NotificationCenter extends React.Component {

	constructor(props){
    super(props);

		//state of the component, including :
    // - itemPerPage : the number of item on each page
    // - items : the items currently displayed
    // - page : the page currently displayed
    // - total : the total number of items
    // - totalPage : the total number of page
    this.state = {
			loading: true,

      itemPerPage: 10,
      items: null,
      page: null,
      total: null,
      totalPage: null
    }

		this.index = this.props.modalHandler.addVerificationWithCallback(indexLast, this.updateIndex);
  }

	/**
   * Once the component is mounted, we can query for the first page of data
   */
  componentDidMount(){
    this.index();
  }

	/**
   * collect the result of the query and update the state of the component to
   * display the new set of data
   *
   * @param data the data of the query
   */
  updateIndex = (data) => {
    this.setState({
			loading: false,

      items: data.items,
      page: data.page,
      total: data.total,
      totalPage: data.total_page,

			markedAsSeen: 0
    })
  }

	getBadgeValue = () => {
		if(this.state.total === 0) {
			return null;
		}
		else if(this.state.total>10) {
			return '9+';
		}
		else {
			return this.state.total;
		}
	}

	onClickAllAsSeen = () => {
		this.setState({
			loading: true
		},
			() => markAllAsSeen().then((data) => this.setState({total:0, markedAsSeen:data.total, loading:false}))
		)

	}

  displayNotificationList() {
    if(this.state.items !== undefined && this.state.items !== null && this.state.total !== 0){
      return (
        <div>
          {this.state.items.map((item, key) => {
            return (
              <TemplateSelector
                key={key}
                item={item}/>
            )
          })}
        </div>
      )
    }
		else if(this.state.total === 0) {
			return (
        <div>
          {strings.common.notification._empty}
        </div>
      )
		}
  }

	displayPopover() {
		return (
			<Popover
        className="notification-center-popover-container">
		    <Popover.Title as="div" className="notification-center-popover-header">
					<Row className="d-flex flex-row justify-content-between">
						<Col>Notification</Col>
						<Col xs="auto" className="d-flex flex-row">
							<Col xs="auto" className="no-gutters">
								<OverlayTrigger
									placement="left"
									overlay={
										<Tooltip>{strings.common.notification._button.allMarkAsSeen}</Tooltip>
									}>
									<Button className="notification-button" disabled={this.state.total === null || this.state.total === 0} variant="my-primary-noline" onClick={() => this.onClickAllAsSeen()}><i className="icon-mail-read"/></Button>
								</OverlayTrigger>
							</Col>
							<Col xs="auto" className="no-gutters">
								<OverlayTrigger
									placement="left"
									overlay={
										<Tooltip>{strings.common.notification._button.index}</Tooltip>
									}>
									<a className="btn btn-my-primary-noline notification-button" variant="my-primary-noline" href="/notifications/index"><i className="icon-doc"/></a>
								</OverlayTrigger>
							</Col>
						</Col>
					</Row>
				</Popover.Title>
		    <Popover.Content className="no-gutter notification-center-popover-content-container">
          {this.displayNotificationList()}
		    </Popover.Content>
		  </Popover>
		)
	}

	displayIcon() {
		if(this.state.loading) {
			return (
				<Loading container="parent" size="small"/>
			)
		}
		else {
			return (
				<i className='icon-bell'></i>
			)
		}
	}

  render() {
    return (
      <OverlayTrigger
				placement="bottom"
				trigger="click"
				overlay={this.displayPopover()}
        rootClose={true}>
        <div>
          <Badge
            value={this.getBadgeValue()}
            disabled={this.state.total === 0 || this.state.total === null}
            rounded={true}
            position='bottom-right-circle'
            size='small'
            variant='info'>
            <Button className="m-2" variant="my-secondary-noline">
              {this.displayIcon()}
            </Button>
          </Badge>
        </div>
			</OverlayTrigger>
    );
  }
}

export default withModalHandler(NotificationCenter);
