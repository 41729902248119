import React from 'react';

import '../../css/homepage.css';

import strings from '../../Localization/Localization';

import { connect } from 'react-redux';
import { resetBreadcrumbAction } from '../../Store/Action/breadcrumb';

import { Container, Col } from 'react-bootstrap';

import MainLayout from './MainLayout';

import Flashbag from '../Flashbag/Flashbag';

import {
  users,

  partners,
  places,

  products,
  productFamilies,
  productSubFamilies,
  productDependencies,
  constructors,
  orders,
  quotes,
  services,

  accountProducts,
  bills,

  technicalTicket,
  commercialTicket,
  administrativeTicket,

  phoneCDR,
  phonePackage,

  notifications,

} from '../../MetaData/Route/routes';

class Homepage extends React.Component {

  componentDidMount() {
    this.props.dispatch(resetBreadcrumbAction(window.location.pathname, "Home"));
  }

  displayUser() {
    if(this.props.credentials.roles.includes('ROLE_USER_INDEX')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={users.routes.index.createPath()}>
						<div><i className="icon-users icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.users}</div>
					</a>
				</Col>
      )
    }
  }

  displayPartners() {
    if(this.props.credentials.roles.includes('ROLE_USER')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={partners.routes.index.createPath()}>
						<div><i className="icon-clients icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.partners}</div>
					</a>
				</Col>
      )
    }
  }

  displayPlaces() {
    if(this.props.credentials.roles.includes('ROLE_USER')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={places.routes.index.createPath()}>
						<div><i className="icon-pin icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.places}</div>
					</a>
				</Col>
      )
    }
  }

  displayProducts() {
    if(this.props.credentials.roles.includes('ROLE_USER')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={products.routes.index.createPath()}>
						<div><i className="icon-store icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.products}</div>
					</a>
				</Col>
      )
    }
  }

  displayProductFamilies() {
    if(this.props.credentials.roles.includes('ROLE_NYUKOM')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={productFamilies.routes.index.createPath()}>
						<div><i className="icon-nk icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.productFamilies}</div>
					</a>
				</Col>
      )
    }
  }

  displayProductSubFamilies() {
    if(this.props.credentials.roles.includes('ROLE_NYUKOM')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={productSubFamilies.routes.index.createPath()}>
						<div><i className="icon-nk icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.productSubFamilies}</div>
					</a>
				</Col>
      )
    }
  }

  displayProductDependencies() {
    if(this.props.credentials.roles.includes('ROLE_NYUKOM')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={productDependencies.routes.index.createPath()}>
						<div><i className="icon-nk icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.productDependencies}</div>
					</a>
				</Col>
      )
    }
  }

  displayConstructors() {
    if(this.props.credentials.roles.includes('ROLE_NYUKOM')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={constructors.routes.index.createPath()}>
						<div><i className="icon-nk icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.constructors}</div>
					</a>
				</Col>
      )
    }
  }

  displayOrders() {
    if(this.props.credentials.roles.includes('ROLE_ORDER')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={orders.routes.index.createPath()}>
						<div><i className="icon-deplacement icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.orders}</div>
					</a>
				</Col>
      )
    }
  }

  displayQuotes() {
    if(this.props.credentials.roles.includes('ROLE_QUOTE')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={quotes.routes.index.createPath()}>
						<div><i className="icon-doc icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.quotes}</div>
					</a>
				</Col>
      )
    }
  }

  displayService() {
    if(this.props.credentials.roles.includes('ROLE_USER')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={services.routes.index.createPath()}>
						<div><i className="icon-sante icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.services}</div>
					</a>
				</Col>
      )
    }
  }

  displayAccounts() {
    if(this.props.credentials.roles.includes('ROLE_ACCOUNTING_ACCOUNT')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={accountProducts.routes.index.createPath()}>
						<div><i className="icon-doc icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.accountProducts}</div>
					</a>
				</Col>
      )
    }
  }

  displayBills() {
    if(this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={bills.routes.index.createPath()}>
						<div><i className="icon-doc icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.bills}</div>
					</a>
				</Col>
      )
    }
  }

  displayTechnicalTicket() {
    if(this.props.credentials.roles.includes('ROLE_USER')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={technicalTicket.routes.index.createPath()}>
						<div><i className="icon-support icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.technicalTicket}</div>
					</a>
				</Col>
      )
    }
  }

  displayCommercialTicket() {
    if(this.props.credentials.roles.includes('ROLE_USER')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={commercialTicket.routes.index.createPath()}>
						<div><i className="icon-music icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.commercialTicket}</div>
					</a>
				</Col>
      )
    }
  }

  displayAdministrativeTicket() {
    if(this.props.credentials.roles.includes('ROLE_USER')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={administrativeTicket.routes.index.createPath()}>
						<div><i className="icon-doc-on icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.administrativeTicket}</div>
					</a>
				</Col>
      )
    }
  }

  displayPhoneCDR() {
    if(this.props.credentials.roles.includes('ROLE_CDR')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={phoneCDR.routes.index.createPath()}>
						<div><i className="icon-sip icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.phoneCDR}</div>
					</a>
				</Col>
      )
    }
  }

  displayPhonePackage() {
    if(this.props.credentials.roles.includes('ROLE_CDR_PHONE_PACKAGE')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={phonePackage.routes.index.createPath()}>
						<div><i className="icon-sip icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.phonePackage}</div>
					</a>
				</Col>
      )
    }
  }

  displayNotifications() {
    if(this.props.credentials.roles.includes('ROLE_USER')) {
      return (
        <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 1 }} className="text-center entry">
					<a href={notifications.routes.index.createPath()}>
						<div><i className="icon-message icon-size-dashboard"></i></div>
						<div>{strings.common.homepage.notifications}</div>
					</a>
				</Col>
      )
    }
  }

  render() {
    return (
      <MainLayout>
        <Flashbag />
        <Container className="dashboard">
          <div className="row pres-circle">
            {this.displayUser()}
            {this.displayPartners()}
            {this.displayPlaces()}
            {this.displayProducts()}
            {this.displayProductFamilies()}
            {this.displayProductSubFamilies()}
            {this.displayProductDependencies()}
            {this.displayConstructors()}
            {this.displayOrders()}
            {this.displayQuotes()}
            {this.displayService()}
            {this.displayAccounts()}
            {this.displayBills()}
            {this.displayTechnicalTicket()}
            {this.displayCommercialTicket()}
            {this.displayAdministrativeTicket()}
            {this.displayPhoneCDR()}
            {this.displayPhonePackage()}
            {this.displayNotifications()}
          </div>
        </Container>
      </MainLayout>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(Homepage);
