import strings from '../../Localization/Localization';

import { checkCode } from '../../API/Products';

import { fetchFamilies } from '../../API/ProductFamilies';

import { fetchSubFamilies } from '../../API/ProductSubFamilies';
import { fetchConstructors } from '../../API/Constructors';

import { form as _buyingPriceForm } from './buyingPrice';
import { newDefaultContractForm as _newDefaultContractForm } from './contracts'
import { productDependencySlaveForm as _productSlaveForm } from './productDependencies';
import { productDependencyMasterForm as _productMasterForm } from './productDependencies';
import { form as _specialConditionOfSaleForm } from './specialConditionOfSale';
import { form as _specialConditionOfSaleArgumentCollectionForm } from './specialConditionOfSaleArgumentCollection';
import { form as _phonePackageProductForm } from './phonePackageProducts';

import FormFieldPrice from '../../Component/Form/Field/FormFieldPrice';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.product.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.product.name.invalid.assertEmpty,
      defaultValue: false
    },
    {
      id: 'assertMax',
      type: 'lengthMax',
      conf: {
        max: 100
      },
      invalidFeedback: strings.form.product.name.invalid.assertMax,
      defaultValue: false
    },
  ]
}

let code = {
  fieldKey: "code",
  type: "text",
  label: strings.form.product.code.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.product.code.invalid.assertEmpty,
      defaultValue: false
    },
    {
      id: 'assertMax',
      type: 'lengthMax',
      conf: {
        max: 40
      },
      invalidFeedback: strings.form.product.code.invalid.assertMax,
      defaultValue: false
    },
    {
      id: 'assertUnique',
      type: 'call',
      call: (value, getValues) => checkCode(getValues()["id"], value),
      invalidFeedback: strings.form.product.code.invalid.assertUnique,
      defaultValue: true,
    }
  ]
}

let description = {
  fieldKey: "description",
  type: "textarea",
  label: strings.form.product.description.label,
  validators: [
    {
      id: 'assertMax',
      type: 'lengthMax',
      conf: {
        max: 1024
      },
      invalidFeedback: strings.form.product.description.invalid.assertMax,
      defaultValue: false
    },
  ]
}

let productFamily = {
  fieldKey: "product_family",
  label: strings.form.product.productFamily.label,
  type: "dropdownEntity",

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.product.productFamily.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  loader: {
    id: "productFamilies",
    loader: fetchFamilies,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let productSubFamily = {
  fieldKey: "product_sub_family",
  label: strings.form.product.productSubFamily.label,
  type: "dropdownEntity",

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.product.productSubFamily.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "product_family",
      function: (component) => component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "productSubFamilies",
    loader: fetchSubFamilies,
    loaderRequire: (value, getValues) => getValues(0)["product_family"] && getValues(0)["product_family"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        product_family_id: getValues(0)["product_family"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let constructor = {
  fieldKey: "constructor",
  label: strings.form.product.constructor.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "constructors",
    loader: fetchConstructors,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let img = {
  fieldKey: "img",
  type: "file",
  label: strings.form.product.img.label,
  showOnDisabled: () => false,

  validators: [],
}

let activeOrder = {
  fieldKey: "active_order",
  type: "switch",
  label: strings.form.product.activeOrder.label,
  defaultValue: null,
  validators: []
}

let activeQuote = {
  fieldKey: "active_quote",
  type: "switch",
  label: strings.form.product.activeQuote.label,
  defaultValue: null,
  validators: []
}

let activeTicket = {
  fieldKey: "active_ticket",
  type: "switch",
  label: strings.form.product.activeTicket.label,
  defaultValue: null,
  validators: []
}

let orderBreaking = {
  fieldKey: "order_breaking",
  type: "triswitch",
  label: strings.form.product.orderBreaking.label,
  defaultValue: null,
  validators: []
}

let serviceTracking = {
  fieldKey: "service_tracking",
  type: "triswitch",
  label: strings.form.product.serviceTracking.label,
  defaultValue: null,
  validators: []
}

let serviceBreaking = {
  fieldKey: "service_breaking",
  type: "triswitch",
  label: strings.form.product.serviceBreaking.label,
  defaultValue: null,
  validators: []
}

let rentable = {
  fieldKey: "rentable",
  type: "triswitch",
  label: strings.form.product.rentable.label,
  defaultValue: null,
  validators: []
}

let displayMainIndex = {
  fieldKey: "display_main_index",
  type: "triswitch",
  label: strings.form.productSubFamily.displayMainIndex.label,
  defaultValue: null,
  validators: []
}

let price = {
  fieldKey: "price",
  type: "price",
  label: strings.form.product.price.label,
  validators: []
}

let priceType = {
  fieldKey: "price_type",
  type: "dropdown",
  label: strings.form.product.priceType.label,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.product.priceType.options.option0
    },
    {
      value: 1,
      label: strings.form.product.priceType.options.option1
    },
    {
      value: 2,
      label: strings.form.product.priceType.options.option2
    }
  ],
}

let minPrice = {
  fieldKey: "min_price",
  type: "price",
  label: strings.form.product.minPrice.label,
  validators: [
    // {
    //   id: 'assertEmpty',
    //   type: 'notEmptyOrNull',
    //   invalidFeedback: strings.form.product.minPrice.invalid.assertEmpty,
    //   defaultValue: false
    // },
    {
      id: 'assertMax',
      type: 'function',
      invalidFeedback: strings.form.product.minPrice.invalid.assertMax,
      defaultValue: false,

      conf: {
        function: (value, getValues) => {
          let ownValue = value;
          let priceValue = getValues()['price'];
          if(priceValue || priceValue === 0 || priceValue === '0') {
            if(isNaN(ownValue)) {
              ownValue = FormFieldPrice.parseFinalValue(ownValue);
            }
            if(isNaN(priceValue)) {
              priceValue = FormFieldPrice.parseFinalValue(priceValue);
            }
            if(Number(priceValue) >= Number(ownValue)) {
              return true;
            }
          }
          return false;
        }
      }
    },
  ]
}

let buyingPrices = {
  fieldKey: "buying_prices",
  type: "array",
  label: strings.form.product.buyingPrices.label,

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.product.buyingPrices.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.product.buyingPrices.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.product.buyingPrices.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: "",

    subForm: _buyingPriceForm,

    validators: [],
  },

  validators: []
}

let contract = {
  fieldKey: "contract",
  type: "subform",
  label: "",

  subForm: _newDefaultContractForm,

  validators: []
}

let productMasters = {
  fieldKey: "product_masters",
  type: "array",
  label: strings.form.product.productMasters.label,
  disabled: true,

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.product.productMasters.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.product.productMasters.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.product.productMasters.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: "",

    subForm: _productMasterForm,

    validators: [],
  },

  validators: []
}

let productSlaves = {
  fieldKey: "product_slaves",
  type: "array",
  label: strings.form.product.productSlaves.label,
  disabled: true,

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.product.productSlaves.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.product.productSlaves.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.product.productSlaves.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: "",

    subForm: _productSlaveForm,

    validators: [],
  },

  validators: []
}

let specialConditionOfSale = {
  fieldKey: "special_condition_of_sale",
  type: "subform",
  label: "",

  subForm: _specialConditionOfSaleForm,

  validators: []
}

let specialConditionOfSaleArgumentCollection = {
  fieldKey: "special_condition_of_sale_argument_collection",
  type: "subform",
  label: "",

  subForm: _specialConditionOfSaleArgumentCollectionForm,

  validators: []
}

let specialConditionOfSaleFinalText = {
  fieldKey: "special_condition_of_sale_final_text",
  type: "textarea",
  label: strings.form.product.specialConditionOfSaleFinalText.label,
  disabled: true,
  validators: []
}

let datasheet = {
  fieldKey: "datasheet_values",
  type: "array",
  label: "",

  validators: [],

  allowMoreField: false,
  defaultNumberOfField: 0,

  subField: {
    type: 'configurableField',
    label: "",
    validators: [],

    subField: () => {},
    subFieldConf: {
      label: (value) => value["datasheet"]["name"],
      type: (value) => 'text',
      disabled: () => false,
      validators: (value) => [],
      valueAttribute: 'value'
    },
  }
}

let phonePackageProduct = {
  fieldKey: "phone_package",
  type: "subform",
  label: strings.form.product.phonePackage.label,

  subForm: _phonePackageProductForm,

  validators: [],
}

export const datasheetForm = {
  formKey: "product",
  fields: [
    datasheet
  ]
}

export const priceForm = {
  formKey: "product",
  fields: [
    price,
    priceType,
    minPrice,
    buyingPrices
  ]
}

export const dependencyForm = {
  formKey: "product",
  fields: [
    productMasters,
    productSlaves
  ]
}

export const configurationForm = {
  formKey: "product",
  fields: [
    activeOrder,
    activeQuote,
    activeTicket,
    orderBreaking,
    serviceTracking,
    serviceBreaking,
    rentable,
    displayMainIndex
  ]
}

export const configurationEditForm = {
  formKey: "product",
  fields: [
    activeOrder,
    activeQuote,
    activeTicket,
    orderBreaking,
    serviceTracking,
    serviceBreaking,
    rentable,
    displayMainIndex
  ]
}

export const contractForm = {
  formKey: "product",
  fields: [
    contract
  ]
}

export const specialConditionOfSaleForm = {
  formKey: "product",
  fields: [
    specialConditionOfSaleFinalText,
    specialConditionOfSale,
    specialConditionOfSaleArgumentCollection
  ]
}

export const phonePackageForm = {
  formKey: "product",
  fields: [
    phonePackageProduct
  ]
}

export const form = {
  formKey: "product",
  fields: [
    name,
    code,
    description,
    img,
    productFamily,
    productSubFamily,
    constructor
  ]
}

export const newForm = {
  formKey: "product",
  fields: [
    name,
    code,
    description,
    img,
    productFamily,
    productSubFamily,
    activeOrder,
    activeQuote,
    activeTicket,
    orderBreaking,
    serviceTracking,
    serviceBreaking,
    rentable,
    price,
    priceType,
    minPrice,
    buyingPrices,
    contract,
    // productMasters,
    // productSlaves,
    specialConditionOfSale,
    specialConditionOfSaleArgumentCollection,
    constructor
  ]
}
