import React from 'react';

import { v4 as uuidv4 } from 'uuid';

/**
 * TableHeader
 */
class TableDropdownHeader extends React.Component {

  constructor(props) {
    super(props);

    this.listId = "table-header-"+uuidv4();
  }

  onChange = (e) => {
    let item = this.props.fieldList.find((item) => item.label === e.target.value);
    if(item !== undefined && item !== null) {
      this.props.onChange(item.value);
    }
  }

  displayDataList() {
    if(this.props.fieldList !== undefined) {
      return (
        <datalist id={this.listId}>
          {this.props.fieldList.map((item) => {
            return (
              <option key={item.value} value={item.label}></option>
            )
          })}
        </datalist>
      )
    }
    else {
      console.error("Table header dropdown : fieldList is not defined");
      return null;
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <th>
        <input className="table-header-dropdown" onChange={this.onChange} list={this.listId} placeholder={this.props.label}/>
        <datalist id={this.listId}>
          {this.props.fieldList.map((item) => {
            return (
              <option key={item.value} value={item.label}></option>
            )
          })}
        </datalist>
      </th>
    );
  }
}

export default TableDropdownHeader;
