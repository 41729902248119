import strings from '../../Localization/Localization';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.technicalData.name.label,
  defaultValue: '',
  validators: [
    // {
    //   id: 'assertEmpty',
    //   type: 'lengthMin',
    //   conf: {
    //     min: 1
    //   },
    //   invalidFeedback: strings.form.technicalData.name.invalid.assertEmpty,
    //   defaultValue: false
    // },
  ]
}

let ciphered = {
  fieldKey: "ciphered",
  type: "switch",
  label: strings.form.technicalData.ciphered.label,
  defaultValue: false,
  validators: []
}

let active = {
  fieldKey: "active",
  type: "switch",
  label: strings.form.technicalData.active.label,
  defaultValue: false,
  validators: []
}

let availableOrder = {
  fieldKey: "available_order",
  type: "switch",
  label: strings.form.technicalData.availableOrder.label,
  defaultValue: false,
  validators: []
}

let availableService = {
  fieldKey: "available_service",
  type: "switch",
  label: strings.form.technicalData.availableService.label,
  defaultValue: false,
  validators: []
}

let displayBillOrder = {
  fieldKey: "display_bill_order",
  type: "text",
  label: strings.form.technicalData.displayBillOrder.label,
  defaultValue: null,
  validators: [
    {
      id: 'assertNumber',
      type: 'regex',
      conf: {
        regex: /\d*/
      },
      invalidFeedback: strings.form.technicalData.displayBillOrder.invalid.assertNumber,
      defaultValue: true
    },
  ]
}

export const form = {
  formKey: "technicalData",
  fields: [
    name,
    ciphered,
    active,
    availableOrder,
    availableService,
    displayBillOrder
  ]
}
