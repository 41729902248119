import strings from '../../Localization/Localization';

import { bonusType } from './commercialBonuses';

let totalTfMin = {
  fieldKey: "total_tf_min",
  type: "price",
  label: strings.form.commercialBonusThreshold.totalTfMin.label,
  validators: []
}

let totalTfMax = {
  fieldKey: "total_tf_max",
  type: "price",
  label: strings.form.commercialBonusThreshold.totalTfMax.label,
  validators: []
}

let flatBonus = {
  fieldKey: "flat_bonus",
  type: "price",
  label: strings.form.commercialBonusThreshold.flatBonus.label,
  validators: []
}

let appliedFrom = {
  fieldKey: "applied_from",
  type: "datetime",
  label: strings.form.commercialBonusThreshold.appliedFrom.label,
  validators: []
}

let appliedUntil = {
  fieldKey: "applied_until",
  type: "datetime",
  label: strings.form.commercialBonusThreshold.appliedUntil.label,
  validators: []
}

export const form = {
  formKey: "commercialBonusThreshold",
  fields: [
    bonusType,
    totalTfMin,
    totalTfMax,
    flatBonus,
    appliedFrom,
    appliedUntil
  ]
}
