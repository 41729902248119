import React from 'react';

import strings from '../../../Localization/Localization';
import { orders } from '../../../MetaData/Route/routes';

import BasicNotification from './BasicNotification';

class OrderCancelValidatedClient extends BasicNotification {

  displayMessage() {
    return (
      <a href={orders.routes.show.createPath(this.props.item._arguments["%order_id%"])}>{strings.formatString(strings.common.notification.order_cancel_validated_client, this.props.item._arguments["%order_number%"])}</a>
    )
  }
}

export default OrderCancelValidatedClient;
