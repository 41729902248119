import React from 'react';

import '../../../css/grid.css';

const SampleBox = React.forwardRef(function SampleBox(props, ref) {
  return (
    <div ref={ref} className="grid-sample-box">
    </div>
  );
})

export default SampleBox;
