import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, InputGroup, Col } from 'react-bootstrap';
import '../../../css/form.css';

import { v4 as uuid } from 'uuid';

import NotEmptyOrNullAssert from '../../../Form/Assert/NotEmptyOrNullAssert';

/**
 * FormFieldDropdown
 *
 * This class handles HTML form field.
 *
 * The particular field is a Dropdown, it allow to choose from a list.
 *
 * Specific configuration attribute :
 * - showEmpty : the blank value
 * - adapter : an adapter in case the options are objects
 * - filterOptions : inherit from FormFieldDropdown
 *
 * - autoComplete : if the dropdown should be displayed as a select (without
 *                auto complete) or an input with a datalist (with
 *                auto complete)
 *
 * - input : the type of input either 'options' or 'formValues'
 *         if 'options', options need to be filled
 *         if 'formValues', compute need to be filled
 *
 * - options : the list of options in the dropdown represented by an array
 *           each object inside the array should have both a label and a value
 *
 * - compute : compute the array of options from the others values of the form
 */
class FormFieldDropdown extends BasicFormField {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    showEmpty: false,
    autoComplete: false,
    input: 'options',
    options: []
  }

  constructor(props) {
    super(props);

    this.uuid = uuid();
    //the state of the component
    //options are the options coming from the call
    this.state = Object.assign(this.state, {
      options: []
    })
  }

  /**
   * @inheritdoc
   */
  getSpecificAsserts() {
    return {
      notEmptyOrNull: NotEmptyOrNullAssert,
    }
  }

  /**
   * @inheritdoc
   */
  getValue(){
    let value = super.getValue();
    if(this.props.autoComplete && this.props.adapter && this.props.adapter.requirement(value)) {
      value = this.applyAdapter(value).label;
    }
    if(!this.props.autoComplete && this.props.adapter && this.props.adapter.requirement(value)) {
      value = this.applyAdapter(value).value;
    }
    return value;
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    if(this.props.adapter && this.props.adapter.isFinalValue) {
      return this.applyAdapter(super.getFinalValue()).value;
    }
    return super.getFinalValue();
  }

  /**
   * get the value of the options
   */
  getOptions() {
    //check for input type
    if(this.props.input === 'options' && this.props.options) {
      return this.props.options;
    }
    else if(this.props.input === 'formValues') {
      let options = this.props.compute(this.props.getValues, this.props.getExtraValues());
      if(options) {
        return options;
      }
    }
    return [];
  }

  /**
   * get the value of the options with the filter
   */
  getFilteredOptions() {
    if(this.props.filterOptions) {
      return this.getOptions().filter((item, i) => {
        return this.props.filterOptions(item, this.props.getValues, this.props.getExtraValues);
      })
    }
    return this.getOptions();
  }

  /**
   * apply the adapter for options
   */
  applyAdapter(option) {
    if(this.props.adapter && this.props.autoComplete) {
      return {
        value: 'value('+this.props.adapter.getValue(option)+')',
        label: this.props.adapter.getLabel(option)
      }
    }
    else if(this.props.adapter && !this.props.autoComplete) {
      return {
        value: this.props.adapter.getValue(option),
        label: this.props.adapter.getLabel(option)
      }
    }
    else if(option.value !== undefined && option.value !== null && option.label !== undefined  && option.label !== null) {
      return option;
    }
    return {
      value: option,
      label: option
    }
  }

  /**
   * Send new value to parent element
   */
  onChange = (value, ignoreValidation = false) => {
    let endValue = value;
    if(value && this.props.adapter && this.props.adapter.getValue && !this.props.adapter.isValue) {
      this.getOptions().some((item, i) => {
        if(this.props.autoComplete && 'value('+this.props.adapter.getValue(item).toString()+')' === value.toString()) {
          endValue = item;
          return true;
        }
        else if(!this.props.autoComplete && this.props.adapter.getValue(item).toString() === value.toString()) {
          endValue = item;
          return true;
        }
        return false;
      })
    }
    super.onChange(endValue, ignoreValidation);
  }

  getDatalistId() {
    return this.uuid;
  }

  displayOptionsAsDatalist() {
    if(this.props.autoComplete) {
      return (
        <datalist id={this.getDatalistId()}>
          {this.displayOptions()}
        </datalist>
      )
    }
  }

  displayOptionsAsSelect() {
    if(!this.props.autoComplete) {
      return this.displayOptions();
    }
    return null;
  }

  /**
   * Display all options of the field
   */
  displayOptions() {
    return (
      <>
        {this.props.showEmpty?
          <option value=''/>
          :null
        }
        {this.getFilteredOptions().map((option, i) => {
          let {value, label} = this.applyAdapter(option);
          return (
            <option key={i} value={value} label={label}>{label}</option>
          )
        })}
      </>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field">
        {this.displayOptionsAsDatalist()}
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          <Form.Control
            as={this.props.autoComplete?"input":"select"}
            className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius"
            placeholder={this.props.label}
            aria-label={this.props.label}
            aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
            type="text"
            list={this.props.autoComplete?this.getDatalistId():null}
            disabled={this.props.disabled || this.props.submitting || this.state.loading}
            value={this.getValue()}
            onChange={(event) => this.onChange(event.target.value)}
            isValid={(this.props.disabled)?null:this.isValid()}
            isInvalid={(this.props.disabled)?null:this.isInvalid()}
          >
            {this.displayOptionsAsSelect()}
          </Form.Control>
          {this.displayValidating()}
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

/**
 * HOC Modal Handler to support call options
 */
export default FormFieldDropdown;
