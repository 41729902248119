import React from 'react';

import * as echarts from 'echarts';
import 'echarts/i18n/langFR';

import { isDeepEqual } from '../../Utils/DeepEqual';

import { v4 as uuid } from 'uuid';

/**
 * AutoTooltip
 */
class Chart extends React.Component {

  static defaultProps = {
    option: null,
    onClickItem: (data) => {}
  }

  constructor(props) {
    super(props);

    this.uuid = uuid();
  }

  componentDidMount() {
    let chartDom = document.getElementById(this.uuid);
    this.chart = echarts.init(chartDom, null, { locale:'FR' });
    this.chart.setOption(this.props.option);

    this.chart.on("click", this.onChartClick);

    window.addEventListener("resize", this.resizeChart);

    this.checkChartSize();
  }

  componentDidUpdate(prevProps) {
    if(!isDeepEqual(prevProps.option, this.props.option)) {
      this.chart.setOption(this.props.option);
    }

    this.checkChartSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeChart);
    this.chart.dispose();
  }

  checkChartSize() {
    if(this.chart && Math.abs(this.chart.getHeight() - this.chart.getDom().clientHeight) > 5) {
      this.resizeChart();
    }
  }

  resizeChart = () => {
    if(this.chart) {
      this.chart.resize();
    }
  }

  onChartClick = params => {
    if(params.componentType === "series") {
      this.props.onClickItem(params.data);
    }
  }

  /**
   * Main render method for React Component
   */
  render() {

    return (
      <div id={this.uuid} style={{height: "100%", width: "100%"}}/>
    );
  }
}

export default Chart;
