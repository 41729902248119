import React from 'react';

import { Redirect } from "react-router-dom";

import { Container } from 'react-bootstrap';

import Loading from '../Layout/Loading';

import MyForm from '../Form/MyForm';

import { withModalHandler } from '../../HOC/ModalHandler';

import { withRouter } from "react-router-dom";

/**
 * Transition
 *
 * This component managed a transition
 */
class Transition extends React.Component {

  constructor(props) {
    super(props);

    this.transition = this.props.modalHandler.addVerificationWithCallback(props.api, this.postTransition, this.postTransitionFailure);

    this.state = {
      loading: false,

      values: {},

      redirection: false,
    }
  }

  componentDidMount() {
    if(this.props.type === 'direct') {
      this.setState({
        loading: true
      }, () => {
        this.transition(this.props.id, {});
      })
    }
  }

  postTransition = (data) => {
    if(this.props.postTransition) {
      switch (this.props.postTransition.type) {
        case 'redirection':
          this.setState({
            redirection: true,
            loading: false,
            path: this.props.postTransition.route(data)
          });
          // this.props.history.push(this.props.postTransition.route(data));
          break;
        default:

      }
    }
  }

  postTransitionFailure = (msg) => {
    this.setState({
      loading: false
    });
  }

  /**
   * callback used by the form to change the item
   *
   * @param fieldKey the fieldkey to change change in the form
   * @param value the new value of the form
   */
  onChangeItem = (fieldKey, value) => {
    let values = Object.assign({}, this.state.values);
    values[fieldKey] = value;
    this.setState({
      values: values,
    });
  }

  /**
   * callback used by the form on submit
   */
  onSubmit = (values) => {
    this.setState({
      loading: true
    }, () => {
      this.transition(this.props.id, values);
    })
  }

  displayRedirect() {
    if(this.state.redirection === true) {
      return (
        <Redirect to={this.state.path} />
      )
    }
  }

  displayForm() {
    if(this.props.type === 'form') {
      return (
        <MyForm
          formKey={this.props.form.formKey}
          fields={this.props.form.fields}
          extraValues={this.props.form.extraValues}
          disabled={this.props.disabled}
          values={this.state.values}
          onChangeField={this.onChangeItem}
          onSubmit={this.onSubmit}
        />
      )
    }
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        <Container className="d-flex flex-column align-items-center">
          {this.displayRedirect()}
          {this.displayLoading()}
          {this.displayForm()}
        </Container>
      </>
    );
  }
}

export default withRouter(withModalHandler(Transition));
