import strings from '../../Localization/Localization';

let keyField = {
  fieldKey: "key",
  type: "text",
  label: strings.form.specialConditionOfSaleArgumentCollection.key.label,
  validators: []
}

let valueField = {
  fieldKey: "value",
  type: "text",
  label: strings.form.specialConditionOfSaleArgumentCollection.value.label,
  validators: []
}

const argumentForm = {
  formKey: "specialConditionOfSale",
  fields: [
    keyField,
    valueField
  ]
}

let argumentsField = {
  fieldKey: "arguments",
  type: "array",
  label: "",

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.specialConditionOfSaleArgumentCollection.arguments.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.specialConditionOfSaleArgumentCollection.arguments.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.specialConditionOfSaleArgumentCollection.arguments.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: strings.form.specialConditionOfSaleArgumentCollection.arguments.label,

    subForm: argumentForm,

    validators: [],
  },

  validators: []
}

export const form = {
  formKey: "specialConditionOfSaleArgument",
  fields: [
    argumentsField,
  ]
}
