import strings from '../../Localization/Localization';

import { fetchServices } from '../../API/Services';

import {
  services as servicesRoutes
} from '../Route/routes';

let serviceSlave = {
  fieldKey: "service_slave",
  type: "dropdownEntity",
  label: strings.form.service.serviceSlave.label,
  validators: [],

  disabledLink: (value, getValues) => {
    return servicesRoutes.routes.show.createPath(value["id"]);
  },

  loader: {
    id: "services",
    loader: fetchServices,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["cart_item"] && data["cart_item"]["product"] && data["cart_item"]["product"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["cart_item"]["product"]["name"],
  },
}

export const form = {
  formKey: "serviceDpendency",
  fields: [
    serviceSlave
  ]
}
