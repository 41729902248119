import React from 'react';

import MainLayout from '../Layout/MainLayout';

import BaseEntityTabs from '../EntityTabs/MyEntityTabs';

/**
 * MyTabsWithConf
 *
 * This component configure the entity tab component
 */
class MyEntityTabs extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <MainLayout>
        <BaseEntityTabs
          pageTitle={this.props.conf.pageTitle}
          pageTitleReturnPath={this.props.conf.pageTitleReturnPath}
          breadcrumbIdentifier={this.props.conf.breadcrumbIdentifier}

          apiGet={this.props.conf.apiGet}

          defaultTab={this.props.conf.defaultTab}
          tabs={this.props.conf.tabs}

          extendedHeader={this.props.conf.extendedHeader}
          header={this.props.conf.header}

          menu={this.props.conf.menu}/>
      </MainLayout>
    );
  }
}

export default MyEntityTabs;
