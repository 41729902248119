import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getBillsFromId,
  // startBilling,
  // startBillingTest
} from '../../API/Billings';
import { form, billsForm } from '../Form/billings';
import { form as searchForm } from '../Form/Search/billings';

import { billings as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.billings,

    id: "billing",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.createdAtMin) {
        result["created_at_min"] = search.createdAtMin;
      }
      if(search.createdAtMax) {
        result["created_at_max"] = search.createdAtMax;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            label: strings.billing.index.tab.headers.filename,
            value: "filename"
          },
          {
            label: strings.billing.index.tab.headers.createdAt,
            value: "created_at"
          },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.billings,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "created_at",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.billing.show.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {
          console.log(data);
        },
        onSubmit: (data) => {},

        apiGet: getFromId,

        auth: ['ROLE_ACCOUNTING_ACCOUNT'],
        editable: false
      },
      bills: {
        title: strings.billing.show.tab.bills,
        type: 'form',
        form: billsForm,

        onUpdateItem: (data) => {},
        onSubmit: (data) => {},

        apiGet: getBillsFromId,

        auth: ['ROLE_ACCOUNTING_ACCOUNT'],
        editable: false
      },
    }
  }
}
