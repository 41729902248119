import React from 'react';

/**
 * TableRow
 */
class TableRowCollapsedRow extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <></>
    );
  }
}

export default TableRowCollapsedRow;
