import strings from '../../Localization/Localization';

import { fetchPhonePackageProducts } from '../../API/PhonePackageProducts';

import { form as _phonePackageProductForm } from './phonePackageProducts';

let consumption = {
  fieldKey: "current_month_consumption",
  type: "subform",
  label: strings.form.phonePackageOrder.currentMonthConsumption.label,
  validators: [],

  subForm: {
    formKey: "consumptions",
    fields: [
      {
        fieldKey: "specific_phone_number_used",
        type: "text",
        label: strings.form.phonePackageOrder.specificPhoneNumberUsed.label,
        validators: [],

        messageRight: (value, getValues) => `sur ${getValues(0)['included_specific_phone_number']}`
      },
      {
        fieldKey: "total_data_quantity_used",
        type: "text",
        label: strings.form.phonePackageOrder.totalDataQuantityUsed.label,
        validators: [],

        messageRight: (value, getValues) => `sur ${getValues(0)['included_total_data_quantity']}`
      },
      {
        fieldKey: "total_phone_time_used",
        type: "text",
        label: strings.form.phonePackageOrder.totalPhoneTimeUsed.label,
        validators: [],

        messageRight: (value, getValues) => `sur ${getValues(0)['included_total_phone_time']}`
      },
      {
        fieldKey: "last_update",
        type: "datetime",
        label: strings.form.phonePackageOrder.lastUpdate.label,
        validators: [],
      }
    ]
  },
}

let phonePackageProduct = {
  fieldKey: "phone_package_product",
  type: "subform",
  label: "",
  validators: [],

  subForm: _phonePackageProductForm,
}

let phonePackageProductChoice = {
  fieldKey: "phone_package_product",
  type: "dropdownEntity",
  label: strings.form.phonePackageOrder.phonePackageProduct.label,
  validators: [],

  loader: {
    id: "phonePackageProduct",
    loader: fetchPhonePackageProducts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["canal_quantity"] && data["phone_package"] && data["phone_package"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => `${data["canal_quantity"]} canaux - ${data["phone_package"]["name"]}`,
  },
}

export const consumptionForm = {
  formKey: "phonePackageOrder",
  fields: [
    consumption
  ]
}

export const newForm = {
  formKey: "phonePackageOrder",
  fields: [
    phonePackageProductChoice
  ]
}


export const form = {
  formKey: "phonePackageOrder",
  fields: [
    phonePackageProduct
  ]
}
