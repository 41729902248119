import React from 'react';

import '../../css/grid.css';

import SampleBox from './Box/SampleBox';

/**
 * TODO
 */
class Grid extends React.Component {

  constructor(props) {
    super(props);

    this.sampleContainer = React.createRef();
    this.sampleBox = React.createRef();

    this.state = {
      width: 0,
      totalColumn: 8,
      margin: 0
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  getTotalColumn = () => {
    return this.state.totalColumn;
  }

  getBaseWidth = () => {
    return this.state.width;
  }

  getBaseMargin = () => {
    return this.state.margin;
  }

  onResize = () => {
    if(this.sampleBox) {
      const { width: widthContainer } = window.getComputedStyle(this.sampleContainer.current);
      const { marginLeft, width } = window.getComputedStyle(this.sampleBox.current);
      this.setState({
        width: Math.floor(Number(width.replace("px", ""))),
        totalColumn: Math.round(Number(widthContainer.replace("px", "")) / (Number(width.replace("px", "")) + 2 * Number(marginLeft.replace("px", "")))),
        margin: Math.floor(Number(marginLeft.replace("px", "")))
      })
    }
  }

  render() {
    return (
      <>
        <div ref={this.sampleContainer} className="d-flex flex-row flex-wrap">
          <SampleBox ref={this.sampleBox}/>
        </div>
        <div className={`d-flex flex-row flex-wrap justify-content-center grid-container${this.props.additionalGridClassName?" "+this.props.additionalGridClassName:""}`} style={{...this.props.additionalGridStyle}}>
          {React.Children.map(this.props.children, (child, i) => {
            if (React.isValidElement(child)) {
              return (
                <div>
                  {React.cloneElement(child, { baseSide: this.state.width, baseMargin: this.state.margin, totalColumn: this.state.totalColumn })}
                </div>
              )
            }
            return child;
          })}
        </div>
      </>
    );
  }
}

export default Grid;
