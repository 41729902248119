import strings from '../../Localization/Localization';

import { index, getFromId, editFromId, newItem } from '../../API/Dependencies';
import { form } from '../Form/dependencies';
import { form as searchForm } from '../Form/Search/dependencies';

import { dependencies as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.dependencies,

    id: "dependency",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_ADMIN'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => item.name
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.dependencies,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.dependency.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (data) => {},

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_ADMIN'],
        editable: ['ROLE_ADMIN']
      },
    }
  },
  new: {
    pageTitle: strings.dependency.new.title,

    api: newItem,
    empty: {},
    form: form,
    onSubmit: (dependencies) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
