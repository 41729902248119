import strings from '../../Localization/Localization';

import { fetchActiveTicketProduct } from '../../API/Products';

let product = {
  fieldKey: "product",
  label: strings.form.appointmentProduct.product.label,
  type: "dropdownEntity",

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.appointmentProduct.product.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  loader: {
    id: "products",
    loader: fetchActiveTicketProduct,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let quantity = {
  fieldKey: "quantity",
  type: "text",
  label: strings.form.appointmentProduct.quantity.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'regex',
      conf: {
        regex: /\d+/
      },
      invalidFeedback: strings.form.appointmentProduct.quantity.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

export const form = {
  formKey: "appointmentProducts",
  fields: [
    product,
    quantity
  ]
}
