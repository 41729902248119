import React from 'react';
import BasicWidget from './BasicWidget';

import { Button } from 'react-bootstrap';

import { connect } from 'react-redux';

import Chart from '../../Echart/Chart';

import { chartOption } from '../../../MetaData/Echart/centreon';

import ItemIndex from '../../ItemIndex/ItemIndex';

// import '../../../css/dashboard/widget_chart_pie.css';

/**
 * WidgetChartBar
 */
class WidgetSupervision extends BasicWidget {

  static defaultProps = {
    interval: 60
  }

  constructor(props) {
    super(props);

    this.chart = React.createRef();

    this.timer = null;

    this.requests = {};
    this.fetchCentreonServicesRequests = {};

    this.state = {
      viewportWidth: 1920,

      metric: {},

      centreonServices: [],
      centreonService: null
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();

    if(this.props.conf.centreonService) {
      this.initiateMetrics(true)
    }
    else {
      this.initiateFetchCentreonServices();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.parameterBag !== this.props.parameterBag || prevProps.conf !== this.props.conf) {
      this.initiateMetrics(true)
    }
    else {
      this.initiateMetrics()
    }

    if(this.chart.current && (prevProps.column !== this.props.column || prevProps.row !== this.props.row)) {
      this.chart.current.resizeChart();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);

    if(this.timer) {
      clearInterval(this.timer);
    }
  }

  onResize = () => {
    if(this.state.viewportWidth !== window.innerWidth) {
      this.setState({
        viewportWidth: window.innerWidth
      })
    }
  }

  requestCallback = (data) => {
    let metric = data.item;
    metric.metrics.forEach((item) => {
      switch (item.metric) {
        case "traffic_in":
          item.data = item.data.map((data) => {
            return data / 1000 / 1000
          });
          break;
        case "traffic_out":
          item.data = item.data.map((data) => {
            return data / 1000 / 1000
          });
          break;
        default:

      }
    });
    metric.times = metric.times.map((time) => {
      let date = new Date(time);
      return `${date.getDate()}/${date.getMonth()+1}/${(''+date.getFullYear()).slice(-2)} ${('0'+date.getHours()).slice(-2)}:${('0'+date.getMinutes()).slice(-2)}`;
    });
    this.setState({
      loading: false,
      metric: metric
    })
  }

  requestCallbackFailure = (msg) => {
    console.log(msg);
  }

  fetchCentreonServicesCallback = (data) => {
    this.setState({
      loading: false,
      centreonServices: data.items
    })
  }

  fetchCentreonServicesCallbackFailure = (msg) => {
    console.log(msg);
  }

  resetCentreonService = () => {
    if(this.timer) {
      clearInterval(this.timer);
    }
    this.setState({
      metric: {},
      centreonService: null
    })
  }

  initiateMetrics = (forceLoad = false) => {
    let centreonService = this.props.conf.centreonService;
    if(!centreonService) {
      centreonService = this.state.centreonService;
    }

    if(centreonService && this.props.conf.element) {
      if(!this.requests[this.props.conf.element]) {
        this.requests[this.props.conf.element] = {
          fetch: this.props.modalHandler.addVerificationWithCallback(this.props.widgetType.elementSettings[this.props.conf.element].fetchMetrics, this.requestCallback, this.requestCallbackFailure, true)
        }
      }

      if(this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setInterval(() => this.timeFetch(centreonService), this.props.interval * 1000);

      if(forceLoad) {
        this.timeFetch(centreonService)
      }
    };
  }

  timeFetch = (centreonService) => {
    if(this.requests[this.props.conf.element]) {
      this.setState({
        loading: true
      }, () => {
        this.requests[this.props.conf.element].fetch(centreonService["id"]/*, this.state.settings.timeframe*/)
      })
    }
  }

  initiateFetchCentreonServices = () => {
    if(this.props.conf.element) {
      if(!this.fetchCentreonServicesRequests[this.props.conf.element]) {
        this.fetchCentreonServicesRequests[this.props.conf.element] = {
          fetch: this.props.modalHandler.addVerificationWithCallback(this.props.widgetType.elementSettings[this.props.conf.element].fetchCentreonServices, this.fetchCentreonServicesCallback, this.fetchCentreonServicesCallbackFailure, true)
        }
      }

      if(this.fetchCentreonServicesRequests[this.props.conf.element]) {
        this.setState({
          loading: true
        }, () => {
          this.fetchCentreonServicesRequests[this.props.conf.element].fetch({}, 'main')
        })
      }
    };
  }

  getChartOption = () => {
    if(this.state.loading === false) {
      let fontSize = this.state.viewportWidth / 100 * 0.787;
      let additionalOption = {};
      if(this.props.conf.chartType) {
        additionalOption.chartType = this.props.conf.chartType
      }
      return chartOption(this.state.metric, this.props.theme, fontSize, additionalOption);
    }
    return null;
  }

  displayChart() {
    let option = this.getChartOption();
    if(option) {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <Chart ref={this.chart} option={option}/>
        </div>
      )
    }
  }

  displayEmpty() {
    if(this.state.loading === false && !this.props.conf.centreonService && !this.state.centreonService && this.state.centreonServices.length > 0) {
      return (
        <ItemIndex
          type="choice"
          choices={this.state.centreonServices}

          displaySearch={false}
          displayToolbar={false}
          displayPagination={false}

          onClickItem={(id, item) => {
            this.setState({
              centreonService: item
            }, () => {
              this.initiateMetrics(true);
            })
          }}
          itemDisplay={{
            line: {
              label: (item) => `${item["host"]["name"]} - ${item["name"]}`,
            }
          }}
        />
      )
    }
  }

  displayAdditionalTitleButtons() {
    if(this.state.centreonService) {
      return (
        <>
          <Button variant="my-secondary-noline" onClick={this.resetCentreonService}><i className="icon-cross"/></Button>
        </>
      )
    }
  }

  displayContent() {
    return (
      <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
        {this.displayTitle()}
        {this.displayArguments()}
        <div className="dashboard-content-container">
          {this.displayChart()}
          {this.displayEmpty()}
        </div>
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="dashboard-widget-container">
        {this.displayContent()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.persisted.theme.name
})

export default connect(mapStateToProps)(WidgetSupervision);
