import React from 'react';

import strings from '../../Localization/Localization';

import { Container, Col, Row, Form, Button, Navbar } from 'react-bootstrap';

import MainLayout from '../Layout/MainLayout';

import { connect } from 'react-redux';
import { addBreadcrumbAction } from '../../Store/Action/breadcrumb';

import { withModalHandler } from '../../HOC/ModalHandler';

import AsyncCounter from '../../Utils/Counter/AsyncCounter';

import { index as productSubFamilyIndex } from '../../API/ProductSubFamilies';
import {
  index as billIndex,
  getBillLinesFromId,
  newItem as newBill
} from '../../API/Bills';

import Loading from '../Layout/Loading';

import Table from '../Table/Table';
import TableHeader from '../Table/TableHeader';
import TableBody from '../Table/TableBody';
import TableTextHeader from '../Table/Header/TableTextHeader';
import TableRow from '../Table/Body/TableRow';

import StringCell from '../Form/Field/TableCell/StringCell';
import IntegerCell from '../Form/Field/TableCell/IntegerCell';
import FloatCell from '../Form/Field/TableCell/FloatCell';
import PriceCell from '../Form/Field/TableCell/PriceCell';
import DropdownCell from '../Form/Field/TableCell/DropdownCell';
import TimeCell from '../Form/Field/TableCell/TimeCell';
import SwitchCell from '../Form/Field/TableCell/SwitchCell';

import {
  bills as route
} from '../../MetaData/Route/routes';

// import BaseEntityTabs from '../EntityTabs/MyEntityTabs';

/**
 * CreditNoteCreator
 *
 * This component configure the entity tab component
 */
class CreditNoteCreator extends React.Component {

  constructor(props) {
    super(props);

    this.productSubFamilyIndex = this.props.modalHandler.addVerificationWithCallback(productSubFamilyIndex, this.postProductSubFamilyIndex, this.postProductSubFamilyIndexFailure);
    this.billIndex = this.props.modalHandler.addVerificationWithCallback(billIndex, this.postbillIndex, this.postbillIndexFailure);
    this.billDetail = this.props.modalHandler.addVerificationWithCallback(getBillLinesFromId, this.postBillLinesFromId, this.postBillLinesFromIdFailure);
    this.newBill = this.props.modalHandler.addVerificationWithCallback(newBill, this.postNewBill, this.postNewBillFailure);

    this.state = {
      loading: true,
      loadingGenerate: false,
      loadingGenerateError: false,

      productSubFamilies: null,
      productSubFamiliesError: false,
      bills: [],
      billSelection: "",
      billsDetails: [],
      billsError: false,
      freeLines: [],

      paymentSelection: "",
    }
  }

  componentDidMount() {
    this.props.dispatch(addBreadcrumbAction(window.location.pathname, strings.bill.creditNote.breadcrumb));

    this.counter = new AsyncCounter();

    let productSubFamilyBill = this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FREE_LINE');
    let billCreditNote = this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_CREDIT_NOTE');

    this.counter.reset();
    this.counter.setValue(((productSubFamilyBill)?1:0) + ((billCreditNote)?1:0));
    this.counter.subscribe(0, () => this.setState({ loading:false }));

    if(productSubFamilyBill) {
      this.productSubFamilyIndex(1, 0);
    }
    if(billCreditNote) {
      this.billIndex(1, 0, {
        client_id: this.props.match.params.clientId,
        created_at_min: '2023-01-01'
      }, 'ASC');
    }
    if(!productSubFamilyBill && !billCreditNote) {
      this.setState({ loading:false });
    }
  }

  postProductSubFamilyIndex = (data) => {
    this.setState({
      productSubFamilies: data.items,
    }, () => this.counter.decrement())
  }

  postProductSubFamilyIndexFailure = (msg) => {
    this.setState({
      productSubFamiliesError: true,
    }, () => this.counter.decrement());
  }

  postbillIndex = (data) => {
    this.setState({
      bills: data.items,
    }, () => this.counter.decrement())
  }

  postbillIndexFailure = (msg) => {
    this.setState({
      billsError: true,
    }, () => this.counter.decrement());
  }

  postBillLinesFromId = (data) => {
    this.setState({
      billSelection: "",
      billsDetails: [...this.state.billsDetails, data.item]
    })
  }

  postBillLinesFromIdFailure = (msg) => {
    this.setState({
      billsError: true,
    });
  }

  postNewBill = (data) => {
    //TODO dispatch flashbag

    //redirect to
    this.props.history.push(route.routes.index.createPath());
  }

  postNewBillFailure = (error) => {
    this.setState({
      loadingGenerate: false,
      loadingGenerateError: true
    })
  }

  //Free Line
  onChangeTitleFreeLine = (lineId, title) => {

    let newLine = Object.assign({}, this.state.freeLines[lineId], { title: title });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangePriceFreeLine = (lineId, price) => {
    let newLine = Object.assign({}, this.state.freeLines[lineId], { price: price });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangeQuantityFreeLine = (lineId, quantity) => {

    if(quantity < 0) {
      return;
    }

    let newLine = Object.assign({}, this.state.freeLines[lineId], { quantity: quantity });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangeDiscountFreeLine = (lineId, discount) => {

    let newLine = Object.assign({}, this.state.freeLines[lineId], { discount: discount });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangeDiscountUnitFreeLine = (lineId, discountUnit) => {

    let newLine = Object.assign({}, this.state.freeLines[lineId], { discount_unit: discountUnit });

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  onChangeProductSubFamilyFreeLine = (lineId, productSubFamilyId) => {

    let newLine;
    let productSubFamily = this.state.productSubFamilies.find(productSubFamily => productSubFamily["id"].toString() === productSubFamilyId);

    if(productSubFamily) {
      newLine = Object.assign({}, this.state.freeLines[lineId], { product_sub_family: productSubFamily, product_sub_family_name: undefined });
    }
    else {
      newLine = Object.assign({}, this.state.freeLines[lineId], { product_sub_family: undefined, product_sub_family_name: productSubFamilyId });
    }

    this.setState({ freeLines: this.state.freeLines.map((line, i) => i === lineId?newLine:line) });
  }

  addFreeLine = () => {

    this.setState({
      freeLines: [...this.state.freeLines, {
        discount_unit: '€'
      }]
    });
  }

  removeFreeLine = (lineId) => {
    this.setState({
      freeLines: [...this.state.freeLines.slice(0, lineId), ...this.state.freeLines.slice(lineId+1)]
    });
  }

  //Credit note Bills
  addBill = () => {
    let bill = this.state.bills.find((bill) => bill.number === this.state.billSelection);
    if(bill && bill.id) {
      this.billDetail(bill.id);
    }
  }

  onChangePriceBill = (billId, rowId, price) => {
    let bill = Object.assign({}, this.state.billsDetails.find((temp) => temp.id === billId));
    //check for maxomum amount
    let temp = price;
    if(typeof temp === 'string') {
      temp = temp.replace(',', '.');
      temp = temp.replaceAll(/\s/g, '');
    }
    if(bill["bill_lines"][rowId].total<temp) {
      price = bill["bill_lines"][rowId].total;
    }
    if(temp<0) {
      price = 0;
    }
    bill["bill_lines"] = [...bill["bill_lines"]];
    bill["bill_lines"][rowId] = Object.assign({}, bill["bill_lines"][rowId], {
      credit_note: price
    });
    this.setState({
      billsDetails: this.state.billsDetails.map((temp) => temp.id === billId?bill:temp)
    });
  }

  //bill generation
  generateBill = () => {

    this.setState({
      loadingGenerate: true
    }, () => {

      let new_bill_lines = [];

      if(this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_CREDIT_NOTE')) {
        this.state.billsDetails.forEach((bill, i) => {
          bill["bill_lines"].forEach((billLine, i) => {
            if(billLine["credit_note"]) {
              new_bill_lines.push({
                title: "Avoir : "+billLine["title"],//freeLine["title"],
                amount: (-1)*PriceCell.parseFinalValue(billLine["credit_note"]),
                quantity: 1,
                discount: 0,
                discount_unit: '€',
                origin: {
                  type: 'credit_note',
                  credit_note: {
                    id: billLine["id"]
                  }
                }
              });
            }
          });
        });
      }

      if(this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FREE_LINE')) {
        this.state.freeLines.forEach((freeLine, i) => {
          if(freeLine["product_sub_family"]
            && freeLine["title"]
            && !Number.isNaN(freeLine["price"])
            && (!Number.isNaN(freeLine["discount"]) || freeLine["discount"] === undefined)
            && !Number.isNaN(freeLine["quantity"])
          ) {
            new_bill_lines.push({
              title: freeLine["title"],
              amount: (-1)*PriceCell.parseFinalValue(freeLine["price"]),
              quantity: freeLine["quantity"]?freeLine["quantity"]:0,
              discount: freeLine["discount"]?freeLine["discount"]:0,
              discount_unit: freeLine["discount_unit"],
              origin: {
                type: 'product_sub_family',
                product_sub_family: {
                  id: freeLine["product_sub_family"]["id"]
                }
              }
            });
          }
        });
      }

      let additionalData = [];
      if(this.state.paymentSelection) {
        if(this.state.paymentSelection === strings.bill.creditNote.payment.simple) {
          additionalData[0] = 'simple';
        }
        else if (this.state.paymentSelection === strings.bill.creditNote.payment.refund) {
          additionalData[0] = 'refund';
        }
        else if (this.state.paymentSelection === strings.bill.creditNote.payment.deduct) {
          additionalData[0] = 'deduct';
        }
        else if (this.state.paymentSelection === strings.bill.creditNote.payment.intern) {
          additionalData[0] = 'intern';
        }
      }

      let data = {
        item: {
          additional_data: additionalData,
          bill_lines: new_bill_lines,
          client: {
            id: this.props.match.params.clientId
          }
        },
      }

      if(data.item.bill_lines.length > 0) {
        this.newBill(data);
      }
      else {
        this.setState({
          loadingGenerate: false
        })
      }
    })
  }

  displayFreeLines() {
    if(!this.state.loading && !this.state.productSubFamiliesError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FREE_LINE')) {
      return (
        <Container>
          <Row>
            <Col xs="auto"><h2>Ligne(s) Libre(s)</h2></Col>
            <Col xs="auto" className="d-flex align-items-center"><Button variant="my-validated" className="button-form-row" onClick={() => this.addFreeLine()}>Ajout</Button></Col>
          </Row>
          {this.state.freeLines.length > 0?<Table
            cellCollection={{
              string: StringCell,
              integer: IntegerCell,
              float: FloatCell,
              price: PriceCell,
              dropdown: DropdownCell,
              time: TimeCell,
              switch: SwitchCell
            }}>
            <colgroup>
              <col style={{width: '39%'}}/>{/*title*/}
              <col style={{width: '8%'}}/>{/*price*/}
              <col style={{width: '8%'}}/>{/*quantity*/}
              <col style={{width: '8%'}}/>{/*discount*/}
              <col style={{width: '6%'}}/>{/*discount_unit*/}
              <col style={{width: '16%'}}/>{/*productSubFamily*/}
              <col style={{width: '5%'}}/>{/*delete*/}
            </colgroup>
            <TableHeader>
              <TableTextHeader
                label="Titre"
                type="string"
                value={(item) => item["title"]?item["title"]:""}
                additionalConfiguration={{
                  onChange: (rowId, valueId, title) => this.onChangeTitleFreeLine(rowId, title),
                  placeholder: "Titre",
                  editable: true
                }}/>
              <TableTextHeader
                label="P.U. (€ H.T.)"
                type="price"
                value={(item) => item["price"]?item["price"]:0}
                additionalConfiguration={{
                  onChange: (rowId, valueId, title) => this.onChangePriceFreeLine(rowId, title),
                  editable: true
                }}/>
              <TableTextHeader
                type="integer"
                label="Quantité"
                value={(item) => item["quantity"]?item["quantity"]:0}
                additionalConfiguration={{
                  onChange: (rowId, valueId, quantity) => this.onChangeQuantityFreeLine(rowId, quantity),
                  editable: true
                }}/>
              <TableTextHeader
                type="float"
                label="Promotion"
                value={(item) => item["discount"]?item["discount"]:0}
                colSpan={2}
                additionalConfiguration={{
                  onChange: (rowId, valueId, quantity) => this.onChangeDiscountFreeLine(rowId, quantity),
                  editable: true
                }}/>
              <TableTextHeader
                value={(item, i) => {
                  return (
                    <Button variant="my-secondary-noline" onClick={(e) => this.onChangeDiscountUnitFreeLine(i, (item["discount_unit"] === '€')?'%':'€')}>{item["discount_unit"]}</Button>
                  )
                }}
                colSpan={0}/>
              <TableTextHeader
                value={(item) => item["product_sub_family"]?item["product_sub_family"]["name"]:(item["product_sub_family_name"]?item["product_sub_family_name"]:"")}
                type="dropdown"
                label="SF de produit"
                additionalConfiguration={{
                  onChange: (rowId, valueId, productSubFamilyId) => this.onChangeProductSubFamilyFreeLine(rowId, productSubFamilyId),
                  editable: true,
                  placeholder: "SF de produit",
                  fieldList: this.state.productSubFamilies.map((productSubFamily) => {
                    return {
                      label: `${productSubFamily["product_family"]["name"]} > ${productSubFamily["name"]}`,
                      value: productSubFamily.id
                    }
                  })
                }}/>
                <TableTextHeader
                  value={(item, i) => {
                    return (
                      <Button variant="my-secondary-noline" onClick={() => this.removeFreeLine(i)}><i className="icon-edit"/></Button>
                    )
                  }}
                  label=""/>

            </TableHeader>
            <TableBody>
              {this.state.freeLines.map((freeLine, i) => {
                return (
                  <TableRow
                    key={i}
                    data={freeLine}
                  />
                )
              })}
            </TableBody>
          </Table>:null}
        </Container>
      )
    }
    else if(!this.state.loading && this.state.productSubFamiliesError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_FREE_LINE')) {
      return (
        <Container>
          <div>{strings.bill.creditNote.productSubFamiliesError}</div>
        </Container>
      )
    }
  }

  displayPayment() {
    return (
      <Container>
        <datalist id="payment-datalist">
          <option>{strings.bill.creditNote.payment.simple}</option>
          <option>{strings.bill.creditNote.payment.refund}</option>
          <option>{strings.bill.creditNote.payment.deduct}</option>
          <option>{strings.bill.creditNote.payment.intern}</option>
        </datalist>
        <h2>Paiement</h2>
        <Row>
          <Col xs="auto">
            <Form.Control
              as="input"
              placeholder="Type de paiement"
              aria-label="Type de paiement"
              type="text"
              list="payment-datalist"
              value={this.state.paymentSelection}
              onChange={(event) => this.setState({paymentSelection: event.target.value})}
            />
          </Col>
        </Row>
      </Container>
    )
  }

  displayBills() {
    if(!this.state.loading && !this.state.billsError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_CREDIT_NOTE')) {
      return (
        <Container>
          <datalist id="bill-datalist">
            {this.state.bills.filter(
              (bill) => this.state.billsDetails.find((billDetail) => bill.id === billDetail.id) === undefined
            ).map((bill, i) => {
              return (
                <option key={i}>{bill.number}</option>
              )
            })}
          </datalist>
          <h2>Avoirs</h2>
          <Row>
            <Col xs="auto">
              <Form.Control
                as="input"
                placeholder="Numéro de facture"
                aria-label="Numéro de facture pour avoir"
                type="text"
                list="bill-datalist"
                value={this.state.billSelection}
                onChange={(event) => this.setState({billSelection: event.target.value})}
              />
            </Col>
            <Col xs="auto" className="d-flex align-items-center"><Button variant="my-validated" className="button-form-row" onClick={() => this.addBill()}>Ajout</Button></Col>
          </Row>
          {this.state.billsDetails.length > 0?
            this.state.billsDetails.map((bill) => {
              return (
                <div key={bill.number}>
                  <h4>{bill.number}</h4>
                  <Table
                    cellCollection={{
                      string: StringCell,
                      integer: IntegerCell,
                      float: FloatCell,
                      price: PriceCell,
                      dropdown: DropdownCell,
                      time: TimeCell,
                      switch: SwitchCell
                    }}>
                    <colgroup>
                      <col style={{width: '50%'}}/>{/*title*/}
                      <col style={{width: '30%'}}/>{/*type*/}
                      <col style={{width: '10%'}}/>{/*total*/}
                      <col style={{width: '10%'}}/>{/*price*/}
                    </colgroup>
                    <TableHeader>
                      <TableTextHeader
                        label="Titre"
                        type="string"
                        value={(item) => item["title"]?item["title"]:""}
                        additionalConfiguration={{
                          editable: false
                        }}/>
                      <TableTextHeader
                        label="Type"
                        type="string"
                        value={(item) => {
                          switch (item["origin"]["type"]) {
                            case 'contract_sub':
                              return strings.bill.creditNote.type.contractSub;
                            case 'contract_sub_l':
                              return strings.bill.creditNote.type.contractSubL;
                            case 'contract_sub_l_f_p':
                              return strings.bill.creditNote.type.contractSubLFP;
                            case 'contract_cdr_ff':
                              return strings.bill.creditNote.type.contractCdrFF;
                            case 'contract_cdr_fm':
                              return strings.bill.creditNote.type.contractCdrFM;
                            case 'contract_cdr_ii':
                              return strings.bill.creditNote.type.contractCdrII;
                            case 'contract_cdr_sn':
                              return strings.bill.creditNote.type.contractCdrSN;
                            case 'contract_cdr_sms':
                              return strings.bill.creditNote.type.contractCdrSMS;
                            case 'contract_cdr_data':
                              return strings.bill.creditNote.type.contractCdrData;
                            case 'order_sell_q':
                              return strings.bill.creditNote.type.orderSellQ;
                            case 'order_sell_p':
                              return strings.bill.creditNote.type.orderSellP;
                            case 'order_fas':
                              return strings.bill.creditNote.type.orderFas;
                            case 'product_sub_family':
                              return strings.bill.creditNote.type.productSubFamily;
                            case 'appointment_work':
                              return strings.bill.creditNote.type.appointmentWork;
                            case 'appointment_product':
                              return strings.bill.creditNote.type.appointmentProduct;
                            case 'appointment_trip':
                              return strings.bill.creditNote.type.appointmentTrip;
                            case 'credit_note':
                              return strings.bill.creditNote.type.creditNote;
                            default:
                              return item["origin"]["type"];
                          }
                        }}
                        additionalConfiguration={{
                          editable: false
                        }}/>
                      <TableTextHeader
                        label="Total HT"
                        type="price"
                        value={(item) => item["total"]?item["total"]:""}
                        additionalConfiguration={{
                          editable: false
                        }}/>
                        <TableTextHeader
                          label="Montant avoir"
                          type="price"
                          value={(item) => item["credit_note"]?item["credit_note"]:0}
                          additionalConfiguration={{
                            onChange: (rowId, valueId, price) => this.onChangePriceBill(bill.id, rowId, price),
                            editable: true
                          }}/>
                    </TableHeader>
                    <TableBody>
                      {bill["bill_lines"].map((billLine, i) => {
                        return (
                          <TableRow
                            key={i}
                            data={billLine}
                          />
                        )
                      })}
                    </TableBody>
                  </Table>
                </div>
              )
            })
          :null}
        </Container>
      )
    }
    else if(!this.state.loading && this.state.billsError && this.props.credentials.roles.includes('ROLE_ACCOUNTING_BILL_CREATE_CREDIT_NOTE')) {
      return (
        <Container>
          <div>{strings.bill.creditNote.billsError}</div>
        </Container>
      )
    }
  }

  displayButton() {
    return (
      <Navbar fixed="bottom" className="sub-footer">
        <Container>
          <Col xs={12} className="d-flex justify-content-center text-center small-text">
            <Button variant="my-validated" className="btn-sub-footer" onClick={this.generateBill}><i className="icon-document-edit"/> Générer la facture</Button>
          </Col>
        </Container>
      </Navbar>
    )
  }

  displayError() {
    if(this.state.loadingGenerateError) {
      return (
        <Container>
          <div>{strings.bill.creditNote.billGenerateError}</div>
        </Container>
      )
    }
  }

  displayLoading() {
    if(this.state.loading || this.state.loadingGenerate) {
      return (
        <Loading/>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <MainLayout>
        {this.displayLoading()}
        {this.displayError()}
        {this.displayPayment()}
        {this.displayBills()}
        {this.displayFreeLines()}
        {this.displayButton()}
      </MainLayout>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(withModalHandler(CreditNoteCreator));
