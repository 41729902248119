const specificPrice = {
  discount: {
    label: "Remise",
  },
  discountUnit: {
    label: "Type de remise",
  },
  product: {
    label: "produit",
  }
};

export default specificPrice;
