import strings from '../../Localization/Localization';

import { fetchPhonePrices } from '../../API/PhonePrices';
import { fetchPhonePriceLists } from '../../API/PhonePriceLists';

import { phonePriceList as phonePriceListRoutes } from '../Route/routes';

import { store } from '../../Store/configureStore';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.phonePackage.name.label,
  validators: []
}

let phonePriceList = {
  fieldKey: "phone_price_list",
  type: "dropdownEntity",
  label: strings.form.phonePackage.phonePriceList.label,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && value["id"] && (credentials.roles.includes('ROLE_CDR_PRICE_LIST'))) {
        return phonePriceListRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "priceLists",
    loader: fetchPhonePriceLists,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let includedTotalPhoneTime = {
  fieldKey: "included_total_phone_time",
  type: "text",
  label: strings.form.phonePackage.includedTotalPhoneTime.label,
  validators: []
}

let includedSpecificPhoneNumber = {
  fieldKey: "included_specific_phone_number",
  type: "text",
  label: strings.form.phonePackage.includedSpecificPhoneNumber.label,
  validators: []
}

let includedSpecificPhoneTime = {
  fieldKey: "included_specific_phone_time",
  type: "text",
  label: strings.form.phonePackage.includedSpecificPhoneTime.label,
  validators: []
}

let includedTotalDataQuantity = {
  fieldKey: "included_total_data_quantity",
  type: "text",
  label: strings.form.phonePackage.includedTotalDataQuantity.label,
  validators: []
}

let includedRedirection = {
  fieldKey: "included_redirection",
  type: "switch",
  label: strings.form.phonePackage.includedRedirection.label,
  validators: []
}

let includedMms = {
  fieldKey: "included_mms",
  type: "switch",
  label: strings.form.phonePackage.includedMms.label,
  validators: []
}

let includedSms = {
  fieldKey: "included_sms",
  type: "switch",
  label: strings.form.phonePackage.includedSms.label,
  validators: []
}

let slowDataAtHome = {
  fieldKey: "slow_data_at_home",
  type: "switch",
  label: strings.form.phonePackage.slowDataAtHome.label,
  validators: []
}

let includedPrices = {
  fieldKey: "included_prices",
  type: "array",
  label: strings.form.phonePackage.includedPrices.label,
  validators: [],

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phonePackage.includedPrices.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phonePackage.includedPrices.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phonePackage.includedPrices.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.phonePackage.includedPrice.label,

    ignoreValidation: true,
    validators: [],

    showEmpty: true,
    loader: {
      id: "prices",
      loader: fetchPhonePrices,
      loaderRequire: (value, getValues) => getValues() && getValues()["id"],
      loaderArguments: (value, getValues) => {
        return [{
          phone_package_id: getValues()["id"]
        }]
      },
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  }
}

export const safeguardForm = {
  formKey: "phonePackage",
  fields: [
    includedTotalPhoneTime,
    includedSpecificPhoneNumber,
    includedSpecificPhoneTime,
    includedTotalDataQuantity,
    includedRedirection,
    includedMms,
    includedSms,
    slowDataAtHome
  ]
}

export const safeguardPriceForm = {
  formKey: "phonePackage",
  fields: [
    includedPrices,
  ]
}

export const form = {
  formKey: "phonePackage",
  fields: [
    name,
    phonePriceList
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}

export const newForm = {
  formKey: "phonePackage",
  fields: [
    name,
    phonePriceList,
    includedTotalPhoneTime,
    includedSpecificPhoneNumber,
    includedSpecificPhoneTime,
    includedTotalDataQuantity,
    includedRedirection,
    includedMms,
    includedSms,
    slowDataAtHome
  ]
}
