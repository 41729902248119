import React from 'react';
import BasicFormField from './BasicFormField';

import FormFieldSelector from '../FormFieldSelector';

/**
 * FormFieldConfigurable
 *
 * This class handles HTML form field.
 *
 * The particular field is for configurable form field.
 *
 * Specific configuration attribute :
 *   - subFieldConf:
 *      - label
 *      - type
 *      - disabled
 *      - validators
 *      - valueAttribute
 */
class FormFieldConfigurable extends BasicFormField {

  constructor(props) {
    super(props);

    this.subFieldRef = React.createRef();
  }

  /**
   * @inheritdoc
   */
  componentDidMount() {}

  /**
   * @inheritdoc
   */
  getValue(attribute = true) {
    let value = super.getValue();
    if(attribute && this.props.subFieldConf.valueAttribute) {
      value = value[this.props.subFieldConf.valueAttribute];
    }
    return value;
  }

  /**
   * @inheritdoc
   */
  getFinalValue(attribute = true) {
    let finalValue = super.getFinalValue();
    if(typeof finalValue === 'object') {
      finalValue = Object.assign({}, finalValue);
    }
    if(attribute && this.props.subFieldConf.valueAttribute) {
      finalValue[this.props.subFieldConf.valueAttribute] = this.subFieldRef.getFinalValue();
    }
    else {
      finalValue = this.subFieldRef.getFinalValue()
    }
    return finalValue;
  }

  /**
   * @inheritdoc
   */
  needValidation() {
    return this.subFieldRef.needValidation();
  }

  /**
   * @inheritdoc
   */
  isValid = (target = null) => {
    return this.subFieldRef.isValid();
  }

  /**
   * @inheritdoc
   */
  startValidation = (value = null) => {
    this.subFieldRef.startValidation(value);
  }

  /**
   * @inheritdoc
   */
  onChange(value, ignoreValidation = false) {
    let newValue = this.getValue(false);

    if(typeof newValue === 'object' && this.props.subFieldConf.valueAttribute) {
      newValue = Object.assign({}, newValue);
      newValue[this.props.subFieldConf.valueAttribute] = value;
    }
    else {
      newValue = value;
    }
    this.props.onChange(newValue);
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <FormFieldSelector
        forwardedRef={(ref) => this.subFieldRef = ref}
        {...this.props.subField(this.getValue(), this.props.getValues, this.props.getExtraValues)}

        fieldVariant={this.props.fieldVariant}

        label={this.props.subFieldConf.label(this.getValue(false), this.props.getValues, this.props.getExtraValues)}
        type={this.props.subFieldConf.type(this.getValue(false), this.props.getValues, this.props.getExtraValues)}
        formKey={this.props.formKey}
        fieldKey={this.props.fieldKey}

        disabled={this.props.disabled || this.props.subFieldConf.disabled(this.getValue(false), this.props.getValues, this.props.getExtraValues)}

        value={this.getValue()}
        getValues={this.props.getValues}
        getExtraValues={this.props.getExtraValues}
        onChange={(value) => this.onChange(value)}

        ignoreValidation={this.props.ignoreValidation}
        validators={this.props.subFieldConf.validators(this.getValue(false))}
        validationCallback={() => this.props.validationCallback()}
        submitted={this.props.submitted}
        submitting={this.props.submitting}

        middlePart={this.props.middlePart}
      />
    );
  }
}

export default FormFieldConfigurable;
