import FormFieldText from './FormFieldText';

import '../../../css/form.css';

/**
 * FormFieldTextUpperCase
 *
 * This class handles HTML form field.
 *
 * The particular field is for text uppercase. It will change all characters
 * used to uppercase
 *
 * Specific configuration attribute : none
 */
class FormFieldTextUpperCase extends FormFieldText {

  /**
   * Send new value to parent element
   */
  onChange(value) {
    //check the value and change it to uppercase
    if(value !== undefined && value !== null) {
      value = value.toUpperCase();
    }
    super.onChange.call(this, value);
  }
}

export default FormFieldTextUpperCase;
