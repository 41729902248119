import {
  index as baseIndex,
  request,
 } from './Base';

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all code nafs to serve in a dropdown form choice
 */
export function fetchErrorLines(distinct) {
  let search = {}
  if(distinct === true) {
    search.distinct = true;
  }
  return baseIndex('flex_api_file_error_lines', 1, 0, search);
}

/**
 * start to process error line
 */
export function processErrorLines() {
  return request('/flex_file_process_error_lines');
}
