class BaseAssert {

  defaultConf = { };

  value = null;

  values = null;

  extraValues = null;

  submitted = null;

  configure(conf = null) {
    conf === null || conf === undefined ? this.conf = this.defaultConf : this.conf = conf;
  }

  setValue(value) {
    this.value = value;
  }

  getValue = () => {
    if(this.value && this.conf.subField !== undefined) {
      return this.value[this.conf.subField];
    }
    return this.value;
  }

  setValues(values) {
    this.values = values;
  }

  setExtraValues(extraValues) {
    this.extraValues = extraValues;
  }

  getValues = () => {
    if(this.values instanceof Function) {
      return this.values();
    }
    else {
      return this.values;
    }
  }

  setExtraData(extraData) {
    this.extraData = extraData;
  }

  getExtraData = () => {
    return this.extraData;
  }

  setSubmitted(submitted) {
    this.submitted = submitted;
  }

  getSubmitted = () => {
    return this.submitted;
  }

  assert() {
    console.warn("Must implement assert function of Assert");
  }

  isDelayed() {
    return false;
  }
}

export default BaseAssert;
