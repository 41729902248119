import React from 'react';
import BasicFormField from './BasicFormField';

import { Form } from 'react-bootstrap';

/**
 * FormFieldHidden
 *
 * This class handles HTML form field.
 *
 * The particular field is hidden.
 */
class FormFieldHidden extends BasicFormField {
  
  /**
   * @inheritdoc
   */
  getValue() {
    return '';
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    if(typeof this.props.defaultValue === 'function') {
      return this.props.defaultValue(this.props.getValues, this.props.getExtraValues);
    }
    return this.props.defaultValue;
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Form.Control
        type="hidden"
        disabled={this.props.disabled || this.props.submitting}
        value={this.getValue()}
      />
    );
  }
}

export default FormFieldHidden;
