const specialConditionOfSaleArgumentCollection = {
  key: {
    label: "Clé de l'argument",
  },
  value: {
    label: "Valeur de l'argument",
  },
  arguments: {
    label: "Arguments",
    allowMoreField: {
      addLabel: "Ajouter un argument",
      removeLabel: "Enlever un argument",
      removingLabel: "Supprimer"
    }
  },
};

export default specialConditionOfSaleArgumentCollection;
