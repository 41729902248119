import React from 'react';

import TableCell from '../../../Table/Body/TableCell';

/**
 * FloatCell
 */
class FloatCell extends TableCell {

  getPlaceholder() {
    if(this.props.additionalConfiguration.placeholder) {
      return this.props.additionalConfiguration.placeholder;
    }
    return null;
  }

  getValue() {
    return this.props.value;
  }

  onChange(event) {
    this.props.additionalConfiguration.onChange(this.props._rowId, this.props._valueId, event.target.value, this.props.data);
  }

  displayValue() {
    if(this.props.additionalConfiguration.editable) {
      return <input type="text" className="cell-form cell-form-float" placeholder={this.getPlaceholder()} value={this.getValue()} onChange={(e) => this.onChange(e)}/>
    }
    else {
      return `${Intl.NumberFormat('fr-FR', {minimumFractionDigits: 2}).format(this.props.value)}`;
    }
  }

  displayInnerCell() {
    return <div className="cell-container cell-container-float">{this.displayValue()}</div>;
  }
}

export default FloatCell;
