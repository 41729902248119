import React from 'react';
import BasicFormField from './BasicFormField';

import { Button } from 'react-bootstrap';

import FormFieldSelector from '../FormFieldSelector';

import LengthMinAssert from '../../../Form/Assert/LengthMinAssert';
import LengthMaxAssert from '../../../Form/Assert/LengthMaxAssert';

/**
 * FormFieldArray
 *
 * This class handles HTML form field.
 *
 * The particular field is for array. It will display a list of fields using
 * one of the other type of field.
 *
 * Specific configuration attribute :
 * - defaultNumberOfField : the default number of field displayed
 * - allowMoreField : whether a button to add field should be displayed or not
 * - subField : an object representing the sub form :
 *   - type: the type of the subfield,
 *   - other specific field field
 */
class FormFieldArray extends BasicFormField {

  static defaultProps = {
    filterValues: () => true
  }

  constructor(props) {
    super(props);

    this.subFieldRefs = [];

    //state of the component, including :
    //removing : a boolean variable indicating if the the list is in
    // remove mode
    //toRemove : the list of item to remove when in remove mode
    this.state = Object.assign(this.state, {
      removing: false,
      toRemove: []
    });
  }

  /**
   * @return the value of this field
   */
  getValue(){
    if(this.props.value) {
      return this.props.value
    }
    return [];
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    let value = [];
    this.subFieldRefs.filter((item) => item.current !== null).forEach((item, i) => {
      if(item.current.getFinalValue() !== null && item.current.getFinalValue() !== undefined) value.push(item.current.getFinalValue());
    });
    return value;
  }

  /**
   * Allow to get the values depending of the scope (depth) inside the form
   */
  getValues = (depth = -1) => {
    if(depth === -1) {
      return this.props.getValues();
    }
    else if(depth === 0) {
      return this.getValue();
    }
    else {
      return this.props.getValues(depth-1);
    }
  }

  getDefaultNumberOfField() {
    if(typeof this.props.defaultNumberOfField === 'function') {
      return this.props.defaultNumberOfField(this.props.value, this.getValues, this.props.getExtraValues);
    }
    else {
      return this.props.defaultNumberOfField;
    }
  }

  /**
   * @inheritdoc
   */
  needValidation() {
    return super.needValidation() || this.subFieldRefs.filter((item) => item && item.current).some((item) => item.current.needValidation());
  }

  /**
   * @inheritdoc
   */
  isValid = () => {
    return (Object.values(this.state.valid).every((value) => value === true)?true:false)
      && (this.subFieldRefs.filter((item) => item && item.current).every((item) => item.current.isValid()));
  }

  /**
   * @inheritdoc
   */
  isInvalid = () => {
    return Object.values(this.state.valid).some((value) => value === false)?true:false
     || this.subFieldRefs.filter((item) => item && item.current).some((item) => item.current.isInvalid());
  }

  /**
   * Send new value to parent element
   *
   * @param key the key of the subfield
   * @param value the new value of the subfield
   */
  onChange = (key, value) => {
    let newValue = this.getValue();
    //set the value that changed
    newValue[key] = value;
    super.onChange(newValue, true);
  }

  resetArray = () => {
    super.onChange([]);
  }

  triggerEvents = (key) => {
    if(this.props.subField.events) {
      this.props.subField.events.forEach((event) => {
        if(event.event === 'onChange'
          && (event.target === key
          || event.target === "")) {
          this.subFieldRefs.forEach((item, i) => {
            event.function(this.subFieldRefs[i].current);
          });
        }
      });
    }
  }

  getSpecificAsserts() {
    return {
      lengthMin: LengthMinAssert,
      lengthMax: LengthMaxAssert,
    }
  }

  getExtraValidation() {
    return this.subFieldRefs.filter((item) => item.current !== null && item.current.needValidation()).map((item) => item.current);
  }

  displayLabel() {
    if(this.props.label !== undefined && this.props.label !== null && this.props.label !== '') {
      return (
        <div className="container-form-field col-md-12">
          {this.props.label}
        </div>
      )
    }
    return null;
  }

  /**
   * Display all sub fields.
   *
   * if the field is in remove mode, all subfields will be selectable, then when
   * the user click again on the remove button all subfields selected will be
   * removed.
   */
  displaySubFields() {
    //first check the number of array to display
    let number = -1;
    //take the greater value between the size of the values and the default
    //number of subfield
    let defaultNumberOfField = this.getDefaultNumberOfField();
    if(!this.props.disabled && (this.props.value === undefined || defaultNumberOfField > this.props.value.length)) {
      number = defaultNumberOfField;
    }
    else{
      number = this.getValue().length;
    }
    //display the correct the number of subfields
    return (
      <>
        {Array.from({length: number}, (item, i) => i)
          .filter((key) => {
            return this.props.filterValues(key, this.getValue()[key], this.getValues, this.props.getExtraValues);
          })
          .map((key) => {
            //if the current mode is removing, display the removable subfield
            //if the current mode is normal then display the subfield
            if(this.state.removing) {
              return (
                <div key={`${this.props.formKey}-${this.props.fieldKey}-removable-${key}`} className="form-array-field-container">
                  {this.displayRemovableSubField(key)}
                </div>
              )
            }
            else {
              return (
                <div key={`${this.props.formKey}-${this.props.fieldKey}-removable-${key}`} className="form-array-field-container">
                  {this.displaySubField(key)}
                </div>
              )
            }
          }
        )}
      </>
    )
  }

  /**
   * default callback of a click on a removable subfield
   *
   * @param event the click event
   * @param key the key of the subfield
   */
  onClickRemoveItem = (event, key) => {
    event.preventDefault();
    event.stopPropagation();

    let toRemove = [...this.state.toRemove];
    //check if the subfield is already inside the list of subfield to remove
    //if it is, delete it from this list so it won't be removed
    //if not, put it on the remove list
    if(toRemove.includes(key)) {
      toRemove = toRemove.filter(item => item !== key);
    }
    else {
      toRemove.push(key);
    }
    //apply change to the remove list
    this.setState({
      toRemove: toRemove
    })
  }

  /**
   * display a field in remove mode
   *
   * @param key the key of the subfield
   */
  displayRemovableSubField(key) {
    //check if the subfield is in the remove list
    let removing = this.state.toRemove.some((item) => item === key);
    //display the removable field, it will display the field but place a button
    //on the field to trigger the remove list addition/deletion
    //it will also be displayed differently if it is in the remove list
    return (
      <div
        key={`${this.props.formKey}-${this.props.fieldKey}-removable-${key}`}
        className="d-flex col-12 form-array-field-removing">
        {this.displaySubField(key)}
        <Button
          onClick={(event) => this.onClickRemoveItem(event, key)}
          bsPrefix="form-array"
          variant={`${removing?'removing':''}`}>
          {removing?<i className="icon-cross"></i>:null}
        </Button>
      </div>
    )
  }

  subFieldRefCallback = (key, ref) => {
    if(this.subFieldRefs[key] === undefined) {
      this.subFieldRefs[key] = React.createRef();
    }
    this.subFieldRefs[key].current = ref;
  }

  /**
   * display the actual subfield using a field selector
   *
   * @param key the key of the subfield
   */
  displaySubField(key) {
    //create label
    let label = "";
    if(typeof this.props.subField.label === 'function') {
      label = this.props.subField.label(key, this.props.value, this.getValues, this.props.getExtraValues);
    }
    else {
      label = this.props.subField.label;
    }
    label = label.replace('#key#', key+1);
    //check whether it should be shown or not
    let disabled = this.props.disabled !== undefined && ((typeof this.props.disabled === 'function' && this.props.disabled(this.getValues, this.props.getExtraValues, key)) || this.props.disabled === true);
    if(!(disabled && typeof this.props.showOnDisabled === 'function' && !this.props.showOnDisabled(this.getValues, this.props.getExtraValues, key))) {
      //display the field selector
      return (
        <FormFieldSelector
          key={`${this.props.formKey}-${this.props.fieldKey}-${key}`}
          forwardedRef={(ref) => this.subFieldRefCallback(key, ref)}

          fieldVariant={this.props.fieldVariant}

          {...this.props.subField}
          label={label}
          formKey={`${this.props.formKey}-${key}`}
          fieldKey={`${this.props.fieldKey}-${key}`}

          disabled={this.props.disabled}

          value={this.getValue()[key]}
          getValues={this.getValues}
          getExtraValues={this.props.getExtraValues}
          onChange={(value) => this.onChange(key, value)}

          validationCallback={() => this.subFieldValidationCallback()}
          submitted={this.props.submitted}
          submitting={this.props.submitting}

          middlePart={this.processMiddlePart(key)}
        />
      )
    }
    return null;
  }

  processMiddlePart(key) {
    if(this.props.middlePart) {
      switch (this.props.middlePart.type) {
        case "form":
          let middlePart = {
            type: "form",
            placeholder: this.props.middlePart.placeholder
          }

          if(Array.isArray(this.props.middlePart.value) && this.props.middlePart.value[key]) {
            middlePart.value = this.props.middlePart.value[key];
          }
          else {
            middlePart.value = "";
          }

          if(Array.isArray(this.props.middlePart.value)) {
            middlePart.onChange = (value) => {
              let newValue = [...this.props.middlePart.value];
              newValue[key] = value;
              this.props.middlePart.onChange(newValue);
            }
          }
          else {
            middlePart.onChange = (value) => {
              let newValue = [];
              newValue[key] = value;
              this.props.middlePart.onChange(newValue);
            }
          }

          return middlePart;
        case "text":
        default:
          return this.props.middlePart;
      }
    }
    return null;
  }

  displayValidators() {
    return (
      <div className="form-array-field-container">
        <div className={`${(this.isInvalid())?"is-invalid":""} ${(this.isValid())?"is-valid":""}`}/>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </div>
    )
  }

  /**
   * callback for the remove button
   * allow to trigger the remove mode
   * when entering the remove mode, all subfields will become selectable
   * when getting out of remove mode, all selected subfields will be removed
   * from the subfields
   *
   * @param event the click event
   */
  onClickRemove = (event) => {
    //check the mode
    if(this.state.removing && this.props.value !== undefined) {
      //prepare the change
      let array = [...this.props.value];
      //remove the subfields from the field if they are in the remove list
      array = array.filter((item, i) => !this.state.toRemove.includes(i));
      //send the change to parent component
      this.props.onChange(array);
    }
    //trigger the modification of the mode
    //reset the remove list
    this.setState({
      removing: !this.state.removing,
      toRemove: []
    })
  }

  /**
   * callback for the add button
   * clicking this will add an empty subfield
   *
   * @param event the click event
   */
  onClickAdd = (event) => {
    let array = []
    //check if the value is undefined or not
    if(this.props.value === undefined) {
      //if the value is undefined, define it here using the default number of field
      array = Array.from({length: this.getDefaultNumberOfField()+1}, (item) => undefined);
    }
    //else add an undefined object as a new subfield
    else {
      array = [...this.props.value];
      array.push(undefined);
    }
    //send the change to parent component
    this.props.onChange(array);
  }

  /**
   * display the buttons that will aloow to add/remove item from the field
   */
  displayButtons() {
    //check if the buttons should be displayed or not
    if(!this.props.disabled && this.props.allowMoreField) {
      return (
        <div id="container-form-field-array-button" className={`d-flex container-form-row justify-content-center`}>
          <Button
            className="col-6 col-md-3 button-form-row"
            variant="my-secondary-outline"
            onClick={this.onClickRemove}>
            {(this.state.removing)?this.props.allowMoreFieldRemovingLabel:this.props.allowMoreFieldRemoveLabel}
          </Button>
          <Button
            className="col-6 col-md-3 button-form-row"
            variant="my-secondary-outline"
            onClick={this.onClickAdd}>
            {this.props.allowMoreFieldAddLabel}
          </Button>
        </div>
      );
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayLabel()}
        {this.displaySubFields()}
        {this.displayValidators()}
        {this.displayButtons()}
      </>
    );
  }
}

export default FormFieldArray;
