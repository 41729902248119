import React from 'react';

/**
 * HoverText
 */
class HoverText extends React.Component {

  static defaultProps = {
    text: "",
    hoverText: ""
  }

  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    }
  }

  computeText = (text) => {
    if(typeof text === 'function') {
      return text();
    }
    else {
      return text;
    }
  }

  displayText = () => {
    if(this.state.hover) {
      return (
        <>{this.computeText(this.props.hoverText)}</>
      )
    }
    else {
      return (
        <>{this.computeText(this.props.text)}</>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div style={{height: "100%", width: "100%"}} onMouseEnter={() => this.setState({hover: true})} onMouseLeave={() => this.setState({hover: false})}>
        {this.displayText()}
      </div>
    );
  }
}

export default HoverText;
