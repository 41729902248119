import {
  index as baseIndex
 } from './Base';

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all code nafs to serve in a dropdown form choice
 */
export function fetchCentreonHost(search) {
  return baseIndex('centreon_hosts', 1, 0, search);
}
