import React from 'react';
import { Col } from 'react-bootstrap';

import BasicWidget from './BasicWidget';

import { connect } from 'react-redux';

import { ParameterTypes } from '../../../Dashboard/Constant';

import '../../../css/dashboard/widget_title.css';

import MyForm from '../../Form/MyForm';

/**
 * WidgetParameter
 */
class WidgetParameter extends BasicWidget {

  static defaultProps = {}

  static evaluateParameterName(widget, parameter) {
    return `${widget.id}_${parameter.id}`
  }

  getValues() {
    return this.props.parameterBag;
  }

  onChange = (key, value) => {
    this.props.modifyParameterBag(key, value);
  }

  defaultDisplay() {
    let fields = this.props.conf.parameters.filter(parameter => {
      if(parameter.title && Object.values(ParameterTypes).includes(parameter.subType)) {
        return true;
      }
      return false;
    }).map((parameter, i) => {

      let fieldKey = WidgetParameter.evaluateParameterName(this.props, parameter);
      let type = "hidden";
      let additionalAttributes = {};


      switch (parameter.subType) {
        case ParameterTypes.STRING:
          type = "text";
          break;
        case ParameterTypes.DATETIME:
          type = "datetime";
          break;
        case ParameterTypes.PARTNER:
          type = "dropdownEntity";
          additionalAttributes.loader = {
            id: "partner",
            loader: this.props.widgetType.additionalData.partner.index,
            loaderRequire: () => true,
            loaderArguments: () => [],
          };
          additionalAttributes.adapter = {
            requirement: (data) => data["id"] && data["name"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["name"],
          }
          break;
        case ParameterTypes.PLACE:
          type = "dropdownEntity";
          let loaderArguments = {
            active: 1
          };
          this.props.conf.parameters
            .filter(subParameter => subParameter.subType === ParameterTypes.PARTNER)
            .some(subParameter => {
              let subValue = this.getValues()[WidgetParameter.evaluateParameterName(this.props, subParameter)]
              if(subValue && subValue["id"]) {
                loaderArguments["partner_id"] = subValue["id"];
                additionalAttributes.events = [
                  {
                    id: "reload",
                    event: "onChange",
                    target: WidgetParameter.evaluateParameterName(this.props, subParameter),
                    function: (component) => component && component.reloadData()
                  }
                ]
                return true;
              }
              return false;
            })
          additionalAttributes.loader = {
            id: "place",
            loader: this.props.widgetType.additionalData.place.index,
            loaderRequire: () => true,
            loaderArguments: () => {
              return [loaderArguments]
            },
          };
          additionalAttributes.adapter = {
            requirement: (data) => data["id"] && data["name"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["name"],
          }
          break;
        case ParameterTypes.USER:
          type = "dropdownEntity";
          additionalAttributes.loader = {
            id: "user",
            loader: this.props.widgetType.additionalData.user.index,
            loaderRequire: () => true,
            loaderArguments: () => [],
          };
          additionalAttributes.adapter = {
            requirement: (data) => data["id"] && data["displayname"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["displayname"],
          }
          break;
        default:

      }

      return {
        fieldKey: fieldKey,
        label: parameter.title,
        type: type,
        showOnDisabled: () => false,
        validators: [],

        showEmpty: true,

        ...additionalAttributes
      }
    })
    return (
      <Col>
        <MyForm
          formKey={`parameters`}
          fields={fields}
          extraValues={{
            credentials: () => this.props.credentials
          }}
          values={this.getValues()}
          onChangeField={this.onChange}
          displaySubmitButton={false}

          ignoreValidation={true}

          formSizeMd={12}
          fieldColMd={12}

          // fieldVariant="dashboard"
        />
      </Col>
    )
  }

  displayParameters() {
    switch (this.props.conf.display) {
      case "display1":
      default:
        return this.defaultDisplay();
    }
  }

  displayContent() {
    return (
      <div className="d-flex flex-column" style={{height: "100%", width: "100%"}}>
        {this.displayTitle()}
        {this.displayArguments()}
        <div className="dashboard-content-container">
          {this.displayParameters()}
        </div>
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="dashboard-widget-container">
        {this.displayContent()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(WidgetParameter);
