import {
  ADD_PRODUCT
} from '../Action/cartProduct'

const initialState = {
  products: {},
}

export default function reduce(state = initialState, action){
  let newState = Object.assign({}, state);

  switch (action.type) {
    case ADD_PRODUCT:
      let products = Object.assign({}, state.products);
      products[action.product.id] = action.product;
      newState.products = products;
      break;
    default:
      newState = state;
  }
  return newState;
}
