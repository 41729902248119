import React from 'react';

import { useRef } from 'react';

import { ItemTypes } from '../../../DnD/Constant';
import { useDrop } from 'react-dnd';

export default function DashboardStorageSlot({ storeBox, children }) {

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_GRID_WIDGET,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragPosition = item.position;

      // Time to actually perform the action
      storeBox(item.id, dragPosition);
    },
  });

  drop(ref);

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}>
      {children}
    </div>
  )
}
