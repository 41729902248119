const phoneZone = {
  label: {
    label: "Label",
  },
  aliases: {
    label: "Alias",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  prefixes: {
    label: "Préfixes",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  prefix: {
    label: "Préfix"
  },
  groups: {
    label: "Groupes",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  group: {
    label: "Groupe"
  },
  priceSources: {
    label: "",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  priceSource: {
    label: "Prix"
  },
  priceDestinations: {
    label: "",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  priceDestination: {
    label: "Prix"
  },
};

export default phoneZone;
