import {
  RESET_WIDGETS,
  SAVE_WIDGETS
} from '../Action/dashboard';

const initialState = {
  widgets: [
    // {
    //   id: 'default-1',
    //   positions: {
    //     0: {
    //       8: null
    //     },
    //     1: {
    //       8: null
    //     },
    //     2: {
    //       8: null
    //     }
    //   }
    // }
  ],
}

export default function reduce(state = initialState, action) {

  let newState;
  switch (action.type) {
    case RESET_WIDGETS:
      newState = initialState
      break;
    case SAVE_WIDGETS:
      newState = Object.assign({}, state);
      newState.widgets = [...action.widgets];
      break;
    default:
      newState = state;
  }

  return newState;
}
