import React from 'react';

import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

// let body = {
//   fieldKey: "body",
//   type: "textarea",
//   label: strings.form.comment.body.label,
//   validators: []
// }

let body = {
  fieldKey: "body",
  type: "configurableField",
  label: "",
  validators: [],

  subField: () => {},
  subFieldConf: {
    label: (value, getValues) => {
      return <>{getValues(0)["hidden"]?<><i className="icon-masquercomment"></i>&nbsp;&nbsp;</>:''}{(!getValues(0)["hidden"] && getValues(0)["send_notification"])?<><i className="icon-mail"></i>&nbsp;&nbsp;</>:''}{strings.form.comment.body.label}</>
    },
    type: () => 'textarea',
    disabled: () => false,
    validators: () => [
      {
        id: 'assertEmpty',
        type: 'lengthMin',
        conf: {
          min:1
        },
        invalidFeedback: strings.form.comment.body.invalid.assertEmpty,
        defaultValue: false
      },
    ],
  }
}

let hidden = {
  fieldKey: "hidden",
  type: "switch",
  label: strings.form.comment.hidden.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    switch (getExtraValues().type) {
      case 'technical':
        return !(credentials.roles.includes('ROLE_TECHNICAL_TICKET_COMMENT_INNER_CREATE') && credentials.roles.includes('ROLE_TECHNICAL_TICKET_COMMENT_CREATE'));
      case 'commercial':
        return !(credentials.roles.includes('ROLE_COMMERCIAL_TICKET_COMMENT_INNER_CREATE') && credentials.roles.includes('ROLE_COMMERCIAL_TICKET_COMMENT_CREATE'));
      case 'administrative':
        return !(credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_COMMENT_INNER_CREATE') && credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_COMMENT_CREATE'));
      default:
    }
    return true;
  },
  showOnDisabled: () => false,
  defaultValue: false,
  validators: []
}

let sendNotification = {
  fieldKey: "send_notification",
  type: "switch",
  label: strings.form.comment.sendNotification.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return getValues(0)["hidden"] || !(credentials.roles.includes('ROLE_NYUKOM'));
  },
  showOnDisabled: () => false,
  defaultValue: false,
  validators: []
}

export const form = {
  formKey: "comment",
  fields: [
    body
  ]
}

export const newForm = {
  formKey: "newComment",
  fields: [
    body,
    hidden,
    sendNotification
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
