import strings from '../../../Localization/Localization';

import { Colors, FontFamily } from '../../../Echart/Constant';

const formatter = Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 4 });

export const networkChartOption = (metric, theme = 'dark', fontSize = 15, additionalOption = {}) => {

  let trafficOut = metric.metrics.find(m => m.metric === 'traffic_out');
  let trafficIn = metric.metrics.find(m => m.metric === 'traffic_in');

  if(!trafficOut || !trafficIn) {
    return;
  }

  let textColor = null;
  let inversedTextColor = null;
  let lineColor = null;
  let backgroundColorSave = null;

  switch (theme) {
    case 'light':
      textColor = 'black'
      inversedTextColor = 'white'
      lineColor = 'grey'
      backgroundColorSave = 'white'
      break;
    case 'dark':
    case 'dark_colorblind':
    default:
      textColor = 'lightgrey'
      inversedTextColor = 'black'
      lineColor = 'grey'
      backgroundColorSave = '#383838'
  }

  console.log(metric);

  switch (additionalOption.chartType) {
    case "bar":
      return {
        color: Colors,
        textStyle: {
          fontFamily: FontFamily,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          formatter: (params) => {
            let result = ''
            result += 'Date : ' + params[0].axisValueLabel + '<br/>'
            result += params
              .map((param) => {
                return param.marker + ' ' + param.seriesName + ' : ' + formatter.format(param.value)
              })
              .join('<br/>')
            return result
          }
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 90,
            end: 100,
            xAxisIndex: [0, 1],
            top: '83%',
            height: '8%'
          },
          {
            type: 'inside',
            realtime: true,
            start: 90,
            end: 100,
            xAxisIndex: [0, 1]
          }
        ],
        axisPointer: {
          link: [
            {
              xAxisIndex: 'all'
            }
          ]
        },
        legend: {
          textStyle: {
            color: textColor
          }
        },
        toolbox: {
          feature: {
            restore: {},
            saveAsImage: {
              backgroundColor: backgroundColorSave
            }
          }
        },
        grid: [
          {
            top: '10%',
            left: '15%',
            right: '10%',
            height: '26%'
          },
          {
            top: '45%',
            left: '15%',
            right: '10%',
            height: '26%'
          }
        ],
        xAxis: [
          {
            type: 'category',
            data: metric.times,
            axisLabel: {
              fontSize: fontSize,
            },
            axisPointer: {
              label:{
                color: inversedTextColor
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: textColor
              }
            },
          },
          {
            gridIndex: 1,
            type: 'category',
            data: metric.times,
            position: 'top',
            axisLabel: {
              show: false
            },
            axisPointer: {
              label:{
                color: inversedTextColor
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: textColor
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: `${strings.echart.centreonNetwork.chartType.bar.yAxis._0.title} (${strings.echart.centreonNetwork.chartType.bar.yAxis._0.unit})`,
            position: 'left',
            axisLine: {
              show: true,
              lineStyle: {
                color: Colors[0]
              }
            },
            splitNumber: 3,
            // axisTick: {
            //   show: false,
            //   interval: 5
            // },
            axisLabel: {
              fontSize: fontSize,
            },
            // nameGap: this.state.viewportWidth / 100 * 0.7,
            nameTextStyle: {
              fontSize: fontSize,
            },

            splitLine: {
              show: true,
              lineStyle: {
                color: lineColor
              }
            },
          },
          {
            gridIndex: 1,
            type: 'value',
            name: `${strings.echart.centreonNetwork.chartType.bar.yAxis._1.title} (${strings.echart.centreonNetwork.chartType.bar.yAxis._1.unit})`,
            position: 'left',
            nameLocation: 'start',
            axisLine: {
              show: true,
              lineStyle: {
                color: Colors[1]
              }
            },
            splitNumber: 3,
            axisLabel: {
              fontSize: fontSize,
            },
            // nameGap: this.state.viewportWidth / 100 * 0.7,
            nameTextStyle: {
              fontSize: fontSize,
            },

            splitLine: {
              show: true,
              lineStyle: {
                color: lineColor
              }
            },
          }
        ],
        series: [
          {
            name: strings.echart.centreonNetwork.series.download.name,
            data: trafficOut.data,
            type: 'bar',
            areaStyle: {}
          },
          {
            name: strings.echart.centreonNetwork.series.upload.name,
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: trafficIn.data,
            type: 'bar',
            areaStyle: {}
          }
        ]
      }
    case "line":
    default:
      return {
        color: Colors,
        textStyle: {
          fontFamily: FontFamily,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          formatter: (params) => {
            let result = ''
            result += 'Date : ' + params[0].axisValueLabel + '<br/>'
            result += params
              .map((param) => {
                return param.marker + ' ' + param.seriesName + ' : ' + formatter.format(param.value)
              })
              .join('<br/>')
            return result
          }
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 90,
            end: 100
          },
          {
            type: 'inside',
            realtime: true,
            start: 90,
            end: 100
          }
        ],
        legend: {
          textStyle: {
            color: textColor
          }
        },
        toolbox: {
          feature: {
            restore: {},
            saveAsImage: {
              backgroundColor: backgroundColorSave
            }
          }
        },
        grid: {
          top: '20%',
          left: '10%',
          right: '10%'
        },
        xAxis: {
          type: 'category',
          data: metric.times,
          axisLabel: {
            fontSize: fontSize,
          },

          axisLine: {
            show: true,
            lineStyle: {
              color: textColor
            }
          },
        },
        yAxis: {
          type: 'value',
          name: `${strings.echart.centreonNetwork.chartType.line.yAxis.title} (${strings.echart.centreonNetwork.chartType.line.yAxis.unit})`,
          position: 'left',
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: textColor
            }
          },
          axisLabel: {
            fontSize: fontSize*0.85,
            formatter: (value) => formatter.format(value)
          },
          axisPointer: {
            label:{
              formatter: (value) => formatter.format(value.value),
              color: inversedTextColor
            },
          },
          nameTextStyle: {
            fontSize: fontSize,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: lineColor
            }
          },
        },
        series: [
          {
            name: strings.echart.centreonNetwork.series.download.name,
            data: trafficOut.data,
            type: 'line',
            areaStyle: {}
          },
          {
            name: strings.echart.centreonNetwork.series.upload.name,
            data: trafficIn.data,
            type: 'line',
            areaStyle: {}
          }
        ]
      }
  }
}
