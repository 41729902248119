import {
  index as baseIndex
 } from './Base';

/**
 * Fetch all family to serve in a dropdown form choice
 */
export function fetchRoles(search) {
  return baseIndex('roles', 1, 0, search);
}
