import React from 'react';

import '../../css/tabs.css';

import { Container, Row, Col, Button, Collapse } from 'react-bootstrap';

/**
 * Tabs
 */
class Tabs extends React.Component {

  static defaultProps = {
    id: null,
    className: null,
    displayTabSelection: true,
    activeKey: null,
    defaultActiveKey: null,
    onSelect: () => {},
    transition: 'fade'
  }

  constructor(props) {
    super(props);

    let defaultActiveKey = this.props.defaultActiveKey;
    if(Array.isArray(this.props.children)) {
      this.props.children.forEach((child) => {
        if(!child || child.props.eventKey === undefined) {
          console.warn("One tab of tabs doesn't have a eventKey props and will be ignored.");
        }
        else if(defaultActiveKey === null) {
          defaultActiveKey = child.props.eventKey;
        }
      });
    }
    else if (this.props.children !== null && this.props.children !== undefined) {
      if(this.props.children.props.eventKey === undefined) {
        console.warn("One tab of tabs doesn't have a eventKey props and will be ignored.");
      }
      else if(defaultActiveKey === null) {
        defaultActiveKey = this.props.children.props.eventKey
      }
    }

    this.state = {
      activeKey: defaultActiveKey,

      _mobile_displayFullMenu: false,
    }
  }

  filteredChildren = () => {
    if(Array.isArray(this.props.children)) {
      return this.props.children.filter((child) => child && child.props.eventKey !== undefined && child.props.eventKey !== null);
    }
    else if ( this.props.children !== undefined && this.props.children !== null) {
      if(this.props.children.props.eventKey === null) {
        return []
      }
      return [this.props.children];
    }
    return null;
  }

  getActiveKey = () => {
    if(this.props.activeKey !== null) {
      return this.props.activeKey;
    }
    else {
      return this.state.activeKey;
    }
  }

  onButtonClick = (eventKey, mobile = false) => {
    if(this.props.activeKey !== null) {
      this.props.onSelect(eventKey);
      this.setState({
        _mobile_displayFullMenu: false
      });
    }
    else {
      this.setState({
        activeKey: eventKey,
        _mobile_displayFullMenu: false
      });
    }
  }

  displayTabLabel(tab) {
    return (
      <div className={`${tab.props.icon && !tab.props.title?"tabs-button-icon":"tabs-button-label"}`}>
        {tab.props.icon?<i className={`${tab.props.icon}`}/>:""}&nbsp;{tab.props.title?tab.props.title:""}
      </div>
    )
  }

  displayButtonsDefault(children) {
    if(children) {
      return (
        <Container className="d-none d-md-flex justify-content-center">
          <Row className="justify-content-center align-items-center">
            {children.map((child, i) => {
              return (
                <Col
                  key={i}
                  xs='auto'
                  className="tabs-button-container">
                  <Button
                    variant="my-secondary-outline"
                    className={`tabs-button ${(this.getActiveKey() === child.props.eventKey)?"active":""}`}
                    onClick={() => this.onButtonClick(child.props.eventKey)}>
                    {this.displayTabLabel(child)}
                  </Button>
                </Col>
              )
            })}
          </Row>
        </Container>
      );
    }
    return null
  }

  displayButtonsMobile(children) {
    if(children) {
      return (
        <Container className="d-flex d-md-none">
          <Col>
            {children
              .filter((child) => this.getActiveKey() === child.props.eventKey)
              .map((child, i) => {
                return (
                  <Row key={i}>
                    <Col className="d-flex justify-content-center">
                      <Button
                        key={i}
                        variant="my-secondary-outline"
                        className={`tabs-button-mobile ${(this.getActiveKey() === child.props.eventKey)?"active":""}`}
                        onClick={() => this.onButtonClick(child.props.eventKey, true)}>
                        {this.displayTabLabel(child)}
                      </Button>
                    </Col>
                  </Row>
                )
              })
            }
            <Row>
              <Col>
                {!this.state._mobile_displayFullMenu?
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Button
                        variant="my-secondary-outline"
                        className="tabs-button-mobile"
                        onClick={() => this.setState({_mobile_displayFullMenu:true})}>
                        Autre(s) onglet(s)
                      </Button>
                    </Col>
                  </Row>
                  :null
                }
                <Collapse in={this.state._mobile_displayFullMenu}>
                  <div>
                    {children
                      .filter((child) => this.getActiveKey() !== child.props.eventKey)
                      .map((child, i) => {
                        return (
                          <Row key={i}>
                            <Col className="d-flex justify-content-center">
                              <Button
                                key={i}
                                variant="my-secondary-outline"
                                className={`tabs-button-mobile ${(this.getActiveKey() === child.props.eventKey)?"active":""}`}
                                onClick={() => this.onButtonClick(child.props.eventKey, true)}>
                                {this.displayTabLabel(child)}
                              </Button>
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </div>
                </Collapse>
              </Col>
            </Row>
          </Col>
          {/*
          <Col xs="auto" className="d-flex justify-content-center">
            <Button
              variant="my-primary-noline"
              onClick={() => this.setState({_mobile_displayFullMenu:!this.state._mobile_displayFullMenu})}>
              <i className={`icon-${this.state._mobile_displayFullMenu?"chevron-down":"chevron-up"}`}></i>
            </Button>
          </Col>
          */}
        </Container>
      )
    }
    return null
  }

  displayButtons() {
    let children = this.filteredChildren();
    if(this.props.displayTabSelection) {
      return (
        <>
          {this.displayButtonsDefault(children)}
          {this.displayButtonsMobile(children)}
        </>
      )
    }
  }

  displayTabs() {
    return (
      <>
        {this.filteredChildren().map((child, i) => {
          return (
            React.cloneElement(child, {
              key: i,
              active: this.getActiveKey() === child.props.eventKey
            })
          )
        })}
      </>
    );
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displayButtons()}
        {this.displayTabs()}
      </>
    );
  }
}

export default Tabs;
