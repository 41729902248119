export const dashboard = {
  name: "Commercial",
  version: 1,

  dashboardSize: 8,
  roles: ['ROLE_QUOTE', 'ROLE_ORDER'],

  widgets: [
    {
      id: 'commercial_ticket_list-1',
      position: 0
    },
    {
      id: 'commercial_ticket_stat-1',
      position: 16
    },
    {
      id: 'order_stat-1',
      position: 3
    },
    {
      id: 'order_list-1',
      position: 5
    },
    {
      id: 'quote_list-1',
      position: 18
    },
  ]
}
