import React from 'react';
import BasicFormField from './BasicFormField';

import FormFieldSelector from '../FormFieldSelector';

import strings from '../../../Localization/Localization';

import { Form, Row, Col, Button } from 'react-bootstrap';
import '../../../css/form.css';

import { ParameterTypes } from '../../../Dashboard/Constant';

/**
 * FormFieldColor
 *
 * This class handles HTML form field.
 *
 * The particular field is for color picker.
 *
 * Specific configuration attribute : none
 */
class DashboardFormFieldParameterChoice extends BasicFormField {

  constructor(props) {
    super(props);

    this.subFieldRef = React.createRef();

    this.subFieldRef_0 = React.createRef();
    this.subFieldRef_1 = React.createRef();
    this.subFieldRef_2 = React.createRef();
  }

  /**
   * @inheritdoc
   */
  getValue(complete = false) {
    if((this.props.value === undefined || this.props.value === null) && complete) {
      return {
        _parameterType: Object.keys(this.props.choices)[0],
        _title: '',
        value: '',
      };
    }

    let value = super.getValue();

    if(!complete && value) {
      value = value.value
    }
    return value;
  }

  getValueArray(key) {
    let value = this.getValue();
    if(Array.isArray(value)) {
      return value[key]
    }
    return "";
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    let value = super.getFinalValue();
    if(value) {
      switch (value._parameterType) {
        case "value":
        case "parameterBag":
          if(value.value) {
            value.value = this.subFieldRef.current.getFinalValue();
          }
          break;
        case "parameterList":
          if(value.value) {
            value.value[0] = this.subFieldRef_0.current.getFinalValue();
            value.value[1] = this.subFieldRef_1.current.getFinalValue();
            value.value[2] = this.subFieldRef_2.current.getFinalValue();
          }
          break;
        case "userSelf":
        default:

      }
    }
    return value;
  }

  onChangeArray(key, value, ignoreValidation = false) {

    let newValue = Object.assign({}, this.getValue(true));
    if(newValue.value) {
      newValue.value = [...newValue.value];
    }
    else {
      newValue.value = []
    }
    newValue.value[key] = value;

    this.props.onChange(newValue);
  }

  /**
   * @inheritdoc
   */
  onChange(value, ignoreValidation = false) {
    let newValue = Object.assign({}, this.getValue(true), {
      value: value
    });

    this.props.onChange(newValue);
  }

  /**
   * @inheritdoc
   */
  needValidation() {
    switch (this.getValue(true)._parameterType) {
      case "value":
      case "parameterBag":
        return this.subFieldRef.current.needValidation();
      case "parameterList":
        return this.subFieldRef_0.current.needValidation() && this.subFieldRef_1.current.needValidation() && this.subFieldRef_2.current.needValidation();
      case "userSelf":
      default:
    }
    return false;
  }

  /**
   * @inheritdoc
   */
  isValid = (target = null) => {
    switch (this.getValue(true)._parameterType) {
      case "value":
      case "parameterBag":
        return this.subFieldRef.current.isValid();
      case "parameterList":
        return this.subFieldRef_0.current.isValid() && this.subFieldRef_1.current.isValid() && this.subFieldRef_2.current.isValid();
      case "userSelf":
      default:
    }
    return false;
  }

  /**
   * @inheritdoc
   */
  startValidation = (value = null) => {
    if(this.subFieldRef.current) {
      this.subFieldRef.current.startValidation(value);
    }
  }

  getSubfieldProps() {
    let value = this.getValue(true)
    switch (value._parameterType) {
      case "value":
        return {
          type: this.props.subType,
          ...this.props.subProps
        }
      case "parameterList":
        return {
          type: this.props.subType,
          ...this.props.subProps
        }
      case "parameterBag":
        let options = [];
        if(this.props.choices.parameterBag && this.props.choices.parameterBag.parameterType && Object.values(ParameterTypes).includes(this.props.choices.parameterBag.parameterType)) {
          let extraValues = this.props.getExtraValues();
          if(extraValues) {
            extraValues.widgets.forEach((widget, i) => {
              if(widget.type === "parameter" && widget.conf && widget.conf.parameters) {
                widget.conf.parameters.forEach((parameter) => {
                  if(this.props.choices.parameterBag.parameterType === parameter.subType) {
                    options.push({
                      value: widget.id+"_"+parameter.id,
                      label: widget.title+" "+parameter.title
                    })
                  }
                });
              }
            });
          }
        }

        return {
          type: "dropdown",

          showEmpty: true,
          input: 'options',
          finalValues: 'values',
          options: options,

          adapter: {
            requirement: (data) => data["value"] && data["label"],
            getValue: (data) => data["value"],
            getLabel: (data) => data["label"],
            isValue: true
          },
        }
      case "userSelf":
      default:
        return {}
    }
  }

  onChangeChoice = (value) => {
    let newValue = Object.assign({}, this.getValue(true), {
      _parameterType: value,
      _title: this.state._title?this.state._title:'',
      value: undefined
    });
    this.props.onChange(newValue);
  }

  onChangeTitle = (value) => {
    let newValue = Object.assign({}, this.getValue(true), {
      _title: value
    });
    this.props.onChange(newValue);
  }

  onChangeTitleArray = (key, value) => {
    let newValue = Object.assign({}, this.getValue(true));
    if(newValue._title) {
      newValue._title = [...newValue._title];
    }
    else {
      newValue._title = []
    }
    newValue._title[key] = value;

    this.props.onChange(newValue);
  }

  // displayOptions() {
  //   return (
  //     <>
  //       {Object.keys(this.props.choices).map((option, i) => {
  //         let label = null;
  //         if(option === "value") {
  //           label = strings.form.default.field.dashboardParameterChoice.value;
  //         }
  //         else if(option === "parameterList") {
  //           label = strings.form.default.field.dashboardParameterChoice.parameterList;
  //         }
  //         else if(option === "parameterBag") {
  //           label = strings.form.default.field.dashboardParameterChoice.parameterBag;
  //         }
  //         return (
  //           <option key={i} value={option} label={label}>{label}</option>
  //         )
  //       })}
  //     </>
  //   )
  // }

  displayChoices() {
    return (
      <>
        {this.props.label}
        &nbsp;&nbsp;&nbsp;&nbsp;
        {Object.keys(this.props.choices).map((option, i) => {
          let label = null;
          let icon = null;
          let selected = (this.getValue(true)._parameterType === option)?true:false;
          if(option === "value") {
            label = strings.form.default.field.dashboardParameterChoice.value;
            icon = "icon-minus";
          }
          else if(option === "parameterList") {
            label = strings.form.default.field.dashboardParameterChoice.parameterList;
            icon = "icon-menu-1 icon-rotate-90";
          }
          else if(option === "parameterBag") {
            label = strings.form.default.field.dashboardParameterChoice.parameterBag;
            icon = "icon-menu-1";
          }
          else if(option === "userSelf") {
            label = strings.form.default.field.dashboardParameterChoice.userSelf;
            icon = "icon-user";
          }
          return (
            <Button key={i} variant="my-secondary-noline" className={`dashboard-widget-argument-button${selected?" dashboard-widget-argument-button-selected":""}`} onClick={() => this.onChangeChoice(option)}>{icon?<i className={icon}/>:null}{label}</Button>
          )
        })}
      </>
    )
  }

  displayTitle = () => {
    if(this.getValue(true)._parameterType === "parameterList")
    return (
      <Form.Control
        type="text"
        value={this.getValue(true)._title}
        onChange={(event) => this.onChangeTitle(event.target.value)} />
    )
  }

  displayMainForm() {
    let value = this.getValue(true);
    switch (value._parameterType) {
      case "value":
        return (
          <Row className="no-gutters">
            <FormFieldSelector
              forwardedRef={this.subFieldRef}
              {...this.getSubfieldProps()}

              fieldVariant={this.props.fieldVariant}

              // label={this.props.label}
              label=""
              placeholder={this.props.label}
              formKey={this.props.formKey}
              fieldKey={this.props.fieldKey}

              disabled={this.props.disabled}

              value={this.getValue()}
              getValues={this.props.getValues}
              getExtraValues={this.props.getExtraValues}
              onChange={(value) => this.onChange(value)}

              ignoreValidation={this.props.ignoreValidation}
              validators={this.props.validators}
              validationCallback={() => this.props.validationCallback()}
              submitted={this.props.submitted}
              submitting={this.props.submitting}

              skipSeparator={true}/>
          </Row>
        )
      case "parameterList":

        return (
          <Row className="no-gutters">
            <Col className="no-gutters">
              <FormFieldSelector
                forwardedRef={this.subFieldRef_0}
                {...this.getSubfieldProps()}

                fieldVariant={this.props.fieldVariant}

                // label={this.props.label}
                label=""
                placeholder={this.props.label}
                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                disabled={this.props.disabled}

                middlePart={{
                  type: "form",
                  value: value._title[0],
                  onChange: (value) => this.onChangeTitleArray(0, value),
                  placeholder: 'Titre'
                }}

                value={this.getValueArray(0)}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={(value) => this.onChangeArray(0, value)}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={() => this.props.validationCallback()}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                skipSeparator={true}/>
            </Col>
            <div className="dashboard-form-field-parameter-separator"/>
            <Col className="no-gutters">
              <FormFieldSelector
                forwardedRef={this.subFieldRef_1}
                {...this.getSubfieldProps()}

                fieldVariant={this.props.fieldVariant}

                // label={this.props.label}
                label=""
                placeholder={this.props.label}
                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                disabled={this.props.disabled}

                middlePart={{
                  type: "form",
                  value: value._title[1],
                  onChange: (value) => this.onChangeTitleArray(1, value),
                  placeholder: 'Titre'
                }}

                value={this.getValueArray(1)}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={(value) => this.onChangeArray(1, value)}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={() => this.props.validationCallback()}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                skipSeparator={true}/>
            </Col>
            <div className="dashboard-form-field-parameter-separator"/>
            <Col className="no-gutters">
              <FormFieldSelector
                forwardedRef={this.subFieldRef_2}
                {...this.getSubfieldProps()}

                fieldVariant={this.props.fieldVariant}

                //label={this.props.label}
                label=""
                placeholder={this.props.label}
                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}

                disabled={this.props.disabled}

                middlePart={{
                  type: "form",
                  value: value._title[2],
                  onChange: (value) => this.onChangeTitleArray(2, value),
                  placeholder: 'Titre'
                }}

                value={this.getValueArray(2)}
                getValues={this.props.getValues}
                getExtraValues={this.props.getExtraValues}
                onChange={(value) => this.onChangeArray(2, value)}

                ignoreValidation={this.props.ignoreValidation}
                validators={this.props.validators}
                validationCallback={() => this.props.validationCallback()}
                submitted={this.props.submitted}
                submitting={this.props.submitting}

                skipSeparator={true}/>
            </Col>
          </Row>
        );
      case "parameterBag":
        return (
          <Row className="no-gutters">
            <FormFieldSelector
              forwardedRef={this.subFieldRef}
              {...this.getSubfieldProps()}

              fieldVariant={this.props.fieldVariant}

              // label={this.props.label}
              label=""
              placeholder={this.props.label}
              formKey={this.props.formKey}
              fieldKey={this.props.fieldKey}

              disabled={this.props.disabled}

              value={this.getValue()}
              getValues={this.props.getValues}
              getExtraValues={this.props.getExtraValues}
              onChange={(value) => this.onChange(value)}

              ignoreValidation={this.props.ignoreValidation}
              validators={this.props.validators}
              validationCallback={() => this.props.validationCallback()}
              submitted={this.props.submitted}
              submitting={this.props.submitting}

              skipSeparator={true}/>
          </Row>
        )
      case "userSelf":
      default:
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        <Row className="d-flex no-gutters">
          {this.displayChoices()}
        </Row>
        <Row className="no-gutters">
          {this.displayMiddlePart()}
        </Row>
        {this.displayMainForm()}
      </Col>
    );
  }
}

export default DashboardFormFieldParameterChoice;
