import strings from '../../../Localization/Localization';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.ticketTimeZone.name.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "ticketTimeZoneSearch",
  fields: [
    name
  ]
}
