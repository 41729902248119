const _default = {
  field: {
    mail: {
      invalid: {
        assertFormat: "Cette valeur n'est pas au format mail."
      }
    },
    password: {
      invalid: {
        assertEqual: "Les deux mot de passe ne sont identiques."
      }
    },
    phoneNumber: {
      invalid: {
        assertFormat: "Cette valeur n'est pas au format numéro de téléphone."
      }
    },
    dropdownEntity: {
      invalid: {
        assertObject: "Cette valeur n'est pas valide, veuillez sélectionner une valeur dans la liste."
      },
      error: {
        loadingFailure: "La liste de valeurs n'a pa pu être chargée pour ce champ."
      }
    },
    dropdownEntityMultiAlt: {
      search: "Recherche"
    },
    dashboardParameterChoice: {
      value: "",
      parameterList: "",
      parameterBag: "",
      userSelf: "",
    }
  },
  button: {
    extraButton: "Annuler",
    submit: "Enregistrer"
  },
  itemIndex: {
    headers: {
      label: "Entête de tableau"
    }
  }
};

export default _default;
