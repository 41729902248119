import React from 'react';

import '../../../css/item_index.css';

import { Row, Button } from 'react-bootstrap';

import VerticalCollapse from '../../Animation/VerticalCollapse/VerticalCollapse';
import VerticalCollapsed from '../../Animation/VerticalCollapse/VerticalCollapsed';

/**
 * Index
 *
 * This component represent an index item, displayed in a table.
 */
class ItemIndexLine extends React.Component {

  static defaultProps = {
    as: 'a',
    buttons: () => []
  }

  displayMain() {
    switch (this.props.as) {
      case 'button':
        return this.displayAsButton();
      case 'a':
        return this.displayAsA();
      default:
        return this.displayDefault();
    }
  }

  displayAsButton() {
    if(this.props.as === 'button' && typeof this.props.onClickItem === 'function') {
      return (
        <div className="d-flex item-index-line-item" onClick={() => this.props.onClickItem(this.props.item.id, this.props.item)}>
          <span className="item-index-line-item-text">{this.props.itemDisplay.label(this.props.item)}</span>
        </div>
      )
    }
    else {
      return this.displayDefault();
    }
  }

  displayAsA() {
    if(this.props.as === 'a' && typeof this.props.entityPath === 'function') {
      let path = this.props.entityPath(this.props.item.id, this.props.item);
      if(path !== '#') {
        return (
          <a href={path}>
            <div className="d-flex item-index-line-item">
              <span className="item-index-line-item-text">{this.props.itemDisplay.label(this.props.item)}</span>
            </div>
          </a>
        )
      }
    }
    return this.displayDefault();
  }

  displayDefault() {
    return (
      <div className="d-flex item-index-line-item">
        <span className="item-index-line-item-text">{this.props.itemDisplay.label(this.props.item)}</span>
      </div>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className={`item-index-line-item-container${['a', 'button'].includes(this.props.as)?" item-index-clickable":""}`}>
        <VerticalCollapse>
          {this.displayMain()}
          <VerticalCollapsed>
            <Row
              className="justify-content-end"
              noGutters={true}>
              {this.props.buttons(this.props.item).map((button, i) => {
                return <Button key={i} className="item-index-button" variant="my-secondary-outline" onClick={button.onClick}>{button.icon?<i className={button.icon}/>:null}{button.label?button.label:null}</Button>;
              })}
            </Row>
          </VerticalCollapsed>
        </VerticalCollapse>
      </div>
    );
  }
}

export default ItemIndexLine;
