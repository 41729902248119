import strings from '../../Localization/Localization';

import { fetchPartnerTypes } from '../../API/PartnerTypes';
import { fetchAccountVats } from '../../API/AccountVats';

let clientType = {
  fieldKey: "client_type",
  label: strings.form.productFamilyVat.clientType.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "partnerTypes",
    loader: fetchPartnerTypes,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let accountVat = {
  fieldKey: "account_vat",
  label: strings.form.productFamilyVat.accountVat.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "accountVat",
    loader: fetchAccountVats,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["title"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["title"],
  },
}

export const form = {
  formKey: "productFamilyVat",
  fields: [
    clientType,
    accountVat
  ]
}
