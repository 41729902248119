const commercialBonusThreshold = {
  totalTfMin: {
    label: "Seuil minimum",
  },
  totalTfMax: {
    label: "Seuil maximum",
  },
  flatBonus: {
    label: "Bonus fixe",
  },
  appliedFrom: {
    label: "Appliqué depuis",
  },
  appliedUntil: {
    label: "Appliqué jusque",
  },
};

export default commercialBonusThreshold;
