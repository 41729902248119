/**
 * Counter
 *
 * A basic counter to either increment or decrement a value
 */
export default class Counter {

  /**
   * The constructor initialize the value of the counter to 0
   */
  constructeur() {
    this.reset();
  }

  /**
   * reset the value of the counter to 0
   */
  reset() {
    this.value = 0;
  }

  /**
   * set the value of the counter in case you want to use a decremental counter
   */
  setValue(value) {
    this.value = value;
  }

  /**
   * get the value of the counter
   */
  getValue() {
    return this.value;
  }

  /**
   * increment the value of the counter by 1
   */
  increment() {
    this.value++;
  }

  /**
   * decrement the value of the counter by 1
   */
  decrement() {
    this.value--;
  }
}
