import React from 'react';

import '../../../css/item_index.css';

import { ListGroup, Col, Row, Button } from 'react-bootstrap';

/**
 * Index
 *
 * This component represent an index item, displayed in a table.
 */
class ItemIndexBar extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <ListGroup.Item className="d-flex justify-content-between item-index-bar-item no-gutter item-index-clickable" >
        <Col xs="auto">
          <img alt="" src='http://172.16.101.100/dev-extranet/public/images/test/chat-256x256.jpg' className="item-index-card-image-container rounded-circle"/>
        </Col>
        <Col className="">
          <Row>{this.props.itemDisplay.firstLine(this.props.item)}</Row>
          <Row>{this.props.itemDisplay.secondLine(this.props.item)}</Row>
          <Row>{this.props.itemDisplay.thirdLine(this.props.item)}</Row>
          <Row>{this.props.itemDisplay.fourthLine(this.props.item)}</Row>
        </Col>
        <Col className="d-flex justify-content-end">
          <Row className="d-flex">{this.props.itemDisplay.dateLine(this.props.item)}</Row>
          <Row className="d-flex align-self-end">
            {this.props.buttons(this.props.item).map((button, i) => {
              return <Button key={i} className="item-index-button" variant="my-secondary-outline" onClick={button.onClick}>{button.icon?<i className={button.icon}/>:null}{button.label?button.label:null}</Button>;
            })}
          </Row>
        </Col>
      </ListGroup.Item>
    );
  }
}

export default ItemIndexBar;
