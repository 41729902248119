import strings from '../../Localization/Localization';

let prefix = {
  fieldKey: "prefix",
  type: "text",
  label: strings.form.phonePrefix.prefix.label,
  validators: []
}

export const form = {
  formKey: "phonePrefix",
  fields: [
    prefix
  ]
}
