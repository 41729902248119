import strings from '../../Localization/Localization';

let value = {
  fieldKey: "value",
  type: "text",
  label: strings.form.ticketFormFieldDropdownElement.value.label,
  validators: []
}

export const form = {
  formKey: "ticketFormFieldDropdownElement",
  fields: [
    value,
  ]
}
