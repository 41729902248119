import strings from '../../../Localization/Localization';

import { store } from '../../../Store/configureStore';

import { fetchPartners } from '../../../API/Partners';
import { fetchPlaces } from '../../../API/Places';
import {
  fetchMainUsers,
  fetchPartnerUsers
} from '../../../API/Users';

import { timeframe } from '../common';

let title = {
  fieldKey: "title",
  label: strings.form.cart.title.label,
  type: "text",
  showOnDisabled: () => false,
  validators: [],
}

let number = {
  fieldKey: "number",
  label: strings.form.quote.number.label,
  type: "text",
  showOnDisabled: () => false,
  validators: [],
}

let orderNumber = {
  fieldKey: "orderNumber",
  label: strings.form.quote.orderNumber.label,
  type: "text",
  showOnDisabled: () => false,
  validators: [],
}

export const state = {
  fieldKey: "state",
  label: strings.form.cart.state.label,
  type: "dropdownMulti",
  showOnDisabled: () => false,
  validators: [],

  input: 'options',
  finalValues: 'objects',
  options: [
    {
      value: 'q_w_agreement',
      label: strings.form.cart.state.options.quote.waitingAgreement
    },
    {
      value: 'q_w_validation',
      label: strings.form.cart.state.options.quote.waitingValidation
    },
    {
      value: 'q_validated',
      label: strings.form.cart.state.options.quote.validated
    },
    {
      value: 'q_canceled',
      label: strings.form.cart.state.options.quote.canceled
    },
  ],
}

let validated = {
  fieldKey: "validated",
  label: strings.form.quote.validated.label,
  type: "triswitch",
  showOnDisabled: () => false,
  validators: [],
}

let client = {
  fieldKey: "client",
  label: strings.form.cart.client.label,
  type: "dropdownEntity",
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "client",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let placeDelivery = {
  fieldKey: "placeDelivery",
  label: strings.form.cart.placeDelivery.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues(0).client === undefined || getValues(0).client === null || getValues(0).client.id === undefined,
  showOnDisabled: () => false,
  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component && component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "partnerPlaces",
    loader: fetchPlaces,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"],
        active: 1
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let clientUser = {
  fieldKey: "clientUser",
  type: "dropdownEntity",
  label: strings.form.cart.clientUser.label,
  showOnDisabled: () => false,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component && component.reloadData()
    }
  ],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "clientUser",
    loader: fetchPartnerUsers,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let mainUser = {
  fieldKey: "mainUser",
  type: "dropdownEntity",
  label: strings.form.cart.mainUser.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "mainUser",
    loader: fetchMainUsers,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let signatureDateMin = {
  fieldKey: "signatureDateMin",
  type: "date",
  label: strings.form.quote.signatureDateMin.label,
  showOnDisabled: () => false,
  validators: []
}

let signatureDateMax = {
  fieldKey: "signatureDateMax",
  type: "date",
  label: strings.form.quote.signatureDateMax.label,
  showOnDisabled: () => false,
  validators: []
}

let createdAtMin = {
  fieldKey: "createdAtMin",
  type: "date",
  label: strings.form.cart.createdAtMin.label,
  showOnDisabled: () => false,
  validators: []
}

let createdAtMax = {
  fieldKey: "createdAtMax",
  type: "date",
  label: strings.form.cart.createdAtMax.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "orderSearch",
  fields: [
    title,
    number,
    orderNumber,
    state,
    validated,
    client,
    placeDelivery,
    clientUser,
    mainUser,
    Object.assign({}, timeframe, {
      fieldKey: "signatureDateTimeframe",
      label: `${strings.form.common.timeframe.label} ${strings.form.quote.signatureDateTimeframe.label}`
    }),
    signatureDateMin,
    signatureDateMax,
    Object.assign({}, timeframe, {
      fieldKey: "createdAtTimeframe",
      label: `${strings.form.common.timeframe.label} ${strings.form.cart.createdAtTimeframe.label}`
    }),
    createdAtMin,
    createdAtMax
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
