import strings from '../../Localization/Localization';

import {
  fetchAccountProducts
} from '../../API/AccountProducts';

import { form as _productFamilyVatForm } from './productFamilyVats';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.productFamily.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.productFamily.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let activeOrder = {
  fieldKey: "active_order",
  type: "switch",
  label: strings.form.productFamily.activeOrder.label,
  defaultValue: false,
  validators: []
}

let activeQuote = {
  fieldKey: "active_quote",
  type: "switch",
  label: strings.form.productFamily.activeQuote.label,
  defaultValue: false,
  validators: []
}

let activeTicket = {
  fieldKey: "active_ticket",
  type: "switch",
  label: strings.form.productFamily.activeTicket.label,
  defaultValue: false,
  validators: []
}

let orderBreaking = {
  fieldKey: "order_breaking",
  type: "switch",
  label: strings.form.productFamily.orderBreaking.label,
  defaultValue: false,
  validators: []
}

let serviceTracking = {
  fieldKey: "service_tracking",
  type: "switch",
  label: strings.form.productFamily.serviceTracking.label,
  defaultValue: false,
  validators: []
}

let serviceBreaking = {
  fieldKey: "service_breaking",
  type: "switch",
  label: strings.form.productFamily.serviceBreaking.label,
  defaultValue: false,
  validators: []
}

let rentable = {
  fieldKey: "rentable",
  type: "switch",
  label: strings.form.productFamily.rentable.label,
  defaultValue: false,
  validators: []
}

let displayMainIndex = {
  fieldKey: "display_main_index",
  type: "switch",
  label: strings.form.productFamily.displayMainIndex.label,
  defaultValue: false,
  validators: []
}

let vats = {
  fieldKey: "vats",
  type: "array",
  label: strings.form.productFamily.vats.label,

  submitRemoval: true,
  removalCondition: (value) => value === undefined || value === '',
  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.productFamily.vats.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.productFamily.vats.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.productFamily.vats.allowMoreField.removingLabel,

  validators: [],

  subField: {
    type: "subform",
    label: strings.form.productFamily.vat.label,

    subForm: _productFamilyVatForm,

    validators: [],
  }
}

let vatPercentage = {
  fieldKey: "vat_percentage",
  type: "text",
  label: strings.form.productFamily.vatPercentage.label,
  defaultValue: false,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.productFamily.vatPercentage.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let accountSell = {
  fieldKey: "account_sell",
  label: strings.form.productFamily.accountSell.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "accountProduct",
    loader: fetchAccountProducts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["title"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["title"],
  },
}

let accountSubscription = {
  fieldKey: "account_subscription",
  label: strings.form.productFamily.accountSubscription.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "accountProduct",
    loader: fetchAccountProducts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["title"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["title"],
  },
}

let accountWorkforce = {
  fieldKey: "account_workforce",
  label: strings.form.productFamily.accountWorkforce.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "accountProduct",
    loader: fetchAccountProducts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["title"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["title"],
  },
}

let accountTrip = {
  fieldKey: "account_trip",
  label: strings.form.productFamily.accountTrip.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "accountProduct",
    loader: fetchAccountProducts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["title"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["title"],
  },
}

export const form = {
  formKey: "productFamily",
  fields: [
    name,
  ]
}

export const configurationForm = {
  formKey: "productFamily",
  fields: [
    activeOrder,
    activeQuote,
    activeTicket,
    orderBreaking,
    serviceTracking,
    serviceBreaking,
    rentable,
    displayMainIndex
  ]
}

export const accountingForm = {
  formKey: "productFamily",
  fields: [
    accountSell,
    accountSubscription,
    accountWorkforce,
    accountTrip,
    vatPercentage,
    vats
  ]
}

export const newForm = {
  formKey: "productFamily",
  fields: [
    name,
    activeOrder,
    activeQuote,
    activeTicket,
    orderBreaking,
    serviceTracking,
    serviceBreaking,
    rentable,
    displayMainIndex,
    vatPercentage,
  ]
}
