export const order_stat = {
  id: "order_stat-1",
  default: true,
  roles: ["ROLE_ORDER"],

  title: "Stat commande",
  type: "listStat",
  conf: {
    element: "order",
    stats: [
      {
        stat:"quantity",
        attribute:"id",
        title:"Qté en attente de validation",
        filters:{
          state:{
            _parameterType:"value",
            _title:"","value":[
              {
                value:"o_w_validation",
                label:"En attente de validation"
              }
            ]
          },
        },
      },
      {
        stat:"quantity",
        attribute:"id",
        title:"Qté en période de rétractation",
        filters:{
          state:{
            _parameterType:"value",
            _title:"","value":[
              {
                value:"o_c_period",
                label:"En période de rétractation"
              }
            ]
          },
        },
      },
      {
        stat:"quantity",
        attribute:"id",
        title:"Qté en attente de paiement",
        filters:{
          state:{
            _parameterType:"value",
            _title:"","value":[
              {
                value:"o_w_a_payment",
                label:"En attente du paiement initial"
              }
            ]
          },
        },
      },
      {
        stat:"quantity",
        attribute:"id",
        title:"Qté en cours de livraison",
        filters:{
          state:{
            _parameterType:"value",
            _title:"","value":[
              {
                value:"o_d_i_progress",
                label:"En cours de livraison initial"
              }
            ]
          },
        },
      },

    ]
  },
  column: 2,
  row: 2
}
