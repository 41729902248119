import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getIdentificationFromId,
  editFromId,
  newItem,
  deactivatePlace,
  reactivatePlace
} from '../../API/Places';
import {
  form,
  // editForm,
  newForm
} from '../Form/places';
import { form as searchForm } from '../Form/Search/places';

import {
  places as routes,
  services as servicesRoutes
} from '../Route/routes';

import { config } from '../../API/config';

import { store } from '../../Store/configureStore';
import {
  modifySearchAction
} from '../../Store/Action/search';
import {
  conf as serviceConf
} from './services';

export const conf = {
  index: {
    id: "place",
    pageTitle: strings.common.homepage.places,
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_PARTNER_CREATE'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name
      }
      if(typeof search.partner === 'object') {
        result["partner_id"] = search.partner.id;
      }
      if(search.number) {
        result["number"] = search.number
      }
      if(search.way) {
        result["way"] = search.way
      }
      if(search.zipCode) {
        result["zip_code"] = search.zipCode
      }
      if(search.city) {
        result["city"] = search.city
      }
      if(search.active === true) {
        result["active"] = 1
      }
      if(search.active === false) {
        result["active"] = 0
      }
      return result;
    },

    availableItemDisplay:['card', 'tab'],
    itemDisplay: {
      card: {
        firstLine: (item) => item["partner"]["name"],
        secondLine: (item) => item["name"],
        thirdLine: (item) => `${item["number"]} ${item["way"]} ${item["city"]}`,
        fourthLine: (item) => `${item["active_service_count"]} service(s) actif(s)`,
        img: (item) => (item.img)?config.file_path+item.img:null,
      },
      tab: {
        headers: [
          {
            label: strings.place.index.tab.headers.partnerName,
            value: (item) => item["partner"]["name"]
          },
          {
            label: strings.place.index.tab.headers.name,
            value: "name"
          },
          {
            label: strings.place.index.tab.headers.address,
            value: (item) => `${item["number"]} ${item["way"]} ${item["city"]}`
          },
          {
            label: strings.place.index.tab.headers.service,
            value: "active_service_count"
          },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: (item) => {
      let buttons = [];
      if(item["active_service_count"] > 0) {
        buttons.push({
          label: strings.place.index.buttons.service,
          onClick: () => {
            store.dispatch(modifySearchAction(serviceConf.index.id, {
              place: item
            }));
            window.location.assign(servicesRoutes.routes.index.createPath());
          },
        });
      }
      return buttons;
    }
  },
  show: {
    pageTitle: strings.common.homepage.places,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getIdentificationFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => (item.img)?config.file_path+item.img:null,
      placeholderTitle: (item) => item.name.split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item && item["partner"] && item["partner"]["name"],
      additionalData1: (item) => item && item["name"],
      additionalData2: (item) => item && item["active"] === true?strings.place.show.header.active:strings.place.show.header.inactive,
      additionalData3: (item) => '',
    },

    menu: (item) => {
      let buttons = [];
      if(item["active_service_count"] === 0 && item["active"]) {
        buttons.push({
          id: 'deactivatePlace',
          type: 'request',
          auth: 'ROLE_NYUKOM', // TODO voir sil faut changer
          request: deactivatePlace,
          addVerification: true,
          label: strings.place.tabs.buttons.deactivatePlace,
          variant: "my-warning"
        });
      }
      if(!item["active"]) {
        buttons.push({
          id: 'reactivatePlace',
          type: 'request',
          auth: 'ROLE_NYUKOM', // TODO voir sil faut changer
          request: reactivatePlace,
          addVerification: true,
          label: strings.place.tabs.buttons.reactivatePlace,
          variant: "my-validated"
        });
      }
      return buttons;
    },

    tabs: {
      main: {
        title: strings.place.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: ['ROLE_NYUKOM']
      }
    }
  },
  new: {
    pageTitle: strings.place.new.title,

    api: newItem,

    empty: {},
    form: newForm,

    onSubmit: () => {},

    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
