import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, InputGroup, Col } from 'react-bootstrap';
import '../../../css/form.css';

/**
 * FormFieldPrice
 *
 * This class handles HTML form field.
 *
 * The particular field is for price.
 *
 * Specific configuration attribute : none
 */
class FormFieldPrice extends BasicFormField {

  /**
   * @inheritdoc
   */
  getValue(){
    if(this.props.value === undefined || this.props.value === null) {
      return '';
    }
    let value = super.getValue();

    value = value.toString();
    value = value.replace('.', ',');

    return value;
  }

  getFormattedValue() {
    return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(super.getValue())
  }

  /**
   * @inheritdoc
   */
  getFinalValue(){
    let value = this.getValue();
    value = FormFieldPrice.parseFinalValue(value);
    return (value !== null && value !== undefined)?value:this.props.defaultValue;
  }

  static parseFinalValue(value) {
    if(typeof value === 'string') {
      value = value.replace(',', '.');
      value = value.replaceAll(/\s/g, '');
    }
    return parseFloat(value);
  }

  /**
   * @inheritdoc
   */
  onChange = (value) => {
    //check if the value is a number
    if(value !== undefined && value !== null) {

      value = value.toString();
      let regex = /^-?(\d{0,2})(\d{3})*([.,]\d{0,5})?$/g;
      if(value.match(regex)) {
        super.onChange(value);
      }
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          {
            //If the field is not disabled show the field as normal
            !this.props.disabled?
            <>
              <Form.Control
                className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius"
                placeholder={this.props.label}
                aria-label={this.props.label}
                aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
                type="text"
                disabled={this.props.disabled || this.props.submitting}
                value={this.getValue()}
                onChange={(event) => this.onChange(event.target.value)}
                isValid={(this.props.disabled)?null:this.isValid()}
                isInvalid={(this.props.disabled)?null:this.isInvalid()}
              />
              <InputGroup.Prepend
                className="d-md-flex container-form-prepend">
                <InputGroup.Text>€</InputGroup.Text>
              </InputGroup.Prepend>
            </>
            :
            //If the field is disabled show a link to send a mail
            <div className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius form-control align-items-center form-disabled">{this.getFormattedValue()}</div>
          }

          {this.displayValidating()}
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

export default FormFieldPrice;
