import strings from '../../Localization/Localization';

import {
  MODIFY_CART,
  RESET_CART,
  ADD_CART_SECTION,
  REMOVE_CART_SECTION,
  MODIFY_CART_SECTION_ORDER,
  MODIFY_CART_SECTION_TITLE,
  ADD_CART_ITEM,
  ADD_CART_ITEM_SLAVE,
  REMOVE_CART_ITEM,
  MODIFY_CART_ITEMS_ORDER,
  MODIFY_CART_ITEM_RENTED,
  MODIFY_CART_ITEM_QUANTITY,
  MODIFY_CART_ITEM_DISCOUNT,
  MODIFY_CART_ITEM_DISCOUNT_UNIT,

  ADD_AMENDMENT,
  REMOVE_AMENDMENT,

  COPY_QUOTE_INFORMATION,
  COPY_QUOTE_ITEMS
} from '../Action/cart'

const initialState = {
  client: '',
  clientAccount: '',
  placeDelivery: '',
  sections: [],
  cartItems: [],
  title: '',
  description: '',
  clientUser: '',
  mainUser: '',
  relinquishRightToCancellation: false,
  locationPriceModifier: 'price'
}

function findLastSid(state) {
  let sid = 1;
  state.sections.forEach((item) => {
    if(item._sid > sid) {
      sid = item._sid;
    }
  });
  state.cartItems.forEach((item) => {
    if(item._sid > sid) {
      sid = item._sid;
    }
  });
  return sid+1;
}

function findLastMaster(state, item) {
  if(item && item.dependencyMaster) {
    return findLastMaster(state, state.cartItems.find((cartItem) => cartItem._sid === item.dependencyMaster.masterId));
  }
  else {
    return item;
  }
}

function checkIfBelongToSection(state, item, sectionId = null, checkMaster = false) {
  let itemToCheck = item;

  if(checkMaster) {
    itemToCheck = findLastMaster(state, itemToCheck);
  }

  if(!itemToCheck.section && sectionId === null) {
    return true;
  }
  else if(itemToCheck.section && itemToCheck.section._sid === sectionId) {
    return true;
  }
  return false;
}

export default function reduce(state = initialState, action){
  let newState = Object.assign({}, state);

  let index = -1;
  let newItem = {};
  let sections = [];
  let cartItems = [];

  switch (action.type) {
    case MODIFY_CART:
      newState[action.key] = action.value;
      if(['client', 'clientAccount', 'placeDelivery'].includes(action.key)) {
        state.cartItems
          .filter((item) => !findLastMaster(state, item).amendment)
          .forEach((item, i) => {
            cartItems.push(item);
          }
        );
        newState.cartItems = cartItems;
      }
      break;
    case RESET_CART:
      newState = initialState;
      break;
    case ADD_CART_SECTION:
      state.sections.forEach((section, i) => {
        sections.push(section);
      });
      let section = {
        _sid: findLastSid(state),
        title: "",
        order: state.sections.length +1
      }
      sections.push(section);
      newState.sections = sections;
      break;
    case REMOVE_CART_SECTION:
      let sectionToDelete = state.sections.find(section => section._sid === action.sectionId);
      state.sections.forEach((section, i) => {
        if(section._sid !== action.sectionId) {
          let newSection = Object.assign({}, section);
          if(newSection.order > sectionToDelete.order) {
            newSection.order--;
          }
          sections.push(newSection);
        }
      });
      state.cartItems.forEach((item, i) => {
        if(!checkIfBelongToSection(state, item, action.sectionId, true)) {
          cartItems.push(item);
        }
      });
      newState.sections = sections;
      newState.cartItems = cartItems;
      break;
    case MODIFY_CART_SECTION_ORDER:
      state.sections.forEach((section, i) => {
        let newSection = Object.assign({}, section);
        if(section.order === action.dragOrder) {
          newSection.order = action.hoverOrder;
        }
        else {
          if(newSection.order > action.dragOrder) {
            newSection.order--;
          }
          if(newSection.order >= action.hoverOrder) {
            newSection.order++;
          }
        }
        sections.push(newSection);
      });
      newState.sections = sections;
      break;
    case MODIFY_CART_SECTION_TITLE:
      state.sections.forEach((section, i) => {
        let newSection = Object.assign({}, section);
        if(newSection._sid === action.sectionId) {
          newSection.title = action.title;
        }
        sections.push(newSection);
      });
      newState.sections = sections;
      break;
    case ADD_CART_ITEM:
      state.cartItems.forEach((item, i) => {
        cartItems.push(item);
      });
      let item = {
        _sid: findLastSid(state),
        product: {
          id: action.productId
        },
        quantity: action.quantity,
        discount: 0,
        discountUnit: '€',
        rented: false,
        order: state.cartItems.filter((item) => (item.section === undefined || item.section === null) && item.order !== null).length +1
      }
      cartItems.push(item);
      newState.cartItems = cartItems;
      break;
    case ADD_CART_ITEM_SLAVE:
      state.cartItems.forEach((item, i) => {
        cartItems.push(item);
      });
      let itemSlave = {
        _sid: findLastSid(state),
        product: {
          id: action.productId
        },
        dependencyMaster: {
          masterId: action.masterId,
          relationId: action.relationId,
        },
        quantity: 1,
        discount: 0,
        discountUnit: '€',
        rented: false,
        order: null
      }
      cartItems.push(itemSlave);
      newState.cartItems = cartItems;
      break;
    case REMOVE_CART_ITEM:
    case REMOVE_AMENDMENT:
      let itemToDelete = state.cartItems.find(item => item._sid === action.itemId);
      if(itemToDelete) {
        state.cartItems
          .filter((item) => {
            let result = true;
            let itemToCheck = item;
            while(itemToCheck && itemToCheck.dependencyMaster) {
              if(itemToCheck.dependencyMaster.masterId === itemToDelete._sid) {
                result = false;
                break;
              }
              let temp = itemToCheck;
              itemToCheck = state.cartItems.find(item => item._sid === temp.dependencyMaster.masterId);
            }
            return result;
          })
          .forEach((item, i) => {
            if(item._sid !== action.itemId) {
              let newItem = Object.assign({}, item);
              if((((newItem.section === undefined || newItem.section === null)
                && (itemToDelete.section === undefined || itemToDelete.section === null))
                || (newItem.section !== undefined
                && newItem.section !== null
                && itemToDelete.section !== undefined
                && itemToDelete.section !== null
                && newItem.section._sid === itemToDelete.section._sid))
                && (newItem.order > itemToDelete.order)) {
                newItem.order--;
              }
              cartItems.push(newItem);
            }
          });
        newState.cartItems = cartItems;
      }
      break;
    case MODIFY_CART_ITEMS_ORDER:
      state.cartItems.forEach((item, i) => {
        let newItem = Object.assign({}, item);
        if(
          (checkIfBelongToSection(state, newItem, action.dragSectionId))
          && newItem.order === action.dragOrder
        ) {
          if((newItem.section === undefined || newItem.section === null) && action.hoverSectionId !== null) {
            newItem.section = {
              _sid: action.hoverSectionId
            }
          }
          else if (action.hoverSectionId === null) {
            newItem.section = undefined;
          }
          else {
            newItem.section._sid = action.hoverSectionId;
          }
          newItem.order = action.hoverOrder;
        }
        else {
          if(
            (checkIfBelongToSection(state, newItem, action.dragSectionId))
            && newItem.order > action.dragOrder)
          {
            newItem.order--;
          }
          if(
            (checkIfBelongToSection(state, newItem, action.hoverSectionId))
            && newItem.order >= action.hoverOrder
          ) {
            newItem.order++;
          }
        }
        cartItems.push(newItem);
      });
      newState.cartItems = cartItems
      break;
    case MODIFY_CART_ITEM_RENTED:
      newState.cartItems.forEach((item, i) => {
        if(item._sid === action.itemId) {
          index = i;
          Object.assign(newItem, item, {
            rented: action.rented
          })
        }
      });
      if(index !== -1) newState.cartItems[index] = newItem;
      break;
    case MODIFY_CART_ITEM_QUANTITY:
      newState.cartItems.forEach((item, i) => {
        if(item._sid === action.itemId) {
          index = i;
          Object.assign(newItem, item, {
            quantity: action.quantity
          })
        }
      });
      if(index !== -1) newState.cartItems[index] = newItem;
      break;
    case MODIFY_CART_ITEM_DISCOUNT:
      newState.cartItems.forEach((item, i) => {
        if(item._sid === action.itemId) {
          index = i;
          Object.assign(newItem, item, {
            discount: action.discount
          })
        }
      });
      if(index !== -1) newState.cartItems[index] = newItem;
      break;
    case MODIFY_CART_ITEM_DISCOUNT_UNIT:
      newState.cartItems.forEach((item, i) => {
        if(item._sid === action.itemId) {
          index = i;
          Object.assign(newItem, item, {
            discountUnit: action.discountUnit
          })
        }
      });
      if(index !== -1) newState.cartItems[index] = newItem;
      break;
    case ADD_AMENDMENT:
      let done = false;
      if(state.cartItems) state.cartItems.forEach((item, i) => {
        if(item.amendment) {
          if(item.amendment.service["id"] === action.amendment.service["id"]) {
            done = true;
            item.product = {
              id: action.productId
            };
            item.quantity = action.quantity;
            item.amendment = action.amendment;
          }
          if(item.amendment.client.id === action.amendment.client.id && item.amendment.clientAccount.id === action.amendment.clientAccount.id && item.amendment.place.id === action.amendment.place.id) {
            cartItems.push(item);
          }
        }
        else {
          cartItems.push(item);
        }
      });

      if(!done) {
        let cartItemWithAmendment = {
          _sid: findLastSid(state),
          product: {
            id: action.productId
          },
          quantity: action.quantity,
          amendment: action.amendment,
          discount: 0,
          discountUnit: '€',
          rented: false,
          order: state.cartItems.filter((item) => item.section === undefined || item.section === null).length +1
        }
        cartItems.push(cartItemWithAmendment);
      }

      newState.client = action.amendment.client;
      newState.clientAccount = action.amendment.clientAccount;
      newState.placeDelivery = action.amendment.place;
      newState.cartItems = cartItems;
      if(state.title === '') {
        newState.title = strings.amendment.reducer.defaultTitle
      }
      break;
    case COPY_QUOTE_INFORMATION:
      newState.client = action.client;
      newState.clientAccount = action.clientAccount;
      newState.placeDelivery = action.placeDelivery;
      newState.clientUser = action.clientUser;
      newState.title = action.title;
      newState.description = action.description;
      break;
    case COPY_QUOTE_ITEMS:
      //copy existing values
      state.sections.forEach((section, i) => {
        sections.push(section);
      });
      newState.sections = sections;
      state.cartItems.forEach((item, i) => {
        cartItems.push(item);
      });
      newState.cartItems = cartItems;

      //copy sections from quote
      let sectionTransferIds = {};
      action.sections.forEach((section, i) => {
        if(!sectionTransferIds[section.id]) {
          sectionTransferIds[section.id] = findLastSid(newState)
        }
        let newSection = {
          _sid: sectionTransferIds[section.id],
          title: section["title"],
          order: state.sections.length + section["order"]
        }
        sections.push(newSection);
      });

      //copy cartItems from quote
      let cartItemTransferIds = {};
      action.cartItems
        .filter((item, i) => !item["dependency_master"] || !item["dependency_master"]["product_dependency"])
        .forEach((item, i) => {
          if(!cartItemTransferIds[item.id]) {
            cartItemTransferIds[item.id] = findLastSid(newState)
          }
          let types = [];
          if(item["amendment"] && item["amendment"]["id"]) {
            if(state["client"]["id"] !== item["amendment"]["previous_cart_item"]["cart"]["client"]["partner"]["id"] || state["clientAccount"]["id"] !== item["amendment"]["previous_cart_item"]["cart"]["client"]["id"]) {
              console.error("invalid data copied");
              return state;
            }
            if(item["price"] !== item["product"]["price"]) {
              types.push("price");
            }
            if(item["product"]["id"] !== item["amendment"]["previous_cart_item"]["product"]["id"]) {
              types.push("product");
            }
            if(state["placeDelivery"]["id"] !== item["amendment"]["previous_cart_item"]["cart"]["place"]["id"]) {
              types.push("place");
            }
            if(item["amendment"]["quantity_additional"] === true) {
              types.push("quantity");
            }
          }

          let newItem = {
            _sid: cartItemTransferIds[item.id],
            product: {
              id: item["product"]["id"]
            },
            quantity: item["quantity"],
            discount: item["discount"],
            discountUnit: item["discount_unit"],
            rented: item["rented"],
            amendment: (item["amendment"] && item["amendment"]["id"])?{
              quantityAdditional: item["amendment"]["quantity_additional"],
              unitPrice: (types.includes("price"))?item["price"]:undefined,
              service: item["amendment"]["service"],
              previousCartItem: item["amendment"]["previous_cart_item"],
              client: state["client"],
              clientAccount: state["clientAccount"],
              place: types.includes("place")?{
                id: state["placeDelivery"]["id"]
              }:undefined,
              types: types
            }:undefined,
            section: (item["section"] && item["section"]["id"])?{
              _sid: sectionTransferIds[item["section"]["id"]]
            }:undefined,
            order: (item["section"] && item["section"]["id"])?
              item["order"]
              :state.cartItems.filter((item) => (item.section === undefined || item.section === null) && item.order !== null).length + item["order"] +1
            ,
          }
          cartItems.push(newItem);
        }
      );
      action.cartItems
        .filter((item, i) => item["dependency_master"] && item["dependency_master"]["product_dependency"])
        .forEach((item, i) => {
          if(!cartItemTransferIds[item.id]) {
            cartItemTransferIds[item.id] = findLastSid(newState)
          }
          let newItem = {
            _sid: cartItemTransferIds[item.id],
            product: {
              id: item["product"]["id"]
            },
            dependencyMaster: item["dependency_master"]?{
              masterId: cartItemTransferIds[item["dependency_master"]["cart_item_master"]["id"]],
              relationId: item["dependency_master"]["product_dependency"]["id"],
            }:undefined,
            quantity: item["quantity"],
            discount: item["discount"],
            discountUnit: item["discount_unit"],
            rented: item["rented"],
            section: (item["section"] && item["section"]["id"])?{
              _sid: sectionTransferIds[item["section"]["id"]]
            }:undefined,
            order: null,
          }
          cartItems.push(newItem);
        }
      );
      break;
    default:
      newState = state;
  }
  return newState;
}
