import strings from '../../../Localization/Localization';

import { fetchServices } from '../../../API/Services';

let service = {
  fieldKey: "service",
  type: "dropdownEntity",
  label: strings.form.phoneCDR.service.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  loader: {
    id: "service",
    loader: fetchServices,
    loaderRequire: () => true,
    loaderArguments: () => [
     {
       phone_package_order: true
     }
    ],
  },

  adapter: {
    requirement: (data) => data["id"] && data["order"] && data["order"]["number"] && data["cart_item"] && data["cart_item"]["product"] && data["cart_item"]["product"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => `${data["order"]["number"]}${data["serial_number"]?" "+data["serial_number"]:""} ${data["cart_item"]["product"]["name"]}`,
  },
}

let communicationType = {
  fieldKey: "communicationType",
  type: "dropdown",
  label: strings.form.phoneCDR.communicationType.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 1,
      label: strings.form.phoneCDR.communicationType.options.voice
    },
    {
      value: 2,
      label: strings.form.phoneCDR.communicationType.options.data
    },
    {
      value: 3,
      label: strings.form.phoneCDR.communicationType.options.sms
    },
    {
      value: 4,
      label: strings.form.phoneCDR.communicationType.options.mms
    },
    {
      value: 5,
      label: strings.form.phoneCDR.communicationType.options.other
    }
  ],
}

let roaming = {
  fieldKey: "roaming",
  type: "triswitch",
  label: strings.form.phoneCDR.roaming.label,
  showOnDisabled: () => false,
  validators: [],
}

let roamingLikeAtHome = {
  fieldKey: "roamingLikeAtHome",
  type: "triswitch",
  label: strings.form.phoneCDR.roamingLikeAtHome.label,
  showOnDisabled: () => false,
  validators: [],
}

let redirection = {
  fieldKey: "redirection",
  type: "triswitch",
  label: strings.form.phoneCDR.redirection.label,
  showOnDisabled: () => false,
  validators: [],
}

let inout = {
  fieldKey: "inout",
  type: "dropdown",
  label: strings.form.phoneCDR.inout.label,
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 0,
      label: strings.form.phoneCDR.inout.options.in
    },
    {
      value: 1,
      label: strings.form.phoneCDR.inout.options.out
    }
  ],
}

let sourceNumber = {
  fieldKey: "sourceNumber",
  type: "text",
  label: strings.form.phoneCDR.sourceNumber.label,
  showOnDisabled: () => false,
  validators: [],
}

let destinationNumber = {
  fieldKey: "destinationNumber",
  type: "text",
  label: strings.form.phoneCDR.destinationNumber.label,
  showOnDisabled: () => false,
  validators: [],
}

let beginAtMin = {
  fieldKey: "beginAtMin",
  type: "date",
  label: strings.form.phoneCDR.beginAtMin.label,
  showOnDisabled: () => false,
  validators: []
}

let beginAtMax = {
  fieldKey: "beginAtMax",
  type: "date",
  label: strings.form.phoneCDR.beginAtMax.label,
  showOnDisabled: () => false,
  validators: []
}

let quantityMin = {
  fieldKey: "quantityMin",
  type: "text",
  label: strings.form.phoneCDR.quantityMin.label,
  showOnDisabled: () => false,
  validators: []
}

let quantityMax = {
  fieldKey: "quantityMax",
  type: "text",
  label: strings.form.phoneCDR.quantityMax.label,
  showOnDisabled: () => false,
  validators: []
}

let priceFinalMin = {
  fieldKey: "priceFinalMin",
  type: "price",
  label: strings.form.phoneCDR.priceFinalMin.label,
  showOnDisabled: () => false,
  validators: []
}

let priceFinalMax = {
  fieldKey: "priceFinalMax",
  type: "price",
  label: strings.form.phoneCDR.priceFinalMax.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "cdrSearch",
  fields: [
    service,
    communicationType,
    roaming,
    roamingLikeAtHome,
    redirection,
    inout,
    sourceNumber,
    destinationNumber,
    beginAtMin,
    beginAtMax,
    quantityMin,
    quantityMax,
    priceFinalMin,
    priceFinalMax
  ]
}
