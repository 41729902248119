import React from 'react';

import AbstractPlacement from './AbstractPlacement';

import { Row as BaseRow } from 'react-bootstrap';

/**
 */
class Row extends AbstractPlacement {

  static defaultProps = {
    partChildren: null,
    
    noGutters: null
  }

  render() {
    return (
      <BaseRow
        noGutters={this.props.noGutters}>
        {this.displayChildren()}
      </BaseRow>
    );
  }
}

export default Row;
