const accountAnalytic = {
  title: {
    label: "Titre",
  },
  collectiveClientNumber: {
    label: "Collectivité client"
  }
};

export default accountAnalytic;
