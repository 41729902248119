import strings from '../../Localization/Localization';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.constructor.name.label,
  validators: []
}

export const form = {
  formKey: "constructor",
  fields: [
    name
  ]
}
