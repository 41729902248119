import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, Row, Col } from 'react-bootstrap';
import '../../../css/form.css';

/**
 * FormFieldColor
 *
 * This class handles HTML form field.
 *
 * The particular field is for color picker.
 *
 * Specific configuration attribute : none
 */
class DashboardFormFieldColor extends BasicFormField {

  /**
   * @inheritdoc
   */
  getValue() {
    return (this.props.value)?this.props.value:'';
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    return (this.props.value)?this.props.value:this.props.defaultValue;
  }

  displayInput() {
    return (
      <Form.Control
        className="d-flex dashboard-field-color"
        placeholder={this.props.label}
        aria-label={this.props.label}
        aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
        type="color"
        disabled={this.props.disabled || this.props.submitting}
        value={this.getValue()}
        onChange={(event) => this.onChange(event.target.value)}
        isValid={(this.props.disabled)?null:this.isValid()}
        isInvalid={(this.props.disabled)?null:this.isInvalid()}/>
    )
  }

  getMiddlePartAdditionalClassname() {
    return "dashboard-field-middle-part";
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className={`dashboard-form-row${!this.props.skipSeparator?" dashboard-form-row-separator":""}`}>
        <Row className="d-flex align-items-center no-gutters">
          {this.props.label !== ""?
            <Col xs="auto" className="no-gutters dashboard-field-color-label">
              {this.props.label}&nbsp;{this.displayValidating()}&nbsp;&nbsp;&nbsp;{this.shouldDisplayMiddlePart()?<>&nbsp;&nbsp;&nbsp;{this.displayMiddlePart()}</>:''}
            </Col>
          :undefined}
          {this.props.label === "" && this.shouldDisplayMiddlePart()?
            <>
              <Col xs={12} className="no-gutters dashboard-field-color-label">
                {this.displayValidating()}{this.displayMiddlePart()}
              </Col>
            </>
          :undefined}
          <Col xs={this.props.label === ""?12:""} className="no-gutters">
            {this.displayInput()}
          </Col>
        </Row>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    )
  }
}

export default DashboardFormFieldColor;
