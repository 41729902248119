import BaseAssert from './BaseAssert';

class LengthMaxAssert extends BaseAssert {

  defaultConf = { max:0 };

  assert() {
    return (this.getValue() !== undefined && this.getValue() !== null)? this.getValue().length <= this.conf.max:null;
  }
}

export default LengthMaxAssert;
