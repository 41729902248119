import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getZonesFromId,
  getPriceSourcesFromId,
  getPriceDestinationsFromId,
  editFromId,
  newItem
} from '../../API/PhoneZoneGroups';
import {
  form,
  zoneForm,
  priceSourceForm,
  priceDestinationForm
} from '../Form/phoneZoneGroups';
import { form as searchForm } from '../Form/Search/phoneZoneGroups';

import { phoneZoneGroup as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.phoneZoneGroup,

    id: "phoneZoneGroup",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.label) {
        result["label"] = search.label;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => `${item["label"]}`
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: (item) => []
  },
  show: {
    pageTitle: strings.common.homepage.phoneZoneGroup,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "label",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.phoneZoneGroup.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_CDR_CONFIGURATION'],
        editable: ['ROLE_CDR_CONFIGURATION']
      },
      zone: {
        title: strings.phoneZoneGroup.tab.zone,
        type: 'form',
        form: zoneForm,

        onUpdateItem: (data) => {},

        apiGet: getZonesFromId,

        role: ['ROLE_CDR_CONFIGURATION'],
        editable: false
      },
      priceSource: {
        title: strings.phoneZoneGroup.tab.priceSource,
        type: 'form',
        form: priceSourceForm,

        onUpdateItem: (data) => {},

        apiGet: getPriceSourcesFromId,

        auth: ['ROLE_CDR_CONFIGURATION'],
        editable: false
      },
      priceDestination: {
        title: strings.phoneZoneGroup.tab.priceDestination,
        type: 'form',
        form: priceDestinationForm,

        onUpdateItem: (data) => {},

        apiGet: getPriceDestinationsFromId,

        auth: ['ROLE_CDR_CONFIGURATION'],
        editable: false
      },
    }
  },
  new: {
    pageTitle: strings.phoneZoneGroup.new.title,

    api: newItem,
    empty: {},
    form: form,
    onSubmit: (phoneZoneGroup) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
