import BaseAssert from './BaseAssert';

class RegexAssert extends BaseAssert {

  defaultConf = { regex: /.*/ };

  assert() {
    return (this.getValue() !== undefined && this.getValue() !== null && this.getValue() !== "")? this.conf.regex.test(this.getValue()):null;
  }
}

export default RegexAssert;
