import strings from '../../Localization/Localization';

import { fetchPhonePriceLists } from '../../API/PhonePriceLists';

import { phonePriceList as phonePriceListRoutes } from '../Route/routes';

let applicableFrom = {
  fieldKey: "applicable_from",
  type: "date",
  label: strings.form.specificPhonePriceList.applicableFrom.label,
  validators: []
}

let phonePriceList = {
  fieldKey: "phone_price_list",
  type: "dropdownEntity",
  label: strings.form.specificPhonePriceList.phonePriceList.label,
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && value["id"] && (credentials.roles.includes('ROLE_CDR_PRICE_LIST'))) {
        return phonePriceListRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  loader: {
    id: "priceLists",
    loader: fetchPhonePriceLists,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

export const form = {
  formKey: "specificPhonePriceList",
  fields: [
    applicableFrom,
    phonePriceList
  ]
}
