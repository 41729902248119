const partnerAccount = {
  partnerType: {
    label: "Type"
  },
  specificPrices: {
    label: "Prix spécifiques",
    allowMoreField: {
      addLabel: "Ajouter un prix",
      removeLabel: "Retirer un prix",
      removingLabel: "Supprimer"
    }
  },
  requireAdvancePayment: {
    label: "Demande de première facture ?"
  },
  advancePaymentPercentage: {
    label: "Pourcentage première facture"
  }
};

export default partnerAccount;
