const log = {
  content: {
    label: "Content",
  },
  logType: {
    label: "Type",
  },
};

export default log;
