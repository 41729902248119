import React from 'react';

import { useRef } from 'react';

import { ItemTypes } from '../../../DnD/Constant';
import { useDrop } from 'react-dnd';

export default function CartSectionSlot({ order, sectionId, modifyCartItemOrder }) {

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CART_ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragOrder = item.order;
      const dragSection = item.sectionId;
      const hoverOrder = order;
      const hoverSection = sectionId;
      if(dragSection === hoverSection) {
        return;
      }
      // Don't replace items with themselves
      if (dragOrder === hoverOrder && dragSection === hoverSection) {
        return;
      }
      // Time to actually perform the action
      modifyCartItemOrder(dragOrder, dragSection, hoverOrder, hoverSection);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.order = hoverOrder;
      item.sectionId = hoverSection;
    },
  });

  drop(ref);

  return (

    <tbody
      ref={ref}
      data-handler-id={handlerId}
    >
      <tr
        style={{
          height: '10px',
          width: '100%',
          opacity: 1,
        }}
      >
        <td colSpan={1000}/>
      </tr>
    </tbody>
  )
}
