import React from 'react';

import '../../../css/grid.css';

/**
 * TODO
 */
class Box extends React.Component {

  static defaultProps = {
    row: 1,
    column: 1,

    position: 0,
    totalColumn: 8,

    baseSide: 100,
    baseMargin: 0,

    preview: 0,
    previewRatio: 0.2
  }

  constructor(props) {
    super(props);

    this.divEl = React.createRef();

    this.state = {
      width: 0
    }
  }

  componentDidMount() {
    this.setState({
      width: this.divEl.current.clientWidth
    })
  }

  displayPreview() {
    if(this.props.preview) {
      let width = (this.props.baseSide * this.props.column + (this.props.column-1) * 2 * this.props.baseMargin) * this.props.previewRatio;
      let height = (this.props.baseSide * this.props.row + (this.props.row-1) * 2 * this.props.baseMargin) * this.props.previewRatio;
      return (
        <div ref={this.divEl} style={
          {
            width: `${width}px`,
            height: `${height}px`,
            margin: `${this.props.baseMargin * this.props.previewRatio}px`
          }}
          className="grid-box-preview">
          {React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { preview: 1 });
            }
            return child;
          })}
        </div>
      );
    }
  }

  displayDefault() {
    if(!this.props.preview) {
      let width = this.props.baseSide * this.props.column + (this.props.column-1) * 2 * this.props.baseMargin;
      let height = this.props.baseSide * this.props.row + (this.props.row-1) * 2 * this.props.baseMargin;
      let left = (this.props.position % this.props.totalColumn) * (this.props.baseSide + 2 * this.props.baseMargin);
      let top = (Math.floor(this.props.position / this.props.totalColumn)) * (this.props.baseSide + 2 * this.props.baseMargin);
      return (
        <div ref={this.divEl} style={
          {
            position: 'absolute',
            left: `${left}px`,
            top: `${top}px`,
            width: `${width}px`,
            height: `${height}px`,
            margin: `${this.props.baseMargin}px`
          }}
          className="grid-box">
          {this.props.children}
        </div>
      );
    }
  }

  render() {
    return (
      <>
        {this.displayPreview()}
        {this.displayDefault()}
      </>
    )
  }
}

export default Box;
