import React from 'react';

import { useRef } from 'react';

import { useDrag } from 'react-dnd';
import { useDrop } from 'react-dnd';

import { getEmptyImage } from 'react-dnd-html5-backend';

import { ItemTypes } from '../../../DnD/Constant';

export default function DashboardGridWidget({ id, position, title, width, swapBox, children, notifyIsDragging }) {

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.DASHBOARD_GRID_WIDGET,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragPosition = item.position;
      const hoverPosition = position;

      // Don't replace items with themselves
      if (dragPosition === hoverPosition) {
        return;
      }
      // Time to actually perform the action
      let valid = swapBox(item.id, dragPosition, hoverPosition);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      if(valid === true) item.position = hoverPosition;
    },
  });

  const [{ canDrag }, drag, preview] = useDrag({
    type: ItemTypes.DASHBOARD_GRID_WIDGET,
    item: () => {
      return {
        id: id,
        position: position,
        title: title,
        width: width
      };
    },
    collect: (monitor) => ({
      canDrag: monitor.canDrag()
    }),
  });

  preview(getEmptyImage(), { captureDraggingState: true })
  // useEffect(() => {
  //   preview(getEmptyImage(), { captureDraggingState: true })
  // }, [])
  // preview(drop(ref));

  drop(ref);

  notifyIsDragging(!canDrag);

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={{
        height: '100%',
        width: '100%',
      }}>
      <div
        ref={drag}
        style={{
        height: '100%',
        width: '100%',
        cursor: 'move'
        }}>
        {children}
      </div>
    </div>
  );
};
