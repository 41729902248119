import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, InputGroup, Col } from 'react-bootstrap';
import '../../../css/form.css';

/**
 * FormFieldText
 *
 * This class handles HTML form field.
 *
 * The particular field is for Y/N switch.
 *
 * Specific configuration attribute : none
 */
class FormFieldSwitch extends BasicFormField {

  componentDidMount() {
    super.componentDidMount();
    if(this.getValue() === undefined && this.props.defaultValue !== undefined) {
      this.onChange(this.props.defaultValue)
    }
  }

 getValue() {
   if(!this.props.value) {
     return false;
   }
   return this.props.value;
 }

 getFinalValue() {
   return this.getValue();
 }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12`}>
          <InputGroup.Prepend
            className="d-flex col container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          <InputGroup.Prepend
            className={`d-flex col-auto container-form-prepend container-form-switch ${this.props.disabled?"container-form-switch-disabled":""}`}>
            <Form.Check
              type="switch"
              id={`form-${this.props.formKey}-${this.props.fieldKey}-${this.props.disabled}`}
              label=""
              bsCustomPrefix="nk-switch"
              disabled={this.props.disabled || this.props.submitting}
              checked={this.getValue()}
              onChange={(event) => this.onChange(event.target.checked)}
            />
          </InputGroup.Prepend>
        </InputGroup>
      </Col>
    );
  }
}

export default FormFieldSwitch;
