import React from 'react';

import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import {
  index,
  getFromId,
  getPlacesFromId,
  getAccountsFromId,
  getAccountingFromId,
  getBankInformationFromId,
  getSpecificPricesFromId,
  editFromId,
  // editPlacesFromId,
  editAccountsFromId,
  editAccountingFromId,
  editBankInformationFromId,
  editSpecificPricesFromId,
  newItem,
  changePaymentFailure
} from '../../API/Partners';
import {
  form,
  placesForm,
  accountsForm,
  accountingForm,
  bankInformationForm,
  specificPricesForm,
  partnerAccountChoiceForm,
  newForm
} from '../Form/partners';
import { form as searchForm } from '../Form/Search/partners';

import {
  partners as routes,
  users as usersRoutes
} from '../Route/routes';
import { routes as mainRoutes } from '../Route/main';

import {
  FLASHBAG_TYPE_SUCCESS,
  FLASHBAG_TYPE_DANGER
} from '../../Store/Action/flashbag';

export const conf = {
  index: {
    id: "partner",
    pageTitle: strings.common.homepage.partners,
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_PARTNER_CREATE'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name;
      }
      if(search.partnerNumber) {
        result["partner_number"] = search.partnerNumber;
      }
      if(search.siret) {
        result["siret"] = search.siret;
      }
      if(search.legalForm) {
        result["legal_form"] = search.legalForm;
      }
      if(search.prospect === true) {
        result["prospect"] = 1
      }
      if(search.active === false) {
        result["active"] = 0
      }
      if(typeof search.partnerType === 'object') {
        result["partner_type_id"] = search.partnerType.id
      }
      if(search.waitingTicketBilling === true) {
        result["waiting_ticket_billing"] = 1
      }
      return result;
    },

    availableItemDisplay:['line', 'card', 'tab'],
    itemDisplay: {
      line: {
        label: (item) => `${item["partner_number"]} (${item["prospect"]?"Prospect":"Client"}) > ${item["legal_form"]} ${item["name"]}`
      },
      card: {
        firstLine: (item) => item["name"],
        secondLine: (item) => item["legal_form"],
        thirdLine: (item) => `${item["partner_number"]} (${item["prospect"]?"Prospect":"Client"})`,
        fourthLine: (item) => '',
        img: (item) => null,
      },
      tab: {
        headers: [
          {
            label: strings.partner.index.tab.headers.number,
            value: "partner_number"
          },
          {
            label: strings.partner.index.tab.headers.name,
            value: "name"
          },
          {
            label: strings.partner.index.tab.headers.legalForm,
            value: "legal_form"
          },
          {
            label: strings.partner.index.tab.headers.status,
            value: (item) => item["prospect"]?"Prospect":"Client"
          },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: (item) => []
  },
  show: {
    pageTitle: strings.common.homepage.partners,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => null,
      placeholderTitle: (item) => item.name.split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item.name,
      additionalData1: (item) => {
        if(item && item["partner_referrer"] && item["partner_referrer"]["id"]) {
          let state = store.getState();
          if(state && state.persisted.credentials.roles.includes('ROLE_USER_INDEX')) {
            return <a href={usersRoutes.routes.show.createPath(item["partner_referrer"]["id"])}>{item["partner_referrer"]["displayname"]}</a>
          }
          else {
            return item["partner_referrer"]["displayname"]
          }
        }
        return '';
      },
      additionalData2: (item) => item && `${item["partner_number"]}${item["payment_failure"]?" - Partenaire en défaut de paiement":""}`,
      additionalData3: (item) => item && item["places_count"]?`${item["places_count"]} site${item["places_count"] > 1?'s':''}`:"",
    },

    menu: (item) => {
      let buttons = [];

      if(item["payment_failure"] === false) {
        buttons.push({
          id: 'updatePaymentFailure',
          type: 'request',
          label: strings.partner.tabs.buttons.updatePaymentFailure.labelActivate,
          auth: 'ROLE_PARTNER_ACCOUNTING_PARTNER_EDIT',
          variant: "my-danger",
          alwaysDisplay: true,
          request: () => changePaymentFailure(item["id"], true),
          postRequestCallback: [
            {
              type: "flashbag",
              message: strings.partner.tabs.buttons.updatePaymentFailure.flashbagMessage,
              flashbagType: FLASHBAG_TYPE_SUCCESS,
            },
            {
              type: "updateItem",
              key: "payment_failure",
              value: () => true
            }
          ],
          postRequestCallbackFailure: {
            type: "flashbag",
            message: strings.partner.tabs.buttons.updatePaymentFailure.flashbagMessageFailure,
            flashbagType: FLASHBAG_TYPE_DANGER,
          },
        })
      }
      else if(item["payment_failure"] === true) {
        buttons.push({
          id: 'updatePaymentFailure',
          type: 'request',
          label: strings.partner.tabs.buttons.updatePaymentFailure.labelDeactivate,
          auth: 'ROLE_PARTNER_ACCOUNTING_PARTNER_EDIT',
          variant: "my-danger",
          alwaysDisplay: true,
          request: () => changePaymentFailure(item["id"], false),
          postRequestCallback: [
            {
              type: "flashbag",
              message: strings.partner.tabs.buttons.updatePaymentFailure.flashbagMessage,
              flashbagType: FLASHBAG_TYPE_SUCCESS,
            },
            {
              type: "updateItem",
              key: "payment_failure",
              value: () => false
            }
          ],
          postRequestCallbackFailure: {
            type: "flashbag",
            message: strings.partner.tabs.buttons.updatePaymentFailure.flashbagMessageFailure,
            flashbagType: FLASHBAG_TYPE_DANGER,
          },
        })
      }

      buttons.push({
        id: 'billCreator',
        label: strings.partner.tabs.buttons.billCreator,
        type: 'modal',
        modalData: () => {
          return {
            title: strings.partner.tabs.buttons.billCreatorModal.title,
            form: partnerAccountChoiceForm,
            successButton: strings.partner.tabs.buttons.billCreatorModal.successButton,
            successButtonOnClick: (item) => {
              if(item["partner_account"] && item["partner_account"]["id"]) {
                return {
                  redirection: mainRoutes.billCreator.createPath(item["partner_account"]["id"]),
                }
              }
              return {
                error: strings.partner.tabs.buttons.billCreatorModal.error
              }
            },
          }
        },
        auth: ['ROLE_ACCOUNTING_BILL_CREATE']
      });

      buttons.push({
        id: 'creditNoteCreator',
        label: strings.partner.tabs.buttons.creditNoteCreator,
        type: 'modal',
        modalData: () => {
          return {
            title: strings.partner.tabs.buttons.creditNoteCreatorModal.title,
            form: partnerAccountChoiceForm,
            successButton: strings.partner.tabs.buttons.creditNoteCreatorModal.successButton,
            successButtonOnClick: (item) => {
              if(item["partner_account"] && item["partner_account"]["id"]) {
                return {
                  redirection: mainRoutes.creditNoteCreator.createPath(item["partner_account"]["id"]),
                }
              }
              return {
                error: strings.partner.tabs.buttons.creditNoteCreatorModal.error
              }
            },
          }
        },
        auth: ['ROLE_ACCOUNTING_BILL_CREATE']
      });

      return buttons;
    },

    tabs: {
      main: {
        title: strings.partner.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: ['ROLE_NYUKOM']
      },
      places: {
        title: strings.partner.tab.places,
        type: 'form',
        form: placesForm,

        onUpdateItem: (data) => {},

        apiGet: getPlacesFromId,

        auth: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: false
      },
      accounts: {
        title: strings.partner.tab.accounts,
        type: 'form',
        form: accountsForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getAccountsFromId,
        apiEdit: editAccountsFromId,

        role: ['ROLE_USER', 'ROLE_NYUKOM'],
        editable: ['ROLE_PARTNER_ACCOUNTS_EDIT']
      },
      bankInformation: {
        title: strings.partner.tab.bankInformation,
        type: 'form',
        form: bankInformationForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'bankInformation'
        },

        apiGet: getBankInformationFromId,
        apiEdit: editBankInformationFromId,

        role: ['ROLE_PARTNER_BANK_INFORMATION'],
        editable: ['ROLE_PARTNER_BANK_INFORMATION_EDIT']
      },
      accounting: {
        title: strings.partner.tab.accounting,
        type: 'form',
        form: accountingForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'accounting'
        },

        apiGet: getAccountingFromId,
        apiEdit: editAccountingFromId,

        role: ['ROLE_PARTNER_ACCOUNTING_PARTNER'],
        editable: ['ROLE_PARTNER_ACCOUNTING_PARTNER_EDIT']
      },
      specificPrices: {
        title: strings.partner.tab.specificPrices,
        type: 'form',
        form: specificPricesForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'specificPrices'
        },

        apiGet: getSpecificPricesFromId,
        apiEdit: editSpecificPricesFromId,

        role: ['ROLE_PARTNER_SPECIFIC_PRICES'],
        editable: ['ROLE_PARTNER_SPECIFIC_PRICES_EDIT']
      },
    }
  },
  new: {
    pageTitle: strings.partner.new.title,

    api: newItem,

    type: 'process',
    // empty: {},
    // form: newForm,
    processId: 'newPartner',
    steps: [
      {
        id: "_general_data",
        label: "Informations Générales",
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['name', 'legal_form', 'siret', 'vat_intra', 'prospect'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {
          console.log(routes);
        }
      },
      {
        id: "_main_place",
        label: "Site principal",
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['main_place', 'billing_place'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
      {
        id: "_others_places",
        label: "Autre(s) site(s)",
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['places'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
      {
        id: "_accounts",
        label: "Compte(s)",
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['accounts'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      }
    ],

    onSubmit: (partner) => {
      partner.main_place._sid = 1
      if(partner.places === undefined) {
        partner.places = [];
      }
      partner.places.push(partner.main_place);
      if(partner.billing_place === undefined || partner.billing_place.name === null || partner.billing_place.name === '') {
        partner.billing_place = partner.main_place;
      }
      else {
        partner.billing_place._sid = 2
        partner.places.push(partner.billing_place);
      }
    },

    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
