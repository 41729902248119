import React from 'react';

import { Form } from 'react-bootstrap';

import { v4 as uuidv4 } from 'uuid';

import TableCell from '../../../Table/Body/TableCell';

/**
 * SwitchCell
 */
class SwitchCell extends TableCell {

  constructor(props) {
    super(props);

    this.switchId = uuidv4();
  }

  getPlaceholder() {
    if(this.props.additionalConfiguration.placeholder) {
      return this.props.additionalConfiguration.placeholder;
    }
    return null;
  }

  getValue() {
    return this.props.value;
  }

  onChange(event) {
    this.props.additionalConfiguration.onChange(this.props._rowId, this.props._valueId, event.target.checked, this.props.data);
  }

  displayValue() {
    return <Form.Check
      id={`cell-switch-${this.switchId}`}
      type="switch"
      label=""
      disabled={!this.props.additionalConfiguration.editable}
      checked={this.getValue()}
      onChange={(e) => this.onChange(e)}
    />
  }

  displayInnerCell() {
    return <div className="cell-container cell-container-string">{this.displayValue()}</div>;
  }
}

export default SwitchCell;
