const dependency = {
  name: {
    label: "Nom de la liaison",
    invalid: {
      assertEmpty: "Ce nom ne doit pas être vide."
    },
  },
  displayname: {
    label: "Affichage de la liaison",
    invalid: {
      assertEmpty: "Ce nom ne doit pas être vide."
    },
  },
  mandatory: {
    label: "La partie accessoire est-il obligatoire ?",
  },
  reverseMandatory: {
    label: "Le partie principale est-il obligatoire ?",
  },
  amendmentMandatory: {
    label: "Le partie accessoire est-il obligatoire lors d'un avenant ?",
  },
  mergePriceInMaster: {
    label: "Fusionner les prix dans le parent"
  },
  quantity: {
    label: "Quantité",
    options: {
      option0: "1 pour X",
      option1: "1 pour 1"
    }
  },
  productMasters: {
    label: "Produit principal",
    allowMoreField: {
      addLabel: "Ajouter un produit",
      removeLabel: "Enlever un produit",
      removingLabel: "Supprimer"
    }
  },
  productSlaves: {
    label: "Accesoires",
    allowMoreField: {
      addLabel: "Ajouter un produit",
      removeLabel: "Enlever un produit",
      removingLabel: "Supprimer"
    }
  },
  dependency: {
    label: "Dépendance"
  }
};

export default dependency;
