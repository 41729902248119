const ticketForm = {
  tab: {
    main: "Formulaire",
  },
  search: {
    name: "Nom"
  },
  new: {
    title: "Nouveau formulaire"
  }
};

export default ticketForm;
