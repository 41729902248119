import React from 'react';

/**
 * TableRow
 */
class TableRowCollapsedElement extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <></>
    );
  }
}

export default TableRowCollapsedElement;
