import strings from '../../Localization/Localization';

import { fetchPartnerTypes } from '../../API/PartnerTypes';

import { form as _specificPrice } from './specificPrice';

let partnerType = {
  fieldKey: "partner_type",
  type: "dropdownEntity",
  label: strings.form.partnerAccount.partnerType.label,

  validators: [],

  showEmpty: true,
  storage: 'redux',
  shareStorage: true,
  loader: {
    id: "partnerTypes",
    loader: fetchPartnerTypes,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let specificPrices = {
  fieldKey: "specific_prices",
  type: "array",
  label: strings.form.partnerAccount.specificPrices.label,

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.partnerAccount.specificPrices.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.partnerAccount.specificPrices.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.partnerAccount.specificPrices.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: strings.form.partnerAccount.specificPrices.label,

    subForm: _specificPrice,

    validators: [],
  },

  validators: []
}

let requireAdvancePayment = {
  fieldKey: "require_advance_payment",
  type: "switch",
  label: strings.form.partnerAccount.requireAdvancePayment.label,
  validators: []
}

let advancePaymentPercentage = {
  fieldKey: "advance_payment_percentage",
  type: "text",
  label: strings.form.partnerAccount.advancePaymentPercentage.label,
  defaultValue: null,
  validators: []
}

export const form = {
  formKey: "accounts",
  fields: [
    partnerType
  ]
}

export const accountingForm = {
  formKey: "accounts",
  fields: [
    Object.assign({}, partnerType, {
      disabled: () => true
    }),
    requireAdvancePayment,
    advancePaymentPercentage
  ]
}

export const specificPricesForm = {
  formKey: "accounts",
  fields: [
    Object.assign({}, partnerType, {
      disabled: () => true
    }),
    specificPrices
  ]
}
