import strings from '../../../Localization/Localization';

import { v4 as uuid } from 'uuid';

import { fetchPartners } from '../../../API/Partners';
import { fetchPlaces } from '../../../API/Places';
import { fetchUsers } from '../../../API/Users';

import { ParameterTypes } from '../../../Dashboard/Constant';

export const parameter = {
  id: "parameter",
  name: strings.dashboard.widget.parameter.name,
  icon: "icon-view-list",
  column: {
    min: 1,
    max: 2
  },
  row: {
    min: 1,
    max: 2
  },
  additionalData: {
    partner: {
      index: fetchPartners
    },
    place: {
      index: fetchPlaces
    },
    user: {
      index: fetchUsers
    }
  },
  elementSettings: {},
  visualSettings: [
    {
      fieldKey: "display",
      label: "Affichage",
      type: "dropdown",

      validators: [
        {
          id: 'assertEmpty',
          type: 'notEmptyOrNull',
          invalidFeedback: strings.form.ticket.criticity.invalid.assertEmpty,
          defaultValue: false
        },
      ],

      showEmpty: true,
      input: 'options',
      options: [
        {
          value: "display1",
          label: "Display 1"
        },
      ],
    }
  ],
  dataSettings: [
    {
      fieldKey: "parameters",
      type: "array",
      label: "",
      validators: [],

      defaultNumberOfField: 4,
      allowMoreField: false,

      subField: {
        type: "subform",
        label: "Paramètre #key#",

        validators: [],

        subForm: {
          formKey: "parameters",
          fields: [
            {
              fieldKey: "id",
              type: "hidden",
              label: "",
              showOnDisabled: () => false,
              validators: [],
              defaultValue: (getValues) => {
                let values = getValues(0)
                if(values.id) {
                  return values.id
                }
                return uuid()
              }
            },
            {
              fieldKey: "title",
              type: "text",
              label: "Titre",
              showOnDisabled: () => false,
              validators: []
            },
            {
              fieldKey: "subType",
              type: "dropdown",
              label: "Type",
              showOnDisabled: () => false,
              validators: [],

              showEmpty: true,
              input: 'options',
              options: [
                {
                  value: ParameterTypes.STRING,
                  label: "Texte"
                },
                {
                  value: ParameterTypes.DATETIME,
                  label: "Date"
                },
                {
                  value: ParameterTypes.PARTNER,
                  label: "Partenaire"
                },
                {
                  value: ParameterTypes.PLACE,
                  label: "Site"
                },
                {
                  value: ParameterTypes.USER,
                  label: "Utilisateur"
                },
              ],
            }
          ]
        }
      },
    }
  ]
}
