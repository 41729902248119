import { TOGGLE_MENU } from '../Action/collapseMenu'

const initialState = {
  isCollapse: false,
}

export default function reduce(state = initialState, action){

  let newState

  switch (action.type) {
    case TOGGLE_MENU:
      newState = Object.assign({}, {
        isCollapse: !state.isCollapse,
      })
      break;
    default:
      newState = state;
  }

  return newState;
}
