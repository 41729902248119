import React from 'react';

import WidgetTitle from '../../../Dashboard/Widget/WidgetTitle'

const baseStyles = {
  display: 'inline-block',
  width: '100px',
  height: '100px'
}

export const DashboardWidget = (props) => {

  let styles = baseStyles;
  if(props.width) {
    styles = Object.assign({}, baseStyles, {
      width: props.width
    })
  }

  return (
    <div style={styles}>
      <WidgetTitle title={props.title}/>
    </div>
  )
}
