import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getIdentificationFromId,
  getConfigurationFromId,
  getConfigurationEditFromId,
  getAccountingFromId,
  editFromId,
  editConfigurationFromId,
  editAccountingFromId,
  newItem
} from '../../API/ProductFamilies';
import { form, configurationForm, accountingForm, newForm } from '../Form/productFamilies';
import { form as searchForm } from '../Form/Search/productFamilies';

import { productFamilies as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.productFamilies,

    id: "productFamily",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_MARKETING_PRODUCT_FAMILY_CREATE'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.name) {
        result["name"] = search.name;
      }
      if(search.activeOrder === true) {
        result["active_order"] = 1
      }
      else if(search.activeOrder === false) {
        result["active_order"] = 0
      }
      if(search.activeQuote === true) {
        result["active_quote"] = 1
      }
      else if(search.activeQuote === false) {
        result["active_quote"] = 0
      }
      if(search.activeTicket === true) {
        result["active_ticket"] = 1
      }
      else if(search.activeTicket === false) {
        result["active_ticket"] = 0
      }
      if(search.rentable === true) {
        result["rentable"] = 1
      }
      else if(search.rentable === false) {
        result["rentable"] = 0
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      line: {
        label: (item) => item["name"]
      },
      tab: {
        headers: [
          {
            label: "Nom",
            value: "name"
          },
          {
            label: "Commande",
            value: (item) => item["active_order"]?"oui":"non"
          },
          {
            label: "Devis",
            value: (item) => item["active_quote"]?"oui":"non"
          },
          {
            label: "Ticket",
            value: (item) => item["active_ticket"]?"oui":"non"
          },
        ]
      }
    },
    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.productFamilies,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "name",

    apiGet: getIdentificationFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => null,
      placeholderTitle: (item) => item.name.split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item.name,
      additionalData1: (item) => '',
      additionalData2: (item) => ''
    },

    onUpdateItem: (data) => {},

    tabs: {
      main: {
        title: strings.productFamily.tab.show,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_NYUKOM'],
        editable: ['ROLE_MARKETING_PRODUCT_FAMILY_EDIT']
      },
      configuration: {
        title: strings.productFamily.tab.configuration,
        type: 'form',
        form: configurationForm,

        onUpdateItem: (data) => {},

        apiGet: getConfigurationFromId,

        role: ['ROLE_MARKETING_PRODUCT_FAMILY_CONFIGURATION'],
        editable: false
      },
      configurationEdit: {
        title: strings.productFamily.tab.configuration,
        type: 'form',
        form: configurationForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'configuration'
        },

        apiGet: getConfigurationEditFromId,
        apiEdit: editConfigurationFromId,

        role: ['ROLE_MARKETING_PRODUCT_FAMILY_CONFIGURATION_EDIT'],
        editable: true,
      },
      accounting: {
        title: strings.productFamily.tab.accounting,
        type: 'form',
        form: accountingForm,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'accounting'
        },

        apiGet: getAccountingFromId,
        apiEdit: editAccountingFromId,

        role: ['ROLE_ACCOUNTING_PRODUCT_FAMILY_ACCOUNTING'],
        editable: ['ROLE_ACCOUNTING_PRODUCT_FAMILY_ACCOUNTING_EDIT']
      },
    }
  },
  new: {
    pageTitle: strings.productFamily.new.title,

    api: newItem,
    empty: {},
    form: newForm,
    onSubmit: (item) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
