import React from 'react';

import '../../../css/notification.css';

import { markAsSeen, markAsUnseen } from '../../../API/Notifications';

class BasicNotification extends React.Component {

  dateRegex = /(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<day>[0-9]{2}) (?<hour>[0-9]{2}):(?<minute>[0-9]{2})/;

  constructor(props) {
    super(props)

    this.state = {
      seen: props.item.seen
    }
  }

  onClickSeen = () => {
    if(this.state.seen) {
      markAsUnseen(this.props.item.id).then(() => {
        this.setState({
          seen: false
        })
      })
    }
    else {
      markAsSeen(this.props.item.id).then(() => {
        this.setState({
          seen: true
        })
      })
    }
  }

  displayIcon() {
    return (
      <div className="notification-basic-icon"><i className="icon-message"></i></div>
    )
  }

  displayMarkAsSeen() {
    return (
      <span onClick={this.onClickSeen} className="notification-basic-read"><i className={`icon-mail${this.state.seen?"":"-read"}`}></i></span>
    )
  }

  displayMessage() {
    return (
      <>{this.props.item._arguments.message}</>
    )
  }

  render() {

    let date = this.props.item.date.date;
    // eslint-disable-next-line no-unused-vars
    let [match, year, month, day, hour, minute] = this.dateRegex.exec(date);

    return (
      <div className="notification-container">
        <div className="d-flex justify-content-between notification-basic-container">
          <div className="d-flex flex-row">
            {this.displayIcon()}
            <div className="notification-basic-message">
              <div>{this.displayMessage()}</div>
              <div className="d-flex notification-basic-sign">
                <i>{this.props.item.sender.displayname} - {`${hour}:${minute} ${day}/${month}/${year}`}</i>
              </div>
            </div>
          </div>
          {this.displayMarkAsSeen()}
        </div>
      </div>
    );
  }
}

export default BasicNotification;
