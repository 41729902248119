const profil = {
  tab: {
    main: "Profil",
  },
  search: {
    label: "Label",
    active: "Actif",
    group: "Groupe",
    role: "ID d'un rôle"
  },
  new: {
    title: "Nouveau profil"
  }
};

export default profil;
