import { withModalHandler } from '../../HOC/ModalHandler';

import WidgetColor from './Widget/WidgetColor';
import WidgetTitle from './Widget/WidgetTitle';

import WidgetParameter from './Widget/WidgetParameter';

import WidgetList from './Widget/WidgetList';
import WidgetListStat from './Widget/WidgetListStat';
import WidgetObjective from './Widget/WidgetObjective';

import WidgetStateMachine from './Widget/WidgetStateMachine';

import WidgetSupervision from './Widget/WidgetSupervision';

const Color = WidgetColor;
const Title = WidgetTitle;

const Parameter = WidgetParameter;

const List = withModalHandler(WidgetList);
const ListStat = withModalHandler(WidgetListStat);
const Objective = withModalHandler(WidgetObjective);

const StateMachine = withModalHandler(WidgetStateMachine);

const Supervision = withModalHandler(WidgetSupervision);

export {
  Title,
  Color,

  Parameter,

  List,
  ListStat,
  Objective,

  StateMachine,

  Supervision
};
