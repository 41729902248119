const constructor = {
  tab: {
    main: "Constructeur",
  },
  new: {
    title: "Nouveau constructeur"
  }
};

export default constructor;
