import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, InputGroup, Button, Col, Collapse } from 'react-bootstrap';
import '../../../css/form.css';

import NotEmptyOrNullAssert from '../../../Form/Assert/NotEmptyOrNullAssert';

/**
 * FormFieldDropdownMulti
 *
 * This class handles HTML form field.
 *
 * The particular field is a Dropdown, it allow to choose multiple element from
 * a list.
 *
 * Specific configuration attribute :
 * - adapter : an adapter in case the options are objects
 * - filterOptions : inherit from FormFieldDropdown
 *
 * - input : the type of input either 'options' or 'formValues'
 *         if 'options', options need to be filled
 *         if 'formValues', compute need to be filled
 *
 * - options : the list of options in the dropdown represented by an array
 *           each object inside the array should have both a label and a value
 *
 * - compute : compute the array of options from the others values of the form
 *
 * - finalValues : determine how the final values are sent
 *              if 'values', return an array with the values
 *              if 'labels', return an array with the labels
 *              if 'objects', return an array with the objects, only useful when
 *                          if there is an array adapter
 */
class FormFieldDropdownMulti extends BasicFormField {

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    autoComplete: false,
    alwaysOpen: false,
    input: 'options',
    finalValues: 'values', // or labels or objects
    options: []
  }

  constructor(props) {
    super(props);

    //the state of the component
    //options are the options coming from the call
    this.state = Object.assign(this.state, {
      options: [],

      selected: {},

      collapse: true,
    })
  }

  static getDerivedStateFromProps(props, state) {
    if(props.value && Array.isArray(props.value)) {
      let selected = {};
      props.value.forEach((item, i) => {
        let {value} = FormFieldDropdownMulti.applyAdapter(props, item);
        if(value) {
          selected[value] = true
        }
        else {
          selected[item] = true
        }
      });
      return{
        selected: selected
      };
    }
    else if(props.value && typeof props.value === "object") {
      return{
        selected: props.value
      };
    }
    return null;
  }

  /**
   * @inheritdoc
   */
  getSpecificAsserts() {
    return {
      notEmptyOrNull: NotEmptyOrNullAssert,
    }
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    let results = this.getFilteredOptions().filter((item) => {
      let {value} = this.applyAdapter(item);
      return Object.keys(this.state.selected).includes(value) && this.state.selected[value] === true;
    });
    switch (this.props.finalValues) {
      case 'values':
        return results.map((item) => {
          let {value} = this.applyAdapter(item);
          return value;
        });
      case 'labels':
        return results.map((item) => {
          let {label} = this.applyAdapter(item);
          return label;
        });
      case 'objects':
      default:
        return results;
    }
  }

  /**
   * get the value of the options
   */
  getOptions() {
    //check for input type
    if(this.props.input === 'options' && this.props.options) {
      return this.props.options;
    }
    else if(this.props.input === 'formValues') {
      let options = this.props.compute(this.props.getValues, this.props.getExtraValues());
      if(options) {
        return options;
      }
    }
    return [];
  }

  /**
   * get the value of the options with the filter
   */
  getFilteredOptions() {
    if(this.props.filterOptions) {
      return this.getOptions().filter((item, i) => {
        return this.props.filterOptions(item, this.props.getValues, this.props.getExtraValues);
      })
    }
    return this.getOptions();
  }

  static applyAdapter(props, option) {
    if(props.adapter && props.autoComplete) {
      return {
        value: 'value('+props.adapter.getValue(option)+')',
        label: props.adapter.getLabel(option)
      }
    }
    else if(props.adapter && !props.autoComplete) {
      return {
        value: props.adapter.getValue(option),
        label: props.adapter.getLabel(option)
      }
    }
    else if(option.value !== undefined && option.value !== null && option.label !== undefined  && option.label !== null) {
      return option;
    }
    return {
      value: option,
      label: option
    }
  }

  /**
   * apply the adapter for options
   */
  applyAdapter(option) {
    return FormFieldDropdownMulti.applyAdapter(this.props, option);
  }

  /**
   * Send new value to parent element
   */
  onChange = (value, checked, ignoreValidation = false) => {
    let selected = Object.assign({}, this.state.selected);
    selected[value] = checked;
    super.onChange(selected, ignoreValidation)
  }

  /**
   * Display all options of the field
   */
  displayOptions() {
    return (
      <>
        {this.getFilteredOptions().map((option, i) => {
          let {value, label} = this.applyAdapter(option);
          let checked = this.state.selected[value]?this.state.selected[value]:false;
          return (
            <Form.Check
              key={`${this.props.formKey}-${this.props.fieldKey}-${value}`}
              id={`${this.props.formKey}-${this.props.fieldKey}-${value}`}
              label={label}
              disabled={this.props.disabled || this.props.submitting}
              checked={checked}
              onChange={(event) => this.onChange(value, event.target.checked)}
            />
          )
        })}
      </>
    )
  }

  collapse = () => {
    this.setState({
      collapse: !this.state.collapse
    })
  }

  /**
   * Main render method for React Component
   */
  render() {
    let length = Object.keys(this.state.selected).filter(key => this.state.selected[key] === true).length;
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          <div className="d-flex flex-column col-12 col-md-8 col-border-radius col-md-border-radius form-control" style={{height: "inherit"}}>
            <div className="align-items-center form-disabled">
              {length===0?"Aucune sélection":`${length} option(s) selectionée(s)`}
            </div>
            <Collapse in={this.props.alwaysOpen || !this.state.collapse}>
              <div>
                {this.displayOptions()}
              </div>
            </Collapse>
          </div>
          {this.displayValidating()}
          <InputGroup.Append>
            {!this.props.alwaysOpen?
              <Button
                variant="my-primary-noline"
                onClick={this.collapse}>
                <i className={`icon-${this.state.collapse?"chevron-down":"chevron-up"}`}></i>
              </Button>
              :<></>
            }
          </InputGroup.Append>
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

/**
 * HOC Modal Handler to support call options
 */
export default FormFieldDropdownMulti;
