import strings from '../../../Localization/Localization';

let title = {
  fieldKey: "title",
  type: "text",
  label: strings.form.preFillComment.title.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "preFillCommentSearch",
  fields: [
    title
  ]
}
