import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of analytic accounts based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('commercial_bonus_thresholds', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single analytic account based on his ID
 *
 * @param id the of the account requested
 */
export function getFromId(id) {
  return baseGetFromId('commercial_bonus_thresholds', id);
}

/**
 * Get a single analytic account based on his ID
 *
 * @param id the of the analytic account requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('commercial_bonus_thresholds', id, 'identification');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

 /**
  * Edit a single pre fill comment based on his ID
  *
  * @param id the of the pre fill comment requested
  * @param data the new data of the pre fill comment
  */
 export function editFromId(id, data) {
   return baseEditFromId('commercial_bonus_thresholds', id, data);
 }

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

 /**
  * Create a new pre fill comment
  *
  * @param data the new data of the partner
  */
 export function newItem(data) {
   return baseNewItem('commercial_bonus_thresholds', data);
 }

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/
