import strings from '../../Localization/Localization';

import { store } from '../../Store/configureStore';

import { processTimeframe } from './common';

import {
  resetCartAction,
  copyQuoteInformationAction,
  copyQuoteItemsAction
} from '../../Store/Action/cart'

import React from 'react';
import YousignQuote from '../../Component/Yousign/Holder/YousignQuote';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  index,
  getFromId,
  getIdentificationFromId,
  getCartFromId,
  getOrderFromId,
  getDuplicateFromId,
  getCopyFromId,
  // editFromId,
  sendNewNotification,
} from '../../API/Quotes';
import {
  form,
  cartForm,
  orderForm
} from '../Form/quotes';
import { form as searchForm } from '../Form/Search/quotes';

import {
  quotes as routes,
  carts as cartRoutes
} from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.quotes,

    id: "quote",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.title) {
        result["title"] = search.title;
      }
      if(search.number) {
        result["number"] = search.number;
      }
      if(search.orderNumber) {
        result["order_number"] = search.orderNumber;
      }
      if(typeof search.state === 'object' && search.state.length > 0) {
        result["state"] = search.state.map((item) => item.value).toString();
      }
      if(search.validated === true) {
        result["validated"] = 1;
      }
      else if(search.validated === false) {
        result["validated"] = 0;
      }
      if(typeof search.client === 'object') {
        result["partner_id"] = search.client.id
      }
      if(typeof search.placeDelivery === 'object') {
        result["place_delivery_id"] = search.placeDelivery.id
      }
      if(typeof search.clientUser === 'object') {
        result["client_user_id"] = search.clientUser.id
      }
      if(typeof search.mainUser === 'object') {
        result["main_user_id"] = search.mainUser.id
      }
      if(search.signatureDateTimeframe) {
        let signatureDateTimeframe = processTimeframe(search.signatureDateTimeframe);

        result["signature_date_min"] = signatureDateTimeframe.min;
        result["signature_date_max"] = signatureDateTimeframe.max;
      }
      if(search.signatureDateMin) {
        result["signature_date_min"] = search.signatureDateMin;
      }
      if(search.signatureDateMax) {
        result["signature_date_max"] = search.signatureDateMax;
      }
      if(search.createdAtTimeframe) {
        let createdAtTimeframe = processTimeframe(search.createdAtTimeframe);

        result["created_at_min"] = createdAtTimeframe.min;
        result["created_at_max"] = createdAtTimeframe.max;
      }
      if(search.createdAtMin) {
        result["created_at_min"] = search.createdAtMin;
      }
      if(search.createdAtMax) {
        result["created_at_max"] = search.createdAtMax;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        enableHeaderEdit: true,
        defaultHeader: [0,1,2,3,4,5],
        headers: [
          {
            id: "state",
            label: <>&nbsp;&nbsp;<i className="icon-flag-1"/>&nbsp;&nbsp;</>,
            value: item => {
              switch (item['cart']['state']) {
                case 'q_w_agreement':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.quote.waitingAgreement}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-doc"/></div>
                    </OverlayTrigger>
                  );
                case 'q_w_validation':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.quote.waitingValidation}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-info-icon"><i className="icon-clock"/></div>
                    </OverlayTrigger>
                  );
                case 'q_validated':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.quote.validated}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-validated-icon"><i className="icon-doc"/></div>
                    </OverlayTrigger>
                  );
                case 'q_canceled':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.quote.canceled}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-danger-icon"><i className="icon-document-delete"/></div>
                    </OverlayTrigger>
                  );
                case 'q_w_yousign':
                  return (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip>{strings.form.cart.state.options.quote.yousign}</Tooltip>
                      }>
                      <div className="item-index-status item-index-status-warning-icon"><i className="icon-document-edit"/></div>
                    </OverlayTrigger>
                  );
                default:
                  return item["cart"]['state'];
              }

            }
          },
          {
            id: "number",
            label: "Numéro",
            value: "number"
          },
          {
            id: "title",
            label: "Titre",
            value: item => item["cart"]["title"]
          },
          {
            id: "client",
            label: "Nom du partenaire",
            value: item => item["cart"]["client"]["partner"]["name"]
          },
          {
            id: "createdAt",
            label: "Date d'émission",
            value: item => item["cart"]["created_at"]
          },
          {
            id: "mail",
            label: "@mail",
            value: item => item["cart"]["client_referrer"]["mail"]
          },
          {
            id: "place",
            label: "Site de livraison",
            value: item => item["cart"]["place"]["name"]
          },
          // {
          //   label: "Montant TTC",
          //   value: item => item["cart"][""]
          // },
          // {
          //   label: "Statut",
          //   value: item => {
          //     if(item.locked) {
          //       return strings.form.cart.state.options.quote.locked;
          //     }
          //     switch (item["cart"]["state"]) {
          //       case 'q_w_agreement':
          //         return strings.form.cart.state.options.quote.waitingAgreement;
          //       case 'q_w_validation':
          //         return strings.form.cart.state.options.quote.waitingValidation;
          //       case 'q_validated':
          //         return strings.form.cart.state.options.quote.validated;
          //       case 'q_canceled':
          //         return strings.form.cart.state.options.quote.canceled;
          //       default:
          //     }
          //   }
          // },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: (item) => {
      return [];
    }
  },
  show: {
    pageTitle: strings.common.homepage.quotes,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "number",

    apiGet: getIdentificationFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => null,
      placeholderTitle: (item) => item["cart"]["client"]["partner"]["name"].split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item["cart"]["client"]["partner"]["name"] + " > " +  item["cart"]["place"]["name"],
      additionalData1: (item) => {
        let state = null;
        if(item.locked) {
          state = strings.form.cart.state.options.quote.locked
        }
        else {
          switch (item["cart"]["state"]) {
            case 'q_w_agreement':
              state = strings.form.cart.state.options.quote.waitingAgreement
              break;
            case 'q_w_validation':
              state = strings.form.cart.state.options.quote.waitingValidation
              break;
            case 'q_validated':
              state = strings.form.cart.state.options.quote.validated
              break;
            case 'q_canceled':
              state = strings.form.cart.state.options.quote.canceled
              break;
            case 'q_w_yousign':
              state = strings.form.cart.state.options.quote.yousign
              break;
            default:
          }
        }
        return `${item?item["number"]:''}${state?` - ${state}`:''}`
      },
      additionalData2: (item) => item["cart"]["created_at"],
      additionalData3: (item) => '',
    },

    menu: (item) => {
      let buttons = [];
      if(item.cart.state === 'q_w_agreement') {
        buttons.push({
          id: 'sendNewNotification',
          type: 'request',
          auth: 'ROLE_NYUKOM', // TODO voir sil faut changer
          request: sendNewNotification,
          label: strings.quote.tabs.buttons.notification,
          variant: "my-information"
        });
        buttons.push({
          id: 'addingAgreement',
          type: 'redirection',
          auth: 'ROLE_QUOTE_ADDING_AGREEMENT',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'addingAgreement'),
          label: strings.quote.tabs.buttons.addingAgreement,
          variant: "my-validated",
          alwaysDisplay: true,
        });
        buttons.push({
          id: 'signWithYousignQuote',
          type: 'redirection',
          auth: (button, item, credentials) => item && credentials && item["cart"]["client_referrer"]["id"] === credentials["id"],
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'signWithYousignQuote'),
          addVerification: true,
          label: strings.quote.tabs.buttons.signWithYousign,
          variant: "my-validated",
          alwaysDisplay: true,
        });
        buttons.push({
          id: 'cancelQuote',
          type: 'redirection',
          auth: 'ROLE_QUOTE_CANCEL_QUOTE',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'cancelQuote'),
          addVerification: true,
          label: strings.quote.tabs.buttons.cancelQuote,
          variant: "my-warning"
        });
      }
      else if(item.cart.state === 'q_w_yousign') {
        buttons.push({
          id: 'signWithYousignQuote',
          type: 'redirection',
          auth: (button, item, credentials) => item && credentials && item["cart"]["client_referrer"]["id"] === credentials["id"],
          path: routes.routes.yousign.createPath(item.id),
          label: strings.quote.tabs.buttons.sign,
          variant: "my-validated",
          alwaysDisplay: true,
        });
      }
      else if(item.cart.state === 'q_w_validation') {
        buttons.push({
          id: 'validatingAgreement',
          type: 'redirection',
          auth: 'ROLE_QUOTE_VALIDATING_AGREEMENT',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'validatingAgreement'),
          addVerification: true,
          label: strings.quote.tabs.buttons.validatingAgreement,
          variant: "my-validated",
          alwaysDisplay: true,
        });
        buttons.push({
          id: 'invalidatingAgreement',
          type: 'redirection',
          auth: 'ROLE_QUOTE_INVALIDATING_AGREEMENT',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'invalidatingAgreement'),
          addVerification: true,
          label: strings.quote.tabs.buttons.invalidatingAgreement,
          variant: "my-warning"
        });
        buttons.push({
          id: 'cancelQuote',
          type: 'redirection',
          auth: 'ROLE_QUOTE_CANCEL_QUOTE',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'cancelQuote'),
          addVerification: true,
          label: strings.quote.tabs.buttons.cancelQuote,
          variant: "my-warning"
        });
      }
      else if(item.cart.state === 'q_canceled') {
        buttons.push({
          id: 'uncancelQuote',
          type: 'redirection',
          auth: 'ROLE_QUOTE_UNCANCEL_QUOTE',
          path: cartRoutes.routes.transition.createPath(item.cart.id, 'uncancelQuote'),
          addVerification: true,
          label: strings.quote.tabs.buttons.uncancelQuote,
          variant: "my-validated"
        });
      }
      buttons.push({
        id: 'duplicate',
        type: 'request',
        auth: 'ROLE_QUOTE_CREATE',
        request: getDuplicateFromId,
        postRequestCallback: [
          {
            type: "callback",
            callback: (payload) => {
              store.dispatch(resetCartAction());
              store.dispatch(copyQuoteInformationAction(
                payload.data.item.cart.client.partner,
                payload.data.item.cart.client,
                payload.data.item.cart.place,
                payload.data.item.cart.client_referrer,
                payload.data.item.cart.title,
                payload.data.item.cart.description
              ));

              let sections = [];
              payload.data.item.cart["sections"].forEach((section, i) => {
                sections.push(section);
              });

              let cartItems = [];
              payload.data.item.cart["cart_items"].forEach((cartItem, i) => {
                if(cartItem["product"]["active_quote"] === true) {
                  cartItems.push(cartItem);
                }
              });

              store.dispatch(copyQuoteItemsAction(sections, cartItems));
            }
          }
        ],
        addVerification: true,
        label: strings.quote.tabs.buttons.duplicate,
        variant: "my-information"
      });
      if(item["has_amendment"] === false) {
        buttons.push({
          id: 'copy',
          type: 'request',
          auth: 'ROLE_QUOTE_CREATE',
          request: getCopyFromId,
          postRequestCallback: [
            {
              type: "callback",
              callback: (payload) => {
                let sections = [];
                payload.data.item.cart["sections"].forEach((section, i) => {
                  sections.push(section);
                });

                let cartItems = [];
                payload.data.item.cart["cart_items"].forEach((cartItem, i) => {
                  if(cartItem["product"]["active_quote"] === true) {
                    cartItems.push(cartItem);
                  }
                });

                store.dispatch(copyQuoteItemsAction(sections, cartItems));
              }
            }
          ],
          addVerification: true,
          label: strings.quote.tabs.buttons.copy,
          variant: "my-information"
        });
      }


      return buttons;
    },

    tabs: {
      main: {
        title: strings.quote.tab.show,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {
          data["item"]["client_account"] = data["item"]["client"];
          data["item"]["client"] = data["item"]["client_account"]["partner"];
        },

        apiGet: getFromId,
        //apiEdit: editFromId,

        role: ['ROLE_QUOTE'],
        editable: false
      },
      cart: {
        title: strings.quote.tab.cart,
        type: 'form',
        form: cartForm,

        onUpdateItem: (data) => {},

        apiGet: getCartFromId,

        role: ['ROLE_QUOTE'],
        editable: false
      },
      order: {
        title: strings.quote.tab.order,
        type: 'form',
        form: orderForm,

        onUpdateItem: (data) => {},

        apiGet: getOrderFromId,

        shouldDisplay: (item) => item && item["validated"],
        role: ['ROLE_QUOTE'],
        editable: false
      },
    }
  },
  custom: {
    yousign: {
      path: '/yousign/:id',
      createPath: (id) => `/yousign/${id}`,
      type: 'render',
      getRender: (props) => <YousignQuote {...props}/>,
    }
  }
}
