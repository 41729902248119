import strings from '../../../Localization/Localization';

import { fetchFamilies } from '../../../API/ProductFamilies';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.productSubFamily.name.label,
  showOnDisabled: () => false,
  validators: []
}

let activeOrder = {
  fieldKey: "activeOrder",
  type: "triswitch",
  label: strings.form.productSubFamily.activeOrder.label,
  showOnDisabled: () => false,
  validators: []
}

let activeQuote = {
  fieldKey: "activeQuote",
  type: "triswitch",
  label: strings.form.productSubFamily.activeQuote.label,
  showOnDisabled: () => false,
  validators: []
}

let activeTicket = {
  fieldKey: "activeTicket",
  type: "triswitch",
  label: strings.form.productSubFamily.activeTicket.label,
  showOnDisabled: () => false,
  validators: []
}

let rentable = {
  fieldKey: "rentable",
  type: "triswitch",
  label: strings.form.productSubFamily.rentable.label,
  showOnDisabled: () => false,
  validators: []
}

let productFamily = {
  fieldKey: "productFamily",
  label: strings.form.productSubFamily.productFamily.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  loader: {
    id: "productFamilies",
    loader: fetchFamilies,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

export const form = {
  formKey: "productSubFamilySearch",
  fields: [
    productFamily,
    name,
    activeOrder,
    activeQuote,
    activeTicket,
    rentable
  ]
}
