import strings from '../../../Localization/Localization';

import { fetchPartners } from '../../../API/Partners';
import { fetchPartnerAccounts } from '../../../API/PartnerAccounts';
import { fetchPlaces } from '../../../API/Places';
import {
  fetchMainUsers,
  fetchPartnerUsers
} from '../../../API/Users';

let title = {
  fieldKey: "title",
  label: strings.form.cart.title.label,
  type: "text",
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.cart.title.invalid.assertEmpty,
      defaultValue: false
    },
  ],
}

let description = {
  fieldKey: "description",
  label: strings.form.cart.description.label,
  type: "textarea",
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.cart.description.invalid.assertEmpty,
      defaultValue: false
    },
  ],
}

let client = {
  fieldKey: "client",
  label: strings.form.cart.client.label,
  type: "dropdownEntity",

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.cart.client.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  loader: {
    id: "client",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let clientAccount = {
  fieldKey: "clientAccount",
  label: strings.form.cart.clientAccount.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues(0).client === undefined || getValues(0).client === null || getValues(0).client.id === undefined,

  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.cart.clientAccount.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component && component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "clientAccount",
    loader: fetchPartnerAccounts,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["partner_type"] && data["partner_type"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["partner_type"]["name"],
  },
}

let placeDelivery = {
  fieldKey: "placeDelivery",
  label: strings.form.cart.placeDelivery.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues(0).client === undefined || getValues(0).client === null || getValues(0).client.id === undefined,
  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.cart.placeDelivery.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component && component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "partnerPlaces",
    loader: fetchPlaces,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"],
        active: 1
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let clientUser = {
  fieldKey: "clientUser",
  label: strings.form.cart.clientUser.label,
  type: "dropdownEntity",
  disabled: (getValues, getExtraValues) => getExtraValues().credentials.roles.includes('ROLE_ORDER_CREATE') || getValues(0).client === undefined || getValues(0).client === null || getValues(0).client.id === undefined,
  showOnDisabled: (getValues, getExtraValues) => !(getExtraValues().credentials.roles.includes('ROLE_ORDER_CREATE')),
  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.cart.clientUser.invalid.assertEmpty,
      defaultValue: false
    }
  ],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "client",
      function: (component) => component && component.reloadData()
    }
  ],

  showEmpty: true,
  loader: {
    id: "clientUser",
    loader: fetchPartnerUsers,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let mainUser = {
  fieldKey: "mainUser",
  label: strings.form.cart.mainUser.label,
  type: "dropdownEntity",
  disabled: (getValues, getExtraValues) => getExtraValues().credentials.roles.includes('ROLE_ORDER_CREATE'),
  showOnDisabled: (getValues, getExtraValues) => !(getExtraValues().credentials.roles.includes('ROLE_ORDER_CREATE')),
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.cart.mainUser.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  loader: {
    id: "mainUser",
    loader: fetchMainUsers,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

let rightToCancellation = {
  fieldKey: "relinquishRightToCancellation",
  label: strings.form.cart.rightToCancellation.label,
  type: "switch",
  defaultValue: true,

  validators: [],
}

export const form = {
  formKey: "cart",
  fields: [
    title,
    client,
    clientAccount,
    placeDelivery,
    clientUser,
    mainUser,
    description
  ]
}

export const rightToCancellationForm = {
  formKey: "cart",
  fields: [
    rightToCancellation
  ]
}
