export const ADD_REQUEST = 'add_verification_with_callback';
export const ADD_VERIFICATION = 'add_verification';
export const MODIFY_TYPE = 'modify_type';
export const REMOVE_ACTION = 'remove_action';
export const ON_AUTHENTICATION_SUCCESS = 'modal_handler_on_authentication_success';

export function addVerificationWithCallbackAction(call, callback, callbackOnFailure, myArguments, singleCall, ownerUuid, callUuid){
  return {
    type: ADD_REQUEST,
    action: {
      type: 'request',
      call: call,
      callback: callback,
      callbackOnFailure: callbackOnFailure,
      myArguments: myArguments,
      singleCall: singleCall,
      ownerUuid: ownerUuid,
      callUuid: callUuid
    }
  }
}

export function addVerificationAction(call, myArguments){
  return {
    type: ADD_VERIFICATION,
    action: {
      type: 'verification',
      call: call,
      myArguments: myArguments
    }
  }
}

export function modifyTypeAction(uuid, newType){
  return {
    type: MODIFY_TYPE,
    uuid: uuid,
    action: {
      type: newType
    }
  }
}

export function removeActionAction(uuid){
  return {
    type: REMOVE_ACTION,
    uuid: uuid
  }
}

export function onAuthenticationSuccessAction() {
  return {
    type: ON_AUTHENTICATION_SUCCESS
  }
}
