import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem
 } from './Base';

 /******************************************************************************
  * INDEX FUNCTION                                                             *
  ******************************************************************************/

/**
 * Get a list of product dependencies based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('product_dependencies', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single product dependency based on his ID
 *
 * @param id the of the product dependency requested
 */
export function getFromId(id) {
  return baseGetFromId('product_dependencies', id);
}

/**
 * Get a single product dependency based on his ID
 *
 * @param id the of the product dependency requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('product_dependencies', id, 'identification');
}

/**
 * Get a single product dependency based on his ID
 *
 * @param id the of the product dependency requested
 */
export function getMastersFromId(id) {
  return baseGetFromId('product_dependencies', id, 'masters');
}

/**
 * Get a single product dependency based on his ID
 *
 * @param id the of the product dependency requested
 */
export function getSlavesFromId(id) {
  return baseGetFromId('product_dependencies', id, 'slaves');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single product dependency based on his ID
 *
 * @param id the of the product dependency requested
 * @param data the new data of the product dependency
 */
export function editFromId(id, data) {
  return baseEditFromId('product_dependencies', id, data);
}

/**
 * Edit a single product dependency based on his ID
 *
 * @param id the of the product dependency requested
 * @param data the new data of the product dependency
 */
export function editMastersFromId(id, data) {
  return baseEditFromId('product_dependencies', id, data, 'masters');
}

/**
 * Edit a single product dependency based on his ID
 *
 * @param id the of the product dependency requested
 * @param data the new data of the product dependency
 */
export function editSlavesFromId(id, data) {
  return baseEditFromId('product_dependencies', id, data, 'slaves');
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new product dependency
 *
 * @param data the new data of the product dependency
 */
export function newItem(data) {
  return baseNewItem('product_dependencies', data);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all dependency to serve in a dropdown form choice
 */
export function fetchProductDependencies() {
  return baseIndex('product_dependencies', 1, 0);
}
