/**
 * CalculParser
 *
 * parse a string to a mathematical equation with parameters
 */
export default class FormulaParser {

  constructor() {
    this.formula = null;

    this.parens = /\(([0-9+\-*/^ .]+)\)/              // Regex for identifying parenthetical expressions
    this.exp = /(\d+(?:\.\d+)?) ?\^ ?(\d+(?:\.\d+)?)/ // Regex for identifying exponentials (x ^ y)
    this.mul = /(\d+(?:\.\d+)?) ?\* ?(\d+(?:\.\d+)?)/ // Regex for identifying multiplication (x * y)
    this.div = /(\d+(?:\.\d+)?) ?\/ ?(\d+(?:\.\d+)?)/ // Regex for identifying division (x / y)
    this.add = /(\d+(?:\.\d+)?) ?\+ ?(\d+(?:\.\d+)?)/ // Regex for identifying addition (x + y)
    this.sub = /(\d+(?:\.\d+)?) ?- ?(\d+(?:\.\d+)?)/  // Regex for identifying subtraction (x - y)
  }

  setFormula(formula) {
    this.formula = formula;
  }

  parse(parameters) {
    if(this.formula === null) {
      return 0;
    }
    let formula = this.formula;
    Object.keys(parameters).forEach((key, i) => {
      formula = formula.replace(key, parameters[key])
    });

    return this.evaluate(formula);
  }


  /**
   * Evaluates a numerical expression as a string and returns a Number
   * Follows standard PEMDAS operation ordering
   * @param {String} expr Numerical expression input
   * @returns {Number} Result of expression
   */
  evaluate(expr)
  {
    if(isNaN(Number(expr)))
    {
      if(this.parens.test(expr))
      {
        let newExpr = expr.replace(this.parens, function(match, subExpr) {
          return this.evaluate(subExpr);
        });
        return this.evaluate(newExpr);
      }
      else if(this.exp.test(expr))
      {
        let newExpr = expr.replace(this.exp, function(match, base, pow) {
          return Math.pow(Number(base), Number(pow));
        });
        return this.evaluate(newExpr);
      }
      else if(this.mul.test(expr))
      {
        let newExpr = expr.replace(this.mul, function(match, a, b) {
          return Number(a) * Number(b);
        });
        return this.evaluate(newExpr);
      }
      else if(this.div.test(expr))
      {
        let newExpr = expr.replace(this.div, function(match, a, b) {
          if(b !== 0)
            return Number(a) / Number(b);
          else
            throw new Error('Division by zero');
        });
        return this.evaluate(newExpr);
      }
      else if(this.add.test(expr))
      {
        let newExpr = expr.replace(this.add, function(match, a, b) {
            return Number(a) + Number(b);
        });
        return this.evaluate(newExpr);
      }
      else if(this.sub.test(expr))
      {
        let newExpr = expr.replace(this.sub, function(match, a, b) {
            return Number(a) - Number(b);
        });
        return this.evaluate(newExpr);
      }
      else
      {
        return expr;
      }
    }
    return Number(expr);
  }
}
