import React from 'react';

/**
 * TableHeader
 */
class TableTextHeader extends React.Component {

  static defaultProps = {
    colSpan: 1,
    label: "Label"
  }

  /**
   * Main render method for React Component
   */
  render() {
    if(this.props.colSpan === 0) {
      return null;
    }
    return (
      <th colSpan={this.props.colSpan}>{this.props.label}</th>
    );
  }
}

export default TableTextHeader;
