import React from 'react';

import { connect } from 'react-redux';

import '../../css/breadcrumb.css';

import Link from './Link';

/**
 * Breadcrumb
 */
class Breadcrumb extends React.Component {

  componentDidMount() {
    // if(!window.sessionStorage.getItem("id")) {
    //   window.sessionStorage.setItem("id", uuid());
    // }
    // console.log(window.sessionStorage.getItem("id"));
  }

  getHistory() {
    let history = JSON.parse(window.sessionStorage.getItem("breadcrumb"));
    if(history) {
      return history;
    }
    return [];
  }

  displayBreadcrumb(displayAsTooltip = true) {
    let history = this.getHistory();
    return(
      <>
        {history.map((breadcrumb, i) => {
          if(i === history.length -1) {
            document.title = `Nyukom > ${breadcrumb.title}`
          }
          return (
            <Link
              key={i}
              href={breadcrumb.path}
              title={breadcrumb.title}
              identifier={breadcrumb.identifier}
              displaySeparator={history[0] !== breadcrumb}
              displayIdentifier={breadcrumb.identifier !== null}
              displayAsTooltip={displayAsTooltip} />
          )
        })}
      </>
    )
  }

  displayMobile() {
    return (
      <div className="d-flex d-md-none"/>
    );
  }

  displaySmall() {
    return (
      <div className="d-none d-sm-flex d-lg-none flex-row">
        {this.displayBreadcrumb(true)}
      </div>
    );
  }

  displayDefault() {
    return (
      <div className="d-none d-lg-flex flex-row">
        {this.displayBreadcrumb(false)}
      </div>
    );
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div>
        {this.displayMobile()}
        {this.displaySmall()}
        {this.displayDefault()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  breadcrumb: state.breadcrumb,
  // breadcrumb: state.persisted.breadcrumb,
})

export default connect(mapStateToProps)(Breadcrumb);
