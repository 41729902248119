import React from 'react';

import BasicNotification from './Template/BasicNotification';

import NewBillClient from './Template/NewBillClient';

import TicketNewClient from './Template/TicketNewClient';
import TicketNewNyukom from './Template/TicketNewNyukom';
import TicketEditNyukom from './Template/TicketEditNyukom';
import TicketCommentMixed from './Template/TicketCommentMixed';
import TicketAppointmentClient from './Template/TicketAppointmentClient';
import TicketFileMixed from './Template/TicketFileMixed';
import TicketCloseClient from './Template/TicketCloseClient';
import TicketCloseNyukom from './Template/TicketCloseNyukom';
import TicketAssignNyukom from './Template/TicketAssignNyukom';
import TicketEscalateClient from './Template/TicketEscalateClient';
import TicketEscalateNyukom from './Template/TicketEscalateNyukom';
import TicketGfrtNyukom from './Template/TicketGfrtNyukom';
import TicketGfrtTimeoutNyukom from './Template/TicketGfrtTimeoutNyukom';

import OrderNewClient from './Template/OrderNewClient';
import OrderNewNyukom from './Template/OrderNewNyukom';
import OrderValidateClient from './Template/OrderValidateClient';
import OrderCancellationPeriodEndClient from './Template/OrderCancellationPeriodEndClient';
import OrderStartDeliveryNyukom from './Template/OrderStartDeliveryNyukom';
import OrderDeliverClient from './Template/OrderDeliverClient';
import OrderCancelClient from './Template/OrderCancelClient';
import OrderCancelNyukom from './Template/OrderCancelNyukom';
import OrderCancelValidatedClient from './Template/OrderCancelValidatedClient';
import OrderCancelValidatedNyukom from './Template/OrderCancelValidatedNyukom';

import QuoteNewClient from './Template/QuoteNewClient';
import QuoteValidatedClient from './Template/QuoteValidatedClient';
import QuoteSignedNyukom from './Template/QuoteSignedNyukom';

import UserNew from './Template/UserNew';
import UserResetPassword from './Template/UserResetPassword';

import ReportNewNyukom from './Template/ReportNewNyukom';

class TemplateSelector extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {

    let _arguments = {};
    this.props.item.arguments.forEach((item, i) => {
      _arguments[item.label] = item.value;
    });

    this.props.item._arguments = _arguments;

    //select the notification template
    switch (this.props.item.template) {
        case "new_bill.client":
          return (
            <NewBillClient
              item={this.props.item}
            />
          );

        case 'ticket.new.client':
          return (
            <TicketNewClient
              item={this.props.item}
            />
          );
        case 'ticket.new.nyukom':
          return (
            <TicketNewNyukom
              item={this.props.item}
            />
          );
        case 'ticket.edit.client':
          return (
            <TicketEditNyukom
              item={this.props.item}
            />
          );
        case 'ticket.comment.mixed':
          return (
            <TicketCommentMixed
              item={this.props.item}
            />
          );
        case 'ticket.appointment.client':
          return (
            <TicketAppointmentClient
              item={this.props.item}
            />
          );
        case 'ticket.file.mixed':
          return (
            <TicketFileMixed
              item={this.props.item}
            />
          );
        case 'ticket.close.client':
          return (
            <TicketCloseClient
              item={this.props.item}
            />
          );
        case 'ticket.close.nyukom':
          return (
            <TicketCloseNyukom
              item={this.props.item}
            />
          );
        case 'ticket.assign_referee.nyukom':
          return (
            <TicketAssignNyukom
              item={this.props.item}
            />
          );
        case 'ticket.escalate.client':
          return (
            <TicketEscalateClient
              item={this.props.item}
            />
          );
        case 'ticket.escalate.nyukom':
          return (
            <TicketEscalateNyukom
              item={this.props.item}
            />
          );
        case 'ticket.gfrt.nyukom':
          return (
            <TicketGfrtNyukom
              item={this.props.item}
            />
          );
        case 'ticket.gfrt_timeout.nyukom':
          return (
            <TicketGfrtTimeoutNyukom
              item={this.props.item}
            />
          );

        case 'order.new.client':
          return (
            <OrderNewClient
              item={this.props.item}
            />
          );
        case 'order.new.nyukom':
          return (
            <OrderNewNyukom
              item={this.props.item}
            />
          );
        case 'order.validate.client':
          return (
            <OrderValidateClient
              item={this.props.item}
            />
          );
        case 'order.cancellation_period_end.client':
          return (
            <OrderCancellationPeriodEndClient
              item={this.props.item}
            />
          );
        case 'order.start_delivery.nyukom':
          return (
            <OrderStartDeliveryNyukom
              item={this.props.item}
            />
          );
        case 'order.deliver.client':
          return (
            <OrderDeliverClient
              item={this.props.item}
            />
          );
        case 'order.cancel.client':
          return (
            <OrderCancelClient
              item={this.props.item}
            />
          );
        case 'order.cancel.nyukom':
          return (
            <OrderCancelNyukom
              item={this.props.item}
            />
          );
        case 'order.cancel_validated.client':
          return (
            <OrderCancelValidatedClient
              item={this.props.item}
            />
          );
        case 'order.cancel_validated.nyukom':
          return (
            <OrderCancelValidatedNyukom
              item={this.props.item}
            />
          );

        case 'quote.new.client':
          return (
            <QuoteNewClient
              item={this.props.item}
            />
          );
        case 'quote.validated.client':
          return (
            <QuoteValidatedClient
              item={this.props.item}
            />
          );
        case 'quote.signed.nyukom':
          return (
            <QuoteSignedNyukom
              item={this.props.item}
            />
          );

        case 'user.new':
          return (
            <UserNew
              item={this.props.item}
            />
          );
        case 'user.reset_password':
          return (
            <UserResetPassword
              item={this.props.item}
            />
          );

        case 'report.new.nyukom':
          return (
            <ReportNewNyukom
              item={this.props.item}
            />
          );
        case "test":
          return (
            <BasicNotification
              item={this.props.item}
            />
          );
        default:
    }

    return <div/>;
  }
}

export default TemplateSelector;
