import React from 'react';

import { useRef } from 'react';

import { useDrag, useDrop } from 'react-dnd';

import { ItemTypes } from '../../../DnD/Constant';

export default function CartItem({ id, order, sectionId, tbodyClassName, trClassName, handleClassName, subItem, modifyCartItemOrder, children }) {

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CART_ITEM,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragOrder = item.order;
      const dragSection = item.sectionId;
      const hoverOrder = order;
      const hoverSection = sectionId;
      // Don't replace items with themselves
      if (dragOrder === hoverOrder && dragSection === hoverSection) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragOrder < hoverOrder && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragOrder > hoverOrder && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      modifyCartItemOrder(dragOrder, dragSection, hoverOrder, hoverSection);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.order = hoverOrder;
      item.sectionId = hoverSection;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CART_ITEM,
    item: () => {
      return { order, sectionId };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if(!subItem) preview(drop(ref));

  return (
    <tbody
      ref={ref}
      className={tbodyClassName}
      data-handler-id={handlerId}
    >
      <tr className={trClassName} style={{opacity: isDragging ? 0.1 : 1}}>
        {subItem?
          <td className={handleClassName}><i className="icon-arrow-thin-right"/></td>
          :<td ref={drag} style={{cursor: 'move'}} className={handleClassName}><i className="icon-media-pause icon-rotate-90"/></td>
        }
        {children}
      </tr>
    </tbody>
  );
};
