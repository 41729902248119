import React from 'react';

import TableCell from '../../../Table/Body/TableCell';

/**
 * StringCell
 */
class StringCell extends TableCell {

  getPlaceholder() {
    if(this.props.additionalConfiguration.placeholder) {
      return this.props.additionalConfiguration.placeholder;
    }
    return null;
  }

  getValue() {
    return this.props.value;
  }

  onChange(event) {
    this.props.additionalConfiguration.onChange(this.props._rowId, this.props._valueId, event.target.value, this.props.data);
  }

  displayValue() {
    if(this.props.additionalConfiguration.editable) {
      return <input type="text" className="cell-form cell-form-string" placeholder={this.getPlaceholder()} value={this.getValue()} onChange={(e) => this.onChange(e)}/>
    }
    else if(this.props.href) {
      return (
        <a className="table-cell-link" href={this.props.href}>
          <div className="table-cell-link">
            {this.props.value}
          </div>
        </a>
      )
    }
    else {
      return this.props.value;
    }
  }

  displayInnerCell() {
    return <div className="cell-container cell-container-string">{this.displayValue()}</div>;
  }
}

export default StringCell;
