import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, InputGroup, Col } from 'react-bootstrap';
import '../../../css/form.css';

import LengthMinAssert from '../../../Form/Assert/LengthMinAssert';
import LengthMaxAssert from '../../../Form/Assert/LengthMaxAssert';
import ForbiddenCharactersAssert from '../../../Form/Assert/ForbiddenCharactersAssert';

/**
 * FormFieldText
 *
 * This class handles HTML form field.
 *
 * The particular field is for text.
 *
 * Specific configuration attribute : none
 */
class FormFieldText extends BasicFormField {

  getSpecificAsserts() {
    return {
      lengthMin: LengthMinAssert,
      lengthMax: LengthMaxAssert,
      forbiddenCharacters: ForbiddenCharactersAssert,
    }
  }

  /**
   * @inheritdoc
   */
  getValue() {
    return (this.props.value)?this.props.value:'';
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    return (this.props.value)?this.props.value:this.props.defaultValue;
  }

  /**
   * Main render method for React Component
   */
  render() {
    let disabledLink = null;
    if(this.props.disabledLink) {
      disabledLink = this.props.disabledLink(this.props.value, this.props.getValues, this.props.getExtraValues)
    }
    let messageRight = null;
    if(typeof this.props.messageRight === 'function') {
      messageRight = this.props.messageRight(this.props.value, this.props.getValues, this.props.getExtraValues)
    }
    else if(this.props.messageRight) {
      messageRight = this.props.messageRight;
    }
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          {
            //If the field is not disabled show the field as normal
            !(this.props.disabled && disabledLink)?
              <Form.Control
                className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius"
                placeholder={this.props.label}
                aria-label={this.props.label}
                aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
                type="text"
                disabled={this.props.disabled || this.props.submitting}
                value={this.getValue()}
                onChange={(event) => this.onChange(event.target.value)}
                isValid={(this.props.disabled)?null:this.isValid()}
                isInvalid={(this.props.disabled)?null:this.isInvalid()}
              />
              :
              //If the field is disabled show a link to send a mail
              <a className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius form-control align-items-center form-disabled" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}} href={disabledLink}>{this.getValue()}</a>
          }
          {this.displayValidating()}
          {
            messageRight?
            <InputGroup.Prepend
              className="d-md-flex container-form-prepend">
              <InputGroup.Text>{messageRight}</InputGroup.Text>
            </InputGroup.Prepend>
            :null
          }
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

export default FormFieldText;
