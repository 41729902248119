const ticketTimeZone = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Le nom ne peut pas être vide."
    }
  },
};

export default ticketTimeZone;
