const accountVat = {
  index: {
    tab: {
      headers: {
        number: "Numéro",
        title: "Titre",
        vat: "T.V.A."
      }
    }
  },
  tab: {
    main: "Compte",
    edit: "Editer",
  },
  search: {
    number: "Numéro",
    title: "Titre",
    vat: "T.V.A."
  },
  new: {
    title: "Nouveau compte de TVA"
  }
};

export default accountVat;
