import strings from '../../Localization/Localization';

let file = {
  fieldKey: "filename",
  type: "file",
  label: strings.form.ticketFile.filename.label,
  validators: []
}

export const form = {
  formKey: "file",
  fields: [
    file
  ]
}

export const newForm = {
  formKey: "newFile",
  fields: [
    file
  ]
}
