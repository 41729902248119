import { 
  ADD_LINE_TO_FLASHBAG,
  REMOVE_LINE_FROM_FLASHBAG
} from '../Action/flashbag';

import { v4 as uuid } from 'uuid';

const initialState = {
  lines: [],
}

export default function reduce(state = initialState, action){

  let newState;
  switch (action.type) {
    case ADD_LINE_TO_FLASHBAG:
      newState = Object.assign({}, state);
      newState.lines = [...state.lines, {
        id: uuid(),
        text: action.text,
        type: action.line_type
      }];
      break;
    case REMOVE_LINE_FROM_FLASHBAG:
      newState = Object.assign({}, state);
      newState.lines = state.lines.filter((line) => line.id !== action.id);
      break;
    default:
      newState = state;
  }

  return newState;
}
