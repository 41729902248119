import React from 'react';

// import { Container, Row, Col, Image } from 'react-bootstrap';
// import strings from '../../Localization/Localization';

import '../../css/tutorial_carousel.css';

import { connect } from 'react-redux';

import { resetBreadcrumbAction } from '../../Store/Action/breadcrumb';

import MainLayout from '../Layout/MainLayout';
import TutorialCarousel from './TutorialCarousel';

/**
 * Tutorial
 */
class Tutorial extends React.Component {

  /**
   * Once the component is mounted, we can query for the first page of data
   */
  componentDidMount() {
    this.props.dispatch(resetBreadcrumbAction())
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <MainLayout>
        <div className="tutorial-container">
          <TutorialCarousel />
        </div>
      </MainLayout>
    );
  }
}

export default connect()(Tutorial);
