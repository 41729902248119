const phoneCDR = {
  index: {
    headers: {
      sourceNumber: "Source",
      destinationNumber: "Destination",
      redirection: "Redirection",
      type: "Type",
      beginAt: "Date",
      quantity: "Quantité",
      priceFinal: "Prix",
    }
  },
  tab: {
    main: "Détail",
  },
  search: {
    service: "Service",
    communicationType: "Type",
    roaming: "Roaming",
    roamingLikeAtHome: "Roaming Like At Home",
    redirection: "Redirection",
    inout: "Communication",
    sourceNumber: "Source",
    destinationNumber: "Destination",
    beginAtMin: "Date (min)",
    beginAtMax: "Date (max)",
    quantityMin: "Quantité (min)",
    quantityMax: "Quantité (max)",
    priceFinalMin: "Prix (min)",
    priceFinalMax: "Prix (max)",
  },
  errorProcessing: {
    breadcrumb: "Traitement des erreurs de téléphonie",
    tabs: {
      flex: "Flex",
      alphalink: "Alphalink",
      zone: "Zone",
      zoneSpecial: "Zone Spécial",
      duplicateMail: "@mail dupliquée",
      price: "Prix",
      roamingLikeAtHomeNotFound: "Roaming introuvable",
      importPrice: "import prix"
    },
    alphalink: {
      header: {
        type: "Type",
        value: "Valeur",
        version: "Schéma de données",
      },
      buttons: {
        startProcess: "Lancer le processus"
      }
    },
    flex: {
      header: {
        type: "Type",
        value: "Valeur",
        version: "Schéma de données",
      },
      buttons: {
        startProcess: "Lancer le processus"
      }
    },
    duplicateMail: {
      header: {
        type: "Type",
        mail: "@mail testée",
        destination: "Regex trouvé"
      },
      buttons: {
        startProcess: "Lancer le processus"
      }
    },
    zone: {
      header: {
        type: "Type",
        source: "Source",
        destination: "Destination"
      },
      buttons: {
        startProcess: "Lancer le processus"
      }
    },
    price: {
      header: {
        type: "Type",
        inout: {
          label: "Entrant/Sortant",
          values: {
            in: "Entrant",
            out: "Sortant"
          }
        },
        roaming: {
          label: "Roaming",
          values: {
            y: "Oui",
            n: "Non"
          }
        },
        date: "Date",
        communicationType: {
          label: "Communication",
          values: {
            voice: "Voix",
            data: "Données",
            sms: "SMS",
            mms: "MMS",
            other: "Autre"
          }
        },
        zoneSource: "Source",
        zoneDestination: "Destination",
        zoneSourceGroup: "Source (groupe)",
        zoneDestinationGroup: "Destination (groupe)",
      },
      buttons: {
        startProcess: "Lancer le processus"
      }
    },
    roamingLikeAtHomeNotFound: {
      header: {
        type: "Type",
        inout: {
          label: "Entrant/Sortant",
          values: {
            in: "Entrant",
            out: "Sortant"
          }
        },
        date: "Date",
        communicationType: {
          label: "Communication",
          values: {
            voice: "Voix",
            data: "Données",
            sms: "SMS",
            mms: "MMS",
            other: "Autre"
          }
        },
        sourceNumber: "Numéro Source",
        destinationNumber: "Numéro Destination",
        zoneSource: "Zone Source",
        zoneDestination: "Zone Destination",
      },
      buttons: {
        startProcess: "Lancer le processus"
      }
    },
    importPrice: {
      buttons: {
        import: "Lancer import",
        fetchEmptyTable: "Tableau vide",
        fetchEmptyTableDescription: "Description tableau",
      }
    },
  },
};

export default phoneCDR;
