const specificPhonePriceList = {
  applicableFrom: {
    label: "Applicable depuis",
  },
  phonePriceList: {
    label: "Grille tarifaire",
  },
};

export default specificPhonePriceList;
