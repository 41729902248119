const productFamily = {
  tab: {
    show: "Famille",
    edit: "Editer",
    configuration: "Configuration",
    configuration_edit: "",
    accounting: "Comptabilité",
    accounting_edit: ""
  },
  search: {
    name: "Nom",
    activeOrder: "Actif en commande",
    activeQuote: "Actif en devis",
    activeTicket: "Actif en ticket",
    rentable: "Louable"
  },
  new: {
    title: "Nouvelle famille de produit"
  }
};

export default productFamily;
