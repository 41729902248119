// import strings from '../../Localization/Localization';

import { index } from '../../API/Logs';
import { form as searchForm } from '../Form/Search/logs';

// import { logs as logsRoutes } from '../Route/routes';

export const conf = {
  index: {
    id: "log",
    api: index,
    onClickItem: {
      redirect: false,
    },

    searchForm: searchForm,

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => {
          return item.message;
          //item.message.replace(/(?<=\{).+?(?=\})/g, (param) => item.params[param])
        }
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
}
