const accountingType = {
  index: {
    tab: {
      headers: {
        title: "Titre",
        collectiveClientNumber: "Collectivité"
      }
    }
  },
  tab: {
    main: "Compte",
    edit: "Editer",
  },
  search: {
    title: "Titre",
    collectiveClientNumber: "Collectivité"
  },
  new: {
    title: "Nouveau type de compte"
  }
};

export default accountingType;
