const partnerType = {
  tab: {
    main: "Type",
  },
  search: {
    name: "Nom",
  },
  new: {
    title: "Nouveau type de partenaire"
  }
};

export default partnerType;
