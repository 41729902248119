import React from 'react';

import strings from '../../../Localization/Localization';
import { quotes } from '../../../MetaData/Route/routes';

import BasicNotification from './BasicNotification';

class QuoteSignedNyukom extends BasicNotification {

  displayMessage() {
    return (
      <a href={quotes.routes.show.createPath(this.props.item._arguments["%quote_id%"])}>{strings.formatString(strings.common.notification.quote_signed_nyukom, this.props.item._arguments["%quote_number%"])}</a>
    )
  }
}

export default QuoteSignedNyukom;
