import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  getIndexFromId,
  getPasswordFromId,
  getProfilsFromId,
  getPartnersFromId,
  getNotificationFromId,
  getEscalateLevelFromId,
  editFromId,
  editProfilsFromId,
  editPasswordFromId,
  editPartnersFromId,
  editNotificationFromId,
  editEscalateLevelFromId,
  newItem,
  unlockUser,
  deactivateUser,
  reactivateUser
} from '../../API/Users';
import {
  form,
  passwordForm,
  // roleForm,
  roleEditForm,
  partnerForm,
  notificationForm,
  escalateLevelForm,
  newForm
} from '../Form/users';
import { form as searchForm } from '../Form/Search/users';

import { users as routes } from '../Route/routes';

import { config } from '../../API/config';

import {
  FLASHBAG_TYPE_SUCCESS,
  FLASHBAG_TYPE_DANGER
} from '../../Store/Action/flashbag';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.users,

    id: "user",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: ['ROLE_USER_CREATE_INNER', 'ROLE_USER_CREATE_PARTNER']
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.firstname) {
        result["firstname"] = search.firstname;
      }
      if(search.lastname) {
        result["lastname"] = search.lastname;
      }
      // if(search.mail) {
      //   result["mail"] = search.mail;
      // }
      // if(search.phoneNumber) {
      //   result.phone_number = search.phoneNumber;
      // }
      if(search.username) {
        result["username"] = search.username;
      }
      if(search.blocked === true) {
        result["blocked"] = 1
      }
      if(search.active === true) {
        result["active"] = 1
      }
      else if(search.active === false) {
        result["active"] = 0
      }
      if(typeof search.partner === 'object') {
        result["partner_id"] = search.partner.id
      }
      else if(search.partner && search.partner !== ''){
        result["partner_label"] = search.partner
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            label: "Société",
            value: (line) => {
              if(line["user_partners"] && line["user_partners"].length === 1) {
                return line["user_partners"][0]["partner"]["name"];
              }
              else if(line["user_partners"] && line["user_partners"].length > 0) {
                return "Multiple partenaire";
              }
              return "Nyukom";
            }
          },
          // {
          //   label: "Nom Utilisateur",
          //   value: "username"
          // },
          {
            label: "Prénom / Nom",
            value: "displayname"
          },
          {
            label: "@mail",
            value: "mail"
          },
          {
            label: "Téléphone",
            value: (line) => {
              if(line["phone_number"] && line["phone_number"].prefix && line["phone_number"].number) {
                return "+"+line["phone_number"].prefix+line["phone_number"].number;
              }
              return "";
            }
          },
          {
            label: "Last Log",
            value: "last_login"
          },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: () => []
  },
  show: {
    pageTitle: strings.common.homepage.users,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "displayname",

    apiGet: getIndexFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => (item.img)?config.file_path+item.img:null,
      placeholderTitle: (item) => item.displayname.split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item.displayname,
      additionalData1: (item) => item.mail,
      additionalData2: (item) => {
        if(item && item.phone_number && item.phone_number.prefix) {
          return (item && item.phone_number)?'+'+item.phone_number.prefix+item.phone_number.number:''
        }
        else if(item && item.mobile_phone_number && item.mobile_phone_number.prefix) {
          return (item && item.mobile_phone_number)?'+'+item.mobile_phone_number.prefix+item.mobile_phone_number.number:''
        }
        return '';
      },
      additionalData3: (item) => {
        if(item["user_partners"] && item["user_partners"].length === 1) {
          return item["user_partners"][0]["partner"]["name"];
        }
        else if(item["user_partners"] && item["user_partners"].length > 0) {
          return "Multiple partenaire";
        }
        return "Nyukom";
      }
    },

    menu: (item) => {
      let buttons = [];
      if(item.locked) {
        buttons.push({
          id: 'unlock',
          type: 'request',
          auth: 'ROLE_NYUKOM',
          request: () => unlockUser(item["id"]),
          postRequestCallback: [
            {
              type: "flashbag",
              message: strings.user.tabs.buttons.unlock.flashbagMessage,
              flashbagType: FLASHBAG_TYPE_SUCCESS,
            },
            {
              type: "updateItem",
              key: "locked",
              value: (data) => false
            }
          ],
          postRequestCallbackFailure: {
            type: "flashbag",
            message: strings.user.tabs.buttons.unlock.flashbagMessageFailure,
            flashbagType: FLASHBAG_TYPE_DANGER,
          },
          label: strings.user.tabs.buttons.unlock.label,
          variant: "my-validated"
        });
      }
      if(item.active) {
        buttons.push({
          id: 'deactivate',
          type: 'request',
          auth: 'ROLE_USER_DELETE',
          request: () => deactivateUser(item["id"]),
          postRequestCallback: [
            {
              type: "flashbag",
              message: strings.user.tabs.buttons.deactivate.flashbagMessage,
              flashbagType: FLASHBAG_TYPE_SUCCESS,
            },
            {
              type: "updateItem",
              key: "active",
              value: (data) => false
            }
          ],
          postRequestCallbackFailure: {
            type: "flashbag",
            message: strings.user.tabs.buttons.deactivate.flashbagMessageFailure,
            flashbagType: FLASHBAG_TYPE_DANGER,
          },
          label: strings.user.tabs.buttons.deactivate.label,
          addVerification: true,
          variant: "my-warning"
        });
      }
      else {
        buttons.push({
          id: 'reactivate',
          type: 'request',
          auth: 'ROLE_USER_DELETE',
          request: () => reactivateUser(item["id"]),
          postRequestCallback: [
            {
              type: "flashbag",
              message: strings.user.tabs.buttons.reactivate.flashbagMessage,
              flashbagType: FLASHBAG_TYPE_SUCCESS,
            },
            {
              type: "updateItem",
              key: "active",
              value: (data) => true
            }
          ],
          postRequestCallbackFailure: {
            type: "flashbag",
            message: strings.user.tabs.buttons.reactivate.flashbagMessageFailure,
            flashbagType: FLASHBAG_TYPE_DANGER,
          },
          label: strings.user.tabs.buttons.reactivate.label,
          addVerification: true,
          variant: "my-validated"
        });
      }
      return buttons;
    },

    tabs: {
      main: {
        title: strings.user.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_USER_ADMIN', 'ROLE_USER_INDEX'],
        editable: ['ROLE_USER_ADMIN', 'ROLE_USER_MAIN_EDIT'],
      },
      password: {
        title: strings.user.tab.password,
        type: 'form',
        form: passwordForm,

        onUpdateItem: (data) => {},
        onSubmit: (user) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getPasswordFromId,
        apiEdit: editPasswordFromId,

        role: ['ROLE_USER_ADMIN'],
        editable: ['ROLE_USER_ADMIN'],
      },
      roles: {
        title: strings.user.tab.roles,
        type: 'form',
        form: roleEditForm,

        onUpdateItem: (data) => {},
        onSubmit: (user) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getProfilsFromId,
        apiEdit: editProfilsFromId,

        role: ['ROLE_USER_ADMIN', 'ROLE_USER_PROFILS'],
        editable: ['ROLE_USER_ADMIN', 'ROLE_USER_PROFILS_EDIT'],
      },
      escalateLevels: {
        title: strings.user.tab.escalateLevels,
        type: 'form',
        form: escalateLevelForm,

        onUpdateItem: (data) => {
          if(data.item["default_ticket_level"]) {
            data.item.defaultEscalateLevel = data.item["default_ticket_level"];
          }
          data.item.technicalEscalateLevel = {
            level1: {},
            level2: {},
            level3: {}
          };
          data.item.commercialEscalateLevel = {
            level1: {},
            level2: {},
            level3: {}
          };
          data.item.administrativeEscalateLevel = {
            level1: {},
            level2: {},
            level3: {}
          };
          if(data.item["typed_ticket_levels"]) {
            data.item["typed_ticket_levels"].forEach((typedTicketLevel) => {
              switch (typedTicketLevel["ticket_type"]) {
                case 0:
                  data.item.technicalEscalateLevel.defaultEscalateLevel = typedTicketLevel["ticket_level"]
                  break;
                case 1:
                  data.item.commercialEscalateLevel.defaultEscalateLevel = typedTicketLevel["ticket_level"]
                  break;
                case 2:
                  data.item.administrativeEscalateLevel.defaultEscalateLevel = typedTicketLevel["ticket_level"]
                  break;
                default:
              }
            });
          }
          if(data.item["typed_sub_familied_ticket_levels"]) {
            data.item["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
              switch (typedSubFamiliedTicketLevel["ticket_type"]) {
                case 0:
                  switch (typedSubFamiliedTicketLevel["ticket_level"]) {
                    case 1:
                      data.item.technicalEscalateLevel.level1[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] = true;
                      break;
                    case 2:
                      data.item.technicalEscalateLevel.level2[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] = true;
                      break;
                    case 3:
                      data.item.technicalEscalateLevel.level3[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] = true;
                      break;
                    default:
                  }
                  break;
                  case 1:
                    switch (typedSubFamiliedTicketLevel["ticket_level"]) {
                      case 1:
                        data.item.commercialEscalateLevel.level1[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] = true;
                        break;
                      case 2:
                        data.item.commercialEscalateLevel.level2[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] = true;
                        break;
                      case 3:
                        data.item.commercialEscalateLevel.level3[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] = true;
                        break;
                      default:
                    }
                    break;
                    case 2:
                      switch (typedSubFamiliedTicketLevel["ticket_level"]) {
                        case 1:
                          data.item.administrativeEscalateLevel.level1[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] = true;
                          break;
                        case 2:
                          data.item.administrativeEscalateLevel.level2[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] = true;
                          break;
                        case 3:
                          data.item.administrativeEscalateLevel.level3[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] = true;
                          break;
                        default:
                      }
                      break;
                default:
              }
            })
          }
        },
        onSubmit: (user) => {
          if(user.defaultEscalateLevel) {
            user["default_ticket_level"] = user.defaultEscalateLevel;
            user.defaultEscalateLevel = undefined;
          }

          if(user.technicalEscalateLevel) {
            if([1, 2, 3].includes(Number(user.technicalEscalateLevel.defaultEscalateLevel))) {
              let done = false;
              user["typed_ticket_levels"].forEach((typedTicketLevel) => {
                if(typedTicketLevel["ticket_type"] === 0) {
                  typedTicketLevel["ticket_level"] = user.technicalEscalateLevel.defaultEscalateLevel;
                  done = true;
                }
              });
              if(!done) {
                user["typed_ticket_levels"].push({
                  ticket_type: 0,
                  ticket_level: user.technicalEscalateLevel.defaultEscalateLevel
                })
              }
            }

            //delete unmaintained data
            user["typed_sub_familied_ticket_levels"] = user["typed_sub_familied_ticket_levels"].filter((typedSubFamiliedTicketLevel) => {
              if(Number(typedSubFamiliedTicketLevel["ticket_type"]) !== 0
                || user.technicalEscalateLevel.level1[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] === true
                || user.technicalEscalateLevel.level2[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] === true
                || user.technicalEscalateLevel.level3[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] === true
              ) {
                return true;
              }
              return false;
            });

            //modify data
            if(user.technicalEscalateLevel.level1) {
              Object.keys(user.technicalEscalateLevel.level1).forEach((key) => {
                if(user.technicalEscalateLevel.level1[key] === true) {
                  let done = false;
                  user["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
                    if(typedSubFamiliedTicketLevel["ticket_type"] === 0 && String(typedSubFamiliedTicketLevel["product_sub_family"]["id"]) === String(key)) {
                      typedSubFamiliedTicketLevel["ticket_level"] = 1;
                      done = true;
                    }
                  });
                  if(!done) {
                    user["typed_sub_familied_ticket_levels"].push({
                      ticket_type: 0,
                      product_sub_family: {
                        id: key
                      },
                      ticket_level: 1
                    })
                  }
                }
              });
            }
            if(user.technicalEscalateLevel.level2) {
              Object.keys(user.technicalEscalateLevel.level2).forEach((key) => {
                if(user.technicalEscalateLevel.level2[key] === true) {
                  let done = false;
                  user["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
                    if(typedSubFamiliedTicketLevel["ticket_type"] === 0 && String(typedSubFamiliedTicketLevel["product_sub_family"]["id"]) === String(key)) {
                      typedSubFamiliedTicketLevel["ticket_level"] = 2;
                      done = true;
                    }
                  });
                  if(!done) {
                    user["typed_sub_familied_ticket_levels"].push({
                      ticket_type: 0,
                      product_sub_family: {
                        id: key
                      },
                      ticket_level: 2
                    })
                  }
                }
              });
            }
            if(user.technicalEscalateLevel.level3) {
              Object.keys(user.technicalEscalateLevel.level3).forEach((key) => {
                if(user.technicalEscalateLevel.level3[key] === true) {
                  let done = false;
                  user["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
                    if(typedSubFamiliedTicketLevel["ticket_type"] === 0 && String(typedSubFamiliedTicketLevel["product_sub_family"]["id"]) === String(key)) {
                      typedSubFamiliedTicketLevel["ticket_level"] = 3;
                      done = true;
                    }
                  });
                  if(!done) {
                    user["typed_sub_familied_ticket_levels"].push({
                      ticket_type: 0,
                      product_sub_family: {
                        id: key
                      },
                      ticket_level: 3
                    })
                  }
                }
              });
            }
            user.technicalEscalateLevel = undefined;
          }

          if(user.commercialEscalateLevel) {
            if([1, 2, 3].includes(Number(user.commercialEscalateLevel.defaultEscalateLevel))) {
              let done = false;
              user["typed_ticket_levels"].forEach((typedTicketLevel) => {
                if(typedTicketLevel["ticket_type"] === 1) {
                  typedTicketLevel["ticket_level"] = user.commercialEscalateLevel.defaultEscalateLevel;
                  done = true;
                }
              });
              if(!done) {
                user["typed_ticket_levels"].push({
                  ticket_type: 1,
                  ticket_level: user.commercialEscalateLevel.defaultEscalateLevel
                })
              }
            }

            //delete unmaintained data
            user["typed_sub_familied_ticket_levels"] = user["typed_sub_familied_ticket_levels"].filter((typedSubFamiliedTicketLevel) => {
              if(Number(typedSubFamiliedTicketLevel["ticket_type"]) !== 1
                || user.commercialEscalateLevel.level1[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] === true
                || user.commercialEscalateLevel.level2[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] === true
                || user.commercialEscalateLevel.level3[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] === true
              ) {
                return true;
              }
              return false;
            });

            //modify data
            if(user.commercialEscalateLevel.level1) {
              Object.keys(user.commercialEscalateLevel.level1).forEach((key) => {
                if(user.commercialEscalateLevel.level1[key] === true) {
                  let done = false;
                  user["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
                    if(typedSubFamiliedTicketLevel["ticket_type"] === 1 && String(typedSubFamiliedTicketLevel["product_sub_family"]["id"]) === String(key)) {
                      typedSubFamiliedTicketLevel["ticket_level"] = 1;
                      done = true;
                    }
                  });
                  if(!done) {
                    user["typed_sub_familied_ticket_levels"].push({
                      ticket_type: 1,
                      product_sub_family: {
                        id: key
                      },
                      ticket_level: 1
                    })
                  }
                }
              });
            }
            if(user.commercialEscalateLevel.level2) {
              Object.keys(user.commercialEscalateLevel.level2).forEach((key) => {
                if(user.commercialEscalateLevel.level2[key] === true) {
                  let done = false;
                  user["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
                    if(typedSubFamiliedTicketLevel["ticket_type"] === 1 && String(typedSubFamiliedTicketLevel["product_sub_family"]["id"]) === String(key)) {
                      typedSubFamiliedTicketLevel["ticket_level"] = 2;
                      done = true;
                    }
                  });
                  if(!done) {
                    user["typed_sub_familied_ticket_levels"].push({
                      ticket_type: 1,
                      product_sub_family: {
                        id: key
                      },
                      ticket_level: 2
                    })
                  }
                }
              });
            }
            if(user.commercialEscalateLevel.level3) {
              Object.keys(user.commercialEscalateLevel.level3).forEach((key) => {
                if(user.commercialEscalateLevel.level3[key] === true) {
                  let done = false;
                  user["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
                    if(typedSubFamiliedTicketLevel["ticket_type"] === 1 && String(typedSubFamiliedTicketLevel["product_sub_family"]["id"]) === String(key)) {
                      typedSubFamiliedTicketLevel["ticket_level"] = 3;
                      done = true;
                    }
                  });
                  if(!done) {
                    user["typed_sub_familied_ticket_levels"].push({
                      ticket_type: 1,
                      product_sub_family: {
                        id: key
                      },
                      ticket_level: 3
                    })
                  }
                }
              });
            }
            user.commercialEscalateLevel = undefined;
          }

          if(user.administrativeEscalateLevel) {
            if([1, 2, 3].includes(Number(user.administrativeEscalateLevel.defaultEscalateLevel))) {
              let done = false;
              user["typed_ticket_levels"].forEach((typedTicketLevel) => {
                if(typedTicketLevel["ticket_type"] === 2) {
                  typedTicketLevel["ticket_level"] = user.administrativeEscalateLevel.defaultEscalateLevel;
                  done = true;
                }
              });
              if(!done) {
                user["typed_ticket_levels"].push({
                  ticket_type: 2,
                  ticket_level: user.administrativeEscalateLevel.defaultEscalateLevel
                })
              }
            }

            //delete unmaintained data
            user["typed_sub_familied_ticket_levels"] = user["typed_sub_familied_ticket_levels"].filter((typedSubFamiliedTicketLevel) => {
              if(Number(typedSubFamiliedTicketLevel["ticket_type"]) !== 2
                || user.administrativeEscalateLevel.level1[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] === true
                || user.administrativeEscalateLevel.level2[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] === true
                || user.administrativeEscalateLevel.level3[typedSubFamiliedTicketLevel["product_sub_family"]["id"]] === true
              ) {
                return true;
              }
              return false;
            });

            //modify data
            if(user.administrativeEscalateLevel.level1) {
              Object.keys(user.administrativeEscalateLevel.level1).forEach((key) => {
                if(user.administrativeEscalateLevel.level1[key] === true) {
                  let done = false;
                  user["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
                    if(typedSubFamiliedTicketLevel["ticket_type"] === 2 && String(typedSubFamiliedTicketLevel["product_sub_family"]["id"]) === String(key)) {
                      typedSubFamiliedTicketLevel["ticket_level"] = 1;
                      done = true;
                    }
                  });
                  if(!done) {
                    user["typed_sub_familied_ticket_levels"].push({
                      ticket_type: 2,
                      product_sub_family: {
                        id: key
                      },
                      ticket_level: 1
                    })
                  }
                }
              });
            }
            if(user.administrativeEscalateLevel.level2) {
              Object.keys(user.administrativeEscalateLevel.level2).forEach((key) => {
                if(user.administrativeEscalateLevel.level2[key] === true) {
                  let done = false;
                  user["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
                    if(typedSubFamiliedTicketLevel["ticket_type"] === 2 && String(typedSubFamiliedTicketLevel["product_sub_family"]["id"]) === String(key)) {
                      typedSubFamiliedTicketLevel["ticket_level"] = 2;
                      done = true;
                    }
                  });
                  if(!done) {
                    user["typed_sub_familied_ticket_levels"].push({
                      ticket_type: 2,
                      product_sub_family: {
                        id: key
                      },
                      ticket_level: 2
                    })
                  }
                }
              });
            }
            if(user.administrativeEscalateLevel.level3) {
              Object.keys(user.administrativeEscalateLevel.level3).forEach((key) => {
                if(user.administrativeEscalateLevel.level3[key] === true) {
                  let done = false;
                  user["typed_sub_familied_ticket_levels"].forEach((typedSubFamiliedTicketLevel) => {
                    if(typedSubFamiliedTicketLevel["ticket_type"] === 2 && String(typedSubFamiliedTicketLevel["product_sub_family"]["id"]) === String(key)) {
                      typedSubFamiliedTicketLevel["ticket_level"] = 3;
                      done = true;
                    }
                  });
                  if(!done) {
                    user["typed_sub_familied_ticket_levels"].push({
                      ticket_type: 2,
                      product_sub_family: {
                        id: key
                      },
                      ticket_level: 3
                    })
                  }
                }
              });
            }
            user.administrativeEscalateLevel = undefined;
          }

        },
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getEscalateLevelFromId,
        apiEdit: editEscalateLevelFromId,

        role: ['ROLE_USER_ADMIN', 'ROLE_USER_ESCALATE_LEVEL_TECHNICAL', 'ROLE_USER_ESCALATE_LEVEL_COMMERCIAL', 'ROLE_USER_ESCALATE_LEVEL_ADMINISTRATIVE'],
        editable: ['ROLE_USER_ADMIN', 'ROLE_USER_ESCALATE_LEVEL_TECHNICAL', 'ROLE_USER_ESCALATE_LEVEL_COMMERCIAL', 'ROLE_USER_ESCALATE_LEVEL_ADMINISTRATIVE'],
      },
      partners: {
        title: strings.user.tab.partners,
        type: 'form',
        form: partnerForm,

        onUpdateItem: (data) => {},
        onSubmit: (user) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getPartnersFromId,
        apiEdit: editPartnersFromId,

        role: ['ROLE_USER_ADMIN', 'ROLE_USER_PARTNERS'],
        editable: ['ROLE_USER_ADMIN', 'ROLE_USER_PARTNERS_EDIT'],
      },
      notifications: {
        title: strings.user.tab.notifications,
        type: 'form',
        form: notificationForm,

        onUpdateItem: (data) => {
          data.item.notifiedByFlags = [];

          if(data.item.roles.includes("ROLE_ACCOUNTING_BILL")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.bill,
              notifiedByMail: data.item["notified_by_mail_flag_bill"],
              notifiedByWeb: data.item["notified_by_web_flag_bill"]
            });
          }

          if(data.item.roles.includes("ROLE_ORDER") || data.item.roles.includes("ROLE_QUOTE")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.commercial,
              notifiedByMail: data.item["notified_by_mail_flag_commercial"],
              notifiedByWeb: data.item["notified_by_web_flag_commercial"]
            });
          }

          if(data.item.roles.includes("ROLE_TECHNICAL_TICKET_NOTIFICATION_GFRT")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.technical,
              notifiedByMail: data.item["notified_by_mail_flag_technical"],
              notifiedByWeb: data.item["notified_by_web_flag_technical"]
            });
          }

          if(data.item.roles.includes("ROLE_ORDER")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.installation,
              notifiedByMail: data.item["notified_by_mail_flag_installation"],
              notifiedByWeb: data.item["notified_by_web_flag_installation"]
            });
          }

          data.item.notifiedByFlags.push({
            name: strings.form.user.notifiedByFlags.row.administrative,
            notifiedByMail: data.item["notified_by_mail_flag_administrative"],
            notifiedByWeb: data.item["notified_by_web_flag_administrative"]
          });

          data.item.notifiedByFlags.push({
            name: strings.form.user.notifiedByFlags.row.service_lifecycle,
            notifiedByMail: data.item["notified_by_mail_flag_service_lifecycle"],
            notifiedByWeb: data.item["notified_by_web_flag_service_lifecycle"]
          });

          if((data.item.roles.includes("ROLE_USER") && !data.item.roles.includes("ROLE_NYUKOM")) || data.item.roles.includes("ROLE_TECHNICAL_TICKET_NOTIFICATION") || data.item.roles.includes("ROLE_COMMERCIAL_TICKET_NOTIFICATION") || data.item.roles.includes("ROLE_ADMINISTRATIVE_TICKET_NOTIFICATION")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.ticket,
              notifiedByMail: data.item["notified_by_mail_flag_ticket"],
              notifiedByWeb: data.item["notified_by_web_flag_ticket"]
            });
          }

          if(data.item.roles.includes("ROLE_CDR_TECH_ERROR_REPORT")) {
            data.item.notifiedByFlags.push({
              name: strings.form.user.notifiedByFlags.row.admin_report,
              notifiedByMail: data.item["notified_by_mail_flag_admin_report"],
              notifiedByWeb: data.item["notified_by_web_flag_admin_report"]
            });
          }

          data.item.notifiedByFlags.push({
            name: strings.form.user.notifiedByFlags.row.other,
            notifiedByMail: data.item["notified_by_mail"],
            notifiedByWeb: data.item["notified_by_web"]
          });
        },
        onSubmit: (user) => {
          user.notifiedByFlags.forEach((notifiedByFlag) => {
            switch (notifiedByFlag.name) {
              case strings.form.user.notifiedByFlags.row.bill:
                user["notified_by_mail_flag_bill"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_bill"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.commercial:
                user["notified_by_mail_flag_commercial"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_commercial"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.technical:
                user["notified_by_mail_flag_technical"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_technical"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.installation:
                user["notified_by_mail_flag_installation"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_installation"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.administrative:
                user["notified_by_mail_flag_administrative"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_administrative"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.service_lifecycle:
                user["notified_by_mail_flag_service_lifecycle"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_service_lifecycle"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.ticket:
                user["notified_by_mail_flag_ticket"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_ticket"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.admin_report:
                user["notified_by_mail_flag_admin_report"] = notifiedByFlag.notifiedByMail
                user["notified_by_web_flag_admin_report"] = notifiedByFlag.notifiedByWeb
                break;
              case strings.form.user.notifiedByFlags.row.other:
                user["notified_by_mail"] = notifiedByFlag.notifiedByMail
                user["notified_by_web"] = notifiedByFlag.notifiedByWeb
                break;
              default:

            }
          });
          user.notifiedByFlags = undefined;
        },
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getNotificationFromId,
        apiEdit: editNotificationFromId,

        role: 'ROLE_USER_ADMIN',
        editable: 'ROLE_USER_ADMIN',
      },
    },
  },

  new: {
    pageTitle: strings.user.new.title,

    api: newItem,

    type: 'process',
    processId: 'newUser',

    steps: [
      {
        id: "_general_data",
        label: strings.user.new.steps.generalData,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['username', 'firstname', 'lastname', 'mail', 'phone_numbers', 'notified_by_mail', 'notified_by_web'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
      {
        id: "_profils_data",
        label: strings.user.new.steps.profilsData,
        type: "form",
        valueOrigin: "transparent",
        form: {
          ...newForm,
          fields: newForm.fields.filter((item) => ['profils', 'user_partners'].includes(item.fieldKey)),
        },
        onSubmitStep: () => {}
      },
    ],

    onSubmit: (user) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
