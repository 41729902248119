import {
  getFromId as baseGetFromId,
  index as baseIndex
 } from './Base';

 /******************************************************************************
  * GETTER FUNCTION                                                            *
  ******************************************************************************/

/**
 * Get a single partner based on his ID
 *
 * @param id the of the partner requested
 */
export function getOrderWaitingBillingFromId(id) {
  return baseGetFromId('partner_accounts', id, 'order_waiting_billing');
}

/**
 * Get a single partner based on his ID
 *
 * @param id the of the partner requested
 */
export function getTicketWaitingBillingFromId(id) {
  return baseGetFromId('partner_accounts', id, 'ticket_waiting_billing');
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get all partner accounts
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function fetchPartnerAccounts(search){
  return baseIndex('partner_accounts', 1, 0, search);
}
