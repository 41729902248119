import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem,
  transitionFromId as baseTransitionFromId
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of carts based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  search = {
    ...search,
    state: 'saved'
  }
  return baseIndex('carts', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single cart based on his ID
 *
 * @param id the of the cart requested
 */
export function getFromId(id) {
  return baseGetFromId('carts', id);
}

/**
 * Get a single product based on his ID
 *
 * @param id the of the product requested
 */
export function getIdentificationDataFromId(id) {
  return baseGetFromId('carts', id, 'id');
}

/**
 * Get a single product prices based on his ID
 *
 * @param id the of the product requested
 */
export function getClientFromId(id) {
  return baseGetFromId('carts', id, 'client');
}

/**
 * Get a single product prices based on his ID
 *
 * @param id the of the product requested
 */
export function getUsersFromId(id) {
  return baseGetFromId('carts', id, 'users');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Edit a single cart based on his ID
 *
 * @param id the of the cart requested
 * @param data the new data of the cart
 */
export function editFromId(id, data) {
  return baseEditFromId('carts', id, data);
}

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editUsersFromId(id, data) {
  return baseEditFromId('carts', id, data, 'users');
}

/**
 * Edit a single product based on his ID
 *
 * @param id the of the product requested
 * @param data the new data of the product
 */
export function editCompleteFromId(id, data) {
  return baseEditFromId('carts', id, data, 'complete');
}

/******************************************************************************
 * TRANSITION FUNCTION                                                        *
 ******************************************************************************/

/**
 *
 */
export function addingAgreementTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'adding_agreement');
}

/**
 *
 */
export function signWithYousignTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'sign_with_yousign');
}

/**
 *
 */
export function signWithDocusignTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'sign_with_docusign');
}

/**
 *
 */
export function validatingAgreementTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'validating_agreement');
}

/**
 *
 */
export function invalidatingAgreementTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'invalidating_agreement');
}

/**
 *
 */
export function cancelQuoteTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'cancel_quote');
}

/**
 *
 */
export function uncancelQuoteTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'uncancel_quote');
}

/**
 *
 */
export function validatingOrderTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'validating_order');
}

/**
 *
 */
export function advancePaymentPaidTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'advance_payment_paid');
}

/**
 *
 */
export function cancelOrderTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'cancel_order');
}

/**
 *
 */
export function cancelValidatedOrderTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'cancel_validated_order');
}

/**
 *
 */
export function cancelValidatedOrderNoBillTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'cancel_validated_order_no_bill');
}

/**
 *
 */
export function deliverTransition(id, data) {
  return baseTransitionFromId('carts', id, data, 'deliver');
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new cart
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return baseNewItem('carts', data);
}
