
export const ADD_CREDENTIALS = 'add_credentials';

export const REMOVE_CREDENTIALS = 'remove_credentials';

export function addCredentialsAction(id, username, authToken, refreshToken, roles, partners){
  return {
    type: ADD_CREDENTIALS,
    id: id,
    username: username,
    authToken: authToken,
    refreshToken: refreshToken,
    roles: roles,
    partners: partners
  }
}

export function removeCredentialsAction(){
  return {
    type: REMOVE_CREDENTIALS
  }
}
