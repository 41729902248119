import BaseAssert from './BaseAssert';

class LengthMinAssert extends BaseAssert {

  defaultConf = { min:0 };

  assert() {
    return (this.getValue() !== undefined && this.getValue() !== null)? this.getValue().length >= this.conf.min:null;
  }
}

export default LengthMinAssert;
