const cart = {
  tab: {
    main: "Informations générales",
    edit: "Editer"
  },
  search: {
    title: "Titre",
    owner_id: "ID du propriétaire",
    client_id: "ID du client",
    place_delivery_id: "ID du site de livraison",
    client_user_id: "ID de l'utilisateur client",
    created_at_min: "Date de création <",
    created_at_max: "Date de création >",
  },
  custom: {
    summary: {
      breadcrumb: "Panier actif"
    }
  }
};

export default cart;
