const dependency = {
  tab: {
    main: "Dépendance",
  },
  search: {
    name: "Nom"
  },
  new: {
    title: "Nouvelle dépendance"
  }
};

export default dependency;
