const productFamily = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Ce nom ne doit pas être vide."
    },
  },
  activeOrder: {
    label: "Activer pour les commandes ?",
  },
  activeQuote: {
    label: "Activer pour les devis ?",
  },
  activeTicket: {
    label: "Activer pour les tickets ?",
  },
  orderBreaking: {
    label: "Activer la séparation en commande ?",
  },
  serviceTracking: {
    label: "Activer le tracking de service ?",
  },
  serviceBreaking: {
    label: "Activer la séparation des services ?",
  },
  rentable: {
    label: "Activer la location ?",
  },
  displayMainIndex: {
    label: "Visible dans l'index produit ?",
  },
  vatPercentage: {
    label: "Pourcentage de T.V.A.",
    invalid: {
      assertEmpty: "Le pourcentage de T.V.A. ne doit pas être vide."
    },
  },
  accountSell: {
    label: "Comptabilité Vente"
  },
  accountSubscription: {
    label: "Comptabilité Abonnement"
  },
  accountWorkforce: {
    label: "Comptabilité MO"
  },
  accountTrip: {
    label: "Comptabilité DEP"
  },
  vats: {
    label: "T.V.A.s",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer",
    }
  },
  vat: {
    label: "T.V.A."
  }
};

export default productFamily;
