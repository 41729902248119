import strings from '../../Localization/Localization';

import { fetchProducts } from '../../API/Products';
import { fetchDependencies } from '../../API/Dependencies';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.productDependency.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.productDependency.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let displayname = {
  fieldKey: "displayname",
  type: "text",
  label: strings.form.productDependency.displayname.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min: 1
      },
      invalidFeedback: strings.form.productDependency.displayname.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let mandatory = {
  fieldKey: "mandatory",
  type: "switch",
  label: strings.form.productDependency.mandatory.label,
  defaultValue: false,
  validators: []
}

let reverseMandatory = {
  fieldKey: "reverse_mandatory",
  type: "switch",
  label: strings.form.productDependency.reverseMandatory.label,
  defaultValue: false,
  validators: []
}

let amendmentMandatory = {
  fieldKey: "amendment_mandatory",
  type: "switch",
  label: strings.form.productDependency.amendmentMandatory.label,
  defaultValue: false,
  validators: []
}

let mergePriceInMaster = {
  fieldKey: "merge_price_in_master",
  type: "switch",
  label: strings.form.productDependency.mergePriceInMaster.label,
  defaultValue: false,
  validators: []
}

let quantity = {
  fieldKey: "quantity",
  type: "dropdown",
  label: strings.form.productDependency.quantity.label,
  validators: [],

  input: 'options',
  showEmpty: true,
  options: [
    {
      value: 0,
      label: strings.form.productDependency.quantity.options.option0
    },
    {
      value: 1,
      label: strings.form.productDependency.quantity.options.option1
    }
  ],
}

/*
let productMasters = {
  fieldKey: "product_masters",
  type: "array",
  label: "",

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.productDependency.productMasters.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.productDependency.productMasters.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.productDependency.productMasters.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.productDependency.productMasters.label,

    validators: [],

    showEmpty: true,
    storage: "redux",
    shareStorage: true,
    filterOptions: (item, getValues) => !getValues(1)['product_masters'] || !getValues(1)['product_masters'].some((value) => value && value.id === item.id),
    loader: {
      id: "products",
      loader: fetchProducts,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  },

  validators: []
}
*/

let productMasters = {
  fieldKey: "product_masters",
  label: strings.form.productDependency.productMasters.label,
  type: "dropdownMultiEntityAlt",

  validators: [],

  showEmpty: true,
  storage: "redux",
  shareStorage: true,
  // filterOptions: (item, getValues) => !getValues(1)['product_masters'] || !getValues(1)['product_masters'].some((value) => value && value.id === item.id),
  loader: {
    id: "products",
    loader: fetchProducts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

/*
let productSlaves = {
  fieldKey: "product_slaves",
  type: "array",
  label: "",

  defaultNumberOfField: 1,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.productDependency.productSlaves.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.productDependency.productSlaves.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.productDependency.productSlaves.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.productDependency.productSlaves.label,

    validators: [],

    showEmpty: true,
    storage: "redux",
    shareStorage: true,
    filterOptions: (item, getValues) => !getValues(1)['product_slaves'] || !getValues(1)['product_slaves'].some((value) => value && value.id === item.id),
    loader: {
      id: "products",
      loader: fetchProducts,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  },

  validators: []
}
*/

let productSlaves = {
  fieldKey: "product_slaves",
  label: strings.form.productDependency.productSlaves.label,
  type: "dropdownMultiEntityAlt",

  validators: [],

  showEmpty: true,
  storage: "redux",
  shareStorage: true,
  // filterOptions: (item, getValues) => !getValues(1)['product_masters'] || !getValues(1)['product_masters'].some((value) => value && value.id === item.id),
  loader: {
    id: "products",
    loader: fetchProducts,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let dependency = {
  fieldKey: "dependency",
  label: strings.form.productDependency.dependency.label,
  type: "dropdownEntity",

  // disabledLink: (value) => value && partnersRoutes.routes.show.createPath(value["id"]),

  validators: [],
  assertObjectDefaultValue: true,

  showEmpty: true,
  loader: {
    id: "dependency",
    loader: fetchDependencies,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

export const form = {
  formKey: "productDependency",
  fields: [
    name,
    displayname,
    dependency,
    mandatory,
    reverseMandatory,
    amendmentMandatory,
    mergePriceInMaster,
    quantity
  ]
}

export const mastersForm = {
  formKey: "productDependency",
  fields: [
    productMasters
  ]
}

export const slavesForm = {
  formKey: "productDependency",
  fields: [
    productSlaves
  ]
}

export const productDependencySlaveForm = {
  formKey: "productSlaves",
  fields: [
    name,
    displayname,
    mandatory,
    reverseMandatory,
    amendmentMandatory,
    mergePriceInMaster,
    quantity,
    productSlaves
  ]
}

export const productDependencyMasterForm = {
  formKey: "productMasters",
  fields: [
    name,
    displayname,
    mandatory,
    reverseMandatory,
    amendmentMandatory,
    mergePriceInMaster,
    quantity,
    productMasters
  ]
}

export const newForm = {
  formKey: "productDependency",
  fields: [
    name,
    displayname,
    dependency,
    mandatory,
    reverseMandatory,
    amendmentMandatory,
    mergePriceInMaster,
    quantity,
    productMasters,
    productSlaves
  ]
}
