const phonePriceList = {
  tab: {
    main: "Détail",
    price: "Prix",
    completePrice: "Prix (complet)",
    newPrice: "Nouveau prix"
  },
  search: {
    name: "Nom"
  },
  new: {
    title: "Nouveau liste de prix"
  }
};

export default phonePriceList;
