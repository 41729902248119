const quote = {
  tab: {
    show: "Devis",
    cart: "Produit(s)",
    order: "Commande(s)"
  },
  tabs: {
    buttons: {
      notification: "Notifier",
      addingAgreement: "Ajouter BpA",
      signWithYousign: "Signer avec Yousign",
      sign: "Signer",
      validatingAgreement: "Valider devis",
      invalidatingAgreement: "Rejeter BpA",
      cancelQuote: "Annuler devis",
      uncancelQuote: "Réactiver",
      duplicate: "Dupliquer",
      copy: "Copier (articles)"
    }
  },
  search: {
    title: "Titre",
    number: "Numéro",
    orderNumber: "Numéro de commande",
    state: "Etat",
    validated: "Validé",
    client: "Client",
    placeDelivery: "Site",
    clientUser: "Utilisateur client",
    mainUser: "Utilisateur Nyukom",
    signatureDateTimeframe: "Plage de temps / Signature",
    signatureDateMin: "Date de signature (min)",
    signatureDateMax: "Date de signature (max)",
    createdAtTimeframe: "Plage de temps / Création",
    createdAtMin: "Date de création (min)",
    createdAtMax: "Date de création (max)"
  },
};

export default quote;
