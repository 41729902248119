import {
  ADD_OPTION,
  START_LOADING,
  ADD_ITEMS_TO_OPTION,
  RELOAD_OPTION
} from '../Action/optionsContainer'

const initialState = {}

export default function reduce(state = initialState, action){
  let newState
  switch (action.type) {
    case ADD_OPTION:
      newState = Object.assign({}, state);
      if(!newState[action.storageId]) {
        newState[action.storageId] = {
          owner: action.owner,
          loading: false,
          loaded: false,
          items: []
        }
      }
      break;
    case START_LOADING:
      newState = Object.assign({}, state);
      if(newState[action.storageId]) {
        newState[action.storageId].loading = true;
      }
      else {
        console.log('error in optionsContainer');
      }
      break;
    case ADD_ITEMS_TO_OPTION:
      newState = Object.assign({}, state);
      if(newState[action.storageId]) {
        newState[action.storageId].loading = false;
        newState[action.storageId].loaded = true;
        newState[action.storageId].options = action.options;
      }
      else {
        console.log('error in optionsContainer');
      }
      break;
    case RELOAD_OPTION:
      newState = Object.assign({}, state);
      if(newState[action.storageId]) {
        newState[action.storageId].loading = false;
        newState[action.storageId].loaded = false;
        newState[action.storageId].options = [];
      }
      else {
        console.log('error in optionsContainer');
      }
      break;
    default:
      newState = state;
  }
  return newState;
}
