const accountProduct = {
  title: {
    label: "Titre",
    invalid: {},
  },
  numbers: {
    label: "Numéro #key#",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  number: {
    label: "Numéro",
  },
  accountingType: {
    label: "Type de compte"
  },
  accountVat: {
    label: "Compte T.V.A."
  }
};

export default accountProduct;
