import strings from '../../../Localization/Localization';

import { fetchPartners } from '../../../API/Partners';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.place.name.label,
  showOnDisabled: () => false,
  validators: []
}

let partner = {
  fieldKey: "partner",
  label: strings.form.place.partner.label,
  type: "dropdownEntity",
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  loader: {
    id: "partner",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.place.number.label,
  showOnDisabled: () => false,
  validators: []
}

let way = {
  fieldKey: "way",
  type: "text",
  label: strings.form.place.way.label,
  showOnDisabled: () => false,
  validators: []
}

let zipCode = {
  fieldKey: "zipCode",
  type: "text",
  label: strings.form.place.zipCode.label,
  showOnDisabled: () => false,
  validators: []
}

let city = {
  fieldKey: "city",
  type: "text",
  label: strings.form.place.city.label,
  showOnDisabled: () => false,
  validators: []
}

let active = {
  fieldKey: "active",
  type: "triswitch",
  label: strings.form.place.active.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "partnerSearch",
  fields: [
    name,
    partner,
    number,
    way,
    zipCode,
    city,
    active
  ]
}
