import strings from '../../Localization/Localization';

let label = {
  fieldKey: "label",
  type: "text",
  label: strings.form.phoneZone.label.label,
  validators: []
}

export const form = {
  formKey: "phoneZoneAlias",
  fields: [
    label
  ]
}
