import strings from '../../Localization/Localization';

import { processTimeframe } from './common';

import { index, getFromId, editFromId, exportData } from '../../API/Bills';
import { form } from '../Form/bills';
import { form as searchForm } from '../Form/Search/bills';

import { bills as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.bills,

    id: "bill",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    export: {
      link: (search) => exportData(search),
      auth: ['ROLE_ACCOUNTING_BILL_EXTRACT']
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.number) {
        result["number"] = search.number;
      }
      if(search.partner && typeof search.partner === 'object') {
        result["partner_id"] = search.partner.id
      }
      if(search.amountMin) {
        result["amount_min"] = search.amountMin;
      }
      if(search.amountMax) {
        result["amount_max"] = search.amountMax;
      }
      if(search.createdAtTimeframe) {
        let createdAtTimeframe = processTimeframe(search.createdAtTimeframe);

        result["created_at_min"] = createdAtTimeframe.min;
        result["created_at_max"] = createdAtTimeframe.max;
      }
      if(search.createdAtMin) {
        result["created_at_min"] = search.createdAtMin;
      }
      if(search.createdAtMax) {
        result["created_at_max"] = search.createdAtMax;
      }
      if(search.partialBill) {
        result["partial_bill"] = 1;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            id: "number",
            label: strings.bill.index.tab.headers.number,
            value: "number"
          },
          {
            id: "client",
            label: strings.bill.index.tab.headers.client,
            value: (item) => {
              return item["client"]["partner"]["name"]
            },
            hide: (credentials) => {
              if(credentials.partners.length === 1) {
                return true;
              }
              return false;
            }
          },
          {
            id: "date",
            label: strings.bill.index.tab.headers.date,
            value: "created_at"
          },
          {
            id: "deadline",
            label: strings.bill.index.tab.headers.deadline,
            value: "deadline"
          },
          {
            id: "price",
            label: strings.bill.index.tab.headers.price,
            type: "price",
            value: "total_ti"
          },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.bills,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "number",

    apiGet: getFromId,

    extendedHeader: true,
    header: {
      imgUrl: (item) => null,
      placeholderTitle: (item) => item["client"]["partner"]["name"].split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr),
      label: (item) => item["client"]["partner"]["name"],
      additionalData1: (item) => `${Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(item["total_ti"])}/TTC`,
      additionalData2: (item) => '',
      additionalData3: (item) => '',
    },

    tabs: {
      main: {
        title: strings.bill.show.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (data) => {},

        apiGet: getFromId,
        apiEdit: editFromId,

        auth: ['ROLE_ACCOUNTING_BILL'],
        editable: false
      },
    }
  }
}
