import strings from '../../../Localization/Localization';

import { conf as serviceConf } from '../../Configuration/services';

import { fetchServices } from '../../../API/Services';
import { fetchPartners } from '../../../API/Partners';
import { fetchPlaces } from '../../../API/Places';
import { fetchCentreonServices } from '../../../API/CentreonService';

export const supervision = {
  id: "supervision",
  name: strings.dashboard.widget.supervision.name,
  icon: "icon-graph-bar",
  column: {
    min: 2,
    max: 4
  },
  row: {
    min: 2,
    max: 2
  },
  elementSettings: {
    service: {
      name: strings.dashboard.entity.service,
      fetchCentreonServices: fetchCentreonServices,
      fetchMetrics: serviceConf.show.tabs.centronChart.apiCentreon,
      role: "ROLE_CENTREON"
    },
  },
  visualSettings: [
    {
      fieldKey: "chartType",
      label: "Type de graphique",
      type: "dropdown",

      validators: [],

      input: 'options',
      options: [
        {
          value: "line",
          label: "Ligne"
        },
        {
          value: "bar",
          label: "Histogramme"
        },
      ],
    }
  ],
  dataSettings: [
    {
      fieldKey: "partner",
      label: "Partenaire",
      type: "dropdownEntity",
      showOnDisabled: () => true,

      validators: [],

      showEmpty: true,
      loader: {
        id: "partner",
        loader: fetchPartners,
        loaderRequire: () => true,
        loaderArguments: () => [],
      },

      adapter: {
        requirement: (data) => data["id"] && data["name"],
        getValue: (data) => data["id"],
        getLabel: (data) => data["name"],
      },
    },
    {
      fieldKey: "place",
      label: "Site",
      type: "dropdownEntity",
      showOnDisabled: () => true,

      validators: [],

      events: [
        {
          id: "reloadFromPartner",
          event: "onChange",
          target: "partner",
          function: (component) => component.reloadData()
        }
      ],

      showEmpty: true,
      loader: {
        id: "place",
        loader: fetchPlaces,
        loaderRequire: () => true,
        loaderArguments: (value, getValue) => {
          let filters = {};
          let values = getValue(0);
          if(values["partner"] && values["partner"]["id"]) {
            filters["partner_id"] = values["partner"]["id"]
          }
          return [filters];
        },
      },

      adapter: {
        requirement: (data) => data["id"] && data["name"],
        getValue: (data) => data["id"],
        getLabel: (data) => data["name"],
      },
    },
    {
      fieldKey: "service",
      label: "Service",
      type: "dropdownEntity",
      showOnDisabled: () => true,

      validators: [],

      events: [
        // {
        //   id: "reloadFromPartner",
        //   event: "onChange",
        //   target: "partner",
        //   function: (component) => component.reloadData()
        // },
        {
          id: "reloadFromPlace",
          event: "onChange",
          target: "place",
          function: (component) => component.reloadData()
        }
      ],

      showEmpty: true,
      loader: {
        id: "services",
        loader: fetchServices,
        loaderRequire: () => true,
        loaderArguments: (value, getValues) => {
          let filters = {};
          let values = getValues();
          if(values["partner"] && values["partner"]["id"]) {
            filters["partner_id"] = values["partner"]["id"]
          }
          if(values["place"] && values["place"]["id"]) {
            filters["place_id"] = values["place"]["id"]
          }
          return [filters];
        },
      },

      adapter: {
        requirement: (data) => typeof data === 'object' && data["id"] && data["cart_item"],
        getValue: (data) => data["id"],
        getLabel: (data) => `${data["serial_number"]?data["serial_number"]+' ~ ':''}${data["cart_item"]["product"]["name"]}`,
      },
    },
    {
      fieldKey: "centreonService",
      label: "Données",
      type: "dropdownEntity",
      showOnDisabled: () => true,

      validators: [],

      events: [
        // {
        //   id: "reloadFromPartner",
        //   event: "onChange",
        //   target: "partner",
        //   function: (component) => component.reloadData()
        // },
        // {
        //   id: "reloadFromPlace",
        //   event: "onChange",
        //   target: "place",
        //   function: (component) => component.reloadData()
        // },
        {
          id: "reloadFromService",
          event: "onChange",
          target: "service",
          function: (component) => component.reloadData()
        }
      ],

      showEmpty: true,
      loader: {
        id: "centreonServices",
        loader: fetchCentreonServices,
        loaderRequire: () => true,
        loaderArguments: (value, getValues) => {
          let filters = {};
          let values = getValues();
          if(values["partner"] && values["partner"]["id"]) {
            filters["partner_id"] = values["partner"]["id"]
          }
          if(values["place"] && values["place"]["id"]) {
            filters["place_id"] = values["place"]["id"]
          }
          if(values["service"] && values["service"]["id"]) {
            filters["service_id"] = values["service"]["id"]
          }
          return [filters, 'main'];
        },
      },

      adapter: {
        requirement: (data) => data["id"] && data["name"] && data["host"] && data["host"]["name"] && data["template"] && data["template"]["name"],
        getValue: (data) => data["id"],
        getLabel: (data) => `${data["host"]["name"]} > ${data["name"]} > ${data["template"]["name"]}`,
      },
    }
  ]
}
