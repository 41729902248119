export const stringToBase64 = (text) => {
  text = new TextEncoder().encode(text);
  text = Array.from(text, (byte) =>
    String.fromCodePoint(byte),
  ).join("");
  return btoa(text);
}

export const base64ToString = (text) => {
  text = atob(text);
  text = Uint8Array.from(text, (m) => m.codePointAt(0));
  return new TextDecoder().decode(text);
}
