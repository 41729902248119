import React from 'react';

import { Carousel } from 'react-bootstrap';
// import strings from '../../Localization/Localization';

import '../../css/tutorial_carousel.css';

/**
 * TutorialCarousel
 */
class TutorialCarousel extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Carousel
        className="tutorial-carousel"
        interval={null}
        wrap={false}>
        <Carousel.Item>
          <div>
            Test 1
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div>
            Test 2
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div>
            Test 3
          </div>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default TutorialCarousel;
