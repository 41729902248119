const partner = {
  name: {
    label: "Nom",
    invalid: {
      assertEmpty: "Le nom du type de partenaire ne doit pas être vide.",
    },
  },
  orderAvailable: {
    label: "Ce type de partenaire a-t-il accès aux commandes ?",
  },
  vatApplicable: {
    label: "Ce type de partenaire paie-t-il de la T.V.A. ?",
  },
  vatComment: {
    label: "Commentaire non T.V.A.",
  },
  accountingPrefix: {
    label: "Préfix comptable",
    invalid: {
      assertEmpty: "Le préfix comptable ne doit pas être vide.",
    },
  },
  accountingType: {
    label: "Comptabilité",
  },
};

export default partner;
