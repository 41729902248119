import React from 'react';

/**
 * TableBody
 */
class TableBody extends React.Component {

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <tbody>
        {this.props.children}
      </tbody>
    );
  }
}

export default TableBody;
