import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  editFromId,
  newItem
} from '../../API/PhonePrefixes';
import {
  form
} from '../Form/phonePrefixes';
import { form as searchForm } from '../Form/Search/phonePrefixes';

import { phonePrefix as routes } from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.phonePrefix,

    id: "phonePrefix",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.prefix) {
        result["prefix"] = search.prefix;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => `${item["prefix"]}`
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: (item) => []
  },
  show: {
    pageTitle: strings.common.homepage.phonePrefix,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "prefix",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.phonePrefix.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_CDR_CONFIGURATION'],
        editable: ['ROLE_CDR_CONFIGURATION']
      },
    }
  },
  new: {
    pageTitle: strings.phonePrefix.new.title,

    api: newItem,
    empty: {},
    form: form,
    onSubmit: (phonePrefix) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
