import {
  CREATE_CONTAINER,
  MODIFY_FORM_VALUE,
  RESET_CONTAINER
} from '../Action/process'

const initialState = {}

export default function reduce(state = initialState, action){
  let newState
  switch (action.type) {
    case CREATE_CONTAINER:
      newState = Object.assign({}, state);
      newState[action.processId] = {};
      action.steps.forEach((step, i) => {
        newState[action.processId][step.id] = {}
      });
      break;
    case MODIFY_FORM_VALUE:
      newState = Object.assign({}, state);
      if(action.stepId === null) {
        let newForm = Object.assign({}, newState[action.processId]);
        newForm[action.key] = action.value;
        newState[action.processId] = newForm;
      }
      else {
        let newForm = Object.assign({}, newState[action.processId][action.stepId]);
        newForm[action.key] = action.value;
        newState[action.processId][action.stepId] = newForm;
      }
      break;
    case RESET_CONTAINER:
      newState = Object.assign({}, state);
      newState[action.processId] = undefined;
      break;
    default:
      newState = state;
  }

  return newState;
}
