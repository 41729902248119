const appointmentType = {
  tab: {
    main: "Type",
  },
  search: {
    name: "Nom"
  },
  new: {
    title: "Nouveau type d'intervention"
  }
};

export default appointmentType;
