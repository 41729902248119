export const dashboard = {
  name: "Technique",
  version: 1,

  dashboardSize: 8,

  widgets: [
    {
      id: 'technical_service_list-1',
      position: 2
    },
    {
      id: 'technical_ticket_list-1',
      position: 5
    },
    {
      id: 'technical_pie_ticket_state-1',
      position: 8
    },
    {
      id: 'technical_pie_ticket_criticity-1',
      position: 9
    },
    {
      id: 'technical_centreon-1',
      position: 16
    },
    {
      id: 'technical_ticket_stat-1',
      position: 19
    }
  ]
}
