import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  editFromId,
  newItem
} from '../../API/AccountProducts';
import { form } from '../Form/accountProducts';
import { form as searchForm } from '../Form/Search/accountProducts';

import { accountProducts as routes } from '../Route/routes';

export const conf = {
  index: {
    id: "accountProduct",
    pageTitle: strings.common.homepage.accountProducts,
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    newLink: {
      link: () => routes.routes.new.createPath(),
      auth: 'ROLE_ACCOUNTING_ACCOUNT'
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.title) {
        result["title"] = search.title;
      }
      if(search.number) {
        result["number"] = search.number;
      }
      return result;
    },

    availableItemDisplay:['tab'],
    itemDisplay: {
      tab: {
        headers: [
          {
            label: strings.accountProduct.index.tab.headers.title,
            value: "title"
          },
        ]
      }
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },

    buttons: (item) => []
  },
  show: {
    pageTitle: strings.common.homepage.accountProducts,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "title",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.accountProduct.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (entity) => {},
        postEdit: {
          type: 'tab',
          tabId: 'main'
        },

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_ACCOUNTING_ACCOUNT'],
        editable: ['ROLE_ACCOUNTING_ACCOUNT']
      }
    }
  },
  new: {
    pageTitle: strings.accountProduct.new.title,

    api: newItem,
    empty: {},
    form: form,
    onSubmit: (accountProduct) => {},
    postNew: {
      type: 'path',
      path: (id) => routes.routes.show.createPath(id)
    }
  }
}
