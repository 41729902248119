const partner = {
  index: {
    tab: {
      headers: {
        number: "Numéro",
        name: "Nom",
        legalForm: "Forme légale",
        status: "Statut"
      }
    }
  },
  tab: {
    main: "Partenaire",
    places: "Site(s)",
    accounts: "Comptes",
    bankInformation: "Informations bancaires",
    specificPrices: "Prix spécifiques",
    accounting: "Comptabilité"
  },
  search: {
    name: "Nom",
    partnerNumber: "Numéro",
    siret: "Siret",
    legalForm: "Forme légale",
    prospect: "Prospect",
    active: "Actif",
    partnerType: "Type",
    waitingTicketBilling: "En attente de facture ticket"
  },
  tabs: {
    buttons: {
      updatePaymentFailure: {
        labelActivate: "Mettre défaut de paiement",
        labelDeactivate: "Enlever défaut de paiement",
        flashbagMessage: "Votre demande a été validé.",
        flashbagMessageFailure: "Votre demande n'a pas pu aboutir."
      },
      billCreator: "Nouvelle facture",
      billCreatorModal: {
        title: "Choix du compte partenaire ?",
        successButton: "Valider",
        error: "Veuillez sélectionner un compte client."
      },
      creditNoteCreator: "Nouvel avoir",
      creditNoteCreatorModal: {
        title: "Choix du compte partenaire ?",
        successButton: "Valider",
        error: "Veuillez sélectionner un compte client."
      },
    }
  },
  new: {
    title: "Nouveau partenaire"
  }
};

export default partner;
