import LocalizedStrings from 'react-localization';

import fr from './dictionary/fr/fr';
import en from './dictionary/en/en';

let strings = new LocalizedStrings({
  fr: fr,
  en: en,
});

strings.resolve = (value) => {
  let words = value.split('.');
  let label = strings;
  words.forEach((word, i) => {
    if(label) {
      label = label[word];
    }
  });
  if(typeof label === 'string') {
    return label;
  }
  else {
    return value;
  }
}

export default strings;
