import React from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * Breadcrumb
 */
class Link extends React.Component {

  static defaultProps = {
    displayIdentifier: true,
    displayAsTooltip: true,
    displaySeparator: true,

    href: '#',
    title: 'title',
    identifier: 'identifier'
  }

  displaySeparator() {
    if(this.props.displaySeparator) {
      return (
        <i className="icon-chevron-right"/>
      )
    }
  }

  displayIdentifier() {
    return this.props.identifier
  }

  displayTitle() {
    return this.props.title
  }

  displayLink() {
    if(this.props.displayIdentifier) {
      if(this.props.displayAsTooltip) {
        return (
          <div>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>{this.displayIdentifier()}</Tooltip>
              }>
              <div>{this.displayTitle()}</div>
              </OverlayTrigger>
          </div>
        )
      }
      else {
        return (
          <div>{this.displayTitle()} {this.displayIdentifier()}</div>
        )
      }
    }
    else {
      return (
        <div>{this.displayTitle()}</div>
      );
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        {this.displaySeparator()}
        <a href={this.props.href} className="breadcrumb-link">{this.displayLink()}</a>
      </>
    );
  }
}

export default Link;
