const ticketFile = {
  filename: {
    label: "Fichier",
    invalid: {
      assertEmpty: "Le commentaire ne doit pas être vide."
    },
  },
};

export default ticketFile;
