import strings from '../../Localization/Localization';

import { fetchAccountingTypes } from '../../API/AccountingTypes';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.partnerType.name.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min:1
      },
      invalidFeedback: strings.form.partnerType.name.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let orderAvailable = {
  fieldKey: "order_available",
  type: "switch",
  label: strings.form.partnerType.orderAvailable.label,
  validators: []
}

let vatApplicable = {
  fieldKey: "vat_applicable",
  type: "switch",
  label: strings.form.partnerType.vatApplicable.label,
  validators: []
}

let vatComment = {
  fieldKey: "vat_comment",
  type: "text",
  label: strings.form.partnerType.vatComment.label,
  validators: []
}

let accountingPrefix = {
  fieldKey: "accounting_prefix",
  type: "text",
  label: strings.form.partnerType.accountingPrefix.label,
  validators: [
    {
      id: 'assertEmpty',
      type: 'lengthMin',
      conf: {
        min:1
      },
      invalidFeedback: strings.form.partnerType.accountingPrefix.invalid.assertEmpty,
      defaultValue: false
    },
  ]
}

let accountingType = {
  fieldKey: "accounting_type",
  type: "dropdownEntity",
  label: strings.form.partnerType.accountingType.label,
  validators: [],

  showEmpty: true,
  loader: {
    id: "accountingTypes",
    loader: fetchAccountingTypes,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["title"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["title"],
  },
}

export const form = {
  formKey: "partnerType",
  fields: [
    name,
    orderAvailable,
    vatApplicable,
    vatComment,
    accountingPrefix,
    accountingType
  ]
}
