import React from 'react';

import TableHeader from './TableHeader';
import TableBody from './TableBody';

import TableTextHeader from './Header/TableTextHeader';
import TableDropdownHeader from './Header/TableDropdownHeader';

import TableCell from './Body/TableCell';

import TableRowCollapsedRow from './Body/TableRowCollapsedRow'

import '../../css/table.css';


/**
 * MyTable
 */
class Table extends React.Component {

  static defaultProps = {
    showHeader: true
  }

  constructor(props) {
    super(props);

    this.state = {
      headers: []
    }
  }

  static getDerivedStateFromProps(props, state) {
    let headers = [];
    let header = props.children.find((item) => item.type === TableHeader);
    let modified = false;
    if(header && header.props.children) {
      header.props.children.forEach((child, i) => {
        if(child) {
          switch (child.type) {
            case TableTextHeader:
              let curr = {
                value: child.props.value,
                type: (child.props.type)? child.props.type:undefined,
                href: (child.props.href)? child.props.href:undefined,
              }
              headers[i] = curr;
              if(!(state.headers[i]
                && state.headers[i].value === curr.value
                && state.headers[i].type === curr.type
                && state.headers[i].href === curr.href))
              {
                modified = true;
              }
              break;
            case TableDropdownHeader:
              headers[i] = {
                value: child.props.defaultValue,
                href: child.props.href
              }
              break;
            default:
              console.error("Table header not recognised : " + child.type);
          }
        }
      })
      if(header.props.children.length < state.headers.length) {
        modified = true;
      }
    }

    if(modified) {
      return {
        headers: headers
      }
    }
    return null;
  }

  onHeaderValueChange = (index, value) => {
    let headers = [...this.state.headers];
    headers[index].value = value;
    this.setState({
      headers: headers
    });
  }

  displayColgroup() {
    let colgroup = this.props.children.find((item) => item.type === 'colgroup');
    if(colgroup) {
      return colgroup
    }
  }

  displayHeader() {
    let header = this.props.children.find((item) => item.type === TableHeader);
    if(this.props.showHeader && header && header.props.children) {
      return (
        <TableHeader subRows={header.props.subRows}>
          {header.props.children.map((child, i) => {
            if(child !== null) {
              switch (child.type) {
                case TableTextHeader:
                  return child
                case TableDropdownHeader:
                  return React.cloneElement(child, {
                    key: i,
                    label: child.props.fieldList.find((item) => item.value === child.props.defaultValue).label,
                    onChange: (value) => this.onHeaderValueChange(i, value)
                  })
                default:

              }
            }
            return null;
          })}
        </TableHeader>
      )
    }
    return null;
  }

  displayBody() {
    let body = this.props.children.find((item) => item.type === TableBody);
    let headers = this.props.children.find((item) => item.type === TableHeader);
    if(body && body.props.children && headers && headers.props.children) {
      return (
        <>
          {body.props.children.map((child, i) => {
            return (
              <TableBody
                key={i}>
                {React.cloneElement(child, {
                  nbOfColumns: this.state.headers.length,
                  children: this.buildRowChildren(child, i, headers)
                })}
              </TableBody>
            )
          })}
        </>
      )
    }
    return null;
  }

  buildRowChildren(row, i, headers) {
    let children = [];

    if(typeof row.props.children === 'object' && row.props.children !== null && !Array.isArray(row.props.children)) {
      children.push(row.props.children);
    }
    else if(Array.isArray(row.props.children)) {
      row.props.children
        .filter(child => child)
        .forEach((child, i) => {
          switch (child.type) {
            case React.Fragment:
              let fragments = [child];
              while(fragments.length > 0) {
                fragments[0].props.children.forEach((item, i) => {
                  switch (item.type) {
                    case TableRowCollapsedRow:
                      children.push(item);
                      break;
                    case React.Fragment:
                      fragments.push(item);
                      break;
                    default:
                  }
                });
                fragments.splice(0,1);
              }
              break;
            case TableRowCollapsedRow:
              break;
            default:
              children.push(child);
          }
        }
      );
    }

    return [
      ...children.map((child, k) => {
        switch (child.type) {
          case TableRowCollapsedRow:
            return React.cloneElement(child, {
              nbOfColumns: this.state.headers.length,
              children: this.buildRowChildren(child, k, headers)
            })
          default:
            return child;
        }
      }),
      ...this.state.headers.map((header, j) => {
        let data = row.props.data;
        if(data) {
          if(header.type) {
            if(this.props.cellCollection !== undefined) {
              let type = null;
              if(typeof header.type === "function") {
                type = header.type(data, i);
              }
              else {
                type = header.type;
              }
              let element = this.props.cellCollection[type];
              if(element !== undefined && element !== null) {
                let value = null;
                if(typeof header.value === "function") {
                  value = header.value(data, i);
                }
                else {
                  value = data[header.value];
                }
                let props = {
                  key: header.value+'-'+i+'-'+j,
                  _rowId: (data._rowId !== undefined)?data._rowId:i,
                  _valueId: header.value,
                  value: value,
                  data: data,
                  colSpan: headers.props.children[j].props.itemColSpan,
                  additionalConfiguration: headers.props.children[j].props.additionalConfiguration?headers.props.children[j].props.additionalConfiguration:{},
                  href: header.href?header.href(data):null
                }
                return React.createElement(element, props)
              }
              else {
                console.error("Table error: trying to use a specific cell element but the cell type is not present in the cell collection");
                return (
                  <TableCell key={data[header.value]+'-'+i+'-'+j} colSpan={header.itemColSpan} value='' data={data} href={header.href?header.href(data):null} />
                )
              }
            }
            else {
              console.error("Table error: trying to use a specific cell element but cell collection is not defined");
              return (
                <TableCell key={data[header.value]+'-'+i+'-'+j} colSpan={header.itemColSpan} value='' data={data} href={header.href?header.href(data):null} />
              )
            }
          }
          else {
            let value = null;
            if(typeof header.value === "function") {
              value = header.value(data, i);
            }
            else {
              value = data[header.value];
            }
            return (
              <TableCell key={value+'-'+i+'-'+j} colSpan={header.itemColSpan} value={value} data={data} href={header.href?header.href(data):null} />
            )
          }
        }
        return null;
      }
    )]
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <table className={`table-my-primary${(this.props.className !== undefined)?` ${this.props.className}`:""}`}>
        {this.displayColgroup()}
        {this.displayHeader()}
        {this.displayBody()}
      </table>
    );
  }
}

export default Table;
