const buyingPrice = {
  company: {
    label: "Fournisseur",
    invalid: {
      assertEmpty: "Le fournisseur ne doit pas être vide."
    },
  },
  reference: {
    label: "Code fournisseur",
    invalid: {
      assertEmpty: "Ce code ne doit pas être vide.",
    }
  },
  price: {
    label: "Prix",
  }
};

export default buyingPrice;
