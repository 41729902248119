import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, InputGroup, Button, Col } from 'react-bootstrap';
import '../../../css/form.css';

import strings from '../../../Localization/Localization';

import LengthMinAssert from '../../../Form/Assert/LengthMinAssert';
import LengthMaxAssert from '../../../Form/Assert/LengthMaxAssert';
import ForbiddenCharactersAssert from '../../../Form/Assert/ForbiddenCharactersAssert';
import PasswordEqualAssert from '../../../Form/Assert/PasswordEqualAssert';

import { randomPassword } from '../../../API/Users';

import Loading from '../../Layout/Loading';

/**
 * FormFieldPassword
 *
 * This class handles HTML form field.
 *
 * The particular field is for Password, there is a second field for spellcheck.
 * It has two more buttons at the right of the field :
 * - One for randomized the password
 * - One to peek the password
 *
 * Specific configuration attribute :
 * - showVerification : show the second field or not
 * - showPeekPassword : show the peek button
 * - showRandomizedPassword : show the randomize password button
 * - verification : information relative to the second field (label)
 */
class FormFieldPassword extends BasicFormField {

  static fieldIdentifiers = {
    main: "main",
    repeat: "repeat"
  }

  /**
   * TODO may change default to restriction using propTypes
   * default Props used in the class.
   */
  static defaultProps = {
    showVerification: true,
    showPeekPassword: true,
    showRandomizedPassword: true,
    verification: {
      label: ""
    }
  }

  constructor(props) {
    super(props);

    //state of the Component
    //peekPassword : the current peeking state for the password field
    //peekVerification : the current peeking state for the verification field
    this.state = Object.assign(this.state, {
      loading: false,
      peekPassword: false,
      peekVerification: false,
      repeatField: "",
    });

    //add verification callback to password randomizer
    this.randomPassword = this.props.modalHandler.addVerificationWithCallback(randomPassword, this.randomizePasswordCallback, this.randomizePasswordCallbackFailure)
  }

  /**
   * @inheritdoc
   */
  getSpecificValidators() {
    return [
      {
        id: 'assertEqual',
        type: 'passwordEqual',
        invalidFeedback: strings.form.default.field.password.invalid.assertEqual,
        defaultValue: true,
        target: FormFieldPassword.fieldIdentifiers.repeat
      },
    ]
  }

  /**
   * @inheritdoc
   */
  getSpecificAsserts() {
    return {
      lengthMin: LengthMinAssert,
      lengthMax: LengthMaxAssert,
      forbiddenCharacters: ForbiddenCharactersAssert,
      passwordEqual: PasswordEqualAssert,
    }
  }

  /**
   * @inheritdoc
   */
  getAssertExtraData() {
    return {
      repeatField: this.state.repeatField
    };
  }

  /**
   * Triggered when clicking the randomize password button
   * start randomization
   */
  randomizePassword = () => {
    if(!this.props.disabled) {
      this.setState({
        loading: true,
      }, () => this.randomPassword());
    }
  }

  /**
   * Collect the result of the password randomization API
   * end of the randomization
   */
  randomizePasswordCallback = (data) => {
    this.setState({
      loading: false,
      repeatField: data.password
    }, () => this.onChange(data.password));
  }

  randomizePasswordCallbackFailure = (msg) => {
    this.setState({
      loading: false
    })
  }

  displayLoading() {
    if(this.state.loading) {
      return (
        <InputGroup.Append>
          <Loading container="parent" containerClassName="loading-form-row" size="small"/>
        </InputGroup.Append>
      )
    }
  }

  /**
   * Display the main password field
   */
  displayMainField() {
    return (
      <Col>
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid(FormFieldPassword.fieldIdentifiers.main))?" is-valid":""}${(!this.props.disabled && this.isInvalid(FormFieldPassword.fieldIdentifiers.main))?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          <Form.Control
            className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius"
            placeholder={this.props.label}
            aria-label={this.props.label}
            aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
            type={this.state.peekPassword?"text":"password"}
            disabled={this.props.disabled || this.props.submitting}
            value={this.getValue()}
            onChange={(event) => this.onChange(event.target.value)}
            isValid={(this.props.disabled)?null:this.isValid(FormFieldPassword.fieldIdentifiers.main)}
            isInvalid={(this.props.disabled)?null:this.isInvalid(FormFieldPassword.fieldIdentifiers.main)}
          />
          {this.displayValidating()}
          {this.displayLoading()}
          {this.props.showRandomizedPassword?
            <InputGroup.Append>
              <Button
                variant="my-primary-noline"
                onClick={this.randomizePassword}>
                <i className="icon-media-shuffle"></i>
              </Button>
            </InputGroup.Append>
          :null}
          {this.props.showPeekPassword?
            <InputGroup.Append>
              <Button
                variant="my-secondary-noline"
                //onMouseEnter={(event) => this.setState({ peekPassword: true })}
                //onMouseLeave={(event) => this.setState({ peekPassword: false })}
                onClick={() => this.setState({peekPassword: !this.state.peekPassword})}>
                <i className={`icon-${this.state.peekPassword?'masquer':'preview'}`}></i>
              </Button>
            </InputGroup.Append>
          :null}
        </InputGroup>
        {this.displayValidValidators(FormFieldPassword.fieldIdentifiers.main)}
        {this.displayInvalidValidators(FormFieldPassword.fieldIdentifiers.main)}
      </Col>
    );
  }

  /**
   * Display the verification password field
   */
  displayVerificationField() {
    if(this.props.showVerification) {
      return (
        <Col>
          <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid(FormFieldPassword.fieldIdentifiers.repeat))?" is-valid":""}${(!this.props.disabled && this.isInvalid(FormFieldPassword.fieldIdentifiers.repeat))?" is-invalid":""}`}>
            <InputGroup.Prepend
              className="d-none d-md-flex col-md-4 container-form-prepend">
              <InputGroup.Text
                id={`form-${this.props.formKey}-${this.props.fieldKey}-verification`}
                className="col-md-12">
                {this.props.verification.label}
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius"
              placeholder={this.props.label}
              aria-label={this.props.verification.label}
              aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}-verification`}
              type={this.state.peekVerification?"text":"password"}
              disabled={this.props.disabled || this.props.submitting}
              value={this.state.repeatField}
              onChange={(event) => this.setState({repeatField: event.target.value}, () => this.startValidation())}
              isValid={(this.props.disabled)?null:this.isValid(FormFieldPassword.fieldIdentifiers.repeat)}
              isInvalid={(this.props.disabled)?null:this.isInvalid(FormFieldPassword.fieldIdentifiers.repeat)}
            />
            {this.displayValidating()}
            {this.props.showPeekPassword?
              <InputGroup.Append>
                <Button
                  variant="my-secondary-noline"
                  onClick={() => this.setState({peekVerification: !this.state.peekVerification})}>
                  <i className={`icon-eye${this.state.peekVerification?'':''}`}></i>
                </Button>
              </InputGroup.Append>
            :null}
          </InputGroup>
          {this.displayValidValidators(FormFieldPassword.fieldIdentifiers.repeat)}
          {this.displayInvalidValidators(FormFieldPassword.fieldIdentifiers.repeat)}
        </Col>
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div className="container-form-field">
        {this.displayMainField()}
        {this.displayVerificationField()}
      </div>
    );
  }
}

export default FormFieldPassword;
