import React from 'react';

import { useDrag } from 'react-dnd';

import { getEmptyImage } from 'react-dnd-html5-backend';

import { ItemTypes } from '../../../DnD/Constant';

export default function DashboardStoredWidget({ id, title, width, children, notifyIsDragging, ...additionalProps }) {

  const [{ canDrag }, drag, preview] = useDrag({
    type: ItemTypes.DASHBOARD_GRID_WIDGET,
    item: () => {
      return {
        id: id,
        title: title,
        width: width
      };
    },
    collect: (monitor) => ({
      canDrag: monitor.canDrag()
    }),
  });

  preview(getEmptyImage(), { captureDraggingState: true })

  notifyIsDragging(!canDrag);

  return (
    <div
      ref={drag}
      style={{
      height: '100%',
      width: '100%',
      cursor: 'move'
      }}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, additionalProps);
        }
        return child;
      })}
    </div>
  );
};
