import React from 'react';
import PropTypes from 'prop-types';

import {
  Title,
  Color,

  Parameter,

  List,
  ListStat,
  Objective,

  StateMachine,

  Supervision
} from './WidgetReference';

/**
 * WidgetSelector
 */
class WidgetSelector extends React.Component {

  static defaultProps = {
    preview: false
  }

  /**
   * Main render method for React Component
   */
  render() {
    //select the field using type of the field
    switch (this.props.type) {
      case "title":
        return (
          <Title
            id={this.props.id}
            title={this.props.title}
            conf={this.props.conf}
            column={this.props.column}
            row={this.props.row}

            startEditConf={this.props.startEditConf}

            widgetType={this.props.widgetType}

            parameterBag={this.props.parameterBag}
            modifyParameterBag={this.props.modifyParameterBag}

            history={this.props.history}
            credentials={this.props.credentials}
          />
        );
      case "color":
        return (
          <Color
            id={this.props.id}
            title={this.props.title}
            conf={this.props.conf}
            column={this.props.column}
            row={this.props.row}

            startEditConf={this.props.startEditConf}

            widgetType={this.props.widgetType}

            parameterBag={this.props.parameterBag}
            modifyParameterBag={this.props.modifyParameterBag}

            history={this.props.history}
            credentials={this.props.credentials}
          />
        );

      case "parameter":
        return (
          <Parameter
            id={this.props.id}
            title={this.props.title}
            conf={this.props.conf}
            column={this.props.column}
            row={this.props.row}

            startEditConf={this.props.startEditConf}

            widgetType={this.props.widgetType}

            parameterBag={this.props.parameterBag}
            modifyParameterBag={this.props.modifyParameterBag}

            history={this.props.history}
            credentials={this.props.credentials}
          />
        );

      case "list":
        return (
          <List
            id={this.props.id}
            title={this.props.title}
            conf={this.props.conf}
            column={this.props.column}
            row={this.props.row}

            startEditConf={this.props.startEditConf}

            widgetType={this.props.widgetType}

            parameterBag={this.props.parameterBag}
            modifyParameterBag={this.props.modifyParameterBag}

            history={this.props.history}
            credentials={this.props.credentials}
          />
        );

      case "listStat":
        return (
          <ListStat
            id={this.props.id}
            title={this.props.title}
            conf={this.props.conf}
            column={this.props.column}
            row={this.props.row}

            startEditConf={this.props.startEditConf}

            widgetType={this.props.widgetType}

            parameterBag={this.props.parameterBag}
            modifyParameterBag={this.props.modifyParameterBag}

            history={this.props.history}
            credentials={this.props.credentials}
          />
        );

      case "objective":
        return (
          <Objective
            id={this.props.id}
            title={this.props.title}
            conf={this.props.conf}
            column={this.props.column}
            row={this.props.row}

            startEditConf={this.props.startEditConf}

            widgetType={this.props.widgetType}

            parameterBag={this.props.parameterBag}
            modifyParameterBag={this.props.modifyParameterBag}

            history={this.props.history}
            credentials={this.props.credentials}
          />
        );

      case "stateMachine":
        return (
          <StateMachine
            id={this.props.id}
            title={this.props.title}
            conf={this.props.conf}
            column={this.props.column}
            row={this.props.row}

            startEditConf={this.props.startEditConf}

            widgetType={this.props.widgetType}

            parameterBag={this.props.parameterBag}
            modifyParameterBag={this.props.modifyParameterBag}

            history={this.props.history}
            credentials={this.props.credentials}
          />
        );

      case "supervision":
        return (
          <Supervision
            id={this.props.id}
            title={this.props.title}
            conf={this.props.conf}
            column={this.props.column}
            row={this.props.row}

            startEditConf={this.props.startEditConf}

            widgetType={this.props.widgetType}

            parameterBag={this.props.parameterBag}
            modifyParameterBag={this.props.modifyParameterBag}

            history={this.props.history}
            credentials={this.props.credentials}
          />
        );

      default:
    }
    return <div/>;
  }
}

//use propTypes to check if the attribute are all present
WidgetSelector.propTypes = {
  type: PropTypes.oneOf([
    'color',
    'title',

    'parameter',

    'list',
    'listStat',
    'objective',

    'stateMachine',

    'supervision'
  ]),
  conf: PropTypes.any,
}

export default WidgetSelector;
