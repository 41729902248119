//import strings from '../../Localization/Localization';

import { Colors, FontFamily } from '../../Echart/Constant';

export const chartOption = (data, theme = 'dark', fontSize = 15, additionalOption = {}) => {

  let textColor = null;
  let textShadowColor = null;

  switch (theme) {
    case 'light':
      textColor = 'black'
      textShadowColor = 'transparent'
      break;
    case 'dark':
    case 'dark_colorblind':
    default:
      textColor = 'white'
      textShadowColor = 'black'
  }

  return {
    color: Colors,
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        padAngle: 3,
        itemStyle: {
          borderRadius: 1
        },
        label: {
          show: false,
          position: 'center',
          fontSize: fontSize,

          formatter: '{b}\n{c}',

          color: textColor,
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontFamily: FontFamily,

          textShadowColor: textShadowColor,
          textShadowBlur: 7,
          textShadowOffsetX: 0,
          textShadowOffsetY: 0
        },
        emphasis: {
          label: {
            show: true,
          }
        },
        data: data
      }
    ]
  }
}
