import React from 'react';
import BasicFormField from './BasicFormField';

import { v4 as uuid } from 'uuid';
import { config } from '../../../API/config';

import { Form, InputGroup, Col, Button } from 'react-bootstrap';
import '../../../css/form.css';

import NotEmptyOrNullAssert from '../../../Form/Assert/NotEmptyOrNullAssert';

/**
 * FormFieldText
 *
 * This class handles HTML form field.
 *
 * The particular field is for text.
 *
 * Specific configuration attribute : none
 */
class FormFieldText extends BasicFormField {

  constructor(props) {
    super(props);

    this.inputId = `form-${this.props.formKey}-${this.props.fieldKey}-${uuid()}`;

    this.state = {
      ...this.state,
      files: []
    }
  }

  /**
   * @inheritdoc
   */
  getSpecificAsserts() {
    return {
      notEmptyOrNull: NotEmptyOrNullAssert,
    }
  }

  /**
   * @inheritdoc
   */
  getValue() {
    if(this.state.files.length >= 1) {
      return this.state.files[0];
    }
    return (this.props.value)?this.props.value:'';
  }

  /**
   * @inheritdoc
   */
  getFinalValue() {
    if(this.state.files.length >= 1) {
      return this.state.files[0];
    }
    return this.props.value;
  }

  /**
   * @inheritdoc
   */
  onChange(value) {
    //console.log(value);
    //this.props.onChange(value);
    //this.startValidation(value);
    this.setState({
      files: value
    })
  }

  reset() {
    this.setState({
      files: []
    });
    this.props.onChange('');
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          {
            //If the field is not disabled show the field as normal
            !this.props.disabled?
              <Form.Control
                id={this.inputId}
                className="d-none col-border-radius col-md-border-radius"
                placeholder={this.props.label}
                aria-label={this.props.label}
                aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
                type="file"
                disabled={this.props.disabled || this.props.submitting}
                //value={this.state.files}
                onChange={(event) => this.onChange(event.target.files)}
                isValid={(this.props.disabled)?null:this.isValid()}
                isInvalid={(this.props.disabled)?null:this.isInvalid()}
              />
            :null
          }
          {
            //If the field is not disabled show the field as normal
            !this.props.disabled?
              <InputGroup.Prepend>
                <a
                  className="form-field-file-link"
                  target={`${(this.props.value && typeof this.props.value === 'string')?'_blank':''}`}
                  href={`${(this.props.value && typeof this.props.value === 'string')?`${config.file_path}${this.props.value}`:'#'}`}>
                  {(this.props.value && typeof this.props.value === 'string')?'Télécharger':'Aucun fichier'}
                </a>
                <label htmlFor={this.inputId} className="form-field-file-label">&nbsp; uploader {this.state.files[0]?"1 fichier selectioné"/*this.state.files[0].name*/:"Aucun fichier selectioné."}</label>
              </InputGroup.Prepend>
            :
            //If the field is disabled show a link to send a mail
            <a
              className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius form-control align-items-center form-disabled"
              target={`${(this.props.value && typeof this.props.value === 'string')?'_blank':''}`}
              href={`${(this.props.value && typeof this.props.value === 'string')?`${config.file_path}${this.props.value}`:'#'}`}>
              {(this.props.value && typeof this.props.value === 'string')?'Télécharger':'Aucun fichier'}
            </a>
          }
          {
            //If the field is not disabled show the field as normal
            !this.props.disabled?
              <InputGroup.Append>
                <Button
                  variant="my-secondary-noline"
                  //onMouseEnter={(event) => this.setState({ peekPassword: true })}
                  //onMouseLeave={(event) => this.setState({ peekPassword: false })}
                  onClick={() => this.reset()}>
                  <i className={`icon-cross`}></i>
                </Button>
              </InputGroup.Append>
            :null
          }
          {this.displayValidating()}
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

export default FormFieldText;
