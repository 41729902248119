import React from 'react';
import BasicFormField from './BasicFormField';

import { Form, InputGroup, Col } from 'react-bootstrap';
import '../../../css/form.css';

import NotEmptyOrNullAssert from '../../../Form/Assert/NotEmptyOrNullAssert';

/**
 * FormFieldDate
 *
 * This class handles HTML form field.
 *
 * The particular field is for text.
 *
 * Specific configuration attribute : none
 */
class FormFieldTime extends BasicFormField {

  /**
   * @inheritdoc
   */
  getSpecificAsserts() {
    return {
      notEmptyOrNull: NotEmptyOrNullAssert,
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <Col className="container-form-field">
        <InputGroup className={`container-form-row container-form-row-colored col-12${(!this.props.disabled && this.isValid())?" is-valid":""}${(!this.props.disabled && this.isInvalid())?" is-invalid":""}`}>
          <InputGroup.Prepend
            className="d-none d-md-flex col-md-4 container-form-prepend">
            <InputGroup.Text
              id={`form-${this.props.formKey}-${this.props.fieldKey}`}
              className="col-md-12">
              {this.props.label}
            </InputGroup.Text>
          </InputGroup.Prepend>
          {this.displayMiddlePart()}
          <Form.Control
            className="d-flex col-12 col-md-8 col-border-radius col-md-border-radius"
            placeholder={this.props.label}
            aria-label={this.props.label}
            aria-describedby={`form-${this.props.formKey}-${this.props.fieldKey}`}
            type="time"
            disabled={this.props.disabled || this.props.submitting}
            value={this.getValue()}
            onChange={(event) => this.onChange(event.target.value)}
            isValid={(this.props.disabled)?null:this.isValid()}
            isInvalid={(this.props.disabled)?null:this.isInvalid()}
          />
          {this.displayValidating()}
        </InputGroup>
        {this.displayValidValidators()}
        {this.displayInvalidValidators()}
      </Col>
    );
  }
}

export default FormFieldTime;
