const comment = {
  body: {
    label: "Commentaire",
    invalid: {
      assertEmpty: "Le commentaire ne doit pas être vide."
    },
  },
  hidden: {
    label: "Le commentaire est-il caché ?",
  },
  sendNotification: {
    label: "Envoyer la notification ?"
  }
};

export default comment;
