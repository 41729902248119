import React from 'react';

/**
 * Maintenance
 */
class Maintenance extends React.Component {

  /**
   * Main render method for React Component
   */
   render() {
     return (
       <>
         <h1>Serveur en maintenance</h1>
       </>
     );
   }
}

export default Maintenance;
