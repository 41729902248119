import strings from '../../../Localization/Localization';

let label = {
  fieldKey: "label",
  type: "text",
  label: strings.form.phoneZone.label.label,
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "phoneZoneSearch",
  fields: [
    label
  ]
}
