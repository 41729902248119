import strings from '../../../Localization/Localization';

import { Colors, FontFamily } from '../../../Echart/Constant';

const formatter = Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 4 });

export const pingChartOption = (metric, theme = 'dark', fontSize = 15, additionalOption = {}) => {

  let rta = metric.metrics.find(m => m.metric === 'rta');
  let rtmax = metric.metrics.find(m => m.metric === 'rtmax');
  let rtmin = metric.metrics.find(m => m.metric === 'rtmin');
  let pl = metric.metrics.find(m => m.metric === 'pl');

  if(!rta || !rtmax || !rtmin || !pl) {
    return;
  }

  let textColor = null;
  let inversedTextColor = null;
  let lineColor = null;
  let backgroundColorSave = null;

  switch (theme) {
    case 'light':
      textColor = 'black'
      inversedTextColor = 'white'
      lineColor = 'grey'
      backgroundColorSave = 'white'
      break;
    case 'dark':
    case 'dark_colorblind':
    default:
      textColor = 'lightgrey'
      inversedTextColor = 'black'
      lineColor = 'grey'
      backgroundColorSave = '#383838'
  }

  return {
    color: Colors,
    textStyle: {
      fontFamily: FontFamily,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: (params) => {
        let result = ''
        result += 'Date : ' + params[0].axisValueLabel + '<br/>'
        result += params
          .map((param) => {
            return param.marker + ' ' + param.seriesName + ' : ' + formatter.format(param.value)
          })
          .join('<br/>')
        return result
      }
    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        start: 90,
        end: 100
      },
      {
        type: 'inside',
        realtime: true,
        start: 90,
        end: 100
      }
    ],
    legend: {
      textStyle: {
        color: textColor
      }
    },
    toolbox: {
      feature: {
        restore: {},
        saveAsImage: {
          backgroundColor: backgroundColorSave
        }
      }
    },
    grid: {
      top: '20%',
    },
    xAxis: {
      type: 'category',
      data: metric.times,
      axisLabel: {
        fontSize: fontSize,
      },
      axisPointer: {
        label:{
          color: inversedTextColor
        },
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: textColor
        }
      },
    },
    yAxis: [
      {
        type: 'value',
        name: `${strings.echart.centreonPing.yAxis._0.title} (${strings.echart.centreonPing.yAxis._0.unit})`,
        position: 'left',
        alignTicks: true,
        splitNumber: 5,
        axisLine: {
          show: true,
          lineStyle: {
            color: textColor
          }
        },
        axisLabel: {
          fontSize: fontSize*0.85,
          formatter: (value) => formatter.format(value)
        },
        axisPointer: {
          label:{
            formatter: (value) => formatter.format(value.value),
            color: inversedTextColor
          },
        },
        nameTextStyle: {
          fontSize: fontSize,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: lineColor
          }
        },
      },
      {
        type: 'value',
        name: `${strings.echart.centreonPing.yAxis._1.title}`,
        position: 'right',
        alignTicks: true,
        splitNumber: 5,
        min: 0,
        max: 100,
        interval: 20,
        axisLine: {
          show: true,
          lineStyle: {
            color: textColor
          }
        },
        axisLabel: {
          fontSize: fontSize*0.85,
          formatter: (value) => formatter.format(value)
        },
        axisPointer: {
          label:{
            formatter: (value) => formatter.format(value.value),
            color: inversedTextColor
          },
        },
        nameTextStyle: {
          fontSize: fontSize,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: lineColor
          }
        },
      },
    ],
    series: [
      {
        name: strings.echart.centreonPing.series.roundTripAverage.name,
        data: rta.data,
        type: 'line'
      },
      {
        name: strings.echart.centreonPing.series.roundTripMax.name,
        data: rtmax.data,
        type: 'line'
      },
      {
        name: strings.echart.centreonPing.series.roundTripMin.name,
        data: rtmin.data,
        type: 'line'
      },
      {
        name: strings.echart.centreonPing.series.packetLoss.name,
        data: pl.data,
        yAxisIndex: 1,
        type: 'line',
        areaStyle: {}
      },
    ]
  }
}
