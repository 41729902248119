const bill = {
  index: {
    tab: {
      headers: {
        number: "Numéro",
        client: "Nom du client",
        price: "Montant TTC",
        date: "Date d'émission",
        deadline: "Date d'échéance"
      }
    }
  },
  show: {
    tab: {
      main: "Facture",
    },
  },
  search: {
    number: "Numéro",
    partner: "Partenaire",
    amountMin: "Prix (min)",
    amountMax: "Prix (max)",
    createdAtTimeframe: "Plage de temps / Date",
    createdAtMin: "Date (min)",
    createdAtMax: "Date (max)",
    partialBill: "Facture partielle"
  },
  billCreator: {
    breadcrumb: "Nouvelle facture",
    ordersError: "Les commandes n'ont pas pu être chargées pour ce client.",
    ticketsError: "Les tickets n'ont pas pu être chargés pour ce client.",
    productSubFamiliesError: "Les sous-famille de produits n'ont pas pu être chargées.",
    billGenerateError: "La facture n'a pas pu être génerée.",
  },
  creditNote: {
    breadcrumb: "Nouvel avoir",
    billsError: "Les factures n'ont pas pu être chargées pour ce client.",
    billGenerateError: "La facture n'a pas pu être génerée.",
    productSubFamiliesError: "Les sous-famille de produits n'ont pas pu être chargées.",
    type: {
      contractSub: "Abonnement",
      contractSubL: "Location abonnement",
      contractSubLFP: "Location premier loyer",
      contractCdrFF: "CDR france fixe",
      contractCdrFM: "CDR france mobile",
      contractCdrII: "CDR International",
      contractCdrSN: "CDR numéro spéciaux",
      contractCdrSMS: "CDR SMS",
      contractCdrData: "CDR données 4G/5G",
      orderSellQ: "Commande", //quantity
      orderSellP: "Commande", //percentage
      orderFas: "Commande FAS",
      productSubFamily: "Ligne libre",
      appointmentWork: "Ticket MO",
      appointmentProduct: "Ticket produit",
      appointmentTrip: "Ticket trajet",
      creditNote: "Avoir",
    },
    payment: {
      simple: "Simple",
      refund: "Remboursement",
      deduct: "Déduction sur facture",
      intern: "Comptabilité interne"
    }
  }
};

export default bill;
