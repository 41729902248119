import strings from '../../Localization/Localization';

import {
  bills as billsRoutes
} from '../Route/routes';

let number = {
  fieldKey: "number",
  type: "text",
  label: strings.form.bill.number.label,
  validators: []
}

let numberWithLink = {
  fieldKey: "number",
  type: "text",
  label: strings.form.bill.number.label,

  disabledLink: (value, getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    if(credentials && credentials.roles.includes("ROLE_ACCOUNTING_BILL")) {
      return getValues(0) && getValues(0)["id"]?billsRoutes.routes.show.createPath(getValues(0)["id"]):null;
    }
    return false;
  },

  validators: []
}

let totalTf = {
  fieldKey: "total_tf",
  type: "price",
  label: strings.form.bill.totalTf.label,
  validators: []
}

let totalTi = {
  fieldKey: "total_ti",
  type: "price",
  label: strings.form.bill.totalTi.label,
  validators: []
}

let filename = {
  fieldKey: "filename",
  type: "file",
  label: strings.form.bill.filename.label,
  validators: []
}

export const numberForm = {
  formKey: "bill",
  fields: [
    numberWithLink,
  ]
}

export const form = {
  formKey: "bill",
  fields: [
    number,
    totalTf,
    totalTi,
    filename
  ]
}

/*
{
  formKey: "bill",
  fieldKey: "created_at",
  type: "date",
  label: strings.form.bill.createdAt.label,
  validators: []
},
{
  formKey: "bill",
  fieldKey: "deadline",
  type: "date",
  label: strings.form.bill.deadline.label,
  validators: []
},
/*
{
  formKey: "bill",
  fieldKey: "lines",
  type: "table",
  label: strings.form.bill.accountLines.label,
  validators: [],
  table: {
    headers: [
      {
        type: "string",
        value: "designation",
        label: strings.form.bill.accountLines.table.headers.designation,
        headerColSpan: 1,
        itemColSpan: 1,
      },
      {
        type: "price",
        value: "amount",
        label: strings.form.bill.accountLines.table.headers.amount,
        headerColSpan: 1,
        itemColSpan: 1,
      },
    ]
  }
},
*/
/*
{
  formKey: "bill",
  fieldKey: "payments",
  type: "table",
  label: strings.form.bill.payments.label,
  validators: [],
  table: {
    headers: [
      {
        type: "dropdown",
        value: "payment_type",
        label: strings.form.bill.payments.table.headers.type,
        headerColSpan: 1,
        itemColSpan: 1,
      },
      {
        type: "price",
        value: "amount",
        label: strings.form.bill.payments.table.headers.amount,
        headerColSpan: 1,
        itemColSpan: 1,
      },
      {
        type: "boolean",
        value: "validated",
        label: strings.form.bill.payments.table.headers.validated,
        headerColSpan: 1,
        itemColSpan: 1,
      },
    ]
  }
},
*/
