import {
  index as baseIndex,
  editFromSearch as baseEditFromSearch,
  request
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of appointment types based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('call_detail_records', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * EDIT BATCH FUNCTION                                                        *
 ******************************************************************************/

/**
 * apply a change to all call detail records corresponding to the search
 *
 * @param search the search used
 * @param data the change to apply
 */
export function editWipeCalculationFromSearch(search = {}, data) {
  return baseEditFromSearch('call_detail_records', search, data, 'wipe_calculation');
}

/**
 * apply a change to all call detail records corresponding to the search
 *
 * @param search the search used
 * @param data the change to apply
 */
export function editTransferFromSearch(search = {}, data) {
  return baseEditFromSearch('call_detail_records', search, data, 'transfer');
}

/******************************************************************************
 * EXPORT FUNCTION                                                            *
 ******************************************************************************/

/**
 * export all cdrs depending on search
 *
 * @param data the new data of the partner
 */
export function exportData(search) {
  return request('/call_detail_records/export', 'GET', search);
}
