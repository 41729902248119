import strings from '../../Localization/Localization';

import {
  index,
  getFromId,
  editFromId,

  addingAgreementTransition,
  signWithYousignTransition,
  validatingAgreementTransition,
  invalidatingAgreementTransition,
  cancelQuoteTransition,
  uncancelQuoteTransition,

  validatingOrderTransition,
  advancePaymentPaidTransition,
  cancelOrderTransition,
  cancelValidatedOrderTransition,
  cancelValidatedOrderNoBillTransition,
  deliverTransition
} from '../../API/Carts';

import { form } from '../Form/carts';
import { form as searchForm } from '../Form/Search/carts';
import { form as addingAgreementForm } from '../Form/Transition/Cart/addingAgreement';

import {
  carts as routes,
  orders as orderRoutes,
  quotes as quoteRoutes
} from '../Route/routes';

export const conf = {
  index: {
    pageTitle: strings.common.homepage.carts,

    id: "cart",
    api: index,
    onClickItem: {
      redirect: true,
      path: (id) => routes.routes.show.createPath(id)
    },

    searchForm: searchForm,
    computeSearch: (search) => {
      let result = {};
      if(search.title) {
        result["title"] = search.title;
      }
      if(typeof search.owner === 'object') {
        result["owner_id"] = search.owner.id
      }
      if(typeof search.client === 'object') {
        result["partner_id"] = search.client.id
      }
      if(typeof search.placeDelivery === 'object') {
        result["place_delivery_id"] = search.placeDelivery.id
      }
      if(typeof search.clientUser === 'object') {
        result["client_user_id"] = search.clientUser.id
      }
      if(search.createdAtMin) {
        result["created_at_min"] = search.createdAtMin;
      }
      if(search.createdAtMax) {
        result["created_at_max"] = search.createdAtMax;
      }
      return result;
    },

    availableItemDisplay:['line'],
    itemDisplay: {
      line: {
        label: (item) => `${item["title"]}`
      },
    },

    sizing: {
      numberColumn: 2,
      formSizeMd: 12
    },
  },
  show: {
    pageTitle: strings.common.homepage.carts,
    pageTitleReturnPath: () => routes.routes.index.createPath(),
    breadcrumbIdentifier: "title",

    apiGet: getFromId,

    extendedHeader: false,

    tabs: {
      main: {
        title: strings.cart.tab.main,
        type: 'form',
        form: form,

        onUpdateItem: (data) => {},
        onSubmit: (cart) => {},

        apiGet: getFromId,
        apiEdit: editFromId,

        role: ['ROLE_NYUKOM'],
        editable: false
      },
    }
  },
  transition: {
    //Quote transition
    addingAgreement: {
      type: 'form',
      form: addingAgreementForm,
      api: addingAgreementTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => quoteRoutes.routes.show.createPath(data.item.quote.id)
      },
    },
    signWithYousignQuote: {
      type: 'direct',
      api: signWithYousignTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => quoteRoutes.routes.yousign.createPath(data.item.quote.id)
      },
    },
    validatingAgreement: {
      type: 'direct',
      api: validatingAgreementTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => quoteRoutes.routes.show.createPath(data.item.quote.id)
      },
    },
    invalidatingAgreement: {
      type: 'direct',
      api: invalidatingAgreementTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => quoteRoutes.routes.show.createPath(data.item.quote.id)
      },
    },
    cancelQuote: {
      type: 'direct',
      api: cancelQuoteTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => quoteRoutes.routes.show.createPath(data.item.quote.id)
      },
    },
    uncancelQuote: {
      type: 'direct',
      api: uncancelQuoteTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => quoteRoutes.routes.show.createPath(data.item.quote.id)
      },
    },
    //Order transition
    validatingOrder: {
      type: 'direct',
      api: validatingOrderTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => orderRoutes.routes.show.createPath(data.item.order.id)
      },
    },
    advancePaymentPaid: {
      type: 'direct',
      api: advancePaymentPaidTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => orderRoutes.routes.show.createPath(data.item.order.id)
      },
    },
    cancelOrder: {
      type: 'direct',
      api: cancelOrderTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => orderRoutes.routes.show.createPath(data.item.order.id)
      },
    },
    cancelValidatedOrder: {
      type: 'direct',
      api: cancelValidatedOrderTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => orderRoutes.routes.show.createPath(data.item.order.id)
      },
    },
    cancelValidatedOrderNoBill: {
      type: 'direct',
      api: cancelValidatedOrderNoBillTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => orderRoutes.routes.show.createPath(data.item.order.id)
      },
    },
    deliver: {
      type: 'direct',
      api: deliverTransition,
      postTransition: {
        type: 'redirection',
        route: (data) => orderRoutes.routes.show.createPath(data.item.order.id)
      },
    },
  }
}
