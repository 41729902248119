import strings from '../../Localization/Localization';

import { fetchAccountingTypes } from '../../API/AccountingTypes';
import { fetchAccountVats } from '../../API/AccountVats';

let title = {
  fieldKey: "title",
  type: "text",
  label: strings.form.accountProduct.title.label,
  validators: []
}

let numbers = {
  fieldKey: "numbers",
  type: "array",
  label: "",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.accountProduct.numbers.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.accountProduct.numbers.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.accountProduct.numbers.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: strings.form.accountProduct.numbers.label,

    subForm: {
      formKey: "accountProductNumber",
      fields: [
        {
          fieldKey: "number",
          type: "text",
          label: strings.form.accountProduct.number.label,
          validators: []
        },
        {
          fieldKey: "accounting_type",
          type: "dropdownEntity",
          label: strings.form.accountProduct.accountingType.label,

          validators: [],

          showEmpty: true,
          storage: 'redux',
          shareStorage: true,
          loader: {
            id: "accountingTypes",
            loader: fetchAccountingTypes,
            loaderRequire: () => true,
            loaderArguments: () => [],
          },

          adapter: {
            requirement: (data) => typeof data === 'object' && data["id"] && data["title"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["title"],
          },
        },
        {
          fieldKey: "account_vat",
          type: "dropdownEntity",
          label: strings.form.accountProduct.accountVat.label,

          validators: [],

          showEmpty: true,
          storage: 'redux',
          shareStorage: true,
          loader: {
            id: "accountVat",
            loader: fetchAccountVats,
            loaderRequire: () => true,
            loaderArguments: () => [],
          },

          adapter: {
            requirement: (data) => typeof data === 'object' && data["id"] && data["title"],
            getValue: (data) => data["id"],
            getLabel: (data) => data["title"],
          },
        }
      ]
    },

    validators: [],
  },

  validators: []
}

/*
let number = {
  fieldKey: "numbers",
  type: "table",
  label: strings.form.cart.cartItems.label,
  validators: [],

  table: {
    valuesToRows: (values) => {
      let result = [];
      values.forEach((value) => {
        let type = '';
        switch (value.product.price_type) {
          case 0:
            if(value.rented === true) {
              type = strings.form.product.priceType.options.option_1;
            }
            else {
              type = strings.form.product.priceType.options.option0;
            }
            break;
          case 1:
            type = strings.form.product.priceType.options.option1;
            switch (value["contract"]["bill_period"]) {
              case 1:
                type += ' ' + strings.form.contract.billPeriod.options.monthly;
                break;
              case 2:
                type += ' ' + strings.form.contract.billPeriod.options.fortnightly;
                break;
              case 3:
                type += ' ' + strings.form.contract.billPeriod.options.quarterly;
                break;
              case 6:
                type += ' ' + strings.form.contract.billPeriod.options.halfYearly;
                break;
              case 12:
                type += ' ' + strings.form.contract.billPeriod.options.yearly;
                break;
              default:
            }
            break;
          case 2:
            type = strings.form.product.priceType.options.option2;
            break;
          default:
        }
        result.push(Object.assign({}, value, {
          product_name: value.product.name,
          type: type
        }))
      });
      return result;
    },
    headers: [
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: "product_name",
        label: "Produit",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: "type",
        label: "Type",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "integer",
        value: "quantity",
        label: "Quantité",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "price",
        value: "price",
        label: "Prix Unitaire",
        editable: false
      },
      {
        headerColSpan: 2,
        itemColSpan: 1,
        type: "float",
        value: "discount",
        label: "Remise",
        editable: false
      },
      {
        headerColSpan: 0,
        itemColSpan: 1,
        type: "integer",
        value: "discount_unit",
        label: "",
        editable: false
      }
    ]
  }
}
*/

export const form = {
  formKey: "accountProduct",
  fields: [
    title,
    numbers
  ]
}
