import strings from '../../Localization/Localization';

import {
  services as servicesRoutes,
  orders as ordersRoutes
} from '../Route/routes';

import { fetchSubFamilies } from '../../API/ProductSubFamilies';
import { fetchServices } from '../../API/Services';

import { subFamilyRecapForm as _orderSubFamilyRecapForm } from './orders'

let originType = {
  fieldKey: "origin_type",
  type: "dropdown",
  label: strings.form.ticket.originType.label,
  disabled: (getValues) => getValues(0) && getValues(0)["origin_type"] === 'order',
  validators: [
    {
      id: 'assertEmpty',
      type: 'notEmptyOrNull',
      invalidFeedback: strings.form.ticket.originType.invalid.assertEmpty,
      defaultValue: false
    },
  ],

  showEmpty: true,
  input: 'options',
  filterOptions: (option, getValues) => {
    if(option.value === 'order' && getValues(0)["origin_type"] !== 'order') {
      return false;
    }
    return true;
  },
  options: [
    // {
    //   value: 'nothing',
    //   label: strings.form.ticket.originType.options.nothing
    // },
    {
      value: 'product_sub_family',
      label: strings.form.ticket.originType.options.productSubFamily
    },
    {
      value: 'service',
      label: strings.form.ticket.originType.options.service
    },
    {
      value: 'order',
      label: strings.form.ticket.originType.options.order
    },
  ],
}

let originProductSubFamily = {
  fieldKey: "product_sub_family",
  label: strings.form.ticket.originProductSubFamily.label,
  type: "dropdownEntity",
  disabled: (getValues) => !(getValues(0) && getValues(0)["origin_type"] === 'product_sub_family'),
  showOnDisabled: (getValues) => getValues(0) && getValues(0)["origin_type"] === 'product_sub_family',

  validators: [],

  events: [
    {
      id: "reset",
      event: "onChange",
      target: "origin_type",
      function: (component) => component && component.resetValue()
    }
  ],

  showEmpty: true,
  loader: {
    id: "productSubFamilies",
    loader: fetchSubFamilies,
    loaderRequire: () => true,
    loaderArguments: () => [{
      available_as_ticket_origin: 1
    }],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let originService = {
  fieldKey: "service",
  label: strings.form.ticket.originService.label,
  type: "dropdownEntity",
  disabled: (getValues) => !(getValues(0) && getValues(0)["origin_type"] === 'service'),
  showOnDisabled: (getValues) => getValues(0) && getValues(0)["origin_type"] === 'service',

  disabledLink: (value) => value && servicesRoutes.routes.show.createPath(value["id"]),

  validators: [],

  events: [
    // {
    //   id: "resetFromOriginType",
    //   event: "onChange",
    //   target: "origin_type",
    //   function: (component) => {
    //     if(component) {
    //       component.resetValue();
    //       component.resetOptions();
    //     }
    //   }
    // },
    // {
    //   id: "resetFromClient",
    //   event: "onChange",
    //   target: "client",
    //   function: (component) => {
    //     if(component) {
    //       component.resetValue();
    //       component.resetOptions();
    //     }
    //   }
    // },
    // {
    //   id: "resetFromClientAccount",
    //   event: "onChange",
    //   target: "client_account",
    //   function: (component) => {
    //     if(component) {
    //       component.resetValue();
    //       component.resetOptions();
    //     }
    //   }
    // },
    // {
    //   id: "resetFromPlace",
    //   event: "onChange",
    //   target: "place",
    //   function: (component) => {
    //     if(component) {
    //       component.resetValue();
    //       component.resetOptions();
    //     }
    //   }
    // }
  ],

  showEmpty: true,
  loader: {
    id: "services",
    loader: fetchServices,
    loaderRequire: (value, getValues) => getValues()["client_account"] && getValues()["client_account"]["id"] && getValues()["place"] && getValues()["place"]["id"],
    loaderArguments: (value, getValues) => [{
      client_id: getValues()["client_account"]["id"],
      place_id: getValues()["place"]["id"],
      state: 'active'
    }],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["cart_item"],
    getValue: (data) => data["id"],
    getLabel: (data) => `${data["serial_number"]?data["serial_number"]+' ~ ':''}${data["cart_item"]["product"]["name"]}`,
  },
}

let originOrder = {
  fieldKey: "order",
  label: strings.form.ticket.originOrder.label,
  type: "dropdownEntity",
  disabled: () => true,
  showOnDisabled: (getValues) => getValues(0) && getValues(0)["origin_type"] === 'order',

  disabledLink: (value) => value && ordersRoutes.routes.show.createPath(value["id"]),

  validators: [],

  showEmpty: true,
  loader: {
    id: "services",
    loader: fetchServices,
    loaderRequire: (value, getValues) => getValues()["client_account"] && getValues()["client_account"]["id"] && getValues()["place"] && getValues()["place"]["id"],
    loaderArguments: (value, getValues) => [{
      client_id: getValues()["client_account"]["id"],
      place_id: getValues()["place"]["id"],
      state: 'active'
    }],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["number"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["number"],
  },
}

let originOrderSubFamilyRecap = {
  fieldKey: "order",
  label: "",
  type: "subform",
  disabled: () => true,
  showOnDisabled: (getValues) => getValues(0) && getValues(0)["origin_type"] === 'order',

  validators: [],

  subForm: _orderSubFamilyRecapForm,
}

export const form = {
  formKey: "ticket",
  fields: [
    originType,
    originProductSubFamily,
    originService,
    originOrder,
    originOrderSubFamilyRecap
  ]
}
