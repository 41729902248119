import strings from '../../Localization/Localization';

import { fetchPhonePrefixes } from '../../API/PhonePrefixes';
import { fetchPhoneZoneGroups } from '../../API/PhoneZoneGroups';
import { fetchPhonePrices } from '../../API/PhonePrices';

import { form as _aliasForm } from './phoneZoneAliases';

let label = {
  fieldKey: "label",
  type: "text",
  label: strings.form.phoneZone.label.label,
  validators: []
}

let aliases = {
  fieldKey: "aliases",
  label: strings.form.phoneZone.aliases.label,
  type: "array",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phoneZone.aliases.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phoneZone.aliases.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phoneZone.aliases.allowMoreField.removingLabel,

  subField: {
    type: "subform",
    label: "",

    subForm: _aliasForm,

    validators: [],
  },

  validators: []
}

let groups = {
  fieldKey: "groups",
  label: strings.form.phoneZone.groups.label,
  type: "array",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phoneZone.groups.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phoneZone.groups.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phoneZone.groups.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.phoneZone.group.label,

    ignoreValidation: true,
    validators: [],

    showEmpty: true,
    loader: {
      id: "prefix",
      loader: fetchPhoneZoneGroups,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["label"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["label"],
    },
  },

  validators: []
}

let prefixes = {
  fieldKey: "prefixes",
  label: strings.form.phoneZone.prefixes.label,
  type: "array",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phoneZone.prefixes.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phoneZone.prefixes.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phoneZone.prefixes.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.phoneZone.prefix.label,

    ignoreValidation: true,
    validators: [],

    showEmpty: true,
    loader: {
      id: "prefix",
      loader: fetchPhonePrefixes,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["prefix"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["prefix"],
    },
  },

  validators: []
}

let priceSources = {
  fieldKey: "price_sources",
  label: strings.form.phoneZone.priceSources.label,
  type: "array",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phoneZone.priceSources.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phoneZone.priceSources.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phoneZone.priceSources.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.phoneZone.priceSource.label,

    ignoreValidation: true,
    validators: [],

    showEmpty: true,
    loader: {
      id: "prefix",
      loader: fetchPhonePrices,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  },

  validators: []
}

let priceDestinations = {
  fieldKey: "price_destinations",
  label: strings.form.phoneZone.priceDestinations.label,
  type: "array",

  defaultNumberOfField: 0,
  allowMoreField: true,

  allowMoreFieldAddLabel: strings.form.phoneZone.priceDestinations.allowMoreField.addLabel,
  allowMoreFieldRemoveLabel: strings.form.phoneZone.priceDestinations.allowMoreField.removeLabel,
  allowMoreFieldRemovingLabel: strings.form.phoneZone.priceDestinations.allowMoreField.removingLabel,

  subField: {
    type: "dropdownEntity",
    label: strings.form.phoneZone.priceSource.label,

    ignoreValidation: true,
    validators: [],

    showEmpty: true,
    loader: {
      id: "prefix",
      loader: fetchPhonePrices,
      loaderRequire: () => true,
      loaderArguments: () => [],
    },

    adapter: {
      requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
      getValue: (data) => data["id"],
      getLabel: (data) => data["name"],
    },
  },

  validators: []
}

export const prefixForm = {
  formKey: "phoneZone",
  fields: [
    prefixes
  ]
}

export const priceSourceForm = {
  formKey: "phoneZone",
  fields: [
    priceSources
  ]
}

export const priceDestinationForm = {
  formKey: "phoneZone",
  fields: [
    priceDestinations
  ]
}

export const form = {
  formKey: "phoneZone",
  fields: [
    label,
    aliases,
    groups
  ]
}
