import {
  ADD_CREDENTIALS,
  REMOVE_CREDENTIALS
} from '../Action/credentials'

const initialState = {
  username: null,
  authToken: null,
  refreshToken: null,
  roles: []
}

export default function reduce(state = initialState, action){

  let newState

  switch (action.type) {
    case ADD_CREDENTIALS:
      newState = Object.assign({}, {
        id: action.id,
        username: action.username,
        authToken: action.authToken,
        refreshToken: action.refreshToken,
        roles: action.roles,
        partners: action.partners
      })
      break;
    case REMOVE_CREDENTIALS:
      newState = Object.assign({}, initialState)
      break;
    default:
      newState = state;
  }

  return newState;
}
