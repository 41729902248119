const phonePriceList = {
  name: {
    label: "Nom",
  },
  prices: {
    label: "Prix",
    table: {
      name: "Nom",
      price: "Prix minute (HT)",
      servicePrice: "Prix Etablissement (HT)",
      communicationType: "Type",
      inout: {
        label: "Sens",
        optionTrue: "Sortant",
        optionFalse: "Entrant"
      },
    }
  },
  completePrices: {
    label: "Prix",
    allowMoreField: {
      addLabel: "Ajouter un prix",
      removeLabel: "Enlever un prix",
      removingLabel: "Supprimer"
    }
  }
};

export default phonePriceList;
