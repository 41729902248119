const parameter = {
  tab: {
    main: "Paramètre",
    edit: "Editer"
  },
  search: {
    name: "Nom du paramètre"
  }
};

export default parameter;
