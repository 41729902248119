const appointmentType = {
  secondaryTickets: {
    label: "Ticket secondaire",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  refereeClient: {
    label: "Référent client"
  },
  cause: {
    label: "Constat"
  },
  result: {
    label: "Résultat"
  },
  file: {
    label: "Fichier"
  },
  fileSigned: {
    label: "Fichier signé"
  },
  appointmentType: {
    label: "Type d'intervention"
  },
  yousignOperatorMode: {
    label: "Transmission code Yousign",
    options: {
      mail: "Mail",
      sms: "SMS"
    }
  },
  yousignResult: {
    label: "Résultat Yousign",
    options: {
      procedureFinished: "Terminé",
      procedureRefused: "Refusé",
      procedureExpired: "Expiré",
      procedureDeleted: "Supprimé",
    }
  },
  yousignLink: {
    label: "Signature"
  },
  appointmentProducts: {
    label: "Fourniture",
    allowMoreField: {
      addLabel: "Ajouter",
      removeLabel: "Enlever",
      removingLabel: "Supprimer"
    }
  },
  timeSlot: {
    label: "Durée",
    invalid: {
      assertEmpty: "La durée ne peut pas être vide."
    }
  },
  dateStart: {
    label: "Date de début",
    invalid: {
      assertEmpty: "La date ne peut pas être vide."
    }
  },
  dateEnd: {
    label: "Date de fin",
    invalid: {
      assertEmpty: "La date ne peut pas être vide."
    }
  },
};

export default appointmentType;
