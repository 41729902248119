const cart = {
  title: {
    label: "Titre",
    invalid: {
      assertEmpty: "Veuillez remplir le titre."
    },
  },
  description: {
    label: "Description",
    invalid: {
      assertEmpty: "Veuillez remplir la description.",
    },
  },
  cartItems: {
    label: "Produits"
  },
  client: {
    label: "Partenaire",
    invalid: {
      assertEmpty: "Veuillez remplir le partenaire de la commande."
    },
  },
  clientAccount: {
    label: "Compte client",
    invalid: {
      assertEmpty: "Veuillez remplir le compte client utilisé lors de la commande.",
      assertClient: "Le compte client n'appartient pas au client selectioné."
    },
  },
  placeDelivery: {
    label: "Site de livraison",
    invalid: {
      assertEmpty: "Veuillez remplir le site de livraison.",
      assertClient: "Le site n'appartient pas au client selectioné."
    },
  },
  clientUser: {
    label: "Référent client",
    invalid: {
      assertEmpty: "Veuillez remplir le référent client.",
      assertClient: "Le site n'appartient pas au client selectioné."
    },
  },
  mainUser: {
    label: "Référent Nyukom",
    invalid: {
      assertEmpty: "Veuillez remplir le référent Nyukom.",
      assertClient: "Le site n'appartient pas au client selectioné."
    },
  },
  owner: {
    label: "Propriétaire",
  },
  createdAtMin: {
    label: "Date de création (min)",
  },
  createdAtMax: {
    label: "Date de création (max)",
  },
  createdAtTimeframe: {
    label: "Création"
  },
  rightToCancellation: {
    label: "Je fais valoir mon droit de rétractation de 14 jours."
  },
  state: {
    label: "Etat",
    options: {
      cart: {
        saved: "Sauvegardé"
      },
      amendment: {
        waiting: "Programmé",
        validated: "Validé"
      },
      order: {
        waitingValidation: "En attente de validation",
        cancellationPeriod: "En période de rétractation",
        waitingAdvancePayment: "En attente du paiement initial",
        deliveryInProgress: "En cours de livraison",
        delivered: "Livré",
        canceled: "Annulé"
      },
      quote: {
        waitingAgreement: "En attente de BpA",
        waitingValidation: "En attente de validation",
        validated: "Validé",
        canceled: "Annulé",
        yousign: "En attente de Yousign",
        locked: "Verrouillé"
      }
    }
  },
  transition: {
    addingAgreement: {
      file: {
        label: "Fichier",
        invalid: {
          assertEmpty: "Veuillez choisir un fichier.",
        }
      },
      signatureDate: {
        label: "Date de signature",
        invalid: {
          assertEmpty: "Veuillez choisir la date de signature figurant sur le fichier.",
        }
      },
      externalNumber: {
        label: "Numéro Externe Service"
      },
      externalNumberFurniture: {
        label: "Numéro Externe Matériel"
      },
      rightToCancellation: {
        label: "Je fais valoir mon droit de rétractation de 14 jours."
      }
    }
  }
};

export default cart;
