import React from 'react';

import { Redirect } from "react-router-dom";

import SubFooter from '../../Layout/SubFooter';

import { connect } from 'react-redux';

import strings from '../../../Localization/Localization';

import { Container, Button } from 'react-bootstrap';
import '../../../css/tabs.css';
import '../../../css/tabs_history_ticket.css';

import MyForm from '../../Form/MyForm';
import { SubmitPositions } from '../../../Form/Constant';
import { withModalHandler } from '../../../HOC/ModalHandler';

import {
  addLineToFlashbag,
  FLASHBAG_TYPE_PRIMARY,
} from '../../../Store/Action/flashbag';

import Tabs from '../../Tabs/Tabs';
import Tab from '../../Tabs/Tab/Tab';

import Loading from '../../Layout/Loading';

/**
 * TicketHistoryTab
 *
 * This component managed a entity tab with a form
 */
class TicketHistoryTab extends React.Component {

  static defaultProps = {
    onUpdateItem: (data) => {},
    onSubmit: (entity) => {},
  }

  constructor(props) {
    super(props);

    //state of the component, including :
    // - preLoading : if the data is actually preLoading
    // - item : the item
    this.state = {
      preLoading: true,
      preLoadingError: false,
      postLoading: false,

      redirection: false,
      path: null,

      item: null,

      activeNewElement: "",
      newComment: {
        send_notification: true
      },
      newFile: {},
      newFormAnswer: {},
      newAppointment: {}
    }

    // this.getFromId = this.props.modalHandler.addVerificationWithCallback(props.apiGet, this.updateItem);
    // this.editFromId = this.props.modalHandler.addVerificationWithCallback(props.apiEdit, this.postEdit);
    this.getHistoryFromId = this.props.modalHandler.addVerificationWithCallback(props.apiHistory, this.updateItem, this.updateItemFailure);
    this.newCommentFromId = this.props.modalHandler.addVerificationWithCallback(props.apiNewComment, this.postEdit, this.postEditFailure);
    this.newFileFromId = this.props.modalHandler.addVerificationWithCallback(props.apiNewFile, this.postEdit, this.postEditFailure);
    this.newFormAnswerFromId = this.props.modalHandler.addVerificationWithCallback(props.apiNewFormAnswer, this.postEdit, this.postEditFailure);
    this.editFormAnswerFromId = this.props.modalHandler.addVerificationWithCallback(props.apiEditFormAnswer, this.postEdit, this.postEditFailure);
    this.newAppointmentFromId = this.props.modalHandler.addVerificationWithCallback(props.apiNewAppointment, this.postEditAppointment, this.postEditFailure);
  }

  /**
   * Once the component is mounted, we can query for the information of the item
   * needed to be displayed in the component
   */
  componentDidMount() {
    this.getHistoryFromId(this.props.entityId);
  }

  static getDerivedStateFromProps(props, state) {
    let changed = false;
    let newAppointment = Object.assign({}, state.newAppointment)
    if(props.item["request_type"] !== state.newAppointment["request_type"]) {
      newAppointment["request_type"] = props.item["request_type"];
      changed = true;
    }
    if(props.item["client"] !== state.newAppointment["client"]) {
      newAppointment["client"] = props.item["client"];
      changed = true;
    }

    if(changed) {
      return {
        newAppointment: newAppointment
      }
    }
    return null;
  }

  /**
   * callback that collect the data from the fetch call and update the item
   *
   * @param data data from the fetch call
   */
  updateItem = (data) => {
    this.props.onUpdateItem(data);
    this.setState({
      preLoading: false,
      item: data.item
    })
  }

  updateItemFailure = (msg) => {
    this.setState({
      preLoading: false,
      preLoadingError: true
    })
  }

  /**
   * callback after editing the data, changes the tab
   *
   * @param data data from the edit call
   */
  postEdit = (data) => {
    this.setState({
      postLoading: false,
      activeNewElement: ""
    }, () => {
      //TODO check result in data
      this.props.dispatch(addLineToFlashbag("L'élément a bien été sauvegardé", FLASHBAG_TYPE_PRIMARY));

      if(this.props.postEdit !== null) {
        let postEdit = null;
        if(typeof this.props.postEdit === 'function') {
          // this.props.postEdit(data);
          postEdit = this.props.postEdit(data);
        }
        else if(typeof this.props.postEdit === 'object') {
          postEdit = this.props.postEdit;
        }

        if(postEdit !== null) {
          switch (postEdit.type) {
            case 'tab':
              this.props.changeTab(postEdit.tabId);
              break;
            case 'redirection':
              this.setState({
                redirection: true,
                path: postEdit.path
              });
              break;
            default:
          }
        }
      }
    })
  }

  postEditAppointment = (data) => {
    this.setState({
      postLoading: false,
      activeNewElement: ""
    }, () => {
      //TODO check result in data
      this.props.dispatch(addLineToFlashbag("L'élément a bien été sauvegardé", FLASHBAG_TYPE_PRIMARY));

      if(this.props.postEdit !== null) {
        let postEdit = null;
        if(typeof this.props.postEditAppointment === 'function') {
          // this.props.postEdit(data);
          postEdit = this.props.postEditAppointment(data);
        }
        else if(typeof this.props.postEditAppointment === 'object') {
          postEdit = this.props.postEditAppointment;
        }

        if(postEdit !== null) {
          switch (postEdit.type) {
            case 'tab':
              this.props.changeTab(postEdit.tabId);
              break;
            case 'redirection':
              this.setState({
                redirection: true,
                path: postEdit.path
              });
              break;
            default:
          }
        }
      }
    })
  }

  postEditFailure = (msg) => {
    this.setState({
      postLoading: false
    });
  }

  onChangeItemNewComment = (fieldKey, value) => {
    let newComment = Object.assign({}, this.state.newComment);
    newComment[fieldKey] = value;
    this.setState({
      newComment: newComment,
    });
  }

  onChangeItemNewFile = (fieldKey, value) => {
    let newFile = Object.assign({}, this.state.newFile);
    newFile[fieldKey] = value;
    this.setState({
      newFile: newFile,
    });
  }

  onChangeItemNewFormAnswer = (fieldKey, value) => {
    let newFormAnswer = Object.assign({}, this.state.newFormAnswer);
    newFormAnswer[fieldKey] = value;
    this.setState({
      newFormAnswer: newFormAnswer,
    });
  }

  onChangeItemNewAppointment = (fieldKey, value) => {
    let newAppointment = Object.assign({}, this.state.newAppointment);
    newAppointment[fieldKey] = value;
    this.setState({
      newAppointment: newAppointment,
    });
  }

  onChangeFormAnswer = (i, fieldKey, value) => {
    let history = [...this.state.item.history];
    let item = Object.assign({}, history[i]);
    item[fieldKey] = value;
    history[i] = item;

    this.setState({
      item: Object.assign({}, this.state.item, {
        history: history
      })
    });
  }

  onSubmitNewComment = (item) => {
    this.setState({
      postLoading: true
    }, () => {
      let newItem = {
        id: this.state.item.id,
        comment: item
      }
      this.newCommentFromId(newItem.id, newItem);
    })
  }

  onSubmitNewFile = (item) => {
    this.setState({
      postLoading: true
    }, () => {
      let newItem = {
        id: this.state.item.id,
        file: item
      }
      this.newFileFromId(newItem.id, newItem);
    })
  }

  onSubmitNewFormAnswer = (item) => {
    this.setState({
      postLoading: true
    }, () => {
      let newItem = {
        id: this.state.item.id,
        form_answer: item
      }
      this.newFormAnswerFromId(newItem.id, newItem);
    })
  }

  onSubmitNewAppointment = (item) => {
    this.setState({
      postLoading: true
    }, () => {
      let newAppointment = Object.assign({}, item, {
        request_type: undefined,
        client: undefined
      })
      let newItem = {
        id: this.state.item.id,
        appointment: newAppointment
      }
      this.newAppointmentFromId(newItem.id, newItem);
    })
  }

  onSubmitFormAnswer = (i, form_answer) => {
    this.setState({
      postLoading: true
    }, () => {
      let item = {
        id: this.state.item.id,
        form_answers: [form_answer]
      }
      this.editFormAnswerFromId(item.id, item);
    })
  }


  displayNewComment() {
    return (
      <MyForm
        formKey={this.props.newCommentForm.formKey}
        fields={this.props.newCommentForm.fields}
        extraValues={{
          ...this.props.newCommentForm.extraValues,
          type: this.props.subType
        }}
        disabled={false}
        values={this.state.newComment}
        onChangeField={this.onChangeItemNewComment}
        onSubmit={this.onSubmitNewComment}
        submitPosition={SubmitPositions.SUB_FOOTER}

        displayExtraButton={true}
        buttonExtraClassName=""
        onClickExtraButton={() => this.setState({activeNewElement: ""})}
      />
    )
  }

  displayNewFile() {
    return (
      <MyForm
        formKey={this.props.newFileForm.formKey}
        fields={this.props.newFileForm.fields}
        extraValues={this.props.newFileForm.extraValues}
        disabled={false}
        values={this.state.newFile}
        onChangeField={this.onChangeItemNewFile}
        onSubmit={this.onSubmitNewFile}
        submitPosition={SubmitPositions.SUB_FOOTER}

        displayExtraButton={true}
        buttonExtraClassName=""
        onClickExtraButton={() => this.setState({activeNewElement: ""})}
      />
    )
  }

  displayNewTicketFormAnswer() {
    return (
      <MyForm
        formKey={this.props.newFormAnswerForm.formKey}
        fields={this.props.newFormAnswerForm.fields}
        extraValues={this.props.newFormAnswerForm.extraValues}
        disabled={false}
        values={this.state.newFormAnswer}
        onChangeField={this.onChangeItemNewFormAnswer}
        onSubmit={this.onSubmitNewFormAnswer}
        submitPosition={SubmitPositions.SUB_FOOTER}

        displayExtraButton={true}
        buttonExtraClassName=""
        onClickExtraButton={() => this.setState({activeNewElement: ""})}
      />
    )
  }

  displayNewAppointment() {
    return (
      <MyForm
        formKey={this.props.newAppointmentForm.formKey}
        fields={this.props.newAppointmentForm.fields}
        extraValues={this.props.newAppointmentForm.extraValues}
        disabled={false}
        values={this.state.newAppointment}
        onChangeField={this.onChangeItemNewAppointment}
        onSubmit={this.onSubmitNewAppointment}
        submitPosition={SubmitPositions.SUB_FOOTER}

        displayExtraButton={true}
        buttonExtraClassName=""
        onClickExtraButton={() => this.setState({activeNewElement: ""})}
      />
    )
  }

  displayNew() {
    if(!this.state.preLoading && !this.state.preLoadingError) {
      return (
        <Tabs className="entity-tabs-selection" activeKey={this.state.activeNewElement} displayTabSelection={false}>
          {((this.props.subType === 'technical' && (this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_COMMENT_INNER_CREATE') || this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_COMMENT_CREATE')))
            || (this.props.subType === 'commercial' && (this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_COMMENT_INNER_CREATE') || this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_COMMENT_CREATE')))
            || (this.props.subType === 'administrative' && (this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_COMMENT_INNER_CREATE') || this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_COMMENT_CREATE'))))
            && (this.state.item.state !== 'closed')?
            <Tab eventKey="newComment" title="Ajouter un commentaire" icon="icon-pen">
              {this.displayNewComment()}
            </Tab>
            :undefined
          }
          {((this.props.subType === 'technical' && this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_FILE_CREATE'))
            || (this.props.subType === 'commercial' && this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_FILE_CREATE'))
            || (this.props.subType === 'administrative' && this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_FILE_CREATE')))
            && (this.state.item.state !== 'closed')?
            <Tab eventKey="newFile" title="Ajouter un fichier" icon="icon-pen">
              {this.displayNewFile()}
            </Tab>
            :undefined
          }
          {((this.props.subType === 'technical' && this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_FORM_ANSWER_CREATE'))
            || (this.props.subType === 'commercial' && this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_FORM_ANSWER_CREATE'))
            || (this.props.subType === 'administrative' && this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_FORM_ANSWER_CREATE')))
            && (this.state.item.state !== 'closed')?
            <Tab eventKey="newFormAnswer" title="Ajouter un formulaire" icon="icon-pen">
              {this.displayNewTicketFormAnswer()}
            </Tab>
            :undefined
          }
          {((this.props.subType === 'technical' && this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_APPOINTMENT_CREATE'))
            || (this.props.subType === 'commercial' && this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_APPOINTMENT_CREATE'))
            || (this.props.subType === 'administrative' && this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_APPOINTMENT_CREATE')))
            && (this.state.item.state !== 'closed')?
            <Tab eventKey="newAppointment" title="Ajouter une intervention" icon="icon-pen">
              {this.displayNewAppointment()}
            </Tab>
            :undefined
          }
        </Tabs>
      )
    }
  }

  displayHistory() {
    if(!this.state.preLoading && !this.state.preLoadingError) {
      return (
        <>
          {this.state.item.history.map((element, i) => {
            switch (element.type) {
              case 'comment':
                return (
                  <React.Fragment
                    key={i}>
                    <div className="tabs-history-title col-md-9 col-md-offset-3">{strings.formatString(strings.ticket.history.comment, element["user"]["displayname"], element["created_at"])}</div>
                    <MyForm
                      formKey={this.props.commentForm.formKey}
                      fields={this.props.commentForm.fields}
                      extraValues={this.props.commentForm.extraValues}
                      disabled={true}
                      values={element}
                    />
                  </React.Fragment>
                )
              case 'file':
                return (
                  <React.Fragment
                    key={i}>
                    <div className="tabs-history-title col-md-9 col-md-offset-3">{strings.formatString(strings.ticket.history.file, element["user"]["displayname"], element["created_at"])}</div>
                    <MyForm
                      formKey={this.props.fileForm.formKey}
                      fields={this.props.fileForm.fields}
                      extraValues={this.props.fileForm.extraValues}
                      disabled={true}
                      values={element}
                    />
                  </React.Fragment>
                )
              case 'appointment':
                return (
                  <React.Fragment
                    key={i}>
                    <div className="tabs-history-title col-md-9 col-md-offset-3">{strings.formatString(strings.ticket.history.appointment, element["referee"]["displayname"], element["date_start"], element["number"])}</div>
                    <MyForm
                      formKey={this.props.appointmentForm.formKey}
                      fields={this.props.appointmentForm.fields}
                      extraValues={this.props.appointmentForm.extraValues}
                      disabled={true}
                      values={element}
                    />
                  </React.Fragment>
                )
              case 'form_answer':
                return (
                  <React.Fragment
                    key={i}>
                    <div className="tabs-history-title container-form-row col-md-9 col-md-offset-3">{element["answered"]?strings.formatString(strings.ticket.history.formAnswerFill, element["form"]["name"]):strings.formatString(strings.ticket.history.formAnswerEmpty, element["form"]["name"])}</div>
                    <MyForm
                      formKey={this.props.formAnswerForm.formKey}
                      fields={this.props.formAnswerForm.fields}
                      extraValues={this.props.formAnswerForm.extraValues}
                      disabled={element["answered"]}
                      values={element}
                      onChangeField={(fieldKey, value) => this.onChangeFormAnswer(i, fieldKey, value)}
                      onSubmit={(item) => this.onSubmitFormAnswer(i, item)}
                    />
                  </React.Fragment>
                )
              default:

            }
            return null;
          })}
        </>
      );
    }
  }

  displayButtons() {
    if(this.state.item && this.state.activeNewElement === "") {
      return (
        <SubFooter>
          {((this.props.subType === 'technical' && (this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_COMMENT_INNER_CREATE') || this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_COMMENT_CREATE')))
            || (this.props.subType === 'commercial' && (this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_COMMENT_INNER_CREATE') || this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_COMMENT_CREATE')))
            || (this.props.subType === 'administrative' && (this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_COMMENT_INNER_CREATE') || this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_COMMENT_CREATE'))))
            && (this.state.item.state !== 'closed')?
            <Button variant="my-information" className="btn-sub-footer" onClick={() => this.setState({activeNewElement: "newComment"})}><i className="icon-pen"/>&nbsp;Nouveau commentaire</Button>
            :undefined
          }
          {((this.props.subType === 'technical' && this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_FILE_CREATE'))
            || (this.props.subType === 'commercial' && this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_FILE_CREATE'))
            || (this.props.subType === 'administrative' && this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_FILE_CREATE')))
            && (this.state.item.state !== 'closed')?
            <Button variant="my-information" className="btn-sub-footer" onClick={() => this.setState({activeNewElement: "newFile"})}><i className="icon-pen"/>&nbsp;Nouveau fichier</Button>
            :undefined
          }
          {((this.props.subType === 'technical' && this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_FORM_ANSWER_CREATE'))
            || (this.props.subType === 'commercial' && this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_FORM_ANSWER_CREATE'))
            || (this.props.subType === 'administrative' && this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_FORM_ANSWER_CREATE')))
            && (this.state.item.state !== 'closed')?
            <Button variant="my-information" className="btn-sub-footer" onClick={() => this.setState({activeNewElement: "newFormAnswer"})}><i className="icon-pen"/>&nbsp;Nouveau formulaire</Button>
            :undefined
          }
          {((this.props.subType === 'technical' && this.props.credentials.roles.includes('ROLE_TECHNICAL_TICKET_APPOINTMENT_CREATE'))
            || (this.props.subType === 'commercial' && this.props.credentials.roles.includes('ROLE_COMMERCIAL_TICKET_APPOINTMENT_CREATE'))
            || (this.props.subType === 'administrative' && this.props.credentials.roles.includes('ROLE_ADMINISTRATIVE_TICKET_APPOINTMENT_CREATE')))
            && (this.state.item.state !== 'closed')?
            <Button variant="my-information" className="btn-sub-footer" onClick={() => this.setState({activeNewElement: "newAppointment"})}><i className="icon-pen"/>&nbsp;Nouvelle intervention</Button>
            :undefined
          }
        </SubFooter>
      )
    }
  }

  displayLoading() {
    if(this.state.preLoading || this.state.postLoading) {
      return (
        <Loading/>
      )
    }
  }

  displayRedirect() {
    if(this.state.redirection === true) {
      return (
        <Redirect to={this.state.path} />
      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <>
        <Container className="d-flex flex-column align-items-center">
          {this.displayLoading()}
          {this.displayRedirect()}
          {this.displayNew()}
          {this.displayHistory()}
          {this.displayButtons()}
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.persisted.credentials
})

export default connect(mapStateToProps)(withModalHandler(TicketHistoryTab));
