import React from 'react';

import { connect } from 'react-redux';

import { withModalHandler } from '../../HOC/ModalHandler';

import MenuButton from '../Menu/MainMenu/HeaderButton';
import NotificationCenter from '../Notification/NotificationCenter';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

import { Container, Navbar, Button, Form, Dropdown, Col } from 'react-bootstrap';
import '../../css/header.css';
import strings from '../../Localization/Localization';

import Badge from '../Basic/Badge';

import logo from '../../image/nk_logo.png';
import logoCollapse from '../../image/nk_logo_home.png';

import { getProfil } from '../../API/Profil';

import { saveProfilAction } from '../../Store/Action/profil';
import { modifyThemeAction } from '../../Store/Action/theme';

/**
 * Header
 *
 * The header of the application.
 */
class Header extends React.Component {

  static defaultProps = {
    centerHeader: "breadcrumb",
    customCenterHeader: <div/>
  }

  constructor(props) {
    super(props);

    this.getProfil = this.props.modalHandler.addVerificationWithCallback(getProfil, this.postGet);
  }

  /**
   * get the title for the dropdown menu of the header.
   */
  getDrowpDownTitle() {
    if(this.props.user !== undefined) {
      return strings.formatString(strings.common.header.userName , (this.props.user.firstname !== undefined)?this.props.user.firstname:'', (this.props.user.lastname !== undefined)?this.props.user.lastname:'');
    }
    return "";
  }

  /**
   * Once the component is mounted, we can query for the information of the user
   * needed to be displayed in the component
   */
  componentDidMount() {
    this.getProfil();
  }

  /**
   * callback used to collect user data and store them in the global state of
   * the application.
   */
  postGet = (data) => {
    this.props.dispatch(saveProfilAction(data.item));
  }

  getBadgeValue = () => {
    let quantity = this.props.cart && this.props.cart.cartItems && this.props.cart.cartItems.reduce((accumulator, currentValue) => accumulator + Number(currentValue.quantity), 0);
		if(quantity === 0) {
			return null;
		}
		else if(quantity>10) {
			return '9+';
		}
		else {
			return quantity;
		}
	}

  displayCenterHeader() {
    if(this.props.centerHeader === "breadcrumb") {
      return (
        <Breadcrumb/>
      )
    }
    else if(this.props.centerHeader === "custom") {
      return this.props.customCenterHeader;
    }
  }

  displayCartButton() {
    if(this.props.user && this.props.user.roles && this.props.user.roles.includes('ROLE_CART')) {
      return (
        <Badge
          value={this.getBadgeValue()}
          disabled={!(this.props.cart && this.props.cart.cartItems && this.props.cart.cartItems.length !== 0)}
          rounded={true}
          position='bottom-right-circle'
          size='small'
          variant='info'>
          <Button
            href="/cartSummary"
            className="m-2"
            variant="my-secondary-noline">
            <i className='icon-cart'></i>
          </Button>
        </Badge>

      )
    }
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div>
        <Navbar fixed="top" className="navbar-header" bg="my-primary" expand="lg">
          <Container className="d-flex container-header">
            <Col xs="auto" className="d-flex container-header-left">
              <MenuButton/>
              <Navbar.Brand href="/homepage" className={`${(this.props.isCollapse)?"logo-collapsed":"logo-uncollapsed"}`}>
                <img
                  alt="Nyukom Logo"
                  src={(this.props.isCollapse)?logoCollapse:logo}
                  width={(this.props.isCollapse)?"62":"135"/*"126"*/}
                  height={(this.props.isCollapse)?"32":"23"/*"32"*/}
                  className={`d-inline-block align-top`}
                />
              </Navbar.Brand>
            </Col>
            <Col className="d-flex container-header-center">
              {this.displayCenterHeader()}
            </Col>
            <Col xs="auto" className="d-flex container-header-right">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" className="flex-row-reverse">
                <div className="d-flex flex-row-reverse">
                  <Dropdown
                    alignRight={true}>
                    <Dropdown.Toggle as="div">
                      <Button className="m-2" variant="my-secondary-noline">
                        <i className='icon-user'></i>
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/profil">{strings.common.header.profil}</Dropdown.Item>
                      <Dropdown.Divider />
                      <Form.Control
                        as="select"
                        value={this.props.theme}
                        className="header-theme-selector"
                        onChange={(event) => {
                          this.props.dispatch(modifyThemeAction(event.target.value));
                          window.location.reload()
                        }}>
                        <option value="dark">Thème sombre</option>
                        <option value="light">Thème claire</option>
                        <option value="dark_colorblind">Thème sombre - daltonien</option>
                        {/*<option value="custom_1">Thème custom</option>*/}
                      </Form.Control>
                      <Dropdown.Divider />
                      <Dropdown.Item href="/logout">{strings.common.header.logout}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <NotificationCenter/>
                  {this.displayCartButton()}
                </div>
              </Navbar.Collapse>
            </Col>
          </Container>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.profil.user,
  isCollapse: state.persisted.collapseMenu.isCollapse,
  theme: state.persisted.theme.name,
  cart: state.persisted.cart,
})

export default connect(mapStateToProps)(withModalHandler(Header));
