import strings from '../../../Localization/Localization';

import { store } from '../../../Store/configureStore';

import { fetchPartners } from '../../../API/Partners';
import { fetchPartnerAccounts } from '../../../API/PartnerAccounts';
import { fetchPlaces } from '../../../API/Places';
import { fetchFamilies } from '../../../API/ProductFamilies';
import { fetchSubFamilies } from '../../../API/ProductSubFamilies';

import { timeframe } from '../common';

let serialNumber = {
  fieldKey: "serialNumber",
  label: strings.form.service.serialNumber.label,
  type: "text",
  showOnDisabled: () => false,
  validators: [],
}

let orderNumber = {
  fieldKey: "orderNumber",
  label: strings.form.service.orderNumber.label,
  type: "text",
  showOnDisabled: () => false,
  validators: [],
}

let quoteNumber = {
  fieldKey: "quoteNumber",
  label: strings.form.service.quoteNumber.label,
  type: "text",
  showOnDisabled: () => false,
  validators: [],
}

let productName = {
  fieldKey: "productName",
  label: strings.form.service.productName.label,
  type: "text",
  showOnDisabled: () => false,
  validators: [],
}

let price = {
  fieldKey: "price",
  type: "price",
  label: strings.form.service.price.label,
  showOnDisabled: () => false,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  validators: []
}

// let priceType = {
//   fieldKey: "priceType",
//   type: "dropdown",
//   label: strings.form.product.priceType.label,
//   showOnDisabled: () => false,
//   disabled: (getValues, getExtraValues) => {
//     let credentials = getExtraValues().credentials();
//     return !credentials.roles.includes('ROLE_NYUKOM');
//   },
//   validators: [],
//
//   showEmpty: true,
//   input: 'options',
//   options: [
//     {
//       value: 0,
//       label: strings.form.product.priceType.options.option0
//     },
//     {
//       value: 1,
//       label: strings.form.product.priceType.options.option1
//     },
//     {
//       value: 2,
//       label: strings.form.product.priceType.options.option2
//     }
//   ],
// }

export const state = {
  fieldKey: "state",
  label: strings.form.service.state.label,
  type: "dropdownMulti",
  showOnDisabled: () => false,
  validators: [],

  input: 'options',
  finalValues: 'objects',
  options: [
    {
      value: 'active',
      label: strings.form.service.state.options.active
    },
    {
      value: 'inactive',
      label: strings.form.service.state.options.inactive
    },
    {
      value: 'termination',
      label: strings.form.service.state.options.termination
    },
    {
      value: 'terminated',
      label: strings.form.service.state.options.terminated
    },
  ],
}

// let status = {
//   fieldKey: "status",
//   label: strings.form.service.status.label,
//   type: "dropdown",
//   showOnDisabled: () => false,
//   validators: [],
// }

let partner = {
  fieldKey: "partner",
  label: strings.form.service.partner.label,
  type: "dropdownEntity",
  showOnDisabled: () => false,
  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "partner",
    loader: fetchPartners,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let client = {
  fieldKey: "client",
  label: strings.form.service.client.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues(0).partner === undefined || getValues(0).partner === null || getValues(0).partner.id === undefined,

  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "partner",
      function: (component) => component && component.reloadData()
    }
  ],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "client",
    loader: fetchPartnerAccounts,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["partner"] && getValues(0)["partner"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["partner"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["partner_type"] && data["partner_type"]["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["partner_type"]["name"],
  },
}

let place = {
  fieldKey: "place",
  label: strings.form.service.place.label,
  type: "dropdownEntity",
  disabled: (getValues) => getValues(0).partner === undefined || getValues(0).partner === null || getValues(0).partner.id === undefined,
  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "partner",
      function: (component) => component && component.reloadData()
    }
  ],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "partnerPlaces",
    loader: fetchPlaces,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["partner"] && getValues(0)["partner"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["partner"]["id"],
        active: 1
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let productFamily = {
  fieldKey: "productFamily",
  label: strings.form.service.productFamily.label,
  type: "dropdownEntity",

  validators: [],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "productFamilies",
    loader: fetchFamilies,
    loaderRequire: () => true,
    loaderArguments: () => [],
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let productSubFamily = {
  fieldKey: "productSubFamily",
  label: strings.form.service.productSubFamily.label,
  type: "dropdownEntity",

  validators: [],

  events: [
    {
      id: "reload",
      event: "onChange",
      target: "productFamily",
      function: (component) => component.reloadData()
    }
  ],

  showEmpty: true,
  assertObjectDefaultValue: true,
  loader: {
    id: "productSubFamilies",
    loader: fetchSubFamilies,
    loaderRequire: (value, getValues) => getValues(0)["productFamily"] && getValues(0)["productFamily"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        product_family_id: getValues(0)["productFamily"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => typeof data === 'object' && data["id"] && data["name"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["name"],
  },
}

let deliveryDateMin = {
  fieldKey: "deliveryDateMin",
  type: "date",
  label: strings.form.service.deliveryDateMin.label,
  showOnDisabled: () => false,
  validators: []
}

let deliveryDateMax = {
  fieldKey: "deliveryDateMax",
  type: "date",
  label: strings.form.service.deliveryDateMax.label,
  showOnDisabled: () => false,
  validators: []
}

let updateDateMin = {
  fieldKey: "updateDateMin",
  type: "date",
  label: strings.form.service.updateDateMin.label,
  showOnDisabled: () => false,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  validators: []
}

let updateDateMax = {
  fieldKey: "updateDateMax",
  type: "date",
  label: strings.form.service.updateDateMax.label,
  showOnDisabled: () => false,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  validators: []
}

let emptyApiKey = {
  fieldKey: "emptyApiKey",
  type: "switch",
  label: strings.form.service.emptyApiKey.label,
  disabled: (getValues, getExtraValues) => {
    let credentials = getExtraValues().credentials();
    return !credentials.roles.includes('ROLE_NYUKOM');
  },
  showOnDisabled: () => false,
  validators: []
}

export const form = {
  formKey: "serviceSearch",
  fields: [
    serialNumber,
    orderNumber,
    quoteNumber,
    productName,
    price,
    // priceType,
    state,
    // status,
    partner,
    client,
    place,
    productFamily,
    productSubFamily,
    Object.assign({}, timeframe, {
      fieldKey: "deliveryDateTimeframe",
      label: `${strings.form.common.timeframe.label} ${strings.form.service.deliveryDateTimeframe.label}`
    }),
    deliveryDateMin,
    deliveryDateMax,
    Object.assign({}, timeframe, {
      fieldKey: "updateDateTimeframe",
      label: `${strings.form.common.timeframe.label} ${strings.form.service.updateDateTimeframe.label}`
    }),
    updateDateMin,
    updateDateMax,
    emptyApiKey
  ],
  extraValues: {
    credentials: () => store.getState().persisted.credentials
  }
}
