import strings from '../../Localization/Localization';

import {
  users as usersRoutes,
  products as productsRoutes
} from '../Route/routes';

import { fetchPartnerUsers } from '../../API/Users';

let title = {
  fieldKey: "title",
  type: "text",
  label: strings.form.cart.title.label,
  validators: []
}

let description = {
  fieldKey: "description",
  type: "textarea",
  label: strings.form.cart.description.label,
  validators: []
}

let cartItems = {
  fieldKey: "cart_items",
  type: "table",
  label: strings.form.cart.cartItems.label,
  validators: [],

  table: {
    valuesToRows: (values) => {
      let result = [];
      values.forEach((value) => {
        let type = '';
        switch (value.product.price_type) {
          case 0:
            if(value.rented === true) {
              type = strings.form.product.priceType.options.option_1;
            }
            else {
              type = strings.form.product.priceType.options.option0;
            }
            break;
          case 1:
            type = strings.form.product.priceType.options.option1;
            switch (value["contract"]["bill_period"]) {
              case 1:
                type += ' ' + strings.form.contract.billPeriod.options.monthly;
                break;
              case 2:
                type += ' ' + strings.form.contract.billPeriod.options.fortnightly;
                break;
              case 3:
                type += ' ' + strings.form.contract.billPeriod.options.quarterly;
                break;
              case 6:
                type += ' ' + strings.form.contract.billPeriod.options.halfYearly;
                break;
              case 12:
                type += ' ' + strings.form.contract.billPeriod.options.yearly;
                break;
              default:
            }
            break;
          case 2:
            type = strings.form.product.priceType.options.option2;
            break;
          default:
        }
        result.push(Object.assign({}, value, {
          product_name: value.product.name,
          type: type
        }))
      });
      return result;
    },
    headers: [
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: "product_name",
        href: (item) => item && item["product"] && productsRoutes.routes.show.createPath(item["product"]["id"]),
        label: "Produit",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "string",
        value: "type",
        label: "Type",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "integer",
        value: "quantity",
        label: "Quantité",
        editable: false
      },
      {
        headerColSpan: 1,
        itemColSpan: 1,
        type: "price",
        value: "price",
        label: "Prix Unitaire",
        editable: false
      },
      {
        headerColSpan: 2,
        itemColSpan: 1,
        type: "float",
        value: "discount",
        label: "Remise",
        editable: false
      },
      {
        headerColSpan: 0,
        itemColSpan: 1,
        type: "integer",
        value: "discount_unit",
        label: "",
        editable: false
      }
    ]
  }
}

let cartItemTechnicalData = {
  fieldKey: "cart_items",
  type: "array",
  label: "",

  validators: [],

  allowMoreField: false,
  defaultNumberOfField: 0,

  subField: {
    type: "subform",
    label: (key, values) => values[key]["product"]["name"],

    validators: [],

    subForm: {
      formKey: "cartItem",
      fields: [
        {
          fieldKey: "_technical_data_values",
          type: "array",
          label: "",

          validators: [],

          allowMoreField: false,
          defaultNumberOfField: 0,
          subField: {
            type: "array",
            label: "Entité #key#",

            validators: [],

            allowMoreField: false,
            defaultNumberOfField: 0,

            subField: {
              type: 'configurableField',
              label: "",
              validators: [],

              subField: () => {},
              subFieldConf: {
                label: (value) => value["technical_data"]["name"],
                type: (value) => 'text',
                disabled: () => false,
                validators: (value) => [],
                valueAttribute: 'value'
              },
            }
          }
        }
      ]
    }
  }
}

let state = {
  fieldKey: "state",
  label: strings.form.cart.state.label,
  type: "dropdown",
  validators: [],

  showEmpty: true,
  input: 'options',
  options: [
    {
      value: 'o_w_validation',
      label: strings.form.cart.state.options.order.waitingValidation
    },
    {
      value: 'o_c_period',
      label: strings.form.cart.state.options.order.cancellationPeriod
    },
    {
      value: 'o_w_a_payment',
      label: strings.form.cart.state.options.order.waitingAdvancePayment
    },
    {
      value: 'o_d_i_progress',
      label: strings.form.cart.state.options.order.deliveryInProgress
    },
    {
      value: 'o_delivered',
      label: strings.form.cart.state.options.order.delivered
    },
    {
      value: 'o_canceled',
      label: strings.form.cart.state.options.order.canceled
    }
  ],
}

let clientReferrer = {
  fieldKey: "client_referrer",
  label: strings.form.cart.clientUser.label,
  type: "dropdownEntity",
  validators: [],

  disabledLink: (value, getValues, getExtraValues) => {
    if(getExtraValues().credentials) {
      let credentials = getExtraValues().credentials();
      if(value && (credentials.roles.includes('ROLE_USER_INDEX'))) {
        return usersRoutes.routes.show.createPath(value["id"]);
      }
    }
    return false;
  },

  showEmpty: true,
  loader: {
    id: "clientUser",
    loader: fetchPartnerUsers,
    loaderRequire: (value, getValues) => getValues(0) && getValues(0)["client"] && getValues(0)["client"]["id"],
    loaderArguments: (value, getValues) => {
      return [{
        partner_id: getValues(0)["client"]["id"]
      }]
    },
  },

  adapter: {
    requirement: (data) => data["id"] && data["displayname"],
    getValue: (data) => data["id"],
    getLabel: (data) => data["displayname"],
  },
}

export const technicalDataForm = {
  formKey: "cart",
  fields: [
    cartItemTechnicalData
  ]
}

export const stateForm = {
  formKey: "cart",
  fields: [
    state
  ]
}

export const clientRefererForm = {
  formKey: "cart",
  fields: [
    clientReferrer
  ]
}

export const form = {
  formKey: "cart",
  fields: [
    title,
    description,
    cartItems
  ]
}
