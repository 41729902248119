import {
  ADD_BREADCRUMB,
  RESET_BREADCRUMB
} from '../Action/breadcrumb';

const initialState = {
  version: 0
}

export default function reduce(state = initialState, action){

  let newState
  let history = [];
  let past_history = [];
  switch (action.type) {
    case ADD_BREADCRUMB:
      // if(window.sessionStorage.getItem("id")) {
      //   if(state[window.sessionStorage.getItem("id")] && state[window.sessionStorage.getItem("id")].history) {
      //     past_history = state[window.sessionStorage.getItem("id")].history;
      //   }
      //
      //   if(past_history.some((breadcrumb) => breadcrumb.path === action.path)) {
      //     history = [...past_history.slice(0, 1+past_history.findIndex((breadcrumb) => breadcrumb.path === action.path))];
      //   }
      //   else {
      //     history = [...past_history];
      //     history.push({
      //       path: action.path,
      //       title: action.title,
      //       identifier: action.identifier
      //     })
      //   }
      //
      //   newState = Object.assign({}, state)
      //   newState[window.sessionStorage.getItem("id")] = {
      //     history: history
      //   }
      // }

      if(window.sessionStorage.getItem("breadcrumb")) {
        // if(state[window.sessionStorage.getItem("id")] && state[window.sessionStorage.getItem("id")].history) {
          past_history = JSON.parse(window.sessionStorage.getItem("breadcrumb"));
        // }
      }

      if(past_history.some((breadcrumb) => breadcrumb.path === action.path)) {
        history = [...past_history.slice(0, 1+past_history.findIndex((breadcrumb) => breadcrumb.path === action.path))];
      }
      else {
        history = [...past_history];
        history.push({
          path: action.path,
          title: action.title,
          identifier: action.identifier
        })
      }

      window.sessionStorage.setItem("breadcrumb", JSON.stringify(history));

      newState = Object.assign({}, state, {
        version: state.version +1
      })


      break;
    case RESET_BREADCRUMB:
      // if(window.sessionStorage.getItem("id")) {
      //   if(action.path !== null) {
      //     history.push({
      //       path: action.path,
      //       title: action.title,
      //       identifier: action.identifier
      //     })
      //   }
      //   newState = Object.assign({}, state)
      //   newState[window.sessionStorage.getItem("id")] = {
      //     history: history
      //   }
      // }

      if(action.path !== null) {
        history.push({
          path: action.path,
          title: action.title,
          identifier: action.identifier
        })
      }

      window.sessionStorage.setItem("breadcrumb", JSON.stringify(history));

      newState = Object.assign({}, state, {
        version: state.version +1
      })

      break;
    default:
      newState = state;
  }

  return newState;
}
