import React from 'react';

import '../../../css/item_index.css';

import { Col, Row, Button, Card } from 'react-bootstrap';

import VerticalCollapse from '../../Animation/VerticalCollapse/VerticalCollapse';
import VerticalCollapsed from '../../Animation/VerticalCollapse/VerticalCollapsed';

import OneRowText from '../../Basic/OneRowText';

import Image from '../../Basic/MyImage';

import { connect } from 'react-redux';
import { addCartItemAction } from '../../../Store/Action/cart';
import {
  addLineToFlashbag,
  FLASHBAG_TYPE_SUCCESS
} from '../../../Store/Action/flashbag';

/**
 * Index
 *
 * This component represent an index item, displayed in a table.
 */
class ItemIndexCard extends React.Component {

  static defaultProps = {
    as: 'a',
    buttons: () => []
  }

  constructor(props) {
    super(props);

    this.state = {
      quantity: 0
    }
  }

  addToCart = () => {
    this.props.dispatch(addCartItemAction(this.props.item.id, this.state.quantity));
    this.props.dispatch(addLineToFlashbag("L'objet a été ajouté au panier.", FLASHBAG_TYPE_SUCCESS));
  }

  getImgUrl = () => {
    if(this.props.item === null) {
      return "holder.js/100x100?random=yes&text=loading";
    }
    else if(this.props.itemDisplay.img && this.props.itemDisplay.img(this.props.item) !== null) {
      return this.props.itemDisplay.img(this.props.item);
    }
    else {
      let url = `holder.js/100x100?random=yes&text=${this.props.itemDisplay.firstLine(this.props.item).split(' ').map((item) => item[0]).reduce((prev, curr) => prev+curr)}`;
      return url;
    }
  }

  displayCartItem() {
    if(!!this.props.cartItem) {
      if(this.props.user && this.props.user.roles
        && (
          (this.props.user.roles.includes('ROLE_QUOTE_CREATE')
            && this.props.item.active_quote)
          || (this.props.user.roles.includes('ROLE_ORDER_CREATE')
            && this.props.item.active_order)
          )
        )
      {
        return (
          <Card.Footer className="item-index-card-footer">
            <div className="item-index-card-footer-cart-text">{this.props.cartItem.priceLine?this.props.cartItem.priceLine(this.props.item):null}</div>
            <div className="item-index-card-footer-cart-form-container">
              <div className="item-index-input-quantity-container"><input className="item-index-input-quantity" type="number" value={this.state.quantity} onChange={(e) => this.setState({quantity: e.target.value})} /></div>
              <Button variant="my-terciary-outline" className="item-index-input-submit" onClick={this.addToCart}><i className="icon-cart-new"/></Button>
            </div>
          </Card.Footer>
        )
      }
      else if(this.props.user && this.props.user.roles
        && this.props.user.roles.includes('ROLE_ORDER_CREATE')
        && this.props.item.active_quote)
      {
        return (
          <Card.Footer className="item-index-card-footer">
            <div className="item-index-card-footer-cart-text">{this.props.cartItem.priceLine?this.props.cartItem.priceLine(this.props.item):null}</div>
            <div className="item-index-card-footer-cart-text">Sur devis</div>
          </Card.Footer>
        )
      }
      else if(!this.props.item.active_quote && !this.props.item.active_order) {
        return (
          <Card.Footer className="item-index-card-footer">
            <div className="item-index-card-footer-cart-text">{this.props.cartItem.priceLine?this.props.cartItem.priceLine(this.props.item):null}</div>
            <div className="item-index-card-footer-cart-text">Non disponible</div>
          </Card.Footer>
        )
      }
      else if(this.props.user && this.props.user.roles
        && !this.props.user.roles.includes('ROLE_QUOTE_CREATE')
        && !this.props.user.roles.includes('ROLE_ORDER_CREATE'))
      {
        return (
          <Card.Footer className="item-index-card-footer">
            <div className="item-index-card-footer-cart-text">{this.props.cartItem.priceLine?this.props.cartItem.priceLine(this.props.item):null}</div>
          </Card.Footer>
        )
      }
    }
  }

  displayMain() {
    switch (this.props.as) {
      case 'button':
        return this.displayAsButton();
      case 'a':
        return this.displayAsA();
      default:
        return this.displayDefault();
    }
  }

  displayAsButton() {
    if(this.props.as === 'button') {
      return (
        <Card.Body className="item-index-card-body" onClick={() => this.props.onClickItem(this.props.item.id, this.props.item)}>
          <Row>
            <Col style={{maxWidth:'40%'}}>
              <div className="item-index-card-image-container">
                <Image url={this.getImgUrl()} roundedCircle/>
              </div>
            </Col>
            <Col style={{maxWidth:'60%'}}>
              <OneRowText text={this.props.itemDisplay.firstLine(this.props.item)}/>
              <OneRowText text={this.props.itemDisplay.secondLine(this.props.item)}/>
              <OneRowText text={this.props.itemDisplay.thirdLine(this.props.item)}/>
              <OneRowText text={this.props.itemDisplay.fourthLine(this.props.item)}/>
            </Col>
          </Row>
        </Card.Body>
      )
    }
    else {
      return this.displayDefault()
    }
  }

  displayAsA() {
    if(this.props.as === 'a' && this.props.entityPath) {
      return (
        <a href={this.props.entityPath(this.props.item.id, this.props.item)}>
          <Card.Body className="item-index-card-body">
            <Row className="item-index-card-body-row">
              <Col xs="auto">
                <div className="item-index-card-image-container">
                  <Image url={this.getImgUrl()} roundedCircle/>
                </div>
              </Col>
              <Col className="d-flex item-index-card-text-container">
                <OneRowText text={this.props.itemDisplay.firstLine(this.props.item)}/>
                <OneRowText text={this.props.itemDisplay.secondLine(this.props.item)}/>
                <OneRowText text={this.props.itemDisplay.thirdLine(this.props.item)}/>
                <OneRowText text={this.props.itemDisplay.fourthLine(this.props.item)}/>
              </Col>
            </Row>
          </Card.Body>
        </a>
      )
    }
    else {
      return this.displayDefault()
    }
  }

  displayDefault() {
    return (
      <Card.Body className="item-index-card-body">
        <Row>
          <Col xs="auto">
            <div className="item-index-card-image-container">
              <Image url={this.getImgUrl()} roundedCircle/>
            </div>
          </Col>
          <Col>
            <OneRowText text={this.props.itemDisplay.firstLine(this.props.item)}/>
            <OneRowText text={this.props.itemDisplay.secondLine(this.props.item)}/>
            <OneRowText text={this.props.itemDisplay.thirdLine(this.props.item)}/>
            <OneRowText text={this.props.itemDisplay.fourthLine(this.props.item)}/>
          </Col>
        </Row>
      </Card.Body>
    )
  }

  /**
   * Main render method for React Component
   */
  render() {
    return (
      <div>
        <VerticalCollapse>
          <Card className="item-index-card item-index-clickable">
            {this.displayMain()}
            {this.displayCartItem()}
          </Card>
          <VerticalCollapsed>
            <Row
              className="justify-content-end"
              noGutters={true}>
              {this.props.buttons(this.props.item).map((button, i) => {
                return <Button key={i} className="item-index-button" variant="my-secondary-outline" onClick={button.onClick}>{button.icon?<i className={button.icon}/>:null}{button.label?button.label:null}</Button>;
              })}
            </Row>
          </VerticalCollapsed>
        </VerticalCollapse>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.profil.user
})

export default connect(mapStateToProps)(ItemIndexCard);
