import React from 'react';

import Menu from './Menu';

import '../../../css/main_menu.css';

/**
 * LeftMenu
 */
class LeftMenu extends React.Component {

  static defaultProps = {
    leftMenu: "mainMenu",
    customLeftMenu: <div/>,
  }

  displayMenu() {
    if(this.props.leftMenu === "mainMenu") {
      return (
        <Menu/>
      )
    }
    else if(this.props.leftMenu === "custom") {
      return this.props.customLeftMenu;
    }
  }

  /**
   * LeftMenu render method for React Component
   */
  render() {
    return (
      <div className="d-none d-md-flex">
        <div className="left-menu-position"/>
        <div className="left-menu-position left-menu-background"/>
        <div className="left-menu-position left-menu-wrapper">
          {this.displayMenu()}
        </div>
      </div>
    );
  }
}

export default LeftMenu;
