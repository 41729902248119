import {
  index as baseIndex,
  getFromId as baseGetFromId,
  editFromId as baseEditFromId,
  newItem as baseNewItem,
  transitionFromId as baseTransitionFromId,
  statistic as baseStatistic,
  stateMachineStatistic as baseStateMachineStatistic
 } from './Base';

/******************************************************************************
 * INDEX FUNCTION                                                             *
 ******************************************************************************/

/**
 * Get a list of services based on a pagination system
 *
 * @param page the page requested
 * @param itemPerPage the number of item in the page requested
 */
export function index(page = 1, itemPerPage = 10, search = {}, order = 'ASC', scheme = null){
  return baseIndex('services', page, itemPerPage, search, order, scheme);
}

/******************************************************************************
 * GETTER FUNCTION                                                            *
 ******************************************************************************/

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getFromId(id) {
  return baseGetFromId('services', id);
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getIdentificationFromId(id) {
  return baseGetFromId('services', id, 'identification');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getDependencyFromId(id) {
  return baseGetFromId('services', id, 'dependency');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getTechnicalDataFromId(id) {
  return baseGetFromId('services', id, 'technical_data');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getContractsFromId(id) {
  return baseGetFromId('services', id, 'contract');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getPhonePackageApiFromId(id) {
  return baseGetFromId('services', id, 'phone_package_api');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getPhonePackageOrderFromId(id) {
  return baseGetFromId('services', id, 'phone_package_order');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getPhonePackageConsumptionFromId(id) {
  return baseGetFromId('services', id, 'phone_package_consumption');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getSpecificPhonePriceListFromId(id) {
  return baseGetFromId('services', id, 'specific_phone_price_list');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getAmendmentFromId(id) {
  return baseGetFromId('services', id, 'amendment');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getNewAmendmentFromId(id) {
  return baseGetFromId('services', id, 'new_amendment');
}

/**
 * Get a single service based on his ID
 *
 * @param id the of the service requested
 */
export function getCentreonConfigurationFromId(id) {
  return baseGetFromId('services', id, 'centreon_configuration');
}

/******************************************************************************
 * SETTER FUNCTION                                                            *
 ******************************************************************************/

 /**
  * Edit a single service based on his ID
  *
  * @param id the of the service requested
  * @param data the new data of the service
  */
 export function editFromId(id, data) {
   return baseEditFromId('services', id, data);
 }

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function editTechnicalDataFromId(id, data) {
  return baseEditFromId('services', id, data, 'technical_data');
}

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function editContractsFromId(id, data) {
  return baseEditFromId('services', id, data, 'contract');
}

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function editPhonePackageApiFromId(id, data) {
  return baseEditFromId('services', id, data, 'phone_package_api');
}

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function editSpecificPhonePriceListFromId(id, data) {
  return baseEditFromId('services', id, data, 'specific_phone_price_list');
}

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function editNewAmendmentFromId(id, data) {
  return baseEditFromId('services', id, data, 'new_amendment');
}

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function editNewAmendmentServicePriceFromId(id, data) {
  return baseEditFromId('services', id, data, 'new_amendment_service_price');
}

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function editCentreonConfigurationFromId(id, data) {
  return baseEditFromId('services', id, data, 'centreon_configuration');
}

/******************************************************************************
 * NEW FUNCTION                                                               *
 ******************************************************************************/

/**
 * Create a new service
 *
 * @param data the new data of the partner
 */
export function newItem(data) {
  return baseNewItem('services', data);
}

/******************************************************************************
 * TRANSITION FUNCTION                                                        *
 ******************************************************************************/

/**
 *
 */
export function disableTransition(id, data) {
  return baseTransitionFromId('services', id, data, 'disable');
}

/**
 *
 */
export function terminationTransition(id, data) {
  return baseTransitionFromId('services', id, data, 'termination');
}

/**
 *
 */
export function cancelTerminationTransition(id, data) {
  return baseTransitionFromId('services', id, data, 'cancel_termination');
}

/**
 *
 */
export function terminatedTransition(id, data) {
  return baseTransitionFromId('services', id, data, 'terminated');
}

/**
 *
 */
export function terminatedNoBillTransition(id, data) {
  return baseTransitionFromId('services', id, data, 'terminated_no_bill');
}

/******************************************************************************
 * STATISTIC FUNCTION                                                         *
 ******************************************************************************/

export function statistic(stat, attribute, search) {
  return baseStatistic('services', stat, attribute, search);
}

/******************************************************************************
 * STATE MACHINE STATISTIC FUNCTION                                           *
 ******************************************************************************/

export function stateMachineStatistic(stateStatistic, states, search) {
  return baseStateMachineStatistic('services', stateStatistic, states, search);
}

/******************************************************************************
 * FETCH FUNCTION                                                             *
 ******************************************************************************/

/**
 * Fetch all family to serve in a dropdown form choice
 */
export function fetchServices(search) {
  return baseIndex('services', 1, 0, search);
}

/******************************************************************************
 * SPECIFIC FUNCTION                                                          *
 ******************************************************************************/

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function addPhonePackage(id, data) {
  return baseEditFromId('services', id, data, 'phone_package_order');
}

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function activatePhonePackageOrder(id, phonePackageOrderId) {
  let data = {
    id: id,
    phone_package_order: {
      id: phonePackageOrderId,
      active: true
    }
  }
  return baseEditFromId('services', id, data, 'phone_package_order_activation');
}

/**
 * Edit a single service based on his ID
 *
 * @param id the of the service requested
 * @param data the new data of the service
 */
export function deactivatePhonePackageOrder(id, phonePackageOrderId) {
  let data = {
    id: id,
    phone_package_order: {
      id: phonePackageOrderId,
      active: false
    }
  }
  return baseEditFromId('services', id, data, 'phone_package_order_activation');
}
