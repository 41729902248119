import strings from '../../../Localization/Localization';

let name = {
  fieldKey: "name",
  type: "text",
  label: strings.form.partner.name.label,
  showOnDisabled: () => false,
  validators: []
}


export const form = {
  formKey: "partnerTypeSearch",
  fields: [
    name,
  ]
}
